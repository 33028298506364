<template>
  <span>
    <button :disabled="disabled"
      @click="!disabled && jumpToDate()"
      title="Calendar"
    class="date-navigation__button gds-button gds-compact gds-button-outline">
      <svg class="gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
      </svg>
      <span>{{buttonLabel || defaultButtonLabel}}</span>
    </button>
    
  </span>
</template>

<script>

export default {
  name: "DateNavigationButtonJumpToDate",
  data() {
    return {
      defaultButtonLabel: 'Jump to Date'
    };
  },
  props: {
    jumpToDate: Function,
    buttonLabel: String,
    disabled: Boolean
  }
};
</script>

<style scoped>
</style>