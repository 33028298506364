var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bill-pay-login__main gds-flex-container",
      attrs: { id: "top-target" }
    },
    [
      _c("nav", { staticClass: "bill-pay-login__nav" }, [
        _c(
          "div",
          { staticClass: "outage-login__nav__inner-link-wrap" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button",
                attrs: { to: "/login" }
              },
              [_vm._v("Login")]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button",
                attrs: { to: "/login-account" }
              },
              [_vm._v("Report with Account Number")]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button",
                attrs: { to: "/login-phone" }
              },
              [_vm._v("Report with Phone Number")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bill-pay-login__phone-address-section-desktop" },
          [_c("manual-reporting-box")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bill-pay-login__login-type-container" }, [
        _c("section", { staticClass: "bill-pay-login__one-time-section" }, [
          _c(
            "h2",
            { staticClass: "bill-pay-login__main-headline gds-display-2" },
            [_vm._v("Report an Outage with your Phone Number")]
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "gds-body-normal" },
            [
              _vm._v(
                "\n        Please enter the phone number associated with your service account. You can also report an outage by\n        "
              ),
              _c("router-link", { attrs: { to: "/login" } }, [
                _vm._v("Logging In")
              ]),
              _vm._v(" or by looking up your account by\n        "),
              _c("router-link", { attrs: { to: "/login-account" } }, [
                _vm._v("Account Number")
              ]),
              _vm._v(".\n      ")
            ],
            1
          ),
          _vm._v(" "),
          _vm.errorWrongNumber
            ? _c(
                "div",
                {
                  staticClass:
                    "form-message-box form-message-box--full-width gds-space-stack-l"
                },
                [
                  _vm._v(
                    "\n        We were unable to locate an account that matched the information you provided. You can also report an outage by phone at\n        "
                  ),
                  _c("span", { staticClass: "gds-nobreak" }, [
                    _vm._v(_vm._s(_vm.GMPPhoneNumber) + ".")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "bill-pay-login__form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.Login()
                }
              }
            },
            [
              _c(
                "fieldset",
                {
                  staticClass:
                    "gds-fieldset gds-space-stack-m gds-space-inline-default",
                  class: { "gds-has-error": _vm.errorWrongNumber }
                },
                [
                  _c(
                    "label",
                    { staticClass: "gds-input-field" },
                    [
                      _c("span", { staticClass: "gds-input-field__label" }, [
                        _vm._v("Phone Number")
                      ]),
                      _vm._v(" "),
                      _c("the-mask", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:10",
                            expression: "'required|min:10'"
                          }
                        ],
                        key: "phone number",
                        staticClass: "gds-input-field__input",
                        attrs: {
                          mask: "+1 (###) ###-####",
                          "data-vv-validate-on": "input",
                          name: "phone number",
                          type: "tel",
                          placeholder: "Your phone number"
                        },
                        nativeOn: {
                          input: function($event) {
                            _vm.touched = true
                          }
                        },
                        model: {
                          value: _vm.phoneNumber,
                          callback: function($$v) {
                            _vm.phoneNumber = $$v
                          },
                          expression: "phoneNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.errors.first("phone number")
                    ? _c("div", { staticClass: "validation-error" }, [
                        _vm._v("Complete phone number required")
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gds-space-stack-l" }, [
                _vm.errorLogin
                  ? _c(
                      "div",
                      {
                        staticClass: "gds-helper-text-error",
                        attrs: { id: "one-time-submission_error" }
                      },
                      [_vm._v(_vm._s(_vm.errorLogin))]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gds-space-stack-l" }, [
                _c(
                  "button",
                  {
                    staticClass: "gds-button gds-orange",
                    class: { "gds-loading": _vm.pendingLogin },
                    attrs: { disabled: _vm.pendingLogin, type: "submit" }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Next")
                    ])
                  ]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "bill-pay-login__phone-address-section-mobile" },
          [_c("manual-reporting-box")],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }