var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      {
        staticClass: "past-due-wizard-success-fail",
        attrs: { id: "top-target" }
      },
      [
        _vm.isOverallSuccess
          ? _c("div", { staticClass: "bill-pay-success__intro-image" }, [
              _c("img", {
                staticClass: "my-account__payment-plan-icon",
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/past-due-wizard-complete.svg",
                  alt: "successful payment"
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isOverallSuccess
          ? _c(
              "div",
              { staticClass: "bill-pay-fail__intro-image gds-space-stack-xl" },
              [
                _c("img", {
                  staticClass: "my-account__payment-plan-icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/pay-fatal-error.svg",
                    alt: "unsuccessful payment"
                  }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "gds-space-stack-l" }, [
          _vm._v(_vm._s(_vm.language))
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "bill-pay-success__button-wrap gds-align--text-center"
          },
          [
            _c(
              "button",
              {
                staticClass: "bill-pay-success__button gds-button",
                attrs: { type: "button" },
                on: { click: _vm.returnToAccountDashBoard }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }