<template>
  <div v-if="validUsageNotificationTypeCodes.length > 0">
      <h2 class="gds-space-top-xl gds-space-stack-l">Usage Notifications</h2>
    <div class="gds-position--relative">
      <div v-if="loadingData" class="usage-notification__spinner"></div>
      <div v-if="!loadingData && apiError" class="usage-notification__error-container">Unable to load, please try again later.</div>
      <div class="usage-notification__widget-container" v-if="!loadingData && !apiError && alertTypes && alertTypes.length > 0">
        <div class="usage-notification__alert-container gds-position--relative" v-for="alertType of validUsageNotificationTypeCodes" :key="alertType.name">
          <div class="usage-notification__alert-image">
            <img  class="usage-notification__alert-image" :src="alertType.icon" alt="usage alert image" />
          </div>
          <div class="usage-notification__alert-content">
            <span class="usage-notification__alert-name">{{alertType.name}}</span>
            <p class="usage-notification__alert-description">{{alertType.description}}</p>
            <p class="usage-notification__alert-description-short">{{alertType.descriptionShort}}</p>
            <span class="usage-notification__alert-indicator">              
              <IndicatorOnOff :value="alertType.active"></IndicatorOnOff>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="usage-notification__manage-notifications-container">
      <div class="gds-align--text-center">
        <a href="/account/notifications" class="gds-card__button gds-button gds-round gds-button-outline gds-compact">Manage Notifications</a>
      </div>
    </div>
  </div>
</template>

<script>

import GMPAPI from "../../../../services/gmpapi";
import { ToServerDateTruncate, DumpError } from '../../../../utilities';
import IndicatorOnOff from "../../../generic/IndicatorOnOff";

export default {
  name: "UsageNotification",
  data() {
    return {
      validUsageNotificationTypeCodes: [],
      loadingData: false,
      apiError: false,
      alertTypes: undefined,
      alertContacts: undefined,
      usageAlertTypes: undefined,
    }
  },
  components: {
    IndicatorOnOff,
  },
  mounted() {
    this.refreshData();
  },
  watch: {
    currentAccount() {
      this.refreshData();
    },
  },
  computed: {
   currentAccount() {
        return this.$store.state.user.currentAccount;
    },
  },
  methods: {  
    async refreshData() {
      try {
        this.apiError = false;
        this.loadingData = true;
        this.setValidUsageNotificationTypes();

        await this.getAlertTypes().catch(() => {
          throw new Error("Error getting alert types.");
        });
        this.filterSubscribedUsageAlerts(); 
        await this.getAlertContacts().catch(() => {
          throw new Error("Error getting alert contacts.");
        });
        this.setAlertStatus();
      } catch(err) {
        this.apiError = true;
        DumpError(err.message, err);
      } finally {
        this.loadingData = false;
      }
    },
    async getAlertTypes() {
      const accountNumber = this.currentAccount.accountNumber;
      this.alertTypes = await GMPAPI.GetAlertTypes(accountNumber);
    },
    async getAlertContacts() {
      const accountNumber = this.currentAccount.accountNumber;
      this.alertContacts = await GMPAPI.GetAlertContacts(accountNumber);
    },
    filterSubscribedUsageAlerts() {
      this.usageAlertTypes = this.alertTypes.filter(item1 => this.validUsageNotificationTypeCodes.some(item2 => item1.code === item2.code));
      this.validUsageNotificationTypeCodes.forEach((obj, index) => { 
        const alertTypeIndex = this.usageAlertTypes.findIndex(item => item.code === obj.code);         
        if (alertTypeIndex != -1) { 
          this.validUsageNotificationTypeCodes[index].icon = this.usageAlertTypes[alertTypeIndex].iconUrl;          
        } else {
          this.validUsageNotificationTypeCodes.splice(index);
        }
      });
    },
    hasContactSubscribed(alertType) {
      return this.alertContacts.some(contact => 
        contact.subscriptions && 
        contact.subscriptions.some(subscription => 
          subscription.alertType.code === alertType.code
        )
      );
    },
    setAlertStatus() {
      this.validUsageNotificationTypeCodes = this.validUsageNotificationTypeCodes.map(item => ({
        ...item, active: this.hasContactSubscribed(item)}));
    },
    setValidUsageNotificationTypes() {
      this.validUsageNotificationTypeCodes = [
        {
          code: 'WEEKLY_SUMMARY_NOTIFICATIONS',
          name: 'Weekly Summary Email',
          description: 'Your usage summarized each week, with daily breakdown, bill prediction, and week-to-week comparison.',
          descriptionShort: 'Daily breakdown, bill prediction, and week-to-week comparison.',
          icon: undefined,
          active: false
        },
        {
          code: 'USAGE_THRESHOLD_ALERT',
          name: 'Usage Alerts',
          description: 'Avoid high-usage surprises.  Get notified when your usage exceeds a threshold you set for the billing period.',
          descriptionShort: 'Set usage thresholds and avoid high-usage surprises',
          icon: undefined,
          active: false
        }
      ]
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
