import { render, staticRenderFns } from "./PageGhostingSearch.vue?vue&type=template&id=e972de28&scoped=true&"
import script from "./PageGhostingSearch.vue?vue&type=script&lang=js&"
export * from "./PageGhostingSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e972de28",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/bamboo/local-working-dir/WWW-WWW-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e972de28')) {
      api.createRecord('e972de28', component.options)
    } else {
      api.reload('e972de28', component.options)
    }
    module.hot.accept("./PageGhostingSearch.vue?vue&type=template&id=e972de28&scoped=true&", function () {
      api.rerender('e972de28', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/admin/PageGhostingSearch.vue"
export default component.exports