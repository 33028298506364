<template>
  <span>
    <button v-if="!disabled" @click="buttonClicked()"
    class="date-navigation__button-mobile"
    :class="{ 'add-background': addBackground }">
      <span class="date-navigation__button-mobile__label">{{buttonText}}</span>
      <i v-if="addBackground" class="usage-detail-mobile__down-caret"></i>
    </button>
    <button v-if="disabled" disabled
    class="date-navigation__button-mobile disabled">
      <span class="date-navigation__button-mobile__label"
      :class="{ 'add-background': addBackground }">{{buttonText}}</span>
      <i v-if="addBackground" class="usage-detail-mobile__down-caret"></i>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButtonMobile",
  props: {
    disabled: Boolean,
    addBackground: Boolean,
    buttonText: String,
    buttonLabel: String,
    buttonClicked: Function,
  }
};
</script>

<style scoped>
</style>