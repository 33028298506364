var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "my-account__modal-box no-scroll-modal" },
          [
            _c(
              "button",
              {
                staticClass: "close bill-pay__close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.close("documentChecklist")
                  }
                }
              },
              [
                _c("svg", { staticClass: "gds-icon close-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("FocusLock", [
              _c(
                "div",
                { staticClass: "eicproducts__modal-box--body" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gds-font-size-xl gds-font-demi gds-space-stack-m"
                    },
                    [_vm._v("What are you uploading?")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "my-account__modal-box--message gds-space-stack-l"
                    },
                    [
                      _vm.selectedFile
                        ? _c("div", [
                            _vm._v(
                              "Please select which document(s) are contained in the file: " +
                                _vm._s(_vm.selectedFile.name) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.uploadDocumentList, function(uploadDocument, i) {
                    return _c(
                      "div",
                      {
                        key: uploadDocument.documentName + i,
                        staticClass: "file-uploader"
                      },
                      [
                        _c("div", { staticClass: "gds-space-stack-ml" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "gds-checkbox gds-checkbox-thin gds-space-stack-m"
                            },
                            [
                              _c("input", {
                                attrs: {
                                  id: _vm.generateInputId(
                                    uploadDocument.displayName
                                  ),
                                  type: "checkbox",
                                  disabled:
                                    _vm.documentTypes.includes(
                                      uploadDocument.displayName
                                    ) && uploadDocument.file != null
                                      ? true
                                      : false,
                                  name: ""
                                },
                                domProps: {
                                  value: uploadDocument.documentName
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onSelect(
                                      $event,
                                      uploadDocument.displayName
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-checkbox__faux" }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-font-size-ml" }, [
                                _c("b", [
                                  _vm._v(_vm._s(uploadDocument.displayName))
                                ])
                              ])
                            ]
                          )
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "gds-button gds-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.close("documentChecklist")
                        }
                      }
                    },
                    [_vm._v("Cancel\n          Upload")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "gds-button",
                      attrs: { type: "button" },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v("Save")]
                  )
                ],
                2
              )
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }