<template>
  <div
    class="gmp-devices-page__devices-list"
    :class="{ 'is-desktop-view': isDesktopView}"
  >
    <Device
      v-for="device of devices"
      :key="device.deviceId"
      :device="device"
    />
    <Device />
  </div>
</template>

<script>
import Device from './device/Device.vue';
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';

export default {
  name: 'DevicesList',
  components: {
    Device,
  },
  mixins: [MediaQueryMixin],
  props: {
    devices: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
};
</script>
