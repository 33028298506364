import GMPAPI from "../../../services/gmpapi";
import DataStore from "../../../store";
import PageUsageDetail from "../usage/PageUsageDetail.vue";
import UsageDetailGraph from "../usage/usagedetailgraph/UsageDetailGraph.vue";
import UsageDetailTable from "../usage/usagedetailtable/UsageDetailTable.vue";
import UsageDetailTimeOfDay from "../usage/usagedetailtimeofday/UsageDetailTimeOfDay.vue";
import UsageDetailSeasonal from "../usage/usagedetailseasonal/UsageDetailSeasonal.vue";
//import UsageDetailHeatingCooling from "../usage/usagedetailheatingcooling/UsageDetailHeatingCooling.vue";
import UsageDetailPerformance from "../usage/usagedetailperformance/UsageDetailPerformance.vue";
import UsageDetailCompare from "../usage/usagedetailcompare/UsageDetailCompare.vue";

import UsageDetailEVCharging from "../usage/usagedetailevcharging/UsageDetailEVCharging.vue";

export const UsageDetailRouterOptions = {
  mode: 'history',
  base: '/account/usage-new/detail',
  routes: [
    {
      path: '/',
      component: PageUsageDetail,
      redirect: '/graph'
    },
    {
      path: '/graph',
      component: UsageDetailGraph,
    },
    {
      path: '/table',
      component: UsageDetailTable,
    },
    {
      path: '/time-of-day',
      component: UsageDetailTimeOfDay,
    },
    {
      path: '/seasonal-disabled',
      component: UsageDetailSeasonal,
    },
    {
      path: '/ev-charging',
      component: UsageDetailEVCharging,
    },
    // {
    //   path: '/heating-cooling',
    //   component: UsageDetailHeatingCooling,
    // },
    {
      path: '/performance',
      component: UsageDetailPerformance,
    },
    {
      path: '/compare',
      component: UsageDetailCompare,
    },
    { path: '*', redirect: '/' },
  ],
};


let knownLoggedIn = false;

export function UsageDetailRouterInit(router) {
  // Enforce required login before showing my account
  router.beforeEach(async (to, from, next) => {
    const dataStoreState = DataStore.state;

    const currentAccountAvailable = !!(dataStoreState.user && dataStoreState.user.currentAccount);
    const currentAccount = currentAccountAvailable ? dataStoreState.user.currentAccount : {};

    if (knownLoggedIn) {
      next();
    } else {
      try {
        DataStore.commit("setRouteLoading", true);
        await GMPAPI.GetCurrentUser();
        // We have auth, but may not have user info
        if (!DataStore.state.user.userinfo) {
          try {
            await DataStore.dispatch("AttemptCookieLogin");
            // Set this, so we don't try to verify login on every route change
            knownLoggedIn = true;
            next();
          } catch (err) {
            window.location.href = "/transit-login?redirect=" + encodeURIComponent(window.location.pathname + window.location.hash + window.location.search);
          }
        } else {
          // Set this, so we don't try to verify login on every route change
          knownLoggedIn = true;
          next();
          if (currentAccountAvailable && currentAccount.resilientHome) {
            window.location.href = "/account/usage-unavailable";
          }

          if (router.history.current.path === '/ev-charging/') {
            if (currentAccountAvailable && !currentAccount.evCharger) {
                router.push({ path: 'graph' });
            }
          }
        }
      } catch (err) {
        if (!(err.response && err.response.status === 401)) {
          DumpError("Arrange routing error", err);
        }
        window.location.href = "/transit-login?redirect=" + encodeURIComponent(window.location.pathname + window.location.hash + window.location.search);
      } finally {
        DataStore.commit("setRouteLoading", false);
      }
    }
  });
}
