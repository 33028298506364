import GMPAPI from "./gmpapi";
import { DumpError } from "../utilities";

let summary;

/** Populates the status summary the first time after page load, then reuses the same one */
async function PopulateSummary() {
  if (!summary) {
    summary = await GMPAPI.GetStatuspageSummary();
  }
}

/**
 * Check a component's status via statuspage.io
 * Returns "operational" "maintenance" or "error"
 */
export async function GetComponentStatus(componentName) {
  try {
    await PopulateSummary();
    const component = summary.components.find(item => item.name === componentName);
    if (!component) throw new Error("No such component " + componentName);
    switch (component.status) {
      case "operational":
        return "operational";
      case "under_maintenance":
        return "maintenance";
      default:
        return "error";
    }
  } catch (err) {
    DumpError("Unable to get statuspage", err);
    return "operational";
  }
}