import { subYears, endOfMonth, getYear, format, getMonth, startOfMonth, differenceInDays } from "date-fns";
import GMPAPI from '../services/gmpapi';
import { ToServerDateTruncate, ToServerDateTruncateHours } from '../utilities';

const ADJUSTMENT_TYPE = 'Adjustment';
const BILL_TYPE = 'Bill Segment';
const BILL_CANCEL_TYPE = 'Bill Segment Cancellation';
const PAYMENT_TYPE = 'Pay Segment';
const PAYMENT_CANCEL_TYPE = 'Pay Segment Cancellation';

export async function GetFormattedBillingInfo(accountNumber) {
  const today = new Date();
  const reportStart = startOfMonth(subYears(today, 2));
  const reportEnd = endOfMonth(today);
  const allTransactions = await GMPAPI.GetAccountTransactions(accountNumber, ToServerDateTruncate(reportStart), ToServerDateTruncate(reportEnd));

  const thisYear = getYear(today);
  const lastYear = getYear(subYears(today, 1));
  const thisYearStats = RollupYear(allTransactions, thisYear);
  const lastYearStats = RollupYear(allTransactions, lastYear);
  const winterAverage = SeasonalAverage(allTransactions, [11, 0, 1]);
  const springAverage = SeasonalAverage(allTransactions, [2, 3, 4]);
  const summerAverage = SeasonalAverage(allTransactions, [5, 6, 7]);
  const fallAverage = SeasonalAverage(allTransactions, [8, 9, 10]);
  return {
    thisYear: thisYearStats,
    lastYear: lastYearStats,
    winterAverage,
    springAverage,
    summerAverage,
    fallAverage,
    noTransactions: !allTransactions.length
  };
}

// Get flat line-items
export async function GetBillingRates(accountNumber, startDate, endDate) {
  const formattedStartDate = ToServerDateTruncateHours(startDate);
  const formattedEndDate = ToServerDateTruncateHours(endDate);
  const billingRates = GMPAPI.GetBillingRates(accountNumber, formattedStartDate, formattedEndDate);
  return billingRates;
}

// Download
export async function DownloadBillingReport(accountNumber, filename, data) {
  return await GMPAPI.DownloadBillingReport(accountNumber, filename, data);
}

// View
export async function GetTransposedBillReport(accountNumber, data) {
  const billingReport = GMPAPI.GetTransposedBillingReport(accountNumber, data);
  return billingReport;
}

export async function isAccountXDaysPastDue(account, numOfDays) {
  const accountNumber = account.accountNumber;
  const status = await GMPAPI.GetAccountStatus(accountNumber);

  if (numOfDays >= 60) {
    return status && status.pastDue60;
  }
  if (numOfDays >= 30) {
    return status && status.pastDue30;
  }
  return false;
}

function payoffTotaller(accum, current) {
  return accum + current.payoffAmount;
}

function RollupYear(allTransactions, year) {
  const transactions = allTransactions.filter(item => getYear(item.date) === year);
  const bills = transactions.filter(item => item.type === BILL_TYPE || item.type === ADJUSTMENT_TYPE || item.type === BILL_CANCEL_TYPE);
  const payments = transactions.filter(item => item.type === PAYMENT_TYPE || item.type === PAYMENT_CANCEL_TYPE);
  const billTotal = Math.round(bills.reduce(payoffTotaller, 0));
  const paymentTotal = Math.round(payments.reduce(payoffTotaller, 0));
  return {
    year,
    billTotal,
    paymentTotal,
  }
}

// Note: this uses the last 24 months of bills. The old site uses 1 year, sort of (most recent 3 "month" periods for each season)
function SeasonalAverage(allTransactions, months) {
  const transactions = allTransactions.filter(item => months.includes(getMonth(item.date)));
  const bills = transactions.filter(item => item.type === BILL_TYPE || item.type === ADJUSTMENT_TYPE || item.type === BILL_CANCEL_TYPE);
  const uniqueMonths = new Set();
  for (const bill of bills) {
    uniqueMonths.add(format(bill.date, "yyyy-MM"));
  }
  if (uniqueMonths.size > 0) {
    return Math.round(bills.reduce(payoffTotaller, 0) / uniqueMonths.size);
  } else {
    return 0;
  }
}
