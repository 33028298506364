<template>
  <div class="make-payment-container">
    <div id="top-target" class="bill-pay-success-fail">
      <div class="bill-pay-fail__intro-image gds-space-stack-xl">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151 119">
          <title>Oh no!</title>
          <g fill="none" fill-rule="evenodd">
            <path
              fill="#939BAA"
              d="M133.91 101.47L27.21 118.8a14.23 14.23 0 0 1-16.3-11.75L.05 40.35a4.53 4.53 0 0 1 3.74-5.2l125.83-20.44a4.53 4.53 0 0 1 5.2 3.73l10.83 66.71a14.23 14.23 0 0 1-11.75 16.32z"
            />
            <path
              fill="#FFF"
              d="M4.15 37.33A2.32 2.32 0 0 0 2.23 40l10.84 66.71c1.07 6.54 7.25 11 13.8 9.93L133.55 99.3c6.54-1.06 11-7.25 9.93-13.78L132.65 18.8a2.32 2.32 0 0 0-2.66-1.92L4.15 37.33z"
            />
            <path
              fill="#B8BDC7"
              d="M27.42 113.87a10.6 10.6 0 0 1-12.15-8.75L7 54.16l1.3-.21 8.29 50.96a9.27 9.27 0 0 0 10.62 7.65l.22 1.3zM7.64 49.93l-1.3.2-1.42-8.69 1.3-.2 1.42 8.69zm124.1 46.98l-.22-1.3a9.27 9.27 0 0 0 7.66-10.63l-8.28-50.96 1.3-.21 8.28 50.96a10.6 10.6 0 0 1-8.74 12.14zm-.2-67.12l-1.3.21-1.41-8.7 1.3-.2 1.42 8.69z"
            />
            <path
              fill="#DDE2E8"
              d="M123.05 93.64l-16.5 2.68a4.75 4.75 0 0 1-5.44-3.92l-.12-.7a4.75 4.75 0 0 1 3.93-5.44l16.5-2.68a4.75 4.75 0 0 1 5.44 3.92l.11.7a4.75 4.75 0 0 1-3.92 5.44zM15.4 46.85l-.94-1.69c.32-.14.67-.24 1.03-.3l106.54-17.32c.43-.07.86-.07 1.28-.01l-.35 1.84a6.04 6.04 0 0 1-4.97 4.85L21.66 49.88c-2.51.4-5.01-.8-6.25-3.03zm1.98 12.17l-.94-1.69a4.3 4.3 0 0 1 1.03-.3L124.01 39.7c.44-.07.87-.07 1.29-.01l-.35 1.84a6.04 6.04 0 0 1-4.97 4.85L23.64 62.05c-2.51.41-5.01-.8-6.25-3.03z"
            />
            <path
              fill="#939BAA"
              d="M118.25 35.74L21.9 51.4a7.16 7.16 0 0 1-7.4-3.58l-2.01-3.64a3.6 3.6 0 0 0-3.73-1.8l-6.75 1.1-.36-2.18 6.76-1.1c2.4-.38 4.82.78 6 2.91l2.02 3.64c1.01 1.82 3.07 2.81 5.12 2.48l96.33-15.66a4.96 4.96 0 0 0 4.07-3.97l.77-4.1a5.82 5.82 0 0 1 4.77-4.65l6.75-1.1.36 2.18-6.76 1.1a3.6 3.6 0 0 0-2.96 2.88l-.76 4.1a7.16 7.16 0 0 1-5.88 5.73"
            />
            <path
              fill="#939BAA"
              d="M120.15 47.48L23.82 63.14a7.16 7.16 0 0 1-7.4-3.58l-2.02-3.64a3.6 3.6 0 0 0-3.72-1.8l-6.75 1.1-.36-2.18 6.76-1.1c2.4-.39 4.82.78 6 2.91l2.02 3.64c1 1.81 3.06 2.8 5.12 2.47l96.33-15.65a4.96 4.96 0 0 0 4.07-3.97l.76-4.1a5.82 5.82 0 0 1 4.78-4.65l6.75-1.1.36 2.18-6.76 1.1a3.6 3.6 0 0 0-2.96 2.88l-.77 4.1a7.16 7.16 0 0 1-5.88 5.73"
            />
            <path fill="#FC5B39" d="M150.79 17.92a17.76 17.76 0 1 1-35.52 0 17.76 17.76 0 0 1 35.52 0" />
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M133.03 15.66l5.24-5.25 2.27 2.26-5.25 5.25 5.25 5.25-2.27 2.26-5.24-5.25-5.25 5.25-2.27-2.26 5.25-5.25-5.25-5.25 2.27-2.26 5.25 5.25z"
            />
          </g>
        </svg>
      </div>

      <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Oh no!</h2>

      <div class="bill-pay-fail__description gds-body-large gds-align--text-center gds-space-stack-xl">
        We were unable to change your settings at this time. Please try again or contact customer support for assistance at
        <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
      </div>

      <div class="bill-pay-success__button-wrap gds-align--text-center gds-space-stack-l">
        <button @click="ReturnToSender('/arrange-payment', '/account/billing')" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment"
import { ReturnToSender } from "../../utilities";

export default {
  name: "PayArrangeFatalError",
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  computed: {
  },
  async mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>