var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "export-data__modal-box" },
          [
            _c("FocusLock", [
              _c("header", { staticClass: "delete-user__modal-box--header" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "close gds-button gds-button-circle gds-secondary",
                    on: {
                      click: function($event) {
                        return _vm.Close(false)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "gds-visibility--hide-visually" },
                      [_vm._v("Close")]
                    ),
                    _vm._v(" "),
                    _c("svg", { staticClass: "gds-icon" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                        }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "export-data__modal-box--headline" }, [
                  _vm._v(
                    "This saved payment method is used for automatic payments."
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "delete-user__modal-box--body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "export-data__modal-box--message gds-space-stack-l"
                  },
                  [
                    _vm._v(
                      "\n          Removing this payment method will stop automatic payments for this GMP account. Visit the\n          "
                    ),
                    _c(
                      "router-link",
                      { attrs: { to: "/preferences#autopay" } },
                      [_vm._v("Billing Preferences")]
                    ),
                    _vm._v(
                      " page\n          to choose a different payment method and manage your recurring auto payment settings.\n        "
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.errorRemove
                  ? _c(
                      "div",
                      { staticClass: "form-message-box gds-space-stack-m" },
                      [_vm._v(_vm._s(_vm.errorRemove))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-secondary gds-compact gds-space-stack-m",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.Close(false)
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Cancel")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m",
                    class: { "gds-loading": _vm.pendingRemove },
                    attrs: { disabled: _vm.pendingRemove, type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.Save()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Remove Payment Method")
                    ])
                  ]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }