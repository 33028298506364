import { debounce, makeAjaxRequest, trapFocus } from '../functions';
import autoComplete from '@tarekraafat/autocomplete.js';

const maxAPIResults = 100;
const maxShownResults = 10;
const hideClass = 'gds-visibility--hide';

const KEY_CODE_MAP = {
  TAB: 9,
  ENTER: 13,
  ESC: 27
};

function getResultCountText(count) {
  var base = (count >= maxAPIResults) ? `over ${maxAPIResults}` : count;
  var resultWord = (count == 1) ? 'result' : 'results';
  var showing = (count > maxShownResults) ? `Showing ${maxShownResults} of ` : '';
  return showing + base + ' ' + resultWord + ' found:'
}

export default function configureSearchInput(
  wrapperSelector,
  searchInputSelector,
  searchResultsWindowSelector,
  onClose = false,
  additionalQueryStrings = false,
  onSelection = false,
  sortResults = false
) {
  const wrapper = document.querySelector(wrapperSelector);
  const searchInput = document.querySelector(searchInputSelector);
  const resultsWindow = document.querySelector(searchResultsWindowSelector);
  if (!wrapper || !searchInput || !resultsWindow) return;

  const cleanup = trapFocus(wrapper, '.gds-site-search__input', '.gds-search-results li:last-child a');

  const close = function () {
    cleanup();
    if (onClose) onClose();
    if (resultsWindow) resultsWindow.classList.add(hideClass);
    if (searchInput) searchInput.value = "";
  }

  if (onSelection === false) {
    onSelection = function (feedback) {
      feedback.event.preventDefault();
      window.location.href = feedback.selection.value.url;
    }
  }

  searchInput.addEventListener('keydown', function (event) {
    if (event.which == KEY_CODE_MAP.ENTER) {
      event.preventDefault();
      try {
        document.querySelector('#autoComplete_list li:first-child a').focus();
      } catch { }
    }
  });

  const searchXHR = new XMLHttpRequest();
  resultsWindow.classList.add(hideClass);
  new autoComplete({
    data: {
      src: async () => {
        if (searchInput.value.length > 1) {
          resultsWindow.classList.remove(hideClass);
        } else {
          resultsWindow.classList.add(hideClass);
        }
        const searchQuery = searchInput.value;
        var requestURL = window.location.origin
          + '/wp-json/wp/v2/search'
          + '?search=' + encodeURIComponent(searchQuery)
          + '&per_page=' + 10
          + '&' + new Date().getTime();
        var viewAllURL = window.location.origin + '/?s=' + encodeURIComponent(searchQuery);

        if (additionalQueryStrings) {
          requestURL = requestURL + additionalQueryStrings[0];
          viewAllURL = viewAllURL + additionalQueryStrings[1];
        }

        var results = await makeAjaxRequest('GET', requestURL, searchXHR);
        var numResults = 0;
        if (results.length) {
          numResults = results.length;
          results = results.slice(0, 10);
          if (sortResults) results.sort((a, b) => a.title.localeCompare(b.title));
          results.push({ 'id': 0, 'url': viewAllURL, 'title': 'View All >' });
        }
        var resultsCount = resultsWindow.querySelector('[data-role=count]');
        if (resultsCount) {
          resultsCount.setAttribute('data-count', numResults);
          if (numResults > 0) {
            resultsCount.innerHTML = getResultCountText(numResults);
          } else {
            resultsCount.innerHTML = 'No results found, please try something else.';
          }
        }

        document.onkeydown = function (evt) {
          evt = evt || window.event;
          if (evt.which == KEY_CODE_MAP.ESC) {
            close();
          }
        };

        return results;
      },
      key: ["title"],
      cache: false
    },
    selector: searchInputSelector,
    resultsList: {
      render: true,
      destination: resultsWindow,
      position: 'beforeend',
      container: source => {
        source.classList.add('gds-search-results');
        source.setAttribute('data-role', 'search-results');
      },
      element: "ul",
      highlight: true,
    },
    maxResults: 100,
    searchEngine: (query, record) => { return record },
    onSelection: onSelection,
    resultItem: {
      content: (data, source) => {
        source.innerHTML = '<a href="' + data.value.url + '">' + data.match + '</a>';
      },
      element: "li"
    },
  });
}
