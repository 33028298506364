<template>
  <span>
    <button :disabled="disabled" @click="goBack()" class="date-navigation__button gds-button gds-button-outline gds-compact">
      <svg class="gds-icon black shift-left">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left" />
      </svg>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButtonBack",
  props: {
    goBack: Function,
    disabled: Boolean
  }
};
</script>

<style scoped>
</style>