<template>
  <div class="my-account__login-page-wrap account-creation">
    <section v-if="currentPage === 'invalid-logout'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-1 gds-space-stack-l">You must log out to create a new account.</div>
    </section>
    <section v-if="currentPage === 'welcome'" class="my-account__login-form-wrap account-creation">
      <div class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/creation-success.svg" alt="welcome" />
      </div>

      <h2 class="gds-align--text-center gds-space-stack-ml" :class="welcomeFirstName.length > 20 ? 'gds-display-1' : 'gds-display-2'">Welcome {{welcomeFirstName}}!</h2>
      <div
        class="gds-font-size-l gds-space-stack-l gds-align--text-center"
      >Welcome to Green Mountain Power. Your online account is ready to set up. Please follow the next steps to get started.</div>

      <div class="global-panel__form">
        <button @click="ClickNext()" type="button" class="gds-button">
          <span class="gds-button__text">Set Up My Account</span>
        </button>
      </div>
    </section>
    <section v-if="currentPage === 'create-account'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-2 gds-space-stack-l">Create Account</div>
      <div class="gds-font-size-l gds-space-stack-m">
        Already have an account?
        <a href="/account">Log In</a>
      </div>
      <form data-vv-scope="page-create-account" class="global-panel__form onboarding-form" @submit.prevent>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">First Name *</span>
            <the-mask
              v-model="newUser.firstName"
              v-validate="'required|max:50'"
              maxlength="50"
              name="first name"
              key="page-create-account first name"
              class="gds-input-field__input"
              type="text"
              autocomplete="given-name"
              :tokens="noNumbersNoSpecialCharacters"
              :mask="'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk'"
              :aria-invalid="errors.first('first name', 'page1')?true:false"
            />
          </label>
          <div v-if="errors.first('first name', 'page-create-account')" class="validation-error">{{ errors.first("first name", "page-create-account")}}</div>
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Last Name *</span>
            <the-mask
              v-model="newUser.lastName"
              v-validate="'required|max:50'"
              maxlength="50"
              name="last name"
              key="page-create-account last name"
              class="gds-input-field__input"
              type="text"
              autocomplete="family-name"
              :tokens="noNumbersNoSpecialCharacters"
              :mask="'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk'"
              :aria-invalid="errors.first('last name', 'page1')?true:false"
            />
          </label>
          <div v-if="errors.first('last name', 'page-create-account')" class="validation-error">{{ errors.first("last name", "page-create-account")}}</div>
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Your GMP Account Number *</span>
            <input
              v-model="newUser.accountNumber"
              v-mask="'###########'"
              v-validate="'required|numeric|length:10,11'"
              maxlength="11"
              name="account number"
              key="page-create-account account number"
              class="gds-input-field__input"
              type="text"
              required
              aria-required="true"
            />
          </label>
          <div v-if="errors.first('account number', 'page-create-account')" class="validation-error">{{ errors.first("account number", "page-create-account")}}</div>
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Account Nickname (Optional)</span>
            <the-mask
              v-model="newUser.accountNickname"
              v-validate="'max:30'"
              maxlength="30"
              name="account nickname"
              key="page-create-account account nickname"
              class="gds-input-field__input"
              type="text"
              :tokens="nicknameAllowedCharacters"
              :mask="'CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC'"
            />
          </label>
          <div v-if="errors.first('account nickname', 'page-create-account')" class="validation-error">{{ errors.first("account nickname", "page-create-account")}}</div>
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Phone (Last 4 Digits) *</span>
            <input
              v-model="newUser.phone"
              v-validate="'required|numeric|min:4|max:4'"
              maxlength="4"
              name="phone number"
              key="page-create-account phone number"
              class="gds-input-field__input"
              type="tel"
              required
              aria-required="true"
            />
            <div v-if="errors.first('phone number', 'page-create-account')" class="validation-error">{{ errors.first("phone number", "page-create-account")}}</div>
          </label>
        </fieldset>

        <div v-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>
        <div class="gds-flex-container gds-space-stack-m">
          <button @click="ClickNext()" :disabled="pendingNext" :class="{ 'gds-loading': pendingNext }" type="button" class="gds-button">
            <span class="gds-button__text">Next</span>
          </button>
        </div>
      </form>
    </section>
    <section v-if="currentPage === 'set-email'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-2 gds-space-stack-l">Set Your Email and Password</div>

      <form data-vv-scope="page-set-email" class="global-panel__form onboarding-form" @submit.prevent>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Email Address *</span>
            <input
              v-model="newUser.email"
              v-validate="'required|email|max:74'"
              name="email address"
              key="page-set-email email address"
              class="gds-input-field__input"
              type="email"
              spellcheck="false"
              autocapitalize="none"
              autocomplete="email"
              required
              aria-required="true"
              :tokens="allowedCharactersNoSpaces"
              :mask="'cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc'"
            />
          </label>
          <div v-if="errors.first('email address', 'page-set-email')" class="validation-error">{{ errors.first("email address", "page-set-email")}}</div>
        </fieldset>

        <fieldset class="gds-fieldset onboarding__password-requirements-fieldset">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Password *</span>
            <widget-password-input
              ref="password"
              v-model="newUser.password1"
              v-validate.initial.continues="'required|length:7,30|hasupperandlower|hasnumber'"
              data-vv-validate-on="input"
              name="password"
              key="page-set-email password"
            />
          </label>
          <password-requirements :validationErrors="errors" scope="page-set-email" />
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Confirm Password *</span>
            <widget-password-input
              v-model="newUser.password2"
              v-validate="'required|confirmed:password'"
              data-vv-validate-on="input"
              name="password confirmation"
              key="page-set-email password confirmation"
            />
          </label>
          <div v-if="errors.first('password confirmation', 'page-set-email')" class="validation-error">{{ errors.first("password confirmation", "page-set-email")}}</div>
        </fieldset>

        <div v-if="errorNext === 'DUPLICATE_EMAIL'">
          <div class="form-message-box form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text-error gds-space-stack-m">A user with this email already exists.</div>
          </div>
          <div class="form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text gds-space-stack-m">
              Are you trying to add this service account to your existing user profile?
              <a class="gds-link" href="/user-profile/accounts">Log In to Continue</a> and go to "Linked Accounts" in the username menu.
            </div>
          </div>
        </div>
        <div v-else-if="errorNext === 'DUPLICATE_EMAIL_AND_ACCOUNT'">
          <div class="form-message-box form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text-error gds-space-stack-m">A user with this email already exists.</div>
          </div>
          <div class="form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text gds-space-stack-m">
              Do you need help logging in to your account?
              <a class="gds-link" href="/account/forgot">Forgot Password</a>.
            </div>
          </div>
        </div>
        <div v-else-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>

        <div class="onboarding-form__two-button-wrap gds-flex-container gds-flex-container--nowrap">
          <button @click="ClickBack()" type="button" class="gds-button gds-secondary">Back</button>
          <button @click="ClickNext()" :disabled="pendingNext" :class="{ 'gds-loading': pendingNext }" type="button" class="gds-button">
            <span class="gds-button__text">Next</span>
          </button>
        </div>
      </form>
    </section>
    <section v-if="currentPage === 'already-set-up'" class="my-account__login-form-wrap account-creation">
      <div class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/creation-success.svg" alt="welcome" />
      </div>

      <h2 class="gds-align--text-center gds-space-stack-ml gds-display-2">Welcome back!</h2>
      <div class="gds-font-size-l gds-space-stack-l gds-align--text-center">You've already set up your account. Please log in to continue.</div>
      <div class="global-panel__form">
        <button @click="ClickNext()" type="button" class="gds-button">
          <span class="gds-button__text">Log In</span>
        </button>
      </div>
    </section>
    <section v-if="currentPage === 'bad-link'" class="my-account__login-form-wrap account-creation">
      <div class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/not-authorized.svg" alt="welcome" />
      </div>

      <h2 class="gds-align--text-center gds-space-stack-ml gds-display-1">There was a problem setting up your account.</h2>
      <div
        class="gds-font-size-l gds-space-stack-l gds-align--text-center"
      >The link provided may have expired or be invalid. If you copied the link, please try again. Otherwise set up your account manually.</div>
      <div class="global-panel__form">
        <button @click="ClickNext()" type="button" class="gds-button">
          <span class="gds-button__text">Go to Manual Setup</span>
        </button>
      </div>
    </section>
    <section v-if="currentPage === 'verify-account'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-2 gds-space-stack-l gds-align--text-center">Please verify your account information</div>
      <div class="gds-font-size-l gds-space-stack-l gds-align--text-center">This is the account information we have on file for you. Does this look correct?</div>

      <div class="onboarding__account-row gds-space-stack-l">
        <div class="onboarding__account-row--profile-pic small-round-img"></div>
        <div>
          <div class="address gds-linebreaks gds-font-demi">{{magicLink.address | addressFormat({ separator: "\n", skipFirst: false })}}</div>
          <div>Acct #: {{ newUser.accountNumber }}</div>
        </div>
      </div>

      <div v-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>
      <div class="gds-space-stack-m global-panel__form">
        <button @click="ClickNext()" type="button" class="gds-button account-creation__button-margin">
          <span class="gds-button__text">Yes, Looks Correct</span>
        </button>
        <button @click="ClickBack()" type="button" class="gds-button gds-secondary">
          <span class="gds-button__text">This Doesn't Look Right</span>
        </button>
      </div>
    </section>
    <section v-if="currentPage === 'set-email-magic'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-2 gds-space-stack-l">Set Your Email and Password</div>
      <div class="gds-font-size-l gds-space-stack-l">
        Already have a GMP Online Account?
        <br />
        <a @click="errorNext = undefined; GoToPage('login-magic')" href="javascript:void(0);" class="gds-link gds-link--bold">Sign In to Continue</a>
      </div>

      <form data-vv-scope="page-set-email-magic" class="global-panel__form onboarding-form" @submit.prevent>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Email Address *</span>
            <input
              v-model="newUser.email"
              v-validate="'required|email'"
              name="email address"
              key="page-set-email-magic email address"
              class="gds-input-field__input"
              type="email"
              spellcheck="false"
              autocapitalize="none"
              autocomplete="email"
              required
              aria-required="true"
            />
          </label>
          <div v-if="errors.first('email address', 'page-set-email-magic')" class="validation-error">{{ errors.first("email address", "page-set-email-magic")}}</div>
        </fieldset>

        <fieldset class="gds-fieldset onboarding__password-requirements-fieldset">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Password *</span>
            <widget-password-input
              ref="password"
              v-model="newUser.password1"
              v-validate.initial.continues="'required|length:7,30|hasupperandlower|hasnumber'"
              data-vv-validate-on="input"
              name="password"
              key="page-set-email-magic password"
            />
          </label>
          <password-requirements :validationErrors="errors" scope="page-set-email-magic" />
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Confirm Password *</span>
            <widget-password-input
              v-model="newUser.password2"
              v-validate="'required|confirmed:password'"
              data-vv-validate-on="input"
              name="password confirmation"
              key="page-set-email-magic password confirmation"
            />
          </label>
          <div v-if="errors.first('password confirmation', 'page-set-email-magic')" class="validation-error">{{ errors.first("password confirmation", "page-set-email-magic")}}</div>
        </fieldset>

        <div v-if="errorNext === 'DUPLICATE_EMAIL'">
          <div class="form-message-box form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text-error gds-space-stack-m">A user with this email already exists.</div>
          </div>
          <div class="form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text gds-space-stack-m">
              Are you trying to add this service account to your existing user profile?
              <a
                @click="errorNext = undefined; GoToPage('login-magic')"
                href="javascript:void(0);"
                class="gds-link"
              >Log In to Continue</a> and go to "Linked Accounts" in the username menu.
            </div>
          </div>
        </div>
        <div v-else-if="errorNext === 'DUPLICATE_EMAIL_AND_ACCOUNT'">
          <div class="form-message-box form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text-error gds-space-stack-m">A user with this email already exists.</div>
          </div>
          <div class="form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l">
            <div class="gds-helper-text gds-space-stack-m">
              Do you need help logging in to your account?
              <a class="gds-link" href="/account/forgot">Forgot Password</a>.
            </div>
          </div>
        </div>
        <div v-else-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>

        <div class="gds-flex-container gds-space-stack-m">
          <button @click="ClickNext()" :disabled="pendingNext" :class="{ 'gds-loading': pendingNext }" type="button" class="gds-button">
            <span class="gds-button__text">Next</span>
          </button>
        </div>
      </form>
    </section>
    <section v-if="currentPage === 'login-magic'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-2 gds-space-stack-l">Log in to Continue</div>
      <div class="gds-font-size-l gds-space-stack-l">
        <div class="gds-space-stack-s">If you already have an online account, log in to continue setup. Don't have a GMP Online Account?</div>
        <a @click="errorNext = undefined; GoToPage('set-email-magic')" href="javascript:void(0);" class="gds-link gds-link--bold">Create a new account</a>
      </div>

      <form data-vv-scope="page-login-magic" class="global-panel__form onboarding-form" @submit.prevent>
        <fieldset class="gds-fieldset gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Username or Email</span>
            <input
              v-model="newUser.email"
              v-validate="'required'"
              name="username or email"
              key="page-login-magic username or email"
              class="gds-input-field__input"
              type="text"
              spellcheck="false"
              autocapitalize="none"
              autocomplete="email"
              required
              aria-required="true"
            />
          </label>
          <div v-if="errors.first('username or email', 'page-login-magic')" class="validation-error">{{ errors.first("username or email", "page-login-magic")}}</div>
        </fieldset>

        <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errors.first('password', 'page-login-magic') }">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Password</span>
            <widget-password-input v-model="newUser.password1" autocomplete="current-password" v-validate="'required'" name="password" key="page-login-magic password" />
          </label>
          <div v-if="errors.first('password', 'page-login-magic')" class="validation-error">{{ errors.first("password", "page-login-magic")}}</div>
        </fieldset>

        <div class="global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l">
          <div class="global-login-panel__form-helper">
            <label class="gds-checkbox gds-checkbox-thin">
              <input v-model="stayloggedin" type="checkbox" />
              <span class="gds-checkbox__faux"></span>
              <span class="gds-checkbox__label gds-display-00 gds-text-grey">Stay Logged In</span>
            </label>
          </div>

          <div class="global-login-panel__form-helper">
            <a href="/account/forgot" class="gds-display-00 gds-link gds-link--bold">Forgot Password?</a>
          </div>
        </div>

        <div
          v-if="errorNext === 'AUTHENTICATION_FAILURE'"
          class="form-message-box form-message-box--full-width gds-space-stack-l"
        >The email or username and password provided are incorrect.</div>
        <div v-else-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>

        <div class="gds-flex-container gds-space-stack-m">
          <button @click="ClickNext()" :disabled="pendingNext" :class="{ 'gds-loading': pendingNext }" type="button" class="gds-button">
            <span class="gds-button__text">Log In</span>
          </button>
        </div>
      </form>
    </section>
    <section v-if="currentPage === 'add-service-account'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-2 gds-space-stack-l gds-align--text-center">Add Service Account</div>
      <div class="gds-font-size-l gds-space-stack-l">
        You're signed in as
        <span class="gds-font-demi gds-email-word-break">{{ userInfo.email }}</span> and we can link this service account to your user profile. If you'd like to create a new user, log out below.
      </div>

      <div class="onboarding__account-row gds-space-stack-l">
        <div class="onboarding__account-row--profile-pic small-round-img"></div>
        <div>
          <div class="address gds-linebreaks gds-font-demi">{{magicLink.address | addressFormat({ separator: "\n", skipFirst: false })}}</div>
          <div>Acct #: {{ newUser.accountNumber }}</div>
        </div>
      </div>

      <div v-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>
      <div class="gds-space-stack-m global-panel__form">
        <button
          @click="ClickNext()"
          :disabled="pendingNext"
          :class="{ 'gds-loading': pendingNext }"
          type="button"
          class="gds-button account-creation__button-margin gds-space-stack-m"
        >
          <span class="gds-button__text">Link Account</span>
        </button>
        <button @click="ClickBack()" type="button" class="gds-button gds-secondary">
          <span class="gds-button__text">Log Out</span>
        </button>
      </div>
    </section>
    <section v-if="currentPage === 'external-linked'" class="my-account__login-form-wrap account-creation">
      <div class="gds-display-2 gds-space-stack-l gds-align--text-center">We've successfully linked the service account</div>
      <div class="gds-font-size-l gds-space-stack-l">The service account was successfully linked to your user profile. On the next page we'll set up a few things for the account.</div>

      <div class="onboarding__account-row gds-space-stack-l">
        <div class="onboarding__account-row--profile-pic small-round-img"></div>
        <div>
          <div class="address gds-linebreaks gds-font-demi">{{currentAccount.address | addressFormat({ separator: "\n", skipFirst: false })}}</div>
          <div>Acct #: {{ currentAccount.accountNumber }}</div>
        </div>
      </div>

      <div v-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>
      <div class="gds-space-stack-m global-panel__form">
        <button
          @click="ClickNext()"
          :disabled="pendingNext"
          :class="{ 'gds-loading': pendingNext }"
          type="button"
          class="gds-button account-creation__button-margin gds-space-stack-m"
        >
          <span class="gds-button__text">Next</span>
        </button>
      </div>
    </section>
    <section v-if="currentPage === 'enrollment'" class="my-account__login-form-wrap enrollment">
      <div class="enrollment__headline gds-display-2 gds-align--text-center gds-space-stack-l">Let's set up a few things for your account.</div>
      <form data-vv-scope="page-enrollment" class="global-panel__form onboarding-form" @submit.prevent>
        <div class="enrollment-panel__flex gds-flex-container gds-flex-container--top gds-space-stack-xl">
          <div class="enrollment-panel__flex--icon enrollment-panel__flex--icon-plug">
            <img src="/wp-content/themes/gmptwentynineteen/assets/images/outages-notifications.svg" />
          </div>
          <div class="enrollment-panel__flex--text">
            <div class="enrollment-panel__subheader gds-space-stack-s">Service Notifications</div>
            <div class="gds-text-grey gds-space-stack-m">
              Choose the notifications you'd like to receive. Notifications will be sent to
              <span class="gds-font-demi">{{userInfo.email}}</span>, you can make changes later on the notifications page.
            </div>
            <div class="notifications__row-item-notify-types">
              <div role="group" class="enrollment-panel__flex--text__fieldset gds-fieldset">
                <label v-for="alerttype of enrollment.alerttypes" :key="alerttype.alertTypeId" class="gds-checkbox">
                  <input type="checkbox" v-model="alerttype.subscribed" />
                  <span class="gds-checkbox__faux"></span>
                  <span class="gds-checkbox__label">{{alerttype.name}}</span>
                </label>
              </div>
            </div>
            <div class="gds-font-demi gds-space-stack-s">Add a Cell Phone for Alerts (Optional)</div>
            <fieldset class="gds-fieldset gds-space-stack-s gds-space-inline-default">
              <label class="gds-input-field">
                <the-mask
                  v-model="enrollment.alertPhone"
                  :mask="'+1 (###) ###-####'"
                  v-validate="'min:10'"
                  name="phone number"
                  key="page-enrollment phone number"
                  class="enrollment--input-field gds-input-field__input"
                  type="tel"
                />
              </label>
              <div v-if="errors.first('phone number', 'page-enrollment')" class="validation-error">{{ errors.first("phone number", "page-enrollment")}}</div>
            </fieldset>
          </div>
        </div>
        <div class="enrollment-panel__flex gds-flex-container gds-flex-container--top gds-space-stack-l">
          <div class="enrollment-panel__flex--icon enrollment-panel__flex--icon-paperless">
            <img src="/wp-content/themes/gmptwentynineteen/assets/images/paperless-on-alt.svg" />
          </div>
          <div class="enrollment-panel__flex--text">
            <div class="enrollment-panel__subheader gds-space-stack-s">Enroll in Paperless Billing</div>
            <div class="gds-text-grey gds-space-stack-m">With paperless billing we'll deliver your bill electronically each month.</div>
            <div v-if="enrollment.alreadyPaperless" class="enrollment__complete-checklist--row">
              <img class="gds-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/check-circle-small-cropped@3x.png" />
              <div class="gds-font-demi gds-font-size-l">Paperless Billing is Turned On</div>
            </div>
            <fieldset v-else class="gds-fieldset gds-fieldset--no-left-padding">
              <label class="gds-radio">
                <input v-model="enrollment.enrollPaperless" :value="true" type="radio" />
                <span class="gds-radio__faux"></span>
                <span class="gds-radio__label">Turn on Paperless Billing</span>
              </label>
              <label class="gds-radio">
                <input v-model="enrollment.enrollPaperless" :value="false" type="radio" />
                <span class="gds-radio__faux"></span>
                <span class="gds-radio__label">No thanks, send my bill in the mail.</span>
              </label>
            </fieldset>
          </div>
        </div>
        <div v-if="errorNext" class="form-message-box form-message-box--full-width gds-space-stack-l">{{errorNext}}</div>
        <div class="enrollment-panel__next-button-wrap gds-space-stack-m">
          <button @click="ClickNext()" :disabled="pendingNext" :class="{ 'gds-loading': pendingNext }" type="button" class="gds-button">
            <span class="gds-button__text">Done</span>
          </button>
        </div>
      </form>
    </section>
    <section v-if="currentPage === 'complete'" class="my-account__login-form-wrap account-creation enrollment-complete">
      <div class="enrollment-complete--inner">
        <div class="bill-pay-success__intro-image">
          <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/creation-success.svg" alt="set up complete" />
        </div>

        <h2 class="gds-align--text-center gds-space-stack-l">Set Up Completed</h2>
        <div class="enrollment__complete-checklist gds-space-stack-l">
          <!-- Theoretically only one of these next two will ever show at once -->
          <div v-if="userCreated" class="enrollment__complete-checklist--row">
            <img class="gds-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-without-shadow.svg" />
            <div class="gds-font-demi gds-font-size-l">User Created</div>
          </div>

          <div v-if="accountLinked" class="enrollment__complete-checklist--row">
            <img class="gds-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-without-shadow.svg" />
            <div class="gds-font-demi gds-font-size-l">Account Added</div>
          </div>

          <div v-if="enrollment.enabledNotifications" class="enrollment__complete-checklist--row">
            <img class="gds-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-without-shadow.svg" />
            <div class="gds-font-demi gds-font-size-l">Notifications Enabled</div>
          </div>

          <div v-if="enrollment.enabledPaperless" class="enrollment__complete-checklist--row">
            <img class="gds-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/check-circle-small-cropped@3x.png" />
            <div class="gds-font-demi gds-font-size-l">Paperless Billing Turned On</div>
          </div>
        </div>

        <div class="global-panel__form">
          <a href="/account" class="gds-button">Continue to My Account</a>
        </div>
      </div>
      <div class="enrollment-complete--footer">
        <div class="gds-font-size-l gds-align--text-center">
          If you have additional service accounts with GMP you can
          <a href="/user-profile/accounts" class="gds-link gds-link--bold">add another account</a>.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import JwtDecode from "jwt-decode";

import WidgetPasswordInput from "../WidgetPasswordInput";
import PasswordRequirements from "../generic/PasswordRequirements";
import GMPAPI from "../../services/gmpapi";
import { GMPPhoneNumber } from "../../environment";
import { DumpError } from "../../utilities";
import { isFeatureEnabled } from "../../services/featureflags";


export default {
  name: "PageCreateAccount",
  components: {
    WidgetPasswordInput,
    PasswordRequirements,
  },
  data() {
    return {
      noNumbersNoSpecialCharacters: {
        "k": {
          pattern: /[a-zA-Z 'ÀÁÂÄÆÃÅĀàáâäæãåāaÈÉÊËĒĖĘèéêëēėęÎÏÍĪĮÌîïíīįìÔÖÒÓŒØŌÕôöòóœøōõŸÿ]/,
        },
      },
      nicknameAllowedCharacters: {
        "C": {
          pattern: /[0-9a-zA-Z !@#$%^&*()_+~,./?;:'"-=`|ÀÁÂÄÆÃÅĀàáâäæãåāaÈÉÊËĒĖĘèéêëēėęÎÏÍĪĮÌîïíīįìÔÖÒÓŒØŌÕôöòóœøōõŸÿ]/,
        },
      },
      allowedCharactersNoSpaces: {
        "c": {
          pattern: /[0-9a-zA-Z!@#$%^&*()_+~,./?;:'"-=`|ÀÁÂÄÆÃÅĀàáâäæãåāaÈÉÊËĒĖĘèéêëēėęÎÏÍĪĮÌîïíīįìÔÖÒÓŒØŌÕôöòóœøōõŸÿ]/
        }
      },

      /** Populated if we linked here from "link account" rather than account creation */
      enrollmentAccount: undefined,

      /** Information decoded from the magic link (query parameter "t") */
      magicLink: undefined,
      magicLinkToken: undefined,
      magicChecked: undefined,

      newUser: {
        firstName: "",
        lastName: "",
        email: "",
        accountNumber: "",
        accountNickname: "",
        phone: "",

        password1: "",
        password2: "",
      },

      enrollment: {
        alerttypes: undefined,
        alreadyPaperless: false,
        emailContact: undefined,
        // Have to track sms contacts to compare at the end
        smsContacts: undefined,

        alertPhone: "",
        enrollPaperless: true,

        enabledNotifications: false,
        enabledPaperless: false,
      },

      stayloggedin: true,
      userCreated: false,
      accountLinked: false,

      // Start nowhere, we'll decide where to start in mounted()
      currentPage: undefined,

      // Any page "next" that takes a while will set this true to show a loading indicator
      pendingNext: false,
      // Any page "next" that fails will set this to show an error message
      errorNext: undefined,

      GMPPhoneNumber,
      isOnboardingFeatureEnabled: false
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
    userAccounts() {
      return this.$store.state.user.accounts;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    welcomeFirstName() {
      if (this.magicLink) {
        return this.magicLink.firstName;
      } else if (this.$store.state.userinfo) {
        return this.$store.state.user.userinfo.givenName || this.$store.state.user.userinfo.email;
      } else {
        // Logged out and no magic link - should never happen
        return "Unknown";
      }
    },
  },
  async mounted() {
    try {
      this.isOnboardingFeatureEnabled = await isFeatureEnabled('FF_Onboarding', false);
    } catch (err) {
      this.isOnboardingFeatureEnabled = false;
      DumpError('Error reading feature flag', err);
    }
    
    // Load magic link info if available
    const params = new URLSearchParams(window.location.search);
    if (params.has("enroll")) {
      this.enrollmentAccount = params.get("enroll");
    }
    if (params.has("t")) {
      try {
        // Try to base64 decode and JSON parse the token
        this.magicLink = JwtDecode(params.get("t"));
        this.magicLinkToken = params.get("t");
        this.magicChecked = await GMPAPI.CheckMagicLink(this.magicLinkToken);
        // Copy over fields for new user creation
        this.newUser.email = this.magicLink.email;
        this.newUser.firstName = this.magicLink.firstName;
        this.newUser.lastName = this.magicLink.lastName;
        this.newUser.accountNumber = this.magicLink.accountNumber;
        this.newUser.phone = this.magicLink.phone;
      } catch (err) {
        DumpError("Bad magic link", err);
        this.magicLink = undefined;
        this.magicLinkToken = undefined;
        this.GoToPage("bad-link");
        return;
      }
    }

    // Set initial page
    if (this.isOnboardingFeatureEnabled) {
      if (this.enrollmentAccount) {
        try {
          // Select that account as "current"
          let current = this.userAccounts.find(item => item.accountNumber === this.enrollmentAccount);
          if (!current) {
             throw new Error("Could not find account to select");
          }
          this.$store.commit("setCurrentAccount", current);
          this.accountLinked = true;
          // Show "success" page
          this.GoToPage("external-linked");
        } catch (err) {
          DumpError("Could not select enrollment account", err);
          window.location = "/user-profile/accounts";
        }
      } else if (this.magicLink) {
        // if logged in
        if (this.userInfo) {
          // If both are set, account is already set up so bump to My Account
          if (this.magicChecked.email && this.magicChecked.accountNumber) {
            window.location = "/account?account=" + this.magicChecked.accountNumber;
          } else {
            this.GoToPage("add-service-account");
          }
        } else {
          // If both are set, account is already set up
          if (this.magicChecked.email && this.magicChecked.accountNumber) {
            this.GoToPage("already-set-up");
          } else {
            this.GoToPage("welcome");
          }
        }
      } else {
        if (this.userInfo) {
          // We should never get here if logged in and not taking a magic link
          this.GoToPage("invalid-logout");
        } else {
          this.GoToPage("create-account");
        }
      }
    } else {
      if (this.enrollmentAccount) {
        try {
          // Select that account as "current"
          let current = this.userAccounts.find(item => item.accountNumber === this.enrollmentAccount);
          if (!current) {
            throw new Error("Could not find account to select");
          }
          this.$store.commit("setCurrentAccount", current);
          this.accountLinked = true;
          // Show "success" page
          this.GoToPage("external-linked");
        } catch (err) {
          DumpError("Could not select enrollment account", err);
          window.location = "/user-profile/accounts";
        }
      } else {
        if (this.userInfo) {
          // We should never get here if logged in and not taking a magic link
          this.GoToPage("invalid-logout");
        } else {
          this.GoToPage("create-account");
        }
      }
    }
  },
  methods: {
    // Handle the next buttons - a lot of them go to different pages depending on magic link, logged in, etc.
    async ClickNext() {
      try {
        this.pendingNext = true;
        this.errorNext = undefined;
        switch (this.currentPage) {
          case "welcome":
            if (this.magicLink) {
              this.GoToPage("verify-account");
            } else {
              if (!this.isOnboardingFeatureEnabled) {
                this.GoToPage("complete");
                break;
              }
              await this.LoadEnrollmentData();
            }
            break;
          case "create-account":
            await this.VerifyAccountPhone();
            break;
          case "set-email":
            await this.PerformCreateUser("page-set-email");
            break;
          case "already-set-up":
            window.location = "/account?account=" + this.magicChecked.accountNumber;
            break;
          case "bad-link":
            if (this.userInfo) {
              // If logged in w/ bad link, setup in user profile
              window.location = "/user-profile/accounts";
            } else {
              // Otherwise carry on pretending there was no link
              this.GoToPage("create-account");
            }
            break;
          case "verify-account":
            // If email already exists, login
            if (this.magicChecked && this.magicChecked.email) {
              this.GoToPage("login-magic");
            } else {
              // Otherwise, create
              this.GoToPage("set-email-magic");
            }
            break;
          case "set-email-magic":
            await this.PerformCreateUser("page-set-email-magic");
            break;
          case "login-magic":
            await this.PerformLoginUser();
            break;
          case "add-service-account":
            await this.PerformLinkAccount();
            break;
          case "external-linked":
            if (!this.isOnboardingFeatureEnabled) {
              this.GoToPage("complete");
              break;
            }
            await this.LoadEnrollmentData();
            break;
          case "enrollment":
            await this.PerformEnrollment();
            break;
        }
      } finally {
        this.pendingNext = false;
      }
    },
    async ClickBack() {
      this.errorNext = undefined;
      switch (this.currentPage) {
        case "set-email":
          this.GoToPage("create-account");
          break;
        case "verify-account":
          // Kick to regular acct creation, remove magic link
          window.location = "/account/create";
          break;
        case "add-service-account":
          await this.$store.dispatch("LogOut");
          window.location = "/";
          break;
      }
    },

    /** Wrapper so we always scroll to top when changing page */
    GoToPage(page) {
      // Clear any error messages
      this.errorNext = undefined;
      this.currentPage = page;
      window.scrollTo(0, 0);
    },

    // *** USER CREATION ***
    /** Before moving on from the first Create Account page, we must verify the account and phone number */
    async VerifyAccountPhone() {
      if (await this.$validator.validateAll("page-create-account")) {
        try {
          await GMPAPI.VerifyAccountPhone(this.newUser.accountNumber, this.newUser.phone);
          this.GoToPage("set-email");
        } catch (err) {
          if (err.code === 40) {
            this.errorNext = "The account number and phone number combination could not be verified. Try again or call " + this.GMPPhoneNumber + ".";
          } else {
            this.errorNext = "There was an error verifying your account number. Try again or call " + this.GMPPhoneNumber + ".";
          }
        }
      }
    },
    /** Create a user and link the account - can be used from either magic link or vanilla */
    async PerformCreateUser(validationScope) {
      if (await this.$validator.validateAll(validationScope)) {
        try {
          const body = {
            email: this.newUser.email,
            username: this.newUser.email,
            password: this.newUser.password1,
            givenName: this.newUser.firstName,
            surname: this.newUser.lastName,
            energyAccounts: [
              {
                accountNumber: this.newUser.accountNumber,
                nickname: this.newUser.accountNickname || undefined,
                isPrimary: true,
                accountVerifyValue: this.newUser.phone,
                accountVerifyType: "phone",
              },
            ],
          };
          await this.$store.dispatch("CreateUser", body);
          this.userCreated = true;
          if (!this.isOnboardingFeatureEnabled) {
            this.GoToPage("complete");
            return;
          }
          // User created and account linked, now show the enrollment page
          await this.LoadEnrollmentData();
        } catch (err) {
          if (err && err.response && err.response.data && (err.response.data.errorCode === "USERNAME_TAKEN_MESSAGE" || err.response.data.errorCode === "ACCOUNT_WITH_EMAIL_EXISTS")) {
            // Special case to show a more complicated message with link
            this.errorNext = "DUPLICATE_EMAIL";
          } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_WITH_EMAIL_AND_ACCOUNT_NUMBER_EXISTS") {
            this.errorNext = "DUPLICATE_EMAIL_AND_ACCOUNT";
          } else {
            DumpError("Create account error", err);
            this.errorNext = "There was an error creating your account. Try again or call " + this.GMPPhoneNumber + ".";
          }
        } finally {
          this.pendingNext = false;
        }
      }
    },

    async PerformLinkAccount() {
      try {
        // Add the account
        await GMPAPI.AddUserAccount(this.userInfo.username, this.newUser.accountNumber, this.newUser.phone, undefined);
        // Refresh the user's account list
        await this.$store.dispatch("FetchUserInfo");
        // Select that account as "current"
        let current = this.userAccounts.find(item => item.accountNumber === this.newUser.accountNumber);
        if (!current) {
          throw new Error("Could not find account to select");
        }
        this.$store.commit("setCurrentAccount", current);
        this.accountLinked = true;

        if (!this.isOnboardingFeatureEnabled) {
          this.GoToPage("complete");
          return;
        }
        // Account linked, go to the enrollment page
        await this.LoadEnrollmentData();
      } catch (err) {
        DumpError("Link account error", err);
        this.errorNext = "There was an error linking your account. Try again or call " + this.GMPPhoneNumber + ".";
      }
    },

    async PerformLoginUser() {
      if (await this.$validator.validateAll("page-login-magic")) {
        try {
          await this.$store.dispatch("LoginUser", { username: this.newUser.email, password: this.newUser.password1, stayloggedin: this.stayloggedin });
          this.GoToPage("add-service-account");
        } catch (err) {
          DumpError("User login error", err);
          if (err.response && err.response.data && err.response.data.errorCode === "AUTHENTICATION_FAILURE") {
            this.errorNext = "AUTHENTICATION_FAILURE";
          } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_LOCKOUT_FAILURE") {
            this.errorlogin = err.response.data.message;
          } else {
            this.errorNext = "There was an error logging in. Please try again later.";
          }
        }
      }
    },

    // *** ENROLLMENT ***
    async LoadEnrollmentData() {
      const [contacts, alerttypes, billing] = await Promise.all([
        GMPAPI.GetAlertContacts(this.currentAccount.accountNumber),
        GMPAPI.GetAlertTypes(this.currentAccount.accountNumber),
        GMPAPI.GetBillingStatus(this.currentAccount.accountNumber),
      ]);
      const emailAlerts = alerttypes.filter(alert => alert.channels.find(channel => channel.code === "EMAIL"));
      // Get the contact, if any, that is the user email
      this.enrollment.emailContact = contacts.find(contact => contact.channel.code === "EMAIL" && contact.value === this.userInfo.email);
      this.enrollment.smsContacts = contacts.filter(contact => contact.channel.code === "SMS");
      // If there is a contact, populate alert subscriptions
      if (this.enrollment.emailContact) {
        for (const alert of emailAlerts) {
          const subscription = this.enrollment.emailContact.subscriptions.find(sub => sub.alertType.alertTypeId === alert.alertTypeId);
          if (subscription) {
            // Track what it was originally set to
            alert.subscribedOld = true;
            // Save the subscription ID in case we need to unsubscribe
            alert.subscriptionId = subscription.subscriptionId;
            // This one can be toggled
            alert.subscribed = true;
          } else {
            alert.subscribedOld = false;
            alert.subscribed = false;
          }
        }
      } else {
        // If there is no contact, set all subscriptions to true
        for (const alert of emailAlerts) {
          alert.subscribedOld = false;
          alert.subscribed = true;
        }
      }
      this.enrollment.alerttypes = emailAlerts;
      this.enrollment.alreadyPaperless = !!billing.billRouteInformation.isEBill;
      this.GoToPage("enrollment");
    },
    async PerformEnrollment() {
      if (await this.$validator.validateAll("page-enrollment")) {
        try {
          // *** SUBSCRIPTIONS ***
          const channels = await GMPAPI.GetAlertChannels();
          // If we're going to do subscriptions, we need a contact
          const needsContact = this.enrollment.alerttypes.some(alert => alert.subscribed && !alert.subscribedOld);
          let contact = this.enrollment.emailContact;
          // If needed and no existing contact, create one
          if (needsContact && !this.enrollment.emailContact) {
            const emailChannel = channels.find(channel => channel.code === "EMAIL");
            if (!emailChannel) {
              throw new Error("Email channel not found");
            }
            const contactDetails = {
              channel: {
                channelId: emailChannel.channelId,
              },
              value: this.userInfo.email,
              dndStart: null,
              dndEnd: null,
            }
            contact = await GMPAPI.CreateContact(this.currentAccount.accountNumber, contactDetails);
          }
          // Update subscriptions
          for (const alert of this.enrollment.alerttypes) {
            // If the subscription was changed, update it
            if (alert.subscribed !== alert.subscribedOld) {
              if (alert.subscribed) {
                await GMPAPI.SubscribeAlert(this.currentAccount.accountNumber, contact.contactId, alert.alertTypeId);
                // If any notifications get enabled, show so on the last page
                this.enrollment.enabledNotifications = true;
              } else {
                await GMPAPI.UnSubscribeAlert(this.currentAccount.accountNumber, alert.subscriptionId);
              }
            }
          }

          // Cell phone subscriptions
          if (this.enrollment.alertPhone) {
            // If we already have an SMS contact that matches, let's delete it and start from scratch
            const existingContact = this.enrollment.smsContacts.find(contact => contact.channel.code === "SMS" && contact.value === ("+1" + this.enrollment.alertPhone));
            if (existingContact) {
              await GMPAPI.DeleteContact(this.currentAccount.accountNumber, existingContact.contactId);
            }
            // Create the new SMS contact
            const smsChannel = channels.find(channel => channel.code === "SMS");
            if (!smsChannel) {
              throw new Error("SMS channel not found");
            }
            const smsDetails = {
              channel: {
                channelId: smsChannel.channelId,
              },
              value: this.enrollment.alertPhone,
              dndStart: null,
              dndEnd: null,
            }
            const smsContact = await GMPAPI.CreateContact(this.currentAccount.accountNumber, smsDetails);
            // Activate subscriptions
            for (const alert of this.enrollment.alerttypes) {
              if (alert.subscribed && alert.channels.find(channel => channel.code === "SMS")) {
                await GMPAPI.SubscribeAlert(this.currentAccount.accountNumber, smsContact.contactId, alert.alertTypeId);
                // If any notifications get enabled, show so on the last page
                this.enrollment.enabledNotifications = true;
              }
            }
          }


          // *** PAPERLESS BILLING ***
          if (!this.enrollment.alreadyPaperless && this.enrollment.enrollPaperless) {
            const paperlessSettings = {
              billRouteInformation: {
                isEBill: true,
                emailAddress: this.userInfo.email,
              },
            };
            await GMPAPI.UpdateBillingStatus(this.currentAccount.accountNumber, paperlessSettings);
            this.enrollment.enabledPaperless = true;
          }
          this.GoToPage("complete");
        } catch (err) {
          DumpError("Account setup error", err);
          this.errorNext = "There was an error updating your account. Try again or call " + GMPPhoneNumber + ".";
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>