import { parseISO, isToday, subDays, isWithinInterval } from "date-fns";
import { ToServerDateTruncate } from "../../utilities";
import GMPAPI from "../../services/gmpapi";

export default {
  data() {
    return {
      previousBillingCycleDates: undefined,
      currentBillingCycleDates: undefined,
      previousBillingCycleDailyUsageData: undefined,
      currentBillingCycleDailyUsageData: undefined,
      dailyUsageData: undefined,
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  methods: {
    async getBillingCycleDates() {
      const data = await GMPAPI.GetBillingPeriods(this.currentAccount.accountNumber);
      data.periods.forEach(period => {
        if (period.current) { this.currentBillingCycleDates = period; }
        if (period.last) { this.previousBillingCycleDates = period; }
      });

      if (isToday(parseISO(this.currentBillingCycleDates.startDate))) {
        this.currentBillingCycleDates = this.previousBillingCycleDates;
        const searchDateForNewPrevious = subDays(parseISO(this.previousBillingCycleDates.startDate), 1);

        data.periods.forEach(period => {
          if (isWithinInterval(searchDateForNewPrevious, {
            start: parseISO(period.startDate),
            end: parseISO(period.endDate)
          })) {
            this.previousBillingCycleDates = period;
          }
        });
      }
    },
    async getDailyPeriodUsageRateData(rate) {
      const endDate = new Date();
      let startDate = undefined;
      if (!this.previousBillingCycleDates || !this.previousBillingCycleDates.startDate) {
        startDate = new Date();
      } else {
        startDate = parseISO(this.previousBillingCycleDates.startDate);
      }

      if (!this.dailyUsageData) {
        this.dailyUsageData = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, "daily", ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
      }
      this.currentUsageData = true;
      this.loadState = 'complete';

      if (this.dailyUsageData.intervals[0].values.length === 0) {
        this.loadState = 'unavailable';
      }

      this.previousBillingCycleDailyUsageData = this.parseDailyUsageData(this.dailyUsageData, parseISO(this.previousBillingCycleDates.startDate), parseISO(this.previousBillingCycleDates.endDate));
      this.currentBillingCycleDailyUsageData = this.parseDailyUsageData(this.dailyUsageData, parseISO(this.currentBillingCycleDates.startDate), parseISO(this.currentBillingCycleDates.endDate));
      const previousRateData = this.getCumulativeUsageRateDataSum(this.previousBillingCycleDailyUsageData, rate);
      const currentRateData = this.getCumulativeUsageRateDataSum(this.currentBillingCycleDailyUsageData, rate);
      return { previousRateData: previousRateData, currentRateData: currentRateData }
    },
    parseDailyUsageData(dailyUsageData, startDate, endDate) {
      const filteredArr = dailyUsageData.intervals[0].values.filter(value => {
        const date = value.date;
        return date >= startDate && date <= endDate;
      });
      return filteredArr;
    },
    getCumulativeUsageRateDataSum(usageData, rate) {
      let cumulativeArray = [];
      let cumulativeSum = 0;
      for (let i = 0; i < usageData.length; i++) {
        if (usageData[i][rate]) {
          cumulativeSum += usageData[i][rate];
          cumulativeArray.push(cumulativeSum);
        }
      }
      return cumulativeArray;
    },
  }
};
