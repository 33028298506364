export default {
  data() {
    return {
     
    }
  },


  methods: {
    buildIncomeDropDown(incomeIncentives, numberInHousehold) {
      let householdIncomeDropdown = [];

      if (incomeIncentives && incomeIncentives.length > 0) {
        incomeIncentives.sort((a, b) => a.numberInHousehold - b.numberInHousehold);
        const incomeIncentive = incomeIncentives.find(incomeIncentive => incomeIncentive.numberInHousehold === numberInHousehold);

        if (incomeIncentive) {
          const lowerRangeAmount = "$" + incomeIncentive.incomeLimit.toLocaleString() + " or less ";
          const upperRangeAmount = "Greater than $" + incomeIncentive.incomeLimit.toLocaleString();
          householdIncomeDropdown.push({
            incomeOption: lowerRangeAmount,
            incomeAmount: lowerRangeAmount
          });
          householdIncomeDropdown.push({
            incomeOption: upperRangeAmount,
            incomeAmount: upperRangeAmount
          });
        }
      }
      return householdIncomeDropdown;
    },

    updateHouseholdIncome(evt) {
        this.productSubmission.rebate.householdIncome.numberInHousehold = evt.numberInHousehold;
        this.productSubmission.rebate.householdIncome.grossHouseholdIncomeRange = evt.grossHouseholdIncomeRange;
    },

    onChangeWillShareIncome(event) {
      this.productSubmission.rebate.householdIncome.numberInHousehold = undefined;
      this.clearHouseholdIncome();
    },

    clearHouseholdIncome() {
      this.productSubmission.rebate.householdIncome.grossHouseholdIncomeRange = undefined;
    },

  }
}