<template>
  <div class="bill-pay-login__phone-address-inner">
    <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Report an Outage by Phone</div>
    <div class="gds-font-size-m gds-space-stack-m">{{ GMPPhoneNumber }}</div>
    <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Report an Outage by Text</div>
    <div class="gds-font-size-m">Text "OUT" to 46788 to report an outage by text.*</div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
export default {
  name: "ManualReportingBox",
  data() {
    return {
      GMPPhoneNumber,
    };
  },
};
</script>

<style scoped>
</style>