var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "my-account__modal-box" },
          [
            _c("FocusLock", [
              _c("header", { staticClass: "my-account__modal-box--header" }, [
                _c("img", {
                  staticClass: "my-account__modal-box--icon",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/found-address.svg",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "close bill-pay__close gds-button gds-button-circle gds-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.Close()
                      }
                    }
                  },
                  [
                    _c("svg", { staticClass: "gds-icon close-icon" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                        }
                      })
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "my-account__modal-box--body" }, [
                _c("div", { staticClass: "my-account__modal-box--headline" }, [
                  _vm._v("Review your address")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__modal-box--message gds-space-stack-l"
                  },
                  [
                    !_vm.addresses
                      ? _c("div", [
                          _vm._v(
                            "We couldn't find a match for that address. You can use the address as entered, or go back and make a change."
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.addresses && _vm.addresses.length === 1
                      ? _c("div", [
                          _vm._v(
                            "We've found a similar address to the one you've entered. Please select an option below."
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.addresses && _vm.addresses.length > 1
                      ? _c("div", [
                          _vm._v(
                            "We've found several similar addresses to the one you've entered. Please select an option below."
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "fieldset",
                  { staticClass: "fieldset-no-border gds-space-stack-l" },
                  [
                    _vm._l(_vm.addresses, function(address, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "my-account__modal-box--radio-group gds-space-stack-m"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "gds-radio bill-pay-login__form--radio-label-flex"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedAddress,
                                    expression: "selectedAddress"
                                  }
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: address,
                                  checked: _vm._q(_vm.selectedAddress, address)
                                },
                                on: {
                                  change: function($event) {
                                    _vm.selectedAddress = address
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-radio__faux" }),
                              _vm._v(" "),
                              address.text
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "gds-radio__label bill-pay-login__radio-label"
                                    },
                                    [_vm._v(_vm._s(address.text))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              address.fields
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "gds-radio__label bill-pay-login__radio-label"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f(
                                            "addressExpFormat"
                                          )(address.fields, { mailing: true })
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "my-account__modal-box--radio-group" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "gds-radio bill-pay-login__form--radio-label-flex"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedAddress,
                                  expression: "selectedAddress"
                                }
                              ],
                              attrs: { type: "radio" },
                              domProps: {
                                value: _vm.original,
                                checked: _vm._q(
                                  _vm.selectedAddress,
                                  _vm.original
                                )
                              },
                              on: {
                                change: function($event) {
                                  _vm.selectedAddress = _vm.original
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "gds-radio__faux" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "gds-radio__label bill-pay-login__radio-label"
                              },
                              [_vm._v("Use address as entered")]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "gds-flex-container gds-flex-container--left"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "gds-button gds-secondary gds-space-inline-m",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.Close()
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "gds-button",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.Save()
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }