var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "powerwall-installer-widget" },
    [
      _vm.towns
        ? _c("type-ahead", {
            attrs: {
              array: _vm.towns,
              type: "town",
              placeholderText: "Type your town name to get started..."
            },
            on: {
              resetList: _vm.clearInstallerList,
              showSelectValidItem: _vm.handleSelectValidTownMessage
            },
            model: {
              value: _vm.selectedTown,
              callback: function($$v) {
                _vm.selectedTown = $$v
              },
              expression: "selectedTown"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.displayNoInstallerMessage
        ? _c("div", { staticClass: "gds-body-normal" }, [
            _vm._v(
              "If your town does not show any results, please check the spelling, try a neighboring town or call for more details."
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.filteredInstallers, function(installer, i) {
        return _c(
          "div",
          { key: i },
          [
            _c("slat-powerwall-installer-vue", {
              attrs: { installer: installer }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }