var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.validUsageNotificationTypeCodes.length > 0
    ? _c("div", [
        _c("h2", { staticClass: "gds-space-top-xl gds-space-stack-l" }, [
          _vm._v("Usage Notifications")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gds-position--relative" }, [
          _vm.loadingData
            ? _c("div", { staticClass: "usage-notification__spinner" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.loadingData && _vm.apiError
            ? _c(
                "div",
                { staticClass: "usage-notification__error-container" },
                [_vm._v("Unable to load, please try again later.")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.loadingData &&
          !_vm.apiError &&
          _vm.alertTypes &&
          _vm.alertTypes.length > 0
            ? _c(
                "div",
                { staticClass: "usage-notification__widget-container" },
                _vm._l(_vm.validUsageNotificationTypeCodes, function(
                  alertType
                ) {
                  return _c(
                    "div",
                    {
                      key: alertType.name,
                      staticClass:
                        "usage-notification__alert-container gds-position--relative"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "usage-notification__alert-image" },
                        [
                          _c("img", {
                            staticClass: "usage-notification__alert-image",
                            attrs: {
                              src: alertType.icon,
                              alt: "usage alert image"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "usage-notification__alert-content" },
                        [
                          _c(
                            "span",
                            { staticClass: "usage-notification__alert-name" },
                            [_vm._v(_vm._s(alertType.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "usage-notification__alert-description"
                            },
                            [_vm._v(_vm._s(alertType.description))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "usage-notification__alert-description-short"
                            },
                            [_vm._v(_vm._s(alertType.descriptionShort))]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "usage-notification__alert-indicator"
                            },
                            [
                              _c("IndicatorOnOff", {
                                attrs: { value: alertType.active }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(0)
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "usage-notification__manage-notifications-container" },
      [
        _c("div", { staticClass: "gds-align--text-center" }, [
          _c(
            "a",
            {
              staticClass:
                "gds-card__button gds-button gds-round gds-button-outline gds-compact",
              attrs: { href: "/account/notifications" }
            },
            [_vm._v("Manage Notifications")]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }