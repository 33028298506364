var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-modal" }, [
    _c(
      "div",
      {
        staticClass:
          "my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail"
      },
      [
        _c(
          "div",
          { staticClass: "usage-detail-mobile__modal-nav-select__header" },
          [
            _c(
              "span",
              { staticClass: "usage-detail-mobile__modal-nav-select__title" },
              [_vm._v(_vm._s(_vm.modalTitle))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "close bill-pay__close gds-button gds-button-circle gds-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [
                _c("svg", { staticClass: "gds-icon close-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("FocusLock", [
          _c("div", { staticClass: "usage-detail-mobile__modal__body" }, [
            _c("div", [
              _c(
                "fieldset",
                { staticClass: "gds-fieldset" },
                _vm._l(_vm.config.options, function(option, i) {
                  return _c(
                    "label",
                    {
                      key: i,
                      staticClass: "radio-label-flex gds-radio",
                      class: {
                        selected: _vm.optionIsSelected(
                          option.value,
                          _vm.selectedOption
                        )
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedOption,
                            expression: "selectedOption"
                          }
                        ],
                        attrs: { type: "radio" },
                        domProps: {
                          value: option.value,
                          checked: _vm._q(_vm.selectedOption, option.value)
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.selectedOption = option.value
                            },
                            _vm.handleOptionClick
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "gds-radio__faux" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "gds-radio__label radio-label" },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(option.label) +
                              "\n              "
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }