var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { attrs: { href: _vm.installer.enrollmentFormUrl, target: "_blank" } },
    [
      _c("div", { staticClass: "slat", attrs: { id: "slat632dffbce2ab0" } }, [
        _c("div", { staticClass: "slat__header" }, [
          _c("div", { staticClass: "slat__cta" }, [
            _c("div", { staticClass: "slat__text-container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "slat__headline\n        slat__headline--bold        "
                },
                [_vm._v("\n            " + _vm._s(_vm.installer.name) + " ")]
              )
            ]),
            _vm._v(" "),
            _c("a", { staticClass: "slat__button slat__button--linked" })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }