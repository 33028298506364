<template>
  <div
  class="net-metering-diagram__wrapper" ref="container">
      <!-- START summary detail and circle -->
      <div class="summary widget-net-metering__gen__summary">
        <div class="widget-net-metering__gen__summary__title gds-space-stack-default">{{summaryGeneration}}</div>
        <div class="widget-net-metering__gen__summary__detail-item">
          <div class="widget-net-metering__gen__summary__detail-item--circle-container">
            <img class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle"
            :src="'/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/' + summaryIconTotalGeneration"
          alt="performance indicator" />
          </div>
            <div
            v-html="summaryTextGeneration"
            class="usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--text">
            </div>
        </div>
      </div>
      <!-- END summary detail and circle -->
   
      <div class="net-metering-diagram">
        <div class="returned-to-grid">
          <div class="value black">{{ generationReturnedToGrid | numberCommaSingleDecimal}} kWh
          </div>
          <div class="label">Returned to Grid</div>
        </div>
        <div class="grid-usage">
          <div class="value black">{{ energyFromGrid | numberCommaSingleDecimal}} kWh</div>
          <div class="label">Consumed from Grid</div>
        </div>
      
    
        <div class="total-generation">
          <div class="value green">{{ totalGeneration | numberCommaSingleDecimal}} kWh</div>
          <div class="label">Total Generation<span class="small"> (how much solar energy you generated)</span></div>
        </div>
        <div class="generation-to-home">
          <div class="value black">{{ generationToHome | numberCommaSingleDecimal}} kWh</div>
          <div class="label">Generation to Home</div>
        </div>
        <div class="total-home-consumption">
          <div class="value brandblue">{{ totalUsage | numberCommaSingleDecimal}} kWh</div>
          <div class="label">Total Home Consumption<span class="small"> (how much power you used, solar and/or grid)</span></div>
        </div>
        <!-- House SVG -->
        <div class="house-graphic">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 725 405">
            <path fill="#fff" fill-rule="evenodd" stroke="#CECECE" stroke-width="12" d="M42 396h348V189H42v207Z" clip-rule="evenodd"/>
            <path fill="#ECECEC" fill-rule="evenodd" d="m347 193-13 38-17 56-24 103h87V193h-33Z" clip-rule="evenodd" />
            <path fill="#22395B" d="M83 2h56v47h357l43-45 177 182-13 13L539 33 387 187l-11 12H11L31 49h52V2Z" />
            <path fill="#fff" fill-rule="evenodd" stroke="#CECECE" stroke-width="12" d="M539 33 380 199h5v197h308V199h5L539 33Z" clip-rule="evenodd" />
            <path fill="#FD872E" d="M185 303c0-22 17-39 38-39 22 0 39 17 39 39v96h-77v-96Zm416 41H478v-16h123v16Z" />
            <path fill="#BAE0F5" d="M565 152c0 16-13 29-28 29-16 0-29-13-29-29s13-28 29-28c15 0 28 12 28 28Zm26 176H488V225h103v103ZM83 292h56v-57H83v57Zm261 0h-56v-57h56v57Z" />
          </svg>
        </div>

        <!-- Solar Panel SVG -->
        <div class="solar-graphic">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 755 464">
            <path fill="#F8BF97" fill-rule="evenodd" d="m140 0-12 31-23-8-7 23-34-9 10 32-24 7 8 22-32 12 27 20-15 19 20 14-19 27 35 2-1 23h25l1 33 29-18 14 19 20-14 21 26 13-31 23 8 7-23 33 9-9-32 24-7-9-22 33-12-27-21 15-18-21-14 20-27-35-2 1-23h-25l-1-33-29 18-15-19-19 14-22-26Z" clip-rule="evenodd"/>
            <path fill="#FD872E" fill-rule="evenodd" d="M229 130c0 36-30 65-67 65s-67-29-67-65c0-35 30-64 67-64s67 29 67 64" clip-rule="evenodd" />
            <path fill="#2C60B3" fill-rule="evenodd" d="M378 424H199l156-266h180L378 424Zm179 0H392l156-266h175L557 424Zm-375 0H8l166-266h166L182 424Z" clip-rule="evenodd" />
            <path fill="#fff" fill-opacity=".1" fill-rule="evenodd" d="M557 424H8l166-266s60 45 167 116c55 36 216 150 216 150Z" clip-rule="evenodd" />
            <path fill="#B8BBBF" fill-rule="evenodd" d="M754 175 576 464H0v-18h565l174-281 15 10Z" clip-rule="evenodd"/>
          </svg>
        </div>

        <!-- Arrow SVGs -->
        <div class="arrow-right-down hide-on-wide-layout">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="62" viewBox="0 0 62 52">
            <path stroke="#A7A7A7" stroke-linecap="square" stroke-width="3.4" d="M2 3h32c10 0 19 8 19 18v20" />
            <path fill="#A7A7A7" d="m53 52-9-11h17l-8 11Z" />
          </svg>
        </div>

        <div class="arrow-down-left hide-on-wide-layout">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="54" viewBox="0 0 54 50">
            <path stroke="#A7A7A7" stroke-linecap="square" stroke-width="3.4" d="M4 41h35c7 0 13-6 13-13v0V2"/>
            <path fill="#A7A7A7" d="m0 41 11-9v17L0 41Z"/>
          </svg>
        </div>
        <div class="arrow-down hide-on-wide-layout">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="18" viewBox="0 0 18 148">
            <path stroke="#A7A7A7" stroke-linecap="square" stroke-width="3.4" d="M9 142V3" />
            <path fill="#A7A7A7" d="m9 148-9-11h17l-8 11Z" />
            </svg>
        </div>  

        <!-- Arrow SVGs Wide Layout -->
        <div class="arrow-right show-on-wide-layout">
          <!--<svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 9C0 13.4183 3.58172 17 8 17C12.4183 17 16 13.4183 16 9C16 4.58172 12.4183 1 8 1C3.58172 1 0 4.58172 0 9ZM8 10.5H29V7.5H8V10.5Z" fill="#A7A7A7" />
          </svg>
          -->

          <svg class="expanding-arrow-stem" width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          <path d="M0 9L24 9" stroke="#A7A7A7" stroke-width="3"/>
          </svg>

          <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 9L12 0.339747L12 17.6603L27 9ZM-1.31134e-07 10.5L13.5 10.5L13.5 7.5L1.31134e-07 7.5L-1.31134e-07 10.5Z" fill="#A7A7A7" />
          </svg>
        </div>

        <div class="arrow-left show-on-wide-layout">

          <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 9L15 17.6603L15 0.339747L0 9ZM13.5 10.5L27 10.5L27 7.5L13.5 7.5L13.5 10.5Z" fill="#A7A7A7"/>
          </svg>

          <svg class="expanding-arrow-stem" width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          <path d="M0 9L24 9" stroke="#A7A7A7" stroke-width="3"/>
          </svg>
          <!--
          <svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 9C13 13.4183 16.5817 17 21 17C25.4183 17 29 13.4183 29 9C29 4.58172 25.4183 1 21 1C16.5817 1 13 4.58172 13 9ZM0 10.5H21V7.5H0V10.5Z" fill="#A7A7A7" />
          </svg>-->

        </div>
        <div class="arrow-right-up-curve show-on-wide-layout">

          <!--<svg width="32" height="63" viewBox="0 0 32 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 54.5C0 58.9183 3.58172 62.5 8 62.5C12.4183 62.5 16 58.9183 16 54.5C16 50.0817 12.4183 46.5 8 46.5C3.58172 46.5 0 50.0817 0 54.5ZM8 56H32V53H8V56Z" fill="#A7A7A7"/>
          </svg>-->
          <svg class="expanding-arrow-stem" width="37" height="63" viewBox="0 0 37 63" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <path d="M0 54.5H37" stroke="#A7A7A7" stroke-width="3"/>
          </svg>
          <svg width="77" height="63" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M68 0L59.3397 15H76.6603L68 0ZM0 56H25V53H0V56ZM25 56C49.5767 56 69.5 36.0767 69.5 11.5H66.5C66.5 34.4198 47.9198 53 25 53V56Z" fill="#A7A7A7" style="fill:#A7A7A7;fill:color(display-p3 0.6554 0.6554 0.6554);fill-opacity:1;"/>
          </svg>
        </div>    
        <div class="disclaimer">
          <p class="small">Your usage data may be slightly off if you have a connected battery back-up.</p>
        </div>
    </div>
  </div>
</template>

<script>
import WidgetNetMeteringMixin from "./WidgetNetMeteringMixin";
import NetMeteringMixin from "./NetMeteringMixin";

export default {
  name: "WidgetNetMeteringMobile",
  mixins: [
    WidgetNetMeteringMixin,
    NetMeteringMixin
  ],
};
</script>
