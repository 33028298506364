var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "password-wrapper" }, [
    _c("input", {
      ref: "input",
      staticClass: "gds-input-field__input",
      attrs: {
        type: _vm.inputType,
        autocomplete: _vm.autocomplete,
        placeholder: _vm.placeholder,
        required: "",
        "aria-required": "true",
        maxlength: "100"
      },
      domProps: { value: _vm.value },
      on: { input: _vm.updateValue }
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "password-toggle",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.ToggleVisibility()
          }
        }
      },
      [
        _c("svg", { staticClass: "gds-icon" }, [
          this.inputType === "password"
            ? _c("use", {
                attrs: {
                  "xlink:href":
                    "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-visibility-off"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          this.inputType !== "password"
            ? _c("use", {
                attrs: {
                  "xlink:href":
                    "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-visibility"
                }
              })
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }