<template></template>

<script>
// This component does nothing but watch the vuex store and add or remove classes from the page body
// This will let us hide/display wordpress components depending on user state
export default {
  name: "GlobalBodyClassWatcher",
  data() {
    return {
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.state.user.userinfo;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  methods: {
    UpdateBodyClasses() {
      // If user is logged in, set body class
      if (this.loggedInUser) {
        document.body.classList.add("gmp-user-logged-in");
      } else {
        document.body.classList.remove("gmp-user-logged-in");
      }
      // If currently selected account has net metering, set body class
      if (this.currentAccount && this.currentAccount.solarNetMeter) {
        document.body.classList.add("gmp-account-netmeter");
      } else {
        document.body.classList.remove("gmp-account-netmeter");
      }
    },
  },
  watch: {
    loggedInUser() {
      this.UpdateBodyClasses();
    },
    currentAccount() {
      this.UpdateBodyClasses();
    }
  },
  async mounted() {
    this.UpdateBodyClasses();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>