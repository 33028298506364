var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-page__devices-list__device" },
    [
      _vm.device && _vm.device.deviceId
        ? [
            _vm.isDesktopView
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "devices-card devices-card-desktop border-grey"
                    },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/devices/" + _vm.device.deviceId } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "devices-card-desktop__image-container background-blue-gradient gds-flex-container gds-flex-container--bottom"
                            },
                            [
                              _c("img", {
                                staticClass: "width-full",
                                attrs: {
                                  src: _vm.device.imageURL,
                                  alt: "Emporia EV Charger image"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "section",
                            {
                              staticClass:
                                "devices-card-desktop__text-container width-full padding-l"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "color-black gds-font-size-l gds-font-medium"
                                },
                                [_vm._v(_vm._s(_vm.device.deviceName))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-font-size-s gds-text-grey gds-space-stack-s"
                                },
                                [_vm._v(_vm._s(_vm.device.deviceType))]
                              ),
                              _vm._v(" "),
                              _vm.statusIcon
                                ? _c(
                                    "div",
                                    { staticClass: "status-icon-container" },
                                    [
                                      _vm.statusIcon.isEventInProgress
                                        ? [_c("EventInProgressIndicator")]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.statusIcon.isImage
                                        ? _c("span", [
                                            _c("img", {
                                              staticClass: "status-icon",
                                              attrs: {
                                                alt: "Status Icon",
                                                src: _vm.statusIcon.url
                                              }
                                            })
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "gds-text-grey devices-font-size-xs devices-font-uppercase"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.device.statusMessage.message
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isDesktopView
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/devices/" + _vm.device.deviceId } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "devices-card devices-card-mobile gds-flex-container border-grey"
                          },
                          [
                            _c(
                              "section",
                              {
                                staticClass:
                                  "devices-card-mobile__image-container devices-flex-1 background-blue-gradient padding-m"
                              },
                              [
                                _c("img", {
                                  staticClass: "width-full",
                                  attrs: {
                                    src: _vm.device.imageURL,
                                    alt: "Emporia EV Charger image"
                                  }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "section",
                              {
                                staticClass:
                                  "devices-card-mobile__text-container devices-flex-5 padding-m"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "color-black gds-font-size-l gds-font-medium"
                                  },
                                  [_vm._v(_vm._s(_vm.device.deviceName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gds-font-size-s gds-text-grey gds-space-stack-s"
                                  },
                                  [_vm._v(_vm._s(_vm.device.deviceType))]
                                ),
                                _vm._v(" "),
                                _vm.statusIcon
                                  ? _c(
                                      "div",
                                      { staticClass: "status-icon-container" },
                                      [
                                        _vm.statusIcon.isEventInProgress
                                          ? [_c("EventInProgressIndicator")]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.statusIcon.isImage
                                          ? _c("span", [
                                              _c("img", {
                                                staticClass: "status-icon",
                                                attrs: {
                                                  alt: "Status Icon",
                                                  src: _vm.statusIcon.url
                                                }
                                              })
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gds-text-grey devices-font-size-xs devices-font-uppercase"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.device.statusMessage.message
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.device || !_vm.device.deviceId
        ? [
            _vm.isDesktopView
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/devices/add-device" } },
                      [
                        _c(
                          "section",
                          {
                            staticClass:
                              "devices-card devices-card-desktop gds-flex-container gds-flex-container--column border-grey padding-l"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gds-button-circle gds-flex-container gds-font-size-xxl gds-space-stack-m add-icon"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "color-black plus" },
                                  [_vm._v("+")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "color-black gds-font-size-l gds-font-medium gds-space-stack-m"
                              },
                              [_vm._v("Add a Device")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gds-font-size-m gds-text-grey gds-align--text-center devices-width-60"
                              },
                              [
                                _vm._v(
                                  "\n            Connect a battery or EV charger you own, or get one from GMP.\n          "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isDesktopView
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/devices/add-device" } },
                      [
                        _c(
                          "section",
                          {
                            staticClass:
                              "color-blackdevices-card devices-card-mobile gds-flex-container padding-l"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gds-button-circle gds-flex-container gds-font-size-xxl gds-space-inline-m add-icon"
                              },
                              [
                                _c("span", { staticClass: "plus" }, [
                                  _vm._v("+")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "color-black gds-font-size-l gds-font-medium"
                              },
                              [_vm._v("Add a Device")]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }