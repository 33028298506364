var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usage-unavailable-container" }, [
      _c("img", {
        staticClass: "maintenance-graphic",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/maintenance-error.svg"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "maintenance-message maintenance-message--main gds-space-stack-l"
        },
        [
          _vm._v(
            "\n    Usage information is not available for your account.\n  "
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }