<template>
  <div v-if="visible" class="gmp-modal">
    <div class="my-account__modal-box">
      <FocusLock>
        <header class="my-account__modal-box--header">
          <img class="my-account__modal-box--icon" src="/wp-content/themes/gmptwentynineteen/assets/images/found-address.svg" alt />
          <button @click="Close()" type="button" class="close bill-pay__close gds-button gds-button-circle gds-secondary">
            <svg class="gds-icon close-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
          </button>
        </header>
        <div class="my-account__modal-box--body">
          <div class="my-account__modal-box--headline">Review your address</div>
          <div class="my-account__modal-box--message gds-space-stack-l">
            <div v-if="!addresses">We couldn't find a match for that address. You can use the address as entered, or go back and make a change.</div>
            <div v-if="addresses && addresses.length === 1">We've found a similar address to the one you've entered. Please select an option below.</div>
            <div v-if="addresses && addresses.length > 1">We've found several similar addresses to the one you've entered. Please select an option below.</div>
          </div>
          <fieldset class="fieldset-no-border gds-space-stack-l">
            <div v-for="(address, index) of addresses" :key="index" class="my-account__modal-box--radio-group gds-space-stack-m">
              <label class="gds-radio bill-pay-login__form--radio-label-flex">
                <input v-model="selectedAddress" :value="address" type="radio" />
                <span class="gds-radio__faux"></span>
                <span v-if="address.text" class="gds-radio__label bill-pay-login__radio-label">{{address.text}}</span>
                <span v-if="address.fields" class="gds-radio__label bill-pay-login__radio-label">{{address.fields | addressExpFormat({ mailing: true })}}</span>
              </label>
            </div>
            <div class="my-account__modal-box--radio-group">
              <label class="gds-radio bill-pay-login__form--radio-label-flex">
                <input v-model="selectedAddress" :value="original" type="radio" />
                <span class="gds-radio__faux"></span>
                <span class="gds-radio__label bill-pay-login__radio-label">Use address as entered</span>
              </label>
            </div>
          </fieldset>
          <div class="gds-flex-container gds-flex-container--left">
            <button @click="Close()" type="button" class="gds-button gds-secondary gds-space-inline-m">Cancel</button>
            <button @click="Save()" type="button" class="gds-button">Save</button>
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import { GetMailingAddressFromVerified } from '../../../utilities';
import GMPAPI from '../../../services/gmpapi';

export default {
  name: "ModalAddressVerify",
  data() {
    return {
      visible: false,
      addresses: undefined,
      original: undefined,
      selectedAddress: undefined,
    };
  },
  components: {
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
    async Show(addresses, original) {
      this.addresses = addresses;
      this.original = original;
      this.visible = true;
      document.body.classList.add('modal-open');
      if (this.addresses) {
        this.selectedAddress = this.addresses[0];
      } else {
        this.selectedAddress = original;
      }
    },
    async Save() {
      let address = this.selectedAddress;
      let response;

      if (address.text) {
        // This is a non-final address - verify it to get finalized
        response = await GMPAPI.VerifyUSMailingAddress(this.selectedAddress.text);
      }

      // If this is an experian address, convert to mailing address
      if (response && response.result && response.result.address) {
        address = GetMailingAddressFromVerified(response.result.address);
      }
      this.$emit("save", address);
      this.Close();
    },
    async Close() {
      this.visible = false;
      this.addresses = undefined;
      this.original = undefined;
      this.selectedAddress = undefined;
      document.body.classList.remove('modal-open');
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>