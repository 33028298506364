var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "outage-list__slide-in-mobile",
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_vm._v("close")]
      ),
      _vm._v(" "),
      _vm.townData && _vm.townData.length
        ? _c(
            "vue-good-table",
            {
              attrs: {
                columns: _vm.columns,
                rows: _vm.townData,
                "sort-options": {
                  enabled: true,
                  initialSortBy: { field: "customers_affected", type: "desc" }
                },
                "search-options": {
                  enabled: true,
                  placeholder: "Search Towns"
                },
                styleClass: "vgt-table town-table outage-table"
              },
              on: {
                "on-row-click": _vm.onRowClick,
                "on-row-mouseenter": function($event) {
                  return _vm.onRowHover($event.row.town_name)
                },
                "on-row-mouseleave": function($event) {
                  return _vm.onRowHover(null)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-row",
                    fn: function(props) {
                      return [
                        props.column.field == "active"
                          ? [
                              props.row.customers_affected > 0
                                ? _c("div", {
                                    staticClass: "alert-icon active"
                                  })
                                : _c("div", {
                                    staticClass: "alert-icon inactive"
                                  })
                            ]
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                1992414546
              )
            },
            [
              _vm._v(" "),
              _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
                _vm._v("No matching towns")
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }