<template>
  <div class="gmp-modal">
    <div class="export-data__modal-box">
      <FocusLock>
        <header class="delete-user__modal-box--header">
          <button @click="CancelDeleteContact()" class="close gds-button gds-button-circle gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
          </button>
          <div class="export-data__modal-box--headline">Are you sure you want to remove this contact?</div>
        </header>
        <div class="delete-user__modal-box--body">
          <div class="export-data__modal-box--message gds-space-stack-l">Removing this contact will remove it from your account and unsubscribe you from all alerts setup to use this contact.</div>
          <button @click="CancelDeleteContact()" type="button" class="gds-button gds-secondary gds-compact gds-space-stack-m">
            <span class="gds-button__text">Cancel</span>
          </button>
          <button @click="DeleteContactConfirm()" type="button" class="gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m">
            <span class="gds-button__text">Yes, remove contact</span>
          </button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>


<script>

export default {
  name: "ModalRemoveContactConfirm",
  props: {
    CancelDeleteContact: Function,
    DeleteContactConfirm: Function
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>