var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      { staticClass: "eicproduct-success-fail", attrs: { id: "top-target" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "gds-space-stack-l" }, [
          _c("div", { staticClass: "gds-align--text-center" }, [
            _vm._v(
              "\n        This account is not eligible to participate in the EV charger program.         "
            ),
            _c(
              "a",
              {
                staticClass: "greenBoldText",
                attrs: {
                  rel: "noreferrer noopener",
                  href:
                    "https://greenmountainpower.com/wp-content/uploads/2023/01/EV-Rebate-Form-2023.pdf",
                  target: "_blank"
                }
              },
              [_vm._v("Please fill out this form here")]
            ),
            _vm._v(
              " or call " +
                _vm._s(_vm.GMPPhoneNumber || "1-(888)-835-4672") +
                " with any questions.\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "bill-pay-success__button-wrap gds-align--text-center"
          },
          [
            _c(
              "button",
              {
                staticClass: "bill-pay-success__button gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.ReturnToSender("/")
                  }
                }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-flex-container gds-space-stack-s" }, [
      _c("div", { staticClass: "card__data-container--large-text" }, [
        _c("img", {
          staticClass: "my-account__payment-plan-icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/pay-plan.svg",
            alt: ""
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }