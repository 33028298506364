<template>
  <label class="gds-radio">
    <input class="bill-pay__faux-radio-input" :checked="shouldBeChecked" :value="value" :disabled="disabled" @change="updateInput" type="radio" />
    <div class="bill-pay__method-box gds-flex-container gds-flex-container--space-between" :class="{ 'bill-pay__method-box--disabled': disabled }">
      <div v-if="value" class="gds-flex-container">
        <svg v-if="!value.other" class="bill-pay__method-box-icon bill-pay__icon gds-icon">
          <use v-if="!isNew && (value.paymentType === 'CC' || value.paymentType === 'ATM')" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
          <use v-if="!isNew && value.paymentType === 'ACH'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-bank" />
          <use v-if="isNew && (value.paymentType === 'CC' || value.paymentType === 'ATM')" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card-new" />
          <use v-if="isNew && value.paymentType === 'ACH'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-bank-new" />
        </svg>
        <div class="bill-pay__method-box-details">
          <div class="bill-pay__method-box-details--button gds-font-size-l gds-font-book">{{value.accountNickName}}</div>
          <div class="bill-pay__method-box-details--button gds-font-size-l gds-font-book">{{value | walletSlug(true)}}</div>
          <div v-if="value.recurringSchedule" class="gds-badge info-item space-top">Autopay</div>
          <div v-if="value.isDebitAccountUnauthorized && !value.isExpired" class="gds-badge danger-item space-top">Needs Attention</div>
          <div v-if="value.isExpired" class="gds-badge danger-item space-top">Expired</div>
          <div v-if="value.other" class="bill-pay__method-box-details--button gds-font-size-l gds-font-book"><div id="wallet-selector-other">{{value.text}}</div></div>
        </div>
      </div>

      <span class="gds-radio__faux"></span>
    </div>
  </label>
</template>

<script>
export default {
  name: "WalletSelector",
  props: ["value", "modelValue", "disabled", "isNew"],
  model: {
    prop: "modelValue",
    event: "change",
  },
  data() {
    return {
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    shouldBeChecked() {
      return this.modelValue === this.value;
    },
  },
  async mounted() {
  },
  methods: {
    updateInput() {
      this.$emit("change", this.value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>