export default {
  custom: {
    "phone number": {
      min: "Complete phone number required."
    },
    "password confirmation": {
      confirmed: "The password confirmation field must match the password."
    },
    "account number": {
      length: "The account number must be 10 or 11 characters."
    },
    "termsofuse": {
      required: "You must agree to the terms of use."
    },
    "VTRegistrationRequired": {
      required: 'You must answer whether your vehicle is registered in VT.'
    },
    "wifiNetworkRequired": {
      required: 'You must answer whether your home has a WiFi network.'
    },
    "wifiSignalRequired": {
      required: 'You must answer whether your home has a WiFi signal where your EV charger is located/installed.'
    },
    "hasInternet": {
      required: 'You must answer whether your home has an internet connection.'
    },
    "isAllElectric": {
      required: 'You must answer whether your vehicle is all electric or a plug in hybrid.'
    }
  },
}