var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "net-metering-diagram__wrapper" },
    [
      _c("div", { staticClass: "summary widget-net-metering__gen__summary" }, [
        _c(
          "div",
          {
            staticClass:
              "widget-net-metering__gen__summary__title gds-space-stack-default"
          },
          [_vm._v(_vm._s(_vm.summaryGeneration))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "widget-net-metering__gen__summary__detail-item" },
          [
            _c(
              "div",
              {
                staticClass:
                  "widget-net-metering__gen__summary__detail-item--circle-container"
              },
              [
                _c("img", {
                  staticClass:
                    "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle",
                  attrs: {
                    src:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/" +
                      _vm.summaryIconTotalGeneration,
                    alt: "performance indicator"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--text",
              domProps: { innerHTML: _vm._s(_vm.summaryTextGeneration) }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "net-metering-diagram" }, [
        _c("div", { staticClass: "returned-to-grid" }, [
          _c("div", { staticClass: "value black" }, [
            _vm._v(
              _vm._s(
                _vm._f("numberCommaSingleDecimal")(_vm.generationReturnedToGrid)
              ) + " kWh\n        "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [_vm._v("Returned to Grid")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "grid-usage" }, [
          _c("div", { staticClass: "value black" }, [
            _vm._v(
              _vm._s(_vm._f("numberCommaSingleDecimal")(_vm.energyFromGrid)) +
                " kWh"
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [_vm._v("Consumed from Grid")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "total-generation" }, [
          _c("div", { staticClass: "value green" }, [
            _vm._v(
              _vm._s(_vm._f("numberCommaSingleDecimal")(_vm.totalGeneration)) +
                " kWh"
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "generation-to-home" }, [
          _c("div", { staticClass: "value black" }, [
            _vm._v(
              _vm._s(_vm._f("numberCommaSingleDecimal")(_vm.generationToHome)) +
                " kWh"
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "label" }, [_vm._v("Generation to Home")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "total-home-consumption" }, [
          _c("div", { staticClass: "value brandblue" }, [
            _vm._v(
              _vm._s(_vm._f("numberCommaSingleDecimal")(_vm.totalUsage)) +
                " kWh"
            )
          ]),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "house-graphic" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 725 405"
              }
            },
            [
              _c("path", {
                attrs: {
                  fill: "#fff",
                  "fill-rule": "evenodd",
                  stroke: "#CECECE",
                  "stroke-width": "12",
                  d: "M42 396h348V189H42v207Z",
                  "clip-rule": "evenodd"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#ECECEC",
                  "fill-rule": "evenodd",
                  d: "m347 193-13 38-17 56-24 103h87V193h-33Z",
                  "clip-rule": "evenodd"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#22395B",
                  d:
                    "M83 2h56v47h357l43-45 177 182-13 13L539 33 387 187l-11 12H11L31 49h52V2Z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#fff",
                  "fill-rule": "evenodd",
                  stroke: "#CECECE",
                  "stroke-width": "12",
                  d: "M539 33 380 199h5v197h308V199h5L539 33Z",
                  "clip-rule": "evenodd"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#FD872E",
                  d:
                    "M185 303c0-22 17-39 38-39 22 0 39 17 39 39v96h-77v-96Zm416 41H478v-16h123v16Z"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#BAE0F5",
                  d:
                    "M565 152c0 16-13 29-28 29-16 0-29-13-29-29s13-28 29-28c15 0 28 12 28 28Zm26 176H488V225h103v103ZM83 292h56v-57H83v57Zm261 0h-56v-57h56v57Z"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "solar-graphic" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                viewBox: "0 0 755 464"
              }
            },
            [
              _c("path", {
                attrs: {
                  fill: "#F8BF97",
                  "fill-rule": "evenodd",
                  d:
                    "m140 0-12 31-23-8-7 23-34-9 10 32-24 7 8 22-32 12 27 20-15 19 20 14-19 27 35 2-1 23h25l1 33 29-18 14 19 20-14 21 26 13-31 23 8 7-23 33 9-9-32 24-7-9-22 33-12-27-21 15-18-21-14 20-27-35-2 1-23h-25l-1-33-29 18-15-19-19 14-22-26Z",
                  "clip-rule": "evenodd"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#FD872E",
                  "fill-rule": "evenodd",
                  d:
                    "M229 130c0 36-30 65-67 65s-67-29-67-65c0-35 30-64 67-64s67 29 67 64",
                  "clip-rule": "evenodd"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#2C60B3",
                  "fill-rule": "evenodd",
                  d:
                    "M378 424H199l156-266h180L378 424Zm179 0H392l156-266h175L557 424Zm-375 0H8l166-266h166L182 424Z",
                  "clip-rule": "evenodd"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#fff",
                  "fill-opacity": ".1",
                  "fill-rule": "evenodd",
                  d: "M557 424H8l166-266s60 45 167 116c55 36 216 150 216 150Z",
                  "clip-rule": "evenodd"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#B8BBBF",
                  "fill-rule": "evenodd",
                  d: "M754 175 576 464H0v-18h565l174-281 15 10Z",
                  "clip-rule": "evenodd"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-right-down hide-on-wide-layout" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                width: "62",
                viewBox: "0 0 62 52"
              }
            },
            [
              _c("path", {
                attrs: {
                  stroke: "#A7A7A7",
                  "stroke-linecap": "square",
                  "stroke-width": "3.4",
                  d: "M2 3h32c10 0 19 8 19 18v20"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: { fill: "#A7A7A7", d: "m53 52-9-11h17l-8 11Z" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-down-left hide-on-wide-layout" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                width: "54",
                viewBox: "0 0 54 50"
              }
            },
            [
              _c("path", {
                attrs: {
                  stroke: "#A7A7A7",
                  "stroke-linecap": "square",
                  "stroke-width": "3.4",
                  d: "M4 41h35c7 0 13-6 13-13v0V2"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: { fill: "#A7A7A7", d: "m0 41 11-9v17L0 41Z" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-down hide-on-wide-layout" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                fill: "none",
                width: "18",
                viewBox: "0 0 18 148"
              }
            },
            [
              _c("path", {
                attrs: {
                  stroke: "#A7A7A7",
                  "stroke-linecap": "square",
                  "stroke-width": "3.4",
                  d: "M9 142V3"
                }
              }),
              _vm._v(" "),
              _c("path", {
                attrs: { fill: "#A7A7A7", d: "m9 148-9-11h17l-8 11Z" }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-right show-on-wide-layout" }, [
          _c(
            "svg",
            {
              staticClass: "expanding-arrow-stem",
              attrs: {
                width: "24",
                height: "18",
                viewBox: "0 0 24 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                preserveAspectRatio: "none"
              }
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 9L24 9",
                  stroke: "#A7A7A7",
                  "stroke-width": "3"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "27",
                height: "18",
                viewBox: "0 0 27 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M27 9L12 0.339747L12 17.6603L27 9ZM-1.31134e-07 10.5L13.5 10.5L13.5 7.5L1.31134e-07 7.5L-1.31134e-07 10.5Z",
                  fill: "#A7A7A7"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-left show-on-wide-layout" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "27",
                height: "18",
                viewBox: "0 0 27 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M0 9L15 17.6603L15 0.339747L0 9ZM13.5 10.5L27 10.5L27 7.5L13.5 7.5L13.5 10.5Z",
                  fill: "#A7A7A7"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "expanding-arrow-stem",
              attrs: {
                width: "24",
                height: "18",
                viewBox: "0 0 24 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                preserveAspectRatio: "none"
              }
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 9L24 9",
                  stroke: "#A7A7A7",
                  "stroke-width": "3"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "arrow-right-up-curve show-on-wide-layout" }, [
          _c(
            "svg",
            {
              staticClass: "expanding-arrow-stem",
              attrs: {
                width: "37",
                height: "63",
                viewBox: "0 0 37 63",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                preserveAspectRatio: "none"
              }
            },
            [
              _c("path", {
                attrs: {
                  d: "M0 54.5H37",
                  stroke: "#A7A7A7",
                  "stroke-width": "3"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "77",
                height: "63",
                viewBox: "0 0 77 63",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                staticStyle: {
                  fill: "color(display-p3 0.6554 0.6554 0.6554)",
                  "fill-opacity": "1"
                },
                attrs: {
                  d:
                    "M68 0L59.3397 15H76.6603L68 0ZM0 56H25V53H0V56ZM25 56C49.5767 56 69.5 36.0767 69.5 11.5H66.5C66.5 34.4198 47.9198 53 25 53V56Z",
                  fill: "#A7A7A7"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _vm._m(2)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _vm._v("Total Generation"),
      _c("span", { staticClass: "small" }, [
        _vm._v(" (how much solar energy you generated)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label" }, [
      _vm._v("Total Home Consumption"),
      _c("span", { staticClass: "small" }, [
        _vm._v(" (how much power you used, solar and/or grid)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "disclaimer" }, [
      _c("p", { staticClass: "small" }, [
        _vm._v(
          "Your usage data may be slightly off if you have a connected battery back-up."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }