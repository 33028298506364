var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      { staticClass: "bill-pay-success-fail", attrs: { id: "top-target" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "gds-display-2 gds-align--text-center gds-space-stack-ml"
          },
          [_vm._v("Oh no!")]
        ),
        _vm._v(" "),
        _vm.code === 1002
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("The zip code was incorrect.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 1019 || _vm.code === 1026
          ? _c(
              "div",
              {
                staticClass:
                  "error-1019 error-1026 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("The credit card type is not supported.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 1001
          ? _c(
              "div",
              {
                staticClass:
                  "error-1001 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("The card was declined.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 3307
          ? _c(
              "div",
              {
                staticClass:
                  "error-3307 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("The payment was a duplicate.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 3803
          ? _c(
              "div",
              {
                staticClass:
                  "error-3803 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("More than 2 card payments have been made this month.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 28002
          ? _c(
              "div",
              {
                staticClass:
                  "error-28002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [
                _vm._v(
                  "A payment method with that nickname already exists for this account."
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "bill-pay-fail__description gds-body-large gds-align--text-center gds-space-stack-xl"
          },
          [
            _vm._v(
              "\n      We were unable to process your payment arrangement at this time. Please try again or contact customer support for assistance at\n      "
            ),
            _c("span", { staticClass: "gds-nobreak" }, [
              _vm._v(_vm._s(_vm.GMPPhoneNumber) + ".")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "bill-pay-success__button-wrap gds-align--text-center gds-space-stack-l"
          },
          [
            _c(
              "button",
              {
                staticClass: "bill-pay-success__button gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.ReturnToSender("/past-due-wizard")
                  }
                }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "bill-pay-fail__intro-image gds-space-stack-xl" },
      [
        _c("img", {
          staticClass: "my-account__payment-plan-icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/pay-fatal-error.svg",
            alt: "unsuccessful payment"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }