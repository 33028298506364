<template>
  <div class="top-card-display__single-card top-card-display__single-card--bill-pay-parent">
    <div v-if="!currentAccount || isAdminLite">
      <div class="top-card-display__single-card--pay-bill-loggedout">
        <a href="/make-payment" class="top-banner-card gds-card gds-background-green gds-invert">
          <div class="gds-card__contents">
            <h3 class="home-card__title--small-margin gds-card__title">Make Payment</h3>
            <p class="home-card__body--small-margin gds-card__body">Pay your balance online</p>
            <div class="gds-card__button gds-button gds-button-circle gds-secondary"><svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
            </svg></div>
          </div>
        </a>
      </div>
    </div>
    <div v-else>
      <div class="top-card-display__single-card--pay-bill-loggedin">
        <div class="top-banner-card gds-card gds-data-card">
          <h3 class="gds-card__title">Current Balance</h3>
          <payment-status-display :account="currentAccount" />
          <figure class="card__pay-bill-figure gds-card__data-container gds-flex-container">
            <div class="card__data-container--flex-container gds-flex-container">
              <div class="card__data-container--large-text gds-space-inline-s">{{amountDue | currency}}</div>
            </div>
            <div v-if="amountDue < 0" class="gds-font-size-s gds-align--text-center gds-tag gds-secondary">Credit Balance</div>
          </figure>
          <a href="/make-payment" class="account_make_payment gds-card__button gds-button gds-round gds-blue">Make Payment</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isPast } from 'date-fns';
import { ParseDisconnectDate } from '../utilities';
import PaymentStatusDisplay from "./generic/PaymentStatusDisplay.vue";

export default {
  components: { PaymentStatusDisplay },
  name: "CardPayBill",
  data() {
    return {
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    pastDue() {
      const date = ParseDisconnectDate(this.currentAccount.amountDueDate);
      return isPast(date);
    },
    amountDue() {
      return this.currentAccount.amountDue;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
  },
  async mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>