var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-detail__seasonal-bubble-container" }, [
    _c(
      "div",
      {
        staticClass: "usage-detail__seasonal-bubble",
        class: _vm.isCurrentSeason ? "current-season" : null,
        style: {
          transform: _vm.isCurrentSeason
            ? null
            : _vm.averageUsage < 2
            ? "scale(4)"
            : "scale(" + _vm.usageScalar + ")",
          margin:
            "0 " + _vm.usageScalar * 10 + "px 0 " + _vm.usageScalar * 10 + "px"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "usage-detail__seasonal-bubble-usage-text",
            class: _vm.isCurrentSeason ? "current-season" : null,
            style: {
              transform: _vm.isCurrentSeason
                ? "scale(" + 0.333 + ")"
                : _vm.averageUsage <= 2
                ? "scale(" + 0.25 + ")"
                : "scale(" + 1 / _vm.usageScalar + ")"
            }
          },
          [
            _c("div", [_vm._v(_vm._s(_vm.averageUsage))]),
            _vm._v(" "),
            _c("div", [_vm._v("kWh/day")])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "usage-detail__seasonal-bubble-label-container",
        class: _vm.isCurrentSeason ? "current-season" : null
      },
      [
        _c(
          "div",
          { staticClass: "usage-detail__seasonal-bubble-label-season-year" },
          [
            _vm._v(
              _vm._s(_vm.season) +
                " " +
                _vm._s(
                  _vm.season !== "Winter"
                    ? _vm.year
                    : _vm.year - 1 + "-" + _vm.year
                )
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "usage-detail__seasonal-bubble-label-months" },
          [_vm._v(_vm._s(_vm.getMonthsInSeason()))]
        ),
        _vm._v(" "),
        _vm.isCurrentSeason
          ? _c(
              "span",
              {
                staticClass:
                  "usage-detail__seasonal-bubble-current-season-badge"
              },
              [_vm._v("Current")]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }