<template>
  <div class="top-card-display__double-card top-card-display__double-card--recent-transactions">
    <div v-if="currentAccount">
      <div class="gds-card gds-data-card top-banner-card top-banner-card--logged-in" :class="{'gds-loading': loadState === undefined }">
        <div>
          <div class="recent-transactions__header gds-flex-container gds-flex-container--space-between">
            <div class="gds-display-0">Recent Transactions</div>
            <router-link to="/billing#history">View All</router-link>
          </div>

          <template v-if="loadState === 'complete'">
            <div v-for="transaction of recentTransactions" :key="transaction.transactionId" class="recent-transactions--item gds-flex-container gds-flex-container--left">
              <div class="recent-transactions--item-icon">
                <svg v-if="transaction.type === 'Bill Segment'" class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-document" />
                </svg>
                <svg v-if="transaction.type === 'Pay Segment'" class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-payment" />
                </svg>
              </div>
              <div class="recent-transactions--item-details">
                <div class="recent-transactions--item-date">
                  <span class="gds-space-inline-s">{{transaction.date | dateFull}}</span>
                </div>
                <div class="recent-transactions--item-type">{{transaction.type | transactionType}}</div>
              </div>
              <div class="recent-transactions--item-amount">{{transaction.payoffAmount | currency}}</div>
            </div>
          </template>
        </div>
        <div v-if="loadState === 'error'" class="card-error">
          Unable to load,
          <br />please try again later.
        </div>
        <div v-if="loadState === 'error'" class="card-error-layout-blank">&nbsp;</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addDays } from "date-fns";

import GMPAPI from '../../../services/gmpapi';
import { ToServerDateTruncate, DumpError } from '../../../utilities';

export default {
  name: "CardRecentTransactions",
  data() {
    return {
      recentTransactions: undefined,

      loadState: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    this.RefreshData();
  },
  methods: {
    async RefreshData() {
      this.recentTransactions = undefined;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        const start = addDays(new Date(), -365);
        const end = addDays(new Date(), 1);
        const acct = this.currentAccount.accountNumber;
        const allTransactions = await GMPAPI.GetAccountTransactions(acct, ToServerDateTruncate(start), ToServerDateTruncate(end));
        const filteredTransactions = allTransactions.filter(item => item.type === "Bill Segment" || item.type === "Pay Segment");
        const recentTransactions = filteredTransactions.slice(0, 4);
        this.recentTransactions = recentTransactions;
        this.loadState = "complete";
      } catch (err) {
        DumpError("Recent transactions card refresh error", err);
        this.loadState = "error";
      }
    },
  },
  watch: {
    // Recompute whenever the account changes
    async currentAccount() {
      await this.RefreshData();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>