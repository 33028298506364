<template>
  <div>
    <div v-if="loadState === undefined" class="table-loading" />
    <flow-error v-if="loadState === 'error'" name="Information for the specified planned outage" state="error" />
    <div v-if="loadState === 'complete'">
      <outage-breadcrumbs :planned="plannedOutage" />
      <div class="town-incident__row gds-flex-container">
        <div class="town-incident__column--details incident-details">
          <div v-if="plannedOutage">
            <div v-if="plannedOutage.status === 'IN_PROGRESS'"
              class="planned-outage__top-label-and-icon active-outage gds-font-size-l">Active Planned Outage</div>
            <div v-else-if="plannedOutage.status === 'COMPLETED'"
              class="planned-outage__top-label-and-icon complete-outage gds-font-size-l">Complete Planned Outage</div>
            <div v-else class="planned-outage__top-label-and-icon planned-outage gds-font-size-l">Planned Outage</div>
            <div class="gds-text--capitalize gds-font-size-xxl gds-space-stack-l">{{ outageLocation.toLowerCase() }}
            </div>

            <div v-if="plannedOutage.status === 'IN_PROGRESS'" class="gds-font-size-l gds-font-demi gds-space-stack-ml">
              The planned outage near {{ outageLocation }} is currently underway. The purpose of the outage is to allow
              crews to safely fix our infrastructure.</div>
            <div v-else-if="plannedOutage.status === 'COMPLETED'"
              class="gds-font-size-l gds-font-demi gds-space-stack-ml">An outage was planned near {{ outageLocation }}
              to allow crews to safely fix our infrastructure.</div>
            <div v-else class="gds-font-size-l gds-font-demi gds-space-stack-ml">An outage is planned near {{
                outageLocation
            }} to allow crews to safely fix our infrastructure.</div>
            <div class="gds-space-stack-l">
              <div class="planned-outage__list-details-row">
                <div class="gds-font-demi">Planned start time:</div>
                <div>{{ plannedOutage.occursAt | dateTimeFull }}</div>
              </div>
              <div class="planned-outage__list-details-row">
                <div class="gds-font-demi">Planned end time:</div>
                <div>{{ plannedOutage.endsAt | dateTimeFull }}</div>
              </div>
              <div class="planned-outage__list-details-row">
                <div class="gds-font-demi">Planned length:</div>
                <div>{{ plannedOutage.outageDuration | durationMinutes }}</div>
              </div>
              <div v-if="plannedOutage.outageReason" class="planned-outage__list-details-row">
                <div class="gds-font-demi">Reason:</div>
                <div>{{ plannedOutage.outageReason }}</div>
              </div>
            </div>

            <div v-if="myAffectedAccounts && myAffectedAccounts.length > 0"
              class="planned-outage__your-location gds-font-size-l gds-font-demi gds-space-stack-l">
              <div class="gds-space-stack-m">Your Affected {{ myAffectedAccounts.length > 1 ? 'Locations' : 'Location'
              }}</div>
              <div v-for="affectedAccount of myAffectedAccounts" :key="affectedAccount.accountNumber">
                <div class="gds-flex-container gds-flex-container--left">
                  <div>
                    <img v-if="plannedOutage.status === 'IN_PROGRESS'"
                      src="/wp-content/themes/gmptwentynineteen/assets/images/icon-home-circle-alert.svg"
                      class="home-circle-icon gds-space-inline-m" />
                    <img v-else src="/wp-content/themes/gmptwentynineteen/assets/images/icon-home-circle-planned.svg"
                      class="home-circle-icon gds-space-inline-m" />
                  </div>
                  <div>
                    <div>{{ affectedAccount | accountName }}</div>
                    <div class="gds-linebreaks">{{ affectedAccount.address | addressFormat({
                        separator: "\n", skipFirst:
                          true
                      })
                    }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="affectedTowns && affectedTowns.length" class="gds-font-size-l gds-font-demi gds-space-stack-ml">
              This planned outage
              {{ plannedOutage.status === 'IN_PROGRESS' ? "is affecting" : (plannedOutage.status === "COMPLETED" ?
                  "affected" : "will affect")
              }}
              {{ totalCustomers }} customer{{ totalCustomers > 1 ? 's' : '' }}
              {{ myAffectedAccounts.length > 0 ? "(including you)" : '' }}
              in {{ affectedTowns.length > 1 ? (affectedTowns.length + ' towns') : affectedTowns[0].name }}.
            </div>

            <div v-if="affectedTowns && affectedTowns.length >= 1">
              <div v-for="town of affectedTowns" :key="town.name" class="town-incident__affected-towns-list">
                <div class="gds-flex-container gds-flex-container--space-between">
                  <router-link :to="{ name: 'Town', params: { townname: town.slug } }">{{ town.name }}</router-link>
                  <div class="gds-font-size-m">{{ town.customers }} customer{{ town.customers > 1 ? 's' : '' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="town-incident__column--map gds-position--relative">
          <gmp-map ref="map" kind="planned" :plannedShape="plannedShape" @update="onMapUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import { DumpError, HyphenateTown, InitialCaps } from "../../utilities";
import OutageBreadcrumbs from "./OutageBreadcrumbs.vue";
import GmpMap from "./GmpMap.vue";
import { isFeatureEnabled } from "../../services/featureflags";

export default {
  name: "SubpagePlannedOutage",
  components: {
    OutageBreadcrumbs,
    GmpMap,
  },
  computed: {
    outageid() {
      return Number(this.$route.params.outageid);
    },
    outageLocation() {
      return this.plannedOutage.outageLocation || "Unknown location";
    },
    totalCustomers() {
      return this.affectedTowns.reduce((acc, town) => acc + town.customers, 0);
    }
  },
  data() {
    return {
      FF_PlannedOutage: undefined,
      plannedOutage: undefined,
      plannedShape: undefined,
      myAffectedAccounts: [],
      affectedTowns: [],
      loadState: undefined,
    };
  },
  async mounted() {
    this.loadState = undefined;
    try {
      await this.RefreshData();
      if (!this.FF_PlannedOutage) {
        this.loadState = "error";
      } else {
        this.loadState = "complete";
      }
    } catch (err) {
      DumpError("Planned outage load error", err);
      this.loadState = "error";
    }
  },
  methods: {
    async getConfigs() {
      this.FF_PlannedOutage = await isFeatureEnabled('FF_PlannedOutage', false);
    },
    async RefreshData() {
      await this.getConfigs(),
        this.affectedTowns = [];
      const [plannedOutage, plannedShape] = await Promise.all([
        GMPAPI.GetPlannedOutage(this.outageid),
        GMPAPI.GetPlannedOutageShape(this.outageid),
      ]);
      this.plannedOutage = plannedOutage;
      this.plannedShape = plannedShape;
      if (this.plannedOutage.customersByTown) {
        this.affectedTowns = Object.entries(this.plannedOutage.customersByTown).map(([name, customers]) => { return { name: InitialCaps(name), slug: HyphenateTown(name), customers } });
      }
    },
    /** Use the copy of personalData the map is pulling anyway, rather than pulling it ourselves an extra time */
    onMapUpdate(apiData) {
      if (apiData && apiData.personalData && apiData.personalData.length) {
        this.myAffectedAccounts = [];
        for (const account of apiData.personalData) {
          if (account.plannedOutages && account.plannedOutages.length && account.plannedOutages.find(outage => outage.id === this.outageid)) {
            this.myAffectedAccounts.push(account);
          }
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>