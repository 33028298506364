var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "click-outside",
                rawName: "v-click-outside",
                value: _vm.Close,
                expression: "Close"
              }
            ],
            staticClass: "outage-map-legend"
          },
          [
            _c(
              "button",
              {
                staticClass: "close gds-button gds-button-circle gds-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.Close()
                  }
                }
              },
              [
                _c("svg", { staticClass: "gds-icon close-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("label", { staticClass: "outage-map-legend__title" }, [
              _vm._v("Map Legend")
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--space-between gds-flex-container--wrap"
        },
        [
          _c("div", { staticClass: "outage-map-legend__col first" }, [
            _c("div", { staticClass: "legend-txt-wrap" }, [
              _c(
                "div",
                {
                  staticClass: "gds-font-demi gds-font-size-l gds-space-stack-m"
                },
                [_vm._v("Towns")]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "gds-font-light gds-font-size-s gds-text-grey" },
                [
                  _vm._v(
                    "Towns are shaded according to how many customers have power out."
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "legend-img-wrap" }, [
              _c("img", {
                staticClass: "legend-img",
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/outage-center/towns-yellow-orange-red.svg",
                  alt: "Town example"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "outage-map-legend__col" }, [
            _c("div", { staticClass: "legend-txt-wrap" }, [
              _c(
                "div",
                {
                  staticClass: "gds-font-demi gds-font-size-l gds-space-stack-m"
                },
                [_vm._v("Outage Incidents")]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "gds-font-light gds-font-size-s gds-text-grey" },
                [
                  _vm._v(
                    "Outage incidents are shown as dots and may have estimated affected areas shown as shapes."
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "legend-img-wrap" }, [
              _c("img", {
                staticClass: "legend-img",
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/outage-center/map-legend-incident.svg",
                  alt: "Outage incident example"
                }
              })
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }