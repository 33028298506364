<template>
  <div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error" phonetext="To make a payment by phone call us at" closepath="/electric-vehicle-rebate" /> 
    <div v-if="routeloading || loadState === undefined">
      <div class="eicproduct__page-loader">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="eicproduct__page-loader--svg" />
      </div>
    </div>
    <router-view v-if="loadState === 'complete'"></router-view>
  </div>
</template>

<script>
import { EnvironmentConstants } from "../../../environment";
import { DumpError, ReturnToSender } from "../../../utilities";
import { GetComponentStatus } from "../../../services/statuspage";

export default {
  name: "PageEVRebate",
  components: {
  },
  data() {
    return {
      loadState: undefined,
      EnvironmentConstants,
    };
  },
  computed: {
    routeloading() {
      return this.$store.state.layout.routeloading;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    isRestrictedRebateUser() {
      return !!this.$store.state?.user?.userinfo?.groups?.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
  },
  async mounted() {
    // Wire up the close button (outside of vue)
    const closer = document.querySelector("#flow-close");
    if (closer && !this.isRestrictedRebateUser) {
      closer.addEventListener("click", e => {
        ReturnToSender('/electric-vehicle-rebate');
      });
    } else {
      closer.addEventListener("click", e => {
        this.Logout();
        this.$router.replace("/login");
      }); 
    }
    this.loadState = undefined;
    const status = await GetComponentStatus("Rebates");
    if (status === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    async Logout() {
      try {
        await this.$store.dispatch("LogOut");
        this.$router.replace("/login");
      } catch (err) {
        DumpError("Logout error", err);
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>