<template>
  <div id="ev-charger-bar-graph">
    <div ref="usageGraphRef" />
  </div>
</template>

<script>
import Highcharts from "highcharts";

let chartDates;
let chartSeries;
let selectedDataPoint = '';

const CHART_BAR_COLOR_1 = '#31284E';
const CHART_BAR_COLOR_2 = '#847E98';

function roundToFixedDecimalPlace(numberToRound, decimalPlace){
  return numberToRound.toFixed(decimalPlace);
}

export default {
  name: 'UsageDetailEVChargerBarGraph',
  mounted() {
    chartDates = this.chartDates;
    chartSeries = this.chartSeries;
    selectedDataPoint = this.selectedDataPoint;
    this.chart = Highcharts.chart(this.$refs.usageGraphRef, this.chartOptions);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  props: {
    chartDates: Array,
    chartSeries: Object,
    chartCategories: Array,
    selectedDataPoint: String
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          marginLeft: 20
        },
        title: {
          text: '',
        },
        xAxis: {
          min: -1,
          labels: {
            formatter: function() {
                if (this.pos % 2 !== 0) {
                    return null;
                } else {
                    return this.value;
                }
            }
          },
          categories: this.chartCategories
        },
        yAxis: {
          className: 'usage-ev-charger-line-graph-y-axis-label',
          title: {
            text: '',
          },
          labels: {
            useHTML: true,
            align: 'right',
            x: 65,
            y: 0,
            formatter: function() {
              const prefix = selectedDataPoint === 'cost' ? '$' : '';
              return `<i class='value'>${prefix}${this.value}</i>`;
            },
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          },
          column: {
            pointPadding: 0.05,
            groupPadding: 0.05,
            borderWidth: 0,
            color: CHART_BAR_COLOR_1
          }
        },
        series: [
          {
            name: 'Off Peak',
            lineWidth: '1.5',
            data: this.chartSeries.offPeak,
            color: CHART_BAR_COLOR_2
          },
          {
            name: 'On Peak',
            lineWidth: '1.5',
            data: this.chartSeries.onPeak,
            color: CHART_BAR_COLOR_1
          }
        ],
        tooltip: {
          shared: true,
          shadow: false,
          useHTML: true,
          outside: true,
          borderWidth: 0,
          borderRadius: 0,
          valueDecimals: 2,
          backgroundColor: 'transparent',
          formatter: function () {
            let prefix = '';
            let suffix = '';
            const index = this.point.index;
            let seriesValueOnPeak = chartSeries.onPeak[index];
            let seriesValueOffPeak = chartSeries.offPeak[index];

            switch (selectedDataPoint) {
              case 'cost':
                prefix = '$';
                seriesValueOnPeak = roundToFixedDecimalPlace(seriesValueOnPeak, 2);
                seriesValueOffPeak = roundToFixedDecimalPlace(seriesValueOffPeak, 2);
              break;
              case 'duration':
                suffix = ' Hours';
                seriesValueOnPeak = roundToFixedDecimalPlace(seriesValueOnPeak, 1);
                seriesValueOffPeak = roundToFixedDecimalPlace(seriesValueOffPeak, 1);
              break;
              case 'consumed':
                suffix = ' kWh';
                seriesValueOnPeak = roundToFixedDecimalPlace(seriesValueOnPeak, 2);
                seriesValueOffPeak = roundToFixedDecimalPlace(seriesValueOffPeak, 2);
              break;
            }

            let html =`
              <div class='my-account__usage-tooltip'>
                <div class='tooltip__month-year gds-flex-container gds-flex-container--left'>${chartDates[index]}</div>
                <div class='tooltip-rate gds-flex-container gds-flex-container--space-between'>
                  <div class='gds-flex-container gds-flex-container--left'>
                    <div class='rate-swatch' style='background-color: ${CHART_BAR_COLOR_2}'></div>
                    <div class='rate-name'>Off Peak</div>
                  </div>
                  <div class='rate-value'>${prefix}${seriesValueOffPeak}${suffix}</div>
                </div>
                <div class='tooltip-rate gds-flex-container gds-flex-container--space-between'>
                  <div class='gds-flex-container gds-flex-container--left'>
                    <div class='rate-swatch' style='background-color: ${CHART_BAR_COLOR_1}'></div>
                    <div class='rate-name'>Peak</div>
                  </div>
                  <div class='rate-value'>${prefix}${seriesValueOnPeak}${suffix}</div>
                </div>
            `;

            return html;
          }
        }
      },
    };
  }
};
</script>

<style scoped>
</style>
