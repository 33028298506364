var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      { staticClass: "bill-pay-success-fail", attrs: { id: "top-target" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "gds-display-2 gds-align--text-center gds-space-stack-ml"
          },
          [_vm._v("Thank you!")]
        ),
        _vm._v(" "),
        _vm.futurePayment
          ? _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
              _vm._v(
                "\n      Your payment of " +
                  _vm._s(_vm._f("currency")(_vm.paymentAmount)) +
                  " was successfully scheduled for " +
                  _vm._s(_vm._f("dateFull")(_vm.futurePayment)) +
                  _vm._s(
                    _vm.enrollInRecurringPayments && _vm.recurringPaymentSuccess
                      ? ", and we've turned on Recurring Auto Payments."
                      : "."
                  ) +
                  "\n    "
              )
            ])
          : _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
              _vm._v(
                "\n      Your payment of " +
                  _vm._s(_vm._f("currency")(_vm.paymentAmount)) +
                  " was successful" +
                  _vm._s(
                    _vm.enrollInRecurringPayments && _vm.recurringPaymentSuccess
                      ? ", and we've turned on Recurring Auto Payments. "
                      : ". "
                  ) +
                  " If your service is\n      scheduled\n      to be shut off in the next 48 hours, or if your service has already been shut-off for non-payment, please\n      contact Green Mountain Power at\n      "
              ),
              _c("span", { staticClass: "gds-nobreak" }, [
                _vm._v(_vm._s(_vm.GMPPhoneNumber))
              ]),
              _vm._v(" to report this payment.\n    ")
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "bill-pay-success__receipt gds-space-stack-l" },
          [
            _c(
              "p",
              {
                staticClass:
                  "gds-body-small gds-align--text-center gds-text-grey gds-space-stack-s"
              },
              [_vm._v("Confirmation Number")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "gds-body-normal gds-align--text-center" }, [
              _vm._v(_vm._s(_vm.confirmationNumber))
            ])
          ]
        ),
        _vm._v(" "),
        _vm.isRecurringPayments && _vm.recurringPaymentSuccess
          ? _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
              _vm._v(
                "\n      Your balance due will be withdrawn 5 days before the due date. Card payments have a $2,500 per month maximum. To\n      avoid cancelled payments, use a bank account for the payment method if your monthly bill might exceed $2,500.\n    "
              )
            ])
          : _vm.isRecurringPayments && !_vm.recurringPaymentSuccess
          ? _c(
              "div",
              { staticClass: "gds-font-size-l gds-space-stack-l gds-text-red" },
              [
                _c("div", { staticClass: "gds-space-stack-m" }, [
                  _vm._v(
                    "\n        There was an unexpected error saving your recurring payment.\n      "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "gds-display-0 gds-link gds-link--bold",
                    attrs: { href: "/account/settings#autopay" }
                  },
                  [
                    _vm._v(
                      "Click here to setup or manage\n        recurring auto payments"
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "bill-pay-success__button-wrap gds-align--text-center"
          },
          [
            _c(
              "button",
              {
                staticClass: "bill-pay-success__button gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.ReturnToSender("/make-payment")
                  }
                }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/bill-pay-complete.svg",
          alt: "successful payment"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }