 <template>
  <span>
    <UsageDetailCheckboxesModal
    v-if="modalVisible"
    :config="config"
    :modalTitle="modalTitle"
    :closeModal="closeModal"
    :checkboxChanged="checkboxChanged"/>

    <DateNavigationButton
     v-if="!isMobbileView && !isTabletView"
    :disabled="disabled"
    :buttonText="buttonText"
    :addBackground="addBackground"
    :buttonClicked="onButtonClicked"
    :buttonWidth="dateNavigationButtonWidth"
    class="usage-detail__date-navigation-item"/>

    <DateNavigationButtonMobile
     v-if="isMobbileView || isTabletView"
    :disabled="disabled"
    :buttonText="buttonText"
    :addBackground="addBackground"
    :buttonClicked="onButtonClicked"
    class="usage-detail__date-navigation-item"/>
  </span>
</template>

<script>
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageDetailCheckboxesModal from "./UsageDetailCheckboxesModal";
import DateNavigationButton from "../../../../components/generic/DateNavigationButton";
import DateNavigationButtonMobile from "../../../../components/generic/DateNavigationButtonMobile";

export default {
  name: "UsageDetailCheckboxes",
  mixins: [MediaQueryMixin],
  components: {
    DateNavigationButton,
    DateNavigationButtonMobile,
    UsageDetailCheckboxesModal
  },
  props: {
    config: Object,
    disabled: Boolean,
    buttonText: String,
    modalTitle: String,
    addBackground: Boolean,
    checkboxChanged: Function
  },
  data() {
    return {
      modalVisible: false,
      dateNavigationButtonWidth: this.config.buttonWidth || '200px'
    };
  },
  methods: {
    onButtonClicked() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    }
  },
  computed: {
    isMobbileView() {
      return this.deviceIsSmall;
    },
    isTabletView() {
      return this.deviceIsMedium || this.deviceIsLarge;
    }
  },
};
</script>

<style scoped>
</style>