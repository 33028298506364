var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "settings-editor my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
        },
        [
          _c(
            "h2",
            { staticClass: "settings-editor__title gds-space-stack-ml" },
            [_vm._v("Email")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "edit-close-button-combo" }, [
            !_vm.editing
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.editing = true
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Edit")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editing && !_vm.pendingSave
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.editing = false
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Close")]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.editing
        ? _c("div", [
            _c("div", { staticClass: "gds-font-size-m gds-space-stack-ml" }, [
              _vm._v(_vm._s(_vm.userInfo.email))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editing
        ? _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.Save()
                }
              }
            },
            [
              _c(
                "fieldset",
                { staticClass: "gds-fieldset gds-space-stack-m" },
                [
                  _c("label", { staticClass: "gds-input-field" }, [
                    _c("span", { staticClass: "gds-input-field__label" }, [
                      _vm._v("Email")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editedEmail,
                          expression: "editedEmail"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email|max:74",
                          expression: "'required|email|max:74'"
                        }
                      ],
                      key: "email address",
                      staticClass: "gds-input-field__input",
                      attrs: {
                        maxlength: "74",
                        name: "email address",
                        type: "text",
                        placeholder: "Type Here..."
                      },
                      domProps: { value: _vm.editedEmail },
                      on: {
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.editedEmail = $event.target.value
                          },
                          function($event) {
                            _vm.touched = true
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.first("email address")
                      ? _c("div", { staticClass: "validation-error" }, [
                          _vm._v(_vm._s(_vm.errors.first("email address")))
                        ])
                      : _vm._e()
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "gds-button gds-round gds-space-stack-m",
                  class: { "gds-loading": _vm.pendingSave },
                  attrs: {
                    type: "submit",
                    disabled:
                      _vm.pendingSave || !_vm.touched || _vm.errors.any()
                  }
                },
                [
                  _c("span", { staticClass: "gds-button__text" }, [
                    _vm._v("Save")
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errorSave && _vm.emailAlreadyExists
                ? _c(
                    "div",
                    { staticClass: "form-message-box gds-space-stack-m" },
                    [_vm._v("A user with this email already exists.")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.errorSave && !_vm.emailAlreadyExists
                ? _c(
                    "div",
                    { staticClass: "form-message-box gds-space-stack-m" },
                    [_vm._v("There was an error updating your email.")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }