var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-account__login-page-wrap" }, [
    !_vm.enrollSent
      ? _c("section", { staticClass: "my-account__login-form-wrap" }, [
          _c("div", { staticClass: "gds-display-2 gds-space-stack-l" }, [
            _vm._v("Enroll in Paperless Billing")
          ]),
          _vm._v(" "),
          _vm.loadState === undefined
            ? _c("div", { staticClass: "table-loading" })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "error"
            ? _c("div", [
                _vm._v(
                  "The token provided does not appear to be valid. If you copied the link from an email, please make sure you copied the entire URL."
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "complete"
            ? _c("div", [
                _c(
                  "form",
                  {
                    staticClass: "global-panel__form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.Submit()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "gds-space-stack-l" }, [
                      _vm._v(
                        "By selecting an account and clicking submit, you will become enrolled in paperless billing and emails will be sent to: " +
                          _vm._s(_vm.tokenAccounts[0].email)
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.tokenAccounts, function(account) {
                      return _c(
                        "div",
                        {
                          key: account.accountNumber,
                          staticClass: "gds-space-stack-m"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "gds-checkbox gds-checkbox-thin gds-checkbox-flex"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: account.checked,
                                    expression: "account.checked"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(account.checked)
                                    ? _vm._i(account.checked, null) > -1
                                    : account.checked
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = account.checked,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            account,
                                            "checked",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            account,
                                            "checked",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(account, "checked", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-checkbox__faux" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "gds-font-size-m gds-text-grey"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(account.custom_premise_address) +
                                      ", " +
                                      _vm._s(account.custom_premise_city) +
                                      "\n              "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n              Acct #: " +
                                      _vm._s(account.accountNumber) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "gds-space-stack-l" }, [
                      _vm.errorSubmit
                        ? _c(
                            "div",
                            {
                              staticClass: "gds-helper-text-error",
                              attrs: { id: "submission_error" }
                            },
                            [_vm._v(_vm._s(_vm.errorSubmit))]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "gds-space-stack-m" }, [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button",
                          class: { "gds-loading": _vm.pendingSubmit },
                          attrs: { disabled: _vm.pendingSubmit, type: "submit" }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Enroll")
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.enrollSent
      ? _c(
          "section",
          { staticClass: "my-account__login-form-wrap gds-align--text-center" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.checkedAccounts.length > 1
              ? _c("div", { staticClass: "gds-display-1 gds-space-stack-l" }, [
                  _vm._v("Your accounts were enrolled in Paperless Billing.")
                ])
              : _c("div", { staticClass: "gds-display-1 gds-space-stack-l" }, [
                  _vm._v("Your account was enrolled in Paperless Billing.")
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
              _vm._v("You will be returned to the GMP homepage.")
            ]),
            _vm._v(" "),
            _vm._m(1)
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/message-sent.svg",
          alt: "accounts enrolled"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-m global-panel__form" }, [
      _c("a", { staticClass: "gds-button", attrs: { href: "/" } }, [
        _vm._v("Done")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }