<template>
  <div>
    <transition name="slat-message-fade">
      <div v-if="scheduledPayments && scheduledPayments.length" class="gds-space-stack-l scheduled-payments-list" :class="{ 'table-loading': isRefreshing }">
        <div
          v-for="payment of scheduledPayments"
          :key="payment.confirmationNumber"
          class="form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-s gds-flex-container gds-flex-container--space-between"
        >
          <div class="form-message-box--left gds-body-normal">
            <span>A payment of</span>
            <span>{{payment.totalPaymentAmount | currency}}</span>
            <span>{{payment.methodSlug}}</span>
            <template v-if="payment.status==='SCHEDULED'">
              <span v-if="isBeforeToday(payment.scheduledPaymentDate)">was scheduled for {{payment.scheduledPaymentDate | dateFull}}. It will be processed on the next business day.</span>
              <span v-else>is scheduled for {{payment.scheduledPaymentDate | dateFull}}.</span>
            </template>
            <span v-else>is pending. Please allow up to one business day for your payment to be reflected in the current balance.</span>
          </div>
          <div v-if="payment.cancellable" class="form-message-box--right">
            <button @click="removingPayment = payment" type="button" class="gds-button gds-text-button">
              <span class="form-message-box--blue__text-button-text gds-button__text gds-font-size-m">Cancel Payment</span>
            </button>
          </div>
        </div>
      </div>
    </transition>
    <modal-cancel-scheduled :visible="removingPayment !== undefined" :payment="removingPayment" @complete="RemoveComplete" />
  </div>
</template>

<script>
import GMPAPI from '../../../services/gmpapi';
import ModalCancelScheduled from "./ModalCancelScheduled.vue";
import { differenceInDays } from "date-fns";

export default {
  name: "WidgetScheduledPayments",
  components: {
    ModalCancelScheduled,
  },
  data() {
    return {
      scheduledPayments: [],
      userWallets: [],
      removingPayment: undefined,
      isRefreshing: false,

      isBeforeToday: (date) => {
        return differenceInDays(new Date(date), new Date()) < 0;
      },
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    try {
      const wallets = await GMPAPI.GetWallets(this.currentAccount.accountNumber);
      this.userWallets = wallets;
    } catch {
      console.log("Error getting user wallets");
    }
    await this.RefreshHistory();
  },
  methods: {
    async RefreshHistory() {
      this.isRefreshing = true;
      try {
        const recentHistory = await GMPAPI.GetBillingHistory(this.currentAccount.accountNumber, 3);
        for (const payment of recentHistory) {
          let foundWallet;
          if (payment.walletId && this.userWallets && this.userWallets.length > 0) {
            foundWallet = this.userWallets.find(wallet => wallet.walletId === payment.walletId);
          }
          if (foundWallet) {
            if (foundWallet.accountNickName) {
              payment.methodSlug = "with " + foundWallet.accountNickName;
            } else {
              payment.methodSlug = "with " + this.$options.filters.walletSlug(foundWallet, true);
            }
          } else {
            if (payment.paymentType === "ACH") {
              payment.methodSlug = "with bank account ****" + payment.debitAccount;
            } else if (payment.paymentType === "ATM") {
              // Note it looks like we just get CC for ATM payments
              payment.methodSlug = "with ATM card ****" + payment.debitAccount;
            } else if (payment.paymentType === "CC") {
              payment.methodSlug = "with card ****" + payment.debitAccount;
            }
          }
        }
        this.scheduledPayments = recentHistory.filter(item => item.status === "PENDING" || item.status === "SCHEDULED");
      } catch {
        this.scheduledPayments = [];
      } finally {
        this.isRefreshing = false;
      }
    },
    async RemoveComplete(needsRefresh) {
      this.removingPayment = undefined;
      if (needsRefresh) {
        await this.RefreshHistory();
      }
    },
  },
  watch: {
    async currentAccount() {
      await this.RefreshHistory();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>