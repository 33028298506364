<template>
  <div class="outage-breadcrumb-header gds-flex-container gds-flex-container--left">
    <router-link to="/" class="breadcrumb__outage-home">Outage Center</router-link>
    <div v-if="incident" class="breadcrumb__chevrons gds-flex-container">
      <svg class="gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
      </svg>
      <router-link :to="{ name: 'Town', params: {townname: incidentTownSlug} }">{{ incident.town }} VT</router-link>
      <svg class="gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
      </svg>
      {{ incident.street | titlecase }}
    </div>
    <div v-else-if="town" class="breadcrumb__town-state gds-flex-container">
      <svg class="gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
      </svg>
      {{ town.town_name }} VT
    </div>
    <div v-else-if="planned" class="breadcrumb__town-state gds-flex-container">
      <svg class="gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
      </svg>
      <router-link to="/planned-outages">Planned Outages</router-link>
    </div>
    <div v-if="planned && planned !== true" class="breadcrumb__town-state gds-flex-container">
      <svg class="gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
      </svg>
      {{ planned.outageLocation || "Unknown location" }}
    </div>
  </div>
</template>

<script>
import { HyphenateTown } from "../../utilities";
export default {
  name: "OutageBreadcrumbs",
  components: {
  },
  props: ["town", "incident", "planned"],
  data() {
    return {
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    incidentTownSlug() {
      if (this.incident) {
        return HyphenateTown(this.incident.town);
      } else {
        return "";
      }
    }
  },
  async mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>