var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-detail__legend-item" }, [
    _c("div", {
      staticClass: "usage-detail__legend-item--circle",
      style: "background-color: " + _vm.color + ";"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "usage-dashboard__legend-text-container" }, [
      _vm.title
        ? _c("div", { staticClass: "usage-dashboard__legend-text" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.text
        ? _c("div", { staticClass: "usage-detail__legend-item--text" }, [
            _vm._v(_vm._s(_vm.text))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }