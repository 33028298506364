import { format } from "date-fns";
import rates from "./ratedefs";

const COLUMN_BORDER_RADIUS = 3;

export function GetAllRates(bins) {
  const updatedRates = rates.filter(rate => rate.name !== "consumedTotal");
  return updatedRates.filter(rate => bins.some(item => item[rate.name] !== undefined));
}

/**
 * 
 * @param {any[]} bins The raw bin data from the API
 * @param {string[]} visibleLabels Names of the visible rates to start with
 * @param {function} clickCallback Function that can be called when clicking an interval
 */
export function FormatChartData(bins, interval, mini, isUsageGraph) {
  /** Interim sorting object holding arrays of series datapoints, keyed by rate name */
  const datapoints = {};
  // Initialize empty arrays for all rate names
  for (const rate of rates) {
    datapoints[rate.name] = [];
  }

  /** Array of date labels for x axis */
  const labels = [];
  /** Array of bands to act as hover/click targets */
  const dates = [];

  // For each date interval:
  for (const bin of bins) {
    let dateFormatted;
    switch (interval) {
      case "yearly":
        dateFormatted = format(bin.date, "yyyy");
        break;
      case "monthly":
        dateFormatted = format(bin.date, "MMM'<br>'yyyy");
        break;
      case "daily":
        dateFormatted = format(bin.date, "M/d");
        break;
      case "hourly":
        dateFormatted = format(bin.date, "ha'<br>'M/d");
        break;
    }
    // Add the formatted date to the labels array
    labels.push(dateFormatted);
    // Add the raw date to the dates array
    dates.push(bin.date);
    // Add each rate's datapoint to its series
    for (const rate of rates) {
      datapoints[rate.name].push(bin[rate.name] || 0);
    }
  }
  /** Array of chart series, with data gathered above */
  const series = [];
  for (const rate of rates) {
    // Make a series for each rate - we'll filter visibility later
    if (rate.name === 'consumedTotal' && isUsageGraph) {
      continue;
    }

    const ser = {
      name: rate.label,
      measurement: rate.measurement,
      type: rate.line ? "line" : "column",
      yAxis: rate.line ? 1 : 0,
      marker: {
        enabled: false,
      },
      states: !mini ? {} : {
        select: {
          color: rate.gradientColor,
        }
      },
      color: mini ? rate.disabledColor : rate.gradientColor,
      stack: rate.stack,
      data: datapoints[rate.name],
      // Start all series hidden, they'll get shown in RefreshLayers
      visible: false,
    };
    if (rate.rounding === "top") {
      ser.borderRadiusTopLeft = COLUMN_BORDER_RADIUS;
      ser.borderRadiusTopRight = COLUMN_BORDER_RADIUS;
    } else if (rate.rounding === "bottom") {
      ser.borderRadiusBottomLeft = COLUMN_BORDER_RADIUS;
      ser.borderRadiusBottomRight = COLUMN_BORDER_RADIUS;
    } else {
      ser.borderRadius = COLUMN_BORDER_RADIUS;
    }
    series.push(ser);

  }
  return {
    labels,
    series,
    dates
  };
}