var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-login" }, [
    !_vm.loggedInUser && !_vm.isMobile
      ? _c("div", [
          _c(
            "button",
            {
              staticClass:
                "header-login-button gds-button gds-compact gds-nav-button",
              on: { click: _vm.ToggleLoginDrawer }
            },
            [_vm._v("Log In")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loggedInUser && _vm.isMobile
      ? _c(
          "div",
          { staticClass: "menu-group menu-group__tan-background-mobile" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "button-row" }, [
              _c(
                "button",
                {
                  staticClass: "gds-button gds-button-round gds-compact",
                  on: { click: _vm.ToggleLoginDrawer }
                },
                [_vm._v("Log In")]
              ),
              _vm._v(" "),
              _vm._m(1)
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loggedInUser
      ? _c("div", [
          !_vm.isMobile
            ? _c(
                "button",
                {
                  staticClass:
                    "header--user-toggle-button gds-button gds-nav-button gds-primary caret",
                  class: { "caret-down": !_vm.userMenuVisible },
                  on: { click: _vm.ToggleUserMenu }
                },
                [_vm._v(_vm._s(_vm.loggedInUser.fullName))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.userMenuVisible || _vm.isMobile
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.ToggleUserMenu,
                      expression: "ToggleUserMenu"
                    }
                  ],
                  staticClass: "header-login__settings"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "menu-group menu-group__tan-background-mobile"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "menu-group__header-row gds-space-stack-default"
                        },
                        [
                          _c("div", { staticClass: "title " }, [
                            _c("span", [_vm._v("Signed in as ")]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                attrs: {
                                  title:
                                    "Login email: " + _vm.loggedInUser.email
                                }
                              },
                              [_vm._v(_vm._s(_vm.loggedInUser.fullName))]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "action-button gds-button gds-button-text",
                              on: {
                                click: function($event) {
                                  return _vm.LogOut()
                                }
                              }
                            },
                            [_vm._v("Log Out")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass:
                            "header-login__settings__nav-list gds-body-large"
                        },
                        [
                          !_vm.isAdmin || !_vm.isAdminLite
                            ? _c("li", [
                                _c("a", { attrs: { href: "/account" } }, [
                                  _vm._v("Account Dashboard")
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._m(2),
                          _vm._v(" "),
                          !_vm.isAdmin && !_vm.isAdminLite
                            ? _c("li", [
                                _vm.numLinkedAccounts > 1
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "/user-profile/accounts"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "Linked Accounts (" +
                                            _vm._s(_vm.numLinkedAccounts) +
                                            ")"
                                        )
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "/user-profile/accounts"
                                        }
                                      },
                                      [_vm._v("Add a Linked Account")]
                                    )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  (!_vm.isAdmin || !_vm.isAdminLite) &&
                  _vm.numLinkedAccounts > 1
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "menu-group menu-group__tan-background-mobile"
                        },
                        [
                          _c("div", { staticClass: "menu-group__header-row" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Current Account")
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "action-button gds-button gds-button-text",
                                on: {
                                  click: function($event) {
                                    return _vm.ToggleAccountSwitcher()
                                  }
                                }
                              },
                              [_vm._v("Switch")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("widget-account-switcher", {
                            attrs: { minified: true }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menu-group__header-row" }, [
      _c("div", { staticClass: "title" }, [_vm._v("My Account")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "gds-button gds-button-round gds-button-outline gds-compact",
        attrs: { href: "/account/create/" }
      },
      [
        _c("span", { staticClass: "gds-button__text" }, [
          _vm._v("Create Account")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "/user-profile" } }, [_vm._v("User Settings")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }