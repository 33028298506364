<template>
  <div class="subpage-dashboards subpage-billing-payment--dashboard">
    <div class="usage-page--interior-status-container">
        <flow-error v-if="loadState === 'error'" name="Usage Alerts" state="error" />
        <flow-error v-if="loadState === 'maintenance'" name="Usage Alerts" state="maintenance" />
        <flow-error v-if="loadState === 'unavailable'" name="Usage Alerts" state="unavailable"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <div class="subpage-dashboards--left-spacer">
      <div v-if="loadState === 'complete'">
        <h1 class="my-account__title gds-space-stack-xl">Notifications</h1>
        <h2 class=" gds-display-1 gds-space-stack-m">Configure Usage Alerts</h2>
        <p class="gds-font-size-l notification-usage-alert--heading">Usage notifications are sent once you have used 50%,
          75% and 100% of the threshold in a billing period.</p>
        <div class="notification-usage-alert--card-container">
          <div :class="['notification-usage-alert--card', { 'selected': areThresholdsBySeason }]"
            @click="onChangeThresholdType('seasonal')">
            <img v-if="isSingleYearRoundThreshold" class="notification-usage-alert--checkbox"
              src="/wp-content/themes/gmptwentynineteen/assets/images/checkbox-circle.svg">
            <img v-if="areThresholdsBySeason" class="notification-usage-alert--checkbox"
              src="/wp-content/themes/gmptwentynineteen/assets/images/checkmark-in-green-circle.svg">
            <p class="notification-usage-alert--checkbox-label">Different Threshold for Each Season</p>
          </div>
          <div :class="['notification-usage-alert--card', { 'selected': isSingleYearRoundThreshold }]"
            @click="onChangeThresholdType('annual')">
            <img v-if="areThresholdsBySeason" class="notification-usage-alert--checkbox"
              src="/wp-content/themes/gmptwentynineteen/assets/images/checkbox-circle.svg">
            <img v-if="isSingleYearRoundThreshold" class="notification-usage-alert--checkbox"
              src="/wp-content/themes/gmptwentynineteen/assets/images/checkmark-in-green-circle.svg">
            <p class="notification-usage-alert--checkbox-label">Single Threshold Year Round</p>
          </div>
        </div>
        <div class="notification-usage-alert--toggle-container">
          <fieldset class="gds-fieldset gds-space-inline-m">
            <label class="gds-switch">
              <input  @click="toggleAutomaticThresholds()" type="checkbox" v-model="isSettingThresholdsAutomatically" />
              <span class="gds-switch__faux"></span>Set My Thresholds Automatically
            </label>
          </fieldset>
        </div>
        <section v-for='(season, i) in seasons' :key='season.threshold'>
          <div v-if="areThresholdsBySeason" class="notification-usage-alert--threshold-container">
            <div class="notification-usage-alert--threshold-image-container">
              <img class="notification-usage-alert--threshold-image" :src="season.iconUrl">
              <p class="gds-font-demi notification-usage-alert--threshold-label">{{ season.threshold }}</p>
            </div>
            <div :class=rangeFieldStyle>
              <p class="notification-usage-alert--range-field">{{ season.months }}</p>
              <p v-if="!isSettingThresholdsAutomatically" class="notification-usage-alert--range-field">Suggested range: {{ season.suggestedSeasonalLow }} - {{
                season.suggestedSeasonalHigh }} kWh</p>
            </div>
            <div class="notification-usage-alert--kwh-container">
               <input
                  v-model="seasons[i].seasonThresholdAmount"
                  @input="onInputThresholdAmount"
                  @click="onClickThresholdAmount"
                  maxlength="7"
                  :disabled="isSingleYearRoundThreshold"
                  class="gds-input-field__input  notification-usage-alert--threshold-amount"
                  type="text"
                  v-validate="'required|numeric'"
                  :name="'threshold amount' + i"
                />
                <span class="notification-usage-alert--kWh-label">kWh</span>
              </div>
          </div>
          <div v-if="errors.first('threshold amount' + i)" class="validation-error">Threshold amount is required and must be numeric</div>
        </section>
        <section>
          <div v-if="isSingleYearRoundThreshold" class="notification-usage-alert--annual-threshold-container">
            <div class="notification-usage-alert--threshold-image-container">
              <img class="notification-usage-alert--threshold-image"
                src="/wp-content/themes/gmptwentynineteen/assets/images/calendar-white-and-green.svg">
              <p class="notification-usage-alert--annual-threshold-label">Annual Threshold</p>
            </div>
            <div class="notification-usage-alert--annual-range-container">
              <p v-if="!isSettingThresholdsAutomatically" class="notification-usage-alert--annual-range-field">Suggested range: {{ suggestedYearRoundLow }} - {{
                suggestedYearRoundHigh }} kWh</p>
              <form @submit.prevent="Save()">
                <div class="notification-usage-alert--kwh-container">        
                  <input v-model="annualThresholdAmount"
                  @input="onInputThresholdAmount()"
                  @click="onClickThresholdAmount"
                  maxlength="7"
                  :disabled="areThresholdsBySeason"
                  class="gds-input-field__input notification-usage-alert--annual-threshold-amount"
                  type="text"
                  v-validate="'required|numeric'"
                  name= "threshold amount"
                  />
                  <span class="notification-usage-alert--kWh-label">kWh</span>
               </div>
              </form>
              <div v-if="errors.first('threshold amount')" class="validation-error" style="margin-left:2.5rem">Threshold amount must be numeric</div>
            </div>
          </div>
        </section>
        <div class="notification-usage-alert--button-container">
          <button type="submit" class="gds-button gds-secondary gds-space-stack-m notification-usage-alert--cancel-button"
            @click="onCancel()">
            <span class="gds-button__text">Cancel</span>
          </button>

          <button type="submit" :disabled="pendingSave || !touched" :class="{ 'gds-loading': pendingSave }"
            @click="onSave()" class="gds-button gds-space-stack-m">
            <span class="gds-button__text">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import GMPAPI from '../../../services/gmpapi';
import { DumpError } from '../../../utilities';
import { GetComponentStatus } from "../../../services/statuspage";

export default {
  name: "SubpageNotificationUsageAlert",
  data() {
    return {
      loadState: undefined,
      pendingSave: false,
      touched: false,
      isSettingThresholdsAutomatically: undefined,
      areThresholdsBySeason: true,
      suggestedThresholds: undefined,
      accountThresholds: undefined,
      suggestedYearRoundLow: undefined,
      suggestedYearRoundAverage: undefined,
      suggestedYearRoundHigh: undefined,
      annualThresholdAmount: undefined,
      rangeFieldStyle: 'notification-usage-alert--range-container-expanded',
      seasons: [
        {
          threshold: 'Winter Threshold',
          months: 'Dec, Jan, Feb',
          iconUrl: '/wp-content/themes/gmptwentynineteen/assets/images/winter.svg',
          suggestedSeasonalLow: undefined,
          suggestedSeasonalHigh: undefined,
          seasonThresholdAmount: undefined
        },
        {
          threshold: 'Spring Threshold',
          months: 'Mar, Apr, May',
          iconUrl: '/wp-content/themes/gmptwentynineteen/assets/images/spring.svg',
          suggestedSeasonalLow: undefined,
          suggestedSeasonalHigh: undefined,
          seasonThresholdAmount: undefined
        },
        {
          threshold: 'Summer Threshold',
          months: 'Jun, Jul, Aug',
          iconUrl: '/wp-content/themes/gmptwentynineteen/assets/images/summer.svg',
          suggestedSeasonalLow: undefined,
          suggestedSeasonalHigh: undefined,
          seasonThresholdAmount: undefined
        },
        {
          threshold: 'Fall Threshold',
          months: 'Sep, Oct, Nov',
          iconUrl: '/wp-content/themes/gmptwentynineteen/assets/images/fall.svg',
          seasonalAverage: undefined,
          seasonalHigh: undefined,
          seasonThresholdAmount: undefined
        },
      ]
    };
  },
  async mounted() {
    try {
      const status = await GetComponentStatus("Usage");
      if (status === "operational" || this.isAdmin) {
        await this.init();
      } else {
        this.loadState = status;
      }
    } catch (error) {
      this.loadState = 'error';
      DumpError("Error mounting SubpageNotificationsUsageAlert component ", error);
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isSingleYearRoundThreshold() {
      return !this.areThresholdsBySeason;
    },
  },
  watch: {
    async currentAccount() {
      await this.init();
    }
  },
  methods: {
    async readData() {
      this.suggestedThresholds = await GMPAPI.GetSuggestedUsageThresholds(this.currentAccount.accountNumber);
      if (this.suggestedThresholds) {
        this.setSuggestedThresholds(this.suggestedThresholds);
      }

      this.accountThresholds = await GMPAPI.GetAccountUsageThresholds(this.currentAccount.accountNumber);
      if (this.accountThresholds &&
          (this.accountThresholds.thresholdPeriod1 > 0 || this.accountThresholds.thresholdPeriod2 > 0 ||
           this.accountThresholds.thresholdPeriod3 > 0 || this.accountThresholds.thresholdPeriod4 > 0)) {
        this.setAccountThresholdAmounts(this.accountThresholds);
      } else {
        this.setAccountThresholdAmounts(['', '', '', ''])
        this.isSettingThresholdsAutomatically = false;
        this.setRangeFieldStyle();     
        this.touched = true; 
      };
    },

    setThresholdPeriod() {
      if (this.areAllThresholdsSame(this.accountThresholds)) {
        this.areThresholdsBySeason = false;
        this.annualThresholdAmount = this.accountThresholds.thresholdPeriod1;
      } else {
        this.areThresholdsBySeason = true;
      }
    },

    setManualOrAutoThreshold() {
      if (this.doThresholdsMatchSuggestedThresholds(this.suggestedThresholds)) {
        this.isSettingThresholdsAutomatically = true;
      } else {
        this.isSettingThresholdsAutomatically = false;
      }
    },

    async init() {
      this.loadState = undefined;
      await this.readData();
      this.setThresholdPeriod();
      this.setManualOrAutoThreshold();
      this.setRangeFieldStyle();
      this.loadState = 'complete';  
    },

    areAllThresholdsSame(accountThresholds) {
      return accountThresholds.thresholdPeriod1 && accountThresholds.thresholdPeriod2 &&
             accountThresholds.thresholdPeriod3 && accountThresholds.thresholdPeriod4 &&
             accountThresholds.thresholdPeriod1 === accountThresholds.thresholdPeriod2 && 
             accountThresholds.thresholdPeriod2 === accountThresholds.thresholdPeriod3 &&
             accountThresholds.thresholdPeriod3 === accountThresholds.thresholdPeriod4;
    },

    areThresholdValuesEmpty() {
      if (this.areThresholdsBySeason) {
        if (this.seasons[0].seasonThresholdAmount === '' &&
            this.seasons[1].seasonThresholdAmount === '' &&
            this.seasons[2].seasonThresholdAmount === '' &&
            this.seasons[3].seasonThresholdAmount === '') {
              return true;
        } 
      } else {
        if (this.annualThresholdAmount === '') {
          return true;
        }
      }
      return false;
    },

    toggleAutomaticThresholds() {
      this.isSettingThresholdsAutomatically = !this.isSettingThresholdsAutomatically;
      this.touched = true;
      this.setThresholdAmounts();
      this.setRangeFieldStyle();      
    },

    setRangeFieldStyle() {
      if (this.isSettingThresholdsAutomatically) {
        this.rangeFieldStyle = 'notification-usage-alert--range-container';
      } else {
        this.rangeFieldStyle = 'notification-usage-alert--range-container-expanded';
      }
    },

    setThresholdAmounts() {
      if (this.isSettingThresholdsAutomatically) {
        if (this.areThresholdsBySeason) {
          this.setSeasonalThresholds(this.suggestedThresholds);
        } else {
          this.annualThresholdAmount = this.suggestedYearRoundAverage;
        }
      } else {
        if (this.areThresholdsBySeason) {
          this.setAccountThresholdAmounts(this.accountThresholds);
        } else {
          this.annualThresholdAmount = this.accountThresholds.thresholdPeriod1;
        }
      }
    },

    setSeasonalThresholds(suggestedThresholds) {
      this.seasons[0].seasonThresholdAmount = suggestedThresholds.winterAverage;
      this.seasons[1].seasonThresholdAmount = suggestedThresholds.springAverage;
      this.seasons[2].seasonThresholdAmount = suggestedThresholds.summerAverage;
      this.seasons[3].seasonThresholdAmount = suggestedThresholds.fallAverage;
    },

    doThresholdsMatchSuggestedThresholds(suggestedThresholds) {
      if (this.isSingleYearRoundThreshold) {
        return this.annualThresholdAmount === this.seasons[0].seasonThresholdAmount;
      } else {
        return  this.seasons[0].seasonThresholdAmount === suggestedThresholds.winterAverage &&
                this.seasons[1].seasonThresholdAmount === suggestedThresholds.springAverage &&
                this.seasons[2].seasonThresholdAmount === suggestedThresholds.summerAverage &&
                this.seasons[3].seasonThresholdAmount === suggestedThresholds.fallAverage;
      }
    },

    setSuggestedThresholds(suggestedThresholds) {
      this.seasons[0].suggestedSeasonalLow = suggestedThresholds.winterLow;
      this.seasons[0].suggestedSeasonalHigh = suggestedThresholds.winterHigh;
      this.seasons[1].suggestedSeasonalLow = suggestedThresholds.winterLow;
      this.seasons[1].suggestedSeasonalHigh = suggestedThresholds.springHigh;
      this.seasons[2].suggestedSeasonalLow = suggestedThresholds.winterLow;
      this.seasons[2].suggestedSeasonalHigh = suggestedThresholds.summerHigh;
      this.seasons[3].suggestedSeasonalLow = suggestedThresholds.winterLow;
      this.seasons[3].suggestedSeasonalHigh = suggestedThresholds.fallHigh;
      this.suggestedYearRoundLow = suggestedThresholds.yearRoundLow;
      this.suggestedYearRoundAverage = suggestedThresholds.yearRoundAverage;
      this.suggestedYearRoundHigh = suggestedThresholds.yearRoundHigh;
    },

    setAccountThresholdAmounts(thresholdAmounts) {
      this.seasons[0].seasonThresholdAmount = thresholdAmounts.thresholdPeriod1;
      this.seasons[1].seasonThresholdAmount = thresholdAmounts.thresholdPeriod2;
      this.seasons[2].seasonThresholdAmount = thresholdAmounts.thresholdPeriod3;
      this.seasons[3].seasonThresholdAmount = thresholdAmounts.thresholdPeriod4;
    },

    onChangeThresholdType(type) {
      if (type === 'seasonal') {
        this.areThresholdsBySeason = true;
      } else {
        this.areThresholdsBySeason = false;
      }

      this.setThresholdAmounts();
    },

    onShowThreshold() {
      this.showThresholds = !this.showThresholds;
      this.showThresholdLabel = this.showThresholds ? 'hide' : 'show';
    },

    onInputThresholdAmount() {
      this.touched = true;
    },

    onClickThresholdAmount() {
      if (this.isSettingThresholdsAutomatically) {
        this.isSettingThresholdsAutomatically = false;
      }
    },

    onCancel() {
      this.$router.push({path: '/notifications'});
      if (!this.touched || this.isSettingThresholdsAutomatically) {
        return;
      }

      if (this.accountThresholds) {
        this.setAccountThresholdAmounts(this.accountThresholds);
      };
    },

    buildUsageThresholdRecord() {
      if (this.areThresholdsBySeason) {
        return {
          id: this.accountThresholds.id,
          accountNumber: this.currentAccount.accountNumber,
          thresholdPeriod1: parseInt(this.seasons[0].seasonThresholdAmount),
          thresholdPeriod2: parseInt(this.seasons[1].seasonThresholdAmount),
          thresholdPeriod3: parseInt(this.seasons[2].seasonThresholdAmount),
          thresholdPeriod4: parseInt(this.seasons[3].seasonThresholdAmount) };
      } else {
        return {
          id: this.accountThresholds.id,
          accountNumber: this.currentAccount.accountNumber,
          thresholdPeriod1: parseInt(this.annualThresholdAmount),
          thresholdPeriod2: parseInt(this.annualThresholdAmount),
          thresholdPeriod3: parseInt(this.annualThresholdAmount),
          thresholdPeriod4: parseInt(this.annualThresholdAmount) };
      }
    },

    async onSave() {
      try {
        this.pendingSave = true;
        if (await this.$validator.validateAll()) {
          const usageThresholds = this.buildUsageThresholdRecord();

          /* If id is present, usage threshold record has already been saved/created in DB. 
             In this case, an update operation is issued otherwise a create is executed. */
          if (usageThresholds.id) {
            this.accountThresholds = await GMPAPI.UpdateUsageThresholds(this.currentAccount.accountNumber, this.accountThresholds.id, usageThresholds);
          } else { 
            this.accountThresholds = await GMPAPI.CreateUsageThresholds(this.currentAccount.accountNumber, usageThresholds);
          }
        }
      } catch (err) {
        this.loadState = 'error';
        DumpError("Error mounting SubpageNotificationsUsageAlert component ", err);
      }
      this.pendingSave = false;
    }
  }
};
</script>