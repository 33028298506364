<template>
  <div class="outage-center-main">
    <div v-if="loadState === undefined" class="table-loading" />
    <div v-if="loadState === 'error'">
      <flow-error name="Outage Center" outagebox="true" state="error" />
    </div>
    <div v-if="loadState === 'maintenance'">
      <flow-error name="Outage Center" outagebox="true" state="maintenance" />
    </div>
    <div v-if="loadState === 'complete'" class="outage-center-main">
      <router-view />
    </div>
  </div>
</template>

<script>
import { GetComponentStatus } from '../../services/statuspage';
export default {
  name: "PageOutageCenter",
  components: {
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  data() {
    return {
      loadState: undefined,
    };
  },
  async mounted() {
    this.loadState = undefined;
    // Check for service outages
    const status = await GetComponentStatus("Outage");
    if (status === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>