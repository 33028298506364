<template>
  <div class="top-card-display__single-card top-card-display__single-card--usage">
    <div v-if="currentAccount">
      <div v-if="(loadState === 'complete')" class="top-banner-card gds-card gds-data-card top-banner-card--logged-in">
        <!--   Flat Rate Only Card -->
        <div v-if="(!isNetMetered && !isTOU)">
          <div v-if="currentBillingPeriod.consumedFromGrid !== undefined">
            <p class="gds-display-0 gds-space-stack-s">Usage This Billing Period</p>
            <p v-if="daysLeft > 0" class="gds-card__caption">
              {{ daysLeft }}
              <span v-if="daysLeft !== 1">days</span>
              <span v-if="daysLeft === 1">day</span>
              left
            </p>
          </div>
          <figure v-if="currentBillingPeriod.consumedFromGrid !== undefined" class="gds-align--block-center">
            <div @click="navUsage()" class="card__usage--kw-wrap gds-flex-container gds-clickable">
              <div class="card__usage--kw-number">{{ currentBillingPeriod.consumedFromGrid | numberCommaSingleDecimal }}</div>
              <div class="card__usage--kwh">kWh</div>
            </div>
            <img src="/wp-content/themes/gmptwentynineteen/assets/images/electric-plug.svg" alt="electric plug"
              class="gds-align--block-center" />
          </figure>
          <br><br>
          <div v-if="lastBillingPeriod !== undefined" class="card__usage--details">
            Last billing period you used a total of
            <span @click="navUsage()" class="gds-font-demi gds-clickable">{{ lastBillingPeriod.consumedFromGrid | numberCommaSingleDecimal }} kWh</span>.
          </div>
        </div>
        <!--   TOU Only Card -->
        <div v-if="(!isNetMetered && isTOU)">
          <div v-if="currentBillingPeriod.consumedFromGrid !== undefined">
            <p class="gds-card__caption">
              <span class="card__usage-title-text">Time of use </span>this billing period ({{ daysLeft }}
              <span v-if="daysLeft !== 1">days</span>
              <span v-if="daysLeft === 1">day</span>
              left)
            </p>
          </div>
          <figure v-if="currentBillingPeriod.consumedFromGrid !== undefined" class="gds-align--block-center">
            <div class="card-usage-content">
              <div @click="navUsage()" class="card__usage--kw-wrap gds-flex-container gds-clickable">
                <div>
                  <div class="card__usage--kw-number-small">Off Peak {{ currentBillingPeriod.totalKwhOffPeak | numberCommaSingleDecimal }} kWh</div>
                  <div class="card__usage--kw-number-small">On Peak {{ currentBillingPeriod.totalKwhOnPeak | numberCommaSingleDecimal }}
                    kWh</div>
                </div>
              </div>
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/clock.png" alt="clock"
                class="gds-align--block-center clock-image" />
              <div class="card__usage-footer">
                <p style="line-height:1">Last billing period you used:</p>
                <div class="prior-period-container">
                  <div class="card__usage--kw-number-small">Off Peak {{ lastBillingPeriod.totalKwhOffPeak | numberCommaSingleDecimal }}</div>
                  <div class="card__usage--kw-number-small">On Peak {{ lastBillingPeriod.totalKwhOnPeak | numberCommaSingleDecimal }}</div>
                </div>
              </div>
            </div>
          </figure>
        </div>
        <!--   Net Metering Card 1-->
        <figure v-if="(isNetMetered && netmeteringCardNumber === 1)" class="gds-align--block-center">
          <div class="usage-card">
            <p class="gds-card__caption">
              <span class="card__usage-title-text">Net energy use </span> this billing period ({{ daysLeft }}
              <span v-if="daysLeft !== 1">days</span>
              <span v-if="daysLeft === 1">day</span>
              left).
            </p>
            <div class="card__usage--kw-wrap gds-flex-container-column gds-clickable usage-section">
              <div class="card__usage-container @click=navUsage()">
                <p class="card__usage--kw-number">{{ currentBillingPeriod.netConsumption | numberCommaSingleDecimal }} kWh</p>
              </div>
            </div>
            <div class="usage-section">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/house-solar-panel.svg"
                alt="house solar panel" class="gds-align--block-center house-with-solar-image" />
              <br><br>
            </div>
            <div class="arrow-container-right-justify usage-section">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/right-arrow-in-circle.png"
                alt="right arrow in circle" class="arrow-image gds-align--block-center"
                @click="scrollNextCard(0)" />
            </div>
            <div class="scroll-dot-container">
              <div v-for="(scrollDot, index) in scrollDots">
                <span v-if="scrollDot === 0" class="scroll-dot"></span>
                <span v-if="scrollDot === 1" class="scroll-dot-emphasize"></span>
              </div>
            </div>
          </div>
        </figure>
        <!--   Net Metering Card 2-->
        <figure v-if="(isNetMetered && netmeteringCardNumber === 2)" class="gds-align--block-center">
          <div class="usage-card">
            <p class="gds-card__caption">
              <span class="card__usage-title-text">Energy generated </span>this billing period ({{ daysLeft }}
              <span v-if="daysLeft !== 1">days</span>
              <span v-if="daysLeft === 1">day</span>
              left).
            </p>
            <div class="card__usage--kw-wrap gds-flex-container-column gds-clickable">
              <div class="card__usage-container @click=navUsage()">
                <p class="card__usage--kw-number">{{ currentBillingPeriod.grossGenerated | numberCommaSingleDecimal }} kWh</p>
              </div>
            </div>
            <img src="/wp-content/themes/gmptwentynineteen/assets/images/solar-generation.png" alt="solar generation"
              class="gds-align--block-center solar-image" />
            <div class="card__usage-footer">
              <p><span class="card__usage-footer-text">{{ currentBillingPeriod.generationUsedByHome | numberCommaSingleDecimal }}</span>
                used by your home</p>
              <p><span class="card__usage-footer-text">{{ currentBillingPeriod.generationSentToGrid | numberCommaSingleDecimal }}</span>
                to the grid</p>
            </div>
            <div class="arrow-container">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/left-arrow-in-circle.png"
                alt="left arrow in circle" class="arrow-image gds-align--block-center"
                @click="scrollPreviousCard(1)" />
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/right-arrow-in-circle.png"
                alt="right arrow in circle" class="arrow-image gds-align--block-center"
                @click="scrollNextCard(1)" />
            </div>
            <div class="scroll-dot-container">
              <div v-for="(scrollDot, index) in scrollDots">
                <span v-if="scrollDot === 0" class="scroll-dot"></span>
                <span v-if="scrollDot === 1" class="scroll-dot-emphasize"></span>
              </div>
            </div>
          </div>
        </figure>
        <!--   Net Metering Card 3-->
        <figure v-if="(isNetMetered && netmeteringCardNumber === 3)" class="gds-align--block-center">
          <div class="usage-card">
            <p class="gds-card__caption">
              <span class="card__usage-title-text">Energy consumed</span> this billing period ({{ daysLeft }}
              <span v-if="daysLeft !== 1">days</span>
              <span v-if="daysLeft === 1">day</span>
              left).
            </p>
            <div class="card__usage--kw-wrap gds-flex-container-column gds-clickable">
              <div class="card__usage-container @click=navUsage()"">
                <p class=" card__usage--kw-number">{{ currentBillingPeriod.totalConsumption | numberCommaSingleDecimal }} kWh</p>
              </div>
            </div>
            <img src="/wp-content/themes/gmptwentynineteen/assets/images/plug.svg" alt="electric plug"
              class="gds-align--block-center plug-image" />
            <div class="card__usage-footer">
              <p><span class="card__usage-footer-text">{{ currentBillingPeriod.consumedFromGrid | numberCommaSingleDecimal }}</span>
                from
                the grid</p>
              <p><span class="card__usage-footer-text">{{ currentBillingPeriod.generationUsedByHome | numberCommaSingleDecimal }}</span>
                from generation</p>
            </div>
            <div class="arrow-container">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/left-arrow-in-circle.png"
                alt="left arrow in circle" class="arrow-image gds-align--block-center"
                @click="scrollPreviousCard(2)" />
              <img v-if="isTOU" src="/wp-content/themes/gmptwentynineteen/assets/images/right-arrow-in-circle.png"
                alt="right arrow in circle" class="arrow-image gds-align--block-center"
                @click="scrollNextCard(2)" />
            </div>
            <div class="scroll-dot-container">
              <div v-for="(scrollDot, index) in scrollDots">
                <span v-if="scrollDot === 0" class="scroll-dot"></span>
                <span v-if="scrollDot === 1" class="scroll-dot-emphasize"></span>
              </div>
            </div>
          </div>
        </figure>
        <!--   Net Metering / TOU Card 4-->
        <figure v-if="(isNetMetered && isTOU && netmeteringCardNumber === 4)" class="gds-align--block-center">
          <div class="usage-card">
          <div v-if="currentBillingPeriod.consumedFromGrid !== undefined">
            <p class="gds-card__caption">
              <span class="card__usage-title-text">Time of use</span> this billing period ({{ daysLeft }}
              <span v-if="daysLeft !== 1">days</span>
              <span v-if="daysLeft === 1">day</span>
              left).
            </p>
          </div>
          <figure v-if="currentBillingPeriod.consumedFromGrid !== undefined" class="gds-align--block-center">
            <div class="card-usage-content">
              <div class="card__usage--kw-wrap gds-flex-container gds-clickable">
                <div class="card__usage-footer">
                  <p class="card__usage--kw-number">Off Peak {{ currentBillingPeriod.totalKwhOffPeak | numberCommaSingleDecimal }} kWh</p>
                  <p class="card__usage--kw-number">On Peak {{ currentBillingPeriod.totalKwhOnPeak | numberCommaSingleDecimal }}
                    kWh</p>
                </div>
              </div>
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/clock.png" alt="clock"
                class="gds-align--block-center clock-image" />
              <div class="arrow-container">
                <img src="/wp-content/themes/gmptwentynineteen/assets/images/left-arrow-in-circle.png"
                  alt="left arrow in circle" class="arrow-image gds-align--block-center"
                  @click="scrollPreviousCard(3)" />
              </div>
              <div class="scroll-dot-container">
                <div v-for="(scrollDot, index) in scrollDots">
                <span v-if="scrollDot === 0" class="scroll-dot"></span>
                <span v-if="scrollDot === 1" class="scroll-dot-emphasize"></span>
              </div>
              </div>
            </div>
          </figure>
          </div>
        </figure>
      </div>
      <div v-if="loadState !== 'complete'" class="top-banner-card gds-card gds-data-card top-banner-card--logged-in"
        :class="{ 'gds-loading': loadState === undefined }">
        <div>
          <p class="gds-display-0 gds-space-stack-s">Usage This Billing Period</p>
        </div>
        <div v-if="loadState === 'error'" class="card-error">
          Unable to load,
          <br />please try again later.
        </div>
        <div v-if="loadState === 'error'" class="card-error-layout-blank">&nbsp;</div>
        <!-- If we have an "expected" error (acct disconnected) show it instead of unable to load -->
        <div v-if="loadState === 'unavailable'" class="card-error">Usage information is not available for this
          account.
        </div>
        <div v-if="loadState === 'unavailable'" class="card-error-layout-blank">&nbsp;</div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { ToServerDateTruncate, ParseDateOnly, DumpError } from "../../../utilities";
import { parse, differenceInCalendarDays } from 'date-fns';
import { tSParenthesizedType } from "@babel/types";

export default {
  name: "CardUsage",
  data() {
    return {
      daysLeft: undefined,
      usedByYourHome: undefined,
      netmeteringCardNumber: 1,
      loadState: undefined,
      scrollDots: [],
      numberOfScrollDots: 0,
      emphasisScrollDotNumber: 0
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    this.RefreshData();
  },
  methods: {
    async RefreshData() {
      this.cardClass = undefined;
      this.daysLeft = undefined;
      this.loadState = undefined;
      this.isNetMetered = undefined;
      this.isTOU = undefined;
      this.currentBillingPeriod = {
        startDate: undefined,
        endDate: undefined,
        consumedFromGrid: undefined,
        sentToGrid: undefined,
        grossGenerated: undefined,
        netConsumption: undefined,
        usedByYourHome: undefined,
        totalConsumption: undefined,
        totalKwhOffPeak: undefined,
        totalKwhOnPeak: undefined
      };
      this.lastBillingPeriod = {
        startDate: undefined,
        consumedFromGrid: undefined,
        sentToGrid: undefined,
        grossGenerated: undefined,
        netConsumption: undefined,
        usedByYourHome: undefined,
        totalConsumption: undefined,
        totalKwhOffPeak: undefined,
        totalKwhOnPeak: undefined
      };

      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        const summary = await GMPAPI.GetUsageSummary(this.currentAccount.accountNumber);
     
        if (summary) {
          this.netmeteringCardNumber = 1;
          this.isNetMetered = summary.isNetMetered;
          this.isTOU = summary.isTOU;
          this.setUsageAmounts(summary);    
          const endDate = new Date(summary.billingPeriodEndDate.slice(0,10));
          this.daysLeft = differenceInCalendarDays(endDate, new Date());
          this.scrollDots = [];
          this.setScrollDots()
          this.loadState = "complete"
        } else {
          // No current period, so mark as disconnected
          this.loadState = "unavailable";
        }
      } catch (err) {
        // Check if the error was thrown because of a disabled account
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else {
          DumpError("Usage card refresh error", err);
          this.loadState = "error";
        }
      }
    },

    setScrollDots() {
      if (this.isNetMetered && this.isTOU) {
        this.numberOfScrollDots = 4
        this.scrollDots = [1,0,0,0];
      } else if (this.isNetMetered && !this.isTOU) {
        this.numberOfScrollDots = 3
        this.scrollDots = [1,0,0];
      } else {
        this.numberOfScrollDots = 0;
      }
      this.emphasisScrollDotNumber = 0;
    },
    scrollNextCard(index) {
      if (index < this.numberOfScrollDots) {
        this.netmeteringCardNumber++;
        this.scrollDots[index] = this.scrollDots[index] === 0 ? 1 : 0;
        this.scrollDots[index+1] = this.scrollDots[index+1] === 0 ? 1 : 0;
      }
    },
    scrollPreviousCard(index) {
      if (index > 0) {
        this.netmeteringCardNumber--;
        this.scrollDots[index] = this.scrollDots[index] === 0 ? 1 : 0;
        this.scrollDots[index-1] = this.scrollDots[index-1] === 0 ? 1 : 0;
      }
    },
    setUsageAmounts(summary) {
      this.currentBillingPeriod.startDate = summary.billingPeriodStartDate;
      this.currentBillingPeriod.endDate = summary.billingPeriodEndDate;
      this.currentBillingPeriod.grossGenerated = summary.totalGrossGenerated;
      this.currentBillingPeriod.consumedFromGrid = summary.totalConsumedFromGrid;
      this.currentBillingPeriod.generationSentToGrid = summary.totalGenerationSentToGrid;
      this.currentBillingPeriod.totalConsumption = summary.totalConsumption;
      this.currentBillingPeriod.netConsumption = summary.totalNetConsumption;
      this.currentBillingPeriod.generationUsedByHome = summary.totalGenerationUsedByHome;
      this.currentBillingPeriod.totalKwhOffPeak = summary.totalKwhOffPeak;
      this.currentBillingPeriod.totalKwhOnPeak = summary.totalKwhOnPeak;

      this.lastBillingPeriod.grossGenerated = summary.lastBillingPeriodTotalGrossGenerated;
      this.lastBillingPeriod.consumedFromGrid = summary.lastBillingPeriodTotalConsumedFromGrid;
      this.lastBillingPeriod.generationSentToGrid = summary.lastBillingPeriodTotalGenerationSentToGrid;
      this.lastBillingPeriod.totalConsumption = summary.lastBillingPeriodTotalConsumption;
      this.lastBillingPeriod.netConsumption = summary.lastBillingPeriodTotalNetConsumption;
      this.lastBillingPeriod.generationUsedByHome = summary.lastBillingPeriodTotalGenerationUsedByHome;
      this.lastBillingPeriod.totalKwhOffPeak = summary.lastBillingPeriodTotalKwhOffPeak;
      this.lastBillingPeriod.totalKwhOnPeak = summary.lastBillingPeriodTotalKwhOnPeak;
    },
    navUsage() {
      this.$router.push({ path: "/usage" }); ``
    },
  },
  watch: {
    // Recompute usage whenever the account changes
    currentAccount(newValue, oldValue) {
      this.RefreshData();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>