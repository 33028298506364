import DataStore from "../../store";
import GMPAPI from "../../services/gmpapi";

import BillPayLogin from "./BillPayLogin.vue";
import BillPayOneTime from "./BillPayOneTime.vue";
import BillPayAccount from "./BillPayAccount.vue";
import BillPayFlow from "./BillPayFlow.vue";
import BillPayComplete from "./BillPayComplete.vue";
import BillPayFatalError from "./BillPayFatalError.vue";

export const BillPayRouterOptions = {
  mode: "history",
  base: "/make-payment",
  // When going to a page, scroll to the slat id
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          selector: to.params.page ? ("#pay-slat-" + to.params.page) : "#top-target",
          offset: { x: 0, y: 12 },
        });
      }, 50);
    });
  },
  routes: [
    { path: "/", redirect: "/account" },
    { path: "/login", component: BillPayLogin },
    { path: "/onetime", component: BillPayOneTime },
    {
      path: "/account",
      component: BillPayAccount,
      beforeEnter: async (to, from, next) => {
        try {
          DataStore.commit("setRouteLoading", true);
          await GMPAPI.GetCurrentUser();
          // We have auth, but may not have user info
          if (!DataStore.state.user.userinfo) {
            try {
              await DataStore.dispatch("AttemptCookieLogin");
              next();
            } catch (err) {
              next("/login");
            }
          } else {
            next();
          }
        } catch (err) {
          next("/login");
        } finally {
          DataStore.commit("setRouteLoading", false);
        }
      }
    },
    {
      path: "/pay/:page",
      component: BillPayFlow,
      beforeEnter: (to, from, next) => {
        if (!DataStore.state.user.currentAccount) {
          next("/account");
        } else {
          next();
        }
      },
    },
    {
      path: "/complete",
      name: 'complete',
      component: BillPayComplete,
      beforeEnter: (to, from, next) => {
        if (!DataStore.state.billpay.confirmationNumber) {
          next("/account");
        } else {
          next();
        }
      }
    },
    { path: "/error/:code?",
      name: 'fatalerror',
      component: BillPayFatalError },
    { path: "*", redirect: "/" },
  ],
};

export function BillPayRouterInit(router) {
  // No external init needed
}