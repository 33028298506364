<template>
  <div class="usage-detail__component-container">
    <section>
      <UsageDetailControls
        :selectedDate="monthYearSelected"
        :selectedDateSubText="selectedDateSubText"
        :datePickerDisabledDates="disabledDates"
        :showDateBackwardsButton="showDateBackwardsButton"
        :showDateForwardsButton="showDateForwardsButton"
        :showJumpToDateButton="showJumpToDateButton"
        :showGoToTodayButton="showGoToTodayButton"
        :onDateBackwardsClicked="onDateBackwardsClicked"
        :onDateForwardsClicked="onDateForwardsClicked"
        :onSelectedDateChanged="onSelectedDateChanged"
        :onGoToTodayClicked="onGoToTodayClicked"
        :onOptionSelectedCalendarMonthsBillingPeriod="onOptionCalendarTypeSelected"
        :datePickerDateType="'month'"
        :selectConfigCalendarMonthsBillingPeriod="selectConfigCalendarMonthsBillingPeriod"
        :disableDateNavigationButtonBack="disableDateNavigationButtonBack"
        :disableDateNavigationButtonForward="disableDateNavigationButtonForward"
        :disableAllDateNavControls="!loadState  || disableAllDateNavControls"
      />
    </section>
    <div v-if="!loadState" class="table-loading"></div>
    <div class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
        img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <section v-if="loadState === 'complete'" class="usage-detail__time-of-day--container">
      <div class="usage-detail__time-of-day--chart">
        <div ref="timeOfDayConsumption" id="usage-detail__time-of-day--chart-body" />
      </div>
      <div>
        <WidgetTimeOfDay ref="widgetTimeOfDay" :show-widget-header="false" :chart-body-class="chartBodyClass" :widget-text-class="widgetTextClass" :initial-fetch="false" :percentage-data="percentageData" />
      </div>
    </section>
  </div>
</template>

<script>
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls";
import WidgetTimeOfDay from "../widgettimeofday/WidgetTimeOfDay.vue";
import { DumpError } from "../../../../utilities";
import { DrawChart } from "./timeOfDayConsumption.js";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import { GetComponentStatus } from '../../../../services/statuspage';
import {  format, addDays, addMonths, isSameDay, subMonths, subDays, endOfMonth, isAfter, isBefore, isToday, isFuture, parseISO} from "date-fns";

export default {
  components: {
    UsageDetailControls,
    WidgetTimeOfDay
  },
  name: "UsageDetailTimeOfDay",
  mixins: [
    UsageDetailBaseComponentMixin
  ],
  data() {
    return {
      disabledDates: undefined,
      periodTypeSelected: 'billing-period',
      disableDateNavigationButtonForward: true,
      disableDateNavigationButtonBack: true,
      earliestNavigationDate: undefined,
      chartBodyClass: 'usage-detail__time-of-day--widget',
      widgetTextClass: 'usage-detail__time-of-day--widget-text',
      percentageData: undefined,
      selectConfigCalendarMonthsBillingPeriod: {
        selectedValue: 'billing-period',
        buttonAppearance: true,
        options: [
          {
            label: 'Calendar Month',
            value: 'calendar-month'
          },
          {
            label: 'Billing Period',
            value: 'billing-period'
          }
        ]
      },
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    }
  },
  watch: {
    async currentAccount() {
      this.init();
    }
  },
  async mounted() {
    try {
      // Before we do anything else, check to see if component is operational
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        this.disableAllDateNavControls = true;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      } 

      this.earliestNavigationDate = this.determineEarliestNavigationDate(this.billingPeriods.periods);
      this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(this.billingPeriods, this.earliestNavigationDate);
      this.init();

    } catch (err) {
      DumpError("Usage detail time of day refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  methods: {
    async init() {
      this.selectedStartDate = new Date();
      this.adjustDatesForBillingPeriod();
    },

    formatMonthYearSelected() {
      this.monthYearSelected = this.formatSelectedDate(this.selectedStartDate);
    },

    determineEarliestNavigationDate(billingPeriods) {
      const firstBillingPeriod = parseISO(billingPeriods[billingPeriods.length-1].startDate);
      return isBefore(firstBillingPeriod, this.EARLIEST_SNAPSHOT_DATA) ? this.EARLIEST_SNAPSHOT_DATA : firstBillingPeriod;
    },

    async refreshData(accountNumber, startDate, endDate) {
      this.loadState = undefined;
      try {
        this.percentageData = await this.getAccountUsageSnapshot(accountNumber, startDate, endDate);
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        return;
      }

      if (this.loadState === 'unavailable' || this.loadState === 'empty') {
        this.selectedDateSubText = '';
      }

      if (this.percentageData && this.percentageData.averageConsumptionPercentage) {
        const percentages = [
          this.percentageData.averageConsumptionPercentage.hour4to7,
          this.percentageData.averageConsumptionPercentage.hour8to11,
          this.percentageData.averageConsumptionPercentage.hour12to15,
          this.percentageData.averageConsumptionPercentage.hour16to19,
          this.percentageData.averageConsumptionPercentage.hour20to23,
          this.percentageData.averageConsumptionPercentage.hour0to3
        ]

        DrawChart(this.$refs.timeOfDayConsumption, percentages);
      } else {
        this.loadState = 'empty';
      }
    },

    disableDatePickerDatesOutsideBillingPeriods(billingPeriods, earliestNavigationDate) {    
      if (billingPeriods && billingPeriods.length > 0) {
        const disabledDates = {
          from: parseISO(billingPeriods.periods[0].startDate),
          to: earliestNavigationDate
        }
        return disabledDates; 
      }
      return {}
    },

    onSelectedDateChanged(selectedDate) {
      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.selectedEndDate = endOfMonth(selectedDate);
        this.adjustDatesForCalendarMonth();
      } else {
        this.selectedStartDate = selectedDate;
        this.adjustDatesForBillingPeriod();
      }

      this.disableDateNavigationButtonBack = 
        isBefore(selectedDate, this.earliestNavigationDate)  ||isSameDay(selectedDate, this.earliestNavigationDate);

    },
    onDateBackwardsClicked() {
      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.selectedEndDate = endOfMonth(subMonths(this.selectedEndDate, 1));
        this.adjustDatesForCalendarMonth();
      } else {
        this.selectedStartDate = subDays(this.selectedStartDate, 1);
        this.adjustDatesForBillingPeriod();
      }

      this.disableDateNavigationButtonBack = isBefore(this.selectedStartDate, this.earliestNavigationDate);
    },

    adjustDatesForwards() {
      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.selectedEndDate = endOfMonth(addMonths(this.selectedEndDate, 1));
        this.adjustDatesForCalendarMonth();
      } else {
        this.selectedStartDate = addDays(this.selectedEndDate, 1);
        this.adjustDatesForBillingPeriod();
      }
    },

    async setDateNavigationButtonStatus() {
      const earliestDate = this.determineEarliestNavigationDate(this.billingPeriods.periods);
      this.disableDateNavigationButtonBack = isBefore(this.selectedStartDate, earliestDate);

      if (isToday(addDays(this.selectedEndDate, 1)) 
          || isFuture(this.selectedEndDate)
          || isAfter(this.selectedEndDate, parseISO(this.billingPeriods.periods[0].endDate))
      ) {
        this.disableDateNavigationButtonForward = true;
      } else {
        this.disableDateNavigationButtonForward = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>