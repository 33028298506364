<template>
  <div 
    class="usage-detail__seasonal-bubble-container">
    <div class="usage-detail__seasonal-bubble" :class="isCurrentSeason ? 'current-season' : null"
      :style="{ 
        transform: isCurrentSeason ? null : averageUsage < 2 ? `scale(4)` : `scale(${usageScalar})`, 
        margin: `0 ${usageScalar * 10}px 0 ${usageScalar * 10}px` }">
      <div class="usage-detail__seasonal-bubble-usage-text" :class="isCurrentSeason ? 'current-season' : null" :style="{ transform: isCurrentSeason ? `scale(${0.333})` : averageUsage <= 2 ? `scale(${0.25})` : `scale(${1 / usageScalar})` }">
        <div>{{ averageUsage }}</div>
        <div>kWh/day</div>
      </div>
    </div>
    <div class="usage-detail__seasonal-bubble-label-container" :class="isCurrentSeason ? 'current-season' : null">
      <div class="usage-detail__seasonal-bubble-label-season-year">{{ season }} {{ season !== "Winter" ? year :
        `${year - 1}-${year}` }}</div>
      <div class="usage-detail__seasonal-bubble-label-months">{{ getMonthsInSeason() }}</div>
      <span v-if="isCurrentSeason" class="usage-detail__seasonal-bubble-current-season-badge">Current</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "SeasonalUsageBubble",
  props: {
    season: String,
    isCurrentSeason: Boolean,
    scaledAverageUsage: Number,
    averageUsage: Number,
    year: Number
  },
  data() {
    return {
      usageScalar: undefined
    }
  },
  computed: {
  },
  mounted() {
    setTimeout(() => {
      this.usageScalar = this.scaledAverageUsage || 1;
    }, 250)
  },
  methods: {
    getMonthsInSeason() {
      switch (this.season) {
        case "Spring":
          return "Mar, Apr, May";
        case "Summer":
          return "Jun, Jul, Aug";
        case "Fall":
          return "Sep, Oct, Nov";
        case "Winter":
          return "Dec, Jan, Feb";
        default:
          return "Invalid season";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>