var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentAccount &&
    _vm.currentAccount.disconnectNotice &&
    _vm.currentAccount.disconnectNotice.disconnectDate
    ? _c("div", { staticClass: "disconnect-banner gds-flex-container" }, [
        !_vm.alreadyDisconnected
          ? _c(
              "div",
              { staticClass: "gds-flex-container gds-flex-container--nowrap" },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "disconnect-banner--icon gds-icon gds-space-inline-s"
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-warning"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "Your power is scheduled to be shut off on " +
                      _vm._s(
                        _vm._f("dateFull")(
                          _vm.currentAccount.disconnectNotice.disconnectDate
                        )
                      ) +
                      " due to non-payment."
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.alreadyDisconnected
          ? _c(
              "div",
              { staticClass: "gds-flex-container gds-flex-container--nowrap" },
              [
                _c(
                  "svg",
                  {
                    staticClass:
                      "disconnect-banner--icon gds-icon gds-space-inline-s"
                  },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-warning"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "Your power was scheduled to be shut off on " +
                      _vm._s(
                        _vm._f("dateFull")(
                          _vm.currentAccount.disconnectNotice.disconnectDate
                        )
                      ) +
                      " due to non-payment."
                  )
                ])
              ]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }