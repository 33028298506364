<template>
  <div class="top-card-display__single-card top-card-display__single-card--outages-near-you">
    <div v-if="currentAccount">
      <div class="top-banner-card gds-card top-banner-card--logged-in card-box-shadow" :class="{'gds-loading': loadState === undefined }">
        <figure v-if="loadState === 'complete'" class="gds-card__image-container">
            <img :src="outageSummary.screenshotUrl" alt="The Green Mountain Power Outage Center" />
        </figure>
        <div class="gds-card__contents">
          <div>
            <p class="gds-display-0 gds-space-stack-s">Outages in Your Town</p>
            <p class="card--outages-near-you__caption gds-card__caption">{{currentAccount.address | addressTownState}}</p>
          </div>
          <div
            v-if="loadState === 'complete' && townOutages !== undefined"
            class="card--outages-near-you__number card__data-container--large-text gds-align--text-center"
          >{{townOutages}}</div>
          <div v-if="loadState === 'error'" class="card-error">
            Unable to load,
            <br />please try again later.
          </div>
          <a
            v-if="loadState !== undefined"
            href="/outages"
            class="gds-card__button gds-button gds-secondary gds-round gds-align--block-center gds-button-full"
          >View Outage Map</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../../services/gmpapi';
import { EnvironmentConstants, GMPPhoneNumber } from "../../../environment";
import { GetTownIncidents, DumpError } from '../../../utilities';

export default {
  name: "CardOutagesNearYou",
  data() {
    return {
      townOutages: undefined,
      outageSummary: undefined,

      loadState: undefined,

      GMPPhoneNumber,
      EnvironmentConstants,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await this.RefreshAll();
  },
  methods: {
    async RefreshAll() {
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        await Promise.all([
          this.RefreshIncidents(),
          this.RefreshSummary(),
        ]);
        this.loadState = "complete";
      } catch (err) {
        DumpError("Nearby outage card refresh error", err);
        this.loadState = "error";
      }
    },
    async RefreshIncidents() {
      this.townOutages = undefined;
      const incidents = await GMPAPI.GetAllIncidents();
      const townIncidents = GetTownIncidents(this.currentAccount.address.city, incidents);
      this.townOutages = townIncidents.length;
    },
    async RefreshSummary() {
      this.outageSummary = undefined;
      const summary = await GMPAPI.GetIncidentsSummary();
      this.outageSummary = summary;
    },
  },
  watch: {
    async currentAccount() {
      await this.RefreshAll();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>