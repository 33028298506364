<template>
  <div v-if="visible" class="gmp-modal">
    <div class="export-data__modal-box">
      <FocusLock>
        <header class="delete-user__modal-box--header">
          <button @click="Close(false)" class="close gds-button gds-button-circle gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
          </button>
          <div class="export-data__modal-box--headline">This saved payment method is used for automatic payments.</div>
        </header>
        <div class="delete-user__modal-box--body">
          <div class="export-data__modal-box--message gds-space-stack-l">
            Removing this payment method will stop automatic payments for this GMP account. Visit the
            <router-link to="/preferences#autopay">Billing Preferences</router-link>&nbsp;page
            to choose a different payment method and manage your recurring auto payment settings.
          </div>
          <div v-if="errorRemove" class="form-message-box gds-space-stack-m">{{errorRemove}}</div>
          <button @click="Close(false)" type="button" class="gds-button gds-secondary gds-compact gds-space-stack-m">
            <span class="gds-button__text">Cancel</span>
          </button>
          <button
            @click="Save()"
            :disabled="pendingRemove"
            :class="{ 'gds-loading': pendingRemove }"
            type="button"
            class="gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m"
          >
            <span class="gds-button__text">Remove Payment Method</span>
          </button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../../services/gmpapi';
import { DumpError } from '../../../utilities';
import { GMPPhoneNumber } from '../../../environment';

export default {
  name: "ModalRemoveAutopayWallet",
  props: ["visible", "wallet"],
  components: {
  },
  data() {
    return {
      pendingRemove: false,
      errorRemove: undefined,
      GMPPhoneNumber,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
    async Save() {
      this.pendingRemove = true;
      try {
        // Remove it
        await GMPAPI.DeleteWallet(this.currentAccount.accountNumber, this.wallet.walletId);
        this.Close(true);
      } catch (err) {
        DumpError("Remove wallet error", err);
        this.errorRemove = "There was an unexpected error removing your payment method.";
      } finally {
        this.pendingRemove = false;
      }
    },
    // Emitted event has argument true if the wallet was deleted, false if cancelled
    Close(changed) {
      this.$emit("complete", changed);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>