<template>
  <div>
    <div v-if="!userIsOTP">
      <bill-pay-flow-logged-in></bill-pay-flow-logged-in>
    </div>
    <div v-if="userIsOTP">
      <bill-pay-flow-logged-out></bill-pay-flow-logged-out>
    </div>
  </div>
</template>

<script>
import BillPayFlowLoggedIn from "./BillPayFlowLoggedIn";
import BillPayFlowLoggedOut from "./BillPayFlowLoggedOut";

export default {
  name: "BillPayFlow",
  data() {
    return {
    };
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
  },
  components: {
    BillPayFlowLoggedIn,
    BillPayFlowLoggedOut,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>