var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "gds-space-top-xl gds-space-stack-default" }, [
      _vm._v("Would you like to download this info?")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "gds-flex-container gds-flex-container--left" }, [
      _c(
        "div",
        { staticClass: "usage-export__export-container gds-flex-container" },
        [
          !_vm.viewAdvancedOptions
            ? _c(
                "p",
                {
                  staticClass: "usage-dashboard___sub-title gds-space-stack-l"
                },
                [
                  _vm._v(
                    "Get your hourly usage data for past 30 days, as a .CSV"
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorDownload
            ? _c("div", { staticClass: "form-message-box gds-space-stack-m" }, [
                _vm._v(_vm._s(_vm.errorDownload))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.viewAdvancedOptions
            ? _c("div", [
                _c("div", { staticClass: "gds-flex-container" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gds-space-stack-s gds-flex-container gds-flex-container--left gds-flex-container--wrap"
                    },
                    [
                      _c(
                        "fieldset",
                        { staticClass: "usage-export__fieldset gds-fieldset" },
                        [
                          _c(
                            "span",
                            { staticClass: "usage-export__field-label" },
                            [_vm._v("Start Date")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "export-data__filtration-date-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "my-account-billing-report__filtration-date-icon",
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.startPicker.showCalendar()
                                    }
                                  }
                                },
                                [
                                  _c("svg", { staticClass: "gds-icon" }, [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                      }
                                    })
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("datepicker", {
                                ref: "startPicker",
                                attrs: {
                                  format: _vm.DATE_FORMAT,
                                  "disabled-dates": {
                                    from: _vm.lastPossibleDate
                                  },
                                  "input-class":
                                    "export-data__date my-account-billing-report__filtration-date-text"
                                },
                                model: {
                                  value: _vm.startDate,
                                  callback: function($$v) {
                                    _vm.startDate = $$v
                                  },
                                  expression: "startDate"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        { staticClass: "usage-export__fieldset gds-fieldset" },
                        [
                          _c(
                            "span",
                            { staticClass: "usage-export__field-label" },
                            [_vm._v("End Date")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "export-data__filtration-date-box" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "my-account-billing-report__filtration-date-icon",
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.endPicker.showCalendar()
                                    }
                                  }
                                },
                                [
                                  _c("svg", { staticClass: "gds-icon" }, [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                      }
                                    })
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("datepicker", {
                                ref: "endPicker",
                                attrs: {
                                  format: _vm.DATE_FORMAT,
                                  "disabled-dates": {
                                    from: _vm.lastPossibleDate
                                  },
                                  "input-class":
                                    "export-data__date my-account-billing-report__filtration-date-text"
                                },
                                model: {
                                  value: _vm.endDate,
                                  callback: function($$v) {
                                    _vm.endDate = $$v
                                  },
                                  expression: "endDate"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        { staticClass: "usage-export__fieldset gds-fieldset" },
                        [
                          _c("label", [
                            _c(
                              "span",
                              { staticClass: "usage-export__field-label" },
                              [_vm._v("File Format")]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.dataFormat,
                                      expression: "dataFormat"
                                    }
                                  ],
                                  staticClass:
                                    "eicproduct-input-field-l__select gds-input-field",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.dataFormat = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "csv" } }, [
                                    _vm._v("CSV")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "xml" } }, [
                                    _vm._v("XML")
                                  ])
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.rangeDays < 1
            ? _c("div", { staticClass: "validation-error" }, [
                _vm._v("Start date must be before end date.")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.rangeDays > 30
            ? _c("div", { staticClass: "validation-error" }, [
                _vm._v("Maximum export range is 30 days.")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "gds-space-stack-l" }, [
            _c(
              "button",
              {
                staticClass:
                  "usage-export__download-button-with-image gds-card__button gds-button gds-round gds-compact",
                class: { "gds-loading": _vm.pendingDownload },
                attrs: {
                  disabled:
                    _vm.rangeDays < 1 ||
                    _vm.rangeDays > 30 ||
                    _vm.pendingDownload,
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.Save()
                  }
                }
              },
              [
                !_vm.pendingDownload
                  ? _c("span", { staticClass: "usage-export__download-icon" })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Download Usage")
                ])
              ]
            )
          ]),
          _vm._v(" "),
          !_vm.viewAdvancedOptions
            ? _c(
                "span",
                {
                  staticClass: "usage-export__options-link underline",
                  on: {
                    click: function($event) {
                      _vm.viewAdvancedOptions = true
                    }
                  }
                },
                [_vm._v("View advanced export options")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.viewAdvancedOptions
            ? _c(
                "span",
                {
                  staticClass: "usage-export__options-link underline",
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v("Hide advanced export options")]
              )
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }