var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDevicesFeatureEnabled
    ? _c("div", { staticClass: "notifications--container" }, [
        _vm.loadState === undefined
          ? _c("div", { staticClass: "table-loading" })
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "down"
          ? _c(
              "div",
              { staticClass: "usage-page--interior-status-container" },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "usage-page--interior-status-container" },
          [
            _vm.loadState === "error"
              ? _c("flow-error", {
                  attrs: { name: "Notification configuration", state: "error" }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.loadState === "maintenance"
              ? _c("flow-error", {
                  attrs: {
                    name: "Notification configuration",
                    state: "maintenance"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.loadState === "unavailable"
              ? _c("flow-error", {
                  attrs: {
                    name: "Notification configuration",
                    state: "unavailable",
                    img:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.loadState === "complete"
          ? _c("div", [
              _c("div", [
                !_vm.editingContact
                  ? _c("div", [
                      _vm.FF_DeviceContacts
                        ? _c(
                            "section",
                            { staticClass: "my-account__full-width-section" },
                            [
                              _c("div", [
                                _c(
                                  "h2",
                                  { staticClass: "gds-display-1 no-margin" },
                                  [_vm._v("Mobile Devices")]
                                ),
                                _vm._v(" "),
                                _vm.deviceContacts &&
                                _vm.deviceContacts.length === 0
                                  ? _c("div", [
                                      _vm._m(1),
                                      _vm._v(" "),
                                      _vm._m(2),
                                      _vm._v(" "),
                                      _vm._m(3)
                                    ])
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _vm.deviceContacts &&
                              _vm.deviceContacts.length > 0
                                ? _c(
                                    "div",
                                    [
                                      _vm._m(4),
                                      _vm._v(" "),
                                      _vm._l(_vm.deviceContacts, function(
                                        deviceContact
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: deviceContact.deviceId,
                                            staticClass:
                                              "notifications__row-item gds-flex-container gds-flex-container--top"
                                          },
                                          [
                                            _vm._m(5, true),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "notifications__row-item--inner-wrap gds-flex-container gds-flex-container--top"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "notifications__row-item-account"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "notifications__row-item-account--details gds-font-demi gds-space-inline-m"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            deviceContact.nickname
                                                              ? deviceContact.nickname
                                                              : deviceContact.deviceInformation
                                                              ? deviceContact.deviceInformation
                                                              : "Unknown Device"
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-flex-container gds-flex-container--left gds-flex-container--wrap"
                                                      },
                                                      [
                                                        deviceContact.confirmed !==
                                                        true
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "notifications__row-item-account--unverified"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Unverified"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "gds-button gds-text-button",
                                                        attrs: {
                                                          type: "button"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.EditContact(
                                                              deviceContact
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "my-account__notifications--text-button gds-button__text"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Edit / Remove"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "notifications__row-item-notify-types"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "notifications__row-item-notify-type--fieldset gds-flex-container gds-flex-container--left gds-fieldset",
                                                        attrs: { role: "group" }
                                                      },
                                                      _vm._l(
                                                        _vm.alerttypes,
                                                        function(alerttype) {
                                                          return _c(
                                                            "label",
                                                            {
                                                              key:
                                                                alerttype.alertTypeId,
                                                              staticClass:
                                                                "notifications__checkbox-label gds-checkbox"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "notifications__row-item-notify-type--item-label gds-checkbox__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      alerttype.name
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("input", {
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  disabled: !_vm.ValidToSubscribe(
                                                                    deviceContact
                                                                      .channel
                                                                      .code,
                                                                    alerttype
                                                                  )
                                                                },
                                                                domProps: {
                                                                  checked: _vm.HasSubscription(
                                                                    deviceContact,
                                                                    alerttype
                                                                  )
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.Subscribe(
                                                                      deviceContact,
                                                                      alerttype,
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _vm.ValidToSubscribe(
                                                                deviceContact
                                                                  .channel.code,
                                                                alerttype
                                                              )
                                                                ? _c("span", {
                                                                    staticClass:
                                                                      "notifications__checkbox__faux gds-checkbox__faux"
                                                                  })
                                                                : _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "notifications__not-eligible"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Not available via " +
                                                                          _vm._s(
                                                                            deviceContact
                                                                              .channel
                                                                              .name
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.editingContact && _vm.editingContact.channelCode !== "PUSH"
                  ? _c("div", [
                      _c(
                        "section",
                        {
                          staticClass:
                            "my-account__full-width-section gds-space-stack-xl"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                            },
                            [
                              _vm.editingContact.isNew
                                ? _c(
                                    "h2",
                                    { staticClass: "no-margin gds-display-1" },
                                    [_vm._v("New Contact")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.editingContact.isNew
                                ? _c(
                                    "h2",
                                    { staticClass: "no-margin gds-display-1" },
                                    [_vm._v("Edit Contact")]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "form",
                            { staticClass: "my-account__edit-contact-form" },
                            [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "gds-fieldset gds-space-stack-m"
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "gds-input-field" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "gds-input-field__label"
                                        },
                                        [_vm._v("Contact Method")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "gds-position--relative"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "gds-input-field__prefix"
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "gds-icon my-account__edit-contact-form--icon"
                                                },
                                                [
                                                  _vm.editingContact
                                                    .channelCode === "EMAIL"
                                                    ? _c("use", {
                                                        attrs: {
                                                          "xlink:href":
                                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-email"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.editingContact
                                                    .channelCode === "SMS"
                                                    ? _c("use", {
                                                        attrs: {
                                                          "xlink:href":
                                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-cell-phone"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editingContact
                                                      .channelCode,
                                                  expression:
                                                    "editingContact.channelCode"
                                                }
                                              ],
                                              staticClass:
                                                "bill-pay-input-field__select",
                                              attrs: {
                                                disabled: !_vm.editingContact
                                                  .isNew
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.editingContact,
                                                    "channelCode",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "EMAIL" } },
                                                [_vm._v("Email")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "SMS" } },
                                                [_vm._v("Text Message (SMS)")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.editingContact.channelCode === "SMS"
                                ? _c(
                                    "fieldset",
                                    {
                                      staticClass:
                                        "gds-fieldset gds-space-stack-m"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "gds-input-field" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "gds-input-field__label"
                                            },
                                            [_vm._v("Phone Number")]
                                          ),
                                          _vm._v(" "),
                                          _c("the-mask", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required|min:10",
                                                expression: "'required|min:10'"
                                              }
                                            ],
                                            key: "phone number",
                                            staticClass:
                                              "gds-input-field__input",
                                            attrs: {
                                              mask: "+1 (###) ###-####",
                                              name: "phone number",
                                              type: "tel",
                                              placeholder: "Your phone number"
                                            },
                                            model: {
                                              value: _vm.editingContact.sms,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.editingContact,
                                                  "sms",
                                                  $$v
                                                )
                                              },
                                              expression: "editingContact.sms"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.errors.first("phone number")
                                        ? _c(
                                            "div",
                                            { staticClass: "validation-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    "phone number"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editingContact.channelCode === "EMAIL"
                                ? _c(
                                    "fieldset",
                                    {
                                      staticClass:
                                        "gds-fieldset gds-space-stack-m"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "gds-input-field" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "gds-input-field__label"
                                            },
                                            [_vm._v("Email Address")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.editingContact.email,
                                                expression:
                                                  "editingContact.email"
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required|email|max:74",
                                                expression:
                                                  "'required|email|max:74'"
                                              }
                                            ],
                                            key: "email address",
                                            staticClass:
                                              "gds-input-field__input",
                                            attrs: {
                                              maxlength: "74",
                                              name: "email address",
                                              type: "email",
                                              autocomplete: "email",
                                              placeholder: "Your email"
                                            },
                                            domProps: {
                                              value: _vm.editingContact.email
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.editingContact,
                                                  "email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.errors.first("email address")
                                        ? _c(
                                            "div",
                                            { staticClass: "validation-error" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.errors.first(
                                                    "email address"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "gds-fieldset gds-space-stack-m"
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "gds-input-field" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "gds-input-field__label"
                                        },
                                        [_vm._v("Nickname")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.editingContact.nickname,
                                            expression:
                                              "editingContact.nickname"
                                          },
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              mask: "CCCCCCCCCCCC",
                                              tokens: _vm.allowedCharacters
                                            },
                                            expression:
                                              "{mask: 'CCCCCCCCCCCC', tokens: allowedCharacters }"
                                          }
                                        ],
                                        staticClass: "gds-input-field__input",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Type here..."
                                        },
                                        domProps: {
                                          value: _vm.editingContact.nickname
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editingContact,
                                              "nickname",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          staticClass:
                            "my-account__full-width-section gds-space-stack-l"
                        },
                        [
                          _vm._m(6),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "notification__terms gds-space-stack-l"
                            },
                            [
                              _vm._v(
                                "Select the notification types to send to this contact."
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "form",
                            _vm._l(_vm.editingContact.alerts, function(alert) {
                              return _c(
                                "fieldset",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ValidToSubscribe(
                                        _vm.editingContact.channelCode,
                                        alert
                                      ),
                                      expression:
                                        "ValidToSubscribe(editingContact.channelCode, alert)"
                                    }
                                  ],
                                  key: alert.alertTypeId,
                                  staticClass: "gds-fieldset"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "gds-checkbox gds-no-margin"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: alert.subscribed,
                                            expression: "alert.subscribed"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            alert.subscribed
                                          )
                                            ? _vm._i(alert.subscribed, null) >
                                              -1
                                            : alert.subscribed
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = alert.subscribed,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    alert,
                                                    "subscribed",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    alert,
                                                    "subscribed",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(alert, "subscribed", $$c)
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass:
                                          "notifications__checkbox__faux gds-checkbox__faux"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "gds-checkbox__label" },
                                        [_vm._v(_vm._s(alert.name))]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "my-account__full-width-section" },
                        [
                          _vm._m(7),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "notification__terms gds-space-stack-l"
                            },
                            [
                              _vm._v(
                                "When enabled, we will not send notifications to this contact during the specified times."
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "form",
                            { staticClass: "my-account__dndisturb-form" },
                            [
                              _c("fieldset", { staticClass: "gds-fieldset" }, [
                                _c(
                                  "label",
                                  { staticClass: "gds-checkbox gds-no-margin" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editingContact.dndEnable,
                                          expression: "editingContact.dndEnable"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.editingContact.dndEnable
                                        )
                                          ? _vm._i(
                                              _vm.editingContact.dndEnable,
                                              null
                                            ) > -1
                                          : _vm.editingContact.dndEnable
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a =
                                              _vm.editingContact.dndEnable,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.editingContact,
                                                  "dndEnable",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.editingContact,
                                                  "dndEnable",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.editingContact,
                                              "dndEnable",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "gds-checkbox__faux"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "gds-checkbox__label gds-font-demi"
                                      },
                                      [
                                        _vm._v(
                                          "Don't send me notifications from:"
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "gds-fieldset gds-space-stack-l"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "my-account-dndisturb gds-flex-container gds-flex-container--space-between"
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.editingContact.dndStart,
                                              expression:
                                                "editingContact.dndStart"
                                            }
                                          ],
                                          staticClass:
                                            "bill-pay-input-field__select",
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.editingContact,
                                                "dndStart",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "00:00:00" } },
                                            [_vm._v("12:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "01:00:00" } },
                                            [_vm._v("1:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "02:00:00" } },
                                            [_vm._v("2:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "03:00:00" } },
                                            [_vm._v("3:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "04:00:00" } },
                                            [_vm._v("4:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "05:00:00" } },
                                            [_vm._v("5:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "06:00:00" } },
                                            [_vm._v("6:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "07:00:00" } },
                                            [_vm._v("7:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "08:00:00" } },
                                            [_vm._v("8:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "09:00:00" } },
                                            [_vm._v("9:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "10:00:00" } },
                                            [_vm._v("10:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "11:00:00" } },
                                            [_vm._v("11:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "12:00:00" } },
                                            [_vm._v("12:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "13:00:00" } },
                                            [_vm._v("1:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "14:00:00" } },
                                            [_vm._v("2:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "15:00:00" } },
                                            [_vm._v("3:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "16:00:00" } },
                                            [_vm._v("4:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "17:00:00" } },
                                            [_vm._v("5:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "18:00:00" } },
                                            [_vm._v("6:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "19:00:00" } },
                                            [_vm._v("7:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "20:00:00" } },
                                            [_vm._v("8:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "21:00:00" } },
                                            [_vm._v("9:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "22:00:00" } },
                                            [_vm._v("10:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "23:00:00" } },
                                            [_vm._v("11:00 PM")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "my-account__text-between-selects"
                                        },
                                        [_vm._v("to")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.editingContact.dndEnd,
                                              expression:
                                                "editingContact.dndEnd"
                                            }
                                          ],
                                          staticClass:
                                            "bill-pay-input-field__select",
                                          attrs: {
                                            id: "my-account-dndisturb-end"
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$selectedVal = Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function(o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function(o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                              _vm.$set(
                                                _vm.editingContact,
                                                "dndEnd",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "00:00:00" } },
                                            [_vm._v("12:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "01:00:00" } },
                                            [_vm._v("1:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "02:00:00" } },
                                            [_vm._v("2:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "03:00:00" } },
                                            [_vm._v("3:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "04:00:00" } },
                                            [_vm._v("4:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "05:00:00" } },
                                            [_vm._v("5:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "06:00:00" } },
                                            [_vm._v("6:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "07:00:00" } },
                                            [_vm._v("7:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "08:00:00" } },
                                            [_vm._v("8:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "09:00:00" } },
                                            [_vm._v("9:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "10:00:00" } },
                                            [_vm._v("10:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "11:00:00" } },
                                            [_vm._v("11:00 AM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "12:00:00" } },
                                            [_vm._v("12:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "13:00:00" } },
                                            [_vm._v("1:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "14:00:00" } },
                                            [_vm._v("2:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "15:00:00" } },
                                            [_vm._v("3:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "16:00:00" } },
                                            [_vm._v("4:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "17:00:00" } },
                                            [_vm._v("5:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "18:00:00" } },
                                            [_vm._v("6:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "19:00:00" } },
                                            [_vm._v("7:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "20:00:00" } },
                                            [_vm._v("8:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "21:00:00" } },
                                            [_vm._v("9:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "22:00:00" } },
                                            [_vm._v("10:00 PM")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "23:00:00" } },
                                            [_vm._v("11:00 PM")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.editingContact.channelCode === "SMS"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "notification__terms gds-space-stack-l"
                                    },
                                    [
                                      _vm._m(8),
                                      _vm._v(" "),
                                      _c(
                                        "fieldset",
                                        {
                                          staticClass:
                                            "gds-fieldset notifications__long-fieldset"
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "gds-checkbox" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'"
                                                  }
                                                ],
                                                attrs: {
                                                  name: "termsofuse",
                                                  type: "checkbox"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass:
                                                  "gds-checkbox__faux"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(9)
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.errors.first("termsofuse")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "validation-error"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "termsofuse"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-flex-container gds-flex-container--left gds-space-stack-l"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "gds-button gds-secondary gds-space-inline-m",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.editingContact = undefined
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "gds-button gds-space-inline-m",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.SaveContact()
                                        }
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.editingContact.isNew
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "my-account__dndisturb-submit gds-button gds-text-button",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.DeleteContact()
                                          }
                                        }
                                      },
                                      [_vm._v("Remove Contact")]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.editingContact && _vm.editingContact.channelCode === "PUSH"
                  ? _c("div", [
                      _c(
                        "section",
                        {
                          staticClass:
                            "my-account__full-width-section gds-space-stack-xl"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                            },
                            [
                              !_vm.editingContact.isNew
                                ? _c(
                                    "h2",
                                    { staticClass: "no-margin gds-display-1" },
                                    [_vm._v("Edit Device")]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "form",
                            { staticClass: "my-account__edit-contact-form" },
                            [
                              _c(
                                "span",
                                { staticClass: "gds-input-field__label" },
                                [_vm._v("Contact Method")]
                              ),
                              _vm._v(" "),
                              _vm._m(10),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "gds-input-field__label" },
                                [_vm._v("Device Information")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-position--relative gds-flex-container gds-space-stack-l gds-flex-container--left"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "notifications__row-item-account--details"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.editingContact.deviceInformation
                                            ? _vm.editingContact
                                                .deviceInformation
                                            : "Unknown Device"
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "fieldset",
                                {
                                  staticClass: "gds-fieldset gds-space-stack-m"
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "gds-input-field" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "gds-input-field__label"
                                        },
                                        [_vm._v("Nickname")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.editingContact.nickname,
                                            expression:
                                              "editingContact.nickname"
                                          },
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: {
                                              mask: "CCCCCCCCCCCC",
                                              tokens: _vm.allowedCharacters
                                            },
                                            expression:
                                              "{mask: 'CCCCCCCCCCCC', tokens: allowedCharacters }"
                                          }
                                        ],
                                        staticClass: "gds-input-field__input",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Type here..."
                                        },
                                        domProps: {
                                          value: _vm.editingContact.nickname
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editingContact,
                                              "nickname",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        {
                          staticClass:
                            "my-account__full-width-section gds-space-stack-l"
                        },
                        [
                          _vm._m(11),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "notification__terms gds-space-stack-l"
                            },
                            [
                              _vm._v(
                                "Select the notification types to send to this contact."
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "form",
                            _vm._l(_vm.editingContact.alerts, function(alert) {
                              return _c(
                                "fieldset",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ValidToSubscribe(
                                        _vm.editingContact.channelCode,
                                        alert
                                      ),
                                      expression:
                                        "ValidToSubscribe(editingContact.channelCode, alert)"
                                    }
                                  ],
                                  key: alert.alertTypeId,
                                  staticClass: "gds-fieldset"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "gds-checkbox gds-no-margin"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: alert.subscribed,
                                            expression: "alert.subscribed"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            alert.subscribed
                                          )
                                            ? _vm._i(alert.subscribed, null) >
                                              -1
                                            : alert.subscribed
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = alert.subscribed,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    alert,
                                                    "subscribed",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    alert,
                                                    "subscribed",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(alert, "subscribed", $$c)
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass:
                                          "notifications__checkbox__faux gds-checkbox__faux"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "gds-checkbox__label" },
                                        [_vm._v(_vm._s(alert.name))]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "section",
                        { staticClass: "my-account__full-width-section" },
                        [
                          _c(
                            "form",
                            { staticClass: "my-account__dndisturb-form" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-flex-container gds-flex-container--left gds-space-stack-l"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "gds-button gds-secondary gds-space-inline-m",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.editingContact = undefined
                                        }
                                      }
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "gds-button gds-space-inline-m",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.SaveContact()
                                        }
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              !_vm.editingContact.isNew
                                ? _c("div", [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "my-account__dndisturb-submit gds-button gds-text-button",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.DeleteContact()
                                          }
                                        }
                                      },
                                      [_vm._v("Remove Device")]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "global-flow-error--outer-container gds-flex-container" },
      [
        _c("div", { staticClass: "global-flow-error--inner-container" }, [
          _c("img", {
            staticClass: "maintenance-graphic",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/outages-notifications.svg"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "maintenance-message maintenance-message--main gds-space-stack-m"
            },
            [
              _vm._v(
                'We’re upgrading our notifications system and a new interface is coming soon. In the meantime please text "REG" to GMPVT (46788) to register a new contact.'
              )
            ]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "notifications__intro" }, [
      _vm._v(
        "\n                There are no mobile devices set up to receive push notifications. Turn on push notifications from the\n                "
      ),
      _c(
        "a",
        {
          staticClass: "gds-link gds-link--bold",
          staticStyle: { cursor: "pointer" },
          attrs: {
            href: "https://greenmountainpower.com/apps-skills/mobile-app/"
          }
        },
        [
          _c(
            "span",
            {
              staticClass:
                "my-account__notifications--text-button gds-button__text"
            },
            [_vm._v("GMP App")]
          )
        ]
      ),
      _vm._v(" on your phone or tablet.\n              ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href:
            "https://apps.apple.com/us/app/green-mountain-power/id937090423",
          target: "_blank"
        }
      },
      [
        _c("img", {
          staticClass: "notifications__app-badges",
          attrs: {
            src:
              "https://static.greenmountainpower.com/img/app-store-2020_240x80.png",
            alt: "App Store"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          href:
            "https://play.google.com/store/apps/details?id=com.ifactorconsulting.mobile.android.gmp&hl=en_US",
          target: "_blank"
        }
      },
      [
        _c("img", {
          staticClass: "notifications__app-badges",
          attrs: {
            src:
              "https://static.greenmountainpower.com/img/google-play-2020_269x80.png",
            alt: "Google Play"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "notifications__intro" }, [
      _vm._v(
        "\n              To add a new device, turn on push notifications from the\n              "
      ),
      _c(
        "a",
        {
          staticClass: "gds-link gds-link--bold",
          staticStyle: { cursor: "pointer" }
        },
        [
          _c(
            "span",
            {
              staticClass:
                "my-account__notifications--text-button gds-button__text",
              attrs: {
                href: "https://greenmountainpower.com/apps-skills/mobile-app/"
              }
            },
            [_vm._v("GMP App")]
          )
        ]
      ),
      _vm._v(" on your phone or tablet.\n            ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notifications__row-item-icon" }, [
      _c("div", { staticClass: "gds-icon" }, [
        _c("img", {
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/device-arrow.svg",
            alt: "not authorized graphic"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-ml" }, [
      _c("h2", { staticClass: "no-margin gds-display-1" }, [
        _vm._v("Choose notifications")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-ml" }, [
      _c("h2", { staticClass: "no-margin gds-display-1" }, [
        _vm._v("Do Not Disturb")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n                By turning on SMS notifications, you agree to receive power outage or other account-related text messages from\n                "
      ),
      _c("strong", [_vm._v("Green Mountain Power")]),
      _vm._v(
        ". This service will also allow you to report power outages to GMP and check outage status. For more information, text\n                "
      ),
      _c("strong", [_vm._v("HELP")]),
      _vm._v(" to\n                "),
      _c("strong", [_vm._v("46788 (GMPVT)")]),
      _vm._v(". To stop notifications, text\n                "),
      _c("strong", [_vm._v("STOP")]),
      _vm._v(" to\n                "),
      _c("strong", [_vm._v("46788 (GMPVT)")]),
      _vm._v(
        ". Message and data rates may apply. Message frequency varies. See our\n                "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "/apps-skills/text-alerts/notification-terms-and-conditions/"
          }
        },
        [_vm._v("Terms of Use")]
      ),
      _vm._v(" and\n                "),
      _c("a", { attrs: { href: "/legal/" } }, [_vm._v("Privacy Policy")]),
      _vm._v(".\n              ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass:
          "notifications__checkbox-label-with-link gds-checkbox__label gds-font-demi"
      },
      [
        _vm._v(
          "\n                    I have read and agree to the\n                    "
        ),
        _c(
          "a",
          {
            attrs: {
              href:
                "/apps-skills/text-alerts/notification-terms-and-conditions/"
            }
          },
          [_vm._v("Terms of Use")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "gds-position--relative gds-flex-container gds-space-stack-l gds-flex-container--left"
      },
      [
        _c("div", {}, [
          _c("img", {
            staticClass: "device-notifications__row-item-account--item-icon",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/device-arrow.svg",
              alt: "not authorized graphic"
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "device-notifications__row-item-account--details" },
          [_vm._v("Push Notification")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-ml" }, [
      _c("h2", { staticClass: "no-margin gds-display-1" }, [
        _vm._v("Choose notifications")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }