var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__double-card top-card-display__double-card--recent-transactions"
    },
    [
      _vm.currentAccount
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gds-card gds-data-card top-banner-card top-banner-card--logged-in",
                class: { "gds-loading": _vm.loadState === undefined }
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "recent-transactions__header gds-flex-container gds-flex-container--space-between"
                      },
                      [
                        _c("div", { staticClass: "gds-display-0" }, [
                          _vm._v("Recent Transactions")
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          { attrs: { to: "/billing#history" } },
                          [_vm._v("View All")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.loadState === "complete"
                      ? _vm._l(_vm.recentTransactions, function(transaction) {
                          return _c(
                            "div",
                            {
                              key: transaction.transactionId,
                              staticClass:
                                "recent-transactions--item gds-flex-container gds-flex-container--left"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "recent-transactions--item-icon"
                                },
                                [
                                  transaction.type === "Bill Segment"
                                    ? _c("svg", { staticClass: "gds-icon" }, [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-document"
                                          }
                                        })
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  transaction.type === "Pay Segment"
                                    ? _c("svg", { staticClass: "gds-icon" }, [
                                        _c("use", {
                                          attrs: {
                                            "xlink:href":
                                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-payment"
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "recent-transactions--item-details"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "recent-transactions--item-date"
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "gds-space-inline-s" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFull")(
                                                transaction.date
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "recent-transactions--item-type"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("transactionType")(
                                            transaction.type
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "recent-transactions--item-amount"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        transaction.payoffAmount
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        })
                      : _vm._e()
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.loadState === "error"
                  ? _c("div", { staticClass: "card-error" }, [
                      _vm._v("\n        Unable to load,\n        "),
                      _c("br"),
                      _vm._v("please try again later.\n      ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loadState === "error"
                  ? _c("div", { staticClass: "card-error-layout-blank" }, [
                      _vm._v(" ")
                    ])
                  : _vm._e()
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }