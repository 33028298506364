import { forEach, removeClass } from '../functions';

export default function documentGrid() {
  var gridDocumentCategoryButtons = document.querySelectorAll('.document-grid-info-category');
  if(gridDocumentCategoryButtons[0]) {
    filterDocuments(gridDocumentCategoryButtons[0].getAttribute('data-slug'));
    gridDocumentCategoryButtons[0].classList.add('document-grid-info-category--active');
  }
  forEach(gridDocumentCategoryButtons, function(i, but) {
    but.addEventListener('click', () => {
      filterDocuments(but.getAttribute('data-slug'));
      removeClass(gridDocumentCategoryButtons, 'document-grid-info-category--active');
      but.classList.add('document-grid-info-category--active');
    });
  });
}

function filterDocuments(category) {
  var gridDocuments = document.querySelectorAll('.document-grid-document');
  forEach(gridDocuments, function(i, doc) {
    if(doc.getAttribute('data-tab').includes(category) || (category == 'all')) {
      doc.style.display = 'flex';
    } else {
      doc.style.display = 'none';
    }
  });
}
