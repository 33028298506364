<template>
  <div>
    <form @submit.prevent class="gds-space-stack-l">
      <h2 class="gds-display-1 gds-space-stack-l">Edit Saved Bank Account</h2>

      <div v-if="wallet.recurringSchedule" class="form-message-box form-message-box--blue gds-space-stack-l">
        This payment method is used for automatic payments on this GMP Account. Visit the
        <router-link to="/preferences#autopay">Billing Preferences</router-link>&nbsp;page
        to change the payment method used for automatic payments.
      </div>

      <div v-if="wallet.isDebitAccountUnauthorized" class="form-message-box gds-space-stack-l">Please re-enter the information for this bank account.</div>

      <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Payment Method Nickname</span>
          <input
            v-model="wallet.accountNickName"
            v-validate="'required|max:45'"
            name="account nickname"
            key="account nickname"
            class="gds-input-field__input"
            type="text"
            placeholder="Payment Method Nickname"
            maxlength="45"
          />
        </label>
        <div v-if="errors.first('account nickname')" class="validation-error">{{ errors.first("account nickname")}}</div>
      </fieldset>

      <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Account Type</span>
          <select
            v-model="wallet.transType"
            v-validate="'required'"
            name="account type"
            key="account type"
            class="bill-pay-input-field__input gds-input-field bill-pay-input-field__select"
          >
            <option value="SAVINGS">Savings</option>
            <option value="CHECKING">Checking</option>
            <option value="MONEY_MARKET">Money Market</option>
          </select>
          <div v-if="errors.first('account type')" class="validation-error">{{ errors.first("account type")}}</div>
        </label>
      </fieldset>
      <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Account Number</span>
          <input
            v-model="bankNumber"
            v-mask="'######################'"
            v-validate="'required|numeric|min:5|max:22'"
            name="account number"
            key="account number"
            :placeholder="wallet.debitAccount ? '******' + wallet.debitAccount : ''"
            class="bill-pay-input-field__input gds-input-field__input"
            type="tel"
            maxlength="22"
          />
          <div v-if="errors.first('account number')" class="validation-error">{{ errors.first("account number")}}</div>
        </label>
      </fieldset>
      <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Routing Number</span>
          <input
            v-model="wallet.debitRouting"
            v-mask="'#########'"
            v-validate="'required|numeric|min:9'"
            name="routing number"
            key="routing number"
            class="bill-pay-input-field__input gds-input-field__input"
            type="tel"
            maxlength="9"
          />
          <div v-if="errors.first('routing number')" class="validation-error">{{ errors.first("routing number")}}</div>
        </label>
      </fieldset>
    </form>

    <div class="gds-flex-container gds-flex-container--left gds-space-stack-l">
      <button @click="Complete()" type="button" class="bill-pay__paired-button gds-button gds-compact gds-secondary">Cancel</button>
      <button @click="Save()" :disabled="pendingSave" :class="{ 'gds-loading': pendingSave }" type="button" class="bill-pay__paired-button gds-button gds-compact">
        <span class="gds-button__text">Save</span>
      </button>
    </div>
    <div v-if="errorSave" class="form-message-box gds-space-stack-l">{{errorSave}}</div>

    <div>
      <button @click="RemoveMethod()" :disabled="pendingRemove" :class="{ 'gds-loading': pendingRemove }" type="button" class="gds-button gds-secondary gds-round gds-red">
        <span class="my-account__notifications--text-button gds-button__text">Remove This Payment Method</span>
      </button>
      <div v-if="errorRemove" class="form-message-box gds-space-stack-l">{{errorRemove}}</div>
    </div>
    <modal-remove-autopay-wallet :visible="showRemove" :wallet="wallet" @complete="RemoveComplete" />
  </div>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { DumpError, ToFullExpirationDate } from "../../../utilities";
import { InterpretSpeedpayCode } from "../../../speedpay";
import ModalRemoveAutopayWallet from './ModalRemoveAutopayWallet';

export default {
  name: "EditorWalletBank",
  props: ["wallet"],
  data() {
    return {
      bankNumber: undefined,

      pendingSave: false,
      errorSave: false,

      pendingRemove: false,
      errorRemove: false,

      showRemove: false,
    };
  },
  components: {
    ModalRemoveAutopayWallet,
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
    async Save() {
      this.errorSave = undefined;
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        try {
          const wallet = this.wallet;
          const walletRecord = {
            accountNickName: wallet.accountNickName,
            paymentType: wallet.paymentType,
            debitAccount: this.bankNumber,
            debitRouting: wallet.debitRouting,
            transType: wallet.transType,
            creditAccount: this.currentAccount.accountNumber,
            // Populate all location fields from account, should be required but ignored
            debitZip: this.currentAccount.address.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1
          };
          await GMPAPI.UpdateWallet(
            this.currentAccount.accountNumber,
            walletRecord,
            wallet.walletId
          );
          // Kick back to wallet list and refresh
          this.Complete();
        } catch (err) {
          DumpError("Save bank error", err);
          let code;
          if (err.response && err.response.data) {
            code = err.response.data.code;
            if (err.response.data.message) {
              console.error(code + ": " + err.response.data.message);
            }
          }
          // Take specific actions on certain error codes here
          const parsed = InterpretSpeedpayCode(code);
          if (parsed) {
            if (parsed.field) {
              // This is a field-specific error
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add(parsed);
            } else {
              // This is a generic error
              this.errorSave = parsed.msg;
            }
          } else {
            // Error not recognized or no response data
            this.errorSave = "There was an unexpected error saving your bank account.";
          }
        } finally {
          this.pendingSave = false;
        }
      }
    },
    Complete() {
      this.$emit("complete");
    },
    async RemoveMethod() {
      this.pendingRemove = true;
      try {
        if (this.wallet.recurringSchedule) {
          // If this is the autopay wallet, show a confirmation dialog before delete
          this.showRemove = true;
        } else {
          // Just go ahead and delete it
          await GMPAPI.DeleteWallet(this.currentAccount.accountNumber, this.wallet.walletId);
          this.Complete();
        }
      } catch (err) {
        DumpError("Remove wallet error", err);
        this.errorRemove = "There was an unexpected error removing the payment method.";
      } finally {
        this.pendingRemove = false;
      }
    },
    RemoveComplete(changed) {
      this.showRemove = false;
      // If the wallet was deleted, we are done here
      if (changed) {
        this.Complete();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>