var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-billing-payment--dashboard" },
    [
      _c(
        "div",
        { staticClass: "usage-page--interior-status-container" },
        [
          _vm.loadState === "error"
            ? _c("flow-error", {
                attrs: { name: "Usage Alerts", state: "error" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "maintenance"
            ? _c("flow-error", {
                attrs: { name: "Usage Alerts", state: "maintenance" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "unavailable"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage Alerts",
                  state: "unavailable",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "subpage-dashboards--left-spacer" }, [
        _vm.loadState === "complete"
          ? _c(
              "div",
              [
                _c(
                  "h1",
                  { staticClass: "my-account__title gds-space-stack-xl" },
                  [_vm._v("Notifications")]
                ),
                _vm._v(" "),
                _c("h2", { staticClass: " gds-display-1 gds-space-stack-m" }, [
                  _vm._v("Configure Usage Alerts")
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "gds-font-size-l notification-usage-alert--heading"
                  },
                  [
                    _vm._v(
                      "Usage notifications are sent once you have used 50%,\n        75% and 100% of the threshold in a billing period."
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "notification-usage-alert--card-container" },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "notification-usage-alert--card",
                          { selected: _vm.areThresholdsBySeason }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.onChangeThresholdType("seasonal")
                          }
                        }
                      },
                      [
                        _vm.isSingleYearRoundThreshold
                          ? _c("img", {
                              staticClass: "notification-usage-alert--checkbox",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/checkbox-circle.svg"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.areThresholdsBySeason
                          ? _c("img", {
                              staticClass: "notification-usage-alert--checkbox",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/checkmark-in-green-circle.svg"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "notification-usage-alert--checkbox-label"
                          },
                          [_vm._v("Different Threshold for Each Season")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: [
                          "notification-usage-alert--card",
                          { selected: _vm.isSingleYearRoundThreshold }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.onChangeThresholdType("annual")
                          }
                        }
                      },
                      [
                        _vm.areThresholdsBySeason
                          ? _c("img", {
                              staticClass: "notification-usage-alert--checkbox",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/checkbox-circle.svg"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isSingleYearRoundThreshold
                          ? _c("img", {
                              staticClass: "notification-usage-alert--checkbox",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/checkmark-in-green-circle.svg"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass:
                              "notification-usage-alert--checkbox-label"
                          },
                          [_vm._v("Single Threshold Year Round")]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "notification-usage-alert--toggle-container" },
                  [
                    _c(
                      "fieldset",
                      { staticClass: "gds-fieldset gds-space-inline-m" },
                      [
                        _c("label", { staticClass: "gds-switch" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isSettingThresholdsAutomatically,
                                expression: "isSettingThresholdsAutomatically"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(
                                _vm.isSettingThresholdsAutomatically
                              )
                                ? _vm._i(
                                    _vm.isSettingThresholdsAutomatically,
                                    null
                                  ) > -1
                                : _vm.isSettingThresholdsAutomatically
                            },
                            on: {
                              click: function($event) {
                                return _vm.toggleAutomaticThresholds()
                              },
                              change: function($event) {
                                var $$a = _vm.isSettingThresholdsAutomatically,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.isSettingThresholdsAutomatically = $$a.concat(
                                        [$$v]
                                      ))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.isSettingThresholdsAutomatically = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.isSettingThresholdsAutomatically = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "gds-switch__faux" }),
                          _vm._v("Set My Thresholds Automatically\n          ")
                        ])
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.seasons, function(season, i) {
                  return _c("section", { key: season.threshold }, [
                    _vm.areThresholdsBySeason
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "notification-usage-alert--threshold-container"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "notification-usage-alert--threshold-image-container"
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "notification-usage-alert--threshold-image",
                                  attrs: { src: season.iconUrl }
                                }),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "gds-font-demi notification-usage-alert--threshold-label"
                                  },
                                  [_vm._v(_vm._s(season.threshold))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { class: _vm.rangeFieldStyle }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "notification-usage-alert--range-field"
                                },
                                [_vm._v(_vm._s(season.months))]
                              ),
                              _vm._v(" "),
                              !_vm.isSettingThresholdsAutomatically
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "notification-usage-alert--range-field"
                                    },
                                    [
                                      _vm._v(
                                        "Suggested range: " +
                                          _vm._s(season.suggestedSeasonalLow) +
                                          " - " +
                                          _vm._s(season.suggestedSeasonalHigh) +
                                          " kWh"
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "notification-usage-alert--kwh-container"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.seasons[i].seasonThresholdAmount,
                                      expression:
                                        "seasons[i].seasonThresholdAmount"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|numeric",
                                      expression: "'required|numeric'"
                                    }
                                  ],
                                  staticClass:
                                    "gds-input-field__input  notification-usage-alert--threshold-amount",
                                  attrs: {
                                    maxlength: "7",
                                    disabled: _vm.isSingleYearRoundThreshold,
                                    type: "text",
                                    name: "threshold amount" + i
                                  },
                                  domProps: {
                                    value: _vm.seasons[i].seasonThresholdAmount
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.seasons[i],
                                          "seasonThresholdAmount",
                                          $event.target.value
                                        )
                                      },
                                      _vm.onInputThresholdAmount
                                    ],
                                    click: _vm.onClickThresholdAmount
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "notification-usage-alert--kWh-label"
                                  },
                                  [_vm._v("kWh")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errors.first("threshold amount" + i)
                      ? _c("div", { staticClass: "validation-error" }, [
                          _vm._v(
                            "Threshold amount is required and must be numeric"
                          )
                        ])
                      : _vm._e()
                  ])
                }),
                _vm._v(" "),
                _c("section", [
                  _vm.isSingleYearRoundThreshold
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "notification-usage-alert--annual-threshold-container"
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "notification-usage-alert--annual-range-container"
                            },
                            [
                              !_vm.isSettingThresholdsAutomatically
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "notification-usage-alert--annual-range-field"
                                    },
                                    [
                                      _vm._v(
                                        "Suggested range: " +
                                          _vm._s(_vm.suggestedYearRoundLow) +
                                          " - " +
                                          _vm._s(_vm.suggestedYearRoundHigh) +
                                          " kWh"
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return _vm.Save()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "notification-usage-alert--kwh-container"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.annualThresholdAmount,
                                            expression: "annualThresholdAmount"
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required|numeric",
                                            expression: "'required|numeric'"
                                          }
                                        ],
                                        staticClass:
                                          "gds-input-field__input notification-usage-alert--annual-threshold-amount",
                                        attrs: {
                                          maxlength: "7",
                                          disabled: _vm.areThresholdsBySeason,
                                          type: "text",
                                          name: "threshold amount"
                                        },
                                        domProps: {
                                          value: _vm.annualThresholdAmount
                                        },
                                        on: {
                                          input: [
                                            function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.annualThresholdAmount =
                                                $event.target.value
                                            },
                                            function($event) {
                                              return _vm.onInputThresholdAmount()
                                            }
                                          ],
                                          click: _vm.onClickThresholdAmount
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "notification-usage-alert--kWh-label"
                                        },
                                        [_vm._v("kWh")]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.errors.first("threshold amount")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "validation-error",
                                      staticStyle: { "margin-left": "2.5rem" }
                                    },
                                    [_vm._v("Threshold amount must be numeric")]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "notification-usage-alert--button-container" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "gds-button gds-secondary gds-space-stack-m notification-usage-alert--cancel-button",
                        attrs: { type: "submit" },
                        on: {
                          click: function($event) {
                            return _vm.onCancel()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "gds-button__text" }, [
                          _vm._v("Cancel")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "gds-button gds-space-stack-m",
                        class: { "gds-loading": _vm.pendingSave },
                        attrs: {
                          type: "submit",
                          disabled: _vm.pendingSave || !_vm.touched
                        },
                        on: {
                          click: function($event) {
                            return _vm.onSave()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "gds-button__text" }, [
                          _vm._v("Save")
                        ])
                      ]
                    )
                  ]
                )
              ],
              2
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "notification-usage-alert--threshold-image-container" },
      [
        _c("img", {
          staticClass: "notification-usage-alert--threshold-image",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/calendar-white-and-green.svg"
          }
        }),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "notification-usage-alert--annual-threshold-label" },
          [_vm._v("Annual Threshold")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }