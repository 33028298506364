<template>
  <div class="my-account__not-authorized-page-wrap">
    <section class="my-account__login-form-wrap gds-align--text-center">
      <div class="my-account__not-authorized-image">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/not-authorized.svg" alt="not authorized graphic" />
      </div>
      <div class="gds-font-size-xl gds-font-demi gds-space-stack-ml">You are not authorized to view this account.</div>
      <span class="gds-display-0 gds-link gds-link--bold">
        <a class="green-arrow-link--right" href="/account">Go to My Account</a>
      </span>
    </section>
  </div>
</template>

<script>
export default {
  name: "PageNotAuthorized",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>