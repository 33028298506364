<template>
  <div>
    <outage-header :updated="updated" :personalData="personalData" />
    <div class="outage-map-wrapper">
      <gmp-map ref="map" kind="homepage" :townVisibility="townVisibility" @update="onMapUpdate" />
      <button v-if="townListExpanded" @click="townListExpanded= false" class="town-collapse-toggle" :title="(townListExpanded ? 'View' : 'Close') + ' Town List'"></button>
      <town-list class="outage-list-slideout" :townData="townData" :activeIncidents="activeIncidents" :class="{ listopen: townListExpanded }" @hover="onHoverTown" @close="townListExpanded = false" />
      <button v-if="!townListExpanded" @click="townListExpanded = true" class="gds-button outage-slide-out-toggle">
        <span class="gds-button__text">
          <span>View Town List</span>
        </span>
      </button>

      <div class="map-info-button layers" title="Map Layers" @click="showLayer = !showLayer" />
      <div class="map-info-button legend" title="Map Legend" @click="showLegend = true" />
      <div v-if="showLayer" class="map-layer-list" v-click-outside="CloseLayers">
        <div class="gds-display-00 gds-space-stack-m">Map Layers</div>
        <fieldset class="gds-fieldset gds-fieldset--no-left-padding">
          <label class="gds-radio">
            <input v-model="townVisibility" :value="true" type="radio" name="show-towns" />
            <span class="gds-radio__faux"></span>
            <span class="gds-radio__label">Towns &amp; Incidents</span>
          </label>
          <label class="gds-radio">
            <input v-model="townVisibility" :value="false" type="radio" name="show-towns" />
            <span class="gds-radio__faux"></span>
            <span class="gds-radio__label">Incidents Only</span>
          </label>
        </fieldset>
      </div>
      <modal-map-legend :visible="showLegend" @close="showLegend = false" />
    </div>
  </div>
</template>

<script>
import GmpMap from "./GmpMap.vue";
import TownList from "./TownList.vue";
import OutageHeader from "./OutageHeader.vue";
import ModalMapLegend from "./ModalMapLegend.vue";

export default {
  name: "SubpageOutageOverview",
  components: {
    GmpMap,
    TownList,
    OutageHeader,
    ModalMapLegend,
  },
  data() {
    return {
      townListExpanded: false,
      townVisibility: true,
      updated: undefined,
      personalData: undefined,
      townData: undefined,
      activeIncidents: undefined,
      showLayer: false,
      showLegend: false,
    };
  },
  async mounted() {
  },
  methods: {
    onHoverTown(townName) {
      if (this.$refs.map) {
        this.$refs.map.SetTownHover(townName);
      }
    },
    /** Use the copies of personalData, townData, activeIncidents the map is pulling anyway, rather than pulling them ourselves an extra time */
    onMapUpdate(apiData) {
      if (!apiData) return;
      this.updated = new Date();
      if (apiData.personalData !== undefined) {
        this.personalData = apiData.personalData;
      }
      if (apiData.townData !== undefined) {
        this.townData = apiData.townData;
      }
      if (apiData.activeIncidents !== undefined) {
        this.activeIncidents = apiData.activeIncidents;
      }
    },
    CloseLayers() {
      this.showLayer = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>