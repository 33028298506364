var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "settings-editor my-account__full-width-section my-account__section-with-bottom-border"
    },
    [
      _vm.loadState !== "complete"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m"
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass: "settings-editor__title gds-space-inline-m"
                      },
                      [_vm._v("Recurring Auto Payments")]
                    ),
                    _vm._v(" "),
                    _vm.loadState === undefined
                      ? _c("div", { staticClass: "editor-loading" })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "fake-edit-link" }, [_vm._v("Edit")])
              ]
            ),
            _vm._v(" "),
            _vm.loadState === "error"
              ? _c("div", { staticClass: "editor-error" }, [
                  _vm._v("Unable to load this section. Please try again later.")
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m"
                  },
                  [
                    _c(
                      "h2",
                      {
                        ref: "scrolltarget",
                        staticClass: "settings-editor__title gds-space-inline-m"
                      },
                      [_vm._v("Recurring Auto Payments")]
                    ),
                    _vm._v(" "),
                    !_vm.editing &&
                    _vm.billingStatus &&
                    _vm.recurringStatus !== undefined
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "gds-flex-container gds-flex-container--left"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "my-account__on-off-text gds-flex-container gds-flex-container--left gds-flex-container--top"
                              },
                              [
                                _c("IndicatorOnOff", {
                                  attrs: {
                                    value:
                                      _vm.recurringStatus ||
                                      _vm.billingStatus.legacyAutoPayEnrolled
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "edit-close-button-combo" }, [
                  !_vm.editing &&
                  _vm.recurringStatus !== undefined &&
                  _vm.billingStatus &&
                  !_vm.billingStatus.legacyAutoPayEnrolled
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-text-button gds-space-inline-l",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.BeginEdit()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-account__notifications--text-button gds-button__text"
                            },
                            [_vm._v("Edit")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editing && !_vm.pendingSave
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-text-button gds-space-inline-l",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-account__notifications--text-button gds-button__text"
                            },
                            [_vm._v("Close")]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            !_vm.editing && _vm.billingStatus
              ? _c("div", [
                  _vm.billingStatus.legacyAutoPayEnrolled &&
                  _vm.recurringStatus === null
                    ? _c(
                        "div",
                        { staticClass: "gds-space-stack-l gds-text-grey" },
                        [
                          _vm._v(
                            "\n        You are enrolled in Recurring Auto Payments. To make changes please contact customer service at\n        "
                          ),
                          _c("span", { staticClass: "gds-nobreak" }, [
                            _vm._v(_vm._s(_vm.GMPPhoneNumber) + ".")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.billingStatus.legacyAutoPayEnrolled &&
                  _vm.recurringStatus === null
                    ? _c(
                        "div",
                        { staticClass: "gds-space-stack-l gds-text-grey" },
                        [
                          _vm._v(
                            "Turn on recurring auto payments to automatically pay your bill each month."
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.saveComplete && !_vm.recurringStatus
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-message-box form-message-box--green gds-space-stack-l"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "gds-flex-container gds-flex-container--left"
                            },
                            [
                              _c("svg", { staticClass: "gds-icon" }, [
                                _c("use", {
                                  attrs: {
                                    "xlink:href":
                                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "You have successfully unenrolled from Recurring Auto Payments."
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.recurringStatus
                    ? _c("div", [
                        _vm.saveComplete
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "form-message-box form-message-box--green gds-space-stack-l"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gds-flex-container gds-flex-container--left"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        "You have successfully enrolled in Recurring Auto Payments."
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "gds-font-book gds-space-stack-s" },
                          [
                            _vm._v(
                              "Your balance due will be withdrawn 5 days before the due date."
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "gds-font-book gds-space-stack-ml" },
                          [
                            _vm._v(
                              "Card payments have a $2,500 per month maximum. To avoid cancelled payments, use a bank account for the payment method if your monthly bill might exceed $2,500."
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "gds-font-size-m gds-font-demi gds-space-stack-s",
                            attrs: { id: "payment-method" }
                          },
                          [_vm._v("Payment Method")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "gds-flex-container gds-flex-container--top gds-flex-container--left gds-space-stack-l"
                          },
                          [
                            _c("div", { staticClass: "gds-space-inline-s" }, [
                              (_vm.recurringWallet &&
                                _vm.recurringWallet.paymentType === "ACH") ||
                              _vm.recurringStatus.recurringScheduleRecord
                                .debitInformation.paymentType === "ACH"
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "gds-icon gds-gmpgreen-icon"
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-bank"
                                        }
                                      })
                                    ]
                                  )
                                : _c(
                                    "svg",
                                    {
                                      staticClass: "gds-icon gds-gmpgreen-icon"
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card"
                                        }
                                      })
                                    ]
                                  )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "my-account__autopay-wallet-nickname"
                              },
                              [
                                _vm.recurringWallet &&
                                _vm.recurringWallet.accountNickName
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-font-size-l gds-font-book gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.recurringWallet.accountNickName
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gds-font-size-l gds-font-book",
                                    attrs: {
                                      "aria-labelledby": "payment-method"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("walletSlug")(
                                          _vm.recurringWallet ||
                                            _vm.recurringStatus
                                              .recurringScheduleRecord
                                              .debitInformation
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.editing && _vm.billingStatus
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.Save()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "my-account__header-button-pairing gds-flex-container gds-flex-container--left"
                      },
                      [
                        _c(
                          "fieldset",
                          { staticClass: "gds-fieldset gds-space-inline-m" },
                          [
                            _c("label", { staticClass: "gds-switch" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.autoPaySwitch,
                                    expression: "autoPaySwitch"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.autoPaySwitch)
                                    ? _vm._i(_vm.autoPaySwitch, null) > -1
                                    : _vm.autoPaySwitch
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.autoPaySwitch,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.autoPaySwitch = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.autoPaySwitch = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.autoPaySwitch = $$c
                                      }
                                    },
                                    function($event) {
                                      _vm.touched = true
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-switch__faux" })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm.autoPaySwitch === _vm.autoPayAPIEnabled
                            ? _c("div", { staticClass: "gds-space-stack-m" }, [
                                _vm._v(
                                  "Recurring Auto Payments are turned " +
                                    _vm._s(_vm.autoPaySwitch ? "on" : "off") +
                                    "."
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.autoPaySwitch !== _vm.autoPayAPIEnabled
                            ? _c("div", { staticClass: "gds-space-stack-m" }, [
                                _vm._v(
                                  "Recurring Auto Payments will be turned " +
                                    _vm._s(_vm.autoPaySwitch ? "on" : "off") +
                                    ". Click save to confirm."
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.autoPaySwitch
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "gds-font-book gds-space-stack-s" },
                            [
                              _vm._v(
                                "Your balance due will be withdrawn 5 days before the due date."
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gds-font-book gds-space-stack-ml" },
                            [
                              _vm._v(
                                "Card payments have a $2,500 per month maximum. To avoid cancelled payments, use a bank account for the payment method if your monthly bill might exceed $2,500."
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gds-text-grey gds-space-stack-m" },
                            [_vm._v("Select a Payment Method")]
                          ),
                          _vm._v(" "),
                          _vm.wallets && _vm.wallets.length === 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "recurring-payments--no-saved-methods gds-flex-container gds-space-stack-l"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gds-flex-container gds-flex-container--column"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "my-account__recurring--add-new-box"
                                        },
                                        [
                                          _vm.autoPayAPIEnabled
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-m"
                                                },
                                                [
                                                  _vm._v(
                                                    "To make changes, please re-add your payment method."
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.autoPayAPIEnabled
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-m"
                                                },
                                                [
                                                  _vm._v(
                                                    "No Saved Payment Methods"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "gds-button gds-round gds-secondary",
                                              attrs: {
                                                to:
                                                  "/saved-payments" +
                                                  _vm.returnParam +
                                                  "#add"
                                              }
                                            },
                                            [_vm._v("Add New")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.wallets && _vm.wallets.length > 0
                            ? _c("div", { staticClass: "gds-space-stack-s" }, [
                                _c("div", { attrs: { role: "radiogroup" } }, [
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass:
                                        "gds-fieldset gds-space-stack-s"
                                    },
                                    _vm._l(_vm.wallets, function(wallet) {
                                      return _c("wallet-selector", {
                                        key: wallet.walletId,
                                        attrs: { value: wallet },
                                        on: {
                                          change: function($event) {
                                            _vm.touched = true
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedWallet,
                                          callback: function($$v) {
                                            _vm.selectedWallet = $$v
                                          },
                                          expression: "selectedWallet"
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedWallet &&
                          (_vm.selectedWallet.isExpired ||
                            _vm.selectedWallet.isDebitAccountUnauthorized)
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "form-message-box gds-space-stack-l"
                                },
                                [
                                  _vm._v(
                                    "\n          Your selected payment method has an issue. Please select a different method or fix the payment method on the\n          "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: "/saved-payments" + _vm.returnParam
                                      }
                                    },
                                    [_vm._v("Manage Saved Payments")]
                                  ),
                                  _vm._v(" page.\n        ")
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.wallets && _vm.wallets.length !== 0
                            ? _c(
                                "div",
                                { staticClass: "gds-space-stack-l" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "gds-button gds-round gds-secondary",
                                      attrs: {
                                        to:
                                          "/saved-payments" +
                                          _vm.returnParam +
                                          "#add"
                                      }
                                    },
                                    [_vm._v("Add New")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "gds-button gds-round gds-secondary",
                                      attrs: {
                                        to: "/saved-payments" + _vm.returnParam
                                      }
                                    },
                                    [_vm._v("Manage")]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedWallet &&
                          _vm.selectedWallet.paymentType === "CC"
                            ? _c("div", { staticClass: "gds-space-stack-m" }, [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "bill-pay-slat__small-flex gds-fieldset"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "gds-input-field" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gds-input-field__label"
                                          },
                                          [_vm._v("Verify your CVV")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.cvv,
                                              expression: "cvv"
                                            },
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "####",
                                              expression: "'####'"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value:
                                                "required|numeric|min:3|max:4",
                                              expression:
                                                "'required|numeric|min:3|max:4'"
                                            }
                                          ],
                                          key: "CVV",
                                          staticClass: "gds-input-field__input",
                                          attrs: {
                                            name: "CVV",
                                            type: "tel",
                                            maxlength: "4",
                                            autocomplete: "cc-csc"
                                          },
                                          domProps: { value: _vm.cvv },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.cvv = $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors.first("CVV")
                                      ? _c(
                                          "div",
                                          { staticClass: "validation-error" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.errors.first("CVV"))
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedWallet &&
                          (_vm.selectedWallet.paymentType === "CC" ||
                            _vm.selectedWallet.paymentType === "ATM")
                            ? _c("div", { staticClass: "gds-space-stack-m" }, [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "bill-pay-slat__small-flex gds-fieldset"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "gds-input-field" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gds-input-field__label"
                                          },
                                          [
                                            _vm._v(
                                              "Verify your Billing ZIP Code"
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.debitZip,
                                              expression: "debitZip"
                                            },
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "#####",
                                              expression: "'#####'"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value:
                                                "required|numeric|min:5|max:5",
                                              expression:
                                                "'required|numeric|min:5|max:5'"
                                            }
                                          ],
                                          key: "billing zip",
                                          staticClass: "gds-input-field__input",
                                          attrs: {
                                            name: "billing zip",
                                            type: "tel",
                                            maxlength: "5"
                                          },
                                          domProps: { value: _vm.debitZip },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.debitZip = $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors.first("billing zip")
                                      ? _c(
                                          "div",
                                          { staticClass: "validation-error" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first("billing zip")
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.wallets && _vm.wallets.length !== 0
                            ? _c("div", { staticClass: "gds-space-stack-l" }, [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass:
                                      "bill-pay-slat__small-flex gds-fieldset"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "gds-input-field" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "gds-input-field__label"
                                          },
                                          [
                                            _vm._v(
                                              "Email for sending payment confirmations"
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.emailAddress,
                                              expression: "emailAddress"
                                            },
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required|email|max:74",
                                              expression:
                                                "'required|email|max:74'"
                                            }
                                          ],
                                          key: "email address",
                                          staticClass:
                                            "bill-pay-input-field__input gds-input-field__input",
                                          attrs: {
                                            maxlength: "74",
                                            name: "email address",
                                            type: "email",
                                            placeholder: "Your email",
                                            autocomplete: "email"
                                          },
                                          domProps: { value: _vm.emailAddress },
                                          on: {
                                            change: function($event) {
                                              _vm.touched = true
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.emailAddress =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.errors.first("email address")
                                      ? _c(
                                          "div",
                                          { staticClass: "validation-error" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "email address"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.display5DayMessage
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-message-box form-message-box--blue gds-space-stack-l"
                          },
                          [
                            _vm._v(
                              "\n        Note: your recurring auto payment will not begin this months as it is less than 5 days until your bill due date. \n        It will begin next month. If you wish to make a payment for this bill month, please\n        "
                            ),
                            _c("a", { attrs: { href: "/make-payment" } }, [
                              _vm._v("click here")
                            ]),
                            _vm._v(".\n      ")
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorSave
                      ? _c(
                          "div",
                          { staticClass: "form-message-box gds-space-stack-m" },
                          [_vm._v(_vm._s(_vm.errorSave))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "gds-space-stack-l" }, [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button gds-round gds-space-stack-m",
                          class: { "gds-loading": _vm.pendingSave },
                          attrs: {
                            disabled:
                              _vm.pendingSave ||
                              !_vm.touched ||
                              !_vm.wallets ||
                              (_vm.wallets.length === 0 && _vm.autoPaySwitch),
                            type: "button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.Save()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Save")
                          ])
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }