<template>
  <tr>
    <th @click="toggleHourlyUsage(usage.date)">
      <div class="date expand-toggle" :class="{ expanded: isExpanded }">{{ formatDate(usage.date, 'short') }}
        <span>          
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="12" cy="12" r="12" fill="#DADADA" />
<path d="M12 6V18M18 12L6 12" stroke="#202020" stroke-width="2px" />
</svg>

      </span></div>
    </th>
    <td><div class="day-of-week">{{ getDayOfWeek(usage.date, 'long') }}</div></td>
    <td v-if="isNetMetered">
      <div class="net-use-circle" :class="{ negative: usage.returnedGeneration < usage.consumedTotal }">
        {{ netUsage(usage.returnedGeneration, usage.consumedTotal) }}
      </div>
    </td>
    <td v-if="isNetMetered" class="usage-detail__data-series" data-series="totalEnergyUsed">
      {{ usage.totalEnergyUsed | numberCommaSingleDecimal }} <span class="unit">kWh</span>
      <Usage-Consumption-Bar-CSS :series1="usage.totalEnergyUsed" :maxUsageValue="maxUsageValue" />
    </td>
    <td v-if="isNetMetered" class="usage-detail__data-series" data-series="generation">
      <span class="main-series">{{ determineGenerationAmount(usage) | numberCommaSingleDecimal }} <span class="unit">kWh</span></span>
      <span class="sub-series parenthesis-wide">
        <span v-if="usage.generation != undefined && usage.generation > 0">{{ usage.generation - usage.returnedGeneration | numberCommaSingleDecimal }} <span class="unit">kWh to home,</span></span>
        <span class="sub-series">{{ usage.returnedGeneration | numberCommaSingleDecimal }} <span class="unit">kWh to grid</span></span>
      </span>

      <Usage-Consumption-Bar-CSS :series1="usage.generation - usage.returnedGeneration" :series2="usage.returnedGeneration" :maxUsageValue="maxUsageValue" />
    </td>
    <td v-if="!hasTOU" class="usage-detail__data-series" data-series="consumedTotal">
      {{ usage.consumedTotal | numberCommaSingleDecimal }} <span class="unit">kWh</span>
      <Usage-Consumption-Bar-CSS :series1="usage.consumedTotal" :maxUsageValue="maxUsageValue" />
    </td>
    <td v-if="hasTOU" class="usage-detail__data-series" data-series="consumedTOU">
      <span class="main-series">{{ usage.consumedTotal | numberCommaSingleDecimal }} <span class="unit">kWh</span></span>
      <span class="sub-series parenthesis-wide">
        <span class="sub-series">{{ getOnPeakUsage | numberCommaSingleDecimal }} <span class="unit">kWh Peak,</span></span>
        <span class="sub-series"> {{ getOffPeakUsage | numberCommaSingleDecimal }} <span class="unit">kWh Off Peak</span></span>
       </span>
      <Usage-Consumption-Bar-CSS :series1="getOnPeakUsage" :series2="getOffPeakUsage" :maxUsageValue="maxUsageValue" />
    </td>
    <td v-if="hasEvCharger" class="usage-detail__data-series" data-series="evCharger">
      <span v-if="!evChargerHasValue">&nbsp;</span>
      <span v-if="evChargerHasValue">
        <span class="main-series">{{ getTotalEvCharger | numberCommaSingleDecimal }} <span class="unit">kWh</span></span>
        <span v-if="evChargerHasPeakAndOffPeak" class="sub-series parenthesis-wide">
          <span class="sub-series">{{ getOnPeakEvCharger | numberCommaSingleDecimal }} <span class="unit">kWh Peak,</span></span>
          <span class="sub-series"> {{ getOffPeakEvCharger | numberCommaSingleDecimal }} <span class="unit">kWh Off Peak</span></span>
        </span>
        <Usage-Consumption-Bar-CSS :series1="getOnPeakEvCharger" :series2="getOffPeakEvCharger" :maxUsageValue="maxUsageValue" />
      </span>
    </td>
    <td v-if="hasSnapshotData && !isNetMetered" class="usage-detail__spark-chart">
      <div v-if="usage.date && !isTodayOrYesterday(usage.date) && dailyUsagePeriodValues && dailyUsagePeriodValues.length > 0" class="usage-detail__spark-chart-container">
        <span class="usage-detail__highest-usage-period">{{ usage.highestUsagePeriodOfDay }}</span>
        <HighestUsageMicroSparkbar :data="dailyUsagePeriodValues" class="usage-detail__spark-chart" />
      </div>
    </td>
    <td class="usage-detail__temp">{{ getTemperature(usage).low ? getTemperature(usage).low + '°F' : '' }}</td>
    <td class="usage-detail__temp">{{ getTemperature(usage).high ? getTemperature(usage).high + '°F' : '' }}</td>
    <td class="usage-detail__weather">
      <img v-if="usage.weatherIcon" class="usage-detail__weather-image" :src="weatherImagePath + usage.weatherIconImageUrl" alt="Weather Icon" />
    </td>
  </tr>
</template>

<script>
import { format } from "date-fns";
import usageTableUtil from "../../../mixins/UsageTableUtil";
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageConsumptionBarCSS from "../usageshared/UsageConsumptionBarCSS";
import HighestUsageMicroSparkbar from "../highestusagemicrosparkbar/HighestUsageMicroSparkbar";

export default {
  name: "UsageDetailTableRow",
  mixins: [usageTableUtil, MediaQueryMixin],
  props: {
    expandedRow: Number,
    usage: Object,
    hasGenerationRecordsPresent: Boolean,
    maxUsageValue: Number,
    hasTOU: Boolean,
    dailyUsagePeriodValues: Array,
    isNetMetered: Boolean,
    hasSnapshotData: Boolean,
    hasEvCharger: Boolean,
  },
  components: {
    HighestUsageMicroSparkbar,
    UsageConsumptionBarCSS,
  },
  data() {
    return {
      hourlyUsageLoadState: undefined,
      usageTableCellClass: undefined,
      usageTableEmptyCellClass: undefined,
      returnedGenerationClass: undefined,
      monthSelected: undefined,
      timeOfDayMap: [
        { hour0to3: "12am-4am" },
        { hour4to7: "4am-8am" },
        { hour8to11: "8am-12pm" },
        { hour12to15: "12pm-4pm" },
        { hour16to19: "4pm-8pm" },
        { hour20to23: "8pm-12pm" },
      ],
      CALENDAR_MONTH: "calendar-month",
    };
  },
  watch: {
    currentAccount() {
      this.getDailyUsageData();
    },
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    getOnPeakUsage() {
      return this.usage.onPeak ? this.usage.onPeak : 0;
    },
    getOffPeakUsage() {
      return this.usage.offPeak ? this.usage.offPeak : 0;
    },
    evChargerHasValue() {
      return this.usage.evConsumedTotal > 0;
    },
    evChargerHasPeakAndOffPeak() {
      return this.usage.evPeakConsumption > 0 && this.usage.evOffPeakConsumption > 0;
    },
    getTotalEvCharger() {
      return this.usage.evConsumedTotal;
    },
    getOnPeakEvCharger() {
      return this.usage.evPeakConsumption ? this.usage.evPeakConsumption : 0;
    },
    getOffPeakEvCharger() {
      return this.usage.evOffPeakConsumption ? this.usage.evOffPeakConsumption : 0;
    },
    isExpanded() {
      return this.expandedRow != -1;
    },
  },

  methods: {
    getDayOfWeek(date, length) {
      if (length === "long") {
        return format(date, "EEEE");
      }
      if (length === "short") {
        return format(date, "E");
      }
    },
    formatDate(date, length) {
      return format(date, "MMM. d, yyyy");
    },
    toggleHourlyUsage(usageDate) {
      this.$emit("toggle-hourly", usageDate);
    },
    isTodayOrYesterday(date) {
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const usageDate = new Date(date);
      return (
        usageDate.toDateString() === today.toDateString() ||
        usageDate.toDateString() === yesterday.toDateString()
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
