var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--usage"
    },
    [
      _vm.currentAccount
        ? _c("div", [
            _vm.loadState === "complete"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "top-banner-card gds-card gds-data-card top-banner-card--logged-in"
                  },
                  [
                    !_vm.isNetMetered && !_vm.isTOU
                      ? _c("div", [
                          _vm.currentBillingPeriod.consumedFromGrid !==
                          undefined
                            ? _c("div", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "gds-display-0 gds-space-stack-s"
                                  },
                                  [_vm._v("Usage This Billing Period")]
                                ),
                                _vm._v(" "),
                                _vm.daysLeft > 0
                                  ? _c(
                                      "p",
                                      { staticClass: "gds-card__caption" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.daysLeft) +
                                            "\n            "
                                        ),
                                        _vm.daysLeft !== 1
                                          ? _c("span", [_vm._v("days")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.daysLeft === 1
                                          ? _c("span", [_vm._v("day")])
                                          : _vm._e(),
                                        _vm._v("\n            left\n          ")
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentBillingPeriod.consumedFromGrid !==
                          undefined
                            ? _c(
                                "figure",
                                { staticClass: "gds-align--block-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card__usage--kw-wrap gds-flex-container gds-clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.navUsage()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "card__usage--kw-number"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f(
                                                "numberCommaSingleDecimal"
                                              )(
                                                _vm.currentBillingPeriod
                                                  .consumedFromGrid
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "card__usage--kwh" },
                                        [_vm._v("kWh")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass: "gds-align--block-center",
                                    attrs: {
                                      src:
                                        "/wp-content/themes/gmptwentynineteen/assets/images/electric-plug.svg",
                                      alt: "electric plug"
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("br"),
                          _c("br"),
                          _vm._v(" "),
                          _vm.lastBillingPeriod !== undefined
                            ? _c(
                                "div",
                                { staticClass: "card__usage--details" },
                                [
                                  _vm._v(
                                    "\n          Last billing period you used a total of\n          "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "gds-font-demi gds-clickable",
                                      on: {
                                        click: function($event) {
                                          return _vm.navUsage()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberCommaSingleDecimal")(
                                            _vm.lastBillingPeriod
                                              .consumedFromGrid
                                          )
                                        ) + " kWh"
                                      )
                                    ]
                                  ),
                                  _vm._v(".\n        ")
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.isNetMetered && _vm.isTOU
                      ? _c("div", [
                          _vm.currentBillingPeriod.consumedFromGrid !==
                          undefined
                            ? _c("div", [
                                _c("p", { staticClass: "gds-card__caption" }, [
                                  _c(
                                    "span",
                                    { staticClass: "card__usage-title-text" },
                                    [_vm._v("Time of use ")]
                                  ),
                                  _vm._v(
                                    "this billing period (" +
                                      _vm._s(_vm.daysLeft) +
                                      "\n            "
                                  ),
                                  _vm.daysLeft !== 1
                                    ? _c("span", [_vm._v("days")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.daysLeft === 1
                                    ? _c("span", [_vm._v("day")])
                                    : _vm._e(),
                                  _vm._v("\n            left)\n          ")
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentBillingPeriod.consumedFromGrid !==
                          undefined
                            ? _c(
                                "figure",
                                { staticClass: "gds-align--block-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "card-usage-content" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card__usage--kw-wrap gds-flex-container gds-clickable",
                                          on: {
                                            click: function($event) {
                                              return _vm.navUsage()
                                            }
                                          }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card__usage--kw-number-small"
                                              },
                                              [
                                                _vm._v(
                                                  "Off Peak " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "numberCommaSingleDecimal"
                                                      )(
                                                        _vm.currentBillingPeriod
                                                          .totalKwhOffPeak
                                                      )
                                                    ) +
                                                    " kWh"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card__usage--kw-number-small"
                                              },
                                              [
                                                _vm._v(
                                                  "On Peak " +
                                                    _vm._s(
                                                      _vm._f(
                                                        "numberCommaSingleDecimal"
                                                      )(
                                                        _vm.currentBillingPeriod
                                                          .totalKwhOnPeak
                                                      )
                                                    ) +
                                                    "\n                  kWh"
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        staticClass:
                                          "gds-align--block-center clock-image",
                                        attrs: {
                                          src:
                                            "/wp-content/themes/gmptwentynineteen/assets/images/clock.png",
                                          alt: "clock"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "card__usage-footer" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "line-height": "1"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Last billing period you used:"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "prior-period-container"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card__usage--kw-number-small"
                                                },
                                                [
                                                  _vm._v(
                                                    "Off Peak " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "numberCommaSingleDecimal"
                                                        )(
                                                          _vm.lastBillingPeriod
                                                            .totalKwhOffPeak
                                                        )
                                                      )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card__usage--kw-number-small"
                                                },
                                                [
                                                  _vm._v(
                                                    "On Peak " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "numberCommaSingleDecimal"
                                                        )(
                                                          _vm.lastBillingPeriod
                                                            .totalKwhOnPeak
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isNetMetered && _vm.netmeteringCardNumber === 1
                      ? _c(
                          "figure",
                          { staticClass: "gds-align--block-center" },
                          [
                            _c("div", { staticClass: "usage-card" }, [
                              _c("p", { staticClass: "gds-card__caption" }, [
                                _c(
                                  "span",
                                  { staticClass: "card__usage-title-text" },
                                  [_vm._v("Net energy use ")]
                                ),
                                _vm._v(
                                  " this billing period (" +
                                    _vm._s(_vm.daysLeft) +
                                    "\n            "
                                ),
                                _vm.daysLeft !== 1
                                  ? _c("span", [_vm._v("days")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.daysLeft === 1
                                  ? _c("span", [_vm._v("day")])
                                  : _vm._e(),
                                _vm._v("\n            left).\n          ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card__usage--kw-wrap gds-flex-container-column gds-clickable usage-section"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card__usage-container @click=navUsage()"
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "card__usage--kw-number"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f(
                                                "numberCommaSingleDecimal"
                                              )(
                                                _vm.currentBillingPeriod
                                                  .netConsumption
                                              )
                                            ) + " kWh"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._m(0),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "arrow-container-right-justify usage-section"
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "arrow-image gds-align--block-center",
                                    attrs: {
                                      src:
                                        "/wp-content/themes/gmptwentynineteen/assets/images/right-arrow-in-circle.png",
                                      alt: "right arrow in circle"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.scrollNextCard(0)
                                      }
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "scroll-dot-container" },
                                _vm._l(_vm.scrollDots, function(
                                  scrollDot,
                                  index
                                ) {
                                  return _c("div", [
                                    scrollDot === 0
                                      ? _c("span", {
                                          staticClass: "scroll-dot"
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scrollDot === 1
                                      ? _c("span", {
                                          staticClass: "scroll-dot-emphasize"
                                        })
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isNetMetered && _vm.netmeteringCardNumber === 2
                      ? _c(
                          "figure",
                          { staticClass: "gds-align--block-center" },
                          [
                            _c("div", { staticClass: "usage-card" }, [
                              _c("p", { staticClass: "gds-card__caption" }, [
                                _c(
                                  "span",
                                  { staticClass: "card__usage-title-text" },
                                  [_vm._v("Energy generated ")]
                                ),
                                _vm._v(
                                  "this billing period (" +
                                    _vm._s(_vm.daysLeft) +
                                    "\n            "
                                ),
                                _vm.daysLeft !== 1
                                  ? _c("span", [_vm._v("days")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.daysLeft === 1
                                  ? _c("span", [_vm._v("day")])
                                  : _vm._e(),
                                _vm._v("\n            left).\n          ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card__usage--kw-wrap gds-flex-container-column gds-clickable"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card__usage-container @click=navUsage()"
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "card__usage--kw-number"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f(
                                                "numberCommaSingleDecimal"
                                              )(
                                                _vm.currentBillingPeriod
                                                  .grossGenerated
                                              )
                                            ) + " kWh"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticClass:
                                  "gds-align--block-center solar-image",
                                attrs: {
                                  src:
                                    "/wp-content/themes/gmptwentynineteen/assets/images/solar-generation.png",
                                  alt: "solar generation"
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "card__usage-footer" }, [
                                _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "card__usage-footer-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberCommaSingleDecimal")(
                                            _vm.currentBillingPeriod
                                              .generationUsedByHome
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v("\n              used by your home")
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "card__usage-footer-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberCommaSingleDecimal")(
                                            _vm.currentBillingPeriod
                                              .generationSentToGrid
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v("\n              to the grid")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "arrow-container" }, [
                                _c("img", {
                                  staticClass:
                                    "arrow-image gds-align--block-center",
                                  attrs: {
                                    src:
                                      "/wp-content/themes/gmptwentynineteen/assets/images/left-arrow-in-circle.png",
                                    alt: "left arrow in circle"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.scrollPreviousCard(1)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass:
                                    "arrow-image gds-align--block-center",
                                  attrs: {
                                    src:
                                      "/wp-content/themes/gmptwentynineteen/assets/images/right-arrow-in-circle.png",
                                    alt: "right arrow in circle"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.scrollNextCard(1)
                                    }
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "scroll-dot-container" },
                                _vm._l(_vm.scrollDots, function(
                                  scrollDot,
                                  index
                                ) {
                                  return _c("div", [
                                    scrollDot === 0
                                      ? _c("span", {
                                          staticClass: "scroll-dot"
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scrollDot === 1
                                      ? _c("span", {
                                          staticClass: "scroll-dot-emphasize"
                                        })
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isNetMetered && _vm.netmeteringCardNumber === 3
                      ? _c(
                          "figure",
                          { staticClass: "gds-align--block-center" },
                          [
                            _c("div", { staticClass: "usage-card" }, [
                              _c("p", { staticClass: "gds-card__caption" }, [
                                _c(
                                  "span",
                                  { staticClass: "card__usage-title-text" },
                                  [_vm._v("Energy consumed")]
                                ),
                                _vm._v(
                                  " this billing period (" +
                                    _vm._s(_vm.daysLeft) +
                                    "\n            "
                                ),
                                _vm.daysLeft !== 1
                                  ? _c("span", [_vm._v("days")])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.daysLeft === 1
                                  ? _c("span", [_vm._v("day")])
                                  : _vm._e(),
                                _vm._v("\n            left).\n          ")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card__usage--kw-wrap gds-flex-container-column gds-clickable"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card__usage-container @click=navUsage()"
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: " card__usage--kw-number"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f(
                                                "numberCommaSingleDecimal"
                                              )(
                                                _vm.currentBillingPeriod
                                                  .totalConsumption
                                              )
                                            ) + " kWh"
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("img", {
                                staticClass:
                                  "gds-align--block-center plug-image",
                                attrs: {
                                  src:
                                    "/wp-content/themes/gmptwentynineteen/assets/images/plug.svg",
                                  alt: "electric plug"
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "card__usage-footer" }, [
                                _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "card__usage-footer-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberCommaSingleDecimal")(
                                            _vm.currentBillingPeriod
                                              .consumedFromGrid
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              from\n              the grid"
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _c(
                                    "span",
                                    { staticClass: "card__usage-footer-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("numberCommaSingleDecimal")(
                                            _vm.currentBillingPeriod
                                              .generationUsedByHome
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v("\n              from generation")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "arrow-container" }, [
                                _c("img", {
                                  staticClass:
                                    "arrow-image gds-align--block-center",
                                  attrs: {
                                    src:
                                      "/wp-content/themes/gmptwentynineteen/assets/images/left-arrow-in-circle.png",
                                    alt: "left arrow in circle"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.scrollPreviousCard(2)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm.isTOU
                                  ? _c("img", {
                                      staticClass:
                                        "arrow-image gds-align--block-center",
                                      attrs: {
                                        src:
                                          "/wp-content/themes/gmptwentynineteen/assets/images/right-arrow-in-circle.png",
                                        alt: "right arrow in circle"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.scrollNextCard(2)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "scroll-dot-container" },
                                _vm._l(_vm.scrollDots, function(
                                  scrollDot,
                                  index
                                ) {
                                  return _c("div", [
                                    scrollDot === 0
                                      ? _c("span", {
                                          staticClass: "scroll-dot"
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    scrollDot === 1
                                      ? _c("span", {
                                          staticClass: "scroll-dot-emphasize"
                                        })
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isNetMetered &&
                    _vm.isTOU &&
                    _vm.netmeteringCardNumber === 4
                      ? _c(
                          "figure",
                          { staticClass: "gds-align--block-center" },
                          [
                            _c("div", { staticClass: "usage-card" }, [
                              _vm.currentBillingPeriod.consumedFromGrid !==
                              undefined
                                ? _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "gds-card__caption" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "card__usage-title-text"
                                          },
                                          [_vm._v("Time of use")]
                                        ),
                                        _vm._v(
                                          " this billing period (" +
                                            _vm._s(_vm.daysLeft) +
                                            "\n            "
                                        ),
                                        _vm.daysLeft !== 1
                                          ? _c("span", [_vm._v("days")])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.daysLeft === 1
                                          ? _c("span", [_vm._v("day")])
                                          : _vm._e(),
                                        _vm._v(
                                          "\n            left).\n          "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.currentBillingPeriod.consumedFromGrid !==
                              undefined
                                ? _c(
                                    "figure",
                                    { staticClass: "gds-align--block-center" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card-usage-content" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card__usage--kw-wrap gds-flex-container gds-clickable"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card__usage-footer"
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "card__usage--kw-number"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Off Peak " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "numberCommaSingleDecimal"
                                                            )(
                                                              _vm
                                                                .currentBillingPeriod
                                                                .totalKwhOffPeak
                                                            )
                                                          ) +
                                                          " kWh"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "card__usage--kw-number"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "On Peak " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "numberCommaSingleDecimal"
                                                            )(
                                                              _vm
                                                                .currentBillingPeriod
                                                                .totalKwhOnPeak
                                                            )
                                                          ) +
                                                          "\n                  kWh"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("img", {
                                            staticClass:
                                              "gds-align--block-center clock-image",
                                            attrs: {
                                              src:
                                                "/wp-content/themes/gmptwentynineteen/assets/images/clock.png",
                                              alt: "clock"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "arrow-container" },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "arrow-image gds-align--block-center",
                                                attrs: {
                                                  src:
                                                    "/wp-content/themes/gmptwentynineteen/assets/images/left-arrow-in-circle.png",
                                                  alt: "left arrow in circle"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.scrollPreviousCard(
                                                      3
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "scroll-dot-container"
                                            },
                                            _vm._l(_vm.scrollDots, function(
                                              scrollDot,
                                              index
                                            ) {
                                              return _c("div", [
                                                scrollDot === 0
                                                  ? _c("span", {
                                                      staticClass: "scroll-dot"
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                scrollDot === 1
                                                  ? _c("span", {
                                                      staticClass:
                                                        "scroll-dot-emphasize"
                                                    })
                                                  : _vm._e()
                                              ])
                                            }),
                                            0
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.loadState !== "complete"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "top-banner-card gds-card gds-data-card top-banner-card--logged-in",
                    class: { "gds-loading": _vm.loadState === undefined }
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm.loadState === "error"
                      ? _c("div", { staticClass: "card-error" }, [
                          _vm._v("\n        Unable to load,\n        "),
                          _c("br"),
                          _vm._v("please try again later.\n      ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loadState === "error"
                      ? _c("div", { staticClass: "card-error-layout-blank" }, [
                          _vm._v(" ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loadState === "unavailable"
                      ? _c("div", { staticClass: "card-error" }, [
                          _vm._v(
                            "Usage information is not available for this\n        account.\n      "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loadState === "unavailable"
                      ? _c("div", { staticClass: "card-error-layout-blank" }, [
                          _vm._v(" ")
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usage-section" }, [
      _c("img", {
        staticClass: "gds-align--block-center house-with-solar-image",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/house-solar-panel.svg",
          alt: "house solar panel"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "gds-display-0 gds-space-stack-s" }, [
        _vm._v("Usage This Billing Period")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }