var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: { name: "Information for planned outages", state: "error" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c(
            "div",
            [
              _c("outage-breadcrumbs", { attrs: { planned: true } }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "planned-outage-list-wrapper" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm.userInfo
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "planned-outage-list__helper-left-margin gds-font-size-l gds-font-demi"
                            },
                            [_vm._v("Affecting Me")]
                          ),
                          _vm._v(" "),
                          !_vm.personalOutageData.length
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "planned-outages__message gds-space-stack-xl"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "planned-outages__message--inner-short"
                                    },
                                    [
                                      _vm._v(
                                        "There are no planned outages that will affect your\n            service."
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _c("vue-good-table", {
                                attrs: {
                                  columns: _vm.personalColumns,
                                  rows: _vm.personalOutageData,
                                  "sort-options": {
                                    enabled: true,
                                    initialSortBy: {
                                      field: "occursAt",
                                      type: "desc"
                                    }
                                  },
                                  styleClass:
                                    "vgt-table outage-table planned-outage-table gds-space-stack-xl"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "table-row",
                                      fn: function(props) {
                                        return [
                                          props.column.field == "occursAt"
                                            ? [
                                                props.row.status ===
                                                "IN_PROGRESS"
                                                  ? _c("div", {
                                                      staticClass:
                                                        "alert-icon active"
                                                    })
                                                  : _c("div", {
                                                      staticClass:
                                                        "alert-icon inactive"
                                                    }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "planned-outage-table__outage-date"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dateTimeFull")(
                                                          props.row.occursAt
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                props.row.status ===
                                                "IN_PROGRESS"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "planned-outage-list__status-button active"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Active\n              "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            : props.column.field ===
                                              "outageDuration"
                                            ? [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("durationMinutes")(
                                                      props.row.outageDuration
                                                    )
                                                  )
                                                )
                                              ]
                                            : props.column.field ===
                                              "affectedAccount"
                                            ? [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-flex-container gds-flex-container--left"
                                                  },
                                                  [
                                                    props.row.affectedAccount
                                                      .photoUrl
                                                      ? _c("div", {
                                                          staticClass:
                                                            "small-round-img",
                                                          style: {
                                                            backgroundImage:
                                                              "url(" +
                                                              _vm.GetResizedAccountPhoto(
                                                                props.row
                                                                  .affectedAccount
                                                                  .photoUrl
                                                              ) +
                                                              ")"
                                                          }
                                                        })
                                                      : _c("div", {
                                                          staticClass:
                                                            "small-round-img"
                                                        }),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-font-demi gds-space-stack-s"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "accountName"
                                                              )(
                                                                props.row
                                                                  .affectedAccount
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-linebreaks"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "addressFormat"
                                                              )(
                                                                props.row
                                                                  .affectedAccount
                                                                  .address,
                                                                {
                                                                  separator:
                                                                    "\n",
                                                                  skipFirst: true
                                                                }
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            : props.column.field === "link"
                                            ? [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "planned-outage-table__details-btn gds-button gds-compact gds-secondary",
                                                    attrs: {
                                                      to: props.row.incidentId
                                                        ? "/incident/" +
                                                          props.row.incidentId
                                                        : "/planned-outages/" +
                                                          props.row.id.toString(),
                                                      type: "button"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "View\n                Details"
                                                    )
                                                  ]
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3632455752
                                )
                              }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "planned-outage-list__helper-left-margin gds-font-size-l gds-font-demi"
                            },
                            [_vm._v("All Planned Outages")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.plannedOutageData.length
                    ? _c("div", [
                        _vm.userInfo
                          ? _c(
                              "div",
                              { staticClass: "planned-outages__message" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "planned-outages__message--inner-short"
                                  },
                                  [
                                    _vm._v(
                                      "There are no planned outages at this time."
                                    )
                                  ]
                                )
                              ]
                            )
                          : _c("div", [_vm._m(1)])
                      ])
                    : _c("vue-good-table", {
                        attrs: {
                          columns: _vm.plannedColumns,
                          rows: _vm.plannedOutageData,
                          "sort-options": {
                            enabled: true,
                            initialSortBy: { field: "occursAt", type: "desc" }
                          },
                          styleClass:
                            "vgt-table outage-table planned-outage-table"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "table-row",
                              fn: function(props) {
                                return [
                                  props.column.field == "occursAt"
                                    ? [
                                        props.row.status === "IN_PROGRESS"
                                          ? _c("div", {
                                              staticClass: "alert-icon active"
                                            })
                                          : _c("div", {
                                              staticClass: "alert-icon inactive"
                                            }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "planned-outage-table__outage-date"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("dateTimeFull")(
                                                  props.row.occursAt
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        props.row.status === "IN_PROGRESS"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "planned-outage-list__status-button active"
                                              },
                                              [_vm._v("Active\n            ")]
                                            )
                                          : _vm._e()
                                      ]
                                    : props.column.field === "outageDuration"
                                    ? [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("durationMinutes")(
                                              props.row.outageDuration
                                            )
                                          )
                                        )
                                      ]
                                    : props.column.field === "outageLocation"
                                    ? [
                                        props.row.outageLocation
                                          ? _c(
                                              "div",
                                              { staticClass: "gds-font-demi" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    props.row.outageLocation
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("outageLocationDetail")(
                                                props.row
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    : props.column.field === "link"
                                    ? [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "planned-outage-table__details-btn gds-button gds-compact gds-secondary",
                                            attrs: {
                                              to: props.row.incidentId
                                                ? "/incident/" +
                                                  props.row.incidentId
                                                : "/planned-outages/" +
                                                  props.row.id.toString(),
                                              type: "button"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "View\n              Details"
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3999273276
                        )
                      })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "planned-outage-list__headline-instructions gds-flex-container gds-flex-container--space-between"
      },
      [
        _c("h1", [_vm._v("Planned Outages")]),
        _vm._v(" "),
        _c("div", { staticClass: "planned-outages__notifications-link" }, [
          _c(
            "a",
            {
              staticClass: "gds-font-size-l gds-dark-arrow-link",
              attrs: { href: "/account/notifications" }
            },
            [_vm._v("My Notification Settings")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "planned-outages__message" }, [
      _c(
        "div",
        {
          staticClass: "planned-outages__message--inner-long gds-space-stack-m"
        },
        [
          _vm._v(
            "There are no planned outages at this\n              time. Log in and set up notifications so you’ll be informed when a planned outage might affect your\n              service."
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "gds-button gds-secondary gds-round",
          attrs: { href: "/account/notifications" }
        },
        [_vm._v("Set Up Notifications")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }