<template>
  <div class="powerwall-installer-widget">
    <type-ahead v-model="selectedTown" v-if="towns" :array="towns" type="town" @resetList="clearInstallerList" @showSelectValidItem="handleSelectValidTownMessage" placeholderText="Type your town name to get started..."/>
    <div class="gds-body-normal" v-if="displayNoInstallerMessage">If your town does not show any results, please check the spelling, try a neighboring town or call for more details.</div>
    <div v-for="installer, i in filteredInstallers" :key="i">
      <slat-powerwall-installer-vue :installer="installer" />
    </div>
  </div>
</template>

<script>
import GMPAPI from "../services/gmpapi";
import TypeAhead from "./TypeAhead.vue";
import SlatPowerwallInstallerVue from "./SlatPowerwallInstaller.vue";

export default {
  components: { TypeAhead, SlatPowerwallInstallerVue },
  name: "WidgetPowerwallInstallers",
  props: {
  },
  data() {
    return {
      towns: undefined,
      selectedTown: undefined,
      installers: undefined,
      filteredInstallers: undefined,
      displayNoInstallerMessage: undefined,
    };
  },
  watch: {
    selectedTown: {
      handler(newTown) {
        if (!newTown) {
          this.filteredInstallers = undefined;
        } else {
          this.filterInstallers(newTown);
        }
      }
    },
  },
  computed: {
  },
  async mounted() {
    this.setTownData();
    this.getPowerwallInstallers();
  },
  methods: {
    async getPowerwallInstallers() {
      const installers = await GMPAPI.GetPowerwallInstallers();
      this.installers = installers;
    },

    async setTownData() {
      this.towns = [];
      const towns = await GMPAPI.GetVermontTowns();
      for (const town of towns) {
        this.towns.push({
          value: town.townName
        });
      }
    },

    shuffleOrder(array) {
      const shuffledArray = array.slice(); 
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    },

    filterInstallers(town) {
      this.filteredInstallers = [];
      const filteredInstallers = this.installers.filter(installer => {
        if (installer.townsServiced.includes(town)) {
          this.displayNoInstallerMessage = false;
          return installer.townsServiced.includes(town);
        } else {
          this.filteredInstallers = [];
        }
      });
      this.filteredInstallers = this.shuffleOrder(filteredInstallers);
      if (!this.filteredInstallers.length && this.selectedTown !== "") {
        this.displayNoInstallerMessage = true;
      }
    },

    clearInstallerList() {
      this.displayNoInstallerMessage = false;
      this.filteredInstallers = [];
    },
    handleSelectValidTownMessage() {
      this.displayNoInstallerMessage = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>