<template>
  <div>
    <div v-if="routeloading || loadState === undefined">
      <div class="bill-pay__page-loader">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
      </div>
    </div>
    <flow-error v-if="loadState === 'error'" name="Past Due Quick Setup" state="error" phonetext="Please contact customer support for assistance at" closepath="/past-due-wizard" />
    <router-view v-if="loadState === 'complete'"></router-view>
    <div class="bill-pay__speedpay-footer-text">&copy;{{new Date().getFullYear()}} Green Mountain Power. Payments processed by Speedpay, an ACI Worldwide company.</div>
  </div>
</template>

<script>
import { EnvironmentConstants } from "../../environment";
import { ReturnToSender } from "../../utilities";
import { GetComponentStatus } from "../../services/statuspage";

export default {
  name: "PagePastDueWizard",
  components: {
  },
  data() {
    return {
      loadState: undefined,

      EnvironmentConstants,
    };
  },
  computed: {
    routeloading() {
      return this.$store.state.layout.routeloading;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async created() {
  },
  async mounted() {
    // Wire up the close button (outside of vue)
    const closer = document.querySelector("#flow-close");
    if (closer) {
      closer.addEventListener("click", e => {
        ReturnToSender('/past-due-wizard');
      });
    }
    this.loadState = undefined;
    const accountStatus = await GetComponentStatus("MyAccount");
    const paymentStatus = await GetComponentStatus("BillPay");
    const pastDueWizardStatus = await GetComponentStatus("PastDueWizard");
    if (accountStatus === "operational" && paymentStatus === "operational" && pastDueWizardStatus === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = "error"
    }
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>