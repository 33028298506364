<template>
  <div
    ref="mapdiv"
    class="outage-map"
    :class="{ 'homepage-map': kind === 'homepage', 'town-map': kind === 'town', 'incident-map': kind === 'incident', 'planned-map': kind === 'planned' }"
  />
</template>

<script>
import { EnvironmentConstants } from "../../environment";
import GMPAPI from "../../services/gmpapi";
import { DumpError, HyphenateTown } from "../../utilities";
let MapBoxGL = {}; // Later we'll replace this with the externally loaded mapbox if available

export default {
  name: "GmpMap",
  props: ["kind", "initialZoomPoint", "townVisibility", "townName", "incidentId", "plannedShape"],
  data() {
    return {
      map: undefined,

      refreshTimer: undefined,

      infoPopup: undefined,
      infoPopupCurrent: undefined,

      activeIncidents: undefined,
      incidentPoints: undefined,
      incidentShapes: undefined,
      townData: undefined,
      personalData: undefined,
      plannedOutages: undefined,

      masterTownList: undefined,

      fullyLoaded: false,
    };
  },
  computed: {
    userinfo() {
      return this.$store.state.user.userinfo;
    }
  },
  async mounted() {
    // Rather than import, we'll use the mapbox var set by the script link
    if (typeof mapboxgl !== "undefined") {
      MapBoxGL = mapboxgl;
    } else {
      console.log("Mapbox not loaded");
    }

    // Kick off data fetch
    this.$_fetchData();
    // Personal data can take a long time, so handle it separately
    this.$_fetchPersonal();

    MapBoxGL.accessToken = "pk.eyJ1IjoiY29ubmVjdGVkZ21wIiwiYSI6ImNqY2pjd3lzNjN5eTQyd3A1b2Z5cHo0cngifQ.glGIG6TP82bkPdPH2NH_XQ";
    await this.$nextTick();
    const bounds = [
      [-80, 40.291422], // Southwest coordinates  Hershey, PA 
      [-64, 46.427047] // Northeast coordinates northeast Maine 
    ];
    let zoom = 6.5;
    let center = [-72.679774, 43.861197];
    if (this.initialZoomPoint) {
      zoom = 13;
      center = this.initialZoomPoint;
    }
    this.map = new MapBoxGL.Map({
      container: this.$refs.mapdiv,
      style: "mapbox://styles/connectedgmp/cld36q9lj000101jzui1rzxgm",
      zoom,
      center,
      attributionControl: false,
      maxBounds: (this.kind === "incident" || this.kind === "planned") ? null : bounds, // Sets bounds as max,
    });

    //appears not to work on mobile
    this.map.style.touchAction = "false";
    // disable map rotation using right click + drag
    this.map.dragRotate.disable();

    // If this is a touch device, disable drag panning
    const isTouch = matchMedia("(hover: none)").matches;
    if (isTouch) this.map.dragPan.disable();

    // disable map rotation using touch rotation gesture
    this.map.touchZoomRotate.disableRotation();

    this.map.on("load", async () => {
      this.$_addControls();
      await this.$_loadAllIcons();
      this.$_createSources();
      switch (this.kind) {
        case "homepage":
          this.$_createTownFillLayer();
          this.$_createIncidentShapeLayer();
          this.$_createTruckLayer();
          this.$_createIncidentPointLayer();
          this.$_createHouseLayer();
          this.$_addZoomHandler();
          this.$_watchMapHovers();
          this.$_watchMapClicks();
          this.FitVermont();
          break;
        case "town":
          this.$_createTownFillLayer();
          this.$_createIncidentShapeLayer();
          this.$_createTruckLayer();
          this.$_createIncidentPointLayer();
          this.SetTruckVisibility(true);
          this.$_createHouseLayer();
          this.$_addZoomHandler();
          this.$_setTownVisibility(false);
          this.$_watchMapHovers();
          this.$_watchMapClicks();
          break;
        case "incident":
          this.$_createIncidentShapeLayer();
          this.$_createTruckLayer();
          this.$_createIncidentPointLayer();
          this.SetTruckVisibility(true);
          this.$_createHouseLayer();
          this.$_setIncidentZoomRange(false);
          this.map.setMinZoom(9);
          if (window.matchMedia("(max-width: 768px)").matches) {
            this.DisableAllInteraction();
          }
          break;
        case "planned":
          this.$_createPlannedOutageLayer();
          this.$_createHouseLayer();
          if (window.matchMedia("(max-width: 768px)").matches) {
            this.DisableAllInteraction();
          }
          break;
        default:
          throw new Error("Map kind must be homepage, town, incident, planned")
      }

      this.masterTownList = this.map.querySourceFeatures("composite", {
        sourceLayer: "vermont_towns_with_gmp_customers",
      });
      this.fullyLoaded = true;
      this.$_finalizeMap();
      this.$_finalizePersonal();
    });
  },
  // If this component is destroyed, stop the refresh timer
  destroyed() {
    clearTimeout(this.refreshTimer);
  },
  methods: {
    Resize() {
      this.map.resize();
    },
    DisableAllInteraction() {
      this.map.boxZoom.disable();
      this.map.dragPan.disable();
      this.map.dragRotate.disable();
      this.map.keyboard.disable();
      this.map.doubleClickZoom.disable();
      // this.map.touchZoomRotate.disable();
      this.map.scrollZoom.disable();
    },
    FitVermont() {
      const vermontBounds = new MapBoxGL.LngLatBounds([-73.343443, 42.726995], [-71.465131, 45.15]);
      this.map.fitBounds(vermontBounds, {
        padding: 40
      });

    },
    ZoomToTown(townName) {
      const townBounds = this.$_getTownBounds(HyphenateTown(townName));
      if (townBounds) {
        this.map.setFilter("town-boundary-highlight", ["==", "TOWNNAMEMC", townName ? townName : ""]);
        this.$_zoomToShape(townBounds);
      }
    },
    ZoomToIncident(incidentId) {
      const shape = this.incidentShapes.features.find(item => item.properties.incidentId === incidentId.toString());
      if (shape) {
        this.$_zoomToShape(shape.geometry.coordinates[0]);
      } else {
        // Truly maddening - points vs shapes come back with differently named and typed id fields
        const point = this.incidentPoints.features.find(item => item.properties.incident_id === incidentId);
        if (point) {
          this.$_zoomToPoint(point.geometry.coordinates);
        } else {
          console.log("Unable to find incident to zoom to");
        }
      }
    },
    ZoomToPlanned() {
      if (this.plannedShape) {
        this.$_zoomToShape(this.plannedShape.geometry.coordinates);
      }
    },
    SetTruckVisibility(visible) {
      this.map.setLayoutProperty("truck-point", "visibility", visible ? "visible" : "none");
    },
    SetTownHover(townName) {
      if (this.fullyLoaded) {
        this.map.setFilter("town-boundary-hover", ["==", "TOWNNAMEMC", townName ? townName : ""]);
      }
    },
    SetIncidentHover(incidentId) {
      if (this.fullyLoaded) {
        this.map.setFilter("incident-point-hover", ["==", "incident_id", incidentId ? incidentId : ""]);
        this.map.setFilter("incident-boundary-hover-border", ["==", "incidentId", incidentId ? incidentId.toString() : ""]);
        this.map.setFilter("incident-boundary-hover-fill", ["==", "incidentId", incidentId ? incidentId.toString() : ""]);
      }
    },

    // Private methods. Too damn many so prefixed with $_ per vue style guide
    async $_fetchData() {
      try {
        const [activeIncidents, incidentPoints, incidentShapes, townData, plannedOutages] = await Promise.all([
          GMPAPI.GetAllIncidents(),
          GMPAPI.GetActiveIncidentPoints(),
          GMPAPI.GetActiveIncidentShapes(),
          GMPAPI.GetTownData(),
          GMPAPI.GetPlannedOutages(),
        ]);
        this.activeIncidents = activeIncidents;
        this.incidentPoints = incidentPoints;
        this.incidentShapes = incidentShapes;
        this.townData = townData;
        this.plannedOutages = plannedOutages;
        // Pass the API data upstream in case anyone else wants it
        this.$emit("update", { activeIncidents, incidentPoints, incidentShapes, townData, plannedOutages });
        // Now load the data in if the map is loaded
        this.$_finalizeMap();
      } catch (err) {
        DumpError("Unable to update outage data", err);
      } finally {
        // Let's do this again periodically
        this.refreshTimer = setTimeout(() => { this.$_fetchData() }, EnvironmentConstants.OutageRefreshIntervalMS);
      }
    },
    async $_fetchPersonal() {
      // Emit null to indicate we're loading
      this.$emit("update", { personalData: null });
      const personalData = await GMPAPI.GetPersonalOutageData();
      this.personalData = personalData;
      this.$emit("update", { personalData });
      this.$_finalizePersonal();
    },
    $_finalizeMap() {
      if (!this.fullyLoaded || !this.townData) return;
      // Assuming we have data, populate
      this.$_updateTownFillLayer();
      this.$_updateIncidentShapeLayer();
      this.$_updateIncidentPointLayer();
      this.$_updatePlannedOutageLayer();
      switch (this.kind) {
        case "homepage":
          this.$_setTownVisibility(this.townVisibility);
          break;
        case "town":
          this.ZoomToTown(this.townName);
          this.$_subscribeToTownCentered(this.townName);
          break;
        case "incident":
          this.ZoomToIncident(this.incidentId);
          this.$_subscribeToIncidentCentered(this.incidentId);
          break;
        case "planned":
          this.ZoomToPlanned();
          break;
      }
    },
    $_finalizePersonal() {
      if (!this.fullyLoaded || !this.personalData) return;
      // Assuming we have data, populate
      this.$_updateHousePointLayer();
    },
    async $_loadAllIcons() {
      await Promise.all([
        // this.$_loadIcon("/static/img/bucket-truck.png", "truck"),
        this.$_loadIcon("/wp-content/themes/gmptwentynineteen/assets/images/icon-map-point-selected-on@3x.png", "house-good"),
        this.$_loadIcon("/wp-content/themes/gmptwentynineteen/assets/images/icon-map-point-selected-out@3x.png", "house-bad"),
        this.$_loadIcon("/wp-content/themes/gmptwentynineteen/assets/images/icon-map-point-planned@3x.png", "house-planned"),
      ]);
    },
    async $_loadIcon(path, name) {
      return new Promise((resolve, reject) => {
        this.map.loadImage(path, (err, image) => {
          if (!err) {
            this.map.addImage(name, image);
            resolve();
          } else {
            reject(err);
          }
        });
      });
    },
    $_addControls() {
      this.map.addControl(new MapBoxGL.NavigationControl(), 'bottom-right');
      // this.map.addControl(new MapBoxGL.NavigationControl(), 'top-right');
      this.map.addControl(new MapBoxGL.AttributionControl({
        compact: true
      }));
    },
    /** Initialize all required map sources with empty geoJSON data */
    $_createSources() {
      this.$_addEmptyMapSource("incident-points");
      this.$_addEmptyMapSource("incident-points-resolved");
      this.$_addEmptyMapSource("incident-shapes");
      this.$_addEmptyMapSource("planned-outage-shapes");
      this.$_addEmptyMapSource("truck-points");
      this.$_addEmptyMapSource("house-points");
      // Copy just the towns layer for hovering speed
      this.map.addSource("town-hover", {
        type: "vector",
        url: "mapbox://connectedgmp.cjdev3e2q008o31nqip4qgony-2gw9y"
      });
    },
    $_addEmptyMapSource(sourceName) {
      this.map.addSource(sourceName, {
        type: "geojson",
        data: {
          "type": "FeatureCollection",
          "features": [],
        }
      });
    },
    $_createTownFillLayer() {
      this.map.addLayer(require("./maplayers/town-boundary-border.json"), "poi-scalerank3");
      this.map.addLayer(require("./maplayers/town-boundary-fill.json"), "poi-scalerank3");
      // Shapes for hovered town outlines
      this.map.addLayer(require("./maplayers/town-boundary-hover.json"));
      // Highlight for current town
      this.map.addLayer(require("./maplayers/town-boundary-highlight.json"));
      // Shapes for clicked town outlines
      this.map.addLayer(require("./maplayers/town-boundary-clicked.json"));
      // this.map.addLayer(require("./maplayers/town-active-label.json"), "marine-label-sm-ln");
    },
    $_createIncidentShapeLayer() {
      this.map.addLayer(require("./maplayers/incident-boundary-fill.json"), "state-highway-icons");
      this.map.addLayer(require("./maplayers/incident-boundary-transparent.json"), "state-highway-icons");
      // this.map.addLayer(require("./maplayers/incident-boundary-border.json"), "state-highway-icons");
      // Shapes for hovered town outlines
      this.map.addLayer(require("./maplayers/incident-boundary-hover-border.json"), "state-highway-icons");
      this.map.addLayer(require("./maplayers/incident-boundary-hover-fill.json"), "state-highway-icons");
      this.map.addLayer(require("./maplayers/incident-boundary-clicked-border.json"), "state-highway-icons");
      this.map.addLayer(require("./maplayers/incident-boundary-clicked-fill.json"), "state-highway-icons");
    },
    $_createIncidentPointLayer() {
      this.map.addLayer(require("./maplayers/incident-point.json"), "state-highway-icons");
      // Points for hovered incident circles
      this.map.addLayer(require("./maplayers/incident-point-hover.json"), "state-highway-icons");
      this.map.addLayer(require("./maplayers/incident-point-clicked.json"), "state-highway-icons");
      // Points for resolved incidents (usually empty)
      this.map.addLayer(require("./maplayers/incident-point-resolved.json"), "state-highway-icons");
    },
    $_createTruckLayer() {
      this.map.addLayer(require("./maplayers/truck-point.json"), "state-highway-icons");
    },
    $_createHouseLayer() {
      this.map.addLayer(require("./maplayers/house-point.json"));
    },
    $_createPlannedOutageLayer() {
      this.map.addLayer(require("./maplayers/planned-outage-fill.json"), "state-highway-icons");
      this.map.addLayer(require("./maplayers/planned-outage-border.json"), "state-highway-icons");
    },
    // Replace any existing popup with a new one
    $setInfoPopup(content, coords, closeable, bumpup = 0) {
      if (this.infoPopup) this.infoPopup.remove();
      this.infoPopup = undefined;
      this.infoPopup = new MapBoxGL.Popup({
        closeButton: closeable,
        closeOnClick: closeable,
        anchor: "bottom",
        offset: [0, -bumpup],
      });
      this.infoPopup.setHTML("<div id='infoPopup'>" + content + "</div>");
      this.infoPopup.setLngLat(coords);
      this.infoPopup.addTo(this.map);
      // Intercept page pinch zoom over popup
      this.infoPopup._content.addEventListener("gesturestart", e => {
        e.preventDefault();
      });
    },
    $showHousePopup(accountNumber, clicked) {
      // Don't bother if hovering and already done
      if (!clicked && this.infoPopupCurrent === "house" + accountNumber) return;
      this.infoPopupCurrent = "house" + accountNumber;
      const matchedHouse = this.personalData.find(item => Number(item.accountNumber) === accountNumber);
      if (matchedHouse) {
        let body = "";
        if (matchedHouse.nickname) {
          body += "<div class='map-hover-street gds-text--capitalize'>" + matchedHouse.nickname + "</div>"
        }
        body += "<div class='map-hover-text gds-text--capitalize'>" + matchedHouse.address.street1.toLowerCase() + "</div>"
          + "<div class='map-hover-text gds-text--capitalize'>" + matchedHouse.address.city.toLowerCase() + ", " + matchedHouse.address.state + "</div>"
        // outageReported indicates that an outage has been reported but hasn't made it to the incidents yet
        if (matchedHouse.outageReported || (matchedHouse.incidents && matchedHouse.incidents.length > 0)) {
          // If we have incidents, say so
          body += "<div class='map-hover-alert-text gds-flex-container gds-flex-container--left gds-flex-container--top'>" + "<span class='map-hover__outage-icon'></span> Outage Reported</div>";
          if (clicked && matchedHouse.incidents && matchedHouse.incidents.length > 0) {
            // Since this is the click popup (and there is a fully defined incident),
            // we add a link to the incident that will be picked up by the link interceptor
            body += "<div class='map-hover-link'><a data-popup-routed='/incident/" + matchedHouse.incidents[0].id + "' href='/outages/incident/" + matchedHouse.incidents[0].id + "'>Incident Details</a></div>";
          }
        } else {
          // No outages reported
          body += "<div class='map-hover-alert-text gds-flex-container gds-flex-container--left'><div class='outage-header__alert-icon inactive'><img src='/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-with-shadow.svg' /></div>No Outage Reported</div>";
        }
        if (matchedHouse.plannedOutages && matchedHouse.plannedOutages.length > 0) {
          const urlBase = `${window.location.origin}/outages/planned-outages`;
          const urlPath  = matchedHouse.plannedOutages.length ===  1 ? `/${matchedHouse.plannedOutages[0].id}` : '';
          const url = `${urlBase}${urlPath}`;

          body += `<a href="${url}">`;
          body += "<div class='map-hover-alert-text gds-flex-container gds-flex-container--left'><div class='outage-header__alert-icon planned'><img src='/wp-content/themes/gmptwentynineteen/assets/images/planned-outage-info-small.svg' /></div>" + matchedHouse.plannedOutages.length + " Outage" + (matchedHouse.plannedOutages.length === 1 ? "" : "s") + " Planned</div>";
          body += '</a>'
       }
        this.$setInfoPopup(body, [matchedHouse.serviceLocation.lon, matchedHouse.serviceLocation.lat], clicked, 55);
      }
    },
    $showIncidentPopup(incident_id, clicked) {
      // Don't bother if hovering and already done
      if (!clicked && this.infoPopupCurrent === "incident" + incident_id) return;
      this.infoPopupCurrent = "incident" + incident_id;

      const matchedIncident = this.activeIncidents.find(item => item.id === Number(incident_id));
      if (matchedIncident) {
        this.SetIncidentHover(matchedIncident.id);
        let body = "<div class='map-hover-street'><span class='gds-text--capitalize'>" + (matchedIncident.street ? matchedIncident.street.toLowerCase() : "") + "</span>" + (matchedIncident.counter ? " " + matchedIncident.counter : "") + "</div>"
          + "<div class='map-hover-text'>" + matchedIncident.town + "</div>"
          + "<div class='map-hover-alert-text gds-flex-container gds-flex-container--left'>" + "<span class='map-hover__outage-icon'></span>" + matchedIncident.customerCount + " Customer" + (matchedIncident.customerCount === 1 ? "" : "s") + " Out</div>";
        if (clicked) {
          // Since this is the click popup, we add a link to the incident that will be picked up by the link interceptor
          body += "<div class='map-hover-link'><a data-popup-routed='/incident/" + matchedIncident.id + "' href='/outages/incident/" + matchedIncident.id + "'>Incident Details</a></div>";
        }
        this.$setInfoPopup(body, [matchedIncident.location.lon, matchedIncident.location.lat], clicked, 20);
        if (this.incidentHoverCallback) this.incidentHoverCallback(incident_id);
      }
    },
    $showTownPopup(townname, clicked) {
      // Don't bother if hovering and already done
      if (!clicked && this.infoPopupCurrent === "town" + townname) return;
      this.infoPopupCurrent = "town" + townname;

      let custAffected = 0;
      if (this.townData) {
        const matchedTown = this.townData.find(item => item.town_name === townname);
        if (matchedTown) custAffected = matchedTown.customers_affected;
      }
      if (this.activeIncidents) {
        // If we have a copy of ActiveIncidents, look through it for incidents that match this town
        const myIncidents = [];
        for (const incident of this.activeIncidents) {
          if (!incident.town) { continue; }
          // Check if the incident overlaps this town
          let subTown = undefined;
          if (incident.customersByTown) {
            subTown = Object.keys(incident.customersByTown).find(
              item =>
                item.toLowerCase() ===
                townname.toLowerCase()
            );
          }
          // If either overlap matches or incident matches, count this incident
          if (subTown || (incident.town.toLowerCase() === townname.toLowerCase())) {
            myIncidents.push(incident);
          }
        }

        this.SetTownHover(townname);
        let body = "<div class='map-hover-town'>" + townname + "</div>";
        if (myIncidents.length === 0 && custAffected === 0) {
          body += "<div class='map-hover-alert-text gds-flex-container gds-flex-container--left'><div class='outage-header__alert-icon inactive'><img src='/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-with-shadow.svg' /></div>No Outages Reported</div>";
        } else {
          body += "<div class='map-hover-alert-text gds-flex-container gds-flex-container--left gds-flex-container--top'>" + "<span class='map-hover__outage-icon'></span>" + myIncidents.length + " Active Incident" + (myIncidents.length === 1 ? "" : "s") + "<br >" + custAffected + " Customer" + (custAffected === 1 ? "" : "s") + " Out</div>";
        }
        const townPlanned = this.plannedOutages.filter(item => item.customersByTown && Object.keys(item.customersByTown).includes(townname.toUpperCase()));
        if (townPlanned.length > 0) {
          body += "<div class='map-hover-alert-text gds-flex-container gds-flex-container--left'><div class='outage-header__alert-icon planned'><img src='/wp-content/themes/gmptwentynineteen/assets/images/planned-outage-info-small.svg' /></div>" + townPlanned.length + " Outage" + (townPlanned.length === 1 ? "" : "s") + " Planned</div>";
        }
        if (clicked) {
          // Since this is the click popup, we add a link to the town that will be picked up by the link interceptor
          const townSlug = HyphenateTown(townname);
          body += "<div class='map-hover-link'><a data-popup-routed='/town/" + townSlug + "' href='/outages/town/" + townSlug + "'>Town Details</a></div>";
        }
        const townBounds = this.$_getTownBounds(HyphenateTown(townname));
        const townCenter = this.$_calculateCenter(townBounds, true);
        this.$setInfoPopup(body, townCenter, clicked, 0);
        if (this.townHoverCallback) this.townHoverCallback(townname);
      }
    },
    $_watchMapHovers() {
      // Only register hover handlers if we are not on a touch device
      const isTouch = matchMedia("(hover: none)").matches;
      if (isTouch) return;

      // Watch for all mouse movements on the map
      this.map.on("mousemove", e => {
        // If we have a clicked popup open, ignore hovers
        if (this.infoPopup && this.infoPopup.isOpen() && this.infoPopup.options && this.infoPopup.options.closeButton) return;
        // We are going to check through all features under the cursor, and look for one that needs a popup
        const features = this.map.queryRenderedFeatures(e.point);

        // First, try for a home account
        const house = features.find(feat => feat.source === "house-points");
        if (house) {
          this.$showHousePopup(house.id);
          return;
        }

        // Next, try for incident points
        const incidentpoint = features.find(feat => feat.source === "incident-points");
        if (incidentpoint) {
          this.$showIncidentPopup(incidentpoint.properties.incident_id);
          return;
        }

        // If not, try for incident shapes
        const incidentshape = features.find(feat => feat.layer && feat.layer.id === "incident-boundary-transparent");
        if (incidentshape) {
          this.$showIncidentPopup(incidentshape.properties.incidentId);
          return;
        }

        // Finally, try for a town outline
        const town = features.find(feat => feat.layer && feat.layer.id === "town-boundary-fill");
        if (town) {
          this.$showTownPopup(town.properties.TOWNNAMEMC);
          return;
        }

        // Not hovering over any of these, hide popup
        if (this.infoPopup) {
          this.infoPopup.remove();
          this.infoPopup = undefined;
          this.infoPopupCurrent = undefined;
          this.SetTownHover("");
          this.SetIncidentHover("");
          if (this.townHoverCallback) this.townHoverCallback("");
          if (this.incidentHoverCallback) this.incidentHoverCallback("");
        }
      });
    },
    $_watchMapClicks() {
      // Link interceptor:
      // We are going to intercept any clicks, so that if they are on a popup's button we can use the router instead of refresh the page
      // We can tag these links with data-popup-routed="routetofollow" to make this pick them up
      window.addEventListener("click", e => {
        if (e.target && e.target.tagName === "A" && e.target.attributes["data-popup-routed"]) {
          e.preventDefault();
          this.$router.push(e.target.attributes["data-popup-routed"].value);
        }
      })

      // Watch for anything clicked on the map
      this.map.on("click", e => {
        // We are going to check through all features under the cursor, and look for one that needs a popup
        const features = this.map.queryRenderedFeatures(e.point);

        // First, try for a home account
        const house = features.find(feat => feat.source === "house-points");
        if (house) {
          if (e.lngLat) this.map.easeTo({ center: e.lngLat, speed: 0.15, duration: 700 });
          this.$showHousePopup(house.id, true);
          return;
        }

        // Next, try for incident points
        const incidentpoint = features.find(feat => feat.source === "incident-points");
        if (incidentpoint) {
          if (e.lngLat) this.map.easeTo({ center: e.lngLat, speed: 0.15, duration: 700 });
          this.$showIncidentPopup(incidentpoint.properties.incident_id, true);
          return;
        }

        // If not, try for incident shapes
        const incidentshape = features.find(feat => feat.layer && feat.layer.id === "incident-boundary-transparent");
        if (incidentshape) {
          if (e.lngLat) this.map.easeTo({ center: e.lngLat, speed: 0.15, duration: 700 });
          this.$showIncidentPopup(incidentshape.properties.incidentId, true);
          return;
        }

        // Finally, try for a town outline
        const town = features.find(feat => feat.layer && feat.layer.id === "town-boundary-fill");
        // Town fills are hidden at zoom 10 and up
        if (town && e.target.getZoom() < 10) {
          if (e.lngLat) this.map.easeTo({ center: e.lngLat, speed: 0.15, duration: 700 });
          this.$showTownPopup(town.properties.TOWNNAMEMC, true);
          return;
        }

        // Not clicking on any of these, do nothing
      });

    },
    $_calculateCenter(boundary, topEdge = false) {
      if (!boundary) return;
      // Sometimes boundary comes in wrapped in an array...?
      if (Array.isArray(boundary[0]) && Array.isArray(boundary[0][0])) {
        boundary = [].concat(...boundary);
      }
      var bounds = boundary.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new MapBoxGL.LngLatBounds(boundary[0], boundary[0]));
      const center = bounds.getCenter();
      if (topEdge) {
        const top = bounds._ne.lat;
        return [center.lng, top];
      } else {
        return [center.lng, center.lat];
      }
    },
    $_updateTownFillLayer() {
      if (!this.townData || this.kind === "incident" || this.kind === "planned") return;
      const customerLookup = ["match", ["get", "TOWNNAMEMC"]];
      for (const town of this.townData) {
        customerLookup.push(town.town_name, town.customers_affected);
      }
      customerLookup.push(0);
      const filterExpression = [">", customerLookup, 0];
      // // Set town fill opacity to scale with affected customers
      // const opacityExpression = ["interpolate", ["exponential", 1], customerLookup, 0, 0, 1, 0.1, 1002, 0.7];
      // this.map.setPaintProperty("town-boundary-fill", "fill-opacity", opacityExpression);

      // Set town colors to scale with affected customers
      const townColorExpression = [
        "step",
        customerLookup,
        "hsla(0, 0%, 100%, 0)",
        1,
        "hsla(46, 100%, 53%, 0.45)",
        250,
        "hsla(27, 100%, 51%, 0.8)",
        500,
        "hsla(0, 100%, 50%, 0.85)"
      ];
      this.map.setPaintProperty("town-boundary-fill", "fill-color", townColorExpression);
      // Only show town borders where there are affected customers
      this.map.setFilter("town-boundary-border", filterExpression);
      // // Only show town labels where there are affected customers
      // this.map.setFilter("town-active-label", filterExpression);
      // // Set the town label text
      // this.map.setLayoutProperty("town-active-label", "text-field", ["concat", ["get", "TOWNNAMEMC"], " (", ["to-string", customerLookup], ")"])
    },
    $_updateIncidentShapeLayer() {
      if (!this.incidentShapes) return;
      this.map.getSource("incident-shapes").setData(this.incidentShapes);
    },
    $_updateIncidentPointLayer() {
      if (!this.incidentPoints) return;
      this.map.getSource("incident-points").setData(this.incidentPoints);
    },
    $_updateHousePointLayer() {
      if (!this.personalData) return;
      const housePoints = {
        type: "FeatureCollection",
        features: [],
      };
      for (const account of this.personalData) {
        if (account.serviceLocation && account.serviceLocation.lat && account.serviceLocation.lon) {
          housePoints.features.push({
            id: account.accountNumber,
            type: "Feature",
            geometry: {
              coordinates: [account.serviceLocation.lon, account.serviceLocation.lat],
              type: "Point"
            },
            properties: {
              primary: account.primary,
              address: account.address,
              nickname: account.nickname,
              hasIncidents: account.outageReported || account.incidents.length > 0,
              hasPlanned: account.plannedOutages && account.plannedOutages.length > 0,
            },
          });
        }
      }
      this.map.getSource("house-points").setData(housePoints);
    },
    $_updatePlannedOutageLayer() {
      if (!this.plannedShape) return;
      this.map.getSource("planned-outage-shapes").setData({
        "type": "FeatureCollection",
        "features": [this.plannedShape],
      });
    },
    /** Register handler to watch zoom level and hide popups */
    $_addZoomHandler() {
      let zoomingIn = true;
      this.map.on("zoom", e => {
        const zoom = e.target.getZoom();
        // Use zoomingIn to ensure we only fire when we first cross the zoom boundary in either direction
        if (zoomingIn && zoom > 10.0) {
          zoomingIn = false;

          if (this.infoPopup) {
            this.infoPopup.remove();
            this.infoPopup = undefined;
          }
          this.SetTownHover("");
          if (this.townHoverCallback) this.townHoverCallback("");
        } else if (!zoomingIn && zoom < 10.0) {
          zoomingIn = true;

          if (this.infoPopup) {
            this.infoPopup.remove();
            this.infoPopup = undefined;
          }
          this.SetIncidentHover("");
          if (this.incidentHoverCallback) this.incidentHoverCallback("");
        }
      });
    },
    $_getTownBounds(townSlug) {
      if (townSlug) {
        const town = this.masterTownList.find(item => HyphenateTown(item.properties.TOWNNAMEMC) === townSlug);
        if (town) {
          return town.geometry.coordinates[0];
        }
      }
      return undefined;
    },
    $_subscribeToTownCentered(townName) {
      const townBounds = this.$_getTownBounds(HyphenateTown(townName));
      const townCenter = this.$_calculateCenter(townBounds, false);
      this.map.on("moveend", e => {
        const currentCenter = e.target.getCenter();
        const delta = [currentCenter.lng - townCenter[0], currentCenter.lat - townCenter[1]];
        const distance = Math.sqrt(delta[0] * delta[0] + delta[1] * delta[1]);
        this.$emit("centered", distance < 0.05);
      });
    },
    $_subscribeToIncidentCentered(incidentId) {
      let incidentCenter;
      const shape = this.incidentShapes.features.find(item => item.properties.incidentId === incidentId.toString());
      if (shape) {
        incidentCenter = this.$_calculateCenter(shape.geometry.coordinates, false);
      } else {
        const point = this.incidentPoints.features.find(item => item.properties.incident_id === incidentId);
        if (point) {
          incidentCenter = point.geometry.coordinates;
        }
      }
      if (incidentCenter) {
        this.map.on("moveend", e => {
          const currentCenter = e.target.getCenter();
          const delta = [currentCenter.lng - incidentCenter[0], currentCenter.lat - incidentCenter[1]];
          const distance = Math.sqrt(delta[0] * delta[0] + delta[1] * delta[1]);
          this.$emit("centered", distance < 0.02);
        });
      } else {
        console.log("No incident center found");
      }
    },
    $_zoomToShape(boundary) {
      if (!boundary) return;
      // Sometimes boundary comes in wrapped in an array...?
      if (Array.isArray(boundary[0]) && Array.isArray(boundary[0][0])) {
        boundary = [].concat(...boundary);
      }
      var bounds = boundary.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new MapBoxGL.LngLatBounds(boundary[0], boundary[0]));
      this.map.fitBounds(bounds, {
        padding: 80
      });
    },
    $_zoomToPoint(point) {
      if (!point) return;
      this.map.flyTo({
        center: point,
        zoom: 13
      });
    },
    $_setTownVisibility(visible) {
      this.map.setLayoutProperty("town-boundary-fill", "visibility", visible ? "visible" : "none");
      this.map.setLayoutProperty("town-boundary-border", "visibility", visible ? "visible" : "none");
      // this.map.setLayoutProperty("town-active-label", "visibility", visible ? "visible" : "none");
      this.$_setIncidentZoomRange(visible);
    },
    $_setIncidentZoomRange(limited) {
      this.map.setLayerZoomRange("incident-point", limited ? 10 : 0, 24);
      this.map.setLayerZoomRange("incident-boundary-fill", limited ? 10 : 0, 24);
      this.map.setLayerZoomRange("incident-boundary-transparent", limited ? 10 : 0, 24);
      // this.map.setLayerZoomRange("incident-boundary-border", limited ? 10 : 0, 24);
      this.map.setLayerZoomRange("incident-boundary-hover-border", limited ? 10 : 0, 24);
      this.map.setLayerZoomRange("incident-boundary-hover-fill", limited ? 10 : 0, 24);
    },
  },
  watch: {
    async townVisibility() {
      if (!this.fullyLoaded) return;
      this.$_setTownVisibility(this.townVisibility);
    },
    plannedShape() {
      if (!this.fullyLoaded) return;
      this.$_updatePlannedOutageLayer();
    },
    async userinfo() {
      this.$_fetchPersonal();
    },
  }
};

</script>

<style scoped>
</style>