var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "past-due-wizard-container" },
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "bill-pay__page-loader" }, [
            _c("img", {
              staticClass: "bill-pay__page-loader--svg",
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Past Due Quick Setup",
              state: "error",
              phonetext: "To set up a payment plan by phone call us at",
              closepath: "/past-due-wizard"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && !_vm.isPaymentPlanEligible
        ? _c(
            "div",
            [
              _c("past-due-not-eligible", {
                attrs: {
                  arrangementStatus: _vm.paymentArrangementStatus,
                  currentAccount: _vm.currentAccount
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && _vm.isPaymentPlanEligible
        ? _c(
            "div",
            [
              _c("past-due-wizard-logged-in", {
                attrs: {
                  paymentArrangementStatus: _vm.paymentArrangementStatus,
                  budgetBillingStatus: _vm.budgetBillingStatus,
                  isAlreadyRecurringPaymentEnrolled:
                    _vm.isAlreadyRecurringPaymentEnrolled
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }