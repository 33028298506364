<template>
  <div v-if="visible" class="gmp-modal">
    <div class="export-data__modal-box">
      <FocusLock>
        <header class="delete-user__modal-box--header">
          <button @click="Close()" class="close gds-button gds-button-circle gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
          </button>
          <div v-if="!completedRemove" class="export-data__modal-box--headline">Are you sure you want to cancel this payment?</div>
          <div v-else class="export-data__modal-box--headline">Payment cancelled successfully</div>
        </header>
        <div class="delete-user__modal-box--body">
          <div class="export-data__modal-box--message gds-space-stack-l">
            <span>Your payment of {{payment.totalPaymentAmount | currency}} scheduled for {{payment.scheduledPaymentDate | dateFull}} {{payment.methodSlug}}</span>
            <span v-if="!completedRemove">will be cancelled.</span>
            <span v-else>was cancelled.</span>
          </div>
          <div v-if="!completedRemove">
            <div v-if="errorRemove" class="form-message-box gds-space-stack-m">{{errorRemove}}</div>
            <button @click="Close()" type="button" class="gds-button gds-secondary gds-compact gds-space-stack-m">
              <span class="gds-button__text">Cancel</span>
            </button>
            <button
              @click="Save()"
              :disabled="pendingRemove"
              :class="{ 'gds-loading': pendingRemove }"
              type="button"
              class="gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m"
            >
              <span class="gds-button__text">Yes, Cancel Payment</span>
            </button>
          </div>
          <div v-else>
            <button @click="Close()" type="button" class="gds-button gds-primary gds-compact gds-space-stack-m">
              <span class="gds-button__text">Close</span>
            </button>
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../../services/gmpapi';
import { DumpError } from '../../../utilities';
import { GMPPhoneNumber } from '../../../environment';

export default {
  name: "ModalCancelScheduled",
  props: ["visible", "payment"],
  components: {
  },
  data() {
    return {
      pendingRemove: false,
      errorRemove: undefined,
      completedRemove: false,
      GMPPhoneNumber,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
    async Save() {
      this.pendingRemove = true;
      try {
        // Remove it
        await GMPAPI.DeleteScheduledPayment(this.currentAccount.accountNumber, this.payment.confirmationNumber);
        this.completedRemove = true;
      } catch (err) {
        DumpError("Cancel payment error", err);
        this.errorRemove = "There was an unexpected error cancelling your payment.";
      } finally {
        this.pendingRemove = false;
      }
    },
    Close() {
      // If a deletion happened the parent will need to refresh to get the new list
      this.$emit("complete", this.completedRemove);
    },
  },
  watch: {
    payment() {
      this.errorRemove = undefined;
      this.completedRemove = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>