var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "site-main" }, [
    _c(
      "div",
      {
        staticClass: "eicproduct-login__main gds-flex-container",
        attrs: { id: "top-target" }
      },
      [
        _c("nav", { staticClass: "eicproduct-login__nav" }, [
          _c("div", { staticClass: "eicproduct-login__nav__inner-link-wrap" }, [
            _c(
              "div",
              {
                class: !_vm.isGuest
                  ? "div-active eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button"
                  : "eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button",
                on: { click: _vm.renderLoginForm }
              },
              [_vm._v("Login to submit a rebate")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.isGuest
                  ? "div-active eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button"
                  : "eicproduct-login__nav-item eicproduct-login__nav-item--mobile-button",
                on: { click: _vm.renderGuestForm }
              },
              [_vm._v("No account? Sign up as a guest!")]
            )
          ])
        ]),
        _vm._v(" "),
        _vm.isGuest
          ? _c(
              "div",
              { staticClass: "eicproduct-login__login-type-container" },
              [
                _c(
                  "section",
                  { staticClass: "eicproduct-login__guest-section" },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "eicproduct-login__main-headline gds-display-2"
                      },
                      [_vm._v("Submit Rebate as a guest")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "gds-body-normal" }, [
                      _vm._v(
                        "\n          If you have an online account, you can submit a rebate by\n          "
                      ),
                      _c(
                        "span",
                        {
                          attrs: { id: "submitAsGuest" },
                          on: { click: _vm.renderLoginForm }
                        },
                        [_vm._v("Logging In.")]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.errorWrongNumber
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-message-box form-message-box--full-width gds-space-stack-l"
                          },
                          [
                            _vm._v(
                              "\n          We were unable to locate an account that matched the information you provided. You can also\n          "
                            ),
                            _vm._m(0)
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        staticClass: "eicproduct-login__form",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.GuestLogin()
                          }
                        }
                      },
                      [
                        _c(
                          "fieldset",
                          {
                            staticClass: "gds-fieldset gds-space-stack-m",
                            class: { "gds-has-error": _vm.errorWrongNumber }
                          },
                          [
                            _c("label", { staticClass: "gds-input-field" }, [
                              _c(
                                "span",
                                { staticClass: "gds-input-field__label" },
                                [_vm._v("Account Number")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.accountNumber,
                                    expression: "accountNumber"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "###########",
                                    expression: "'###########'"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|numeric|min:10|max:11",
                                    expression:
                                      "'required|numeric|min:10|max:11'"
                                  }
                                ],
                                key: "account number",
                                staticClass: "gds-input-field__input",
                                attrs: { name: "account number", type: "tel" },
                                domProps: { value: _vm.accountNumber },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.accountNumber = $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors.first("account number")
                                ? _c(
                                    "div",
                                    { staticClass: "validation-error" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first("account number")
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          {
                            staticClass: "gds-fieldset gds-space-stack-m",
                            class: { "gds-has-error": _vm.errorWrongNumber }
                          },
                          [
                            _c("label", { staticClass: "gds-input-field" }, [
                              _c(
                                "span",
                                { staticClass: "gds-input-field__label" },
                                [_vm._v("Phone Number (Last 4 Digits)")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.phone,
                                    expression: "phone"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "####",
                                    expression: "'####'"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|numeric|min:4|max:4",
                                    expression: "'required|numeric|min:4|max:4'"
                                  }
                                ],
                                key: "phone number",
                                staticClass: "gds-input-field__input",
                                attrs: { name: "phone number", type: "tel" },
                                domProps: { value: _vm.phone },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.phone = $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors.first("phone number")
                                ? _c(
                                    "div",
                                    { staticClass: "validation-error" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("phone number"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-space-stack-l" }, [
                          _vm.errorLogin
                            ? _c(
                                "div",
                                {
                                  staticClass: "gds-helper-text-error",
                                  attrs: { id: "one-time-submission_error" }
                                },
                                [_vm._v(_vm._s(_vm.errorLogin))]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-space-stack-l" }, [
                          _c(
                            "button",
                            {
                              staticClass: "gds-button",
                              class: { "gds-loading": _vm.pendingLogin },
                              attrs: {
                                disabled: _vm.pendingLogin,
                                type: "submit"
                              }
                            },
                            [
                              _c("span", { staticClass: "gds-button__text" }, [
                                _vm._v("Next")
                              ])
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isGuest
          ? _c(
              "div",
              { staticClass: "eicproduct-login__login-type-container" },
              [
                _c(
                  "section",
                  { staticClass: "eicproduct-login__login-section" },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "eicproduct-login__main-headline gds-display-2"
                      },
                      [_vm._v("Log in to Submit Rebate")]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "gds-body-normal" }, [
                      _vm._v(
                        "\n          Log in to your online GMP account to submit a rebate. Don’t have an online account? You can\n          "
                      ),
                      _c(
                        "span",
                        {
                          attrs: { id: "submitAsGuest" },
                          on: { click: _vm.renderGuestForm }
                        },
                        [_vm._v("Submit as Guest.")]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "form",
                      {
                        staticClass: "global-panel__form",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.Login()
                          }
                        }
                      },
                      [
                        _c(
                          "fieldset",
                          {
                            staticClass: "gds-fieldset gds-space-stack-m",
                            class: { "gds-has-error": _vm.errorlogin }
                          },
                          [
                            _c("label", { staticClass: "gds-input-field" }, [
                              _c(
                                "span",
                                { staticClass: "gds-input-field__label" },
                                [_vm._v("Username or Email")]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.username,
                                    expression: "username"
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                key: "user name",
                                staticClass: "gds-input-field__input",
                                attrs: {
                                  name: "user name",
                                  type: "text",
                                  spellcheck: "false",
                                  autocapitalize: "none",
                                  autocomplete: "username"
                                },
                                domProps: { value: _vm.username },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.username = $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _vm.errors.first("user name")
                                ? _c(
                                    "div",
                                    { staticClass: "validation-error" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("user name"))
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "fieldset",
                          {
                            staticClass: "gds-fieldset gds-space-stack-m",
                            class: { "gds-has-error": _vm.errorlogin }
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "gds-input-field" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "gds-input-field__label" },
                                  [_vm._v("Password")]
                                ),
                                _vm._v(" "),
                                _c("widget-password-input", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'"
                                    }
                                  ],
                                  key: "password",
                                  attrs: {
                                    autocomplete: "current-password",
                                    name: "password"
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.first("password")
                                  ? _c(
                                      "div",
                                      { staticClass: "validation-error" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.errors.first("password"))
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "global-login-panel__form-helper"
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "gds-checkbox gds-checkbox-thin"
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.stayloggedin,
                                          expression: "stayloggedin"
                                        }
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "stay-logged-in"
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.stayloggedin)
                                          ? _vm._i(_vm.stayloggedin, null) > -1
                                          : _vm.stayloggedin
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.stayloggedin,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.stayloggedin = $$a.concat([
                                                  $$v
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.stayloggedin = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.stayloggedin = $$c
                                          }
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "gds-checkbox__faux"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "gds-checkbox__label gds-display-00 gds-text-grey"
                                      },
                                      [_vm._v("Stay Logged In")]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(1)
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-space-stack-l" }, [
                          _vm.errorlogin
                            ? _c(
                                "div",
                                {
                                  staticClass: "gds-helper-text-error",
                                  attrs: { id: "submission_error" }
                                },
                                [_vm._v(_vm._s(_vm.errorlogin))]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "gds-space-stack-l" }, [
                          _c(
                            "button",
                            {
                              staticClass: "gds-button",
                              class: { "gds-loading": _vm.pendingLogin },
                              attrs: {
                                disabled: _vm.pendingLogin,
                                type: "submit"
                              }
                            },
                            [
                              _c("span", { staticClass: "gds-button__text" }, [
                                _vm._v("Log In")
                              ])
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-nobreak" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://greenmountainpower.com/wp-content/uploads/2020/09/EV-Rebate-Form.pdf"
          }
        },
        [_vm._v("fill out a form here.")]
      ),
      _vm._v(".\n          ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-login-panel__form-helper" }, [
      _c(
        "a",
        {
          staticClass: "gds-display-00 gds-link gds-link--bold",
          attrs: { href: "/account/forgot" }
        },
        [_vm._v("Forgot Password?")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }