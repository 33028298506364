import DataStore from "../../store";
import GMPAPI from "../../services/gmpapi";

import ReportOutageLogin from "./ReportOutageLogin.vue";
import ReportOutageLoginAccount from "./ReportOutageLoginAccount.vue";
import ReportOutageLoginPhone from "./ReportOutageLoginPhone.vue";
import ReportOutageFlow from "./ReportOutageFlow.vue";

export const ReportOutageRouterOptions = {
  mode: "history",
  base: "/report-outage",
  routes: [
    { path: "/login", component: ReportOutageLogin },
    { path: "/login-account", component: ReportOutageLoginAccount },
    { path: "/login-phone", component: ReportOutageLoginPhone },
    {
      path: "/",
      component: ReportOutageFlow,
      beforeEnter: async (to, from, next) => {
        try {
          DataStore.commit("setRouteLoading", true);
          await GMPAPI.GetCurrentUser();
          // We have auth, but may not have user info
          if (!DataStore.state.user.userinfo) {
            try {
              await DataStore.dispatch("AttemptCookieLogin");
              next();
            } catch (err) {
              next("/login");
            }
          } else {
            next();
          }
        } catch (err) {
          next("/login");
        } finally {
          DataStore.commit("setRouteLoading", false);
        }
      }
    },
  ],
};

export function ReportOutageRouterInit(router) {
  // No external init needed
}