/**
 * Returns a related field name and message for speedpay errors.
 * If there is no particular field, it is omitted, and the error should be shown globally.
 */
import { GMPPhoneNumber } from "./environment"

export function InterpretSpeedpayCode(code) {
  switch (code) {
    case 18052:
    case 18081:
    case 28004:
      return { field: "account nickname", msg: "The account nickname is invalid" };
    // Credit card
    case 1001:
    case 9002:
      return { field: "card number", msg: "The card was declined." };
    case 1019:
    case 1020:
    case 1021:
    case 1022:
    case 1023:
    case 1024:
    case 1025:
    case 1026:
      return { field: "card number", msg: "This type of card is not accepted." };
    case 3342:
    case 18057:
    case 18077:
      return { field: "card number", msg: "The card number was incorrect." };
    case 1006:
    case 18033:
    case 18076:
      return { field: "CVV", msg: "The CVV was incorrect." };
    case 1012:
    case 18015:
    case 18016:
    case 18035:
      return { field: "expiration date", msg: "The expiration date was incorrect." };
    // Bank account
    case 2518:
    case 4310:
    case 18055:
    case 4533006:
      return { field: "account number", msg: "The account number was incorrect." };
    case 2517:
    case 4330:
    case 4331:
    case 4332:
    case 18056:
    case 4533005:
      return { field: "routing number", msg: "The routing number was incorrect." };
    case 4533003:
    case 4533004:
      return { field: "account number", msg: `There is a problem with this account, please select another account or call ${GMPPhoneNumber || '1-(888)-835-4672'} Monday through Friday, 9am - 6pm.` };

    // Non-specific fields
    case 28002:
      return { msg: "That account nickname already exists for this account." };
    case 28003:
      return { msg: "A matching payment method already exists for this account." };
    default:
      return undefined;
  }
}
