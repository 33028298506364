<template>
  <div class="bill-pay-login__main gds-flex-container">
    <div class="bill-pay-login__login-type-container">
      <section id="top-target" class="bill-pay-login__account-select">
        <h2 class="gds-display-1">Choose Account</h2>

        <form class="bill-pay-login__form" @submit.prevent>
          <fieldset class="gds-fieldset">
            <legend class="gds-body-normal gds-space-stack-ml">Please select one of your accounts from the list below to make a payment.</legend>
            <label v-for="account in accounts" :key="account.accountNumber" class="bill-pay-login__form--radio-label-flex gds-radio">
              <input v-model="selectedAccountNumber" type="radio" :value="account.accountNumber" />
              <span class="gds-radio__faux"></span>
              <span class="gds-radio__label bill-pay-login__radio-label">
                <span v-if="account.nickname">
                  <b>{{account.nickname}}</b>
                  <br />
                </span>
                {{account.address | addressFormat({ separator: ", " })}}
                <br />
                Acct #: {{account.accountNumber}}
                <br />
                <span class="payment-status-display">
                  <payment-status-display :account="account" :disablePending="accounts.length > 2" />
                </span>
              </span>
            </label>
          </fieldset>
        </form>

        <div class="gds-space-stack-l">
          <button @click="ReturnToSender('/make-payment')" type="button" class="gds-button gds-secondary gds-space-inline-m">Cancel</button>
          <button @click="nextPage()" type="button" class="gds-button gds-space-inline-m">Next</button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { DumpError, ReturnToSender } from "../../utilities";
import PaymentStatusDisplay from "../generic/PaymentStatusDisplay.vue";

export default {
  components: { PaymentStatusDisplay },
  name: "BillPayAccount",
  data() {
    return {
      ReturnToSender,
      selectedAccountNumber: undefined,
    };
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
    accounts() {
      if (!this.isAdmin && !this.isAdminLite) {
        return this.$store.state.user.accounts;
      } else {
        return this.$store.state.user.ghostAccounts || [];
      }
    },
  },
  async mounted() {
    try {
      if (this.accounts.length === 1) {
        this.$router.push({ path: "/pay/1" });
      } else {
        if (!this.selectedAccountNumber) {
          this.selectedAccountNumber = this.$store.state.user.currentAccount.accountNumber;
        }
      }
    } catch (err) {
      DumpError("Account mount error", err);
    }
  },
  methods: {
    async nextPage() {
      try {
        let selected = this.accounts.find(item => item.accountNumber === this.selectedAccountNumber);
        this.$store.commit("setCurrentAccount", selected);
        this.$router.push({ path: "/pay/1" });
      } catch (err) {
        DumpError("Account next error", err);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>