<template>
  <div
  class="usage-dashboard__widget-net-metering"
  v-bind:class="{ 'is-dashboard-view': isDashboardView, 'wide-layout': useWideLayout}" ref="resizableElement">
    <div class="header" v-if="isDashboardView">
      <h2>Performance this Billing Period</h2>
      <a v-if="showDetailArrow" href="/account/usage-new/detail/performance" class="usage-dashboard__circle-arrow-right gds-button gds-button-circle gds-button-outline">
      <svg class="gds-icon">
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
      </svg></a>
    </div>
    <template v-if="loadState === 'complete'">
      <WidgetNetMeteringDetail
        :billingSummary="billingSummary"
        :isDashboardView="isDashboardView"
        :showDetailArrow="showDetailArrow"
        :summaryGeneration="summaryGeneration"
        :summaryTextGeneration="summaryTextGeneration"
        :summaryIconTotalGeneration="summaryIconTotalGeneration"
        @change-options-event="handleOptionChangeEvent" />

    </template>
    <div v-if="!loadState" class="usage-dashboard__widget-current-usage my-account__usage-loading" />
    <div v-if="loadState === 'unavailable'" class="error-message">Usage information is not available for this account.</div>
    <div v-if="loadState === 'error'" class="error-message">Unable to load, please try again later.</div>
  </div>
</template>

<script>
import MediaQueryMixin from "../../../mixins/MediaQueryMixin";
import UsageDashboardMixin from "../../../mixins/UsageDashboardMixin";
import WidgetNetMeteringDetail from "./WidgetNetMeteringDetail.vue";

export default {
  name: "WidgetNetMetering",
  components: {
    WidgetNetMeteringDetail,
  },
  mixins: [
    MediaQueryMixin,
    UsageDashboardMixin
  ],
  props: {
    billingSummary: Object,
    loadState: String,
    isDashboardView: Boolean,
    showDetailArrow: Boolean,
    hasNarrowContainer: Boolean,
    summaryGeneration: String,
    summaryTextGeneration: String,
    summaryIconTotalGeneration: String
  },
  data() {
    return {
      rateData: undefined,
      resizeObserver: null,
      widgetWidth: 0,
    }
  },
  computed: {
    useWideLayout() {
      return this.widgetWidth > 1000;
    }
  },
  beforeDestroy() {
    this.destroyResizeObserver();
  },
  mounted() {
      this.initResizeObserver();
  },
  methods: {
    handleOptionChangeEvent(option) {
      this.$emit('change-options-event', option);
    },
     initResizeObserver() {
      if (typeof ResizeObserver !== 'undefined') {
        this.resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            console.log('Resized:', entry.contentRect.width, entry.contentRect.height);
            this.widgetWidth = entry.contentRect.width;
          }
        });

        this.resizeObserver.observe(this.$refs.resizableElement);
      } else {
        console.warn('ResizeObserver is not supported in this browser.');
      }
    },
    destroyResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    }
  },
};
</script>
