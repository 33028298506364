<template>
  <div v-if="visible" class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal">
      <button @click="close('documentChecklist')" type="button" class="close bill-pay__close">
        <svg class="gds-icon close-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
        </svg>
      </button>
      <FocusLock>
        <div class="eicproducts__modal-box--body">
          <div class="gds-font-size-xl gds-font-demi gds-space-stack-m">What are you uploading?</div>
          <div class="my-account__modal-box--message gds-space-stack-l">
            <div v-if="selectedFile">Please select which document(s) are contained in the file: {{ selectedFile.name }}
            </div>
          </div>
          <div v-for="(uploadDocument, i) in uploadDocumentList" :key="uploadDocument.documentName + i"
            class="file-uploader">
            <div class="gds-space-stack-ml">
              <!-- Faux file picker - wraps it in a label and hides the input -->
              <label class="gds-checkbox gds-checkbox-thin gds-space-stack-m">
                <input :id="generateInputId(uploadDocument.displayName)" type="checkbox"
                  @change="onSelect($event, uploadDocument.displayName)"
                  :disabled="documentTypes.includes(uploadDocument.displayName) && uploadDocument.file != null ? true : false"
                  :value="uploadDocument.documentName" name />
                <span class="gds-checkbox__faux"></span>
                <span class="gds-font-size-ml">
                  <b>{{ uploadDocument.displayName }}</b>
                </span>
              </label>
            </div>
          </div>
          <button @click="close('documentChecklist')" type="button" class="gds-button gds-secondary">Cancel
            Upload</button>
          <button @click="onSave" type="button" class="gds-button">Save</button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>


export default {
  name: "ModalDocumentChecklist",
  props: {
    uploadDocumentList: [],
    visible: Boolean,
    selectedFile: File,
    documentTypes: Array,
  },
  data() {
    return {
      docTypes: this.documentTypes
    }
  },
  async mounted() {
    for (let i = 0; i < this.docTypes.length; i++) {
      this.disable(this.docTypes[i]);
    }
  },
  methods: {
    onSave(event) {
      this.$emit('document-types-changed', this.docTypes);
    },
    generateInputId(displayName) {
      return displayName.replace(/\s/g, '')
    },
    disable(docType) {
      const el = document.querySelector(`#${this.generateInputId(docType.displayName)}`);
      el.setAttribute("disabled", true)
    },
    close() {
      this.$emit('close-modal', this.modalType);
    },
    onSelect(event, id) {
      const filetype = {
        documentName: event.target.value,
        id: id
      }
      if (event.target.checked) {
        this.docTypes.push(filetype);
      } else {
        for (let i = 0; i < this.docTypes.length; i++) {
          if (filetype.documentName === this.docTypes[i].documentName) {
            this.docTypes.splice(i, 1);
          }
        }
      }
    },
  },
  watch: {
    visible(val) {
      if (val && this.selectedFile) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>