 <template>
  <span>
  <DateNavigationButtonMobile
  :buttonText="buttonText"
  :disabled="disabled"
  :addBackground="addBackground"
  :buttonClicked="onButtonClicked"
  class="usage-detail__date-navigation-item"/>

  <UsageDetailSelectMobileModal
  v-if="modalVisible"
  :config="configCopied"
  :modalTitle="modalTitle"
  :closeModal="closeModal"
  :selectOption="onOptionSelected"/>
  </span>
</template>

<script>
const defaultWidth = '165px';
import DateNavigationButtonMobile from "../../../../components/generic/DateNavigationButtonMobile";
import UsageDetailSelectMobileModal from "./UsageDetailSelectMobileModal";

export default {
  name: "UsageDetailSelectMobile",
  props: {
    config: Object,
    optionSelected: Function,
    modalTitle: String,
    disabled: Boolean,
    addBackground: Boolean,
  },
  components: {
    DateNavigationButtonMobile,
    UsageDetailSelectMobileModal
  },
  data() {
    return {
      configCopied: this.copyObject(this.config),
      spanWidth: this.config.width || defaultWidth,
      selectWidth: this.config.width || defaultWidth,
      buttonText: this.config.selectedLabel || this.config.options[0].label,
      modalVisible: false
    };
  },
  mounted() {
    if (this.config.width) {
      this.setSelectWidth(this.config.width);
    }
  },
  watch: {
    config(val) {
      this.configCopied = this.copyObject(val),
      this.buttonText = val.selectedLabel;
    }
  },
  methods: {
    copyObject(objectToCopy) {
     return JSON.parse(JSON.stringify(objectToCopy));
    },
    onButtonClicked() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    onSelectChange($event) {
       this.optionSelected($event.target.value);
    },
    setSelectWidth(originalWidth) {
      this.selectWidth = (parseInt(originalWidth) - 20) + 'px';
    },
    onOptionSelected(selectedValue) {
      this.optionSelected(selectedValue);

      const matchingOption = this.getMatchingOption(this.config.options, selectedValue);

      this.buttonText = matchingOption.label;
      this.configCopied.selectedLabel = matchingOption.label;
      this.configCopied.selectedValue = selectedValue;
    },
    getMatchingOption(targetArray, selectedValue) {
      let foundElement = undefined;

      targetArray.forEach((element) => {
        if (element.value === selectedValue) {
          foundElement = element;
        }
      });

      return foundElement;
    }
  }
};
</script>

<style scoped>
</style>