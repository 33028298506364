var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-detail__component-container" }, [
    _c("div", { staticClass: "usage-detail__usage-performance" }, [
      _c(
        "section",
        { staticClass: "gds-space-stack-l" },
        [
          _c("UsageDetailControls", {
            attrs: {
              selectedDateWidth: "300px",
              datePickerDateType: "month",
              showGoToTodayButton: true,
              selectedDate: _vm.selectedDate,
              datePickerDisabledDates: _vm.disabledDates,
              selectedDateSubText: _vm.selectedDateSubText,
              showJumpToDateButton: _vm.showJumpToDateButton,
              showDateForwardsButton: _vm.showDateForwardsButton,
              showDateBackwardsButton: _vm.showDateBackwardsButton,
              onGoToTodayClicked: _vm.onGoToTodayClicked,
              onDateForwardsClicked: _vm.onDateForwardsClicked,
              onSelectedDateChanged: _vm.onSelectedDateChanged,
              onDateBackwardsClicked: _vm.onDateBackwardsClicked,
              disableAllDateNavControls: !_vm.loadState,
              disableDateNavigationButtonBack:
                _vm.disableDateNavigationButtonBack,
              disableDateNavigationButtonForward:
                _vm.disableDateNavigationButtonForward
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.loadState ? _c("div", { staticClass: "table-loading" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "usage-page--interior-status-container" },
        [
          _vm.loadState === "error"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "error" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "maintenance"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "maintenance" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "unavailable"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "unavailable",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "empty"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "nodata",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c("section", { staticClass: "gds-space-stack-l" }, [
              _c("div", { staticClass: "my-account__usage-graph-wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "usage-detail__usage-performance__widget-net-metering-wrapper"
                  },
                  [
                    _c("widget-net-metering", {
                      attrs: {
                        loadState: "complete",
                        billingSummary: _vm.billingSummary,
                        summaryGeneration: _vm.summaryGeneration,
                        summaryTextGeneration:
                          _vm.summaryTextGenerationForNetMeteringWidget,
                        summaryIconTotalGeneration:
                          _vm.summaryIconTotalConsumptionForNetMeteringWidget
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass:
                      "usage-detail__section-heading gds-space-stack-l"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "usage-detail__usage-performance__total-summary",
                        attrs: {
                          id:
                            "usage-detail__usage-performance__total-summary__generation"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "usage-detail__usage-performance__total-summary__top-row"
                          },
                          [
                            _vm._v(
                              "\n                Total Generation\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "usage-detail__usage-performance__total-summary__bottom-row"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "usage-detail__usage-performance__total-summary__bottom-row__left"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle-container"
                                      },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle",
                                          attrs: {
                                            src:
                                              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/" +
                                              _vm.summaryIconTotalGeneration,
                                            alt: "performance indicator"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--text",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.summaryTextGeneration
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.isMobbileView && !_vm.isTabletView
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "usage-detail__usage-performance__total-summary__bottom-row__right"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "usage-detail__performance__total-summary__compare-legend-container"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "usage-detail__performance__total-summary__legend-item gds-space-inline-ml"
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "usage-detail__performance__total-summary__legend-item--circle current generation"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "usage-dashboard__legend-text-container"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text"
                                                  },
                                                  [_vm._v("Total Generation")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text legend-item-sub-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.legendCurrentConsumptionValue
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "usage-detail__performance__total-summary__legend-item"
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "usage-detail__performance__total-summary__legend-item--circle previous"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "usage-dashboard__legend-text-container"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Previous Total Generation"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text legend-item-sub-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.legendPreviousConsumptionValue
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "usage-detail__usage-performance__current-usage-chart-ref-wrapper"
                  },
                  [
                    _c("div", {
                      ref: "currentUsageChartRefGeneration",
                      staticClass: "current-usage-chart-ref"
                    })
                  ]
                ),
                _vm._v(" "),
                _vm.isMobbileView || _vm.isTabletView
                  ? _c("section", { staticClass: "gds-space-stack-l" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "usage-detail__usage-performance__chart-legend-wrapper net-metering"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "usage-detail__usage-performance__chart-legend-items"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "usage-detail__usage-performance__chart-legend-item"
                                },
                                [
                                  _c("span", {
                                    staticClass: "circle current generation"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__label-and-value"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Total Generation\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__value"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.legendCurrentConsumptionValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "usage-detail__usage-performance__chart-legend-item"
                                },
                                [
                                  _c("span", {
                                    staticClass: "circle previous"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__label-and-value"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Previous Total Generation\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__value"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.legendPreviousConsumptionValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "section",
                  {
                    staticClass:
                      "usage-detail__section-heading gds-space-stack-l"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "usage-detail__usage-performance__total-summary",
                        attrs: {
                          id:
                            "usage-detail__usage-performance__total-summary__consumption"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "usage-detail__usage-performance__total-summary__top-row"
                          },
                          [
                            _vm._v(
                              "\n                Total Consumption\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "usage-detail__usage-performance__total-summary__bottom-row"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "usage-detail__usage-performance__total-summary__bottom-row__left"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle-container"
                                      },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--circle",
                                          attrs: {
                                            src:
                                              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/" +
                                              _vm.summaryIconTotalConsumption,
                                            alt: "performance indicator"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "usage-detail__usage-performance__total-summary__bottom-row__left__detail-item--text",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.summaryTextConsumption
                                        )
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.isMobbileView && !_vm.isTabletView
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "usage-detail__usage-performance__total-summary__bottom-row__right"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "usage-detail__performance__total-summary__compare-legend-container"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "usage-detail__performance__total-summary__legend-item gds-space-inline-ml"
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "usage-detail__performance__total-summary__legend-item--circle current consumption"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "usage-dashboard__legend-text-container"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text"
                                                  },
                                                  [_vm._v("Total Consumption")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text legend-item-sub-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.legendCurrentConsumptionValue
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "usage-detail__performance__total-summary__legend-item"
                                          },
                                          [
                                            _c("div", {
                                              staticClass:
                                                "usage-detail__performance__total-summary__legend-item--circle previous"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "usage-dashboard__legend-text-container"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Previous Total Consumption"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "usage-detail__performance__total-summary__legend-item--text legend-item-sub-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.legendPreviousConsumptionValue
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "usage-detail__usage-performance__current-usage-chart-ref-wrapper"
                  },
                  [
                    _c("div", {
                      ref: "currentUsageChartRefTotalHomeConsumption",
                      staticClass: "current-usage-chart-ref"
                    })
                  ]
                ),
                _vm._v(" "),
                _vm.isMobbileView || _vm.isTabletView
                  ? _c("section", { staticClass: "gds-space-stack-l" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "usage-detail__usage-performance__chart-legend-wrapper  net-metering"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "usage-detail__usage-performance__chart-legend-items"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "usage-detail__usage-performance__chart-legend-item"
                                },
                                [
                                  _c("span", {
                                    staticClass: "circle current consumption"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__label-and-value"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Total Home Consumption\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__value"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.legendCurrentConsumptionValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "usage-detail__usage-performance__chart-legend-item"
                                },
                                [
                                  _c("span", {
                                    staticClass: "circle previous"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__label-and-value"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Previous Total Home Consumption\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "usage-detail__usage-performance__chart-legend-item__value"
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.legendPreviousConsumptionValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }