<template>
  <div>
    <!-- Back button -->
    <a :href="backButtonUrl" class="outage-message__back-button">
      <svg class="gds-icon black">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left" />
      </svg>
      <span>Back</span>
    </a>
    <div v-if="loadState === undefined" class="table-loading" />
    <flow-error v-if="loadState === 'error'" name="Information for the specified message" state="error" />
    <flow-error v-if="loadState === 'empty'" name="Information for the specified message" state="nodata" />
    <div v-if="loadState === 'complete'">
      <!-- Message -->
      <section class="outage-message__container">
        <span class="gds-text-grey">{{ messageTime }}</span>
        <h3>{{ messageTitle }}</h3>
        <span v-html="messageDescription"></span>
      </section>
    </div>
  </div>
</template>

<script>

import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";
import SubpageIncidentView from "./SubpageIncidentView.vue";
import { format, parseISO } from "date-fns";
import { isFeatureEnabled } from "../../services/featureflags";

export default {
  name: "SubpageMessageDetail",
  components: {
    SubpageIncidentView
  },
  data() {
    return {
      loadState: undefined,
      messageId: this.$route.params.messageid,
      incidentId: this.$route.params.incidentid,
      messageTime: undefined,
      messageTitle: undefined,
      messageDescription: undefined,
      FF_OutagesMessaging: undefined
    };
  },
  computed: {
    backButtonUrl() {
      let backurl = '';
      if (!this.incidentId) {
        backurl = '/outages/';
      } else {
        backurl = `/outages/incident/${ this.incidentId }`;
      }
      return backurl;
    }
  },
  async mounted() {
    await this.getConfigs();

    try {
      if(this.FF_OutagesMessaging) {
        const message = await GMPAPI.GetMessageByMessageId(this.messageId, this.isPreview());
        if (!message || message.length === 0) {
          this.loadState = "empty";
          return;
        }
        const messageTime = message.sendAt ? message.sendAt : message.createdAt ? message.createdAt : new Date().toISOString();
        this.messageTime = this.formatMessageTime(messageTime);
        this.messageTitle = message.title;
        this.messageDescription = message.fullMessage;
        this.loadState = "complete";
      } else {
        this.loadState = "error";
      }
    } catch (err) {
      DumpError("Message load error", err);
      this.loadState = "error";
    }
  },
  methods: {
    async getConfigs() {
      this.FF_OutagesMessaging = await isFeatureEnabled('FF_OutagesMessaging', false);
    },

    formatMessageTime(timeSent) {
      return format(parseISO(timeSent), 'MMM d, p');
    },

    isPreview() {
      const query = window.location.search;
      const params = new URLSearchParams(query);
      const preview = params.get("ispreview");
      if(preview && preview.toLocaleLowerCase() === "true") {
        return true;
      }

      return false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>