<template>
  <div class="header-login">
    <div v-if="!loggedInUser && !isMobile">
      <!--this is the button visible in the desktop view when not logged in-->
      <button @click="ToggleLoginDrawer" class="header-login-button gds-button gds-compact gds-nav-button">Log In</button>
    </div>

    <!-- this is the login/create section in the mobile menu when not logged in-->
    <div class="menu-group menu-group__tan-background-mobile" v-if="!loggedInUser && isMobile">
      <div class="menu-group__header-row">
          <div class="title">My Account</div>
      </div>
      <div class="button-row">
          <button @click="ToggleLoginDrawer" class="gds-button gds-button-round gds-compact">Log In</button>
          <a href="/account/create/" class="gds-button gds-button-round gds-button-outline gds-compact"><span class="gds-button__text">Create Account</span></a>
      </div>
    </div>


    <div v-if="loggedInUser">
      <!-- This should only show above 900px wide -->
      <button
        v-if="!isMobile"
        @click="ToggleUserMenu"
        class="header--user-toggle-button gds-button gds-nav-button gds-primary caret"
        :class="{ 'caret-down' : !userMenuVisible }"
      >{{loggedInUser.fullName}}</button>



      <div v-if="userMenuVisible || isMobile" v-click-outside="ToggleUserMenu" class="header-login__settings">


        
        <div class="menu-group menu-group__tan-background-mobile">
          <div class="menu-group__header-row gds-space-stack-default">
            <div class="title ">
              <span>Signed in as </span>
              <span :title="'Login email: ' + loggedInUser.email">{{loggedInUser.fullName}}</span>
              <!--<p class="header-login__settings--email">{{loggedInUser.email}}</p>-->
            </div>
            <button @click="LogOut()" class="action-button gds-button gds-button-text">Log Out</button>
          </div>


          <ul class="header-login__settings__nav-list gds-body-large">
            <li v-if="(!isAdmin || !isAdminLite)">
              <a href="/account">Account Dashboard</a>
            </li>
            <li>
              <a href="/user-profile">User Settings</a>
            </li>
            <li v-if="!isAdmin && !isAdminLite">
              <a v-if="numLinkedAccounts > 1" href="/user-profile/accounts">Linked Accounts ({{numLinkedAccounts}})</a>
              <a v-else href="/user-profile/accounts">Add a Linked Account</a>
            </li>
          </ul>
        </div>
        <div class="menu-group menu-group__tan-background-mobile" v-if="(!isAdmin || !isAdminLite) && (numLinkedAccounts > 1)">
          <div class="menu-group__header-row">
            <div class="title">Current Account</div>
            <button @click="ToggleAccountSwitcher()" class="action-button gds-button gds-button-text">Switch</button>
          </div>
          <widget-account-switcher :minified="true"></widget-account-switcher>
        </div>


        <!--
        <div v-if="isDeveloper" style="margin-top: 30px; border-top: 1px solid #d8d8d8; padding-top: 10px;">
          <div class="gds-space-stack-m">Developer tools</div>
          <button @click="ClearRefresh()" type="button" class="gds-button gds-small-button">
            <span class="gds-button__text">Clear refresh timer</span>
          </button>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import WidgetAccountSwitcher from "./WidgetAccountSwitcher.vue";

export default {
  name: "HeaderLoginButton",
    components: {
    WidgetAccountSwitcher,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    // Start watching window size
    window.addEventListener("resize", this.OnResize)
  },
  beforeDestroy() {
    // We must make sure to remove the listener when the component is destroyed
    window.removeEventListener("resize", this.OnResize);
  },
  computed: {
    loggedInUser() {
      return this.$store.state.user.userinfo;
    },
    userMenuVisible() {
      return this.$store.state.layout.usermenuvisible;
    },
    numLinkedAccounts() {
      if (this.$store.state.user.accounts) {
        return this.$store.state.user.accounts.length;
      } else {
        return 0;
      }
    },
    isMobile() {
      return this.windowWidth <= 1100;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
    isDeveloper() {
      // return false;
      return window.location.hostname.startsWith("localhost");
    }
  },
  methods: {
    ToggleLoginDrawer() {
      this.$store.commit("toggleLoginDrawer");
    },
    ToggleUserMenu() {
      this.$store.commit("toggleUserMenu");
    },
    async LogOut() {
      this.$store.commit("setUserMenuVisible", false);
      await this.$store.dispatch("LogOut");
      window.location = "/";
    },
    OnResize() {
      this.windowWidth = window.innerWidth;
    },
    ClearRefresh() {
      this.$store.commit("clearLastRefresh");
    },
    ToggleAccountSwitcher() {
      this.$store.commit("toggleAccountSwitcher");
      // This prevents the page from scrolling down to where it was previously.
      if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual';
      }
      // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded.Cross-browser supported.
      window.scrollTo(0,0);
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
