const KEY_CODE_MAP = {
  TAB: 9,
  ENTER: 13,
  ESC: 27
};

var debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};


var forEach = function (array, callback, scope) {
  for (var i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need
  }
};

var toggleClass = function (target, className) {
  return target.classList.toggle(className);
}

var removeClass = function (targets, className) {
  forEach(targets, (i, target) => target.classList.remove(className));
}

// Prevents tabbing to items in the query.
var disableLinkTabbing = function (query) {
  var links = query.querySelectorAll('a');
  [].forEach.call(links, function (link) {
    link.setAttribute("tabindex", "-1");
  })
};

// Re-enables tabbing to items in the query.
var enableLinkTabbing = function (query) {
  var links = query.querySelectorAll('a');
  [].forEach.call(links, function (link) {
    link.setAttribute("tabindex", "0")
  })
};

var makeAjaxRequest = function (method, url, xhr) {
  return new Promise(function (resolve, reject) {
    xhr.open(method, url, true);
    xhr.responseType = 'json';
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    xhr.send();
  });
}

//https://stackoverflow.com/a/901144
var getURLParam = function (name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

var trapFocus = function (wrapper, firstSelector, lastSelector) {
  const keyboardHandler = function keyboardHandler(e) {
    var firstFocusableEl = wrapper.querySelector(firstSelector);
    var lastFocusableEl = wrapper.querySelector(lastSelector);
    if (e.keyCode === KEY_CODE_MAP.TAB) {
      console.log(lastFocusableEl.innerHTML);
      //Rotate Focus
      if (e.shiftKey && document.activeElement === firstFocusableEl) {
        e.preventDefault();
        lastFocusableEl.focus();
      } else if (!e.shiftKey && document.activeElement === lastFocusableEl) {
        e.preventDefault();
        firstFocusableEl.focus();
      }
    }
  };
  wrapper.addEventListener('keydown', keyboardHandler);
  return function cleanUp() {
    wrapper.removeEventListener('keydown', keyboardHandler);
  }
}

var copyToClipboard = function (value) {
  navigator.clipboard.writeText(value);
}

export { debounce, forEach, toggleClass, copyToClipboard, removeClass, disableLinkTabbing, enableLinkTabbing, getURLParam, makeAjaxRequest, trapFocus }
