var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bill-pay-login__phone-address-inner" }, [
    _c(
      "div",
      { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
      [_vm._v("Report an Outage by Phone")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "gds-font-size-m gds-space-stack-m" }, [
      _vm._v(_vm._s(_vm.GMPPhoneNumber))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
      [_vm._v("Report an Outage by Text")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "gds-font-size-m" }, [
      _vm._v('Text "OUT" to 46788 to report an outage by text.*')
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }