var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "settings-editor my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
        },
        [
          _c(
            "h2",
            { staticClass: "settings-editor__title gds-space-stack-ml" },
            [_vm._v("Name")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "edit-close-button-combo" }, [
            !_vm.editing
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.editing = true
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Edit")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editing && !_vm.pendingSave
              ? _c(
                  "button",
                  {
                    staticClass:
                      "gds-button gds-text-button gds-space-inline-l",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.editing = false
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "my-account__notifications--text-button gds-button__text"
                      },
                      [_vm._v("Close")]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.editing
        ? _c("div", [
            _c("div", { staticClass: "gds-font-size-m gds-space-stack-ml" }, [
              _vm._v(_vm._s(_vm.userInfo.fullName))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.editing
        ? _c(
            "form",
            {
              attrs: { "data-vv-scope": "form" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.Save()
                }
              }
            },
            [
              _c("fieldset", { staticClass: "gds-fieldset" }, [
                _c(
                  "label",
                  { staticClass: "gds-input-field" },
                  [
                    _c("span", { staticClass: "gds-input-field__label" }, [
                      _vm._v("First Name")
                    ]),
                    _vm._v(" "),
                    _c("the-mask", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:50",
                          expression: "'required|max:50'"
                        }
                      ],
                      key: "first name",
                      staticClass: "gds-input-field__input",
                      attrs: {
                        maxlength: "50",
                        name: "first name",
                        type: "text",
                        tokens: _vm.noNumbersNoSpecialCharacters,
                        mask:
                          "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",
                        "aria-invalid": _vm.errors.first("first name", "form")
                          ? true
                          : false
                      },
                      on: {
                        input: function($event) {
                          _vm.touched = true
                          _vm.clearError("first name")
                        }
                      },
                      model: {
                        value: _vm.editedGivenName,
                        callback: function($$v) {
                          _vm.editedGivenName = $$v
                        },
                        expression: "editedGivenName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.errors.first("first name", "form")
                  ? _c("div", { staticClass: "validation-error" }, [
                      _vm._v(_vm._s(_vm.errors.first("first name", "form")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "fieldset",
                { staticClass: "gds-fieldset gds-space-stack-m" },
                [
                  _c(
                    "label",
                    { staticClass: "gds-input-field" },
                    [
                      _c("span", { staticClass: "gds-input-field__label" }, [
                        _vm._v("Last Name")
                      ]),
                      _vm._v(" "),
                      _c("the-mask", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:50",
                            expression: "'required|max:50'"
                          }
                        ],
                        key: "last name",
                        staticClass: "gds-input-field__input",
                        attrs: {
                          maxlength: "50",
                          name: "last name",
                          type: "text",
                          tokens: _vm.noNumbersNoSpecialCharacters,
                          mask:
                            "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",
                          "aria-invalid": _vm.errors.first("last name", "form")
                            ? true
                            : false
                        },
                        on: {
                          input: function($event) {
                            _vm.touched = true
                            _vm.clearError("last name")
                          }
                        },
                        model: {
                          value: _vm.editedSurname,
                          callback: function($$v) {
                            _vm.editedSurname = $$v
                          },
                          expression: "editedSurname"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.errors.first("last name", "form")
                    ? _c("div", { staticClass: "validation-error" }, [
                        _vm._v(_vm._s(_vm.errors.first("last name", "form")))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "gds-button gds-round gds-space-stack-m",
                  class: { "gds-loading": _vm.pendingSave },
                  attrs: {
                    type: "submit",
                    disabled:
                      _vm.pendingSave || !_vm.touched || _vm.errors.any()
                  }
                },
                [
                  _c("span", { staticClass: "gds-button__text" }, [
                    _vm._v("Save")
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errorSave
                ? _c(
                    "div",
                    { staticClass: "form-message-box gds-space-stack-m" },
                    [_vm._v("There was an error updating your name.")]
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }