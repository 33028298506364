<template>
  <div class="webchat-widget">
    <div v-if="queueOnline">
      <div class="gds-space-stack-m">
        <button @click="StartWebchat()" type="button" class="gds-button gds-round gds-invert webchat-button webchat-button-enabled">Start Chat</button>
        <span class="webchat-queue-count">{{ numCalls }} people in queue</span>
      </div>
      <div class="webchat-available-text">Other ways to get in touch</div>
    </div>
    <div v-if="!queueOnline">
      <button disabled type="button" class="gds-button gds-round gds-secondary gds-space-stack-m webchat-button">Chat is offline</button>
      <div class="webchat-unavailable-text">Hi! Chat is not available right now, so please give us a call or email us. Thanks!</div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber, GMPEmail } from "../environment";
import { ShowChat, QueueStatus } from "../services/webchat";
import { DefaultChatQueue } from "../environment";

export default {
  name: "WidgetWebchatButton",
  props: {
    queueName: {
      type: String,
      required: false,
      default: DefaultChatQueue,
    }
  },
  data() {
    return {
      queueStatus: undefined,
      GMPPhoneNumber,
      GMPEmail,
    };
  },
  computed: {
    queueOnline() {
      return this.queueStatus && this.queueStatus.IsOpen;
    },
    queueMode() {
      return this.queueStatus && this.queueStatus.QueueModeName;
    },
    waitTime() {
      return this.queueStatus && this.queueStatus.QueueWaitTime;
    },
    numCalls() {
      return this.queueStatus && this.queueStatus.NumberOfCalls;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.queueStatus = undefined;
      this.queueStatus = await QueueStatus(this.queueName);
      console.log("Chat queue " + this.queueName + " is in " + this.queueMode + " mode");
    },
    StartWebchat() {
      ShowChat(this.queueName);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
