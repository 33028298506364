<template>
  <div class="make-payment-container">
    <div v-if="loadState === undefined" class="bill-pay__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Past Due Wizard" state="error" phonetext="To set up a payment plan by phone call us" closepath="/past-due-wizard" />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 -->
      <section id="slat-1" class="payment-amount bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="bill-pay-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Set Up Installment Plan</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div
                  v-if="!isBudgetBilling && !isAlreadyBudgetBillingEnrolled"
                  class="slat1Summary-past-due-wizard gds-body-normal gds-space-inline-m"
                >{{ selectedOption.money }}/mo added to your bill</div>
                <div
                  v-if="isBudgetBilling || isAlreadyBudgetBillingEnrolled"
                  class="slat1Summary-past-due-wizard gds-body-normal gds-space-inline-m"
                >{{ totalBillAmount | currency }}/mo for next {{ selectedOption.periods }} months.</div>
                <button @click="gotoPage(1)" class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 1" class="past-due-wizard-slat__contents">
            <div class="bill-pay-slat__contents-inner past-due-wizard-banner">
              <banner-one-action
                bannerColor="blue"
                message="Part of your balance is past due."
                linkText="See Assistance Programs"
                url="/past-due-balance-assistance/"
              ></banner-one-action>
              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <div class="bill-pay__amount-flex-container gds-flex-container gds-flex-container--top">
                  <div class="past-due-wizard__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Account Balance</div>
                    <div class="gds-body-normal">{{ currentAccount.amountDue | currency }}</div>
                  </div>
                  <div class="past-due-wizard__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Service Address</div>
                    <div class="gds-body-normal">{{ currentAccount.address | addressFormat }}</div>
                  </div>
                  <div class="past-due-wizard__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Account Number</div>
                    <div class="gds-body-normal">{{ currentAccount.accountNumber }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Box -->
            <div :class="isExpanded ? 'past-due-wizard__installation-details-box' : 'past-due-wizard__installation-details-box-expanded'">
              <span>
                <div class="past-due-wizard__flex-row">
                  <div class="past-due-wizard__flex-item">
                    <div
                      v-if="isBudgetBilling"
                      id="get-current"
                      class="gds-font-demi"
                    >Get current with a {{ totalBillAmount | currency }} total bill amount each month for the next {{ selectedOption.periods }} months.</div>

                    <div
                      v-if="!isBudgetBilling"
                      id="get-current"
                      class="gds-font-demi"
                    >Get current with {{ selectedOption.money }} added to your bill amount each month for the next {{ selectedOption.periods }} months.</div>
                    <ul v-if="isBudgetBilling" id="installation-options-list">
                      <li
                        class="installation-details-list-item"
                      >Includes a {{ selectedOption.money }}/mo interest-free installment for the next {{ selectedOption.periods }} months.</li>
                      <li v-if="budgetBillingStatus.isEligible || !budgetBillingStatus.isEligible && budgetBillingStatus.isEnrolled" class="installation-details-list-item">
                        Includes a {{ budgetBillingStatus.totalBudgetAmount | currency }}/mo Budget Billing amount for energy usage (based
                        on your average usage and adjusted annually).
                        <span
                          class="gds-link gds-link--bold cursorPointer"
                          @click="showModal"
                        >
                          <a>Learn more</a>
                        </span>
                      </li>
                    </ul>
                    <ul v-if="!isBudgetBilling">
                      <li class="installation-details-list-item">The {{ selectedOption.money }}/mo payment for {{ selectedOption.periods }} months is interest-free.</li>
                      <li v-if="!budgetBillingStatus.isEnrolled" class="installation-details-list-item">
                        Based on your current usage, your bill amount each month is projected
                        to range from ${{ billingHistoryMin }}–${{ billingHistoryMax }}.
                      </li>
                      <li v-if="budgetBillingStatus.isEnrolled" class="installation-details-list-item">
                        Already enrolled in a {{ budgetBillingStatus.totalBudgetAmount | currency }}/mo Budget Billing amount for energy usage (based
                        on your average usage and adjusted annually).
                      </li>
                    </ul>
                  </div>

                  <div @click="expandBox()" class="past-due-wizard__flex-item past-due-wizard__expand-icon">
                    <button :id="isExpanded? 'installment-options-button-grey' : 'installment-options-button-green'" class="gds-button gds-text-button">
                      <svg class="past-due-wizard__edit-icon-inline gds-icon">
                        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                      </svg>
                      <span>Installment Options</span>
                    </button>
                  </div>
                </div>
              </span>
            </div>
            <modal-budget-billing-terms :visible="showBudgetBillingTerms" :close="closeModal" :budgetBillingStatus="budgetBillingStatus" />
            <!-- Expanded -->
            <div v-if="isExpanded" class="past-due-wizard__installation-details-box-expanded">
              <span>
                <div class="past-due-wizard__flex-row">
                  <div class="intstallation-options__flex-item">
                    <span class="gds-font-size-medium gds-space-stack-s gds-flex-container--wrap">How would you like to pay off the remaining balance interest free?</span>
                    <div class="radio-button-container">
                      <label v-for="opt of arrangementOptions" :key="opt.type" class="installation-options__radio">
                        <input v-model="selectedOption" :value="opt" type="radio" />
                        <span class="gds-radio__faux"></span>
                        <span class="gds-radio__label">
                          <span class="gds-font-demi">{{ opt.money }}</span>
                          added to your next {{ opt.periods }} bills.
                        </span>
                      </label>
                    </div>
                  </div>

                  <div class="intstallation-options__flex-item gds-flex-container--wrap">
                    <span class="gds-font-size-medium gds-space-stack-s gds-flex-container--wrap">
                      How would you like to pay for your
                      ongoing energy usage each month?
                    </span>
                    <div class="radio-button-container">
                      <div v-if="budgetBillingStatus.isEligible">
                        <label class="gds-radio gds-space-stack-l">
                          <input v-model="isBudgetBilling" :value="true" type="radio" />
                          <span class="gds-radio__faux"></span>
                          <span class="gds-font-demi">Pay same amount each month</span>
                          <div class="enrollment-details-item">
                            This will enroll your account in
                            Budget Billing
                            starting at {{ budgetBillingStatus.totalBudgetAmount | currency }}/mo.
                            <a
                              class="gds-link gds-link--bold"
                              @click="showModal"
                            >Learn more</a>
                          </div>
                        </label>
                        <label class="gds-radio">
                          <input v-model="isBudgetBilling" :value="false" type="radio" />
                          <span class="gds-radio__faux"></span>
                          <span class="gds-font-demi">Pay based on usage</span>
                          <div class="enrollment-details-item">Monthly energy usage costs for this account typically range from ${{ billingHistoryMin }}–${{ billingHistoryMax }}.</div>
                        </label>
                      </div>

                      <div v-if="!budgetBillingStatus.isEligible && !isAlreadyBudgetBillingEnrolled">
                        <label class="gds-radio disabled">
                          <input type="radio" />
                          <span class="gds-radio__faux--disabled"></span>
                          <span class="gds-font-demi">Pay same amount each month</span>
                          <div class="enrollment-details-item">This account is not eligible for Budget Billing.</div>
                        </label>
                        <label class="gds-radio">
                          <input type="radio" checked />
                          <span class="gds-radio__faux"></span>
                          <span class="gds-font-demi">Pay based on usage</span>
                          <div class="enrollment-details-item">Monthly energy usage costs for this account typically range from ${{ billingHistoryMin }}–${{ billingHistoryMax }}.</div>
                        </label>
                      </div>

                      <div v-if="!budgetBillingStatus.isEligible && isAlreadyBudgetBillingEnrolled">
                        <label class="gds-radio gds-space-stack-m">
                          <input type="radio" checked />
                          <span class="gds-radio__faux"></span>
                          <span class="gds-font-demi">Pay same amount each month</span>
                          <div class="enrollment-details-item">This account is already enrolled in Budget Billing.</div>
                        </label>
                        <label class="gds-radio disabled">
                          <input type="radio" />
                          <span class="gds-radio__faux--disabled"></span>
                          <span class="gds-font-demi">Pay based on usage</span>
                          <div class="enrollment-details-item">Monthly energy usage costs for this account typically range from ${{ billingHistoryMin }}–${{ billingHistoryMax }}.</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <button @click="finishPage('page1', 2)" class="gds-button gds-compact">Next</button>
          </div>
        </div>
      </section>

      <!-- Slat 2 -->
      <section id="slat-2" class="payment-amount bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="bill-pay-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Set Up Monthly Payment</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="selectedWallet.accountNickName" class="gds-body-normal gds-space-inline-m">{{ selectedWallet.accountNickName + " ******" + selectedWallet.debitAccount }}</div>
                <button @click="gotoPage(2)" class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 2" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2 && !isAlreadyRecurringPaymentEnrolled" class="past-due-wizard-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row">
                <div
                  class="gds-body-normal gds-space-stack-l"
                >To enroll your installment plan in monthly recurring auto payments, please enter your payment details. Payments are made 5 days before the due date. If you do not want to enroll in auto recurring monthly payments, please select "Do not set up recurring payments".</div>
                <form class="bill-pay-login__form" @submit.prevent role="radiogroup">
                  <fieldset class="gds-fieldset gds-space-inset-s">
                    <wallet-selector v-for="wallet of userWallets" :key="wallet.walletId" :value="wallet" v-model="selectedWallet" :isNew="wallet.isNew" />
                  </fieldset>
                </form>
                <div v-if="selectedWallet && !selectedWallet.isNew && selectedWallet.paymentType === 'CC'" class="gds-space-stack-l">
                  <form data-vv-scope="page2VerifyCC" @submit.prevent>
                    <div class="bill-pay-slat__inner-row gds-flex-container gds-flex-container--top gds-flex-container--space-between gds-flex-container--wrap">
                      <div class="bill-pay__existing-payment-method--left">
                        <div
                          class="bill-pay__existing-payment-description gds-space-stack-l"
                        >For security, please enter the CVV and billing ZIP Code associated with this card. Also, please enter an email address where you would like payment confirmation emails sent.</div>
                        <fieldset class="bill-pay-slat__cvv gds-fieldset gds-space-stack-m">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">CVV</span>
                            <input
                              v-model="selectedWallet.cvv"
                              v-mask="'####'"
                              v-validate="'required|numeric|min:3|max:4'"
                              name="CVV"
                              key="CVV"
                              class="bill-pay-slat__small-input gds-input-field__input"
                              type="tel"
                              maxlength="4"
                              autocomplete="cc-csc"
                              ref="focuser3"
                            />
                            <div v-if="errors.first('CVV', 'page2VerifyCC')" class="validation-error">{{ errors.first("CVV", "page2VerifyCC") }}</div>
                          </label>
                        </fieldset>
                        <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-space-stack-m">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Billing ZIP Code</span>
                            <input
                              v-model="debitZip"
                              v-mask="'#####'"
                              v-validate="'required|numeric|min:5|max:5'"
                              name="billing zip"
                              key="billing zip"
                              class="bill-pay-slat__small-input gds-input-field__input"
                              type="tel"
                              maxlength="5"
                            />
                          </label>
                          <div v-if="errors.first('billing zip', 'page2VerifyCC')" class="validation-error">{{ errors.first("billing zip", "page2VerifyCC")}}</div>
                        </fieldset>
                        <fieldset class="bill-pay-slat__small-flex gds-fieldset">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Email for sending payment confirmations</span>
                            <input
                              v-model="recurringEmailAddress"
                              v-validate="'required|email|max:74'"
                              maxlength="74"
                              name="email address"
                              key="email address"
                              @change="touched = true"
                              class="past-due-wizard-input-field__input gds-input-field__input"
                              type="email"
                              placeholder="Your email"
                              autocomplete="email"
                            />
                          </label>
                          <div v-if="errors.first('email address', 'page2VerifyCC')" class="validation-error">{{ errors.first("email address", 'page2VerifyCC')}}</div>
                        </fieldset>
                      </div>
                    </div>
                    <!--/end flex container -->
                  </form>
                </div>
                <div v-if="selectedWallet.other && selectedWallet.isNew">
                  <editor-wallet-new :showTitle="false" :showCancelButton="false" @complete="refreshWallets" />
                </div>
              </div>
              <button v-if="selectedWallet.paymentType === 'CC'" @click="finishPage('page2VerifyCC', 3)" class="gds-button gds-compact">Next</button>
              <button v-if="!selectedWallet.isNew && selectedWallet.paymentType !== 'CC'" @click="finishPage('page2NewPayment', 3)" class="gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- Already enrolled in recurring payments -->
          <div v-if="currentPage === 2 && isAlreadyRecurringPaymentEnrolled" class="past-due-wizard-slat__contents">
            <div class="gds-font-demi gds-display-00 gds-space-stack-m">This account is already enrolled in recurring auto payments</div>
            <div v-if="isAlreadyRecurringPaymentEnrolled">
              <div class="gds-display-00 gds-space-stack-s">Payment Method:</div>
              <div class="gds-body-small gds-space-stack-l">
                {{ selectedWallet.accountNickName }}
                <br />
                {{selectedWallet | walletSlug}}
              </div>
            </div>
            <button @click="finishPage('page2AlreadyEnrolled', 3)" class="gds-button gds-compact">Next</button>
          </div>
        </div>
      </section>

      <!-- Slat 3 -->
      <section id="slat-3" class="payment-amount bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="bill-pay-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Review & Submit</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m"></div>
                <button @click="gotoPage(3)" class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="past-due-wizard-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <div class="gds-display-00 gds-space-stack-s">GMP Account</div>
                <div class="gds-body-small gds-space-stack-s">{{ `#${currentAccount.accountNumber}` }}</div>
                <div class="gds-body-small gds-space-stack-l">{{ currentAccount.address | addressFormat({ separator: ", " }) }}</div>
                <div class="gds-display-00 gds-space-stack-s">Account Balance</div>
                <div class="gds-body-small gds-space-stack-l">{{ currentAccount.amountDue | currency }}</div>
                <br />

                <div class="gds-display-00 gds-space-stack-s">Payment Details</div>

                <div v-if="isRecurringPayments || isAlreadyRecurringPaymentEnrolled">
                  <div class="gds-body-small gds-space-stack-l">
                    <div
                      v-if="isBudgetBilling"
                      class="gds-space-stack-m"
                    >No payment will be made today. Your recurring auto payment will be made on or around {{ firstRecurringPaymentDueDate }} for {{ totalBillAmount | currency }}.</div>
                    <div
                      v-if="!isBudgetBilling"
                      class="gds-space-stack-m"
                    >No payment will be made today. Your recurring auto payment will be made on or around {{ firstRecurringPaymentDueDate }}.</div>
                    <div class="gds-space-stack-m">This includes:</div>
                    <ul>
                      <li class="gds-space-stack-s">{{ selectedOption.money }}/mo to pay off current balance over the next {{ selectedOption.periods }} months</li>
                      <li
                        v-if="isBudgetBilling || isAlreadyBudgetBillingEnrolled"
                      >${{ this.budgetBillingStatus.totalBudgetAmount }}/mo Budget Billing payment for ongoing usage each month. GMP reviews this budget amount quarterly and it may change up or down consistent with actual electricity used. We'll send you a letter to notify you about a change before the budget amount is adjusted.</li>
                      <li v-if="!isBudgetBilling && !isAlreadyBudgetBillingEnrolled">Payment for usage and other charges due at that time.</li>
                    </ul>
                  </div>
                  <div class="gds-display-00 gds-space-stack-s">Payment Method for Recurring Auto Payments</div>
                  <div class="gds-body-small gds-space-stack-l">
                    {{this.selectedWallet.accountNickName}}
                    <br />
                    {{selectedWallet | walletSlug}}
                  </div>
                </div>

                <div v-if="!isRecurringPayments && !isAlreadyRecurringPaymentEnrolled">
                  <div class="gds-body-small gds-space-stack-l">
                    <div
                      v-if="isBudgetBilling"
                      class="gds-body-small gds-space-stack-m"
                    >No payment will be made today. Your next payment will be due on or before {{ amountDueDatePlusOneMonth }} for {{ totalBillAmount | currency }}.</div>
                    <div
                      v-if="!isBudgetBilling"
                      class="gds-body-small gds-space-stack-m"
                    >No payment will be made today. Your next payment will be due on or before {{ amountDueDatePlusOneMonth }}.</div>
                    <div class="gds-space-stack-m">This includes:</div>
                    <ul>
                      <li class="gds-space-stack-s">{{ selectedOption.money }}/mo to pay off current balance over the next {{ selectedOption.periods }} months</li>
                      <li
                        v-if="isBudgetBilling"
                      >${{ this.budgetBillingStatus.totalBudgetAmount }}/mo Budget Billing payment for ongoing usage each month. GMP reviews this budget amount quarterly and it may change up or down consistent with actual electricity used. We'll send you a letter to notify you about a change before the budget amount is adjusted.</li>
                      <li v-if="!isBudgetBilling">Payment for usage and other charges due at that time.</li>
                    </ul>
                  </div>
                  <div class="gds-display-00 gds-space-stack-s">Payment Method for Recurring Auto Payments</div>
                  <div class="gds-body-small gds-space-stack-l">You elected not to set up recurring payments. Please make a payment before the due date.</div>
                </div>

                <div class="gds-display-00 gds-space-stack-s">Terms</div>
                <div :class="termsExpanded ? 'gds-body-small gds-space-stack-l' : 'gds-body-small gds-space-stack-m' ">
                  You are about to enter into a Payment Arrangement which is a financial agreement between you and GMP. You will not make a payment today. Your current balance of {{ currentAccount.amountDue | currency }} will be divided into {{ selectedOption.periods }} interest-free payments of {{ selectedOption.money }}. Beginning next month, please make the first of
                  <span
                    v-if="!termsExpanded"
                  >...</span>
                  <span id="terms-conditions-expanded">
                    these payments along with your normal bill amount from your energy statement. This total will appear as the amount due on your next bill. No payment is required today, and any potential pending disconnect process will be cancelled.
                    <br />
                    <br />
                    <p>You agree to pay your future bills in full before the bill due date. If the “Total amount due”, as printed in the blue box on your monthly energy statement, is not received by the due date and you are disconnected, GMP has the right (subject to Vermont disconnection rules) to collect the full amount due and assess a deposit on the account before power can be restored.</p>
                    <p
                      v-if="isBudgetBilling"
                    >Additionally, you will be enrolled in Budget Billing. Your consistent monthly payment of ${{ this.budgetBillingStatus.totalBudgetAmount }}/mo for ongoing usage is based on your average usage. If your average usage changes then your Budget Billing is also subject to change. If you stop Budget Billing, your next bill will include an adjustment to true up your account with your actual balance. This is a way to smooth out payments offering you predictability and conveinence so you can plan. This is not a discount.</p>
                  </span>
                </div>
                <div class="gds-space-stack-l">
                  <span v-if="!termsExpanded" @click="expandTerms" class="gds-display-00 gds-text-green gds-clickable">Show All</span>
                </div>

                <div class="gds-space-stack-xl">
                  <label class="gds-checkbox agreeCheckBox">
                    <input v-model="termsAgreed" type="checkbox" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-checkbox__label smallWidth">
                      <span v-if="!isBudgetBilling">I agree to the payment arrangement.</span>
                      <span v-if="isBudgetBilling">I agree to the payment arrangement and Budget Billing terms.</span>
                    </span>
                  </label>
                </div>

                <button @click="handleSubmit()" :disabled="!termsAgreed ? true : false" class="gds-button gds-compact">
                  <span class="gds-button__text">Submit</span>
                  <div v-if="pendingSubmit" class="bill-pay__page-loader">
                    <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { format, addMonths, addDays } from "date-fns";
import GMPAPI from "../../services/gmpapi";
import { ToServerDateTruncate, DumpError } from "../../utilities";
import WalletSelector from "../generic/WalletSelector.vue";
import { ErrorBag } from "vee-validate";
import { InterpretSpeedpayCode } from '../../speedpay';
import ModalBudgetBillingTerms from './ModalBudgetBillingTerms.vue';
import EditorWalletNew from '../myaccount/editors/EditorWalletNew.vue';
import BannerOneAction from "../BannerOneAction.vue"

export default {
  name: "PastDueWizardLoggedIn",
  components: {
    WalletSelector,
    ModalBudgetBillingTerms,
    EditorWalletNew,
    BannerOneAction,
  },
  props: {
    paymentArrangementStatus: Object,
    budgetBillingStatus: Object,
    isAlreadyRecurringPaymentEnrolled: Boolean,
  },
  data() {
    return {
      loadState: undefined,
      pendingSubmit: undefined,

      isExpanded: undefined,
      selectedOption: undefined,

      billingHistory: undefined,
      billingHistoryMax: undefined,
      billingHistoryMin: undefined,

      userWallets: [],
      selectedWallet: undefined,
      paymentName: undefined,

      creditNumber: undefined,
      bankNumber: undefined,

      // cvv: undefined,
      verifyCVV: undefined,
      debitZip: undefined,

      recurringEmailAddress: undefined,

      firstValidCard: format(addMonths(new Date(), -1), "MM/yy"),

      termsAgreed: undefined,
      termsExpanded: undefined,

      isBudgetBillingEligible: undefined,
      isAlreadyBudgetBillingEnrolled: undefined,
      isBudgetBilling: true,

      isRecurringPayments: undefined,

      budgetBillingSuccess: undefined,
      recurringPaymentSuccess: undefined,
      paymentArrangementSuccess: undefined,
      errorSave: undefined,

      showBudgetBillingTerms: false,
      body: {
        success: undefined,
        originalBalance: undefined,
        paymentArrangement: {
          enabled: undefined,
          months: undefined,
          amount: undefined
        },
        budgetBilling: {
          enabled: undefined,
          amount: undefined
        },
        recurring: {
          enabled: undefined,
          type: undefined,
          nickname: undefined,
          lastFour: undefined,
          firstPaymentDate: undefined
        }
      }
    };
  },
  watch: {
    termsExpanded: (termsExpanded) => {
      if (termsExpanded) {
        document.querySelector('#terms-conditions-expanded').style.display = "inline";
      } else {
        document.querySelector('#terms-conditions-expanded').style.display = "none";
      }
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    totalBillAmount() {
      return this.budgetBillingStatus.totalBudgetAmount + Number(this.selectedOption.money.replace(/[^0-9.-]+/g, ""));
    },
    arrangementOptions() {
      return this.paymentArrangementStatus.arrangementOptions.map(opt => {
        // Period data only comes as a full string so we have to parse it out
        const matches = opt.detail.match(/(\S+) added to your bill for (\d+) billing periods/);
        return {
          type: opt.type,
          money: matches[1],
          periods: matches[2],
        };
      });
    },
    amountDueDatePlusOneMonth() {
      let date = new Date(this.currentAccount.amountDueDate)
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      date.setMonth(date.getMonth() + 1)
      return date.toLocaleDateString(undefined, options)
    },
    firstRecurringPaymentDueDate() {
      let date = new Date(this.currentAccount.amountDueDate)
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      date.setMonth(date.getMonth() + 1)
      date.setDate(date.getDate() - 5)
      return date.toLocaleDateString(undefined, options)
    }
  },
  created() {
  },
  async mounted() {
    this.isAlreadyBudgetBillingEnrolled = this.budgetBillingStatus.isEnrolled;
    this.isBudgetBillingEligible = this.budgetBillingStatus.isEligible;
    this.isBudgetBilling = this.isBudgetBillingEligible;

    // Kick off async fetches
    try {
      this.loadState = undefined;
      await Promise.all([
        this.fetchPaymentMeta(),
        this.fetchBillingHistory(),
        this.fetchUserWallets(),
      ]);
      this.loadState = "complete";
    } catch (err) {
      DumpError("Past Due Quick Setup load error", err);
      this.loadState = "error";
    }
    for (let i = 0; i < this.arrangementOptions.length; i++) {
      if (this.arrangementOptions[i].type === "PA-24INSTLMNT") {
        this.selectedOption = this.arrangementOptions[i];
        return
      } else if (this.arrangementOptions[i].type === "PA-18INSTLMNT") {
        this.selectedOption = this.arrangementOptions[i];
        return
      } else if (this.arrangementOptions[i].type === "PA-15INSTLMNT") {
        this.selectedOption = this.arrangementOptions[i];
        return
      } else if (this.arrangementOptions[i].type === "PA-12INSTLMNT") {
        this.selectedOption = this.arrangementOptions[i];
        return
      }
    }
  },
  methods: {
    /** Go to a page number without validation */
    gotoPage(page) {
      this.isKeyboarding = false;
      this.termsExpanded = undefined;
      this.$router.push({ path: "/wizard/" + page });
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        const focuser = this.$refs["focuser" + page];
        if (focuser && focuser.$el) {
          if (focuser.$el.focus) focuser.$el.focus();
        } else if (focuser && Array.isArray(focuser) && focuser.length >= 1) {
          // Page 2 has array of wallets
          if (focuser[0].focus) focuser[0].focus();
        } else if (focuser) {
          if (focuser.focus) focuser.focus();
        }
      }, 100);
    },

    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      let currentPage = nextPage - 1;
      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page2DoNotSetupRecurringPayments":
            if (this.selectedWallet.other) {
              this.isRecurringPayments = false;
            }
            break;
          case "page2NewPayment":
            break;
          case "page2VerifyCC":
            if (!this.errors) this.errors = new ErrorBag();
            break;
        }
        if (this.selectedWallet.other || this.isAlreadyRecurringPaymentEnrolled) {
          this.isRecurringPayments = false;
        } else {
          this.isRecurringPayments = true;
        }
        if (this.errors.any(pageName)) {
          this.slatFocus(currentPage);
        } else {
          this.gotoPage(nextPage);
        }
      }
    },
    expandBox() {
      this.isExpanded = !this.isExpanded
    },
    async fetchPaymentMeta() {
      const meta = await GMPAPI.GetPaymentMeta();
      this.paymentMeta = meta;
    },
    refreshWallets() {
      this.fetchUserWallets();
    },

    async fetchUserWallets() {
      this.userWallets = undefined;
      const wallets = await GMPAPI.GetWallets(this.currentAccount.accountNumber);
      // Add "new" options
      wallets.push({
        other: true,
        isNew: true,
        text: "Set up new payment method",
        accountNickName: ""
      });
      wallets.push({
        other: true,
        none: true,
        text: "Do not set up recurring payments",
        accountNickName: ""
      });
      this.userWallets = wallets;
      // Initialize selected to first
      this.selectedWallet = wallets[0];
    },
    expandTerms() {
      this.termsExpanded = true;
    },
    async fetchBillingHistory() {
      const start = addDays(new Date(), -365);
      const end = addDays(new Date(), 1);
      const acct = this.currentAccount.accountNumber;
      const billingHistory = await GMPAPI.GetAccountTransactions(acct, ToServerDateTruncate(start), ToServerDateTruncate(end));
      this.billingHistory = billingHistory;
      this.setRange();
    },
    setRange() {
      let billAmounts = [];
      this.billingHistoryMax = 0;
      this.billingHistoryMin = 0;

      for (let i = 0; i < this.billingHistory.length; i++) {
        if (this.billingHistory[i].type === "Bill Segment") {
          for (let j = 0; j < this.billingHistory[i].details.length; j++) {
            if (this.billingHistory[i].details[j].type === "Electric Service") {
              billAmounts.push(this.billingHistory[i].details[j].amount)
            }
          }
        }
        if (billAmounts.length > 0) {
          this.billingHistoryMax = Math.floor(Math.max(...billAmounts));
          this.billingHistoryMin = Math.floor(Math.min(...billAmounts));
        }
      }
    },
    showModal() {
      this.showBudgetBillingTerms = true;
    },
    closeModal() {
      this.showBudgetBillingTerms = false;
    },
    async CreateBudget() {
      if (this.isBudgetBilling) {
        try {
          this.budgetBillingSuccess = true;
          this.body.budgetBilling.enabled = true;
          this.body.budgetBilling.amount = this.$options.filters.currency(this.budgetBillingStatus.totalBudgetAmount);
          await GMPAPI.CreateBudgetBilling(this.currentAccount.accountNumber);
        } catch (err) {
          DumpError("Create budget error", err);
          this.budgetBillingSuccess = false;
          this.body.budgetBilling.enabled = false;
        }
      } else if (!this.isBudgetBilling) {
        this.budgetBillingSuccess = false;
        this.body.budgetBilling.enabled = false;
      }
    },
    async CreateRecurring() {
      if (this.isRecurringPayments) {
        try {
          if (!this.selectedWallet) {
            this.errorSave = "You must select a payment method";
            return;
          }
          const userinfo = this.$store.state.user.userinfo;
          const customerInformation = {
            // debitName: this.selectedWallet.debitName || userinfo.fullName,
            debitFirstName: userinfo.givenName,
            debitLastName: userinfo.surname,
            // Account
            creditFirstname: userinfo.givenName,
            creditLastname: userinfo.surname,
            phone: userinfo.phone,
            // Populate all location fields from account, should be required but ignored
            debitAddress1: this.currentAccount.address.street1,
            debitCity: this.currentAccount.address.city,
            debitState: this.currentAccount.address.state,
            debitZip: this.debitZip || this.currentAccount.address.zip,
            creditCity: this.currentAccount.address.city,
            creditState: this.currentAccount.address.state,
            creditZip: this.currentAccount.address.zip,
          };
          let debitInformation;
          if (this.selectedWallet.paymentType === "CC") {
            debitInformation = { cvv: this.selectedWallet.cvv };
          }
          this.recurringPaymentSuccess = true;
          await GMPAPI.SetRecurring(this.currentAccount.accountNumber, this.selectedWallet.walletId, this.recurringEmailAddress, customerInformation, debitInformation);
          this.body.recurring.enabled = true;
          this.body.recurring.type = this.selectedWallet.paymentType;
          this.body.recurring.nickname = this.selectedWallet.accountNickName;
          this.body.recurring.lastFour = this.selectedWallet.debitAccount;
          this.body.recurring.firstPaymentDate = this.firstRecurringPaymentDueDate;
        } catch (err) {
          DumpError("Save recurring error", err);
          this.recurringPaymentSuccess = false;
          let code;
          if (err.response && err.response.data) {
            code = err.response.data.code;
            if (err.response.data.message) {
              console.error(code + ": " + err.response.data.message);
            }
          }
          // Take specific actions on certain error codes here
          const parsed = InterpretSpeedpayCode(code);
          if (parsed) {
            if (parsed.field) {
              // This is a field-specific error
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add(parsed);
            } else {
              // This is a generic error
              this.errorSave = parsed.msg;
            }
          } else {
            // Error not recognized or no response data
            this.errorSave = "There was an unexpected error saving your payment method.";
          }
          this.body.recurring.enabled = false
          throw err
        }
      } else if (!this.isRecurringPayments) {
        this.recurringPaymentSuccess = true;
        this.body.recurring.enabled = false
      }
    },
    async CreatePaymentArrangement() {
      try {
        this.paymentArrangementSuccess = true;
        await GMPAPI.UpdatePaymentArrangement(this.currentAccount.accountNumber, this.selectedOption.type);
        this.body.paymentArrangement.enabled = true;
        this.body.paymentArrangement.months = this.selectedOption.periods;
        this.body.paymentArrangement.amount = this.selectedOption.money;
      } catch (err) {
        DumpError("Start payarrange error", err);
        this.paymentArrangementSuccess = false;
        this.body.paymentArrangement.enabled = false;
      }
    },
    selectedNicknameIsDuplicate() {
      const matcher = this.paymentName.toLowerCase();
      for (const wallet of this.userWallets) {
        if (wallet.accountNickName.toLowerCase() === matcher) return true;
      }
      return false;
    },
    async DeleteWallet() {
      await GMPAPI.DeleteWallet(this.currentAccount.accountNumber, this.selectedWallet.walletId);
    },
    async handleSubmit() {
      this.pendingSubmit = true;
      try {
        await GMPAPI.StartPastDueWizard(this.currentAccount.accountNumber);
      } catch (error) {
        // Swallow Error
      }
      try {
        await this.CreateRecurring();
        await this.CreatePaymentArrangement();
        if (this.paymentArrangementSuccess) {
          await this.CreateBudget();
        }
        try {
          this.body.originalBalance = this.$options.filters.currency(this.currentAccount.amountDue);
          GMPAPI.CompletePastDueWizard(this.currentAccount.accountNumber, this.body);
        } catch (error) {
          // Swallow Error
        }
        this.$router.push({
          name: "complete", params: {
            isRecurringPayments: this.isRecurringPayments,
            isBudgetBilling: this.isBudgetBilling,
            recurringPaymentSuccess: this.recurringPaymentSuccess,
            paymentArrangementSuccess: this.paymentArrangementSuccess,
            budgetBillingSuccess: this.budgetBillingSuccess,
            currentAccount: this.currentAccount
          }
        });
      } catch (err) {
        DumpError("Past Due Wizard Submission error", err);
        if (err.response && err.response.data) {
          const code = err.response.data.code;
          if (err.response.data.message) {
            if (!this.errors) this.errors = new ErrorBag();
            // Take specific actions on certain error codes here
            switch (code) {
              case 1006:
              case 6014:
              case 18033:
              case 18076:
                if (!this.errors) this.errors = new ErrorBag();
                this.errors.add({ field: "CVV", msg: "Please enter a valid CVV.", scope: "page2VerifyCC" });
                this.gotoPage(2)
                break;

              // Any other error code gets redirected to the "fatal" error page
              default:
                this.$router.push({ path: "/error/" + code });
                break;
            }
          }
        }
      }
      this.pendingSubmit = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>