<template>
  <div class="my-account__login-page-wrap">
    <section v-if="!resetSent" class="my-account__login-form-wrap">
      <div class="gds-display-2 gds-space-stack-m">Reset Password</div>
      <form class="global-panel__form" @submit.prevent="Submit()">
        <fieldset class="gds-fieldset gds-space-stack-m">
          <label class="gds-input-field">
            <span class="gds-input-field__label">New Password</span>
            <widget-password-input
              ref="password"
              v-model="password1"
              v-validate.initial.continues="'required|length:7,30|hasupperandlower|hasnumber'"
              data-vv-validate-on="input"
              name="password"
              key="password"
              placeholder="Enter new password..."
            />
          </label>
          <password-requirements :validationErrors="errors" />
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-m">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Confirm New Password</span>
            <widget-password-input
              v-model="password2"
              v-validate="'required|confirmed:password'"
              data-vv-validate-on="input"
              name="password confirmation"
              key="password confirmation"
              placeholder="Re-enter new password..."
            />
          </label>
        </fieldset>
        <div v-if="errors.first('password confirmation')" class="validation-error">{{ errors.first("password confirmation")}}</div>

        <div class="gds-space-stack-l">
          <div v-if="errorSubmit" id="submission_error" class="gds-helper-text-error">{{errorSubmit}}</div>
        </div>

        <div class="gds-space-stack-m">
          <button :disabled="pendingSubmit" :class="{ 'gds-loading': pendingSubmit }" type="submit" class="gds-button">
            <span class="gds-button__text">Reset password</span>
          </button>
        </div>
      </form>
    </section>
    <section v-if="resetSent" class="my-account__login-form-wrap gds-align--text-center">
      <div class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/message-sent.svg" alt="password reset sent" />
      </div>
      <div class="gds-display-2 gds-space-stack-m">Your password was reset successfully</div>
      <div class="gds-font-size-l gds-space-stack-l">You will be returned to the homepage, where you can log in with your new password.</div>

      <div class="gds-space-stack-m global-panel__form">
        <a href="/" class="gds-button">Done</a>
      </div>
    </section>
  </div>
</template>

<script>
import WidgetPasswordInput from "../WidgetPasswordInput";
import PasswordRequirements from "../generic/PasswordRequirements";
import GMPAPI from "../../services/gmpapi";

export default {
  name: "PageResetPassword",
  components: {
    WidgetPasswordInput,
    PasswordRequirements,
  },
  data() {
    return {
      password1: undefined,
      password2: undefined,

      resetSent: false,

      errorSubmit: undefined,
      pendingSubmit: false,
    };
  },
  computed: {
  },
  async mounted() {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.code = params.get("sptoken");
  },
  methods: {
    async Submit() {
      if (await this.$validator.validateAll()) {
        this.errorSubmit = undefined;
        this.pendingSubmit = true;
        try {
          await GMPAPI.UpdateResetPassword(this.code, this.password1);
          this.resetSent = true;
        } catch (err) {
          this.errorSubmit = "Unable to reset password";
        } finally {
          this.pendingSubmit = false;
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>