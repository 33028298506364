<template>
  <div class="make-payment-container">
    <div id="top-target" class="eicproduct-success-fail">
      <div class="bill-pay-fail__intro-image gds-space-stack-xl">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-fatal-error.svg" alt="unsuccessful payment" />
      </div>

      <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Oh no!</h2>
      <div v-if="code === 1" class="error-1002 form-message-box gds-space-stack-l gds-align--text-center">No data matching the search criteria was found.</div>
      <div v-if="code === 15" class="error-1002 form-message-box gds-space-stack-l gds-align--text-center">You do not have permission to perform the requested action.</div>
      <div v-if="code === 30" class="error-1002 form-message-box gds-space-stack-l gds-align--text-center">The account number entered was not valid.</div>
      <div v-if="code === 62" class="error-1002 form-message-box gds-space-stack-l gds-align--text-center">The input did not pass data validation.</div>
      <div v-if="code === 75" class="error-1002 form-message-box gds-space-stack-l gds-align--text-center">The product type is invalid.</div>

      <div v-if="code === 500" class="error-1002 form-message-box gds-space-stack-l gds-align--text-center">An error occurred with the web service.</div>
      <div v-if="code === 140" class="error-1002 form-message-box gds-space-stack-l gds-align--text-center">An error occurred with the web service.</div>

      <div class="bill-pay-fail__description gds-body-large gds-align--text-center gds-space-stack-xl">
        We were unable to process your request to participate in the EV charger program at this time. Please try again or contact us for help at
        <span class="gds-nobreak">{{GMPPhoneNumber || '1-(888)-835-4672'}}.</span>
        <br />
      </div>

      <div class="bill-pay-success__button-wrap gds-align--text-center gds-space-stack-l">
        <!-- <router-link to="/pay/1" class="bill-pay-success__button gds-button gds-secondary gds-space-inline-s">Back</router-link> -->
        <button @click="ReturnToSender('/')" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../../environment";
import { ReturnToSender } from "../../../utilities";

export default {
  name: "EVChargerFatalError",
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  computed: {
    code() {
      return Number(this.$route.params.code);
    }
  },
  async mounted() {
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>