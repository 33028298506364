import { forEach, toggleClass, enableLinkTabbing, disableLinkTabbing, getURLParam, copyToClipboard } from '../functions';

export default function slats() {
  //find all expandable slats in the page
  var slats = document.querySelectorAll('.js-slat-group--expanding .slat');

  // if window is resized, the slat open height might need to be re-calculated.
  window.addEventListener("resize", function () {
    var activeContents = document.querySelectorAll('.slat--expanded .slat__content');
    [].forEach.call(activeContents, function (slatContents) {
      slatContents.style.maxHeight = (slatContents.scrollHeight) + "px";
    });
  });

  [].forEach.call(slats, function (slat) {
    var slatButton = slat.querySelector('.slat__header');

    //make all links within an expanded slat non-tabbable
    disableLinkTabbing(slat);

    slatButton.addEventListener('click', function () {
      toggleSlat(slat);
    });
  });

  var linkedSlatGroups = document.querySelectorAll('.slat-group:not(.js-slat-group--expanding)');
  [].forEach.call(linkedSlatGroups, function (slatGroup) {
    var slats = slatGroup.querySelectorAll('.slat');
    [].forEach.call(slats, function (slat) {
      var slatHeaders = slat.querySelector('.slat__header');
      var slatButtons = slat.querySelector('.slat__button');
      slatHeaders.addEventListener('click', function () {
        slatButtons.click();
      });
    });
  });

  //Open shared slats
  var shared = getURLParam('share');
  if (null != shared) {
    var sharedBlock = document.querySelector('#' + shared);
    if (null != sharedBlock) {
      toggleSlat(sharedBlock);
      sharedBlock.scrollIntoView();
    }
  }

  jQuery('[data-action="copyToClipboard"]').click(function (event) {
    event.preventDefault()
    copyToClipboard(jQuery(this).data('value'));
  });
}

function toggleSlat(slat) {
  var slatContents = slat.querySelector('.slat__content');

  if (slat.classList.contains('slat--expanded')) {
    //collapses expanded slat
    slatContents.style.maxHeight = 0 + "px";
    setTimeout(function () {
      slat.classList.remove('slat--expanded');
    }, 175);
    slatContents.style.maxHeight = null;
    disableLinkTabbing(slat); //makes the links within the now closed slat non-tabbable
  } else {
    //expands collapsed slat
    slat.classList.toggle('slat--expanded');
    slatContents.style.maxHeight = (slatContents.scrollHeight) + "px";
    enableLinkTabbing(slat); //make the links in the now open slat tabbable
  }
}
