<template>
  <div>
    <div v-if="pendingSelect" class="ghost__loading-full" />
    <section class="ghost__top-container ghost__container">
      <h1 class="gds-display-2 gds-space-stack-m">Account Ghosting</h1>
      <form @submit.prevent="Search" class="gds-space-stack-ml gds-flex-container gds-flex-container--nowrap gds-flex-container--stretch">
        <input v-model="searchText" class="ghost__search-input gds-input-field__input gds-space-inline-m" type="text" placeholder="Enter search text" />
        <button :disabled="pendingSearch || !searchText" :class="{ 'gds-loading': pendingSearch }" type="submit" class="ghost__search-button gds-button">
          <span class="gds-button__text">Search</span>
        </button>
      </form>

      <div v-if="errorSearch" class="form-message-box form-message-box--full-width gds-space-stack-m">{{errorSearch}}</div>
      <div v-if="errorSelect" class="form-message-box form-message-box--full-width gds-space-stack-m">{{errorSelect}}</div>
      <div v-if="accounts" class="gds-font-demi gds-text-green">{{accounts.length}} results</div>
    </section>
    <section class="ghost__accounts-table">
      <vue-good-table v-if="accounts && accounts.length" @on-row-click="SelectAccount" :columns="columns" :rows="accounts" styleClass="vgt-table striped ghosting-account-table" />
    </section>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";

export default {
  name: "PageGhostingSearch",
  components: {
  },
  data() {
    return {
      columns: [
        {
          label: "Account Number",
          field: "accountNumber",
          filterOptions: {
            enabled: true,
            placeholder: "Contains...",
          },
          tdClass: "gds-font-demi",
        },
        {
          label: "Name",
          field: "entityName",
          filterOptions: {
            enabled: true,
            placeholder: "Contains...",
          },
        },
        {
          label: "Street Address",
          field: "address1",
          filterOptions: {
            enabled: true,
            placeholder: "Contains...",
          },
        },
        {
          label: "City",
          field: "city",
          filterOptions: {
            enabled: true,
            placeholder: "Contains...",
          },
        },
        {
          label: "Phone",
          field: "phone",
          filterOptions: {
            enabled: true,
            placeholder: "Contains...",
          },
        },
        {
          label: "Email",
          field: "email",
          filterOptions: {
            enabled: true,
            placeholder: "Contains...",
          },
        },
      ],
      searchText: undefined,
      accounts: undefined,

      errorSearch: undefined,
      pendingSearch: false,

      errorSelect: undefined,
      pendingSelect: false,
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    async Search() {
      try {
        this.pendingSearch = true;
        this.errorSearch = undefined;
        const result = await GMPAPI.SearchAccounts(this.searchText);
        this.accounts = result;
      } catch (err) {
        this.errorSearch = "Unable to complete your search";
        DumpError("Ghosting search error", err);
      } finally {
        this.pendingSearch = false;
      }
    },
    async SelectAccount(params) {
      try {
        this.pendingSelect = true;
        this.errorSelect = undefined;
        const account = params.row;
        await this.$store.dispatch("FetchGhostingAccount", account.accountNumber);
        window.location.href = "/account";
      } catch (err) {
        this.errorSelect = "Unable to select that account";
        DumpError("Ghosting select error", err);
      } finally {
        this.pendingSelect = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>