var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-netmetering--dashboard" },
    [
      _c("h1", { staticClass: "my-account__title gds-space-stack-l" }, [
        _vm._v("Net Metering")
      ]),
      _vm._v(" "),
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "usage-page--interior-status-container net-metering" },
        [
          _vm.loadState === "error"
            ? _c("flow-error", {
                attrs: { name: "Net Metering", state: "error" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "unavailable"
            ? _c("flow-error", {
                attrs: {
                  name: "Net Metering",
                  state: "unavailable",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/net-metering-not-supported.svg"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _vm.meteringStatus
              ? _c("div", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gds-display-00 gds-space-stack-s" },
                    [_vm._v("Total Credits Available")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gds-font-book gds-font-size-xl gds-text-green gds-space-stack-l"
                    },
                    [_vm._v(_vm._s(_vm._f("currency")(_vm.totalCredits)))]
                  ),
                  _vm._v(" "),
                  this.meteringStatus.expiringCredits &&
                  this.meteringStatus.expiringCredits.length
                    ? _c("table", { staticClass: "net-meter-table" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          { staticClass: "net-meter-table__body" },
                          _vm._l(this.meteringStatus.expiringCredits, function(
                            credit,
                            index
                          ) {
                            return _c("tr", { key: index }, [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "gds-flex-container gds-flex-container--left"
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "gds-icon gds-space-inline-s"
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-net-meter"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm._f("dateFullUTC")(credit.creditDate)
                                      ) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFullUTC")(credit.expirationDate)
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "gds-font-book gds-font-size-xl gds-text-green gds-align--text-right"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("currency")(-credit.amount))
                                  )
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-l" }, [
      _c("p", [
        _vm._v(
          "\n        Net metering credits are applied towards charges on your bill each month. Vermont law limits which charges can be paid for by your net metering credits. We are prohibited from applying them to loan payments, equipment rentals, and some program fees.\n        "
        ),
        _c("a", { attrs: { href: "/help-category/net-metering/#credits" } }, [
          _vm._v("Learn what net metering credits can cover")
        ]),
        _vm._v(".")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "net-meter-table__head" }, [
      _c("tr", [
        _c("th", [_vm._v("Date Generated")]),
        _vm._v(" "),
        _c("th", [_vm._v("Credit Expires")]),
        _vm._v(" "),
        _c("th", { staticClass: "gds-align--text-right" }, [
          _vm._v("Credit Value")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }