var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-dashboard--dashboard" },
    [
      _c("h1", { staticClass: "my-account__title gds-space-stack-l" }, [
        _vm._v("My Account Dashboard")
      ]),
      _vm._v(" "),
      _c("widget-account-planned-outages"),
      _vm._v(" "),
      _c("widget-scheduled-payments"),
      _vm._v(" "),
      _vm.isSixtyDaysPastDue && !_vm.isRestrictedRebateUser
        ? _c("banner-one-action", {
            attrs: {
              bannerColor: "blue",
              message: "Part of your balance is past due.",
              linkText: "See Assistance Programs",
              url: "/past-due-balance-assistance/"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "my-account__card-section gds-flex-container gds-flex-container--top gds-flex-container--wrap"
        },
        [
          _c("card-account-profile"),
          _vm._v(" "),
          _c("card-report-issue"),
          _vm._v(" "),
          _c("card-pay-bill"),
          _vm._v(" "),
          _c("card-latest-bill"),
          _vm._v(" "),
          _c("card-recent-transactions"),
          _vm._v(" "),
          _c("card-usage"),
          _vm._v(" "),
          _c("card-outages-near-you"),
          _vm._v(" "),
          _c("card-web-chat")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }