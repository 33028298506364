var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "slat-message-fade" } }, [
        _c(
          "div",
          { staticClass: "gds-space-stack-l scheduled-payments-list" },
          [
            _c(
              "div",
              {
                class:
                  "form-message-box form-message-box--" +
                  _vm.bannerColor +
                  " form-message-box--full-width gds-space-stack-s gds-flex-container gds-flex-container--space-between"
              },
              [
                _c("div", { staticClass: "form-message-box--left" }, [
                  _c("div", { staticClass: "gds-body-normal" }, [
                    _vm._v(_vm._s(_vm.message))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-message-box--right" }, [
                  _c("div", { staticStyle: { "text-align": "right" } }, [
                    !_vm.showResultMessage
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "gds-link gds-link--bold cursorPointer",
                            attrs: { href: _vm.url },
                            on: { click: _vm.buttonFunction }
                          },
                          [_vm._v(_vm._s(_vm.linkText))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showResultMessage
                      ? _c("div", { staticClass: "gds-text--bold" }, [
                          _vm._v(_vm._s(_vm.resultMessage))
                        ])
                      : _vm._e()
                  ])
                ])
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }