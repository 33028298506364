import { toggleClass } from '../functions';

export default function usingMouse(){
  // Let the document know when the mouse is being used
  // inspried by facebook and https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press (see facebook example).
  // define focus rules in css using body.using-mouse or :not(body.using-mouse).
  document.body.addEventListener('mousedown', function() {
    document.body.classList.add('using-mouse');
  });

  document.body.addEventListener('keydown', function() {
    document.body.classList.remove('using-mouse');
  });
}
