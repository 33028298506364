<template>
  <section class="settings-editor my-account__full-width-section my-account__section-with-bottom-border">
    <h2 ref="scrolltarget" class="settings-editor__title gds-space-stack-m">Account Photo</h2>
    <div class="gds-flex-container gds-flex-container--left my-account__sub-section-bottom">
      <div class="small-round-img img-large gds-space-inline-default" :style="{backgroundImage: 'url(' + GetResizedAccountPhoto(currentAccount.photoUrl) + ')'}"></div>
      <button v-if="!editing" @click="editing = true" type="button" class="gds-button gds-round gds-secondary gds-compact">Edit</button>
    </div>
    <div v-if="editing">
      <div class="my-account__settings__image-picker-flex gds-flex-container gds-flex-container--left gds-flex-container--top gds-space-stack-m">
        <div class="my-account__settings__image-picker-flex-item">
          <div class="gds-font-size-m gds-font-medium gds-space-stack-s">Pick Icon</div>
          <div class="gds-flex-container gds-flex-container--left">
            <div
              v-for="(iconUrl, index) of defaultIcons"
              :key="index"
              @click="ClickDefault(index)"
              @keyup.enter="ClickDefault(index)"
              :class="{ selected: selectedIcon === index }"
              :style="{ backgroundImage: 'url(' + iconUrl + ')'}"
              class="gds-space-inline-default account-photo-picker"
              tabindex="0"
            />
          </div>
        </div>

        <div>
          <div class="gds-font-size-m gds-font-medium gds-space-stack-s">Custom Image</div>
          <!-- Faux file picker - wraps it in a label and hides the input -->
          <label @keyup.enter="$refs.filePicker.click()" class="gds-file gds-flex-container gds-flex-container--left" tabindex="0">
            <div>
              <div :class="{ selected: selectedIcon === 999 }" :style="{ backgroundImage: 'url(' + customIconData + ')'}" class="account-photo-picker" />
              <input ref="filePicker" @change="FilePicked" :disabled="pendingSave" type="file" accept="image/png, image/jpeg" tabindex="-1" />
            </div>
            <div>
              <div class="gds-font-size-m gds-space-stack-s">Click to browse your device for an image file.</div>
              <div class="gds-font-size-s gds-text-grey">Supports jpg or png files up to 5MB.</div>
            </div>
          </label>
        </div>
      </div>
      <button @click="Close()" type="button" class="gds-button gds-secondary gds-round gds-space-stack-l">
        <span class="gds-button__text">Cancel</span>
      </button>
      <button @click="Save()" :disabled="pendingSave || !touched" :class="{ 'gds-loading': pendingSave }" type="button" class="gds-button gds-round gds-space-stack-l">
        <span class="gds-button__text">Save</span>
      </button>

      <div v-if="errorSave" class="form-message-box gds-space-stack-m">{{errorSave}}</div>
    </div>
  </section>
</template>

<script>
import GMPAPI from '../../../services/gmpapi';
import { DumpError, GetResizedAccountPhoto } from '../../../utilities';

export default {
  name: "EditorAccountPhoto",
  data() {
    return {
      GetResizedAccountPhoto,

      defaultIcons: [
        "/wp-content/themes/gmptwentynineteen/assets/images/default-account.png",
        "/wp-content/themes/gmptwentynineteen/assets/images/default-2.png",
        "/wp-content/themes/gmptwentynineteen/assets/images/default-3.png",
        "/wp-content/themes/gmptwentynineteen/assets/images/default-4.png",
      ],
      // Starts with plus sign
      customIconData: "/wp-content/themes/gmptwentynineteen/assets/images/add-custom-icon.svg",
      customIconFile: undefined,

      selectedIcon: undefined,
      touched: false,
      editing: false,
      pendingSave: false,
      errorSave: undefined,
    };
  },
  components: {
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    // If page was loaded with intent to edit, edit
    if (this.$route.hash === "#photo") {
      this.editing = true;
      await this.$nextTick();
      if (this.$refs.scrolltarget) {
        this.$refs.scrolltarget.scrollIntoView(true);
      }
    }
  },
  methods: {
    ClickDefault(index) {
      this.selectedIcon = index;
      this.touched = true
      this.ResetCustom();
    },
    async Save() {
      this.pendingSave = true;
      this.errorSave = undefined;
      try {
        if (this.selectedIcon >= 0 && this.selectedIcon < 999) {
          const blob = await GMPAPI.GetImageBlob(this.defaultIcons[this.selectedIcon]);
          await GMPAPI.UpdatePhoto(this.userInfo.username, this.currentAccount.accountNumber, blob);
        } else if (this.selectedIcon === 999) {
          await GMPAPI.UpdatePhoto(this.userInfo.username, this.currentAccount.accountNumber, this.customIconFile);
        }
        await this.$store.dispatch("FetchUserInfo");
        this.Close();
      } catch (err) {
        if (err.response && err.response.data && err.response.data.errorCode === "FILE_TOO_LARGE") {
          this.errorSave = "The selected file is too large."
        } else {
          DumpError("Upload photo error", err);
          this.errorSave = "There was an error uploading the file.";
        }
      } finally {
        this.pendingSave = false;
      }
    },
    async FilePicked(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.customIconFile = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.customIconData = e.target.result;
      }
      reader.readAsDataURL(file);
      this.selectedIcon = 999;
      this.touched = true;
    },
    ResetCustom() {
      this.customIconData = "/wp-content/themes/gmptwentynineteen/assets/images/add-custom-icon.svg";
      this.customIconFile = undefined;
      if (this.$refs.filePicker) {
        this.$refs.filePicker.value = "";
      }
    },
    Close() {
      this.touched = false;
      this.selectedIcon = undefined;
      this.touched = false;
      this.pendingSave = false;
      this.errorSave = undefined;
      this.ResetCustom();
      this.editing = false;
    }
  },
  watch: {
    currentAccount() {
      this.Close();
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>