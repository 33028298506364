<template>
  <div class="eicproduct-slat__contents-inner">
    <div class="eicproducts-required-text gds-space-stack-m">
      One more thing! To ensure all Vermonters benefit equitably from the transition to clean energy, we’re asking
      program participants whether you would like to <span><b>voluntarily</b></span> share your household size and income. 
      This helps develop better programs to meet the needs of customers. Your personal information is confidential.
    </div>
    <form data-vv-scope="householdincome">
      <label class="gds-input-field">
        <span class="gds-input-field__label">Are you willing to share information about your
          household income?</span>
        <div class="gds-position--relative">
          <div class="gds-space-stack-l">
            <select @change="onChangeWillShareIncome($event)"
              v-model="productSubmission.rebate.householdIncome.willShareIncome"
              class="eicproduct-input-field__select eicproducts-income_share"
              v-validate="'required'"
              name="household income response"
              key="household income response" autofocus>
              <option value disabled select>Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
      </label>
      <div v-if="errors.first('household income response', 'householdincome')" class="eicproduct-validation-error">{{
        errors.first("household income response", "householdincome")
      }}</div>
      <div
        v-if="productSubmission.rebate.householdIncome.willShareIncome && productSubmission.rebate.householdIncome.willShareIncome.toLowerCase() === 'yes'"
        class="row-s">
        <div class="gds-space-stack-l">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Number Of Household Members</span>
            <div>
              <select
                @change="buildIncomeDropDown(); clearHouseholdIncome(); emitIncomeInfo()"
                v-model="householdIncome.numberInHousehold"
                class="eicproduct-input-field__select"
                v-validate="'required'"
                name="number in household" 
                key="number in household" autofocus>
                <option v-for="(option, index) of metadata.incomeIncentives" :key="index"
                  :value="option.numberInHousehold">{{ option.numberInHousehold }}
                </option>
                <option value disabled select>Select...</option>
              </select>
            </div>
          </label>
          <div v-if="errors.first('number in household', 'householdincome')" class="eicproduct-validation-error"
            style="max-width:80%">{{ errors.first("number in household", "householdincome") }}
          </div>
        </div>
        <label class="gds-input-field">
          <span class="gds-input-field__label">Gross Household Income in {{ lastYear }}</span>
          <select 
            v-model="householdIncome.grossHouseholdIncomeRange"
            @change="emitIncomeInfo"
            class="eicproduct-input-field__select" 
            autofocus 
            v-validate="'required'"
            name="gross household income"
             key="gross household income"
            :disabled="!this.householdIncome.numberInHousehold ? true : false">
            <option v-for="(option, index) of householdIncomeDropdown" :key="index"
              :value="option.incomeAmount">{{ option.incomeOption }}
            </option>
          </select>
          <div v-if="errors.first('gross household income', 'householdincome')"
            class="eicproduct-validation-error">
            {{ errors.first("gross household income", "householdincome") }}</div>
        </label>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: "HouseholdIncome",
  props: {
    metadata: Object,
    productSubmission: Object,
    lastYear: Number,
  },
  data() {
    return {
      householdIncomeDropdown: [],
      householdIncome: {
        numberInHousehold: undefined,
        grossHouseholdIncomeRange: undefined
      }
    }
  },
  async mounted() {
    if (this.productSubmission && this.productSubmission.rebate &&
        this.productSubmission.rebate.householdIncome &&
        this.productSubmission.rebate.householdIncome.numberInHousehold != '') {
      this.householdIncome.numberInHousehold = this.productSubmission.rebate.householdIncome.numberInHousehold;
    }

    if (this.productSubmission && this.productSubmission.rebate && 
        this.productSubmission.rebate.householdIncome &&
        this.productSubmission.rebate.householdIncome.grossHouseholdIncomeRange != '') {
      this.householdIncome.grossHouseholdIncomeRange = this.productSubmission.rebate.householdIncome.grossHouseholdIncomeRange;
    }

    this.buildIncomeDropDown();
  },

  methods: {
    async emitIncomeInfo() {
      this.$emit('household-income-changed', this.householdIncome);
    },
    async onChangeWillShareIncome(event) {
      this.householdIncome.numberInHousehold = undefined;
      this.clearHouseholdIncome();
    },

    async validateAllFields() {
      const validationResult = await this.$validator.validateAll('householdincome');
      return validationResult;
    },

    clearHouseholdIncome() {
      this.householdIncome.grossHouseholdIncomeRange = undefined;
    },

    buildIncomeDropDown() {
      this.metadata.incomeIncentives.sort((a, b) => a.numberInHousehold - b.numberInHousehold);
      const numberInHousehold = this.householdIncome.numberInHousehold;
      if (this.metadata && this.metadata.incomeIncentives.length > 0) {
        const incomeIncentive = this.metadata.incomeIncentives.find(incomeIncentive => incomeIncentive.numberInHousehold === numberInHousehold);

        if (incomeIncentive) {
          const lowerRangeAmount = "$" + incomeIncentive.incomeLimit.toLocaleString() + " or less ";
          const upperRangeAmount = "Greater than $" + incomeIncentive.incomeLimit.toLocaleString();
          this.householdIncomeDropdown = [];
          this.householdIncomeDropdown.push({
            incomeOption: lowerRangeAmount,
            incomeAmount: lowerRangeAmount
          });
          this.householdIncomeDropdown.push({
            incomeOption: upperRangeAmount,
            incomeAmount: upperRangeAmount
          });
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>