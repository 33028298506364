var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-billing-payment--dashboard" },
    [
      _c(
        "h1",
        { staticClass: "my-account__title subpage-dashboards--left-spacer" },
        [_vm._v("Notifications")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "my-account__tab-nav gds-flex-container gds-flex-container--left subpage-dashboards--left-spacer"
        },
        [
          _c(
            "router-link",
            {
              staticClass: "my-account__tab-nav-item",
              attrs: { to: "/notifications" }
            },
            [_vm._v("Overview")]
          ),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "my-account__tab-nav-item",
              attrs: { to: "/notifications/contacts" }
            },
            [_vm._v("Contacts")]
          ),
          _vm._v(" "),
          _vm.isDeviceFeatureEnabled
            ? _c(
                "router-link",
                {
                  staticClass: "my-account__tab-nav-item",
                  attrs: { to: "/notifications/devices" }
                },
                [_vm._v("Mobile Devices")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }