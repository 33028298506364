var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("DateNavigationButtonMobile", {
        staticClass: "usage-detail__date-navigation-item",
        attrs: {
          buttonText: _vm.buttonText,
          disabled: _vm.disabled,
          addBackground: _vm.addBackground,
          buttonClicked: _vm.onButtonClicked
        }
      }),
      _vm._v(" "),
      _vm.modalVisible
        ? _c("UsageDetailSelectMobileModal", {
            attrs: {
              config: _vm.configCopied,
              modalTitle: _vm.modalTitle,
              closeModal: _vm.closeModal,
              selectOption: _vm.onOptionSelected
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }