<template>
  <div id="my-account__sidebar">
    <div class="my-account__sidebar">
      <widget-account-switcher></widget-account-switcher>
      <widget-my-account-nav></widget-my-account-nav>
    </div>
  </div>
</template>

<script>
import WidgetAccountSwitcher from "../WidgetAccountSwitcher.vue";
import WidgetMyAccountNav from "./WidgetMyAccountNav.vue";

// App.component('WidgetMyAccountNav', require('./WidgetMyAccountNav.vue'));
export default {
  name: "WidgetMyAccountSidebar",
  data() {
    return {
    };
  },
  components: {
    WidgetAccountSwitcher,
    WidgetMyAccountNav,
  },
  computed: {

  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>