import DataStore from "../store";
import { PrettyAccountType } from "../utilities";

/** Log user data to analytics at page load */
export function AnalyticsSetUserInfo() {
  if (typeof window.dataLayer === "undefined") {
    console.log("GTag plugin not enabled");
    return;
  }
  const userinfo = DataStore.state.user.userinfo;
  const currentAccount = DataStore.state.user.currentAccount;

  const siteStatus = undefined; // TODO: needs input from WP
  const isOTPLoggedIn = userinfo && !!userinfo.isOTP;
  const customerType = PrettyAccountType(currentAccount);
  const customerLoginStatus = userinfo ? "logged-in" : undefined;
  const isAdmin = userinfo && !!userinfo.isAdmin;
  const isNetMetered = currentAccount && !!currentAccount.solarNetMeter;
  window.dataLayer.push({
    "event": "apiLoaded",
    siteStatus,
    isOTPLoggedIn,
    customerType,
    customerLoginStatus,
    isAdmin,
    isNetMetered,
  });
}

/** Log an event to analytics */
export function AnalyticsLogEvent(event, params) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...params,
  });
}

export function AnalyticsSetProperty(props) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    props
  });
}
