var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "table-bar",
        style: { width: _vm.barWidthTotalPercent + "%" }
      },
      [
        this.series1 > 0 || _vm.isZeroWidth
          ? _c("div", {
              staticClass: "series1",
              style: { width: _vm.barWidthSeries1Percent + "%" }
            })
          : _vm._e(),
        _vm._v(" "),
        this.series2 > 0
          ? _c("div", {
              staticClass: "series2",
              style: { width: _vm.barWidthSeries2Percent + "%" }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }