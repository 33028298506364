var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "button",
      {
        staticClass:
          "view-options-button gds-button gds-compact gds-button-outline",
        attrs: { disabled: _vm.disabled },
        on: {
          click: function($event) {
            return _vm.buttonClicked()
          }
        }
      },
      [_c("span", [_vm._v(_vm._s(_vm.buttonText))])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }