import { DumpError, GetMailingAddressFromVerified, PrintableTransType, InitialCaps, ParseDisconnectDate, DehyphenateTown, GetOutageStatusLabel } from "./utilities";
import { differenceInCalendarDays, format, formatDuration, intervalToDuration, parseISO } from "date-fns";

export default {
  install: (Vue) => {
    Vue.filter("fileSizeFormat", (bytes) => {
      if (bytes < 102400) {
        const totalKB = bytes / 102400;
        return `${Math.round(totalKB * 10) / 10}KB`
      } else {
        const totalMB = bytes / 1048576;
        return `${Math.round(totalMB * 10) / 10}MB`
      }
    });
    Vue.filter("dateDue", (value) => {
      if (!value) return "";
      try {
        const dueDate = ParseDisconnectDate(value);
        const dueInDays = differenceInCalendarDays(dueDate, new Date());
        if (dueInDays === 0) {
          return "Due today";
        }
        if (dueInDays === -1) {
          return "1 day past due";
        }
        if (dueInDays === 1) {
          return "Due in 1 day";
        }
        if (dueInDays < 0) {
          return Math.abs(dueInDays) + " days past due";
        }
        return "Due in " + dueInDays + " days";
      } catch (err) {
        DumpError("DateDue error", err);
        return "";
      }
    });

    // Pretty print a duration in minutes
    Vue.filter("durationMinutes", (value) => {
      if (value === undefined || value === null || value === "") return "";
      const duration = intervalToDuration({ start: 0, end: value * 60000 })
      return formatDuration(duration);
    });

    Vue.filter("dateFullUTC", (value) => {
      if (!value) return "";
      return new Date(value).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric", timeZone: "UTC" });
    });

    Vue.filter("dateFull", (value) => {
      if (!value) return "";
      return new Date(value).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" });
    });
    Vue.filter("dateTimeFull", (value) => {
      if (!value) return "";
      return new Date(value).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" });
    });
    Vue.filter("dateTimeFullAt", (value) => {
      if (!value) return "";
      const date = new Date(value);
      return date.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" }) + " at " + date.toLocaleString("en-US", { hour: "numeric", minute: "numeric" });
    });

    Vue.filter("dateRestored", (value) => {
      if (!value) return "";
      let parsed = value;
      if (!(parsed instanceof Date)) {
        parsed = parseISO(parsed);
      }
      return format(parsed, "EEEE, MMM d, h:mm a");
    });

    Vue.filter("timeOnly", (value) => {
      if (!value) return "";
      let parsed = value;
      if (!(parsed instanceof Date)) {
        parsed = parseISO(parsed);
      }
      return format(parsed, "h:mm a");
    });

    Vue.filter("currency", (value, rounded = false) => {
      if (!value && value !== 0) return "";
      const opts = {
        style: "currency",
        currency: "USD",
      };
      if (rounded) {
        opts.minimumFractionDigits = 0;
        opts.maximumFractionDigits = 0;
      }
      const formatter = new Intl.NumberFormat("en-US", opts);
      return formatter.format(Number(value));
    });

    Vue.filter("phone", (phone) => {
      return phone.replace(/[^0-9]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    });

    Vue.filter("addressTownState", (address) => {
      if (address.city && address.state) {
        return InitialCaps(address.city) + ", " + address.state.toUpperCase();
      } else {
        return "";
      }
    });

    Vue.filter("addressExpFormat", (address, options) => {
      const converted = GetMailingAddressFromVerified(address);
      return addressFormatter(converted, options);
    });

    // Note that this is also currently used for the experian API call.
    // If this formatting is changed, break out and handle that call formatting separately.
    Vue.filter("addressFormat", (address, options) => {
      return addressFormatter(address, options);
    });

    Vue.filter("accountName", (account) => {
      if (account.nickname) {
        return account.nickname;
      } else if (account.address) {
        if (account.address.street1) {
          return InitialCaps(account.address.street1);
        } else {
          return InitialCaps(account.address.addressLine1);
        }
      } else {
        return "";
      }
    });

    Vue.filter("walletSlug", (wallet, hidenew) => {
      if (!wallet) return "";
      // Some APIs return different casings
      const transType = wallet.transType || wallet.transtype;
      if (wallet.paymentType === "CC" && (wallet.lastFourDigitDebitAccount || wallet.debitAccount)) {
        let lastFour = "";
        if (wallet.lastFourDigitDebitAccount) {
          lastFour = wallet.lastFourDigitDebitAccount;
        } else {
          lastFour = wallet.debitAccount.slice(-4);
        }
        const cardType = wallet.cardType || "Credit card";
        return cardType + " ******" + lastFour;
      } else if (wallet.paymentType === "ATM" && transType && (wallet.lastFourDigitDebitAccount || wallet.debitAccount)) {
        let lastFour = "";
        if (wallet.lastFourDigitDebitAccount) {
          lastFour = wallet.lastFourDigitDebitAccount;
        } else {
          if (wallet.debitAccount) {
            lastFour = wallet.debitAccount.slice(-4);
          } else if (wallet.debitNumber) {
            lastFour = wallet.debitNumber.slice(-4);
          } else {
            lastFour = "";
          }
        }
        return "ATM Card" + " ******" + lastFour;
      } else if (wallet.paymentType === "ACH" && transType && (wallet.lastFourDigitDebitAccount || wallet.debitAccount)) {
        return PrintableTransType(transType) + " ******" + (wallet.lastFourDigitDebitAccount || wallet.debitAccount);
      } else if (!hidenew && wallet.isNew === "credit") {
        return "New Card";
      } else if (!hidenew && wallet.isNew === "atm") {
        return "New ATM Card";
      } else if (!hidenew && wallet.isNew === "ach") {
        return "New Bank Account";
      }
      return "";
    });

    Vue.filter("round", (value, decimals = 0) => {
      if (value === undefined || value === null) {
        return "";
      }
      return value.toFixed(decimals);
    });

    Vue.filter("numberComma", (value) => {
      if (value === undefined || value === null) {
        return "";
      }
      return parseFloat((value).toFixed(0)).toLocaleString();
    });

    Vue.filter("singleDigit", (value) => {
      if (value === undefined || value === null) {
        return "";
      }
      return value > 0 ? Math.round(value * 10)/10 : "0.0";
    });
    Vue.filter("numberCommaSingleDecimal", (value) => {
      if (value === undefined || value === null) {
        return "";
      }
      if (value < 0.1 && value > 0) {
        return "0.1";
      }
      return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    });
    Vue.filter("nonBreakingSpaces", function(value) {
      if (!value) {
        return "";
      }
      return value.replace(/ /g, '\xa0');
    });
    
    Vue.filter("townUnslug", (value) => {
      return DehyphenateTown(value);
    });

    Vue.filter("titlecase", (value) => {
      if (!value) {
        return "";
      }
      const words = value.toLowerCase().split(" ");
      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(" ");
    });

    Vue.filter("transactionType", (value) => {
      switch (value) {
        case "Pay Segment":
          return "Payment";
        case "Bill Segment":
          return "Bill";
        default:
          return value;
      }
    });
    Vue.filter("incidentStatus", (statusCode) => {
      return GetOutageStatusLabel(statusCode);
    });
  },
};

function addressFormatter(address, options) {
  if (!address) return "";

  // separator allows us to change the separator between address lines (often useful to use ", " to get all on one line)
  let separator = "\n";
  // skipFirst is passed when we may be replacing street address with account nickname. So hide the street parts
  let skipFirst = false;
  // when displaying a mailing address, we don't decapitalize and do show the country at the end
  let mailing = false;

  if (options) {
    if (options.separator) separator = options.separator;
    if (options.skipFirst) skipFirst = options.skipFirst;
    if (options.mailing) mailing = options.mailing;
  }
  let lines = [];
  if (!skipFirst) {
    if (address.street1) {
      lines.push(address.street1);
    } else {
      lines.push(address.addressLine1);
    }
    if (address.street2) {
      lines.push(address.street2);
    } else if (address.addressLine2) {
      lines.push(address.addressLine2);
    }
  }
  if (address.street3) {
    lines.push(address.street3);
  } else if (address.addressLine3) {
    lines.push(address.addressLine3);
  }
  if (address.street4) {
    lines.push(address.street4);
  } else if (address.addressLine4) {
    lines.push(address.addressLine4);
  }
  if (!mailing) {
    lines = lines.map(line => InitialCaps(line));
  }
  if (address.city) {
    if (!mailing) {
      lines.push(InitialCaps(address.city) + ", " + address.state);
    } else {
      lines.push(address.city + ", " + address.state + " " + address.zip);
    }
  }
  if (mailing) {
    switch (address.country) {
      case "USA":
        lines.push("United States");
        break;
      case "CAN":
        lines.push("Canada");
        break;
    }
  }
  return lines.join(separator);
}
