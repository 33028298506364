<template>
  <div class="create-password__requirements">
    <div v-if="!validationErrors.firstByRule('password', 'length', this.scope)" class="create-password__requirements-single">
      <svg class="my-account__green-check-icon gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
      </svg>
      Must be between 7 and 30 characters
    </div>
    <div v-if="validationErrors.firstByRule('password', 'length', this.scope)" class="create-password__requirements-single gds-text-grey">
      <span class="bullet-point">&bull;</span>Must be between 7 and 30 characters
    </div>

    <div v-if="!validationErrors.firstByRule('password', 'hasupperandlower', this.scope)" class="create-password__requirements-single">
      <svg class="my-account__green-check-icon gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
      </svg>
      One lowercase and one uppercase letter
    </div>
    <div v-if="validationErrors.firstByRule('password', 'hasupperandlower', this.scope)" class="create-password__requirements-single gds-text-grey">
      <span class="bullet-point">&bull;</span>One lowercase and one uppercase letter
    </div>

    <div v-if="!validationErrors.firstByRule('password', 'hasnumber', this.scope)" class="create-password__requirements-single">
      <svg class="my-account__green-check-icon gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
      </svg>
      One number
    </div>
    <div v-if="validationErrors.firstByRule('password', 'hasnumber', this.scope)" class="create-password__requirements-single gds-text-grey">
      <span class="bullet-point">&bull;</span>One number
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordRequirements",
  props: ["validationErrors", "scope"],
  data() {
    return {
    };
  },
};
</script>

<style scoped>
</style>