<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail modal-evcharging-settings">
      <div class="modal-usage-pick-a-view__header">
        <span class="modal-usage-pick-a-view__title modal-title">Settings</span>
        <button @click="closeModal()" type="button" class="close bill-pay__close gds-button gds-button-circle gds-secondary">
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
      </div>
      <FocusLock>
        <div class="modal-usage-pick-a-view__modal-body">
          <div v-if="loadState ==='loading'" class="table-loading" />
          <div
          class="settings-modal"
          v-if="loadState ==='complete' && evSettingsForEdit">
            <div
            class="form-row">
                <div class="form-group ev-model">
                  <label for="ev-model">EV Make / Model</label>
                  <div class="form-control select-container">
                    <label>
                      <select
                        class="modal-evcharging-settings__select"
                        @change="onMakeModelChange"
                        v-model="evSettingsForEdit.selectedMakeModel">
                          <option
                          v-for="model in evSettingsForEdit.makeModels" :key="model.name" :value="model.name">
                          {{ model.name }}
                          </option>
                      </select>
                      <span class="modal-evcharging-settings__custom-caret">&#9662;</span>
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label>EV MPGe</label>
                  <input
                  type="text"
                  class="form-control"
                   @keyup="checkValidation()"
                   v-model="evSettingsForEdit.mpgEquivalent"
                   :class="{ 'ev-charger-settings-validation-error': validationErrors.mpgEquivalent}"/>
                </div>
              </div>
              <h2 class="section-title">Fuel Savings Comparison</h2>
              <div class="form-row">
                <div class="form-group">
                  <label>Gasoline Car MPG</label>
                  <input
                  type="text"
                  class="form-control"
                  @keyup="checkValidation()"
                  v-model="evSettingsForEdit.mpgGas"
                  :class="{ 'ev-charger-settings-validation-error': validationErrors.mpgGas}"/>
                </div>
                <div class="form-group">
                  <label>Cost per Gallon of Gas</label>
                  <input
                  type="text"
                  class="form-control"
                  @keyup="checkValidation()"
                  v-model="evSettingsForEdit.gasPricePerGallon"
                  :class="{ 'ev-charger-settings-validation-error': validationErrors.gasPricePerGallon}"/>
                </div>
              </div>
              <div
              class="error-container"
              v-if="mpgEquivalentWithinRangeWarning">
                {{ mpgEquivalentWithinRangeWarning }}
              </div>
              <div class="button-group">
                <button :disabled="!formValid" @click="updateEvSettings()" class="gds-button gds-compact save-button">Save</button>
                <a href="#" @click="deleteEvSettings()" class="gds-button gds-compact gds-button-outline reset-link">Reset</a>
              </div>
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../../../services/gmpapi";
import { DumpError } from "../../../../utilities";

const MPG_EQUIVALENT_MAX = 300;
const LOAD_STATE_ERROR = 'error';
const LOAD_STATE_LOADING = 'loading';
const LOAD_STATE_COMPLETE = 'complete';

export default {
  name: 'ModalEvChargingSettings',
  props: {
    evSettings: Object,
    accountNumber: String,
    closeModal: Function,
    onApiSuccess: Function
  },
  mounted() {
    this.initEvSettingsForEdit();
  },
  data() {
    return {
      formValid: true,
      validationErrors: {},
      evSettingsForEdit: undefined,
      loadState: LOAD_STATE_COMPLETE,
      mpgEquivalentWithinRangeWarning: undefined
    };
  },
  methods: {
    valueIsWithinRange(input, maxValue) {
      return parseFloat(input) <= maxValue;
    },
    valueIsValidNumber(input) {
      const regex = /^\d+(\.\d+)?$/;
      return regex.test(input);
    },
    initEvSettingsForEdit() {
      const selectedModel = this.evSettings.makeModels.find(model => model.name === event.target.value);

        if (this.evSettings){
            try {
              this.evSettingsForEdit = JSON.parse(JSON.stringify(this.evSettings));
            } catch (error) {
              DumpError('Error in initializing ev settings', err);
            }
        }
    },
    onMakeModelChange(event) {
      const selectedModel = this.evSettingsForEdit.makeModels.find(model => model.name === event.target.value);

      if (selectedModel) {
        this.evSettingsForEdit.mpgEquivalent = selectedModel.mpgEquivalent;
      }
    },
    async deleteEvSettings() {
      this.loadState = LOAD_STATE_LOADING;

      try {
        await GMPAPI.deleteEvSettings(this.accountNumber);
        this.onApiSuccess();
        this.loadState = LOAD_STATE_COMPLETE;
        this.closeModal();
      } catch (err) {
        this.loadState = LOAD_STATE_ERROR;
        DumpError('Error deleting ev settings', err);
      }
    },
    async updateEvSettings() {
      this.loadState = LOAD_STATE_LOADING;

      const settings = this.evSettingsForEdit;

      const postData = {
        mpgGas: settings.mpgGas,
        mpgEquivalent: settings.mpgEquivalent,
        selectedMakeModel: settings.selectedMakeModel,
        gasPricePerGallon: settings.gasPricePerGallon
      }

      try {
        await GMPAPI.updateEvSettings(this.accountNumber, postData);
        this.onApiSuccess();
        this.loadState = LOAD_STATE_COMPLETE;
        this.closeModal();
      } catch (err) {
        this.loadState = LOAD_STATE_ERROR;
        DumpError('Error updating ev settings', err);
      }
    },
    checkValidation() {
      const settings = this.evSettingsForEdit;

      this.validationErrors = {};
      this.mpgEquivalentWithinRangeWarning = undefined;

      const mpgGas = settings.mpgGas;
      const mpgEquivalent = settings.mpgEquivalent;
      const gasPricePerGallon = settings.gasPricePerGallon;

      const mpgGasValid = this.valueIsValidNumber(mpgGas);
      const mpgEquivalentValid = this.valueIsValidNumber(mpgEquivalent);
      const gasPricePerGallonValid = this.valueIsValidNumber(gasPricePerGallon);

      if (!mpgGasValid) {
        this.validationErrors.mpgGas = true;
      }

      if (!mpgEquivalentValid) {
        this.validationErrors.mpgEquivalent = true;
      }

      if (!gasPricePerGallonValid) {
        this.validationErrors.gasPricePerGallon = true;
      }

      let mpgEquivalentWithinRange = true;

      if (mpgEquivalentValid) {
        mpgEquivalentWithinRange = this.valueIsWithinRange(mpgEquivalent, MPG_EQUIVALENT_MAX);

        if (!mpgEquivalentWithinRange) {
          this.mpgEquivalentWithinRangeWarning = `Maximum allowed value is ${MPG_EQUIVALENT_MAX}`;
        }
      }

      this.formValid = mpgGasValid && mpgEquivalentValid && gasPricePerGallonValid && mpgEquivalentWithinRange;
    },
  }
};
</script>

<style scoped></style>