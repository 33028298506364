var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-modal" }, [
    _c(
      "div",
      { staticClass: "export-data__modal-box" },
      [
        _c("FocusLock", [
          _c("header", { staticClass: "delete-user__modal-box--header" }, [
            _c(
              "button",
              {
                staticClass: "close gds-button gds-button-circle gds-secondary",
                on: {
                  click: function($event) {
                    return _vm.CancelDeleteContact()
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-visibility--hide-visually" }, [
                  _vm._v("Close")
                ]),
                _vm._v(" "),
                _c("svg", { staticClass: "gds-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "export-data__modal-box--headline" }, [
              _vm._v("Are you sure you want to remove this contact?")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "delete-user__modal-box--body" }, [
            _c(
              "div",
              {
                staticClass: "export-data__modal-box--message gds-space-stack-l"
              },
              [
                _vm._v(
                  "Removing this contact will remove it from your account and unsubscribe you from all alerts setup to use this contact."
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "gds-button gds-secondary gds-compact gds-space-stack-m",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.CancelDeleteContact()
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Cancel")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "gds-button gds-compact gds-secondary gds-full-red-button gds-space-stack-m",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.DeleteContactConfirm()
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Yes, remove contact")
                ])
              ]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }