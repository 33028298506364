var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.accounts
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "account-profile-button",
            class: { minified: _vm.minified },
            attrs: { tabindex: "0" },
            on: {
              click: _vm.ToggleAccountSwitcher,
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.ToggleAccountSwitcher.apply(null, arguments)
              }
            }
          },
          [
            _vm.currentAccount
              ? _c("div", { staticClass: "account-profile-button__inner" }, [
                  _vm.currentAccount.photoUrl
                    ? _c("div", {
                        staticClass: "small-round-img",
                        style: {
                          backgroundImage:
                            "url(" +
                            _vm.GetResizedAccountPhoto(
                              _vm.currentAccount.photoUrl
                            ) +
                            ")"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "account-switcher__nickname gds-font-demi" },
                    [_vm._v(_vm._s(_vm._f("accountName")(_vm.currentAccount)))]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.currentAccount
              ? _c("div", { staticClass: "account-profile-button__inner" }, [
                  _c("div", {
                    staticClass: "small-round-img",
                    staticStyle: {
                      backgroundImage:
                        "url(/wp-content/themes/gmptwentynineteen/assets/images/default-account.png)"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "gds-font-demi" }, [
                    _vm._v("No account selected")
                  ])
                ])
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }