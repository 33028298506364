var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-outage-page" },
    [
      _vm.loadState === undefined ? _c("div", [_vm._m(0)]) : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "maintenance"
        ? _c("flow-error", {
            attrs: {
              name: "Report Outage",
              state: "maintenance",
              outagebox: "true",
              closepath: "/report-outage"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Report Outage",
              state: "error",
              outagebox: "true",
              closepath: "/report-outage"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" ? _c("router-view") : _vm._e(),
      _vm._v(" "),
      _vm.showManualReportInfo && !this.routeloading
        ? _c("div", { staticClass: "report-outage-info_login" }, [
            _vm._v(
              "*By reporting an outage via text message (SMS) you agree to receive power outage or other account related messages from Green Mountain Power. Reply “HELP” to for more information and “STOP” to opt-out, Message and data rates may apply. Message frequency varies. See our "
            ),
            _c(
              "a",
              {
                staticClass: "gds-link gds-link--bold cursorPointer",
                attrs: {
                  href:
                    "/apps-skills/text-alerts/notification-terms-and-conditions/"
                }
              },
              [_vm._v("Terms of Use")]
            ),
            _vm._v(" and "),
            _c(
              "a",
              {
                staticClass: "gds-link gds-link--bold cursorPointer",
                attrs: { href: "/legal" }
              },
              [_vm._v("Privacy Policy.")]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay__page-loader" }, [
      _c("img", {
        staticClass: "bill-pay__page-loader--svg",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }