<template>
  <div>
    <h2 class="gds-space-top-xl gds-space-stack-default">Would you like to download this info?</h2>
    <div class="gds-flex-container gds-flex-container--left">
      <div class="usage-export__export-container gds-flex-container">
          <p v-if="!viewAdvancedOptions" class="usage-dashboard___sub-title gds-space-stack-l">Get your hourly usage data for past 30 days, as a .CSV</p>

          <div v-if="errorDownload" class="form-message-box gds-space-stack-m">{{errorDownload}}</div>

          <div v-if="viewAdvancedOptions">
            <div class="gds-flex-container">
              <div class="gds-space-stack-s gds-flex-container gds-flex-container--left gds-flex-container--wrap">
                <fieldset class="usage-export__fieldset gds-fieldset">
                  <span class="usage-export__field-label">Start Date</span>
                  <div class="export-data__filtration-date-box">
                    <div @click="$refs.startPicker.showCalendar()" class="my-account-billing-report__filtration-date-icon">
                      <svg class="gds-icon">
                        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                      </svg>
                    </div>
                    <datepicker ref="startPicker" v-model="startDate" :format="DATE_FORMAT" :disabled-dates="{from: lastPossibleDate}" input-class="export-data__date my-account-billing-report__filtration-date-text" />
                  </div>
                </fieldset>

                <fieldset class="usage-export__fieldset gds-fieldset">
                  <span class="usage-export__field-label">End Date</span>
                  <div class="export-data__filtration-date-box">
                    <div @click="$refs.endPicker.showCalendar()" class="my-account-billing-report__filtration-date-icon">
                      <svg class="gds-icon">
                        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                      </svg>
                    </div>
                    <datepicker ref="endPicker" v-model="endDate" :format="DATE_FORMAT" :disabled-dates="{from: lastPossibleDate}" input-class="export-data__date my-account-billing-report__filtration-date-text" />
                  </div>
                </fieldset>

                <fieldset class="usage-export__fieldset gds-fieldset">
                  <label>
                    <span class="usage-export__field-label">File Format</span>
                    <div>
                      <select v-model="dataFormat" class="eicproduct-input-field-l__select gds-input-field">
                        <option value="csv">CSV</option>
                        <option value="xml">XML</option>
                      </select>
                    </div>
                  </label>
                </fieldset>

              </div>
            </div>
          </div>

          <div v-if="rangeDays < 1" class="validation-error">Start date must be before end date.</div>
          <div v-if="rangeDays > 30" class="validation-error">Maximum export range is 30 days.</div>
          <div class="gds-space-stack-l">
          <button
            @click="Save()"
            :class="{ 'gds-loading': pendingDownload }"
            :disabled="rangeDays < 1 || rangeDays > 30 || pendingDownload"
            type="button"
            class="usage-export__download-button-with-image gds-card__button gds-button gds-round gds-compact"
          >
            <span class="usage-export__download-icon" v-if="!pendingDownload"></span>
            <span class="gds-button__text">Download Usage</span>
          </button>
          </div>
          <span v-if="!viewAdvancedOptions" class="usage-export__options-link underline" @click="viewAdvancedOptions = true">View advanced export options</span>
          <span v-if="viewAdvancedOptions" class="usage-export__options-link underline" @click="reset()">Hide advanced export options</span>

      </div>
    </div>
  </div>
</template>

<script>

import Datepicker from 'vuejs-datepicker';
import GMPAPI from '../../../../services/gmpapi';
import { ToServerDateTruncate, DumpError } from '../../../../utilities';
import { format, differenceInCalendarDays, addDays, min } from 'date-fns';

export default {
  name: "UsageExport",
  components: { 
    Datepicker
  },
  watch: {
    currentAccount() {
      this.reset();
    },
    startDate(newStartDate) {
      // calc the desired end date based on startDate
      const desiredEndDate = addDays(newStartDate, 29);
      // Make sure the date isnt in the future.
      this.endDate = min([desiredEndDate, this.lastPossibleDate]);
    }
  },
  mounted() {
    this.reset();
  },
  data() {
    return {
      startDate: undefined,
      endDate: undefined,
      dataFormat: "csv",
      lastPossibleDate: undefined,
      pendingDownload: false,
      errorDownload: undefined,
      viewAdvancedOptions: false,
      DATE_FORMAT: "MMMM d, yyyy"
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    rangeDays() {
      return differenceInCalendarDays(this.endDate, this.startDate) + 1;
    },
  },
  methods: {
    async Save() {
      debugger;
      this.pendingDownload = true;
      try {
        const filename = "UsageData_" + format(this.startDate, "yyyy-MM-dd") + "_" + this.rangeDays + "Days." + this.dataFormat;
        await GMPAPI.DownloadUsageExport(this.currentAccount.accountNumber, filename, ToServerDateTruncate(this.startDate), ToServerDateTruncate(addDays(this.endDate, 1)), this.dataFormat);
      } catch (err) {
        DumpError("Export data error", err);
        this.errorDownload = "There was an unexpected error exporting your data.";
      } finally {
        this.pendingDownload = false;
      }
    },

    reset() {
      this.viewAdvancedOptions = false;
      this.startDate = addDays(new Date(), -30),
      this.endDate = addDays(new Date(), -1),
      this.dataFormat = "csv";
      this.lastPossibleDate = addDays(new Date(), -1);
      this.pendingDownload = false;
      this.errorDownload = undefined;
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>