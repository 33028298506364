var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--report-issue"
    },
    [
      !_vm.currentAccount
        ? _c("div", { staticClass: "top-banner-card--logged-out" }, [
            _c(
              "a",
              {
                staticClass:
                  "top-banner-card gds-card gds-background-forestgreen gds-invert top-banner-card--logged-out",
                attrs: { href: "/report-outage" }
              },
              [
                _c("div", { staticClass: "gds-card__contents" }, [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "home-card__title--small-margin gds-card__title"
                    },
                    [_vm._v("Report an Issue")]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "home-card__body--small-margin gds-card__body"
                    },
                    [_vm._v("Outage, downed wire, tree on line.")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gds-card__button gds-button gds-button-circle gds-secondary"
                    },
                    [
                      _c("svg", { staticClass: "gds-icon" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href":
                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"
                          }
                        })
                      ])
                    ]
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentAccount
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "top-banner-card gds-card gds-data-card top-banner-card--logged-in",
                class: { "gds-loading": _vm.loadState === undefined }
              },
              [
                _c("h3", { staticClass: "gds-card__title" }, [
                  _vm._v("Power Status")
                ]),
                _vm._v(" "),
                _vm.loadState === "complete"
                  ? [
                      _vm.activeIncident
                        ? _c("div", [
                            _c("p", { staticClass: "card__etr-text" }, [
                              _vm._v(_vm._s(_vm.formatEtr(_vm.activeIncident)))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.activeIncident && _vm.powerSONP
                        ? _c("div", [
                            _c("p", { staticClass: "gds-card__caption" }, [
                              _vm._v("Shut off for non‑payment")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.plannedOutage && _vm.FF_PlannedOutage
                        ? _c("div", [
                            _c("p", { staticClass: "gds-card__caption" }, [
                              _vm._v(
                                "Planned Outage " +
                                  _vm._s(
                                    _vm._f("dateTimeFullAt")(
                                      _vm.plannedOutage.occursAt
                                    )
                                  ) +
                                  "."
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "figure",
                        {
                          staticClass:
                            "card__pay-bill-figure gds-card__data-container gds-flex-container"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card__data-container--flex-container gds-flex-container"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "card__house-icon--outer-outer"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card__house-icon--outer-inner",
                                      class: {
                                        "power-out": _vm.activeIncident,
                                        "power-off":
                                          !_vm.activeIncident &&
                                          (_vm.powerSONP || _vm.powerOff)
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "gds-icon card__house-icon"
                                        },
                                        [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-house"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "gds-card__data-container--status"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.activeIncident
                                        ? "Out"
                                        : _vm.powerSONP || _vm.powerOff
                                        ? "Off"
                                        : "On"
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.activeIncident && _vm.activeIncident.id
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "gds-card__button gds-button gds-round gds-orange",
                              attrs: {
                                href:
                                  "/outages/incident/" + _vm.activeIncident.id
                              }
                            },
                            [
                              _c("span", { staticClass: "gds-button__text" }, [
                                _vm._v("View Details")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      (!_vm.activeIncident &&
                        !(_vm.powerSONP || _vm.powerOff)) ||
                      (_vm.activeIncident && !_vm.activeIncident.id)
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "account_report_outage gds-card__button gds-button gds-round gds-orange",
                              attrs: { href: "/report-outage" }
                            },
                            [
                              _c("span", { staticClass: "gds-button__text" }, [
                                _vm._v("Report Outage")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.activeIncident && (_vm.powerSONP || _vm.powerOff)
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "gds-card__button gds-button gds-round gds-secondary",
                              attrs: { href: "/contact" }
                            },
                            [
                              _c("span", { staticClass: "gds-button__text" }, [
                                _vm._v("Contact Us")
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.loadState === "error"
                  ? _c(
                      "div",
                      { staticClass: "card-error card-error--smaller" },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "error-detail" }, [
                          _vm._v(
                            "\n          To report an outage text OUT to GMPVT or call\n          "
                          ),
                          _c("span", { staticClass: "gds-nobreak" }, [
                            _vm._v(_vm._s(_vm.GMPPhoneNumber) + ".")
                          ])
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loadState === "error"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "card-error-layout-blank card-error-layout-blank--short"
                      },
                      [_vm._v(" ")]
                    )
                  : _vm._e()
              ],
              2
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-l" }, [
      _vm._v("\n          Unable to load,\n          "),
      _c("br"),
      _vm._v("please try again later.\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }