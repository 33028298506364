<template>
  <div v-if="visible" class="gmp-modal">
    <div class="floating-feedback gmp-modal__container" v-click-outside="Close">
      <FocusLock>
        <div class="gmp-modal__inner-wrap">
          <button @click="Close()" type="button" class="floating-feedback__close gmp-modal__close close gds-button gds-button-circle gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
          </button>
          <div v-if="!isComplete">
            <div class="floating-feedback__title gds-space-stack-s">Provide Feedback</div>
            <form @submit.prevent="SubmitFeedback()">
              <div class="gds-font-demi gds-space-stack-m">How would you rate the GMP website?</div>
              <div class="floating-feedback__ratings-flex gds-flex-container gds-flex-container--left">
                <div
                  @click="SetRating('thumbsdown')"
                  @keyup.enter="SetRating('thumbsdown')"
                  :class="{ selected: rating === 'thumbsdown' }"
                  class="gds-space-inline-default floating-feedback-rating floating-feedback-rating--down"
                  style="backgroundImage: url(/wp-content/themes/gmptwentynineteen/assets/images/thumbs-down.svg)"
                  tabindex="0"
                />
                <div
                  @click="SetRating('thumbsup')"
                  @keyup.enter="SetRating('thumbsup')"
                  :class="{ selected: rating === 'thumbsup' }"
                  class="gds-space-inline-default floating-feedback-rating floating-feedback-rating--up"
                  style="backgroundImage: url(/wp-content/themes/gmptwentynineteen/assets/images/thumbs-up.svg)"
                  tabindex="0"
                />
              </div>

              <fieldset class="floating-feedback__fieldset gds-fieldset gds-space-stack-ml">
                <label class="gds-input-field">
                  <div class="floating-feedback__fieldset-label-text gds-font-demi">What feedback would you like to share?</div>
                  <textarea
                    v-model="feedbackText"
                    @input="errorSubmit = undefined"
                    class="floating-feedback__textarea gds-input-field__input"
                    placeholder="Type here..."
                    maxlength="1000"
                  />
                </label>
              </fieldset>

              <div v-if="feedbackText.length >= 1000" class="form-message-box gds-space-stack-ml">Feedback must be 1000 characters or shorter.</div>
              <div v-if="errorSubmit" class="form-message-box gds-space-stack-ml">{{errorSubmit}}</div>

              <div class="floating-feedback__button-wrap gds-flex-container gds-flex-container--nowrap">
                <button @click="Close()" class="gds-button gds-secondary">Cancel</button>
                <button :disabled="pendingSubmit" :class="{ 'gds-loading': pendingSubmit }" type="submit" class="gds-button gds-primary">
                  <span class="gds-button__text">Send</span>
                </button>
              </div>
            </form>
          </div>
          <div v-else>
            <div class="floating-feedback__title floating-feedback__thank-you-margin--headline">Thank you for providing your feedback!</div>
            <div class="floating-feedback__thank-you-margin--bodycopy">Your message has been sent to our team.</div>
            <button @click="Close()" class="gds-button gds-primary">Close</button>
          </div>
        </div>
        <!--/gmp-modal__inner-wrap-->
      </FocusLock>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../services/gmpapi";
import { GitHash } from "../environment";
import { DumpError } from "../utilities";
export default {
  name: "ModalFloatingFeedback",
  props: ["visible"],
  components: {
  },
  data() {
    return {
      rating: "neutral",
      feedbackText: "",
      pendingSubmit: false,
      errorSubmit: undefined,
      isComplete: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
  },
  methods: {
    Close() {
      this.$emit("close");
      this.isComplete = false;
    },
    SetRating(rating) {
      this.errorSubmit = undefined;
      this.rating = rating;
    },
    async SubmitFeedback() {
      try {
        this.pendingSubmit = true;
        this.errorSubmit = undefined;
        if (this.rating === "neutral" && !this.feedbackText) {
          this.errorSubmit = "Please provide a rating or feedback.";
          return;
        }
        const body = {
          device: navigator.userAgent,
          rating: this.rating,
          feedback: this.feedbackText,
          username: this.userInfo.username,
          email: this.userInfo.email,
          version: GitHash,
          dismissed: false, // always false for us, sometimes used for the app
          route: window.location.pathname,
        };
        await GMPAPI.SubmitSiteFeedback(body);
        this.isComplete = true;
        this.rating = "neutral";
        this.feedbackText = "";
      } catch (err) {
        DumpError("Submit feedback error", err)
        this.errorSubmit = "Failed to submit feedback";
      } finally {
        this.pendingSubmit = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>