const units = 'px';
const resizeEventName = 'resize';
const menuBreakpoint = 1100;
const small = 520;
const medium = 720;
const large = 900;
const extraLarge = 1024;
const extraExtraLarge = 1600;

export default {
  data() {
    return {
      isPortraitOrientation: false,
      isLandscapeOrientation: false,
      windowWidth: window.innerWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener(resizeEventName, this.onResize);
  },
  mounted() {
    this.setOrientation();
    window.addEventListener(resizeEventName, this.throttle(this.onResize, 50));
  },
  computed: {
    deviceIsPortraitOrientation() {
      return this.isPortraitOrientation;
    },
    deviceIsLandscapeOrientation() {
      return this.isLandscapeOrientation;
    },
    deviceIsSmall() {
      return this.isMaxWidth(small, this.windowWidth);
    },
    deviceIsMedium() {
      return this.compareBreakpoints((small + 1), medium, this.windowWidth);
    },
    deviceIsLarge() {
      return this.compareBreakpoints((medium + 1), large, this.windowWidth);
    },
    deviceIsExtraLarge() {
      return this.compareBreakpoints((large + 1), extraLarge, this.windowWidth);
    },
    deviceIsExtraExtraLarge() {
      return this.compareBreakpoints((extraLarge + 1), extraExtraLarge, this.windowWidth);
    },
    deviceExceedsExtraExtraLarge() {
      return this.isMinWidth((extraExtraLarge + 1), this.windowWidth);
    },
    deviceIsMenuBreakpoint() {
      return this.isMaxWidth((menuBreakpoint), this.windowWidth);
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
      this.setOrientation();
    },
    isMaxWidth(breakpoint) {
      return window.matchMedia(`only screen and (max-width: ${breakpoint}${units})`).matches;
    },
    isMinWidth(breakpoint) {
      return window.matchMedia(`only screen and (min-width: ${breakpoint}${units})`).matches;
    },
    compareBreakpoints (breakpointSmall, breakpointLarge) {
      return  window.matchMedia(`only screen and (min-width: ${breakpointSmall}${units}) and (max-width: ${breakpointLarge}${units})`).matches;
    },
    deviceMatchesOrientation (targetOrientation) {
      return window.matchMedia(`(orientation: ${targetOrientation})`).matches;
    },
    setOrientation() {
      this.isPortraitOrientation = this.deviceMatchesOrientation('portrait');
      this.isLandscapeOrientation = this.deviceMatchesOrientation('landscape');
    },
    throttle(func, wait) {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
      };
    },
  }
};