var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.pendingSelect
      ? _c("div", { staticClass: "ghost__loading-full" })
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "ghost__top-container ghost__container" }, [
      _c("h1", { staticClass: "gds-display-2 gds-space-stack-m" }, [
        _vm._v("Account Ghosting")
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass:
            "gds-space-stack-ml gds-flex-container gds-flex-container--nowrap gds-flex-container--stretch",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.Search.apply(null, arguments)
            }
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchText,
                expression: "searchText"
              }
            ],
            staticClass:
              "ghost__search-input gds-input-field__input gds-space-inline-m",
            attrs: { type: "text", placeholder: "Enter search text" },
            domProps: { value: _vm.searchText },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchText = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "ghost__search-button gds-button",
              class: { "gds-loading": _vm.pendingSearch },
              attrs: {
                disabled: _vm.pendingSearch || !_vm.searchText,
                type: "submit"
              }
            },
            [
              _c("span", { staticClass: "gds-button__text" }, [
                _vm._v("Search")
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.errorSearch
        ? _c(
            "div",
            {
              staticClass:
                "form-message-box form-message-box--full-width gds-space-stack-m"
            },
            [_vm._v(_vm._s(_vm.errorSearch))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.errorSelect
        ? _c(
            "div",
            {
              staticClass:
                "form-message-box form-message-box--full-width gds-space-stack-m"
            },
            [_vm._v(_vm._s(_vm.errorSelect))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.accounts
        ? _c("div", { staticClass: "gds-font-demi gds-text-green" }, [
            _vm._v(_vm._s(_vm.accounts.length) + " results")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "ghost__accounts-table" },
      [
        _vm.accounts && _vm.accounts.length
          ? _c("vue-good-table", {
              attrs: {
                columns: _vm.columns,
                rows: _vm.accounts,
                styleClass: "vgt-table striped ghosting-account-table"
              },
              on: { "on-row-click": _vm.SelectAccount }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }