<template>
  <a :href="installer.enrollmentFormUrl" target="_blank">
    <div id="slat632dffbce2ab0" class="slat">
      <div class="slat__header">
        <div class="slat__cta">
          <div class="slat__text-container">
            <div class="slat__headline
          slat__headline--bold        ">
              {{installer.name}} </div>
          </div>
          <a class="slat__button slat__button--linked"></a>
        </div>
      </div>
    </div>
  </a>
</template>

<script>

export default {
  name: "SlatPowerwallInstaller",
  props: {
    installer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  async mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
