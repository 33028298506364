var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "my-account__sidebar" } }, [
    _c(
      "div",
      { staticClass: "my-account__sidebar" },
      [_c("widget-account-switcher"), _vm._v(" "), _c("widget-my-account-nav")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }