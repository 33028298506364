var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "my-account__not-authorized-page-wrap" }, [
      _c(
        "section",
        { staticClass: "my-account__login-form-wrap gds-align--text-center" },
        [
          _c("div", { staticClass: "my-account__not-authorized-image" }, [
            _c("img", {
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/not-authorized.svg",
                alt: "not authorized graphic"
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "gds-font-size-xl gds-font-demi gds-space-stack-ml"
            },
            [_vm._v("You are not authorized to view this account.")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "gds-display-0 gds-link gds-link--bold" }, [
            _c(
              "a",
              {
                staticClass: "green-arrow-link--right",
                attrs: { href: "/account" }
              },
              [_vm._v("Go to My Account")]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }