var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("UsageDetailControls", {
        staticClass: "usage-detail__seasonal-average-nav-controls",
        attrs: {
          selectedDate: _vm.selectedDate,
          datePickerDisabledDates: _vm.disabledDates,
          selectedDateWidth: "180px",
          datePickerDateType: _vm.datePickerDateType,
          showDateBackwardsButton: true,
          onDateBackwardsClicked: _vm.dateBackwards,
          showDateForwardsButton: true,
          onDateForwardsClicked: _vm.dateForwards,
          disableDateNavigationButtonBack: _vm.navLeftDisabled,
          disableDateNavigationButtonForward: _vm.navRightDisabled,
          showGoToTodayButton: true,
          onGoToTodayClicked: _vm.goToToday,
          showJumpToDateButton: false,
          onSelectedDateChanged: _vm.selectDate,
          disableAllDateNavControls:
            _vm.loading || _vm.disableAllDateNavControls
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "usage-detail__seasonal-average" }, [
        !_vm.loadState || _vm.loading
          ? _c("div", { staticClass: "table-loading" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "usage-page--interior-status-container" },
          [
            _vm.loadState === "error"
              ? _c("flow-error", {
                  attrs: { name: "Usage information", state: "error" }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.loadState === "maintenance"
              ? _c("flow-error", {
                  attrs: { name: "Usage information", state: "maintenance" }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.loadState === "unavailable"
              ? _c("flow-error", {
                  attrs: {
                    name: "Usage information",
                    state: "unavailable",
                    img:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.loadState === "empty"
              ? _c("flow-error", {
                  attrs: {
                    name: "Usage information",
                    state: "nodata",
                    img:
                      "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.loadState === "complete"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading"
                  }
                ],
                staticClass: "usage-detail__seasonal-carousel-container"
              },
              [
                _c(
                  "div",
                  {
                    ref: "carousel",
                    staticClass: "usage-detail__seasonal-carousel",
                    on: { mousedown: _vm.startDrag, touchstart: _vm.startDrag }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "usage-detail__seasonal-carousel-items",
                        style: {
                          transform:
                            "translateX(-" +
                            (_vm.selectedSeasonIndex + 1) * _vm.itemWidth +
                            "%)"
                        }
                      },
                      _vm._l(_vm.seasonalDailyAverageData, function(data, i) {
                        return _c(
                          "div",
                          {
                            key: data.date.toString(),
                            staticClass: "usage-detail__seasonal-carousel-item"
                          },
                          [
                            _c("SeasonalUsageBubble", {
                              attrs: {
                                season: data.season,
                                averageUsage: data.averageUsage,
                                scaledAverageUsage: data.scaledAverageUsage,
                                year: data.year,
                                isCurrentSeason:
                                  _vm.currentSeasonIndex === i ? true : false
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }