
<template>
  <div class="notifications--container">
    <ModalRemoveContactConfirm
    v-if="deleteContactRequested"
    :DeleteContactConfirm="DeleteContactConfirm"
    :CancelDeleteContact="CancelDeleteContact" />
    <div v-if="loadState === undefined" class="table-loading" />
    <div v-if="loadState === 'down'" class="usage-page--interior-status-container">
      <div class="global-flow-error--outer-container gds-flex-container">
        <div class="global-flow-error--inner-container">
          <img class="maintenance-graphic" src="/wp-content/themes/gmptwentynineteen/assets/images/outages-notifications.svg" />
          <div
            class="maintenance-message maintenance-message--main gds-space-stack-m"
          >We’re upgrading our notifications system and a new interface is coming soon. In the meantime please text "REG" to GMPVT (46788) to register a new contact.</div>
        </div>
      </div>
    </div>
    <div v-if="loadState !== 'complete'" class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Notification configuration" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Notification configuration" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Notification configuration" state="unavailable" img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <div v-if="loadState === 'complete'">
      <div>
        <!-- Display existing contacts -->
        <div v-if="!editingContact">
          <div v-for="contact of unconfirmed" :key="contact.contactId">
            <banner-one-action
              bannerColor="blue"
              :message="`The following contact has not been verified: ${contact.value}`"
              :resultMessage="'Message sent'"
              :linkText="'Resend Message'"
              @buttonFunction="ReconfirmContact(contact)"
            ></banner-one-action>
          </div>
          <section class="my-account__full-width-section">
            <div
              class="my-account__notifications-intro-button-wrap gds-flex-container"
              :style="contacts && contacts.length === 0 ? 'flex-direction: column; align-items: flex-start;': 'align-items: center;'"
            >
              <div>
                <h2 class="no-margin gds-display-1">Contacts</h2>
                <p v-if="contacts && contacts.length > 0" class="notifications__intro">Where you'd like us to send notifications.</p>
                <p
                  v-if="contacts && contacts.length === 0"
                  class="notifications__intro"
                >There aren’t any contacts yet for this account. Add a new contact and begin receiving notifications about outages and usage via email or text message.</p>
              </div>
              <button @click="EditContact()" type="button" class="gds-button gds-round gds-compact gds-space-stack-ml">Add New Contact</button>
            </div>

            <div v-for="contact of contacts" :key="contact.contactId" class="notifications__row-item gds-flex-container gds-flex-container--top">
              <div class="notifications__row-item-icon">
                <svg class="gds-icon">
                  <use v-if="contact.channel.code==='EMAIL'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-email" />
                  <use v-if="contact.channel.code==='SMS'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-cell-phone" />
                </svg>
              </div>
              <div class="notifications__row-item--inner-wrap gds-flex-container gds-flex-container--top">
                <div class="notifications__row-item-account">
                  <div v-if="contact.nickname" class="notifications__row-item-account--details gds-font-demi">{{contact.nickname}}</div>
                  <div class="gds-flex-container gds-flex-container--left gds-flex-container--wrap">
                    <div
                      :class="contact.nickname? 'notifications__row-item-account--detailsm gds-space-inline-m' : 'notifications__row-item-account--details gds-font-demi gds-space-inline-m'"
                    >{{contact.value}}</div>
                    <div v-if="contact.confirmed !== true" class="notifications__row-item-account--unverified">Unverified</div>
                  </div>
                  <button @click="EditContact(contact)" type="button" class="gds-button gds-text-button">
                    <span class="my-account__notifications--text-button gds-button__text">Edit / Remove</span>
                  </button>
                </div>
                <div class="notifications__row-item-notify-types">
                  <div role="group" class="notifications__row-item-notify-type--fieldset gds-flex-container gds-flex-container--left gds-fieldset">
                    <label v-for="alerttype of alerttypes" :key="alerttype.alertTypeId" class="notifications__checkbox-label gds-checkbox">
                      <div class="notifications__row-item-notify-type--item-label gds-checkbox__label">{{alerttype.name}}</div>
                      <input
                        type="checkbox"
                        :checked="HasSubscription(contact, alerttype)"
                        :disabled="!ValidToSubscribe(contact.channel.code, alerttype)"
                        @change="Subscribe(contact, alerttype, $event)"
                      />
                      <span v-if="ValidToSubscribe(contact.channel.code, alerttype)" class="notifications__checkbox__faux gds-checkbox__faux"></span>
                      <span v-else class="notifications__not-eligible">Not available via {{ contact.channel.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </section>  
        </div>
        <!-- Add or edit a contact -->
        <div v-if="editingContact && editingContact.channelCode !== 'PUSH'">
          <section class="my-account__full-width-section gds-space-stack-xl">
            <div class="gds-flex-container gds-flex-container--space-between gds-space-stack-l">
              <h2 v-if="editingContact.isNew" class="no-margin gds-display-1">New Contact</h2>
              <h2 v-if="!editingContact.isNew" class="no-margin gds-display-1">Edit Contact</h2>
            </div>
            <form class="my-account__edit-contact-form">
              <fieldset class="gds-fieldset gds-space-stack-m">
                <label class="gds-input-field">
                  <span class="gds-input-field__label">Contact Method</span>
                  <div class="gds-position--relative">
                    <span class="gds-input-field__prefix">
                      <svg class="gds-icon my-account__edit-contact-form--icon">
                        <use v-if="editingContact.channelCode==='EMAIL'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-email" />
                        <use v-if="editingContact.channelCode==='SMS'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-cell-phone" />
                      </svg>
                    </span>
                    <!-- Note: this is not populated dynamically. This is because we have to make assumptions for icon, input name, input format etc.
                    Changing the available alert channels will require code changes.-->
                    <select v-model="editingContact.channelCode" :disabled="!editingContact.isNew" class="bill-pay-input-field__select">
                      <option value="EMAIL">Email</option>
                      <option value="SMS">Text Message (SMS)</option>
                    </select>
                  </div>
                </label>
              </fieldset>
              <fieldset v-if="editingContact.channelCode === 'SMS'" class="gds-fieldset gds-space-stack-m">
                <label class="gds-input-field">
                  <span class="gds-input-field__label">Phone Number</span>
                  <the-mask
                    :disabled="!editingContact.isNew || savingContact || deletingContact"
                    v-model="editingContact.sms"
                    :mask="'+1 (###) ###-####'"
                    v-validate="'required|min:10'"
                    name="phone number"
                    key="phone number"
                    class="gds-input-field__input"
                    type="tel"
                    placeholder="Your phone number"
                  />
                </label>
                <div v-if="errors.first('phone number')" class="validation-error">{{ errors.first("phone number")}}</div>
              </fieldset>
              <fieldset v-if="editingContact.channelCode === 'EMAIL'" class="gds-fieldset gds-space-stack-m">
                <label class="gds-input-field">
                  <span class="gds-input-field__label">Email Address</span>
                  <input
                    :disabled="!editingContact.isNew"
                    v-model="editingContact.email"
                    v-validate="'required|email|max:74'"
                    maxlength="74"
                    name="email address"
                    key="email address"
                    class="gds-input-field__input"
                    type="email"
                    autocomplete="email"
                    placeholder="Your email"
                  />
                </label>
                <div v-if="errors.first('email address')" class="validation-error">{{ errors.first("email address")}}</div>
              </fieldset>
              <fieldset class="gds-fieldset gds-space-stack-m">
                <label class="gds-input-field">
                  <span class="gds-input-field__label">Nickname</span>
                  <input
                    v-model="editingContact.nickname"
                    v-mask="{mask: 'CCCCCCCCCCCC', tokens: allowedCharacters }"
                    class="gds-input-field__input"
                    type="text"
                    placeholder="Type here..."
                  />
                </label>
              </fieldset>
            </form>
          </section>

          <section class="my-account__full-width-section gds-space-stack-l">
            <div class="gds-space-stack-ml">
              <h2 class="no-margin gds-display-1">Choose notifications</h2>
            </div>
            <p class="notification__terms gds-space-stack-l">Select the notification types to send to this contact.</p>
            <form>
              <fieldset v-for="alert of editingContact.alerts" :key="alert.alertTypeId" v-show="ValidToSubscribe(editingContact.channelCode, alert)" class="gds-fieldset">
                <label class="gds-checkbox gds-no-margin">
                  <input v-model="alert.subscribed" type="checkbox" />
                  <span class="notifications__checkbox__faux gds-checkbox__faux"></span>
                  <div class="gds-checkbox__label">{{alert.name}}</div>
                </label>
              </fieldset>
            </form>
          </section>

          <section class="my-account__full-width-section">
            <div class="gds-space-stack-ml">
              <h2 class="no-margin gds-display-1">Do Not Disturb</h2>
            </div>
            <p class="notification__terms gds-space-stack-l">When enabled, we will not send notifications to this contact during the specified times.</p>
            <form class="my-account__dndisturb-form">
              <fieldset class="gds-fieldset">
                <label class="gds-checkbox gds-no-margin">
                  <input v-model="editingContact.dndEnable" type="checkbox" />
                  <span class="gds-checkbox__faux"></span>
                  <span class="gds-checkbox__label gds-font-demi">Don't send me notifications from:</span>
                </label>
              </fieldset>
              <fieldset class="gds-fieldset gds-space-stack-l">
                <div class="my-account-dndisturb gds-flex-container gds-flex-container--space-between">
                  <select v-model="editingContact.dndStart" class="bill-pay-input-field__select">
                    <option value="00:00:00">12:00 AM</option>
                    <option value="01:00:00">1:00 AM</option>
                    <option value="02:00:00">2:00 AM</option>
                    <option value="03:00:00">3:00 AM</option>
                    <option value="04:00:00">4:00 AM</option>
                    <option value="05:00:00">5:00 AM</option>
                    <option value="06:00:00">6:00 AM</option>
                    <option value="07:00:00">7:00 AM</option>
                    <option value="08:00:00">8:00 AM</option>
                    <option value="09:00:00">9:00 AM</option>
                    <option value="10:00:00">10:00 AM</option>
                    <option value="11:00:00">11:00 AM</option>
                    <option value="12:00:00">12:00 PM</option>
                    <option value="13:00:00">1:00 PM</option>
                    <option value="14:00:00">2:00 PM</option>
                    <option value="15:00:00">3:00 PM</option>
                    <option value="16:00:00">4:00 PM</option>
                    <option value="17:00:00">5:00 PM</option>
                    <option value="18:00:00">6:00 PM</option>
                    <option value="19:00:00">7:00 PM</option>
                    <option value="20:00:00">8:00 PM</option>
                    <option value="21:00:00">9:00 PM</option>
                    <option value="22:00:00">10:00 PM</option>
                    <option value="23:00:00">11:00 PM</option>
                  </select>

                  <div class="my-account__text-between-selects">to</div>

                  <select v-model="editingContact.dndEnd" id="my-account-dndisturb-end" class="bill-pay-input-field__select">
                    <option value="00:00:00">12:00 AM</option>
                    <option value="01:00:00">1:00 AM</option>
                    <option value="02:00:00">2:00 AM</option>
                    <option value="03:00:00">3:00 AM</option>
                    <option value="04:00:00">4:00 AM</option>
                    <option value="05:00:00">5:00 AM</option>
                    <option value="06:00:00">6:00 AM</option>
                    <option value="07:00:00">7:00 AM</option>
                    <option value="08:00:00">8:00 AM</option>
                    <option value="09:00:00">9:00 AM</option>
                    <option value="10:00:00">10:00 AM</option>
                    <option value="11:00:00">11:00 AM</option>
                    <option value="12:00:00">12:00 PM</option>
                    <option value="13:00:00">1:00 PM</option>
                    <option value="14:00:00">2:00 PM</option>
                    <option value="15:00:00">3:00 PM</option>
                    <option value="16:00:00">4:00 PM</option>
                    <option value="17:00:00">5:00 PM</option>
                    <option value="18:00:00">6:00 PM</option>
                    <option value="19:00:00">7:00 PM</option>
                    <option value="20:00:00">8:00 PM</option>
                    <option value="21:00:00">9:00 PM</option>
                    <option value="22:00:00">10:00 PM</option>
                    <option value="23:00:00">11:00 PM</option>
                  </select>
                </div>
              </fieldset>

              <div v-if="editingContact.channelCode==='SMS'" class="notification__terms gds-space-stack-l">
                <p>
                  By turning on SMS notifications, you agree to receive power outage or other account-related text messages from
                  <strong>Green Mountain Power</strong>. This service will also allow you to report power outages to GMP and check outage status. For more information, text
                  <strong>HELP</strong> to
                  <strong>46788 (GMPVT)</strong>. To stop notifications, text
                  <strong>STOP</strong> to
                  <strong>46788 (GMPVT)</strong>. Message and data rates may apply. Message frequency varies. See our
                  <a href="/apps-skills/text-alerts/notification-terms-and-conditions/" target="_blank">Terms of Use</a> and
                  <a href="/legal/" target="_blank">Privacy Policy</a>.
                </p>

                <fieldset class="gds-fieldset notifications__long-fieldset">
                  <label class="gds-checkbox">
                    <input v-validate="'required'" name="termsofuse" type="checkbox" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="notifications__checkbox-label-with-link gds-checkbox__label gds-font-demi">
                      I have read and agree to the
                      <a href="/apps-skills/text-alerts/notification-terms-and-conditions/" target="_blank">Terms of Use</a>
                    </span>
                  </label>
                  <div v-if="errors.first('termsofuse')" class="validation-error">{{ errors.first("termsofuse")}}</div>
                </fieldset>
              </div>

              <div class="gds-flex-container gds-flex-container--left gds-space-stack-l">
                <button 
                  @click="editingContact = undefined, saveContactError = undefined, deleteContactError = undefined, deleteContactRequested = undefined"
                  type="button" 
                  class="gds-button gds-secondary gds-space-inline-m"
                  :disabled="savingContact || deletingContact">Cancel</button>
                <button
                  @click="SaveContact()"
                  type="button"
                  :class="{ 'gds-loading': savingContact }"
                  class="gds-button gds-space-inline-m"
                  :disabled="savingContact || deletingContact" ><span class="gds-button__text">Save</span></button>
                  <div v-if="saveContactError" class="delete-contact-error">{{saveContactError}}</div>
              </div>

              <div v-if="!editingContact.isNew">
                <button
                  @click="DeleteContact()" 
                  type="button" 
                  class="my-account__dndisturb-submit gds-button gds-text-button"
                  :class="{ 'gds-loading': deletingContact }"
                  :disabled="savingContact || deletingContact"
                  v-if="!deleteContactRequested">Remove Contact</button>
                  <div v-if="deleteContactError" class="delete-contact-error">{{deleteContactError}}</div>
                  <div v-if="deletingContact" class="table-loading delete-contact-table-loading" />
              </div>
            </form>
          </section>
        </div>

        <!-- Editing a Devce Contact -->
        <div v-if="editingContact && editingContact.channelCode === 'PUSH'">
          <section class="my-account__full-width-section gds-space-stack-xl">
            <div class="gds-flex-container gds-flex-container--space-between gds-space-stack-l">
              <h2 v-if="!editingContact.isNew" class="no-margin gds-display-1">Edit Device</h2>
            </div>
            <form class="my-account__edit-contact-form">
              <span class="gds-input-field__label">Contact Method</span>
              <div class="gds-position--relative gds-flex-container gds-space-stack-l gds-flex-container--left">
                <div class>
                  <img
                    src="/wp-content/themes/gmptwentynineteen/assets/images/device-arrow.svg"
                    alt="not authorized graphic"
                    class="device-notifications__row-item-account--item-icon"
                  />
                </div>
                <div class="device-notifications__row-item-account--details">Push Notification</div>
              </div>

              <span class="gds-input-field__label">Device Information</span>
              <div class="gds-position--relative gds-flex-container gds-space-stack-l gds-flex-container--left">
                <div class="notifications__row-item-account--details">{{editingContact.deviceInformation?editingContact.deviceInformation: 'Unknown Device'}}</div>
              </div>

              <fieldset class="gds-fieldset gds-space-stack-m">
                <label class="gds-input-field">
                  <span class="gds-input-field__label">Nickname</span>
                  <input
                    v-model="editingContact.nickname"
                    v-mask="{mask: 'CCCCCCCCCCCC', tokens: allowedCharacters }"
                    class="gds-input-field__input"
                    type="text"
                    placeholder="Type here..."
                  />
                </label>
              </fieldset>
            </form>
          </section>

          <section class="my-account__full-width-section gds-space-stack-l">
            <div class="gds-space-stack-ml">
              <h2 class="no-margin gds-display-1">Choose notifications</h2>
            </div>
            <p class="notification__terms gds-space-stack-l">Select the notification types to send to this contact.</p>
            <form>
              <fieldset v-for="alert of editingContact.alerts" :key="alert.alertTypeId" v-show="ValidToSubscribe(editingContact.channelCode, alert)" class="gds-fieldset">
                <label class="gds-checkbox gds-no-margin">
                  <input v-model="alert.subscribed" type="checkbox" />
                  <span class="notifications__checkbox__faux gds-checkbox__faux"></span>
                  <div class="gds-checkbox__label">{{alert.name}}</div>
                </label>
              </fieldset>
            </form>
          </section>

          <section class="my-account__full-width-section">
            <form class="my-account__dndisturb-form">
              <div class="gds-flex-container gds-flex-container--left gds-space-stack-l">
                <button 
                  @click="editingContact = undefined, saveContactError = undefined, deleteContactError = undefined, deleteContactRequested = undefined"
                  type="button" 
                  class="gds-button gds-secondary gds-space-inline-m"
                  :disabled="savingContact || deletingContact">Cancel</button>
                <button 
                  @click="SaveContact()"
                  type="button" 
                  :class="{ 'gds-loading': savingContact }" 
                  class="gds-button gds-space-inline-m"
                  :disabled="savingContact || deletingContact"><span class="gds-button__text">Save</span></button>
                  <div v-if="saveContactError" class="delete-contact-error">{{saveContactError}}</div>
              </div>

              <div v-if="!editingContact.isNew">
                <button 
                  @click="DeleteContact()" 
                  type="button"
                  class="my-account__dndisturb-submit gds-button gds-text-button"
                  :disabled="savingContact || deletingContact">Remove Device</button>
                  <div v-if="deleteContactError" class="delete-contact-error">{{deleteContactError}}</div>
                  <div v-if="deletingContact" class="table-loading delete-contact-table-loading" />
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../../services/gmpapi';
import { DumpError } from '../../../utilities';
import { GetComponentStatus } from '../../../services/statuspage';
import BannerOneAction from '../../BannerOneAction.vue'
import { isFeatureEnabled } from '../../../services/featureflags'
import ModalRemoveContactConfirm from './ModalRemoveContactConfirm.vue'

export default {
  name: "SubpageNotificationContacts",
  components: {
    BannerOneAction,
    ModalRemoveContactConfirm
  },
  data() {
    return {
      editingContact: undefined,
      savingContact: false,
      deletingContact: false,
      contacts: [],
      unconfirmed: [],
      deviceContacts: [],
      FF_DeviceContacts: undefined,

      alerttypes: [],
      alertchannels: [],

      allowedCharacters: {
        "C": {
          pattern: /[0-9a-zA-Z ]/,
          pattern: /[0-9a-zA-Z !@#$%^&*()_+~,./?;:'"-=`|ÀÁÂÄÆÃÅĀàáâäæãåāaÈÉÊËĒĖĘèéêëēėęÎÏÍĪĮÌîïíīįìÔÖÒÓŒØŌÕôöòóœøōõŸÿ]/,
        },
      },

      loadState: undefined,
      saveContactError: undefined,
      deleteContactRequested: undefined,
      deleteContactError: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async mounted() {
    this.FF_DeviceContacts = await isFeatureEnabled('FF_DeviceContacts', false);
    await this.PopulateAlertValues();
  },
  methods: {
    async PopulateAlertValues() {
      if (this.loadState === "down") return;
      this.contacts = undefined;
      this.deviceContacts = undefined;
      this.alerttypes = undefined;
      this.alertchannels = undefined;
      this.unconfirmed = undefined;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      // Before we do anything else, check for outages
      const status = await GetComponentStatus("Notification");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        return;
      }
      try {
        const [contacts, alerttypes, alertchannels] = await Promise.all([
          GMPAPI.GetAlertContacts(this.currentAccount.accountNumber),
          GMPAPI.GetAlertTypes(this.currentAccount.accountNumber),
          GMPAPI.GetAlertChannels(),
        ]);
        if (!alerttypes || !alerttypes.length) {
          this.loadState = 'unavailable';
          return;
        }
        let filteredContacts = [];
        let deviceContacts = [];
        for (const contact of contacts) {
          contact.resent = false;

          if (contact.channel.code === 'PUSH') {
            deviceContacts.push(contact)
          } else {
            filteredContacts.push(contact)
          }

        }

        this.contacts = filteredContacts;
        this.deviceContacts = deviceContacts;
        this.alerttypes = alerttypes;
        this.alertchannels = alertchannels;
        this.unconfirmed = contacts.filter(item => item.confirmed !== true);
        this.loadState = "complete";
      } catch (err) {
        DumpError("Notifications refresh error", err);
        this.loadState = "error";
      }
    },
    HasSubscription(contact, alerttype) {
      if (contact.subscriptions) {
        const found = contact.subscriptions.find(item => item.alertType.alertTypeId === alerttype.alertTypeId);
        if (found) return true;
      }
      return false;
    },
    ValidToSubscribe(contactChannelCode, alerttype) {
      if (alerttype.channels) {
        const found = alerttype.channels.find(item => item.code === contactChannelCode);
        if (found) return true;
      }
      return false;
    },
    async Subscribe(contact, alerttype, event) {
      if (event.target.checked) {
        await GMPAPI.SubscribeAlert(this.currentAccount.accountNumber, contact.contactId, alerttype.alertTypeId);
      } else {
        const subscription = contact.subscriptions.find(item => item.alertType.alertTypeId === alerttype.alertTypeId);
        if (subscription) {
          await GMPAPI.UnSubscribeAlert(this.currentAccount.accountNumber, subscription.subscriptionId);
        } else {
          console.error("No subscription for " + contact.value + " " + alerttype.name);
        }
      }
      // Get subscriptions again but don't bother refreshing the whole page
      let contacts = await GMPAPI.GetAlertContacts(this.currentAccount.accountNumber);
      let filteredContacts = [];
      let deviceContacts = [];
      for (const contact of contacts) {
        contact.resent = false;
        if (contact.channel.code === 'PUSH') {
          deviceContacts.push(contact)
        } else {
          filteredContacts.push(contact)
        }
      }
      this.contacts = filteredContacts;
      this.deviceContacts = deviceContacts;
    },
    async ReconfirmContact(contact) {
      await GMPAPI.ResendContactVerification(this.currentAccount.accountNumber, contact.contactId);
      contact.resent = true;
    },
    EditContact(contact) {
      if (!contact) {
        this.editingContact = {
          isNew: true,
          channelCode: "EMAIL",
          dndEnable: false,
          dndStart: "22:00:00",
          dndEnd: "07:00:00",
          alerts: this.alerttypes.map(item => {
            return {
              alertTypeId: item.alertTypeId,
              name: item.name,
              subscribed: true,
              channels: item.channels,
            };
          }),
        };
      } else {
        this.editingContact = {
          contactId: contact.contactId,
          channelCode: contact.channel.code,
          deviceInformation: contact.deviceInformation,
          dndEnable: contact.dndStart || contact.dndEnd,
          dndStart: contact.dndStart || "22:00:00",
          dndEnd: contact.dndEnd || "07:00:00",
          email: contact.channel.code === "EMAIL" ? contact.value : undefined,
          sms: contact.channel.code === "SMS" ? contact.value : undefined,
          nickname: contact.nickname,
          alerts: this.alerttypes.map(item => {
            return {
              alertTypeId: item.alertTypeId,
              name: item.name,
              subscribed: this.HasSubscription(contact, item),
              channels: item.channels,
            };
          }),
          // Track the original subscription states, so we only need to send the changes
          oldAlerts: this.alerttypes.map(item => {
            return {
              alertTypeId: item.alertTypeId,
              name: item.name,
              subscribed: this.HasSubscription(contact, item),
              channels: item.channels,
              subscriptionId: contact.subscriptions.find(sub => sub.alertType.alertTypeId === item.alertTypeId)?.subscriptionId,
            };
          }),
        };
      }
      window.scrollTo(0, 0);
    },
    CancelDeleteContact() {
      this.deleteContactRequested = undefined;
    },
    DeleteContact() {
      this.deleteContactRequested = true;
    },
    async DeleteContactConfirm() {
      try {
        this.deleteContactRequested = undefined;
        this.deletingContact = true;
        this.deleteContactError = undefined;
        await GMPAPI.DeleteContact(this.currentAccount.accountNumber, this.editingContact.contactId);
        await this.PopulateAlertValues();
        this.editingContact = undefined;
      } catch (error) {
        this.deleteContactError = 'There was an error deleting this contact, please try again.';
      } finally {
        this.deletingContact = undefined;
      }
    },
    async SaveContact() {
      if (await this.$validator.validateAll()) {
        this.savingContact = true;
        this.saveContactError = undefined;

        const desiredChannel = this.alertchannels.find(item => item.code === this.editingContact.channelCode);
        const value = this.editingContact.channelCode === "EMAIL" ? this.editingContact.email : this.editingContact.sms;
        const contactDetails = {
          channel: {
            channelId: desiredChannel.channelId,
          },
          nickname: this.editingContact.nickname,
          value: value,
          dndStart: this.editingContact.dndEnable ? this.editingContact.dndStart : null,
          dndEnd: this.editingContact.dndEnable ? this.editingContact.dndEnd : null,
        }
        try {
          if (this.editingContact.isNew) {
            const created = await GMPAPI.CreateContact(this.currentAccount.accountNumber, contactDetails);
            // If they've checked any alerts, subscribe them to them
            for (const alert of this.editingContact.alerts) {
              if (alert.subscribed && this.ValidToSubscribe(this.editingContact.channelCode, alert)) {
                await GMPAPI.SubscribeAlert(this.currentAccount.accountNumber, created.contactId, alert.alertTypeId);
              }
            }
          } else {
            // If they've changed any subscriptions, update them
            for (const alert of this.editingContact.alerts) {
              const oldAlert = this.editingContact.oldAlerts.find(item => item.alertTypeId === alert.alertTypeId);
              if (oldAlert && (oldAlert.subscribed !== alert.subscribed)) {
                // Subscription has changed, update it
                if (alert.subscribed) {
                  await GMPAPI.SubscribeAlert(this.currentAccount.accountNumber, this.editingContact.contactId, alert.alertTypeId);
                } else {
                  await GMPAPI.UnSubscribeAlert(this.currentAccount.accountNumber, oldAlert.subscriptionId);
                }
              }
            }

            await GMPAPI.UpdateContact(this.currentAccount.accountNumber, this.editingContact.contactId, contactDetails);
          }
          // Refresh contacts
          await this.PopulateAlertValues();
          // Hide editor
          this.editingContact = undefined;
        } catch (err) {
          DumpError("Save contact error", err);
          this.saveContactError = 'There was an error saving this contact, please try again.';

          if (err.response && err.response.data) {
            const code = err.response.data.code;
            // Take specific actions on certain error codes here
            if (this.editingContact.channelCode === "SMS" && err.response.data.code === 4 && err.response.data.field === "value") {
              // Make sure we have a fresh ErrorBag for handling special cases
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add({ field: "phone number", msg: "Invalid phone number" });
            }
          }
        }  finally {
          this.savingContact = undefined;
        }
      }
    }
  },
  watch: {
    // Refresh data whenever the account changes
    async currentAccount() {
      await this.PopulateAlertValues();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>