<template>
  <div class="bill-pay-login__main gds-flex-container">
    <div class="bill-pay-login__login-type-container">
      <section id="top-target" class="bill-pay-login__account-select">
        <h2 v-if="!isRestrictedRebateUser" class="gds-display-1">Choose Account</h2>
        <h2 v-if="isRestrictedRebateUser" class="gds-display-1">Enter the customer account number</h2>
        <form v-if="isRestrictedRebateUser" @submit.prevent="getAccount()" class="gds-space-stack-ml" data-vv-scope="page1">
            <input v-model="selectedAccountNumber" v-validate="'required|numeric|length:10'" 
                v-mask="'##########'" name="account number" key="account number" 
                class="gds-input-field__input" type="text" placeholder="Enter 10 digit account number" />
            <div v-if="errors.first('account number', 'page1')" class="validation-error">{{ errors.first("account number","page1") }}</div>
            <div v-if="loadState === 'empty'" class="gds-space-stack-m eicproduct-validation-error">Account not found. Please try again.</div>
        </form>
        <form v-if="!isRestrictedRebateUser" class="bill-pay-login__form" @submit.prevent data-vv-scope="page1">
          <fieldset class="gds-fieldset">
            <legend class="gds-body-normal gds-space-stack-ml">Please select the account number associated with the location where the product will be housed:</legend>
            <label v-for="account in accounts" :key="account.accountNumber" class="bill-pay-login__form--radio-label-flex gds-radio">
              <input v-model="selectedAccountNumber" type="radio" :value="account.accountNumber" />
              <span class="gds-radio__faux"></span>
              <span class="gds-radio__label bill-pay-login__radio-label">
                <span v-if="account.nickname">
                  <b>{{ account.nickname }}</b>
                  <br />
                </span>
                {{ account.address | addressFormat({ separator: ", " }) }}
                <br />
                Acct #: {{ account.accountNumber }}
                <br />
                <!-- TODO: Validation: div cannot be a child of a span -->
              </span>
            </label>
          </fieldset>
        </form>

        <div class="gds-space-stack-l">
          <button @click="ReturnToSender(returnToSender)" type="button" class="gds-button gds-secondary gds-space-inline-m">Cancel</button>
          <button @click="nextPage()" type="button" class="gds-button gds-space-inline-m">Next</button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import { ErrorBag } from "vee-validate";
import { DumpError, ReturnToSender } from "../../utilities";
import GMPAPI from "../../services/gmpapi";


export default {
  name: "EICProductsAccount",
  props: {
    returnToSender: String,
  },
  data() {
    return {
      ReturnToSender,
      selectedAccountNumber: undefined,
      loadState: undefined
    };
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
    isRestrictedRebateUser() {
      return !!this.$store.state?.user?.userinfo?.groups?.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
    accounts() {
      if (!this.isAdmin && !this.isAdminLite) {
        return this.$store.state.user.accounts;
      } else {
        return this.$store.state.user.ghostAccounts || [];
      }
    },
  },
  async mounted() {
    try {
      if (this.accounts.length === 1) {
        this.$router.push({ path: "/form/1" });
      } 
    } catch (err) {
      DumpError("Account mount error", err);
    }
  },
  methods: {
    async getAccount() {
      const accounts = await GMPAPI.GetAccounts([this.selectedAccountNumber]);
      let selectedAccount;
      if (accounts != null && accounts.length) {
        selectedAccount = JSON.parse(JSON.stringify(accounts[0]));
      }
      this.$store.commit("setCurrentAccount", selectedAccount);
      return selectedAccount;
    },

    isAccountNumberValid() {
      return this.$validator.validateAll('page1');
    },

    async nextPage() {
      const localProduct = this.getWithExpiry("localProductSubmission");
      let localProductAccount;
      if (localProduct) {
        localProductAccount = localProduct.account.accountNumber
      }

      if (localProductAccount !== this.selectedAccountNumber) {
        localStorage.removeItem("localProductSubmission")
      }
      try {
        let selected;
        if (!this.isRestrictedRebateUser) {
           this.loadState = undefined;
           selected = this.accounts.find(item => {
            return item.accountNumber === this.selectedAccountNumber
          });
        } else {
            if (this.isAccountNumberValid()) {
              this.loadStatus = undefined;
              selected = await this.getAccount();
              this.loadState = 'complete';
            }
        }
        this.$store.commit("setCurrentAccount", selected);
        this.$router.push({ path: "/form/1" });
      } catch (err) {
        if (err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          DumpError("Account next error", err);
        }
      }
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>