var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.incidentStatus !== "Resolved" &&
    (_vm.incident.estimatedRestoredAt || _vm.incident.etrLatest)
      ? _c("div", { staticClass: "gds-space-stack-m" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.formatEtr(
                  _vm.incident.estimatedRestoredAt,
                  _vm.incident.etrEarliest,
                  _vm.incident.etrLatest
                )
              )
          )
        ])
      : _vm.incident.restoredAt && _vm.incidentStatus === "Resolved"
      ? _c("div", { staticClass: "gds-space-stack-m" }, [
          _vm._v(
            "Power was restored on " +
              _vm._s(_vm._f("dateRestored")(_vm.incident.restoredAt)) +
              "."
          )
        ])
      : _c("div", { staticClass: "gds-space-stack-m" }, [
          _vm._v(_vm._s(_vm.etrText))
        ]),
    _vm._v(" "),
    _c("section", [
      _c("div", { staticClass: "gds-space-stack-m" }, [
        _c(
          "div",
          { staticClass: "gds-font-size-s gds-font-demi gds-space-stack-xs" },
          [_vm._v("Reported")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gds-font-size-m gds-font-book gds-text-grey" },
          [_vm._v(_vm._s(_vm._f("dateRestored")(_vm.incident.createdAt)))]
        )
      ]),
      _vm._v(" "),
      _vm.incident.createdAt &&
      _vm.incidentStatus === "Resolved" &&
      _vm.incident.restoredAt
        ? _c("div", { staticClass: "gds-space-stack-m" }, [
            _c(
              "div",
              {
                staticClass: "gds-font-size-s gds-font-demi gds-space-stack-xs"
              },
              [_vm._v("Restored")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gds-font-size-m gds-font-book gds-text-grey" },
              [_vm._v(_vm._s(_vm._f("dateRestored")(_vm.incident.restoredAt)))]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }