var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.billingSummaryLoadstate !== "loading"
    ? _c(
        "div",
        [
          !_vm.isNetMetered ? _c("PerformanceChart") : _vm._e(),
          _vm._v(" "),
          _vm.isNetMetered ? _c("PerformanceChartNetMetered") : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }