<template>
  <div v-if="visible" class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal">
      <button @click="close" type="button" class="close bill-pay__close">
        <svg class="gds-icon close-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
        </svg>
      </button>
      <FocusLock>
        <div class="past-due-wizard__modal-box--body">
          <div
            class="gds-font-size-xl gds-font-demi gds-space-stack-m"
          >You will be enrolled in Budget Billing. Your consistent monthly payment of ${{ this.budgetBillingStatus.totalBudgetAmount }}/mo for ongoing usage is based on your average usage. If your average usage changes then your Budget Billing is also subject to change. If you stop Budget Billing, your next bill will include an adjustment to true up your account with your actual balance. This is a way to smooth out payments offering you predictability and convenience so you can plan. This is not a discount.</div>
          <div class="my-account__modal-box--message gds-space-stack-l"></div>
          <div class="gds-space-stack-ml"></div>
          <button @click="close" type="button" class="gds-button">Ok</button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>

export default {
  name: "BudgetBillingTerms",
  props: {
    visible: Boolean,
    close: Function,
    budgetBillingStatus: Object
  },
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>