import { isFeatureEnabled } from "../../../../services/featureflags";

export default {
  data() {
    return {
      FF_NetMeteredDashboard: undefined,
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    containerElement() {
      return this.$refs.container;
    },
  },
  async mounted(){
    await this.getConfigs();
  },
  methods: {
    async getConfigs() {
      this.FF_NetMeteredDashboard = await isFeatureEnabled('FF_NetMeteredDashboard', false);
    },
    emitOptionValue(option) {
      this.$emit('change-options-event', option);
    }
  }
};