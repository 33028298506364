<template>
  <div v-if="accounts">
    <div @click="ToggleAccountSwitcher" @keyup.enter="ToggleAccountSwitcher" class="account-profile-button" :class="{ 'minified': minified }" tabindex="0">
        <div v-if="currentAccount" class="account-profile-button__inner">
          <div v-if="currentAccount.photoUrl" :style="{backgroundImage: 'url(' + GetResizedAccountPhoto(currentAccount.photoUrl) + ')'}" class="small-round-img"></div>
          <div class="account-switcher__nickname gds-font-demi">{{currentAccount | accountName}}</div>
        </div>
        <div v-if="!currentAccount" class="account-profile-button__inner">
          <div style="backgroundImage: url(/wp-content/themes/gmptwentynineteen/assets/images/default-account.png)" class="small-round-img"></div>
          <div class="gds-font-demi">No account selected</div>
        </div>
      </div>
  </div>
</template> 

<script>
import { GetResizedAccountPhoto } from "../utilities";

export default {
  name: "WidgetAccountSwitcher",
  props: {
    minified: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      GetResizedAccountPhoto
    };
  },
  computed: {
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  methods: {
    ToggleAccountSwitcher() {
      this.$store.commit("toggleAccountSwitcher");
      // This prevents the page from scrolling down to where it was previously.
      if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual';
      }
      // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded.Cross-browser supported.
      window.scrollTo(0,0);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>