var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "welcome-message welcome-message--clear-day" }, [
      !_vm.loggedInUser
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "welcome-message__headline gds-font-medium gds-space-stack-l"
              },
              [_vm._v(_vm._s(_vm.homepageHeading))]
            ),
            _vm._v(" "),
            _vm.homepageSubheading
              ? _c("span", { staticClass: "welcome-message__subheadline" }, [
                  _vm._v(_vm._s(_vm.homepageSubheading))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.homepageLink && _vm.homepageLink.title
              ? _c("span", { staticClass: "welcome-message__cta" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.homepageLink.url,
                        target: _vm.homepageLink.target
                      }
                    },
                    [_vm._v(_vm._s(_vm.homepageLink.title))]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.homepageLink && !_vm.homepageLink.title
              ? _c("span", { staticClass: "welcome-message__cta" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "gds-card__button gds-button gds-button-circle gds-secondary",
                      attrs: {
                        href: _vm.homepageLink.url,
                        target: _vm.homepageLink.target
                      }
                    },
                    [
                      _c("svg", { staticClass: "gds-icon" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href":
                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"
                          }
                        })
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loggedInUser
        ? _c(
            "div",
            {
              staticClass:
                "welcome-message__headline gds-font-medium gds-space-stack-l"
            },
            [_vm._v("Welcome back, " + _vm._s(_vm.loggedInUser.givenName))]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "welcome-message welcome-message--storm-mode" }, [
      _c(
        "div",
        {
          staticClass:
            "welcome-message__headline gds-font-medium gds-space-stack-ml"
        },
        [_vm._v(_vm._s(_vm.stormWelcome))]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "gds-body-medium gds-space-stack-ml",
        domProps: { innerHTML: _vm._s(_vm.stormMessage) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }