<template>
  <div class="subpage-dashboards subpage-dashboard--dashboard">
    <h1 class="my-account__title gds-space-stack-l">My Account Dashboard</h1>

    <widget-account-planned-outages />
    <widget-scheduled-payments />
    <banner-one-action
      v-if="isSixtyDaysPastDue && !isRestrictedRebateUser"
      bannerColor="blue"
      message="Part of your balance is past due."
      linkText="See Assistance Programs"
      url="/past-due-balance-assistance/"
    ></banner-one-action>
    <section class="my-account__card-section gds-flex-container gds-flex-container--top gds-flex-container--wrap">
      <card-account-profile></card-account-profile>
      <card-report-issue></card-report-issue>
      <card-pay-bill></card-pay-bill>
      <card-latest-bill></card-latest-bill>
      <card-recent-transactions></card-recent-transactions>
      <card-usage></card-usage>
      <card-outages-near-you></card-outages-near-you>
      <card-web-chat></card-web-chat>
    </section>
  </div>
</template>

<script>
import { isAccountXDaysPastDue } from "../../../services/billing"

import CardAccountProfile from "./CardAccountProfile";
import CardReportIssue from "../../CardReportIssue";
import CardPayBill from "../../CardPayBill";
import CardLatestBill from "./CardLatestBill";
import CardRecentTransactions from "./CardRecentTransactions";
import CardUsage from "./CardUsage";
import CardOutagesNearYou from "./CardOutagesNearYou";
import CardWebChat from "./CardWebChat";
import WidgetAccountPlannedOutages from "../../outagecenter/WidgetAccountPlannedOutages";
import BannerOneAction from "../../BannerOneAction.vue"
import WidgetScheduledPayments from "../billingpayment/WidgetScheduledPayments.vue";
import GMPAPI from "../../../services/gmpapi";

export default {
  name: "SubpageDashboard",
  components: {
    CardAccountProfile,
    CardReportIssue,
    CardPayBill,
    CardLatestBill,
    CardRecentTransactions,
    CardUsage,
    CardOutagesNearYou,
    CardWebChat,
    WidgetAccountPlannedOutages,
    BannerOneAction,
    WidgetScheduledPayments,
  },
  data() {
    return {
      isSixtyDaysPastDue: undefined
    };
  },
  watch: {
    currentAccount(newCurrentAccount) {
      this.setSixtyDaysPastDue(newCurrentAccount)
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isRestrictedRebateUser() {
      return !!this.$store.state.user.userinfo.groups.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
  },
  async mounted() {
    this.setSixtyDaysPastDue(this.currentAccount);
  },
  methods: {
    async setSixtyDaysPastDue(newCurrentAccount) {
      this.isSixtyDaysPastDue = await isAccountXDaysPastDue(newCurrentAccount, 60);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>