var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "widget-account--single-row gds-flex-container gds-flex-container--space-between gds-space-stack-l"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "gds-flex-container gds-flex-container--left widget-account--single-row--left gds-clickable",
              on: {
                click: function($event) {
                  return _vm.Dashboard()
                }
              }
            },
            [
              _vm.account.photoUrl
                ? _c("div", {
                    staticClass: "small-round-img gds-space-inline-default",
                    style: {
                      backgroundImage:
                        "url(" +
                        _vm.GetResizedAccountPhoto(_vm.account.photoUrl) +
                        ")"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "user-profile__account-nickname-address-wrap" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "user-profile__account-nickname gds-font-demi"
                    },
                    [_vm._v(_vm._s(_vm._f("accountName")(_vm.account)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "user-profile__account-address gds-font-size-m gds-linebreaks"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("addressFormat")(_vm.account.address, {
                            separator: "\n",
                            skipFirst: false
                          })
                        )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "gds-font-size-m gds-linebreaks" }, [
                    _vm._v("Acct. #" + _vm._s(_vm.account.accountNumber))
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "gds-flex-container widget-account--single-row--right"
            },
            [
              _c("div", { staticClass: "user-profile__account-status" }, [
                _c(
                  "div",
                  {
                    staticClass: "gds-flex-container gds-flex-container--left"
                  },
                  [
                    _vm.account.isPrimary
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "gds-flex-container gds-flex-container--bottom"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "my-account__green-check-cirlce" },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass:
                                      "my-account__white-check-icon gds-icon"
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-white-check-on-green"
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "user-profile__account-status-label gds-font-demi gds-font-size-m gds-space-inline-l"
                              },
                              [_vm._v("Primary Account")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.account.isPrimary
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "gds-button gds-text-button gds-space-inline-l",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.SetPrimary()
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "gds-button__text gds-button__text-m"
                              },
                              [_vm._v("Set as Primary Account")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "user-profile__account-edit-links" }, [
                _c(
                  "button",
                  {
                    staticClass: "gds-button gds-text-button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.Edit()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "gds-button__text gds-button__text-m" },
                      [_vm._v("Edit Settings / Remove")]
                    )
                  ]
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }