<template>
  <nav class="my-account__sidebar__nav">
    <router-link to="/" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '' }">Dashboard</router-link>
    <router-link to="/billing" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '/billing' }">Billing &amp; Payment</router-link>
    <router-link
      v-if="currentAccount && currentAccount.solarNetMeter"
      to="/netmetering"
      class="my-account__sidebar__nav-item"
      :class="{ 'active': subPage === '/netmetering' }"
    >Net Metering</router-link>
    <router-link to="/usage" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '/usage' || subPage === '/usage-new'}">Usage</router-link>
    <router-link v-if="FF_Devices" to="/devices" class="my-account__sidebar__nav-item" :class="{ 'active': isDevicesRoute}">Devices</router-link>
    <router-link v-if="FF_Devices" to="/temp-assets" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '/temp-assets'}">Temp Assets</router-link>
    <router-link to="/notifications" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '/notifications' }">Notifications</router-link>
    <router-link to="/settings" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '/settings' }">Account Settings</router-link>
    <a href="/start-stop-move-service" id="my-account-nav__start-stop-move" class="my-account__sidebar__nav-item arrow-after-link" target="_blank" rel="noopener">Start, Stop, Move</a>
  </nav>
</template>

<script>
import { isFeatureEnabled } from "../../services/featureflags";

export default {
  name: "WidgetMyAccountNav",
  data() {
    return {
      FF_Devices: undefined
    };
  },
  async mounted() {
    this.FF_Devices = await isFeatureEnabled('FF_Devices', false);
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    subPage() {
      if (this.$route.matched.length) {
        return this.$route.matched[0].path;
      }
      return "";
    },
    isDevicesRoute() {
      const subPage = this.subPage;
      const isDevicesRoute = (subPage === '/devices');
      const isAddDeviceRoute = (subPage === '/devices/add-device');
      const isDeviceDetailRoute = !isAddDeviceRoute && subPage.indexOf('/devices/device/') > -1;

      return isDevicesRoute || isAddDeviceRoute || isDeviceDetailRoute;
    },
  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>