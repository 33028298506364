var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Information for the specified planned outage",
              state: "error"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c(
            "div",
            [
              _c("outage-breadcrumbs", {
                attrs: { planned: _vm.plannedOutage }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "town-incident__row gds-flex-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "town-incident__column--details incident-details"
                    },
                    [
                      _vm.plannedOutage
                        ? _c("div", [
                            _vm.plannedOutage.status === "IN_PROGRESS"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "planned-outage__top-label-and-icon active-outage gds-font-size-l"
                                  },
                                  [_vm._v("Active Planned Outage")]
                                )
                              : _vm.plannedOutage.status === "COMPLETED"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "planned-outage__top-label-and-icon complete-outage gds-font-size-l"
                                  },
                                  [_vm._v("Complete Planned Outage")]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "planned-outage__top-label-and-icon planned-outage gds-font-size-l"
                                  },
                                  [_vm._v("Planned Outage")]
                                ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "gds-text--capitalize gds-font-size-xxl gds-space-stack-l"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.outageLocation.toLowerCase()) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.plannedOutage.status === "IN_PROGRESS"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gds-font-size-l gds-font-demi gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "\n            The planned outage near " +
                                        _vm._s(_vm.outageLocation) +
                                        " is currently underway. The purpose of the outage is to allow\n            crews to safely fix our infrastructure."
                                    )
                                  ]
                                )
                              : _vm.plannedOutage.status === "COMPLETED"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gds-font-size-l gds-font-demi gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "An outage was planned near " +
                                        _vm._s(_vm.outageLocation) +
                                        "\n            to allow crews to safely fix our infrastructure."
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gds-font-size-l gds-font-demi gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "An outage is planned near " +
                                        _vm._s(_vm.outageLocation) +
                                        " to allow crews to safely fix our infrastructure."
                                    )
                                  ]
                                ),
                            _vm._v(" "),
                            _c("div", { staticClass: "gds-space-stack-l" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "planned-outage__list-details-row"
                                },
                                [
                                  _c("div", { staticClass: "gds-font-demi" }, [
                                    _vm._v("Planned start time:")
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateTimeFull")(
                                          _vm.plannedOutage.occursAt
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "planned-outage__list-details-row"
                                },
                                [
                                  _c("div", { staticClass: "gds-font-demi" }, [
                                    _vm._v("Planned end time:")
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateTimeFull")(
                                          _vm.plannedOutage.endsAt
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "planned-outage__list-details-row"
                                },
                                [
                                  _c("div", { staticClass: "gds-font-demi" }, [
                                    _vm._v("Planned length:")
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("durationMinutes")(
                                          _vm.plannedOutage.outageDuration
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.plannedOutage.outageReason
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "planned-outage__list-details-row"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "gds-font-demi" },
                                        [_vm._v("Reason:")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.plannedOutage.outageReason)
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _vm.myAffectedAccounts &&
                            _vm.myAffectedAccounts.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "planned-outage__your-location gds-font-size-l gds-font-demi gds-space-stack-l"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "gds-space-stack-m" },
                                      [
                                        _vm._v(
                                          "Your Affected " +
                                            _vm._s(
                                              _vm.myAffectedAccounts.length > 1
                                                ? "Locations"
                                                : "Location"
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.myAffectedAccounts, function(
                                      affectedAccount
                                    ) {
                                      return _c(
                                        "div",
                                        { key: affectedAccount.accountNumber },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "gds-flex-container gds-flex-container--left"
                                            },
                                            [
                                              _c("div", [
                                                _vm.plannedOutage.status ===
                                                "IN_PROGRESS"
                                                  ? _c("img", {
                                                      staticClass:
                                                        "home-circle-icon gds-space-inline-m",
                                                      attrs: {
                                                        src:
                                                          "/wp-content/themes/gmptwentynineteen/assets/images/icon-home-circle-alert.svg"
                                                      }
                                                    })
                                                  : _c("img", {
                                                      staticClass:
                                                        "home-circle-icon gds-space-inline-m",
                                                      attrs: {
                                                        src:
                                                          "/wp-content/themes/gmptwentynineteen/assets/images/icon-home-circle-planned.svg"
                                                      }
                                                    })
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("accountName")(
                                                        affectedAccount
                                                      )
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-linebreaks"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("addressFormat")(
                                                          affectedAccount.address,
                                                          {
                                                            separator: "\n",
                                                            skipFirst: true
                                                          }
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.affectedTowns && _vm.affectedTowns.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "gds-font-size-l gds-font-demi gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "\n            This planned outage\n            " +
                                        _vm._s(
                                          _vm.plannedOutage.status ===
                                            "IN_PROGRESS"
                                            ? "is affecting"
                                            : _vm.plannedOutage.status ===
                                              "COMPLETED"
                                            ? "affected"
                                            : "will affect"
                                        ) +
                                        "\n            " +
                                        _vm._s(_vm.totalCustomers) +
                                        " customer" +
                                        _vm._s(
                                          _vm.totalCustomers > 1 ? "s" : ""
                                        ) +
                                        "\n            " +
                                        _vm._s(
                                          _vm.myAffectedAccounts.length > 0
                                            ? "(including you)"
                                            : ""
                                        ) +
                                        "\n            in " +
                                        _vm._s(
                                          _vm.affectedTowns.length > 1
                                            ? _vm.affectedTowns.length +
                                                " towns"
                                            : _vm.affectedTowns[0].name
                                        ) +
                                        ".\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.affectedTowns && _vm.affectedTowns.length >= 1
                              ? _c(
                                  "div",
                                  _vm._l(_vm.affectedTowns, function(town) {
                                    return _c(
                                      "div",
                                      {
                                        key: town.name,
                                        staticClass:
                                          "town-incident__affected-towns-list"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-flex-container gds-flex-container--space-between"
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "Town",
                                                    params: {
                                                      townname: town.slug
                                                    }
                                                  }
                                                }
                                              },
                                              [_vm._v(_vm._s(town.name))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "gds-font-size-m"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(town.customers) +
                                                    " customer" +
                                                    _vm._s(
                                                      town.customers > 1
                                                        ? "s"
                                                        : ""
                                                    )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "town-incident__column--map gds-position--relative"
                    },
                    [
                      _c("gmp-map", {
                        ref: "map",
                        attrs: {
                          kind: "planned",
                          plannedShape: _vm.plannedShape
                        },
                        on: { update: _vm.onMapUpdate }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }