export default {
  namespaced: false,
  state: {
    logindrawervisible: false,
    accountswitchervisible: false,
    usermenuvisible: false,
    routeloading: false,
  },
  // Changes the state
  mutations: {
    setLoginDrawerVisible(state, visible) {
      state.logindrawervisible = visible;
    },
    toggleLoginDrawer(state) {
      state.logindrawervisible = !state.logindrawervisible;
    },
    setAccountSwitcherVisible(state, visible) {
      state.accountswitchervisible = visible;
    },
    toggleAccountSwitcher(state) {
      state.accountswitchervisible = !state.accountswitchervisible;
    },
    setUserMenuVisible(state, visible) {
      state.usermenuvisible = visible;
    },
    toggleUserMenu(state) {
      state.usermenuvisible = !state.usermenuvisible;
    },
    setRouteLoading(state, loading) {
      state.routeloading = loading;
    },
 },
}