var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-detail__table-container" }, [
    _vm.loadState === undefined
      ? _c("div", { staticClass: "table-loading" }, [_vm._v("Loading")])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "empty"
      ? _c("div", { staticClass: "usage-detail__error-container" }, [
          _vm._v("No daily historical usage data available.\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState == "error"
      ? _c("div", { staticClass: "usage-detail__error-container" }, [
          _c("p", [
            _vm._v(
              "An unexpected error occurred trying to read\n    daily historical usage data, please try again later."
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "complete"
      ? _c("table", { staticClass: "usage-detail__hourly-table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", { staticClass: "heading-cell time" }, [_vm._v("Time")]),
              _vm._v(" "),
              _vm.isNetMetered
                ? _c("th", { staticClass: "heading-cell grow-basis-10" }, [
                    _vm._v("Total Home Consumption")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isNetMetered
                ? _c("th", { staticClass: "heading-cell grow-basis-10" }, [
                    _vm._v("Total Generation")
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isNetMetered && !_vm.hasTOU
                ? _c("th", { staticClass: "heading-cell grow-basis-20" }, [
                    _vm._v("Consumption")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isNetMetered && !_vm.hasTOU
                ? _c("th", { staticClass: "heading-cell grow-basis-20" }, [
                    _vm._v("Consumed From Grid")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasEvCharger
                ? _c("th", { staticClass: "grow-basis-20" }, [
                    _vm._v("EV Charger")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasTOU
                ? _c("th", { staticClass: "heading-cell grow-basis-10" }, [
                    _vm._v("Time Of Use")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("th", { staticClass: "heading-cell" }, [_vm._v("Temp.")]),
              _vm._v(" "),
              _c("th", { staticClass: "heading-cell" }, [_vm._v("Conditions")])
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.hourlyUsageData, function(usage) {
              return _c("tr", { key: usage.date.getTime() }, [
                _c("td", { staticClass: "usage-detail__usage-date" }, [
                  _vm._v(
                    _vm._s(_vm.convertToTimeRange(usage.date)) + "\n        "
                  )
                ]),
                _vm._v(" "),
                _vm.isNetMetered
                  ? _c(
                      "td",
                      {
                        staticClass: "usage-detail__data-series",
                        attrs: { "data-series": "totalEnergyUsed" }
                      },
                      [
                        _vm._v(
                          "\n    " +
                            _vm._s(
                              _vm._f("numberCommaSingleDecimal")(
                                usage.totalEnergyUsed
                              )
                            ) +
                            " "
                        ),
                        _c("span", { staticClass: "unit" }, [_vm._v("kWh")]),
                        _vm._v(" "),
                        _c("Usage-Consumption-Bar-CSS", {
                          attrs: {
                            series1: usage.totalEnergyUsed,
                            maxUsageValue: _vm.maxDailyUsageValue
                          }
                        }),
                        _vm._v(" "),
                        _vm.showPeakEventFlag(usage)
                          ? _c("span", { staticClass: "notice" }, [
                              _vm._v("Battery usage by grid may show as"),
                              _c("br"),
                              _vm._v("negative usage or generation")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isNetMetered
                  ? _c(
                      "td",
                      {
                        staticClass: "usage-detail__data-series",
                        attrs: { "data-series": "generation" }
                      },
                      [
                        _vm._v(
                          "\n    " +
                            _vm._s(
                              _vm._f("numberCommaSingleDecimal")(
                                _vm.determineGenerationAmount(usage)
                              )
                            ) +
                            "\n    "
                        ),
                        _c("span", { staticClass: "unit" }, [_vm._v("kWh")]),
                        _vm._v(" "),
                        _c("span", [
                          _c("span", { staticClass: "sub-series" }, [
                            _vm.hasGenerationValue(usage)
                              ? _c("span", [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm._f("numberCommaSingleDecimal")(
                                          _vm.getGenerationToHome(usage)
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("kWh to home,")
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasGenerationValue(usage)
                              ? _c("span", [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm._f("numberCommaSingleDecimal")(
                                          usage.returnedGeneration
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("kWh to grid")
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        usage.generation > 0 && usage.returnedGeneration > 0
                          ? _c("Usage-Consumption-Bar-CSS", {
                              attrs: {
                                series1: _vm.getGenerationToHome(usage),
                                series2: usage.returnedGeneration,
                                maxUsageValue: _vm.maxDailyUsageValue
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        usage.generation > 0 &&
                        (usage.returnedGeneration === undefined ||
                          usage.returnedGeneration === 0)
                          ? _c("Usage-Consumption-Bar-CSS", {
                              attrs: {
                                series1:
                                  usage.generation - usage.returnedGeneration,
                                series2: 0,
                                maxUsageValue: _vm.maxDailyUsageValue
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        (usage.generation === undefined ||
                          usage.generation === 0) &&
                        usage.returnedGeneration > 0
                          ? _c("Usage-Consumption-Bar-CSS", {
                              attrs: {
                                series1: 0,
                                series2: usage.returnedGeneration,
                                maxUsageValue: _vm.maxDailyUsageValue
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.hasTOU
                  ? _c(
                      "td",
                      {
                        staticClass: "usage-detail__data-series",
                        attrs: { "data-series": "consumedTotal" }
                      },
                      [
                        _vm._v(
                          "\n    " +
                            _vm._s(
                              _vm._f("numberCommaSingleDecimal")(
                                usage.consumedTotal
                              )
                            ) +
                            " "
                        ),
                        _c("span", { staticClass: "unit" }, [_vm._v("kWh")]),
                        _vm._v(" "),
                        _c("Usage-Consumption-Bar-CSS", {
                          attrs: {
                            series1: usage.consumedTotal,
                            maxUsageValue: _vm.maxDailyUsageValue
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasEvCharger
                  ? _c(
                      "td",
                      {
                        staticClass: "usage-detail__data-series",
                        attrs: { "data-series": "evCharger" }
                      },
                      [
                        usage.evConsumedTotal === 0
                          ? _c("span", [_vm._v(" ")])
                          : _vm._e(),
                        _vm._v(" "),
                        usage.evConsumedTotal > 0
                          ? _c(
                              "span",
                              [
                                _c("span", { staticClass: "main-series" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("numberCommaSingleDecimal")(
                                        usage.evConsumedTotal
                                      )
                                    ) + " "
                                  ),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("kWh")
                                  ])
                                ]),
                                _vm._v(" "),
                                usage.evPeakConsumption > 0 &&
                                usage.evOffPeakConsumption > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "sub-series parenthesis-wide"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "sub-series" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f(
                                                  "numberCommaSingleDecimal"
                                                )(usage.evPeakConsumption)
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "unit" },
                                              [_vm._v("kWh Peak,")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "sub-series" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f(
                                                    "numberCommaSingleDecimal"
                                                  )(usage.evOffPeakConsumption)
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "unit" },
                                              [_vm._v("kWh Off Peak")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("Usage-Consumption-Bar-CSS", {
                                  attrs: {
                                    series1: usage.evPeakConsumption,
                                    series2: usage.evOffPeakConsumption,
                                    maxUsageValue: _vm.maxDailyUsageValue
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasTOU
                  ? _c(
                      "td",
                      {
                        staticClass: "usage-detail__data-series",
                        attrs: { "data-series": "consumedTOU" }
                      },
                      [
                        _vm._v(
                          "\n    " +
                            _vm._s(
                              _vm._f("numberCommaSingleDecimal")(
                                usage.consumedTotal
                              )
                            ) +
                            " "
                        ),
                        _c("span", { staticClass: "unit" }, [_vm._v("kWh,")]),
                        _vm._v(" "),
                        usage.consumedTotal > 0
                          ? _c("span", { staticClass: "sub-series" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("numberCommaSingleDecimal")(
                                    _vm.getOnPeakUsage(usage)
                                  )
                                ) + " "
                              ),
                              _c("span", { staticClass: "unit" }, [
                                _vm._v("kWh Peak")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("numberCommaSingleDecimal")(
                                      _vm.getOffPeakUsage(usage)
                                    )
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "unit" }, [
                                _vm._v("kWh Off Peak")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("Usage-Consumption-Bar-CSS", {
                          attrs: {
                            series1: _vm.getOnPeakUsage(usage),
                            series2: _vm.getOffPeakUsage(usage),
                            maxUsageValue: _vm.maxDailyUsageValue
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.getTemperature(usage).avg
                        ? _vm.getTemperature(usage).avg + "\u00B0F"
                        : ""
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", [
                  usage.weatherIcon && usage.weatherIcon != ""
                    ? _c("img", {
                        staticClass: "usage-detail__weather-image",
                        attrs: {
                          src: _vm.weatherImagePath + usage.weatherIconImageUrl,
                          alt: usage.weatherIconImageUrl
                        }
                      })
                    : _vm._e()
                ])
              ])
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }