<template>
  <div class="subpage-dashboards subpage-report--dashboard subpage-dashboards--left-spacer"
    v-if="columns.length > 0 && rows.length > 0">
    <a href="/account/report" class="gds-link gds-link--bold cursorPointer">Return to Report Page</a>
    <section class="custom-report-table my-account__full-width-section gds-space-stack-xl">
      <vue-good-table :columns="columns" :rows="rows" styleClass="vgt-table striped" :group-options="{
        enabled: true,
        collapsable: true
      }" />
    </section>
  </div>
</template>
<script>
import { format, parseISO } from "date-fns";

export default {
  name: "CustomReport",
  props: {
    billingReport: Array,
  },
  components: {
  },
  data() {
    return {
      monthFormat: "MMMM yyyy",
      columns: [
        {
          label: 'Bill Date',
          field: 'date',
        },
        {
          label: 'Amount',
          field: 'amount'
        },
        {
          label: 'Quantity',
          field: 'quantity'
        }
      ],
      rows: []
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    if (!this.billingReport) {
      this.$router.push({ path: '/report' });
      return;
    }
    this.setRows();
    this.setData();
    this.filterRows();
    this.addTotalRow();
  },
  methods: {
    setRows() {
      let keys = Object.keys(this.billingReport[0])
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        if (key.includes('_amt')) {
          this.rows.push({
            mode: 'span',
            label: key.replace('_amt', ''),
            children: []
          })
        }
      }
      this.rows.push({
        mode: 'span',
        label: 'Monthly Totals',
        children: []
      })
    },
    setData() {
      for (let h = 0; h < this.rows.length; h++) {
        let row = this.rows[h];
        for (let i = 0; i < this.billingReport.length; i++) {
          let billingReport = this.billingReport[i]

          if (row.label === 'Monthly Totals') {
            const date = parseISO(billingReport["Bill Date"])
            row.children.push({
              date: format(date, "MMM dd, yyyy"),
              amount: billingReport["Bill Amount"] ? `$${billingReport["Bill Amount"].toFixed(2)}` : '',
            })
          }
          let child = {
            date: undefined,
            amount: undefined,
            quantity: undefined
          }

          let entries = Object.entries(billingReport)
          for (let j = 0; j < entries.length; j++) {
            const billReportItemKey = entries[j][0];
            const billReportItemValue = entries[j][1];

            if (billReportItemKey.includes(row.label) && billReportItemKey.includes('_amt') && billReportItemValue) {
              child.amount = `$${billReportItemValue.toFixed(2)}`
            } else if (billReportItemKey.includes(row.label) && billReportItemKey.includes('_qty') && billReportItemValue) {
              const date = parseISO(billingReport["Bill Date"])
              child.date = format(date, "MMM dd, yyyy");
              child.quantity = billReportItemValue;
            }
          }
          if (
            child.date &&
            child.quantity) {
            this.rows[h].children.push(child);
          }
        }
      }
    },
    filterRows() {
      const filtered = this.rows.filter(row => row.children.length !== 0);
      this.rows = filtered;
    },
    addTotalRow() {
      let total = 0;
      for (let i = 0; i < this.rows.length; i++) {
        let row = this.rows[i];
        for (let j = 0; j < row.children.length; j++) {
          if (row.children[j].amount){
            total += parseFloat(row.children[j].amount.replace('$', ''))
          }
        }
        this.rows[i].children.push({
          date: 'Total',
          amount: `$${total.toFixed(2)}`,
          quantity: undefined
        })
        total = 0;
      }
    },
    returnToReport() {
      this.$router.push({ path: "/report" });
    }
  },
  watch: {
    async currentAccount() {
      this.$router.push({ path: "/report" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>