<template>
  <div :class="showBackground ? 'global-flow-error--outer-container gds-flex-container' : 'gds-flex-container'">
    <div class="global-flow-error--inner-container with-report-outage-flow">
      <img v-if="!img" class="maintenance-graphic" src="/wp-content/themes/gmptwentynineteen/assets/images/maintenance-error.svg" />
      <img v-if="img" class="maintenance-graphic" :src="img" />
      <div class="maintenance-message maintenance-message--main gds-space-stack-l">
        {{name}}
        <template v-if="state === 'feature disabled'">is temporarily unavailable.</template>
        <template v-if="state === 'maintenance'">is temporarily disabled while we perform routine maintenance. Please try again later.</template>
        <template v-if="state === 'nodata'">is not available.</template>
        <template v-if="state === 'error'">
          is not available at the moment.
          <br />Please try again later.
        </template>
        <template v-if="state === 'unavailable'">is not available for this account.</template>
      </div>
      <div v-if="phonetext" class="maintenance-message--phone gds-space-stack-l">
        {{phonetext}}
        <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
      </div>
      <manual-reporting-box-wide v-if="outagebox" />
      <div
        v-if="outagebox"
        class="report-outage-info_flow gds-space-stack-l"
      >*By reporting an outage via text message (SMS) you agree to receive power outage or other account related messages from Green Mountain Power. Reply “HELP” to for more information and “STOP” to opt-out, Message and data rates may apply. Message frequency varies. See our <a href="/apps-skills/text-alerts/notification-terms-and-conditions/" class="gds-link gds-link--bold cursorPointer">Terms of Use</a> and <a href="/legal" class="gds-link gds-link--bold cursorPointer">Privacy Policy.</a></div>

      <button v-if="closepath" @click="ReturnToSender(closepath)" class="global-flow-error__button gds-button">Close</button>
    </div>
  </div>
</template>

<script>
import ManualReportingBoxWide from "../reportoutage/ManualReportingBoxWide";
import { ReturnToSender } from "../../utilities";
import { GMPPhoneNumber } from '../../environment';
export default {
  name: "FlowError",
  props: {
    /** Name of the component/section that is unavailable */
    name: {
      type: String,
    },
    /** State of the component: maintenance, unavailable (for this account), error */
    state: {
      type: String,
    },
    /** If present, add a "call us" message with this text */
    phonetext: {
      type: String,
    },
    /** If present, add a close button to exit the flow. Populate with the flow path, just like ReturnToSender */
    closepath: {
      type: String,
    },
    /** If present, use a custom image rather than crossed tools */
    img: {
      type: String,
    },
    /** If true, show the report outage call/text box */
    outagebox: {
      // could be updated to boolean
      type: String,
    },
    /** If false, global-flow-error--outer-container class is not applied and there will be no background */
    showBackground: {
      type: Boolean,
      default: true
    },
  },
  components: {
    ManualReportingBoxWide,
  },
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
};
</script>

<style scoped>
</style>