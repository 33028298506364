<template>
  <div class="flex-item typeahead">
    <label class="gds-input-field">
      <input
        @keyup="filterArray()"
        v-model="setValue"
        @input="filterArray();"
        @change="selectItem($event)"
        v-validate="'required'"
        :name="type"
        :key="type"
        class="gds-input-field__input gds-space-stack-s"
        type="text"
        autocomplete="off"
        :placeholder="placeholderText || 'Type Here...'"
      />
      <div v-if="errors.first(`${type}`)" class="rebate-validation-error">{{errors.first(`${type}`)}}</div>
    </label>
    <div v-if="showTypeAheadContainer" class="typeAhead-container">
      <div
        class="typeAhead-card gds-space-stack-m"
        :class="{ 'active-dealership typeAhead-card gds-space-stack-m': currentItem === item.id }"
        v-for="(item) in filteredArray"
        :key="item.id"
        @click="setItem(item.value)"
      >
        <div class="typeAhead-text">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TypeAhead",
  components: {
  },
  props: {
    placeholderText: {
      type: String,
      default: "Type Here..."
    },
    type: {
      type: String,
    },
    value: {
      type: String,
    },
    array: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentItem: 0,
      filteredArray: [],
      setValue: this.value,
      selectedItem: undefined,
      showTypeAheadContainer: undefined,
    };
  },
  watch: {
    setValue: {
      handler(newValue) {
        if (!this.setValue) {
          this.showTypeAheadContainer = undefined;
          this.$emit("resetList", true);
        } else if (!this.selectedItem) {
          this.showTypeAheadContainer = true;
        }
      }
    },
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    hideTypeAhead() {
      this.array = [];
      this.currentItem = 0;
    },
    selectItem(event) {
      const typeAhead = document.querySelector(".typeAhead-container")
      if (typeAhead) {
        if (event.keyCode === 38 && this.currentItem > 0) {
          this.currentItem--;
          typeAhead.children[this.currentItem].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        } else if (event.keyCode === 40 && this.currentItem < this.array.length - 1) {
          this.currentItem++;
          typeAhead.children[this.currentItem].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });;
        } else if (event.keyCode === 13) {
          const value = this.array[this.currentItem].value;
          this.setValue = value;
          this.selectedItem = true;
          this.hideTypeAhead();
          this.showTypeAheadContainer = undefined;
        };
        this.$emit('input', this.setValue);
      }
    },
    filterArray() {
      this.selectedItem = undefined;
      let filteredArray;
      for (let i = 0; i < this.array.length; i++) {
        filteredArray = this.array.filter(item => {
          return item.value.toLowerCase().startsWith(this.setValue.toLowerCase());
        })
      }
      this.filteredArray = [];
      for (let i = 0; i < filteredArray.length; i++) {
        this.filteredArray.push({
          id: i,
          value: filteredArray[i].value
        })
      }
      if (this.setValue && !this.filteredArray.length) {
        this.showTypeAheadContainer = undefined;
        this.$emit("showSelectValidItem", true);
      }
    },
    setItem(item) {
      this.showTypeAheadContainer = undefined;
      if (item) {
        this.filteredArray = [];
        this.setValue = item;
        this.selectedItem = true;
        this.$emit('input', this.setValue);
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>