var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.showBackground
        ? "global-flow-error--outer-container gds-flex-container"
        : "gds-flex-container"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "global-flow-error--inner-container with-report-outage-flow"
        },
        [
          !_vm.img
            ? _c("img", {
                staticClass: "maintenance-graphic",
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/maintenance-error.svg"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.img
            ? _c("img", {
                staticClass: "maintenance-graphic",
                attrs: { src: _vm.img }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "maintenance-message maintenance-message--main gds-space-stack-l"
            },
            [
              _vm._v("\n      " + _vm._s(_vm.name) + "\n      "),
              _vm.state === "feature disabled"
                ? [_vm._v("is temporarily unavailable.")]
                : _vm._e(),
              _vm._v(" "),
              _vm.state === "maintenance"
                ? [
                    _vm._v(
                      "is temporarily disabled while we perform routine maintenance. Please try again later."
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.state === "nodata" ? [_vm._v("is not available.")] : _vm._e(),
              _vm._v(" "),
              _vm.state === "error"
                ? [
                    _vm._v(
                      "\n        is not available at the moment.\n        "
                    ),
                    _c("br"),
                    _vm._v("Please try again later.\n      ")
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.state === "unavailable"
                ? [_vm._v("is not available for this account.")]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.phonetext
            ? _c(
                "div",
                { staticClass: "maintenance-message--phone gds-space-stack-l" },
                [
                  _vm._v("\n      " + _vm._s(_vm.phonetext) + "\n      "),
                  _c("span", { staticClass: "gds-nobreak" }, [
                    _vm._v(_vm._s(_vm.GMPPhoneNumber) + ".")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.outagebox ? _c("manual-reporting-box-wide") : _vm._e(),
          _vm._v(" "),
          _vm.outagebox
            ? _c(
                "div",
                { staticClass: "report-outage-info_flow gds-space-stack-l" },
                [
                  _vm._v(
                    "*By reporting an outage via text message (SMS) you agree to receive power outage or other account related messages from Green Mountain Power. Reply “HELP” to for more information and “STOP” to opt-out, Message and data rates may apply. Message frequency varies. See our "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "gds-link gds-link--bold cursorPointer",
                      attrs: {
                        href:
                          "/apps-skills/text-alerts/notification-terms-and-conditions/"
                      }
                    },
                    [_vm._v("Terms of Use")]
                  ),
                  _vm._v(" and "),
                  _c(
                    "a",
                    {
                      staticClass: "gds-link gds-link--bold cursorPointer",
                      attrs: { href: "/legal" }
                    },
                    [_vm._v("Privacy Policy.")]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.closepath
            ? _c(
                "button",
                {
                  staticClass: "global-flow-error__button gds-button",
                  on: {
                    click: function($event) {
                      return _vm.ReturnToSender(_vm.closepath)
                    }
                  }
                },
                [_vm._v("Close")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }