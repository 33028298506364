var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-history" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.loadState === undefined
      ? _c("div", { staticClass: "table-loading" })
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "error"
      ? _c("div", { staticClass: "table-error" }, [
          _vm._v(
            "Unable to load your recent transactions and bills. Please try again later."
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "empty"
      ? _c("div", { staticClass: "table-error" }, [
          _vm._v("No bills or payments found within the last two years.")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "complete"
      ? _c(
          "div",
          [
            _vm._l(_vm.visibleTransactions, function(transaction) {
              return _c(
                "div",
                {
                  key: transaction.transactionId,
                  staticClass: "payment-history--item"
                },
                [
                  _c("div", { staticClass: "payment-history--item-icon" }, [
                    _c("svg", { staticClass: "gds-icon" }, [
                      transaction.type === "Bill Segment"
                        ? _c("use", {
                            attrs: {
                              "xlink:href":
                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-document"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      transaction.type === "Pay Segment"
                        ? _c("use", {
                            attrs: {
                              "xlink:href":
                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-payment"
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "payment-history--item-type gds-mobile-only"
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("transactionType")(transaction.type))
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "payment-history--item-date gds-font-size-m gds-flex-container gds-flex-container--left"
                    },
                    [
                      _c("div", { staticClass: "gds-space-inline-s" }, [
                        _vm._v(
                          _vm._s(_vm._f("dateFull")(transaction.date)) + " "
                        ),
                        _vm.TransactionIsNew(transaction)
                          ? _c("span", { staticClass: "gds-badge new-item" }, [
                              _vm._v("New")
                            ])
                          : _vm._e()
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "payment-history--item-type gds-font-size-m gds-desktop-only"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm._f("transactionType")(transaction.type))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "payment-history--view-bill-wrap" },
                    [
                      transaction.type === "Bill Segment" && transaction.url
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "payment-history--view-bill-button gds-button gds-button-text gds-compact gds-button-underline",
                              attrs: { href: transaction.url, target: "_blank" }
                            },
                            [_vm._v("View Bill")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      transaction.type === "Bill Segment" && _vm.testDriveUsage
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "payment-history--view-bill-button gds-button gds-button-text gds-compact gds-button-underline",
                              attrs: { href: _vm.UsageLink(transaction) }
                            },
                            [_vm._v("View Usage")]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "payment-history--item-amount gds-font-size-xl"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm._f("currency")(transaction.payoffAmount))
                      )
                    ]
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm.visibleCount < _vm.transactions.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "payment-history--load-more-wrap gds-flex-container"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "gds-button red gds-secondary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.visibleCount += 8
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "gds-button__text" }, [
                          _vm._v("Load More")
                        ])
                      ]
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "payment-history--header gds-flex-container gds-flex-container--space-between"
      },
      [
        _c(
          "div",
          { staticClass: "payment-history--item-date gds-font-size-s" },
          [_vm._v("Date")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "payment-history--item-icon" }),
        _vm._v(" "),
        _c("div", { staticClass: "payment-history--view-bill-wrap" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "payment-history--item-amount gds-font-size-s" },
          [_vm._v("New Charges")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }