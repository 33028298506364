<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail">
      <div class="usage-detail-mobile__modal-nav-select__header">
        <span class="usage-detail-mobile__modal-nav-select__title">{{modalTitle}}</span>
        <button @click="closeModal()" type="button" class="close bill-pay__close gds-button gds-button-circle gds-secondary">
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
      </div>
      <FocusLock>
        <div class="usage-detail-mobile__modal__body">
          <span class="usage-detail__datepicker">
            <datepicker
              ref="datePicker"
              :typeable="false"
              :minimum-view="minimumView"
              :maximum-view="maximumView"
              v-model="selectedDate"
              :disabled-dates="disabledDatesConfig"
              @selected="handleSelectedDateChange"
              input-class="usage-detail__datepicker__date-input"
            />
          </span>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
const showDayCalendarMethod = 'showDayCalendar';

export default {
  name: "UsageDetailDatePickerMobile",
  components: { Datepicker },
  mounted() {
    this.setDatePickerDateType();

    if (this.disabledDates) {
      this.disabledDatesConfig = Object.assign({}, this.disabledDates);
    }

    this.$refs.datePicker[this.datePickerOpenMethod]();
  },
  props: {
    closeModal: Function,
    disabledDates: Object,
    datePickerVisible: Boolean,
    modalTitle: String,
    datePickerDateType: String,
    datePickerDefaultDate: Date,
    selectedDateChanged: Function
  },
  watch: {
    datePickerDateType() {
      this.setDatePickerDateType();
    }
  },
  data() {
    return {
      keyDay: 'day',
      keyMonth: 'month',
      keyYear: 'year',
      minimumView: 'day',
      maximumView: 'year',
      disabledDatesConfig: {
        from: new Date(),
      },
      datePickerOpenMethod: showDayCalendarMethod,
      selectedDate: this.datePickerDefaultDate || new Date()
    }
  },
  methods: {
    handleSelectedDateChange() {
      this.$nextTick(() => {
        this.selectedDateChanged(this.selectedDate);
        this.closeModal();
      });
    },
    setDatePickerDateType() {
      switch(this.datePickerDateType) {
        case this.keyDay:
          this.minimumView = this.keyDay;
          this.datePickerOpenMethod = showDayCalendarMethod;
        break;
        case this.keyMonth:
          this.minimumView = this.keyMonth;
          this.datePickerOpenMethod = 'showMonthCalendar';
        break;
        case this.keyYear:
          this.minimumView = this.keyYear;
          this.datePickerOpenMethod = 'showYearCalendar';
        break;
      }
    }
  }
};
</script>

<style scoped>
</style>