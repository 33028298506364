<template>
  <div class="usage-dashboard__usage-visualization-and-tools--container">
    <h2 class="gds-space-top-xl gds-space-stack-default">
      Dive deeper into how you use clean energy</h2>
      <p class="usage-dashboard___sub-title gds-space-stack-l">GMP’s power supply is 100% carbon free and 80% renewable on an annual basis. Using more electricity lowers costs and carbon for all!</p>
    <div class="usage-dashboard__usage-visualization-and-tools--card-container">
      <a href="/account/usage-new/detail/graph" class="usage-dashboard__usage-visualization-and-tools--nav-card">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg"
          alt="Graph chart icon" class="usage-dashboard__usage-visualization-and-tools--icon">
        <span class="usage-dashboard__usage-visualization-and-tools--icon-text">Graph</span>
        <span class="usage-dashboard__usage-visualization-and-tools--description">View your usage as a bar graph.</span>
      </a>
      <a href="/account/usage-new/detail/table" class="usage-dashboard__usage-visualization-and-tools--nav-card">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg"
          alt="Table chart icon" class="usage-dashboard__usage-visualization-and-tools--icon">
        <span class="usage-dashboard__usage-visualization-and-tools--icon-text">Table</span>
        <span class="usage-dashboard__usage-visualization-and-tools--description">View your usage as a set of rows.</span>
      </a>
      <a v-if="isNonNetMeteredAccount" href="/account/usage-new/detail/time-of-day" class="usage-dashboard__usage-visualization-and-tools--nav-card">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg"
          alt="Sun and moon icon" class="usage-dashboard__usage-visualization-and-tools--icon">
        <span class="usage-dashboard__usage-visualization-and-tools--icon-text">Time of Day</span>
        <span class="usage-dashboard__usage-visualization-and-tools--description">When do you use energy the most throughout the day.</span>
      </a>
      <!-- <a href="/account/usage-new/detail/seasonal" class="usage-dashboard__usage-visualization-and-tools--nav-card">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal-green.svg"
          alt="Box with snowflake icon" class="usage-dashboard__usage-visualization-and-tools--icon">
        <span class="usage-dashboard__usage-visualization-and-tools--icon-text">Seasonal</span>
        <span class="usage-dashboard__usage-visualization-and-tools--description">How does your usage change based off of the time of year.</span>
      </a> -->
      <!--
      <a href="/account/usage-new/detail/heating-cooling" class="usage-dashboard__usage-visualization-and-tools--nav-card">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-heating-cooling-green.svg"
          alt="Thermometer icon" class="usage-dashboard__usage-visualization-and-tools--icon">
        <span class="usage-dashboard__usage-visualization-and-tools--icon-text">Heat / Cool</span>
        <span class="usage-dashboard__usage-visualization-and-tools--description">View your usage as a set of rows.</span>
      </a>
      -->
      <a href="/account/usage-new/detail/performance" class="usage-dashboard__usage-visualization-and-tools--nav-card">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg"
          alt="Guage icon" class="usage-dashboard__usage-visualization-and-tools--icon">
        <span class="usage-dashboard__usage-visualization-and-tools--icon-text">Performance</span>
        <span class="usage-dashboard__usage-visualization-and-tools--description">Compare your usage between billing periods.</span>
      </a>
      <a href="/account/usage-new/detail/compare" class="usage-dashboard__usage-visualization-and-tools--nav-card">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg"
          alt="Box with arrows pointing toward each other icon"
          class="usage-dashboard__usage-visualization-and-tools--icon">
        <span class="usage-dashboard__usage-visualization-and-tools--icon-text">Compare</span>
        <span class="usage-dashboard__usage-visualization-and-tools--description">Compare usage over different time periods.</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsageVisualizationAndTools",
  props: {
    isNonNetMeteredAccount: Boolean,
  },
}
</script>