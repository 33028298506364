var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      { staticClass: "bill-pay-success-fail", attrs: { id: "top-target" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "gds-display-2 gds-align--text-center gds-space-stack-ml"
          },
          [_vm._v("Your stop service request was received")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
          _vm._v(
            "\n      Your service will end on " +
              _vm._s(_vm._f("dateFull")(_vm.stoppageDate)) +
              " and we will send you a final bill to the address you provided. If you are moving to another location in our service territory, or need to make changes to your stop service request, please contact customer service at\n      "
          ),
          _c("span", { staticClass: "gds-nobreak" }, [
            _vm._v(_vm._s(_vm.GMPPhoneNumber) + ".")
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "bill-pay-success__button-wrap gds-align--text-center"
          },
          [
            _c(
              "button",
              {
                staticClass: "bill-pay-success__button gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.ReturnToSender("/stop-service")
                  }
                }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ssmove-success__intro-image" }, [
      _c("img", {
        staticClass: "ssmove__success-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/house-solar-panel.svg",
          alt: "Message Received"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }