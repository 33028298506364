var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("outage-header", {
        attrs: { updated: _vm.updated, personalData: _vm.personalData }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "outage-map-wrapper" },
        [
          _c("gmp-map", {
            ref: "map",
            attrs: { kind: "homepage", townVisibility: _vm.townVisibility },
            on: { update: _vm.onMapUpdate }
          }),
          _vm._v(" "),
          _vm.townListExpanded
            ? _c("button", {
                staticClass: "town-collapse-toggle",
                attrs: {
                  title:
                    (_vm.townListExpanded ? "View" : "Close") + " Town List"
                },
                on: {
                  click: function($event) {
                    _vm.townListExpanded = false
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("town-list", {
            staticClass: "outage-list-slideout",
            class: { listopen: _vm.townListExpanded },
            attrs: {
              townData: _vm.townData,
              activeIncidents: _vm.activeIncidents
            },
            on: {
              hover: _vm.onHoverTown,
              close: function($event) {
                _vm.townListExpanded = false
              }
            }
          }),
          _vm._v(" "),
          !_vm.townListExpanded
            ? _c(
                "button",
                {
                  staticClass: "gds-button outage-slide-out-toggle",
                  on: {
                    click: function($event) {
                      _vm.townListExpanded = true
                    }
                  }
                },
                [_vm._m(0)]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "map-info-button layers",
            attrs: { title: "Map Layers" },
            on: {
              click: function($event) {
                _vm.showLayer = !_vm.showLayer
              }
            }
          }),
          _vm._v(" "),
          _c("div", {
            staticClass: "map-info-button legend",
            attrs: { title: "Map Legend" },
            on: {
              click: function($event) {
                _vm.showLegend = true
              }
            }
          }),
          _vm._v(" "),
          _vm.showLayer
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.CloseLayers,
                      expression: "CloseLayers"
                    }
                  ],
                  staticClass: "map-layer-list"
                },
                [
                  _c(
                    "div",
                    { staticClass: "gds-display-00 gds-space-stack-m" },
                    [_vm._v("Map Layers")]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    {
                      staticClass: "gds-fieldset gds-fieldset--no-left-padding"
                    },
                    [
                      _c("label", { staticClass: "gds-radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.townVisibility,
                              expression: "townVisibility"
                            }
                          ],
                          attrs: { type: "radio", name: "show-towns" },
                          domProps: {
                            value: true,
                            checked: _vm._q(_vm.townVisibility, true)
                          },
                          on: {
                            change: function($event) {
                              _vm.townVisibility = true
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "gds-radio__faux" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "gds-radio__label" }, [
                          _vm._v("Towns & Incidents")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "gds-radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.townVisibility,
                              expression: "townVisibility"
                            }
                          ],
                          attrs: { type: "radio", name: "show-towns" },
                          domProps: {
                            value: false,
                            checked: _vm._q(_vm.townVisibility, false)
                          },
                          on: {
                            change: function($event) {
                              _vm.townVisibility = false
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "gds-radio__faux" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "gds-radio__label" }, [
                          _vm._v("Incidents Only")
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("modal-map-legend", {
            attrs: { visible: _vm.showLegend },
            on: {
              close: function($event) {
                _vm.showLegend = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-button__text" }, [
      _c("span", [_vm._v("View Town List")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }