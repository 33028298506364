var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length > 0 && _vm.rows.length > 0
    ? _c(
        "div",
        {
          staticClass:
            "subpage-dashboards subpage-report--dashboard subpage-dashboards--left-spacer"
        },
        [
          _c(
            "a",
            {
              staticClass: "gds-link gds-link--bold cursorPointer",
              attrs: { href: "/account/report" }
            },
            [_vm._v("Return to Report Page")]
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass:
                "custom-report-table my-account__full-width-section gds-space-stack-xl"
            },
            [
              _c("vue-good-table", {
                attrs: {
                  columns: _vm.columns,
                  rows: _vm.rows,
                  styleClass: "vgt-table striped",
                  "group-options": {
                    enabled: true,
                    collapsable: true
                  }
                }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }