var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outage-center-main" }, [
    _vm.loadState === undefined
      ? _c("div", { staticClass: "table-loading" })
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "error"
      ? _c(
          "div",
          [
            _c("flow-error", {
              attrs: {
                name: "Outage Center",
                outagebox: "true",
                state: "error"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "maintenance"
      ? _c(
          "div",
          [
            _c("flow-error", {
              attrs: {
                name: "Outage Center",
                outagebox: "true",
                state: "maintenance"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "complete"
      ? _c("div", { staticClass: "outage-center-main" }, [_c("router-view")], 1)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }