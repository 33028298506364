<template>
  <div class="make-payment-container">
    <div id="top-target" class="bill-pay-success-fail">
      <div class="ssmove-success__intro-image">
        <img class="ssmove__success-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/house-solar-panel.svg" alt="Message Received" />
      </div>

      <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Your stop service request was received</h2>
      <div class="gds-font-size-l gds-space-stack-l">
        Your service will end on {{stoppageDate | dateFull}} and we will send you a final bill to the address you provided. If you are moving to another location in our service territory, or need to make changes to your stop service request, please contact customer service at
        <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
      </div>
      <div class="bill-pay-success__button-wrap gds-align--text-center">
        <button @click="ReturnToSender('/stop-service')" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
import { ReturnToSender } from "../../utilities";

export default {
  name: "StopServiceComplete",
  data() {
    return {
      GMPPhoneNumber,
      ReturnToSender,
    };
  },
  computed: {
    stoppageDate() {
      return this.$store.state.billpay.stoppageDate;
    },
  },
  async mounted() {
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>