var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-dashboard--user-profile" },
    [
      _c("h1", { staticClass: "my-account__title gds-space-stack-xl" }, [
        _vm._v("User Settings")
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "my-account__user-settings-intro gds-font-size-l gds-space-stack-xl"
        },
        [
          _vm._v("Currently signed in as "),
          _c("span", { staticClass: "gds-font-demi" }, [
            _vm._v(_vm._s(_vm.userInfo.fullName))
          ]),
          _vm._v(", " + _vm._s(_vm.userInfo.email) + ". "),
          _vm.numLinkedAccounts > 1
            ? _c("span", [
                _vm._v(
                  "There are " +
                    _vm._s(_vm.numLinkedAccounts) +
                    " energy accounts linked to this user. "
                ),
                _c("a", { attrs: { href: "/user-profile/accounts" } }, [
                  _vm._v(" Manage Accounts")
                ])
              ])
            : _c("span", [
                _vm._v("There is 1 energy account linked to this user. "),
                _c("a", { attrs: { href: "/account/settings" } }, [
                  _vm._v(" View Account Settings")
                ])
              ])
        ]
      ),
      _vm._v(" "),
      _c("editor-profile-name"),
      _vm._v(" "),
      _c("editor-profile-email"),
      _vm._v(" "),
      _c("widget-profile-username"),
      _vm._v(" "),
      _c("editor-profile-password"),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "gds-button red gds-text-button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.showDelete = true
              }
            }
          },
          [
            _c("span", { staticClass: "gds-button__text" }, [
              _vm._v("Delete User Account")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("modal-delete-user", {
        attrs: { visible: _vm.showDelete },
        on: { complete: _vm.DeleteComplete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }