<!-- TODO: Make reusable EICProductComplete & EICProductFatalError components -->
<template>
  <div class="make-payment-container">
    <div id="top-target" class="eicproduct-success-fail">
      <div class="eicproduct-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/bill-pay-complete.svg" alt="successful payment" />
      </div>

      <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Thank you!</h2>
      <div class="gds-font-size-l gds-space-stack-l successBody">We have your incentive request and we will get back to you!</div>
      <div class="rebate-success__button-wrap gds-align--text-center">
        <button @click="ReturnToSender('/')" type="button" class="eicproduct-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../../environment";
import { ReturnToSender } from "../../../utilities";

export default {
  name: "InductionCooktopIncentiveComplete",
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  computed: {
  },
  async mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      var elem = document.getElementById(`induction-cooktop-incentive`);
      elem.scrollIntoView();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>