var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.routeloading || _vm.loadState === undefined
        ? _c("div", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "maintenance"
        ? _c("flow-error", {
            attrs: {
              name: "Bill Pay",
              state: "maintenance",
              phonetext: "To make a payment by phone call us at",
              closepath: "/make-payment"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Bill Pay",
              state: "error",
              phonetext: "To make a payment by phone call us at",
              closepath: "/make-payment"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" ? _c("router-view") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "bill-pay__speedpay-footer-text" }, [
        _vm._v(
          "©" +
            _vm._s(new Date().getFullYear()) +
            " Green Mountain Power. Payments processed by Speedpay, an ACI Worldwide company."
        )
      ]),
      _vm._v(" "),
      _c("modal-more-time", {
        attrs: {
          visible: this.timerTicks >= _vm.TICK_SHOW_WARNING,
          onReset: _vm.ResetTimer
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay__page-loader" }, [
      _c("img", {
        staticClass: "bill-pay__page-loader--svg",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }