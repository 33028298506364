var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.FF_PlannedOutage
    ? _c(
        "div",
        _vm._l(_vm.accountPlannedOutages, function(plannedOutage) {
          return _c(
            "div",
            {
              key: plannedOutage.id,
              staticClass:
                "planned-outage-banner gds-flex-container gds-flex-container--space-between gds-flex-container--top"
            },
            [
              _c("div", { staticClass: "planned-outage-banner--message" }, [
                _vm._v(
                  "A planned outage on " +
                    _vm._s(_vm._f("dateTimeFullAt")(plannedOutage.occursAt)) +
                    " will\n      affect your service for approximately " +
                    _vm._s(
                      _vm._f("durationMinutes")(plannedOutage.outageDuration)
                    ) +
                    "."
                )
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: plannedOutage.incidentId
                      ? "/outages/incident/" + plannedOutage.incidentId
                      : "/outages/planned-outages/" + plannedOutage.id
                  }
                },
                [_vm._v("More\n      Info")]
              )
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }