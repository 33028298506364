var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    !_vm.disabled
      ? _c(
          "button",
          {
            staticClass: "date-navigation__button-mobile",
            class: { "add-background": _vm.addBackground },
            on: {
              click: function($event) {
                return _vm.buttonClicked()
              }
            }
          },
          [
            _c(
              "span",
              { staticClass: "date-navigation__button-mobile__label" },
              [_vm._v(_vm._s(_vm.buttonText))]
            ),
            _vm._v(" "),
            _vm.addBackground
              ? _c("i", { staticClass: "usage-detail-mobile__down-caret" })
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.disabled
      ? _c(
          "button",
          {
            staticClass: "date-navigation__button-mobile disabled",
            attrs: { disabled: "" }
          },
          [
            _c(
              "span",
              {
                staticClass: "date-navigation__button-mobile__label",
                class: { "add-background": _vm.addBackground }
              },
              [_vm._v(_vm._s(_vm.buttonText))]
            ),
            _vm._v(" "),
            _vm.addBackground
              ? _c("i", { staticClass: "usage-detail-mobile__down-caret" })
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }