<template>
  <div class="eicproduct-container">
    <div v-if="loadState === undefined" class="eicproduct__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="eicproduct__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error"
      phonetext="For further help, please call us at" closepath="/induction-cooktop-rebate" />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 Verify Customer Information -->
      <section id="induction-cooktop-rebate-slat-1" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="eicproduct-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Customer Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="!userIsOTP" class="gds-body-normal gds-space-inline-m">Account: {{ currentAccount.nickname }}
                </div>
                <div v-if="userIsOTP" class="gds-body-normal gds-space-inline-m">Account Number: {{
                currentAccount.accountNumber
                }}</div>
                <button @click="gotoPage(1)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <!-- SLAT 1 Edit Screen For Account Information -->

          <!-- User Is Guest -->
          <div v-if="currentPage === 1 && userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                induction cooktop incentive.</legend>
              <label :key="currentAccount.accountNumber"
                class="eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m">
                <div class="eicproduct__account-box gds-space-stack-l">
                  <span v-if="currentAccount">
                    <div class="row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName? currentAccount.lastName: ''}${currentAccount.firstName
                        && currentAccount.lastName? ',':
                        ''} ${currentAccount.firstName? currentAccount.firstName : ''}` | titlecase() }}</span>
                      </div>

                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </label>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- User Has WWW Account -->
          <div v-if="currentPage === 1 && !userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                induction cooktop incentive.</legend>
              <div class="eicproduct__account-box gds-space-stack-l">
                <span v-if="currentAccount">
                  <div class="row-m">
                    <div class="account-info-row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName? currentAccount.lastName: ''}${currentAccount.firstName
                        && currentAccount.lastName? ',':
                        ''} ${currentAccount.firstName? currentAccount.firstName : ''}` | titlecase() }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                    <div v-if="accounts.length > 1" @click="chooseAccount()"
                      class="inline accountInformation eicproduct__edit-account-details">
                      <button class="gds-button gds-text-button choose-account-button">
                        <svg class="eicproduct__edit-icon-inline gds-icon">
                          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                        </svg>
                        <span v-if="accounts.length > 1">Switch Account</span>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 Product Rebate Details -->
      <section id="induction-cooktop-rebate-slat-2" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="eicproduct-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Induction Cooktop Details</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">Total Savings: ${{ totalSavings }}</div>
                <button @click="gotoPage(2)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 2" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2 && isCommercialAccount" class="eicproduct-slat__contents">
            <div class="gds-space-stack-l gds-align--text-center">
              <div class="gds-space-stack-m">
                This account is not eligible to submit an incentive online. Business accounts may qualify for additional
                savings. Please email us at
                <a :href="'mailto:' + EICEmail" class="gds-link gds-link--bold">{{EICEmail}}</a> so we can connect with
                you to process your incentive and other possible savings.
              </div>
              <button @click="returnToHome()" class="gds-button gds-compact">
                <span class="gds-button__text">Return to Dashboard</span>
              </button>
            </div>
          </div>

          <div v-if="currentPage === 2 && !isCommercialAccount" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="rebate-slap__inner-row">
                <div class="row-s">
                  <form data-vv-scope="page2">
                    <fieldset class="gds-fieldset gds-space-stack-s">
                      <div class="gds-space-stack-m">
                        <label>
                          <span class="gds-input-field__label">Make/Brand</span>
                          <input v-model="productSubmission.inductionCooktops[0].brand" v-validate="'required|max:50'"
                            name="brand" key="brand" class="gds-input-field__input gds-space-stack-s" type="text"
                            autocomplete="off" placeholder="Type Here..." />
                          <div v-if="errors.first('brand', 'page2')" class="eicproduct-validation-error">{{
                          errors.first('brand', "page2")
                          }}</div>
                        </label>
                      </div>
                      <div class="gds-space-stack-m">
                        <label>
                          <span class="gds-input-field__label">Model</span>
                          <input v-model="productSubmission.inductionCooktops[0].model" v-validate="'required|max:50'"
                            name="model" key="model" class="gds-input-field__input gds-space-stack-s" type="text"
                            autocomplete="off" placeholder="Type Here..." />
                          <div v-if="errors.first('model', 'page2')" class="eicproduct-validation-error">{{
                          errors.first(`model`, "page2")
                          }}</div>
                        </label>
                      </div>
                      <div class="gds-space-stack-m">
                        <label>
                          <span class="gds-input-field__label">Serial Number</span>
                          <input v-model="productSubmission.inductionCooktops[0].serialNumber" v-validate="'required|max:50'"
                            name="serial number" key="serial number" class="gds-input-field__input gds-space-stack-s"
                            type="text" autocomplete="off" placeholder="Type Here..." />
                          <div v-if="errors.first('serial number', 'page2')" class="eicproduct-validation-error">{{
                          errors.first('serial number', "page2")
                          }}</div>
                        </label>
                      </div>
                      <label @click.prevent class="gds-input-field">
                        <span class="gds-input-field__label">Purchase Date</span>
                        <div class="eicproduct-purchasedate__date-box gds-space-stack-s">
                          <div class="my-account-billing-report__filtration-date-icon">
                            <svg class="gds-icon">
                              <use
                                xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                            </svg>
                          </div>
                          <datepicker ref="datePicker" v-model="productSubmission.inductionCooktops[0].purchaseDate"
                            name="purchase date of inudction cooktop" key="purchase date of inudction cooktop"
                            v-validate="'required'" format="MMMM d, yyyy" :typeable="true" placeholder="Select Date..."
                            :disabled-dates="disableDates"
                            input-class="export-data__date my-account-billing-report__filtration-date-text" />
                        </div>
                        <div v-if="errors.first('purchase date of inudction cooktop', 'page2')"
                          class="eicproduct-validation-error">{{
                          errors.first('purchase date of inudction cooktop', "page2")
                          }}</div>
                      </label>
                      <div class="gds-text-grey gds-font-size-s">*The application for rebate must be submitted
                        within the same calendar year as the purchase, or no more than 180 days from the purchse date
                        if submitted in the following calendar year.</div>
                    </fieldset>
                  </form>
                </div>
                <button
                  @click="finishPage('page2', 3); calculateTotalSavings(); checkIfPurchaseDateIsLessThan60DaysFromNow();"
                  class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
          <ModalEICProducts v-if="showPurchaseDateModal" @close="showPurchaseDateModal = false"
            modalMessage="The purchase date must be within the past 60 days." />
        </div>
      </section>

      <!-- SLAT 3 Upload Documents -->
      <section id="induction-cooktop-rebate-slat-3" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="eicproduct-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Upload Documents</div>
                <ModalEICProducts modalName="missingDocument" v-if="showMissingDocumentsModal" :close="closeModal"
                  modalTitle="You’re missing a required document" modalMessage="Please upload a copy of the following:"
                  :listItems="missingDocuments" />
                <ModalEICProducts modalName="fileLoadTooBig" v-if="showFileLoadTooBigModal" :close="closeModal"
                  :modalMessage="`Your total file size is ${this.$options.filters.fileSizeFormat(totalFileSize)}. Please make sure the combined size of all uploaded files is less than ${this.$options.filters.fileSizeFormat(maxFileLoadSize)}.`" />
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="documents.length > 1" class="gds-body-normal gds-space-inline-s">{{ `${this.documents.length}
                Files Uploaded`}}</div>
                <div v-if="documents.length === 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.documents.length} File Uploaded` }}</div>
                <button @click="gotoPage(3)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div class="slat-title-wrapper">
                  <form>
                    <div id="fileUploader">
                      <File-Uploader :uploadDocumentList="uploadDocumentList" :docTypes="documentTypes"
                        @file-list-changed="updateFileList"></File-Uploader>
                    </div>
                  </form>
                </div>
                <button @click="finishPage('page3', 4)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 Contact & Mailing Address -->
      <section id="induction-cooktop-rebate-slat-4" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 4" class="eicproduct-slat__number-with-circle">
                <span>4</span>
              </div>
              <div v-if="currentPage > 4" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Contact & Mailing Address</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 4" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-s">Mailing Address: {{
                productSubmission.rebate.rebateMailingAddress | addressFormat({ seperator: ", " })
                }}</div>
                <button @click="gotoPage(4)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 4" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page4">
                  <div class="gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Please make my check out to:</span>
                      <input v-model="productSubmission.rebate.rebatePayeeName" @input="$emit('touched', true)"
                        v-validate="'required'" name="rebate payee name" key="rebate payee name"
                        class="eicproduct-input-field gds-input-field__input" type="text" ref="focuser4" />
                      <div v-if="errors.first('rebate payee name', 'page4')" class="eicproduct-validation-error">{{
                      errors.first("rebate payee name", "page4")
                      }}</div>
                    </label>
                  </div>
                  <div class="slat-title-wrapper">
                    <div class="eicproduct-title">Where should we send your check?</div>
                  </div>
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Country</span>
                    <select v-model="productSubmission.rebate.rebateMailingAddress.country"
                      :class="productSubmission.rebate.rebateMailingAddress.country === 'USA' ? 'eicproduct-input-field__select-country-us gds-input-field' : productSubmission.rebate.rebateMailingAddress.country === 'CAN' ? 'eicproduct-input-field__select-country-ca gds-input-field' : 'eicproduct-input-field__select-s gds-input-field'"
                      autofocus>
                      <option :value="'USA'" selected
                        data-thumbnail="/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg">United States
                      </option>
                      <option value="CAN">Canada</option>
                      <option value="FOR">Foreign</option>
                    </select>
                    <div v-if="!productSubmission.rebate.rebateMailingAddress.country" class="eicproduct-validation-error">
                      {{ errors.first("country", "page4") }}</div>
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Street Address</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street1"
                      @input="$emit('touched', true)" v-validate="'required'" name="street address" key="street address"
                      class="eicproduct-input-field gds-input-field__input" type="text" placeholder="Type Here..." />
                    <div v-if="errors.first('street address', 'page4')" class="validation-error">{{
                    errors.first("streetaddress", "page4")
                    }}</div>
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Suite Apt</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street2"
                      @input="$emit('touched', true)" name="street 2" key="street 2"
                      class="eicproduct-input-field gds-input-field__input" type="text" placeholder="Type Here..." />
                  </label>
                  <br />
                  <br />
                  <div class="row-l">
                    <div class="flex-item-l">
                      <label class="gds-input-field-flex">
                        <span class="gds-input-field__label">City</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.city"
                          @input="$emit('touched', true)" v-validate="'required'" name="city" key="city"
                          class="gds-input-field__input" type="text" placeholder="Type Here..." />
                        <div v-if="errors.first('city', 'page4')" class="validation-error">{{ errors.first("city",
                        "page4")
                        }}</div>
                      </label>
                    </div>
                    <div class="flex-item-s gds-space-stack-m">
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">State</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select State...</option>
                          <option v-for="state of stateList" :key="state" :value="state">{{ state }}</option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("state", "page4") }}</div>
                      </label>
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">Province</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select Province...</option>
                          <option v-for="province of provinceList" :key="province" :value="province">{{ province }}
                          </option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("province", "page4") }}</div>
                      </label>
                    </div>
                    <div></div>
                    <div class="flex-item-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Zipcode</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.zip"
                          @input="$emit('touched', true)" v-validate="'required|numeric|length:5'" name="zipcode"
                          key="zipcode" class="gds-input-field__input eicproduct-mailing-zip" type="text"
                          placeholder="Type Here..." autocomplete="postal-code" />
                        <div v-if="errors.first('zipcode', 'page4')" class="validation-error">{{ errors.first("zipcode",
                        "page4")
                        }}</div>
                      </label>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="eicproduct-validation-error">{{ errors.first("zip", "page4") }}</div>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="eicproduct-validation-error">{{ errors.first("canadianZip", "page4") }}</div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div>
                    <div class="eicproduct-title">How should we contact you if we need additional information to process
                      your incentive:</div>
                    <br />
                    <br />
                    <br />
                    <div class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Email Address</span>
                        <input v-model="productSubmission.rebate.confirmationEmailAddress"
                          @input="$emit('touched', true)" v-validate="'required|email'" name="email address"
                          key="email address" class="eicproduct-input-field gds-input-field__input" type="email"
                          placeholder="Your email" autocomplete="email" ref="email" />
                        <div v-if="errors.first('email address', 'page4')" class="validation-error">{{
                        errors.first("email address", "page4")
                        }}</div>
                      </label>
                    </div>
                    <div class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Confirm Your Email Address</span>
                        <input v-model="confirmationEmailAddress" @blur="addConfirmationEmailError()"
                          @input="$emit('touched', true);" v-validate="'required|email'"
                          name="confirmation email address" key="confirmation email address"
                          class="eicproduct-input-field gds-input-field__input" type="email" placeholder="Your email"
                          autocomplete="email" ref="email" />
                        <div v-if="productSubmission.rebate.confirmationEmailAddress !== confirmationEmailAddress"
                          class="validation-error">{{
                          errors.first("confirmation email address", "page4")
                          }}</div>
                      </label>
                    </div>
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Phone Number</span>
                      <div class="gds-space-stack-xl">
                        <the-mask v-model="productSubmission.rebate.contactPhoneNumber" :mask="'+1 (###) ###-####'"
                          @input.native="touched = true" v-validate="'required|min:10'" data-vv-validate-on="input"
                          :name="'phone number'" :key="'phone number'" class="eicproduct-input-field gds-input-field__input"
                          type="tel" placeholder="Your phone number" />
                        <div v-if="errors.first('phone number', 'page4')" class="validation-error">{{
                        errors.first("phone number", "page4")
                        }}</div>
                      </div>
                    </label>
                    <br />
                  </div>
                </form>
                <button @click="finishPage('page4', 5)"
                  :disabled="productSubmission.rebate.confirmationEmailAddress !== confirmationEmailAddress"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 5 Review & Submit-->
      <section id="induction-cooktop-rebate-slat-5" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 5" class="eicproduct-slat__number-with-circle">
                <span>5</span>
              </div>
              <div v-if="currentPage > 5" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Review & Submit</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage < 5" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 5" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <Household-Income 
                  :productSubmission="productSubmission" 
                  :metadata="metadata"
                  :lastYear="lastYear"
                  @household-income-changed="updateHouseholdIncome"
                  ref="householdIncome">
                </Household-Income>
                <div class="gds-display-00 gds-space-stack-s" ref="focuser6">Your check will be mailed to:</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebatePayeeName | titlecase()
                }}</div>
                <div class="gds-body-small gds-space-stack-l">{{ productSubmission.rebate.rebateMailingAddress |
                addressFormat({ separator: ", " })
                }}</div>
                <div class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Your Incentive Information:</div>
                  <div v-for="(inductionCooktop, i) in productSubmission.inductionCooktops" :key="i"
                    class="gds-body-small gds-space-stack-s">
                    <div>Induction Cooktop in the amount of ${{ inductionCooktopIncentiveAmount }}</div>
                  </div>
                </div>
                <div class="gds-space-stack-m">
                  <label class="gds-checkbox gds-checkbox-thin">
                    <input v-model="regulatoryDataSharing" v-validate="'required'" type="checkbox"
                      key="regulatory data sharing" name="regulatory data sharing" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-font-size-ml regulatory-text1">
                      {{ REGULATORY_REBATE_SHARING_TEXT1  }}
                    </span>
                    <p class="gds-font-size-ml regulatory-text2">
                      {{ REGULATORY_REBATE_SHARING_TEXT2  }}
                    </p>
                    <div v-if="errors.first('regulatory data sharing', 'page5')"
                      class="eicproduct-validation-error">{{
                      errors.first("regulatory data sharing", "page5")
                      }}</div>
                  </label>
                </div>
                <div class="gds-space-stack-m">
                  <label class="induction-cooktop-stove-fossil-fuel-replacement-checkbox gds-checkbox-thin">
                    <input v-model="fossilFuelReplacementAgreement" v-validate="'required'" type="checkbox"
                      key="fossil fuel replacement agreement" name="fossil fuel replacement agreement" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-font-size-ml gds-body-normal">
                      <b>Yes!</b> Your induction cooktop will be used as a replacement for a fossil fuel cooktop/range.
                    </span>
                    <div v-if="errors.first('fossil fuel replacement agreement', 'page2')"
                      class="eicproduct-validation-error">{{
                      errors.first("fossil fuel replacement agreement", "page2")
                      }}</div>
                  </label>
                </div>
                <button v-if="!pendingSubmit" @click="handleSubmit" type="submit" style="margin-top: 2rem;" class="gds-button gds-compact"
                  :disabled="pendingSubmit || !fossilFuelReplacementAgreement || !regulatoryDataSharing">
                  <span :disabled="pendingSave || !touched || errors.any()"
                    class="gds-button__text gds-space-stack-m">Submit Rebate</span>
                </button>
                <button v-if="pendingSubmit" style="margin-top: 2rem;" class="gds-button [modifier class] gds-loading">
                  <span class="gds-button__text">Button Element</span>
                  <figure class="gds-button__icon"></figure>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ErrorBag } from "vee-validate";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import Datepicker from 'vuejs-datepicker';
import { proofOfPurchase, w9, inductionCooktopIncentiveAmount, regulatoryRebateSharingText1, regulatoryRebateSharingText2 } from "../constants.js";
import ModalEICProducts from "../ModalEICProducts.vue";
import FileUploader from "../FileUploader.vue";
import { EICEmail } from "../../../environment.js";
import HouseholdIncome from "../HouseholdIncome.vue";
import EICProductsMixin from "../EICProductsMixin";
import { getConfigValue } from "../../../services/featureflags";

const date = new Date();
date.setDate(date.getDate() + 1);

export default {
  name: "InductionCooktopIncentiveFlow",
  mixins: [
    EICProductsMixin
  ],
  components: {
    FileUploader,
    Datepicker,
    ModalEICProducts,
    ModalEICProducts,
    HouseholdIncome
  },
  data() {
    return {
      disableDates: {
        to: new Date(2022, 9, 1),
        from: date,
      },
      metadata: undefined,
      householdIncomeDropdown: [],
      confirmationEmailAddress: undefined,
      showPurchaseDateModal: undefined,
      isCommercialAccount: undefined,
      isKeyboarding: false,
      loadState: undefined,
      lastYear: new Date().getFullYear() - 1,
      pendingSubmit: undefined,
      touched: false,
      pendingSave: undefined,
      regulatoryDataSharing: undefined,
      fossilFuelReplacementAgreement: undefined,
      inductionCooktopIncentiveAmount: inductionCooktopIncentiveAmount,
      EICEmail: EICEmail,
      totalFileSize: undefined,
      maxFileLoadSize: undefined,

      stateList: ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"],
      provinceList: ["QC", "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "SK", "NT", "NU", "YT"],

      totalSavings: 0,

      proofOfPurchase: proofOfPurchase,
      w9: w9,
      REGULATORY_REBATE_SHARING_TEXT1: undefined,
      REGULATORY_REBATE_SHARING_TEXT1_DEFAULT: regulatoryRebateSharingText1,
      REGULATORY_REBATE_SHARING_TEXT2: undefined,
      REGULATORY_REBATE_SHARING_TEXT2_DEFAULT: regulatoryRebateSharingText2,
      showDocumentChecklist: false,
      showMissingDocumentsModal: false,
      showFileLoadTooBigModal: false,
      selectedFile: undefined,
      file: undefined,
      documents: [],
      documentTypes: [],
      selectedDocumentType: undefined,
      missingDocuments: [],
      uploadDocumentList: [{
        documentName: w9,
        displayName: 'IRS W-9',
        error: undefined,
        file: null,
        fileName: null,
        fileSize: null,
      },
      {
        UUID: this.generateUUID(),
        documentName: 'proofOfPurchase',
        displayName: 'Proof of Purchase for Induction Cooktop',
        error: undefined,
        file: null,
        fileName: null,
        fileSize: null,
      }],
      productSubmission: {
        programEnrollment: undefined,
        submissionType: "inductionCooktopIncentive",
        account: {
          accountNumber: undefined,
          firstName: undefined,
          lastName: undefined,
          premiseAddress: {
            street1: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined,
          },
          mailingAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            zip: undefined
          }
        },
        inductionCooktops: [{
          brand: undefined,
          model: undefined,
          serialNumber: undefined,
          purchaseDate: undefined,
        }],
        rebate: {
          rebatePayeeName: undefined,
          confirmationEmailAddress: undefined,
          contactPhoneNumber: undefined,
          rebateDeliveryMethod: "Mailed Check",
          householdIncome: {
            willShareIncome: '',
            grossHouseholdIncomeRange: '',
            numberInHousehold: ''
          },
          rebateMailingAddress: {
            street1: undefined,
            street2: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined,
          },
          rebateMailingAddressOverride: false,
        },
      }
    }
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.currentPage === 2) {
        this.isKeyboarding = true;
      } else {
        this.isKeyboarding = false;
      }
    });
  },
  async mounted() {

    if (this.currentAccount.customerClass === "C") {
      this.isCommercialAccount = true;
      this.loadState = "complete";
    }
    this.$store.commit("setLastValidPage", 'page1');
    document.addEventListener("click", this.hideTypeAhead);
    this.setRebateSubmissionAccount();
    // Kick off async fetches
    try {
      this.loadState = undefined;
      const localStorageProductSubmissionObj = this.getWithExpiry("localProductSubmission") || undefined;
      if (localStorageProductSubmissionObj &&
        localStorageProductSubmissionObj.submissionType === 'inductionCooktopIncentive') {
        this.productSubmission = localStorageProductSubmissionObj;
      }
      await Promise.all([
        this.getEICMeta(),
        this.REGULATORY_REBATE_SHARING_TEXT1 = await getConfigValue('TXT_RebateRegulatorySharingText1', this.REGULATORY_REBATE_SHARING_TEXT1_DEFAULT, null),
        this.REGULATORY_REBATE_SHARING_TEXT2 = await getConfigValue('TXT_RebateRegulatorySharingText2', this.REGULATORY_REBATE_SHARING_TEXT2_DEFAULT, null)
      ]);
      this.householdIncomeDropdown = this.buildIncomeDropDown(this.metadata.incomeIncentives, this.productSubmission.rebate.householdIncome.numberInHousehold);
      this.loadState = "complete";
    } catch (err) {
      DumpError("Induction Cooktop Incentive loggedin load error", err);
      console.dir(err);
      this.loadState = "error";
    }
    setTimeout(() => {
      this.slatFocus(1);
    }, 1000);
    setTimeout(() => {
      this.resetState();
    }, 1000 * 60 * 60 * 6); // flush local storage & reload page after 6 hours
  },
  methods: {
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    toTitleCase(str) {
      if(!str) { return str; }
      return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },
    calculateTotalSavings() {
      this.totalSavings = 0;
      this.totalSavings = inductionCooktopIncentiveAmount * this.productSubmission.inductionCooktops.length;
    },

    /** Go to a page number without validation */
    gotoPage(page) {
      this.isKeyboarding = false;
      this.$router.push({ path: "/form/" + `${page}/` });
      this.slatFocus(page);
    },
    chooseAccount() {
      this.isKeyboarding = false;
      this.$router.push({ path: "/account" });
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        var elem = document.getElementById(`induction-cooktop-rebate-slat-${page}`);
        elem.scrollIntoView();
      }, 100);
    },
    setRebateSubmissionAccount() {
      this.productSubmission.rebate.rebatePayeeName = `${this.toTitleCase(this.currentAccount.firstName)} ${this.toTitleCase(this.currentAccount.lastName)}`;
      this.productSubmission.account.accountNumber = this.currentAccount.accountNumber;
      this.productSubmission.account.firstName = `${this.toTitleCase(this.currentAccount.firstName)}`;
      this.productSubmission.account.lastName = `${this.toTitleCase(this.currentAccount.lastName)}`;
      this.productSubmission.account.mailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.account.premiseAddress = this.currentAccount.address;
      this.productSubmission.rebate.rebateMailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.rebate.contactPhoneNumber = this.currentAccount.phone;
      this.productSubmission.rebate.confirmationEmailAddress = this.currentAccount.emailAddress;
    },
    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      localStorage.removeItem("localProductSubmission")
      this.setWithExpiry("localProductSubmission", this.productSubmission, (1000 * 60 * 60 * 6)); // 6 hours
      let currentPage = nextPage - 1;
      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            break;
          case "page2":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;
          case "page3":
            this.totalFileSize = 0;
            this.missingDocuments.length = 0;
            if (this.documents && this.documents.length > 0) {
              let file;
              for (let i = 0; i < this.documents.length; i++) {
                if (this.documents[i].file) {
                  if (file != this.documents[i].file) {
                    this.totalFileSize += this.documents[i].file.size;
                  }
                  file = this.documents[i].file;
                }
                if (this.documents[i].file === null || this.documents[i].file === undefined) {
                  this.modalMessage = "You’re missing a required document";
                  this.showMissingDocumentsModal = true;
                  if (this.documents[i].documentName === w9) {
                    this.missingDocuments.push('IRS-W9');
                  } else if (this.documents[i].documentName === proofOfPurchase) {
                    this.missingDocuments.push('Proof of purchase for induction cooktop');
                  }
                }
              }
              if (this.totalFileSize > this.maxFileLoadSize) {
                this.showFileLoadTooBigModal = true;
                this.modalMessage = `Your total file size is ${this.totalFileSize}. Please make sure the file size is less than ${this.maxFileLoadSize}.`;
                break;
              }
            } else {
              this.missingDocuments.push(`Proof of Purchase for induction cooktop`);
              this.modalMessage = "You’re missing a required document";
              this.showMissingDocumentsModal = true;
            }
            break;
          case "page4":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;

          case "page5":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;
        }
        if (this.errors.any(pageName)) {
          this.slatFocus(currentPage);
        } else {
          this.$store.commit("setLastValidPage", pageName);
          this.gotoPage(nextPage);
        }
      }
    },
    closeModal(modalName) {
      if (modalName === 'missingDocument') {
        this.showMissingDocumentsModal = false;
        this.gotoPage(3);
      }
      if (modalName === 'documentChecklist') {
        if (this.selectedDocumentTypes) {
          const array = this.documentTypes.filter(type => !this.selectedDocumentTypes.includes(type));
          this.documentTypes = array;
        }
        this.selectedFile = undefined;
        this.showDocumentChecklist = false;
      }
      if (modalName === 'fileLoadTooBig') {
        this.showFileLoadTooBigModal = false;
        this.gotoPage(3);
      }
    },
    async handleSubmit() {
      const validator = this.$refs.householdIncome;
      if (await validator.validateAllFields('householdincome')) {
        try {
          this.pendingSubmit = true;
          
          const formdata = new FormData();
          // Append JSON data to FormData instance
          formdata.append('productSubmission', new Blob([JSON.stringify(this.productSubmission)], { type: "application/json" }));

          // Filter file list so that duplicate files are not included in request
          const uniqueFiles = this.documents.filter((document, index, self) =>
            index === self.findIndex((d) => (
              d.file.name === document.file.name
            ))
          );

          // Append uploaded files to FormData instance
          for (let i = 0; i < uniqueFiles.length; i++) {
            formdata.append('files', uniqueFiles[i].file);
          }

          localStorage.removeItem("localProductSubmission")
          await GMPAPI.SubmitInductionCooktopIncentive(this.productSubmission.account.accountNumber, formdata);
          this.$router.push({ path: "/complete" });
        } catch (err) {
          DumpError("Induction Cooktop Incentive Submission error", err);
          if (err.response && err.response.data) {
            let code = err.response.data.status;
            if (err.response.data.message) {
              console.error(err.response.data.message);
              code = err.response.data.code;
              this.$router.push({ path: "/error/" + code });
            } else {
              this.$router.push({ path: "/error/" + code });
            }
          } else {
            this.$router.push({ path: "/error/" });
          }
        }
      }
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    resetState() {
      localStorage.removeItem("localProductSubmission");
      window.location.reload(true);
    },
    updateFileList(evt) {
      this.documents = evt;
    },
    async getEICMeta() {
      this.metadata = await GMPAPI.GetEICMeta();
      this.maxFileLoadSize = this.metadata.maxFileUploadSize;
    },
    returnToHome() {
      window.location.href = "/";
    },
    checkIfPurchaseDateIsLessThan60DaysFromNow() {
      const purchaseDate = new Date(this.productSubmission.purchaseDate);
      const today = new Date();
      const sixtyDaysFromNow = new Date(today.setDate(today.getDate() + 60));
      if (purchaseDate > sixtyDaysFromNow) {
        this.showPurchaseDateModal = true;
      }
    },
    addConfirmationEmailError() {
      this.errors.add({ field: "confirmation email address", msg: "Email fields must match.", scope: "page4" })
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>