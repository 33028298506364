<template>
  <div class="my-account__login-page-wrap">
    <section v-if="!enrollSent" class="my-account__login-form-wrap">
      <div class="gds-display-2 gds-space-stack-l">Enroll in Paperless Billing</div>
      <div v-if="loadState === undefined" class="table-loading" />
      <div v-if="loadState === 'error'">The token provided does not appear to be valid. If you copied the link from an email, please make sure you copied the entire URL.</div>
      <div v-if="loadState === 'complete'">
        <form class="global-panel__form" @submit.prevent="Submit()">
          <div
            class="gds-space-stack-l"
          >By selecting an account and clicking submit, you will become enrolled in paperless billing and emails will be sent to: {{tokenAccounts[0].email}}</div>
          <div v-for="account of tokenAccounts" :key="account.accountNumber" class="gds-space-stack-m">
            <label class="gds-checkbox gds-checkbox-thin gds-checkbox-flex">
              <input v-model="account.checked" type="checkbox" />
              <span class="gds-checkbox__faux"></span>
              <span class="gds-font-size-m gds-text-grey">
                {{account.custom_premise_address}}, {{account.custom_premise_city}}
                <br />
                Acct #: {{account.accountNumber}}
              </span>
            </label>
          </div>
          <div class="gds-space-stack-l">
            <div v-if="errorSubmit" id="submission_error" class="gds-helper-text-error">{{errorSubmit}}</div>
          </div>

          <div class="gds-space-stack-m">
            <button :disabled="pendingSubmit" :class="{ 'gds-loading': pendingSubmit }" type="submit" class="gds-button">
              <span class="gds-button__text">Enroll</span>
            </button>
          </div>
        </form>
      </div>
    </section>
    <section v-if="enrollSent" class="my-account__login-form-wrap gds-align--text-center">
      <div class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/message-sent.svg" alt="accounts enrolled" />
      </div>
      <div v-if="checkedAccounts.length > 1" class="gds-display-1 gds-space-stack-l">Your accounts were enrolled in Paperless Billing.</div>
      <div v-else class="gds-display-1 gds-space-stack-l">Your account was enrolled in Paperless Billing.</div>
      <div class="gds-font-size-l gds-space-stack-l">You will be returned to the GMP homepage.</div>

      <div class="gds-space-stack-m global-panel__form">
        <a href="/" class="gds-button">Done</a>
      </div>
    </section>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";

export default {
  name: "PagePaperlessEnroll",
  data() {
    return {
      tokenAccounts: undefined,

      enrollSent: false,

      errorSubmit: undefined,
      pendingSubmit: false,

      loadState: undefined,
    };
  },
  computed: {
    checkedAccounts() {
      if (!this.tokenAccounts) {
        return [];
      }
      return this.tokenAccounts.filter(item => item.checked).map(item => item.accountNumber);
    }
  },
  async mounted() {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    try {
      this.code = params.get("sptoken");
      this.tokenAccounts = await GMPAPI.GetEbillToken(this.code);
      this.loadState = "complete";
    } catch (err) {
      this.loadState = "error";
    }
  },
  methods: {
    async Submit() {
      if (await this.$validator.validateAll()) {
        this.errorSubmit = undefined;
        this.pendingSubmit = true;
        try {
          await GMPAPI.SubmitEbillToken(this.code, this.checkedAccounts);
          this.enrollSent = true;
        } catch (err) {
          this.errorSubmit = "Unable to enroll accounts";
        } finally {
          this.pendingSubmit = false;
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>