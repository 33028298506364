var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    attrs: { id: _vm.sparkBarId, "data-spark-bar-array": _vm.dataAsString }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }