<template>
  <div class="subpage-dashboards subpage-dashboard--user-profile">
    <h1 class="my-account__title gds-space-stack-xl">User Settings</h1>
    <div class="my-account__user-settings-intro gds-font-size-l gds-space-stack-xl">Currently signed in as <span class="gds-font-demi">{{userInfo.fullName}}</span>, {{userInfo.email}}. <span v-if="numLinkedAccounts > 1">There are {{numLinkedAccounts}} energy accounts linked to this user. <a href="/user-profile/accounts"> Manage Accounts</a></span><span v-else>There is 1 energy account linked to this user. <a href="/account/settings"> View Account Settings</a></span></div>

    <editor-profile-name />
    <editor-profile-email />
    <widget-profile-username />
    <editor-profile-password />
    <div>
      <button @click="showDelete = true" type="button" class="gds-button red gds-text-button">
        <span class="gds-button__text">Delete User Account</span>
      </button>
    </div>
    <modal-delete-user :visible="showDelete" @complete="DeleteComplete" />
  </div>
</template>

<script>
import EditorProfileName from "./EditorProfileName";
import EditorProfileEmail from "./EditorProfileEmail";
import WidgetProfileUsername from "./WidgetProfileUsername";
import EditorProfilePassword from "./EditorProfilePassword";
import ModalDeleteUser from './ModalDeleteUser';

export default {
  name: "SubpageSettings",
  components: {
    EditorProfileName,
    EditorProfileEmail,
    WidgetProfileUsername,
    EditorProfilePassword,
    ModalDeleteUser,
  },
  data() {
    return {
      showDelete: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
    numLinkedAccounts() {
      if (this.$store.state.user.accounts) {
        return this.$store.state.user.accounts.length;
      } else {
        return 0;
      }
    },
  },
  async mounted() {
  },
  methods: {
    DeleteComplete() {
      this.showDelete = false;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>