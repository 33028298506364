var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ev-charger-page-container" },
    [
      _vm.settingsModalVisible && _vm.currentAccount
        ? _c("ModalEvChargingSettings", {
            attrs: {
              closeModal: _vm.hideSettingsModal,
              onApiSuccess: _vm.GetEvEnergy,
              evSettings: _vm.evSettings,
              accountNumber: _vm.currentAccount.accountNumber
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.simpleInfoModalVisible
        ? _c("ModalSimpleInfo", {
            attrs: {
              closeModal: _vm.hideSimpleInfoModal,
              modalTitle: _vm.modalSimpleInfoTitle,
              modalContent: _vm.modalSimpleInfoContent
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("usage-detail-controls", {
        staticClass:
          "usage-detail__usage-graph-nav-controls usage-detail__component-container",
        attrs: {
          disableAllDateNavControls: _vm.loadState === "loading",
          disableDateNavigationButtonForward: _vm.currentMonthSelected,
          selectedDate: _vm.intervalString,
          datePickerDateType: _vm.datePickerDateType,
          datePickerDefaultDate: _vm.datePickerDefaultDate,
          showGoToTodayButton: true,
          showViewOptionsButton: true,
          showJumpToDateButton: true,
          showDateForwardsButton: true,
          showDateBackwardsButton: true,
          viewOptionsLabel: "Settings",
          onGoToTodayClicked: _vm.onGoToTodayClicked,
          onSelectedDateChanged: _vm.onSelectedDateChanged,
          onDateForwardsClicked: _vm.onDateForwardsClicked,
          onDateBackwardsClicked: _vm.onDateBackwardsClicked,
          onViewOptionsClicked: _vm.onViewOptionsClicked
        }
      }),
      _vm._v(" "),
      _vm.loadState === "loading"
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "usage-page--interior-status-container" },
        [
          _vm.loadState === "maintenance"
            ? _c("flow-error", {
                attrs: { name: "EV Charger information", state: "maintenance" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "unavailable"
            ? _c("flow-error", {
                attrs: {
                  name: "Charger information",
                  state: "unavailable",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "empty"
            ? _c("flow-error", {
                attrs: {
                  name: "Charger information",
                  state: "nodata",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c("div", { staticClass: "ev-charger-details-header" }, [
              _c(
                "h3",
                { staticClass: "ev-charger-details-section-title main" },
                [_vm._v("Home Charging Summary")]
              ),
              _vm._v(" "),
              _vm.evSettings
                ? _c("div", { staticClass: "vehicle-info" }, [
                    _c("span", { staticClass: "ev-settings-label" }, [
                      _vm._v("Vehicle Information")
                    ]),
                    _vm._v(
                      _vm._s(_vm.evSettings.selectedMakeModel) +
                        ", " +
                        _vm._s(_vm.evSettings.mpgEquivalent) +
                        "MPGe\n      "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ev-charger-details-summary-data" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "text-container" }, [
                    _c("div", { staticClass: "large-text total-charged" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.totalConsumed) +
                          "\n              "
                      ),
                      _c("span", { staticClass: "unit" }, [_vm._v("kWh")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "small-text" }, [
                      _vm._v("Total Charged")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "text-container" }, [
                    _c("div", { staticClass: "large-text" }, [
                      _vm._v("$" + _vm._s(_vm.totalCost))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "small-text" }, [
                      _vm._v("Cost to Charge")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "box fuel-savings" }, [
                  _c(
                    "div",
                    {
                      staticClass: "info-icon",
                      on: {
                        click: function($event) {
                          return _vm.showSimpleInfoModal()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "info-symbol" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 5 14",
                              width: "4",
                              height: "13"
                            }
                          },
                          [
                            _c("title", [_vm._v("More Info")]),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                "fill-rule": "evenodd",
                                d:
                                  "M4 1.3a1.2 1.2 0 1 1-2.5 0 1.2 1.2 0 0 1 2.5 0Zm-.1 10.2h.7c.2 0 .4.2.4.5v.8c0 .3-.2.5-.4.5H.9a.4.4 0 0 1-.4-.5V12c0-.3.2-.5.4-.5h.7c.2 0 .3 0 .3-.2V5.9c0-.1-.1-.2-.3-.2H1a.4.4 0 0 1-.4-.4v-1c0-.2.2-.4.4-.4h1c.9 0 1.6.7 1.6 1.6v5.8c0 .1.1.2.3.2Z",
                                "clip-rule": "evenodd"
                              }
                            })
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-container" }, [
                    _c("div", { staticClass: "large-text" }, [
                      _vm._v("$" + _vm._s(_vm.totalSavings))
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "small-text" }, [
                      _vm._v("Estimated Fuel Savings")
                    ])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            !_vm.isZeroUsageForPeriod
              ? _c("div", [
                  _vm.topChartSeries
                    ? _c("div", [
                        _c("div", { staticClass: "title-and-icons-wrapper" }, [
                          _c("div", { staticClass: "title-and-icons" }, [
                            _c(
                              "h3",
                              {
                                staticClass: "ev-charger-details-section-title"
                              },
                              [_vm._v("Charging Per Day")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "ev-charging-tab-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "ev-tab",
                                    class: {
                                      selected: _vm.selectedDataPoint === "cost"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setDatapoint("cost")
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/icon-cost.svg",
                                        alt: "Cost Icon"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Cost")])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ev-tab",
                                    class: {
                                      selected:
                                        _vm.selectedDataPoint === "duration"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setDatapoint("duration")
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/icon-time.svg",
                                        alt: "Time Icon"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Time")])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ev-tab",
                                    class: {
                                      selected:
                                        _vm.selectedDataPoint === "consumed"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.setDatapoint("consumed")
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/icon-energy.svg",
                                        alt: "Energy Icon"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("kWh")])
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "highcharts-container ev-bar-chart" },
                    [
                      _vm.topChartSeries
                        ? _c("UsageDetailEVChargerBarChart", {
                            attrs: {
                              selectedDataPoint: _vm.selectedDataPoint,
                              chartSeries: _vm.topChartSeries,
                              chartDates: _vm.topChartDates,
                              chartCategories: _vm.topChartCategories
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.isZeroUsageForPeriod &&
            _vm.savingsSeries &&
            _vm.savingsSeries.length
              ? _c("div", [
                  _c("div", { staticClass: "title-and-icons-wrapper" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "ev-charger-details-section-title savings"
                      },
                      [
                        _vm._v("Estimated Cost Savings Per Day "),
                        _c(
                          "span",
                          {
                            staticClass: "info-icon",
                            on: {
                              click: function($event) {
                                return _vm.showSimpleInfoModal()
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "info-symbol" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "none",
                                    viewBox: "0 0 5 14",
                                    width: "4",
                                    height: "13"
                                  }
                                },
                                [
                                  _c("title", [_vm._v("More Info")]),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      fill: "currentColor",
                                      "fill-rule": "evenodd",
                                      d:
                                        "M4 1.3a1.2 1.2 0 1 1-2.5 0 1.2 1.2 0 0 1 2.5 0Zm-.1 10.2h.7c.2 0 .4.2.4.5v.8c0 .3-.2.5-.4.5H.9a.4.4 0 0 1-.4-.5V12c0-.3.2-.5.4-.5h.7c.2 0 .3 0 .3-.2V5.9c0-.1-.1-.2-.3-.2H1a.4.4 0 0 1-.4-.4v-1c0-.2.2-.4.4-.4h1c.9 0 1.6.7 1.6 1.6v5.8c0 .1.1.2.3.2Z",
                                      "clip-rule": "evenodd"
                                    }
                                  })
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "highcharts-container" },
                    [
                      _c("UsageDetailEVChargerLineGraph", {
                        attrs: {
                          chartDates: _vm.savingsDates,
                          savingsSeries: _vm.savingsSeries,
                          savingsCategories: _vm.savingsCategories
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.loadState === "complete" && _vm.isZeroUsageForPeriod) ||
      _vm.loadState === "error"
        ? _c("div", { staticClass: "no-usage-message" }, [
            _c("img", {
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/illustration-ev-charging.svg"
              }
            }),
            _vm._v(" "),
            _vm._m(0)
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-content" }, [
      _c("h2", { staticClass: "title" }, [
        _vm._v("Looks like no EV charging sessions this month.")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "subtitle" }, [
        _vm._v(
          "If you did charge during this period, your charger might have been offline, or there is a temporary issue fetching your data."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }