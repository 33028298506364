var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-modal" }, [
    _c(
      "div",
      {
        staticClass: "my-account__modal-box no-scroll-modal modal-simple-info"
      },
      [
        _c("div", { staticClass: "modal-usage-pick-a-view__header" }, [
          _c(
            "span",
            { staticClass: "modal-usage-pick-a-view__title modal-title" },
            [_vm._v(_vm._s(_vm.modalTitle))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "close bill-pay__close gds-button gds-button-circle gds-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                }
              }
            },
            [
              _c("svg", { staticClass: "gds-icon close-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                  }
                })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("FocusLock", [
          _c("div", {
            staticClass: "modal-usage-pick-a-view__modal-body",
            domProps: { innerHTML: _vm._s(_vm.modalContent) }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }