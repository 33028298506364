var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a",
        {
          staticClass: "outage-message__back-button",
          attrs: { href: _vm.backButtonUrl }
        },
        [
          _c("svg", { staticClass: "gds-icon black" }, [
            _c("use", {
              attrs: {
                "xlink:href":
                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left"
              }
            })
          ]),
          _vm._v(" "),
          _c("span", [_vm._v("Back")])
        ]
      ),
      _vm._v(" "),
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Information for the specified message",
              state: "error"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "empty"
        ? _c("flow-error", {
            attrs: {
              name: "Information for the specified message",
              state: "nodata"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c("section", { staticClass: "outage-message__container" }, [
              _c("span", { staticClass: "gds-text-grey" }, [
                _vm._v(_vm._s(_vm.messageTime))
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.messageTitle))]),
              _vm._v(" "),
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.messageDescription) }
              })
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }