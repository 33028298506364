<template>
  <div id="ev-charger-line-graph">
    <div ref="usageGraphRef" />
  </div>
</template>

<script>
import Highcharts from "highcharts";

let chartDates;
let savingsSeries;
const CHART_LINE_COLOR = '#49B06B';

export default {
  name: 'UsageDetailEVChargerLineGraph',
  mounted() {
    chartDates = this.chartDates;
    savingsSeries = this.savingsSeries;
    this.chart = Highcharts.chart(this.$refs.usageGraphRef, this.chartOptions);
  },
  props: {
    chartDates: Array,
    savingsSeries: Array,
    savingsCategories: Array
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          marginLeft: 20
        },
        title: {
          text: '',
        },
        xAxis: {
          min: -1,
          labels: {
            formatter: function() {
                if (this.pos % 2 !== 0) {
                    return null;
                } else {
                    return this.value;
                }
            }
        },
        categories: this.savingsCategories,
        },
        yAxis: {
          className: 'usage-ev-charger-line-graph-y-axis-label',
          title: {
            text: '',
          },
          labels: {
            useHTML: true,
            align: 'right',
            x: 65,
            y: 0,
            formatter: function() {
              return `<i class='value'>$${this.value}</i>`;
            },
          },
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true,
                    lineWidth: 2
                }
            }
        },
        series: [
          {
            name: '',
            lineWidth: '4',
            data: this.savingsSeries,
            color: CHART_LINE_COLOR,
          },
        ],
        tooltip: {
          shared: false,
          shadow: false,
          useHTML: true,
          outside: true,
          borderWidth: 0,
          borderRadius: 0,
          valueDecimals: 2,
          backgroundColor: 'transparent',
          formatter: function () {
            const index = this.point.index;
            let seriesValue  = savingsSeries[index];

            if (seriesValue === 0) {
              return html;
            }

            seriesValue = seriesValue.toFixed(2);

            let html =`
              <div class='my-account__usage-tooltip'>
                <div class='tooltip__month-year gds-flex-container gds-flex-container--left'>${chartDates[index]}</div>
                <div class='tooltip-rate gds-flex-container gds-flex-container--space-between'>
                  <div class='gds-flex-container gds-flex-container--left'>
                    <div class='rate-swatch' style='background-color: ${CHART_LINE_COLOR}'></div>
                    <div class='rate-name'>Savings</div>
                  </div>
                  <div class='rate-value'>$${seriesValue}</div>
                </div>
            `;

            return html;
          }
        }
      }
    };
  }
};
</script>



<style scoped>
</style>