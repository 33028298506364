var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "my-account__modal-box no-scroll-modal" },
          [
            _c(
              "button",
              {
                staticClass: "close bill-pay__close",
                attrs: { type: "button" },
                on: { click: _vm.close }
              },
              [
                _c("svg", { staticClass: "gds-icon close-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("FocusLock", [
              _c("div", { staticClass: "past-due-wizard__modal-box--body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-xl gds-font-demi gds-space-stack-m"
                  },
                  [
                    _vm._v(
                      "You will be enrolled in Budget Billing. Your consistent monthly payment of $" +
                        _vm._s(this.budgetBillingStatus.totalBudgetAmount) +
                        "/mo for ongoing usage is based on your average usage. If your average usage changes then your Budget Billing is also subject to change. If you stop Budget Billing, your next bill will include an adjustment to true up your account with your actual balance. This is a way to smooth out payments offering you predictability and convenience so you can plan. This is not a discount."
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "my-account__modal-box--message gds-space-stack-l"
                }),
                _vm._v(" "),
                _c("div", { staticClass: "gds-space-stack-ml" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    attrs: { type: "button" },
                    on: { click: _vm.close }
                  },
                  [_vm._v("Ok")]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }