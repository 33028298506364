<template>
  <div>
    <button class="outage-list__slide-in-mobile" @click="$emit('close')">close</button>
    <vue-good-table
      v-if="townData && townData.length"
      :columns="columns"
      :rows="townData"
      :sort-options="{ enabled: true, initialSortBy: {field: 'customers_affected', type: 'desc'} }"
      :search-options="{ enabled: true, placeholder: 'Search Towns' }"
      styleClass="vgt-table town-table outage-table"
      @on-row-click="onRowClick"
      @on-row-mouseenter="onRowHover($event.row.town_name)"
      @on-row-mouseleave="onRowHover(null)"
    >
      <template slot="table-row" slot-scope="props">
        <template v-if="props.column.field == 'active'">
          <div v-if="props.row.customers_affected > 0" class="alert-icon active" />
          <div v-else class="alert-icon inactive" />
        </template>
      </template>
      <div slot="emptystate">No matching towns</div>
    </vue-good-table>
  </div>
</template>

<script>
import { GetTownIncidents, HyphenateTown } from "../../utilities";

export default {
  name: "TownList",
  props: ["activeIncidents", "townData"],
  data() {
    return {
      columns: [
        {
          label: "",
          field: "active",
          sortable: false,
        },
        {
          label: "Town",
          field: "town_name",
        },
        {
          label: "Active Incidents",
          field: "number_incidents",
          type: "number",
          firstSortType: "desc",
        },
        {
          label: "Customers Out",
          field: "customers_affected",
          type: "number",
          firstSortType: "desc",
          formatFn: this.formatFn,
        },
      ],
    };
  },
  async mounted() {
    this.UpdateData();
  },
  methods: {
    onRowClick(params) {
      this.$router.push({ path: "/town/" + HyphenateTown(params.row.town_name) })
    },
    onRowHover(town_name) {
      this.$emit("hover", town_name);
    },
    formatFn: function (value) {
      var n = value;
      n.toLocaleString()
      return n;
    },
    // Attach incident counts to towns
    UpdateData() {
      if (!this.townData || !this.activeIncidents) return;
      // Extend the townData with incident info
      for (const town of this.townData) {
        const incidents = GetTownIncidents(town.town_name, this.activeIncidents);
        // town.link = HyphenateTown(town.town_name);
        town.number_incidents = incidents.length;
      }
      // Sort towns alphabetically for "secondary sort"
      this.townData.sort((a, b) => (a.town_name > b.town_name) ? 1 : ((b.town_name > a.town_name) ? -1 : 0));
    },
  },
  watch: {
    // Note this makes the assumption that townData is available
    activeIncidents() {
      this.UpdateData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>