<template>
  <div>
    <transition name="slat-message-fade">
      <div class="gds-space-stack-l scheduled-payments-list">
        <div :class="`form-message-box form-message-box--${bannerColor} form-message-box--full-width gds-space-stack-s gds-flex-container gds-flex-container--space-between`">
          <div class="form-message-box--left">
            <div class="gds-body-normal">{{ message }} 
              <a v-if="linkOneText.length" @click="processLinkOneClick" class="gds-link gds-link--bold cursorPointer">{{ linkOneText }}</a>
              {{ messageTwo }}
              <a v-if="linkTwoText.length" @click="processLinkTwoClick" class="gds-link gds-link--bold cursorPointer">{{ linkTwoText }}.</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "BannerDualAction",
  props: {
    bannerColor: String,
    message: String,
    messageTwo: String,

    linkOneText: String,
    linkTwoText: String,
  },
  data() {
    return {
      isRefreshing: true,
      linkOneClicked: false,
      linkTwoClicked: false
    }
  },
  computed: {
  },
  mounted() {
    setTimeout(() => {
      this.isRefreshing = false
    }, 3000)
  },
  methods: {
    processLinkOneClick() {
      this.$emit('linkOneClicked');
      if (this.linkOneClicked) {
        this.linkOneClicked = true;
      }
    },
    processLinkTwoClick() {
      this.$emit('linkTwoClicked');
      if (this.linkTwoClicked) {
        this.linkTwoClicked = true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
