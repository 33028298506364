<template>
  <div class="report-outage__offwhite-message-box gds-flex-container gds-flex-container--top gds-space-stack-l">
    <div class="report-outage__offwhite-message-box__by-phone">
      <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Report an Outage by Phone</div>
      <div class="gds-font-size-m gds-space-stack-m">{{ GMPPhoneNumber }}</div>
    </div>
    <div class="report-outage__offwhite-message-box__by-text">
      <div class="gds-font-size-l gds-font-demi gds-space-stack-s">Report an Outage by Text</div>
      <div class="gds-font-size-m">Text "OUT" to 46788 to report an outage by text.*</div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
export default {
  name: "ManualReportingBoxWide",
  data() {
    return {
      GMPPhoneNumber,
    };
  },
};
</script>

<style scoped>
</style>