<template>
  <!-- This file can be deleted -->
  <div class="make-payment-container">
    <div id="top-target" class="bill-pay-success-fail">
      <div class="bill-pay-fail__intro-image gds-space-stack-xl">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-fatal-error.svg" alt="unsuccessful payment" />
      </div>

      <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Oh no!</h2>

      <div class="bill-pay-fail__description gds-body-large gds-align--text-center gds-space-stack-xl">
        We were unable to process your request at this time. Please try again or contact customer support for assistance at
        <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
      </div>

      <div class="bill-pay-success__button-wrap gds-align--text-center gds-space-stack-l">
        <!-- <router-link to="/stop/1" class="bill-pay-success__button gds-button gds-secondary gds-space-inline-s">Back</router-link> -->
        <button @click="ReturnToSender('/stop-service')" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
import { ReturnToSender } from "../../utilities";

export default {
  name: "StopServiceFatalError",
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  computed: {
    code() {
      return Number(this.$route.params.code);
    }
  },
  async mounted() {
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>