<template>
  <div>
    <div class="welcome-message welcome-message--clear-day">
      <div v-if="!loggedInUser">
        <div class="welcome-message__headline gds-font-medium gds-space-stack-l">{{homepageHeading}}</div>
        <span v-if="homepageSubheading" class="welcome-message__subheadline">{{homepageSubheading}}</span>
        <!-- Call to action with title -->
        <span v-if="homepageLink && homepageLink.title" class="welcome-message__cta">
          <a :href="homepageLink.url" :target="homepageLink.target">{{homepageLink.title}}</a>
        </span>
        <!-- Call to action no title -->
        <span v-if="homepageLink && !homepageLink.title" class="welcome-message__cta">
          <a :href="homepageLink.url" :target="homepageLink.target" class="gds-card__button gds-button gds-button-circle gds-secondary">
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
            </svg>
          </a>
        </span>
      </div>
      <div v-if="loggedInUser" class="welcome-message__headline gds-font-medium gds-space-stack-l">Welcome back, {{loggedInUser.givenName}}</div>
    </div>
    <div class="welcome-message welcome-message--storm-mode">
      <div class="welcome-message__headline gds-font-medium gds-space-stack-ml">{{stormWelcome}}</div>
      <div v-html="stormMessage" class="gds-body-medium gds-space-stack-ml"></div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../environment";

export default {
  name: "WidgetWelcomeMessage",
  data() {
    return {
      homepageHeading: "Better energy for Vermont.",
      homepageSubheading: undefined,
      homepageLink: undefined,
      stormWelcome: "",
      stormMessage: "",
      GMPPhoneNumber,
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    if (typeof gmp_acf !== undefined && gmp_acf) {
      this.stormWelcome = gmp_acf.gmp_storm_mode_heading;
      this.stormMessage = gmp_acf.gmp_storm_mode_message;

      // If no heading defined, will stay with "better energy"
      if (gmp_settings.homepage_heading) {
        this.homepageHeading = gmp_settings.homepage_heading;
      }
      this.homepageSubheading = gmp_settings.homepage_subheading;
      this.homepageLink = gmp_settings.homepage_call_to_action;
      // Clean up wordpress empty strings to undefineds
      if (this.homepageLink && this.homepageLink.target === "") this.homepageLink.target = undefined;
      if (this.homepageLink && this.homepageLink.title === "") this.homepageLink.title = undefined;
      if (this.homepageLink && this.homepageLink.url === "") this.homepageLink.url = undefined;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
