var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "settings-editor my-account__full-width-section my-account__section-with-bottom-border"
    },
    [
      _c(
        "h2",
        {
          ref: "scrolltarget",
          staticClass: "settings-editor__title gds-space-stack-m"
        },
        [_vm._v("Account Photo")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--left my-account__sub-section-bottom"
        },
        [
          _c("div", {
            staticClass: "small-round-img img-large gds-space-inline-default",
            style: {
              backgroundImage:
                "url(" +
                _vm.GetResizedAccountPhoto(_vm.currentAccount.photoUrl) +
                ")"
            }
          }),
          _vm._v(" "),
          !_vm.editing
            ? _c(
                "button",
                {
                  staticClass: "gds-button gds-round gds-secondary gds-compact",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.editing = true
                    }
                  }
                },
                [_vm._v("Edit")]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.editing
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "my-account__settings__image-picker-flex gds-flex-container gds-flex-container--left gds-flex-container--top gds-space-stack-m"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "my-account__settings__image-picker-flex-item"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "gds-font-size-m gds-font-medium gds-space-stack-s"
                      },
                      [_vm._v("Pick Icon")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "gds-flex-container gds-flex-container--left"
                      },
                      _vm._l(_vm.defaultIcons, function(iconUrl, index) {
                        return _c("div", {
                          key: index,
                          staticClass:
                            "gds-space-inline-default account-photo-picker",
                          class: { selected: _vm.selectedIcon === index },
                          style: { backgroundImage: "url(" + iconUrl + ")" },
                          attrs: { tabindex: "0" },
                          on: {
                            click: function($event) {
                              return _vm.ClickDefault(index)
                            },
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.ClickDefault(index)
                            }
                          }
                        })
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gds-font-size-m gds-font-medium gds-space-stack-s"
                    },
                    [_vm._v("Custom Image")]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "gds-file gds-flex-container gds-flex-container--left",
                      attrs: { tabindex: "0" },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.$refs.filePicker.click()
                        }
                      }
                    },
                    [
                      _c("div", [
                        _c("div", {
                          staticClass: "account-photo-picker",
                          class: { selected: _vm.selectedIcon === 999 },
                          style: {
                            backgroundImage: "url(" + _vm.customIconData + ")"
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          ref: "filePicker",
                          attrs: {
                            disabled: _vm.pendingSave,
                            type: "file",
                            accept: "image/png, image/jpeg",
                            tabindex: "-1"
                          },
                          on: { change: _vm.FilePicked }
                        })
                      ]),
                      _vm._v(" "),
                      _vm._m(0)
                    ]
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "gds-button gds-secondary gds-round gds-space-stack-l",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.Close()
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Cancel")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "gds-button gds-round gds-space-stack-l",
                class: { "gds-loading": _vm.pendingSave },
                attrs: {
                  disabled: _vm.pendingSave || !_vm.touched,
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.Save()
                  }
                }
              },
              [
                _c("span", { staticClass: "gds-button__text" }, [
                  _vm._v("Save")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.errorSave
              ? _c(
                  "div",
                  { staticClass: "form-message-box gds-space-stack-m" },
                  [_vm._v(_vm._s(_vm.errorSave))]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "gds-font-size-m gds-space-stack-s" }, [
        _vm._v("Click to browse your device for an image file.")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "gds-font-size-s gds-text-grey" }, [
        _vm._v("Supports jpg or png files up to 5MB.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }