<template>
  <div>
    <form @submit.prevent class="gds-space-stack-l">
      <h2 v-if="showTitle" class="gds-display-1 gds-space-stack-l">Add New Payment Method</h2>
      <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Payment Method Nickname</span>
          <input
            v-model="wallet.accountNickName"
            v-validate="'required|max:45'"
            name="account nickname"
            key="account nickname"
            class="gds-input-field__input"
            type="text"
            placeholder="Nickname"
            maxlength="45"
          />
        </label>
        <div v-if="errors.first('account nickname')" class="validation-error">{{ errors.first("account nickname")}}</div>
      </fieldset>
      <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding">
        <label class="gds-radio">
          <input v-model="wallet.paymentType" value="CC" type="radio" name="my-radio-group" />
          <span class="gds-radio__faux"></span>
          <span class="gds-radio__label">Credit / Debit Card</span>
        </label>
        <label class="gds-radio">
          <input v-model="wallet.paymentType" value="ATM" type="radio" name="my-radio-group" />
          <span class="gds-radio__faux"></span>
          <span class="gds-radio__label">ATM Card</span>
        </label>
        <label class="gds-radio">
          <input v-model="wallet.paymentType" value="ACH" type="radio" name="my-radio-group" />
          <span class="gds-radio__faux"></span>
          <span class="gds-radio__label">Bank Account</span>
        </label>
      </fieldset>
      <div v-if="wallet.paymentType === 'CC' || wallet.paymentType === 'ATM'">
        <!-- New credit card / ATM fields -->
        <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Name on Card</span>
            <input
              v-model="wallet.debitName"
              v-validate="'required|max:45'"
              name="name on card"
              key="name on card"
              class="gds-input-field__input"
              type="text"
              placeholder="Name"
              maxlength="45"
              autocomplete="cc-name"
            />
          </label>
          <div v-if="errors.first('name on card')" class="validation-error">{{ errors.first("name on card")}}</div>
        </fieldset>
        <div class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-l">
          <fieldset class="bill-pay-slat__card-number gds-fieldset gds-fieldset--no-left-padding">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Card Number</span>
              <div class="gds-position--relative">
                <span class="bill-pay__credit-card-prefix gds-input-field__prefix gds-text">
                  <svg class="bill-pay__icon gds-icon">
                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                  </svg>
                </span>
                <input
                  v-model="creditNumber"
                  v-mask="'################'"
                  v-validate="'required|numeric|min:15|max:16'"
                  name="card number"
                  key="card number"
                  class="gds-input-field__input"
                  type="tel"
                  maxlength="16"
                  autocomplete="cc-number"
                />
              </div>
              <div v-if="errors.first('card number')" class="validation-error">{{ errors.first("card number")}}</div>
            </label>
          </fieldset>
          <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding-when-small">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Expiration</span>
              <input
                v-model="wallet.expirationDate"
                v-mask="'##/##'"
                v-validate="'required|date_format:MM/yy|after:' + firstValidCard"
                name="expiration date"
                key="expiration date"
                class="gds-input-field__input"
                type="text"
                placeholder="MM/YY"
                autocomplete="cc-exp"
              />
            </label>
            <div v-if="errors.first('expiration date')" class="validation-error">{{ errors.first("expiration date")}}</div>
          </fieldset>
          <fieldset v-if="wallet.paymentType === 'CC'" class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding-when-small">
            <label class="gds-input-field">
              <span class="gds-input-field__label">CVV</span>
              <input
                v-model="wallet.cvv"
                v-mask="'####'"
                v-validate="'required|numeric|min:3|max:4'"
                name="CVV"
                key="CVV"
                class="gds-input-field__input"
                type="tel"
                maxlength="4"
                autocomplete="cc-csc"
              />
            </label>
            <div v-if="errors.first('CVV')" class="validation-error">{{ errors.first("CVV")}}</div>
          </fieldset>
          <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding-when-small">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Billing ZIP Code</span>
              <input
                v-model="wallet.debitZip"
                v-validate="'required|numeric|min:5|max:5'"
                v-mask="'#####'"
                name="billing zip"
                key="billing zip"
                class="gds-input-field__input"
                type="tel"
                maxlength="5"
              />
            </label>
            <div v-if="errors.first('billing zip')" class="validation-error">{{ errors.first("billing zip")}}</div>
          </fieldset>
        </div>
      </div>
      <div v-if="wallet.paymentType === 'ACH'">
        <!-- New bank account fields -->
        <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Account Type</span>
            <select
              v-model="wallet.transType"
              v-validate="'required'"
              name="account type"
              key="account type"
              class="bill-pay-input-field__input gds-input-field bill-pay-input-field__select"
            >
              <option value="SAVINGS">Savings</option>
              <option value="CHECKING">Checking</option>
              <option value="MONEY_MARKET">Money Market</option>
            </select>
            <div v-if="errors.first('account type')" class="validation-error">{{ errors.first("account type")}}</div>
          </label>
        </fieldset>
        <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Account Number</span>
            <input
              v-model="bankNumber"
              v-mask="'######################'"
              v-validate="'required|numeric|min:5|max:22'"
              name="account number"
              key="account number"
              class="bill-pay-input-field__input gds-input-field__input"
              type="tel"
              maxlength="22"
            />
            <div v-if="errors.first('account number')" class="validation-error">{{ errors.first("account number")}}</div>
          </label>
        </fieldset>
        <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Routing Number</span>
            <input
              v-model="wallet.debitRouting"
              v-mask="'#########'"
              v-validate="'required|numeric|min:9'"
              name="routing number"
              key="routing number"
              class="bill-pay-input-field__input gds-input-field__input"
              type="tel"
              maxlength="9"
            />
            <div v-if="errors.first('routing number')" class="validation-error">{{ errors.first("routing number")}}</div>
          </label>
        </fieldset>
      </div>
    </form>

    <div class="gds-flex-container gds-flex-container--left">
      <button v-if="showCancelButton" @click="Complete()" type="button" class="bill-pay__paired-button gds-button gds-compact gds-secondary">Cancel</button>
      <button @click="Save()" :disabled="pendingSave" :class="{ 'gds-loading': pendingSave }" type="button" class="bill-pay__paired-button gds-button gds-compact">
        <span class="gds-button__text">Add</span>
      </button>
    </div>
    <div v-if="errorSave" class="form-message-box gds-space-stack-l">{{errorSave}}</div>
  </div>
</template>

<script>
import { format, addMonths } from 'date-fns';
import GMPAPI from "../../../services/gmpapi";
import { DumpError, ToFullExpirationDate, GetCardType } from "../../../utilities";
import { InterpretSpeedpayCode } from "../../../speedpay";

export default {
  name: "EditorWalletNew",
  props:
    ['showTitle', 'showCancelButton'],
  data() {
    return {
      wallet: {
        accountNickName: undefined,
        paymentType: "CC",
        transType: "CHECKING",
      },
      creditNumber: undefined,
      bankNumber: undefined,
      firstValidCard: format(addMonths(new Date(), -1), "MM/yy"),
      paymentMeta: undefined,

      pendingSave: false,
      errorSave: false,
    };
  },
  components: {
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    try {
      const meta = await GMPAPI.GetPaymentMeta();
      this.paymentMeta = meta;
    } catch (err) {
      DumpError("Payment meta error", err);
    }
  },
  methods: {
    async Save() {
      this.errorSave = undefined;
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        try {
          const wallet = this.wallet;
          let tokenizedCard;
          if (wallet.paymentType !== "ACH") {
            // If a card, get type and tokenize
            const cardType = GetCardType(this.creditNumber);
            if (!cardType) {
              // Unknown card type, bail!
              // Make sure we have a fresh ErrorBag for handling special cases
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add({ field: "card number", msg: "Not a valid card number" });
              return;
            }
            wallet.cardType = cardType;

            try {
              tokenizedCard = await GMPAPI.TokenizeCreditCard(
                this.paymentMeta.tokenUrl,
                this.creditNumber
              );
            } catch (err) {
              // Bad response from tokenizer
              DumpError("CC tokenize error", err);
              // Make sure we have a fresh ErrorBag for handling special cases
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add({ field: "card number", msg: "Not a valid card" });
              return;
            }
          }
          const walletRecord = {
            accountNickName: wallet.accountNickName,
            paymentType: wallet.paymentType,
            debitAccount: (wallet.paymentType === "ACH") ? this.bankNumber : tokenizedCard,
            debitRouting: wallet.debitRouting,
            expirationDate: wallet.expirationDate ? ToFullExpirationDate(wallet.expirationDate) : undefined,
            cvv: wallet.cvv || "000",
            transType: wallet.transType,
            creditAccount: this.currentAccount.accountNumber,
            cardType: wallet.cardType,
            // Populate all location fields from account, should be required but ignored
            debitZip: wallet.debitZip || this.currentAccount.address.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1
          };
          await GMPAPI.CreateWallet(
            this.currentAccount.accountNumber,
            walletRecord
          );
          // Kick back to wallet list and refresh
          this.Complete();
        } catch (err) {
          DumpError("Create wallet error", err);
          let code;
          if (err.response && err.response.data) {
            code = err.response.data.code;
            if (err.response.data.message) {
              console.error(code + ": " + err.response.data.message);
            }
          }
          // Take specific actions on certain error codes here
          const parsed = InterpretSpeedpayCode(code);
          console.log("Speedpay code: ", code);
          if (parsed) {
            if (parsed.field) {
              // This is a field-specific error
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add(parsed);
            } else {
              // This is a generic error
              this.errorSave = parsed.msg;
            }
          } else {
            // Error not recognized or no response data
            this.errorSave = "There was an unexpected error saving your payment method.";
          }
        } finally {
          this.pendingSave = false;
        }
      }
    },
    Complete() {
      this.$emit("complete");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>