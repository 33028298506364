var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "outage-breadcrumb-header gds-flex-container gds-flex-container--left"
    },
    [
      _c(
        "router-link",
        { staticClass: "breadcrumb__outage-home", attrs: { to: "/" } },
        [_vm._v("Outage Center")]
      ),
      _vm._v(" "),
      _vm.incident
        ? _c(
            "div",
            { staticClass: "breadcrumb__chevrons gds-flex-container" },
            [
              _c("svg", { staticClass: "gds-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                  }
                })
              ]),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "Town",
                      params: { townname: _vm.incidentTownSlug }
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.incident.town) + " VT")]
              ),
              _vm._v(" "),
              _c("svg", { staticClass: "gds-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                  }
                })
              ]),
              _vm._v(
                "\n    " +
                  _vm._s(_vm._f("titlecase")(_vm.incident.street)) +
                  "\n  "
              )
            ],
            1
          )
        : _vm.town
        ? _c(
            "div",
            { staticClass: "breadcrumb__town-state gds-flex-container" },
            [
              _c("svg", { staticClass: "gds-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                  }
                })
              ]),
              _vm._v("\n    " + _vm._s(_vm.town.town_name) + " VT\n  ")
            ]
          )
        : _vm.planned
        ? _c(
            "div",
            { staticClass: "breadcrumb__town-state gds-flex-container" },
            [
              _c("svg", { staticClass: "gds-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                  }
                })
              ]),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/planned-outages" } }, [
                _vm._v("Planned Outages")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.planned && _vm.planned !== true
        ? _c(
            "div",
            { staticClass: "breadcrumb__town-state gds-flex-container" },
            [
              _c("svg", { staticClass: "gds-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                  }
                })
              ]),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.planned.outageLocation || "Unknown location") +
                  "\n  "
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }