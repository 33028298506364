<template>
  <div v-if="loadstate === 'complete'" class="gds-flex-container gds-flex-container--left gds-space-stack-m">
    <!-- Icon priority is pending > overdue > normal -->
    <div v-if="pendingPayments || scheduledPayments" class="bill-pay-login__blue-pending-circle">
      <svg class="gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-pending" />
      </svg>
    </div>
    <div v-else-if="account.amountDue > 0 && !pastDue">
      <!-- No icon if balance is not past due -->
    </div>
    <div v-else-if="account.amountDue > 0 && pastDue">
      <svg class="gds-icon gds-icon-warning" viewBox="0 0 32 32">
        <path fill="#F9C23A" fill-rule="evenodd"
          d="M17.017 6.21a2 2 0 0 1 .705.704l9.495 16.069A2 2 0 0 1 25.495 26H6.505a2 2 0 0 1-1.722-3.017l9.495-16.07a2 2 0 0 1 2.74-.703zM16 19.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zM17 12h-2v6h2v-6z" />
      </svg>
    </div>
    <div v-else class="bill-pay-login__green-check-cirlce">
      <svg class="bill-pay-login__white-check-icon gds-icon">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
      </svg>
    </div>

    <div class="gds-font-size-m payment-status-pending">
      <span v-if="account.amountDue > 0 && pastDue">{{ pastDueBalance | currency }} of
        balance is due now.</span>
      <span v-else-if="account.amountDue > 0">{{ account.amountDueDate | dateDue }}{{ pendingPayments ||
          scheduledPayments ?
          ","
          : ""
      }}</span>
      <span v-else>All paid up{{ pendingPayments || scheduledPayments ? "," : "" }}</span>
      <span v-if="pendingPayments && !pastDue">{{ pendingPayments > 1 ? "payments" : "payment" }} pending.</span>
      <span v-else-if="pendingPayments && pastDue">{{ pendingPayments > 1 ? "Payments" : "Payment" }} pending.</span>
      <span v-else-if="scheduledPayments && !pastDue">{{ scheduledPayments > 1 ? "payments" : "payment" }} scheduled.</span>
      <span v-else-if="scheduledPayments && pastDue">{{ scheduledPayments > 1 ? "Payments" : "Payment" }} scheduled.</span>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";

export default {
  name: "PaymentStatusDisplay",
  props: {
    account: {
      type: Object,
      required: true,
    },
    disablePending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pendingPayments: 0,
      scheduledPayments: 0,
      pastDueBalance: 0,
      loadstate: undefined,
    };
  },
  computed: {
    pastDue() {
      if (!this.account || !this.account.amountDueDate) return false;
      return (this.pastDueBalance > 0);
    },
  },
  async mounted() {
    this.RefreshData();
  },
  methods: {
    async RefreshData() {
      this.loadstate = undefined;
      this.pendingPayments = 0;
      this.scheduledPayments = 0;
      this.pastDueBalance = 0;
      if (!this.disablePending) {
        const recentHistory = await GMPAPI.GetBillingHistory(this.account.accountNumber, 3);
        this.pendingPayments = recentHistory.filter(item => item.status === "PENDING").length;
        this.scheduledPayments = recentHistory.filter(item => item.status === "SCHEDULED").length;
      }
      const accountNumber = this.account.accountNumber;
      const status = await GMPAPI.GetAccountStatus(accountNumber);
      if (status && (status.pastDue30Balance || status.pastDue60Balance)) {
        this.pastDueBalance = status.pastDue30Balance + status.pastDue60Balance;
      }
      this.loadstate = 'complete';
    },
  },
  watch: {
    async account() {
      await this.RefreshData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>