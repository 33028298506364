var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "webchat-widget" }, [
    _vm.queueOnline
      ? _c("div", [
          _c("div", { staticClass: "gds-space-stack-m" }, [
            _c(
              "button",
              {
                staticClass:
                  "gds-button gds-round gds-invert webchat-button webchat-button-enabled",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.StartWebchat()
                  }
                }
              },
              [_vm._v("Start Chat")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "webchat-queue-count" }, [
              _vm._v(_vm._s(_vm.numCalls) + " people in queue")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "webchat-available-text" }, [
            _vm._v("Other ways to get in touch")
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.queueOnline
      ? _c("div", [
          _c(
            "button",
            {
              staticClass:
                "gds-button gds-round gds-secondary gds-space-stack-m webchat-button",
              attrs: { disabled: "", type: "button" }
            },
            [_vm._v("Chat is offline")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "webchat-unavailable-text" }, [
            _vm._v(
              "Hi! Chat is not available right now, so please give us a call or email us. Thanks!"
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }