var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "eicproduct-slat__contents-inner" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("form", { attrs: { "data-vv-scope": "householdincome" } }, [
      _c("label", { staticClass: "gds-input-field" }, [
        _c("span", { staticClass: "gds-input-field__label" }, [
          _vm._v(
            "Are you willing to share information about your\n        household income?"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gds-position--relative" }, [
          _c("div", { staticClass: "gds-space-stack-l" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value:
                      _vm.productSubmission.rebate.householdIncome
                        .willShareIncome,
                    expression:
                      "productSubmission.rebate.householdIncome.willShareIncome"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                key: "household income response",
                staticClass:
                  "eicproduct-input-field__select eicproducts-income_share",
                attrs: { name: "household income response", autofocus: "" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.productSubmission.rebate.householdIncome,
                        "willShareIncome",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function($event) {
                      return _vm.onChangeWillShareIncome($event)
                    }
                  ]
                }
              },
              [
                _c(
                  "option",
                  { attrs: { value: "", disabled: "", select: "" } },
                  [_vm._v("Select...")]
                ),
                _vm._v(" "),
                _c("option", { attrs: { value: "yes" } }, [_vm._v("Yes")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "no" } }, [_vm._v("No")])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.errors.first("household income response", "householdincome")
        ? _c("div", { staticClass: "eicproduct-validation-error" }, [
            _vm._v(
              _vm._s(
                _vm.errors.first("household income response", "householdincome")
              )
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.productSubmission.rebate.householdIncome.willShareIncome &&
      _vm.productSubmission.rebate.householdIncome.willShareIncome.toLowerCase() ===
        "yes"
        ? _c("div", { staticClass: "row-s" }, [
            _c("div", { staticClass: "gds-space-stack-l" }, [
              _c("label", { staticClass: "gds-input-field" }, [
                _c("span", { staticClass: "gds-input-field__label" }, [
                  _vm._v("Number Of Household Members")
                ]),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.householdIncome.numberInHousehold,
                          expression: "householdIncome.numberInHousehold"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      key: "number in household",
                      staticClass: "eicproduct-input-field__select",
                      attrs: { name: "number in household", autofocus: "" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.householdIncome,
                              "numberInHousehold",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            _vm.buildIncomeDropDown()
                            _vm.clearHouseholdIncome()
                            _vm.emitIncomeInfo()
                          }
                        ]
                      }
                    },
                    [
                      _vm._l(_vm.metadata.incomeIncentives, function(
                        option,
                        index
                      ) {
                        return _c(
                          "option",
                          {
                            key: index,
                            domProps: { value: option.numberInHousehold }
                          },
                          [
                            _vm._v(
                              _vm._s(option.numberInHousehold) +
                                "\n              "
                            )
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "option",
                        { attrs: { value: "", disabled: "", select: "" } },
                        [_vm._v("Select...")]
                      )
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.errors.first("number in household", "householdincome")
                ? _c(
                    "div",
                    {
                      staticClass: "eicproduct-validation-error",
                      staticStyle: { "max-width": "80%" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.errors.first(
                            "number in household",
                            "householdincome"
                          )
                        ) + "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "gds-input-field" }, [
              _c("span", { staticClass: "gds-input-field__label" }, [
                _vm._v("Gross Household Income in " + _vm._s(_vm.lastYear))
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.householdIncome.grossHouseholdIncomeRange,
                      expression: "householdIncome.grossHouseholdIncomeRange"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  key: "gross household income",
                  staticClass: "eicproduct-input-field__select",
                  attrs: {
                    autofocus: "",
                    name: "gross household income",
                    disabled: !this.householdIncome.numberInHousehold
                      ? true
                      : false
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.householdIncome,
                          "grossHouseholdIncomeRange",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.emitIncomeInfo
                    ]
                  }
                },
                _vm._l(_vm.householdIncomeDropdown, function(option, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: option.incomeAmount } },
                    [_vm._v(_vm._s(option.incomeOption) + "\n          ")]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.errors.first("gross household income", "householdincome")
                ? _c("div", { staticClass: "eicproduct-validation-error" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.errors.first(
                            "gross household income",
                            "householdincome"
                          )
                        )
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "eicproducts-required-text gds-space-stack-m" },
      [
        _vm._v(
          "\n    One more thing! To ensure all Vermonters benefit equitably from the transition to clean energy, we’re asking\n    program participants whether you would like to "
        ),
        _c("span", [_c("b", [_vm._v("voluntarily")])]),
        _vm._v(
          " share your household size and income. \n    This helps develop better programs to meet the needs of customers. Your personal information is confidential.\n  "
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }