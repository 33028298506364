<template>
  
  <div v-if="currentAccount && currentAccount.disconnectNotice && currentAccount.disconnectNotice.disconnectDate" class="disconnect-banner gds-flex-container">
    <div v-if="!alreadyDisconnected" class="gds-flex-container gds-flex-container--nowrap">
      <svg class="disconnect-banner--icon gds-icon gds-space-inline-s"><use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-warning"></use></svg>
      <span>Your power is scheduled to be shut off on {{currentAccount.disconnectNotice.disconnectDate | dateFull}} due to non-payment.</span>
    </div>
    <div v-if="alreadyDisconnected" class="gds-flex-container gds-flex-container--nowrap">
      <svg class="disconnect-banner--icon gds-icon gds-space-inline-s"><use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-warning"></use></svg>
      <span>Your power was scheduled to be shut off on {{currentAccount.disconnectNotice.disconnectDate | dateFull}} due to non-payment.</span>
    </div>
  </div>
</template>

<script>
import { isPast } from 'date-fns';
import { ParseDisconnectDate } from '../utilities';
export default {
  name: "WidgetDisconnectBanner",
  data() {
    return {
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    alreadyDisconnected() {
      if (this.currentAccount && this.currentAccount.disconnectNotice && this.currentAccount.disconnectNotice.disconnectDate) {
        return isPast(ParseDisconnectDate(this.currentAccount.disconnectNotice.disconnectDate));
      }
      return false;
    }
  },
  mounted() {
    this.UpdateBodyClass();
  },
  methods: {
    UpdateBodyClass() {
      // If currently selected account has a disconnect notice, set body class
      if (this.currentAccount && this.currentAccount.disconnectNotice) {
        document.body.classList.add("gmp-disconnect-notice");
      } else {
        document.body.classList.remove("gmp-disconnect-notice");
      }
    }
  },
  watch: {
    currentAccount() {
      this.UpdateBodyClass();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
