<template>
  <div>
    <div class="report-outage-flow-container">
      <div v-if="loadState === undefined" class="bill-pay__page-loader">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
      </div>
      <flow-error v-if="loadState === 'error'" name="Report Outage" state="error" phonetext="To report an outage by phone call us at" closepath="/report-outage" />
      <div v-if="loadState === 'complete'">
        <form v-if="!completedSubmit" class="bill-pay-login__form" @submit.prevent>
          <fieldset v-if="true" class="gds-fieldset gds-space-stack-l">
            <legend class="gds-space-stack-l gds-display-1">Where is the outage?</legend>
            <div class="gds-space-stack-l gds-body-normal">If you don't see your location listed, call GMP at {{ GMPPhoneNumber }} to report the outage.</div>
            <!-- If only one account, we just display it read-only -->
            <div v-if="personalData && personalData.length === 1 && selectedAccount">
              <div class="gds-flex-container gds-flex-container--left gds-flex-container--top">
                <div class="outage-house-circle-icon">
                  <img src="/wp-content/themes/gmptwentynineteen/assets/images/outage-center/home.svg" />
                </div>
                <div>
                  <span v-if="selectedAccount.nickname">
                    <strong>{{selectedAccount.nickname}}</strong>
                    <br />
                  </span>
                  <span class="gds-linebreaks">{{selectedAccount.address | addressFormat({ separator: "\n" })}}</span>
                  <br />
                  <span v-if="!phoneLogin" class="gds-linebreaks">Acct. # {{selectedAccount.accountNumber}}</span>
                  <div v-if="selectedAccount.outageReported || (selectedAccount.incidents && selectedAccount.incidents.length)">
                    <div class="outage-status gds-flex-container gds-flex-container--left">
                      <div class="outage-reporting__alert-icon active">
                        <img src="/wp-content/themes/gmptwentynineteen/assets/images/glyph-alert-orange.svg" />
                      </div>
                      <div>
                        <div class="gds-font-demi gds-font-size-m gds-space-stack-s">
                          An outage is already reported at this location.
                          <span
                            v-if="selectedAccount.incidents && selectedAccount.incidents.length && selectedAccount.incidents[0].id"
                            class="outage-report-link"
                          >
                            <a :href="'/outages/incident/' + selectedAccount.incidents[0].id">View Details</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- If multiple accounts, let them choose from all -->
            <div v-if="personalData && personalData.length > 1">
              <div v-for="account in personalData" :key="account.accountNumber">
                <label class="bill-pay-login__form--radio-label-flex gds-radio">
                  <input v-model="selectedAccountNumber" type="radio" :value="account.accountNumber" />
                  <span class="gds-radio__faux"></span>
                  <span class="gds-radio__label bill-pay-login__radio-label">
                    <span v-if="account.nickname">
                      <strong>{{account.nickname}}</strong>
                      <br />
                    </span>
                    <span class="gds-linebreaks">{{account.address | addressFormat({ separator: "\n" })}}</span>
                    <br />
                    <span v-if="!phoneLogin" class="gds-linebreaks">Acct. # {{account.accountNumber}}</span>
                    <div v-if="account.outageReported || (account.incidents && account.incidents.length)">
                      <div class="outage-status gds-flex-container gds-flex-container--left gds-flex-container--top">
                        <div class="outage-reporting__alert-icon active">
                          <img src="/wp-content/themes/gmptwentynineteen/assets/images/glyph-alert-orange.svg" />
                        </div>
                        <div>
                          <div class="gds-font-demi gds-font-size-m gds-space-stack-s">
                            An outage is already reported at this location.
                            <span
                              v-if="account.incidents && account.incidents.length && account.incidents[0].id"
                              class="outage-report-link"
                            >
                              <a :href="'/outages/incident/' + account.incidents[0].id">View Details</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </label>
              </div>
            </div>
            <div v-if="!personalData" class="table-loading" />
          </fieldset>
          <div class="gds-display-1 gds-space-stack-s">Your Contact information</div>
          <div class="gds-space-stack-l gds-body-normal">Please enter your 10 digit phone number in case we need to contact you regarding this outage.</div>
          <fieldset class="gds-fieldset gds-space-stack-s gds-space-stack-xl">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Phone Number</span>
              <the-mask
                v-model="phoneNumber"
                :mask="'+1 (###) ###-####'"
                @input.native="touched = true"
                v-validate="'required|min:10'"
                data-vv-validate-on="input"
                name="phone number"
                key="phone number"
                class="gds-input-field__input"
                type="tel"
                placeholder="Your phone number"
              />
            </label>
            <div v-if="errors.first('phone number')" class="validation-error">Complete phone number required</div>
          </fieldset>
          <div class="outage-double-button-row gds-space-stack-xl gds-flex-container gds-flex-container--space-between">
            <button @click="ReturnToSender('/report-outage', 'outages')" type="button" class="gds-button gds-secondary gds-space-inline-m">Cancel</button>
            <button @click="ReportOutage()" :disabled="pendingSubmit" :class="{ 'gds-loading': pendingSubmit }" type="button" class="gds-button gds-space-inline-m gds-orange">
              <span class="gds-button__text">Report Outage</span>
            </button>
          </div>
          <div class="gds-space-stack-l">
            <div v-if="errorSubmit" class="form-message-box gds-space-stack-m">{{errorSubmit}}</div>
            <div v-if="powerAlreadyOut" class="outage-reporting-error-message-box gds-space-stack-m">
              <p>Unable to report an outage at this location because the power is already turned off.  Please contact GMP at {{ GMPPhoneNumber }} to have power turned back on.</p>
            </div>
          </div>
        </form>
      </div>
      <div v-if="completedSubmit" class="outage-reported-success">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/outage-center/outage-reported.svg" class="outage-reported-success-graphic" />
        <div class="gds-display-2 gds-align--text-center gds-space-stack-l">Outage Reported</div>
        <div
          class="gds-font-size-l gds-space-stack-l"
        >An outage has been succesfully reported for the service location shown below. Our team will assess the outage and provide an estimated restoration time. It may take a few minutes before this outage is added to the outage map.</div>
        <div class="outage-reported-success__address-box-wrap">
          <div class="gds-font-demi gds-space-stack-s">Outage Reported At</div>
          <div class="outage-reported-success__address-box gds-space-stack-xl">
            <div class="gds-flex-container gds-flex-container--left">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/outage-center/icon-house-alert-circle.png" class="town-incident__column--details--home-icon" />
              <div>
                <span v-if="selectedAccount.nickname">
                  <strong>{{selectedAccount.nickname}}</strong>
                  <br />
                </span>
                <span class="gds-linebreaks">{{selectedAccount.address | addressFormat({ separator: "\n" })}}</span>
              </div>
            </div>
          </div>
          <button @click="ReturnToSender('/report-outage', 'outages')" type="button" class="gds-button gds-orange outage-report-success__button">Done</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #main -->
</template>

<script>
import ManualReportingBoxWide from "./ManualReportingBoxWide";
import GMPAPI from "../../services/gmpapi";
import { GMPPhoneNumber } from "../../environment";
import { DumpError, ReturnToSender } from "../../utilities";

export default {
  name: "ReportOutageFlow",
  components: {
    ManualReportingBoxWide,
  },
  data() {
    return {
      personalData: undefined,
      selectedAccountNumber: undefined,
      phoneNumber: undefined,

      pendingSubmit: false,
      errorSubmit: undefined,
      completedSubmit: false,
      powerAlreadyOut: false,

      GMPPhoneNumber,
      ReturnToSender,

      loadState: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    selectedAccount() {
      if (this.personalData && this.personalData.length) {
        const account = this.personalData.find(item => item.accountNumber === this.selectedAccountNumber);
        if (account) {
          return account;
        }
      }
      return {};
    },
    phoneLogin() {
      if (this.$store.state.user.loginType === "LoginPhone") {
        return true;
      } else {
        return false;
      }
    }
  },
  async mounted() {
    document.querySelector(".report-outage-info_login").style.display = "none";
    await this.RefreshData();
  },
  methods: {
    async RefreshData() {
      this.loadState = undefined;
      this.personalData = undefined;
      try {
        this.personalData = await GMPAPI.GetPersonalOutageData();
        if (!this.selectedAccountNumber) {
          this.selectedAccountNumber = this.$store.state.user.currentAccount.accountNumber;
        }
        this.loadState = "complete";
      } catch (err) {
        DumpError("Report outage refresh error", err);
        this.loadState = "error";
      }
    },
    async ReportOutage() {
      this.errorSubmit = undefined;
      this.powerAlreadyOut = false;

      if (await this.$validator.validateAll()) {
        this.pendingSubmit = true;

        if (this.currentAccount.accountNumber.length == 10) {
          try {
            const status = await GMPAPI.GetAccountStatus(this.currentAccount.accountNumber);
            if (status.meterOff || status.sonp) {
              this.powerAlreadyOut = true;
              return;
            }
          } catch (err) {
              DumpError("Report outage read status error", err);
              this.loadState = "error";
          } finally {
              if (this.powerAlreadyOut) {
                this.pendingSubmit = false;
              }
          }
        }

        try {
          await GMPAPI.SubmitOutage(this.selectedAccountNumber, this.phoneNumber);
          this.completedSubmit = true;
        } catch (err) {
          DumpError("Report outage error", err);
          this.errorSubmit = "There was an unexpected error reporting your outage. To report an outage by phone call us at " + GMPPhoneNumber + ".";
        } finally {
          // Either way, turn off the loading indicator
          this.pendingSubmit = false;
        }
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>