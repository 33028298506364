var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "slat-message-fade" } }, [
        _vm.scheduledPayments && _vm.scheduledPayments.length
          ? _c(
              "div",
              {
                staticClass: "gds-space-stack-l scheduled-payments-list",
                class: { "table-loading": _vm.isRefreshing }
              },
              _vm._l(_vm.scheduledPayments, function(payment) {
                return _c(
                  "div",
                  {
                    key: payment.confirmationNumber,
                    staticClass:
                      "form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-s gds-flex-container gds-flex-container--space-between"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-message-box--left gds-body-normal" },
                      [
                        _c("span", [_vm._v("A payment of")]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(payment.totalPaymentAmount)
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(payment.methodSlug))]),
                        _vm._v(" "),
                        payment.status === "SCHEDULED"
                          ? [
                              _vm.isBeforeToday(payment.scheduledPaymentDate)
                                ? _c("span", [
                                    _vm._v(
                                      "was scheduled for " +
                                        _vm._s(
                                          _vm._f("dateFull")(
                                            payment.scheduledPaymentDate
                                          )
                                        ) +
                                        ". It will be processed on the next business day."
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "is scheduled for " +
                                        _vm._s(
                                          _vm._f("dateFull")(
                                            payment.scheduledPaymentDate
                                          )
                                        ) +
                                        "."
                                    )
                                  ])
                            ]
                          : _c("span", [
                              _vm._v(
                                "is pending. Please allow up to one business day for your payment to be reflected in the current balance."
                              )
                            ])
                      ],
                      2
                    ),
                    _vm._v(" "),
                    payment.cancellable
                      ? _c("div", { staticClass: "form-message-box--right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "gds-button gds-text-button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.removingPayment = payment
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "form-message-box--blue__text-button-text gds-button__text gds-font-size-m"
                                },
                                [_vm._v("Cancel Payment")]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("modal-cancel-scheduled", {
        attrs: {
          visible: _vm.removingPayment !== undefined,
          payment: _vm.removingPayment
        },
        on: { complete: _vm.RemoveComplete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }