import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import layout from "./modules/layout";
import user from "./modules/user";
import billpay from "./modules/billpay";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

// BOTH of these persisters are active - some things get persisted to local storage and some to session

// This persister is for storing things in local storage
const vuexLocal = new VuexPersistence({
  key: "gmp-vue",
  storage: window.localStorage,
  reducer: (state) => {
    // What to persist to localstorage
    const localstate = {};
    // If rememberme is true, we will include the user data in this persister
    if (state.user.rememberme) {
      localstate.user = state.user;
    }
    return localstate;
  },
});

// This persister is for storing things in session storage
const vuexSession = new VuexPersistence({
  key: "gmp-vue",
  storage: window.sessionStorage,
  reducer: (state) => {
    // What to persist to sessionstorage
    const sessionstate = {
      // We used to keep outage data here but now we refresh on demand
    };
    // If rememberme is false, we will include the user data in this persister
    if (!state.user.rememberme) {
      sessionstate.user = state.user;
    }
    return sessionstate;
  },
});

export default new Vuex.Store({
  modules: {
    layout,
    user,
    billpay,
  },
  plugins: [
    vuexLocal.plugin,
    vuexSession.plugin,
  ],
  // Note - this may someday cause us to have to do lots of weird stuff in
  // vuex-persist to support strict mode. If so, just turn it off and
  // remember not to alter the state directly.
  strict: debug, // Strict mode in prod would decrease performance
});