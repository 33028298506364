<template>
  <span
    class="usage-detail__select"
    :class="{ 'usage-detail__select--button-appearance': configCopied.buttonAppearance }">
    <label class="select-container">
      <select
        @change="onSelectChange($event)"
        class="usage-detail__select__option">
        <option
          :key="i"
          :value="option.value"
          v-for="(option, i) in configCopied.options"
          :selected="option.selected">{{ option.label }}
        </option>
      </select>
      <span class="custom-caret">&#9662;</span>
    </label>
  </span>
</template>

<script>

export default {
  name: "UsageDetailSelect",
  props: {
    config: Object,
    optionSelected: Function
  },
  data() {
    return {
      configCopied: this.copyObject(this.config),
    };
  },
  mounted() {

    this.setSelectedOption(this.configCopied.options, this.configCopied.selectedValue);
  },
  watch: {
    config(val) {
      this.configCopied = this.copyObject(val);
      this.setSelectedOption(this.configCopied.options, this.configCopied.selectedValue);
    }
  },
  methods: {
    onSelectChange($event) {
       this.optionSelected($event.target.value);
    },
    copyObject(objectToCopy) {
     return JSON.parse(JSON.stringify(objectToCopy));
    },
    getMatchingOption(targetArray, selectedValue) {
      let foundElement = undefined;
      targetArray.forEach(e => {if (e.value === selectedValue) foundElement = e});
      return foundElement;
    },
    setSelectedOption(options, selectedValue) {
      if (selectedValue === undefined) {return; }

      const matchingOption = this.getMatchingOption(options, selectedValue);

      if (!matchingOption) {return; }

      options.forEach(option => option.selected = false);
      matchingOption.selected = true;
    }
  }
};
</script>

<style scoped>
</style>