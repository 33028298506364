<template>
  <div class="report-outage-page">
    <div v-if="loadState === undefined">
      <div class="bill-pay__page-loader">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
      </div>
    </div>
    <flow-error v-if="loadState === 'maintenance'" name="Report Outage" state="maintenance" outagebox="true" closepath="/report-outage" />
    <flow-error v-if="loadState === 'error'" name="Report Outage" state="error" outagebox="true" closepath="/report-outage" />
    <router-view v-if="loadState === 'complete'"></router-view>
    <div
      v-if="showManualReportInfo && !this.routeloading"
      class="report-outage-info_login"
    >*By reporting an outage via text message (SMS) you agree to receive power outage or other account related messages from Green Mountain Power. Reply “HELP” to for more information and “STOP” to opt-out, Message and data rates may apply. Message frequency varies. See our <a href="/apps-skills/text-alerts/notification-terms-and-conditions/" class="gds-link gds-link--bold cursorPointer">Terms of Use</a> and <a href="/legal" class="gds-link gds-link--bold cursorPointer">Privacy Policy.</a></div>
  </div>
</template>

<script>
import { EnvironmentConstants } from "../../environment";
import { DumpError, ReturnToSender } from "../../utilities";
import { GetComponentStatus } from '../../services/statuspage';

export default {
  name: "PageReportOutage",
  data() {
    return {
      EnvironmentConstants,

      loadState: undefined,

      showManualReportInfo: undefined,
    };
  },
  watch: {
  },
  computed: {
    routeloading() {
      return this.$store.state.layout.routeloading;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async created() {
  },
  async mounted() {
    if (this.$route.path === "/login/" || this.$route.path === "/" ) {
      this.showManualReportInfo = true;
    } else {
      this.showManualReportInfo = false;
    }
    // Wire up the close button (outside of vue)
    const closer = document.querySelector("#flow-close");
    if (closer) {
      closer.addEventListener("click", e => {
        ReturnToSender("/report-outage", "/outages");
      });
    }
    this.loadState = undefined;
    // Check for service outages
    const status = await GetComponentStatus("Outage");
    if (status === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    async Logout() {
      try {
        await this.$store.dispatch("LogOut");
        this.$router.replace("/login");
      } catch (err) {
        DumpError("Logout error", err);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>