var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "my-account__modal-box no-scroll-modal" },
          [
            _c(
              "button",
              {
                staticClass: "close bill-pay__close",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.close("documentChecklist")
                  }
                }
              },
              [
                _c("svg", { staticClass: "gds-icon close-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("FocusLock", [
              _c("div", { staticClass: "eicproducts__modal-box--body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-xl gds-font-demi gds-space-stack-m"
                  },
                  [_vm._v("What are you uploading?")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__modal-box--message gds-space-stack-l"
                  },
                  [
                    _vm.selectedFile
                      ? _c("div", [
                          _vm._v(
                            "Please select which document(s) are contained in the file: " +
                              _vm._s(_vm.selectedFile.file.name)
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "gds-space-stack-ml" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "gds-checkbox gds-checkbox-thin gds-space-stack-m"
                    },
                    [
                      _c("input", {
                        attrs: {
                          id: _vm.vehicleRegistration,
                          type: "checkbox",
                          name: ""
                        },
                        domProps: { value: _vm.vehicleRegistration },
                        on: { change: _vm.onSelect }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "gds-checkbox__faux" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "gds-font-size-ml" }, [
                        _c("b", [_vm._v("Vehicle Registration")])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "gds-checkbox gds-checkbox-thin gds-space-stack-m"
                    },
                    [
                      _c("input", {
                        attrs: {
                          id: _vm.proofOfPurchase,
                          type: "checkbox",
                          name: ""
                        },
                        domProps: { value: _vm.proofOfPurchase },
                        on: { change: _vm.onSelect }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "gds-checkbox__faux" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "gds-font-size-ml" }, [
                        _c("b", [_vm._v("Vehicle Proof of Purchase or Lease")])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isRestrictedRebateUser
                    ? _c(
                        "label",
                        {
                          staticClass:
                            "gds-checkbox gds-checkbox-thin gds-space-stack-m"
                        },
                        [
                          _c("input", {
                            attrs: {
                              id: _vm.customerRebateForm,
                              type: "checkbox",
                              name: ""
                            },
                            domProps: { value: _vm.customerRebateForm },
                            on: { change: _vm.onSelect }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "gds-checkbox__faux" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "gds-font-size-ml" }, [
                            _c("b", [_vm._v("Customer Rebate Form")])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isRestrictedRebateUser
                    ? _c(
                        "label",
                        {
                          staticClass:
                            "gds-checkbox gds-checkbox-thin gds-space-stack-m"
                        },
                        [
                          _c("input", {
                            attrs: { id: _vm.w9, type: "checkbox", name: "" },
                            domProps: { value: _vm.w9 },
                            on: { change: _vm.onSelect }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "gds-checkbox__faux" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "gds-font-size-ml" }, [
                            _c("b", [_vm._v("IRS W-9 Form")])
                          ])
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "gds-button gds-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.close("documentChecklist")
                      }
                    }
                  },
                  [_vm._v("Cancel Upload")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    attrs: { type: "button" },
                    on: { click: _vm.onSave }
                  },
                  [_vm._v("Save")]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }