<template>
  <div>
    <UsageDetailControls
      class="usage-detail__seasonal-average-nav-controls"
      :selectedDate="selectedDate"
      :datePickerDisabledDates="disabledDates"
      :selectedDateWidth="`180px`"
      :datePickerDateType="datePickerDateType"
      :showDateBackwardsButton="true"
      :onDateBackwardsClicked="dateBackwards"
      :showDateForwardsButton="true"
      :onDateForwardsClicked="dateForwards"
      :disableDateNavigationButtonBack="navLeftDisabled"
      :disableDateNavigationButtonForward="navRightDisabled"
      :showGoToTodayButton="true"
      :onGoToTodayClicked="goToToday"
      :showJumpToDateButton="false"
      :onSelectedDateChanged="selectDate"
      :disableAllDateNavControls="loading || disableAllDateNavControls" />
    <div class="usage-detail__seasonal-average">
      <div v-if="!loadState || loading" class="table-loading" />
      <div class="usage-page--interior-status-container">
        <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
        <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
        <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
        <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      </div>
      <div v-show="!loading" v-if="loadState === 'complete'" class="usage-detail__seasonal-carousel-container">
        <div class="usage-detail__seasonal-carousel" ref="carousel" @mousedown="startDrag" @touchstart="startDrag">
          <div class="usage-detail__seasonal-carousel-items"
            :style="{ transform: `translateX(-${(selectedSeasonIndex + 1) * itemWidth}%)` }">
            <div v-for="(data, i) in seasonalDailyAverageData" :key="data.date.toString()"
              class="usage-detail__seasonal-carousel-item">
              <SeasonalUsageBubble :season="data.season" :averageUsage="data.averageUsage"
                :scaledAverageUsage="data.scaledAverageUsage" :year="data.year"
                :isCurrentSeason="currentSeasonIndex === i ? true : false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeasonalUsageBubble from "./SeasonalUsageBubble.vue";
import { format, isBefore, subDays, subYears, differenceInDays, isAfter, parseISO, subMonths, addMonths } from "date-fns";
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls.vue";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import { GetComponentStatus } from '../../../../services/statuspage';
import { DumpError } from "../../../../utilities";



export default {
  components: {
    UsageDetailControls,
    SeasonalUsageBubble
  },
  name: "UsageDetailSeasonal",
  mixins: [
    UsageDetailBaseComponentMixin
  ],
  data() {
    return {
      seasonalDailyAverageData: [],
      currentSeasonIndex: undefined,
      selectedSeasonIndex: undefined,
      isDragging: false,
      dragStartX: 0,
      dragOffsetX: 0,
      itemWidth: undefined,
      navRightDisabled: undefined,
      navLeftDisabled: false,
      datePickerDateType: 'month',
      loadState: undefined,
      loading: undefined,
      selectedDate: undefined,
      initialized: false
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    selectedSeasonIndex() {
      if (this.initialized) {
        if (this.selectedSeasonIndex && this.seasonalDailyAverageData && this.seasonalDailyAverageData.length > 0) {
          if (this.selectedSeasonIndex === this.seasonalDailyAverageData.length - 2) {
            this.navRightDisabled = true;
          } else {
            this.navRightDisabled = false;
          }
        }
        setTimeout(() => {
          this.computeSelectedDate();
        }, 500) 
      }
    }
  },
  created() {
    window.addEventListener('resize', this.debounce(this.updateItemWidth, 200));
  },
  async mounted() {
    try {
      // Before we do anything else, check to see if component is operational
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        this.disableAllDateNavControls = true;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      }
      
      this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(this.billingPeriods);
      const startDate = subYears(new Date(), 2);
      const endDate = this.getFirstDayOfMonth(addMonths(new Date(), 1));
      this.seasonalDailyAverageData = await this.setScaledAverageUsage(startDate, endDate);
      if (!this.seasonalDailyAverageData) { return; }
      this.setCurrentSeason();
      this.goToToday();
      if (this.selectedSeasonIndex > 4) {
        this.selectedSeasonIndex -= 3;
      }
      this.updateItemWidth();
      this.initialized = true;

    } catch (err) {
      DumpError("Usage detail seasonal refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateItemWidth);
  },
  methods: {
    async computeSelectedDate() {
      if (this.$refs.carousel) {
        const carouselItems = this.$refs.carousel.querySelectorAll(".usage-detail__seasonal-carousel-item");
        const viewportWidth = window.innerWidth;

        let leftMostIndex = 0;
        let rightMostIndex = Number.MAX_SAFE_INTEGER;

        carouselItems.forEach((bubble, index) => {
          const rect = bubble.getBoundingClientRect();
          const bubbleWidth = rect.width;
          const bubbleLeft = rect.left;
          const bubbleRight = rect.right;
          const visibleWidth = Math.min(bubbleRight, viewportWidth) - Math.max(bubbleLeft, 0);
          const percentageVisible = (visibleWidth / bubbleWidth) * 100;

          if (percentageVisible >= 70) {
            leftMostIndex = Math.max(leftMostIndex, index);
            rightMostIndex = Math.min(rightMostIndex, index);
          }
        });

        if (this.seasonalDailyAverageData && this.seasonalDailyAverageData.length > 0) {
          const leftMostSeasonalUsage = this.seasonalDailyAverageData[leftMostIndex];
          const rightMostSeasonalUsage = this.seasonalDailyAverageData[rightMostIndex];
          const { season: leftSeason, year: leftYear } = leftMostSeasonalUsage;
          const { season: rightSeason, year: rightYear } = rightMostSeasonalUsage;
          const leftString = `${rightSeason} ${rightYear}`;
          const rightString = `${leftSeason} ${leftYear}`;
          this.selectedDate = leftString === rightString ? leftString : `${leftString} - ${rightString}`;
        }
      }
    },
    updateItemWidth() {
      this.$nextTick(() => {
        const viewWidth = window.visualViewport.width;
        if (viewWidth > 1024) {
          this.itemWidth = 17.35;
        } else if (viewWidth > 900) {
          this.itemWidth = 18;
        } else if (viewWidth > 720) {
          this.itemWidth = 25;
        } else if (viewWidth > 520) {
          this.itemWidth = 30;
        } else {
          this.itemWidth = 50;
        }
        this.goToToday();
      });
    },
    async setScaledAverageUsage(start, end) {
      this.loading = true;
      const startDate = this.getFirstDayOfMonth(start);
      try {
        const response = await this.getPeriodUsageData(this.currentAccount.accountNumber, "monthly", startDate, end);
        this.loading = false;
        this.navLeftDisabled = false;
        if (!response) {
          this.loadState = 'empty';
          this.navLeftDisabled = true;
          return;
        }
        this.loadState = "complete";
        let seasonalDailyAverageData = this.transformOneYearToSeasonalDailyAverageData(response);
        const seasonalAverageMinMax = this.getMinMaxAverageUsage(seasonalDailyAverageData);
        seasonalDailyAverageData = seasonalDailyAverageData.map((data) => ({
          ...data,
          scaledAverageUsage: this.logScaleValues(data.averageUsage, seasonalAverageMinMax),
        }));
        return seasonalDailyAverageData.sort((a, b) => a.year - b.year);
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        this.loading = false;
        return;
      }
    },
    getFirstDayOfMonth(aDate) {
      return subDays(aDate, parseInt(format(aDate, 'd')) - 1)
    },
    transformOneYearToSeasonalDailyAverageData(response) {
      const twelveMonthsOfMonthlyUsageData = response.intervals[0].values.map(monthlyUsage => ({
        ...monthlyUsage,
        season: this.getSeason(new Date(monthlyUsage.date).getMonth())
      }));

      const seasonalDailyAverageData = this.setAverageDailyUsageForSeason(twelveMonthsOfMonthlyUsageData);
      this.sortByDate(seasonalDailyAverageData);

      return seasonalDailyAverageData;
    },
    sortByDate(arr) {
      arr.sort((a, b) => new Date(a.date) - new Date(b.date));
      return arr;
    },
    setAverageDailyUsageForSeason(data) {
      const seasonalData = {};
      const today = new Date();
      const currentSeason = this.getSeason(today.getMonth());
      const currentYear = today.getFullYear();

      for (const { date, season, consumedTotal } of data) {
        const newDate = new Date(date);
        const year = newDate.getMonth() >= 11 ? newDate.getFullYear() + 1 : newDate.getFullYear();
        const key = `${year}.${season}`;

        if (!seasonalData[key]) {
          seasonalData[key] = {
            totalConsumed: 0,
            dateStart: date,
            dateEnd: addMonths(date, 3),
            season,
            year,
            seasonLengthInDays: 0,
          };
        }

        seasonalData[key].totalConsumed += consumedTotal;
        if (isBefore(new Date, seasonalData[key].dateEnd)) {
          seasonalData[key].seasonLengthInDays = differenceInDays(new Date(), new Date(seasonalData[key].dateStart));
        } else {
          seasonalData[key].seasonLengthInDays = differenceInDays(seasonalData[key].dateEnd, seasonalData[key].dateStart);
        }
      }

      const result = Object.values(seasonalData).map(({ totalConsumed, dateEnd, seasonLengthInDays, season, year }) => ({
        averageUsage: Math.round(totalConsumed / seasonLengthInDays),
        season,
        date: dateEnd,
        year,
        isCurrent: currentSeason === season && currentYear === year,
      }));

      return result;
    },
    getMinMaxAverageUsage(seasonalDailyAverageData) {
      const filteredData = seasonalDailyAverageData.filter(({ isCurrent }) => !isCurrent);
      const max = Math.max(...filteredData.map(({ averageUsage }) => averageUsage));
      const min = 1;

      return { min, max };
    },
    logScaleValues(value, minMax) {
      const minLog = Math.log10(minMax.min);
      const maxLog = Math.log10(minMax.max);
      const minScale = 1;
      const maxScale = 9;
      const scaleRange = maxScale - minScale;
      const logValue = Math.log10(value);
      const scaledValue = ((logValue - minLog) / (maxLog - minLog)) * scaleRange + minScale;
      return Math.round(scaledValue * 100) / 100;
    },
    async dateBackwards() {
      if (this.selectedSeasonIndex === 0) {
        const startDate = subYears(this.getFirstDayOfMonth(new Date(this.seasonalDailyAverageData[0].date)), 1);
        const endDate = this.getFirstDayOfMonth(new Date(this.seasonalDailyAverageData[0].date));
        const newData = await this.setScaledAverageUsage(startDate, endDate);
        if (!newData) {
          this.loading = false;
          this.loadState = 'empty';
          return;
        }
        const filteredData = newData.slice(0, -1);
        this.seasonalDailyAverageData.push(...filteredData);
        this.selectedSeasonIndex = 1;
        this.seasonalDailyAverageData = this.sortByDate(this.seasonalDailyAverageData);
        const seasonalAverageMinMax = this.getMinMaxAverageUsage(this.seasonalDailyAverageData);
        this.seasonalDailyAverageData = this.seasonalDailyAverageData.map((data) => {
          data.scaledAverageUsage = this.logScaleValues(data.averageUsage, seasonalAverageMinMax);
          return data;
        });
        this.setCurrentSeason();
      }
      this.selectedSeasonIndex = (this.selectedSeasonIndex < 4) ? (this.selectedSeasonIndex - 1) : (this.selectedSeasonIndex - 4);
      const startDate = subYears(this.getFirstDayOfMonth(new Date(this.seasonalDailyAverageData[0].date)), 1);

      if (isBefore(startDate, parseISO(this.billingPeriods.periods[this.billingPeriods.periods.length - 1].startDate))) {
        this.navLeftDisabled = true;
      } else {
          this.navLeftDisabled = false;
      }
      this.computeSelectedDate();
    },
    dateForwards() {
      if (!this.seasonalDailyAverageData) { return; }
      this.selectedSeasonIndex += (this.selectedSeasonIndex + 5 < this.seasonalDailyAverageData.length) ? 4 : 1;
      this.navLeftDisabled = false;
      this.loadState = 'complete';
      this.computeSelectedDate();
    },
    async selectDate($event) {
      if (isBefore($event, parseISO(this.billingPeriods.periods[this.billingPeriods.periods.length - 1].startDate))) {
        this.navLeftDisabled = true;
      } else {
        this.navLeftDisabled = false;
      }
      if (!this.seasonalDailyAverageData) { return; }
      if (isAfter(new Date($event), new Date(this.seasonalDailyAverageData[0].date))) {
        this.selectedSeasonIndex = this.seasonalDailyAverageData.findIndex(
          (data) => data.season === this.getSeason(new Date($event).getMonth()) && data.year === new Date($event).getFullYear()
        );
        if (this.currentSeasonIndex - this.selectedSeasonIndex < 4) {
          if (window.innerWidth < 520) {
            this.selectedSeasonIndex -= 1;
          } else if (window.innerWidth < 720) {
            this.selectedSeasonIndex -= 2;
          } else if (window.innerWidth < 1024) {
            this.selectedSeasonIndex -= 3;
          } else {
            this.selectedSeasonIndex -= 4;
          }
        }
      } else {
        const startDate = this.getFirstDayOfMonth(new Date($event));
        const endDate = this.getFirstDayOfMonth(new Date(this.seasonalDailyAverageData[0].date));
        let newData = await this.setScaledAverageUsage(startDate, endDate);
        if (!newData) {
          this.loading = false;
          this.loadState = 'empty';
          return;
        }
        const filteredData = newData.slice(0, -1);
        let seasonalDailyAverageData = [...this.seasonalDailyAverageData, ...filteredData];
        this.selectedSeasonIndex = 1
        this.seasonalDailyAverageData = this.sortByDate(seasonalDailyAverageData);
        this.setCurrentSeason();
        this.computeSelectedDate();
      }
    },
    setCurrentSeason() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      this.currentSeasonIndex = this.seasonalDailyAverageData.findIndex(
        (data) => data.season === this.getSeason(currentMonth) && data.year === currentYear
      );
    },
    getSeason(month) {
      let season;
      switch (month) {
        case 11:
        case 0:
        case 1:
          season = 'Winter';
          break;
        case 2:
        case 3:
        case 4:
          season = 'Spring';
          break;
        case 5:
        case 6:
        case 7:
          season = 'Summer';
          break;
        case 8:
        case 9:
        case 10:
          season = 'Fall';
          break;
        default:
          season = 'Invalid month';
      }
      return season;
    },
    goToToday() {
      this.selectDate(new Date);
    },
    startDrag(event) {
      const isTouch = event.type === 'touchstart';
      const startX = isTouch ? event.touches[0].clientX : event.clientX;
      this.isDragging = true;
      this.dragStartX = startX;
      this.dragOffsetX = 0;

      window.addEventListener(isTouch ? 'touchmove' : 'mousemove', this.drag);
      window.addEventListener(isTouch ? 'touchend' : 'mouseup', this.stopDrag);
    },
    drag(event) {
      if (!this.isDragging) return;

      const isTouch = event.type === 'touchmove';
      const currentX = isTouch ? event.touches[0].clientX : event.clientX;
      const dragDistance = currentX - this.dragStartX;
      if (Math.abs(dragDistance) > 50 ) {
        this.dragOffsetX = dragDistance;
      }
    },
    stopDrag() {
      if (!this.isDragging) return;
      const newIndex = this.selectedSeasonIndex - Math.sign(this.dragOffsetX);

      if (newIndex >= 0 && newIndex < this.seasonalDailyAverageData.length - 1) {
        this.selectedSeasonIndex = newIndex;
      }

      if (this.selectedSeasonIndex === this.seasonalDailyAverageData.length - 1) {
        this.navRightDisabled = true;
      }

      this.isDragging = false;
      this.dragStartX = 0;
      this.dragOffsetX = 0;

      window.removeEventListener('touchmove', this.drag);
      window.removeEventListener('mousemove', this.drag);
      window.removeEventListener('touchend', this.stopDrag);
      window.removeEventListener('mouseup', this.stopDrag);
    },
    debounce(func, delay) {
      let timeoutId;
      return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    }
  },
};
</script>