<template>
  <div class="my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l">
    <div class="widget-account--single-row gds-flex-container gds-flex-container--space-between gds-space-stack-l">
      <div @click="Dashboard()" class="gds-flex-container gds-flex-container--left widget-account--single-row--left gds-clickable">
        <div v-if="account.photoUrl" :style="{backgroundImage: 'url(' + GetResizedAccountPhoto(account.photoUrl) + ')'}" class="small-round-img gds-space-inline-default"></div>
        <div class="user-profile__account-nickname-address-wrap">
          <div class="user-profile__account-nickname gds-font-demi">{{account | accountName}}</div>
          <div class="user-profile__account-address gds-font-size-m gds-linebreaks">{{account.address | addressFormat({ separator: "\n", skipFirst: false })}}</div>
          <div class="gds-font-size-m gds-linebreaks">Acct. #{{account.accountNumber}}</div>
        </div>
      </div>
      <div class="gds-flex-container widget-account--single-row--right">
        <div class="user-profile__account-status">
          <div class="gds-flex-container gds-flex-container--left">
            <div v-if="account.isPrimary" class="gds-flex-container gds-flex-container--bottom">
              <div class="my-account__green-check-cirlce">
                <svg class="my-account__white-check-icon gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-white-check-on-green" />
                </svg>
              </div>
              <span class="user-profile__account-status-label gds-font-demi gds-font-size-m gds-space-inline-l">Primary Account</span>
            </div>

            <button v-if="!account.isPrimary" @click="SetPrimary()" type="button" class="gds-button gds-text-button gds-space-inline-l">
              <span class="gds-button__text gds-button__text-m">Set as Primary Account</span>
            </button>
          </div>
        </div>
        <div class="user-profile__account-edit-links">
          <button @click="Edit()" type="button" class="gds-button gds-text-button">
            <span class="gds-button__text gds-button__text-m">Edit Settings / Remove</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetResizedAccountPhoto } from "../../utilities";

export default {
  name: "WidgetAccountRow",
  props: ["account"],
  data() {
    return {
      GetResizedAccountPhoto,
    };
  },
  components: {
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    Dashboard() {
      this.$store.commit("setCurrentAccount", this.account);
      window.location.href = "/account";
    },
    Edit() {
      this.$store.commit("setCurrentAccount", this.account);
      window.location.href = "/account/settings";
    },
    async SetPrimary() {
      this.$emit("setprimary", this.account);
    },
  },
  watch: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>