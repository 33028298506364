var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "ev-charger-bar-graph" } }, [
    _c("div", { ref: "usageGraphRef" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }