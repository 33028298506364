var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "settings-editor my-account__full-width-section my-account__section-with-bottom-border"
    },
    [
      _vm.loadState !== "complete"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m"
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass: "settings-editor__title gds-space-stack-m"
                      },
                      [_vm._v("Mailing Address")]
                    ),
                    _vm._v(" "),
                    _vm.loadState === undefined
                      ? _c("div", { staticClass: "editor-loading" })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "fake-edit-link" }, [_vm._v("Edit")])
              ]
            ),
            _vm._v(" "),
            _vm.loadState === "error"
              ? _c("div", { staticClass: "editor-error" }, [
                  _vm._v("Unable to load this section. Please try again later.")
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "gds-flex-container gds-flex-container--space-between gds-flex-container--top"
              },
              [
                _c(
                  "h2",
                  { staticClass: "settings-editor__title gds-space-stack-m" },
                  [_vm._v("Mailing Address")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "edit-close-button-combo" }, [
                  !_vm.editing && _vm.mailingAddress
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-text-button gds-space-inline-l",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.editing = true
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-account__notifications--text-button gds-button__text"
                            },
                            [_vm._v("Edit")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editing && !_vm.pendingVerify && !_vm.pendingUpdate
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "gds-button gds-text-button gds-space-inline-l",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "my-account__notifications--text-button gds-button__text"
                            },
                            [_vm._v("Close")]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            ),
            _vm._v(" "),
            !_vm.editing && _vm.mailingAddress
              ? _c(
                  "div",
                  {
                    staticClass:
                      "my-account__static-address-box my-account__sub-section-bottom"
                  },
                  [
                    _c("div", { staticClass: "gds-linebreaks" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("addressFormat")(_vm.mailingAddress, {
                            mailing: true
                          })
                        )
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editing
              ? _c(
                  "div",
                  [
                    _c("inline-mailing-address", {
                      ref: "inlineAddress",
                      attrs: { address: _vm.mailingAddress },
                      on: { save: _vm.InlineSaved, touched: _vm.FormTouch }
                    }),
                    _vm._v(" "),
                    _vm.errorVerify
                      ? _c(
                          "div",
                          { staticClass: "form-message-box gds-space-stack-l" },
                          [_vm._v(_vm._s(_vm.errorVerify))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.errorUpdate
                      ? _c(
                          "div",
                          { staticClass: "form-message-box gds-space-stack-l" },
                          [_vm._v(_vm._s(_vm.errorUpdate))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "gds-button gds-round gds-space-stack-l",
                        class: {
                          "gds-loading": _vm.pendingVerify || _vm.pendingUpdate
                        },
                        attrs: {
                          disabled:
                            _vm.pendingVerify ||
                            _vm.pendingUpdate ||
                            !_vm.formTouched,
                          type: "button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.Verify()
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "gds-button__text" }, [
                          _vm._v("Save")
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }