<template>
  <span>
    <button :disabled="disabled" @click="goForward()" class="date-navigation__button gds-button gds-button-outline gds-compact">
      <svg class="gds-icon black shift-right">
        <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
      </svg>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButtonForward",
  props: {
    goForward: Function,
    disabled: Boolean
  }
};
</script>

<style scoped>
</style>