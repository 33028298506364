<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail">
      <div class="usage-detail-modal__header">
        <span class="usage-detail-modal__header__title">{{modalTitle}}</span>
        <button @click="closeModal()" type="button" class="close bill-pay__close gds-button gds-button-circle gds-secondary">
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
      </div>
      <FocusLock>
        <div class="usage-detail-modal__body">
          <div>
            <fieldset
            class="gds-fieldset">
              <div class="usage-detail-checkboxes-modal__col-2">
                <span
                :key="i"
                v-for="(option, i) in config.options"
                class="usage-detail-checkboxes-modal__checkbox-container">
                <label class="gds-checkbox gds-checkbox-thin">
                  <input v-model="option.checked" @change="handleCheckboxChange(option)" type="checkbox"/>
                  <span v-if="!option.checked" class="gds-checkbox__faux"></span>
                  <span v-if="option.checked" v-bind:style="{ '--variable-usage-select-config-checkboxes-checked': option.color || '#00704a'}"  class="gds-checkbox__faux"></span>
                  <span class="gds-checkbox__label gds-display-00 gds-text-grey">{{option.label}}</span>
                  </label>
                </span>
              </div>
            </fieldset>
          </div>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsageDetailCheckboxesModal",
  methods: {
    handleCheckboxChange(option) {
      this.checkboxChanged(option);
    }
  },
  props: {
    config: Object,
    modalTitle: String,
    closeModal: Function,
    checkboxChanged: Function,
  }
};
</script>

<style scoped>
</style>