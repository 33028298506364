import SubpageOutageOverview from "./SubpageOutageOverview.vue";
import SubpageTownView from "./SubpageTownView.vue";
import SubpageIncidentView from "./SubpageIncidentView.vue";
import SubpagePlannedOutageList from "./SubpagePlannedOutageList.vue";
import SubpagePlannedOutage from "./SubpagePlannedOutage.vue";
import SubpageMessageDetail from "./SubpageMessageDetail.vue";

export const OutageCenterRouterOptions = {
  mode: "history",
  base: "/outages",
  routes: [
    { path: "/", name: "Overview", component: SubpageOutageOverview },
    { path: "/planned-outages", name: "Planned Outage List", component: SubpagePlannedOutageList },
    { path: "/planned-outages/:outageid", name: "Planned Outage", component: SubpagePlannedOutage },
    { path: "/town/:townname", name: "Town", component: SubpageTownView },
    { path: "/incident/:incidentid", name: "Incident", component: SubpageIncidentView },
    { path: "/message/:messageid", name: "Message", component: SubpageMessageDetail },
    { path: "*", redirect: "/" },
  ],
};

export function OutageCenterRouterInit(router) {
  // No external init needed
}