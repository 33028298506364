var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "button",
      {
        staticClass:
          "date-navigation__button gds-button gds-button-outline gds-compact",
        attrs: { disabled: _vm.disabled },
        on: {
          click: function($event) {
            return _vm.goForward()
          }
        }
      },
      [
        _c("svg", { staticClass: "gds-icon black shift-right" }, [
          _c("use", {
            attrs: {
              "xlink:href":
                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }