var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      [
        _c("UsageDetailControls", {
          staticClass: "usage-detail__compare-nav-controls",
          attrs: {
            disableAllDateNavControls:
              !_vm.loadState || _vm.disableAllDateNavControls,
            datePickerDisabledDates: _vm.disabledDates,
            disableDateNavigationButtonForward:
              _vm.disableDateNavigationForwardButton,
            disableDateNavigationButtonBack:
              _vm.disableDateNavigationBackwardButton,
            comparisonDetailsWidth: _vm.comparisonDetailsWidth,
            datePickerDateType: _vm.datePickerDateType,
            showJumpToDateButton: true,
            jumpToDateButtonLabel: "Base Date",
            showComparisonDateButton: true,
            showComparisonDetails: true,
            comparisonDetailsType: _vm.comparisonDetailsType,
            comparisonDetailsDate: _vm.comparisonDetailsDate,
            datePickerDisabledDatesSecondary:
              _vm.datePickerDisabledDatesSecondary,
            onSelectedDateChanged: _vm.onSelectedBaseDateChanged,
            onSelectedDateChangedSecondary:
              _vm.onSelectedCompareDateChangedSecondary,
            onDateBackwardsClicked: _vm.onDateBackwardsClicked,
            onDateForwardsClicked: _vm.onDateForwardsClicked,
            onOptionSelectedUsageType: _vm.onOptionSelectedUsageType,
            onOptionSelectedMonthlyDailyHourly:
              _vm.onOptionSelectedMonthlyDailyHourly,
            showViewOptionsButton: false,
            showDateBackwardsButton: true,
            showDateForwardsButton: true,
            selectConfigUsageType: _vm.selectConfigUsageType,
            selectConfigMonthlyDailyHourly: _vm.selectConfigMonthlyDailyHourly
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      !_vm.loadState ? _c("div", { staticClass: "table-loading" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "usage-page--interior-status-container" },
        [
          _vm.loadState === "error"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "error" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "maintenance"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "maintenance" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "unavailable"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "unavailable",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "empty"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "nodata",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("section", { staticClass: "usage-detail__usage-compare" }, [
            _c(
              "div",
              { staticClass: "usage-detail__compare-legend-container" },
              [
                _vm.baseDate
                  ? _c("UsageDetailLegendItem", {
                      staticClass: "gds-space-inline-ml",
                      attrs: {
                        color: _vm.selectedUsageTypeColor,
                        text: _vm.baseLegendDate,
                        title: "Base Date"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.compareDate
                  ? _c("UsageDetailLegendItem", {
                      attrs: {
                        text: _vm.compareLegendDate,
                        title: "Comparison Date"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "my-account__usage-graph-wrapper" }, [
              _c("div", { ref: "usageCompareChartRef" })
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }