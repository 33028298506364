<template>
  <div class="ev-charger-page-container">
    <ModalEvChargingSettings
      :closeModal="hideSettingsModal"
      :onApiSuccess="GetEvEnergy"
      :evSettings="evSettings"
      v-if="settingsModalVisible && currentAccount"
      :accountNumber="currentAccount.accountNumber" />
    <ModalSimpleInfo
      v-if="simpleInfoModalVisible"
      :closeModal="hideSimpleInfoModal"
      :modalTitle="modalSimpleInfoTitle"
      :modalContent="modalSimpleInfoContent" />
    <usage-detail-controls
      class="usage-detail__usage-graph-nav-controls usage-detail__component-container"
      :disableAllDateNavControls="loadState ==='loading'"
      :disableDateNavigationButtonForward="currentMonthSelected"
      :selectedDate="intervalString"
      :datePickerDateType="datePickerDateType"
      :datePickerDefaultDate="datePickerDefaultDate"
      :showGoToTodayButton="true"
      :showViewOptionsButton="true"
      :showJumpToDateButton="true"
      :showDateForwardsButton="true"
      :showDateBackwardsButton="true"
      :viewOptionsLabel="'Settings'"
      :onGoToTodayClicked="onGoToTodayClicked"
      :onSelectedDateChanged="onSelectedDateChanged"
      :onDateForwardsClicked="onDateForwardsClicked"
      :onDateBackwardsClicked="onDateBackwardsClicked"
      :onViewOptionsClicked="onViewOptionsClicked"/>
    <div v-if="loadState ==='loading'" class="table-loading" />
    <div class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'maintenance'" name="EV Charger information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Charger information" state="unavailable"
      img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      <flow-error v-if="loadState === 'empty'" name="Charger information" state="nodata"
      img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <div v-if="loadState ==='complete'">
      <div class="ev-charger-details-header">
        <h3 class="ev-charger-details-section-title main">Home Charging Summary</h3>
        <div
        v-if="evSettings"
        class="vehicle-info">
          <span class="ev-settings-label">Vehicle Information</span>{{evSettings.selectedMakeModel}}, {{evSettings.mpgEquivalent}}MPGe
        </div>
      </div>
      <div class="ev-charger-details-summary-data">
        <div class="container">
          <div class="box">
            <div class="text-container">
              <div class="large-text total-charged">
                {{totalConsumed}}
                <span class="unit">kWh</span>
              </div>
              <div class="small-text">Total Charged</div>
            </div>
          </div>
          <div class="box">
            <div class="text-container">
              <div class="large-text">${{totalCost}}</div>
              <div class="small-text">Cost to Charge</div>
            </div>
          </div>
          <div class="box fuel-savings">
            <div class="info-icon" @click="showSimpleInfoModal()">
              <span class="info-symbol">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 5 14" width="4" height="13"><title>More Info</title>
                <path fill="currentColor" fill-rule="evenodd" d="M4 1.3a1.2 1.2 0 1 1-2.5 0 1.2 1.2 0 0 1 2.5 0Zm-.1 10.2h.7c.2 0 .4.2.4.5v.8c0 .3-.2.5-.4.5H.9a.4.4 0 0 1-.4-.5V12c0-.3.2-.5.4-.5h.7c.2 0 .3 0 .3-.2V5.9c0-.1-.1-.2-.3-.2H1a.4.4 0 0 1-.4-.4v-1c0-.2.2-.4.4-.4h1c.9 0 1.6.7 1.6 1.6v5.8c0 .1.1.2.3.2Z" clip-rule="evenodd"/>
                </svg>
              </span>
            </div>
              <div class="text-container">
                <div class="large-text">${{totalSavings}}</div>
                <div class="small-text">Estimated Fuel Savings</div>
              </div>
          </div>
        </div>
      </div>
      
      <div v-if="!isZeroUsageForPeriod">
        <div v-if="topChartSeries">
          <div class="title-and-icons-wrapper">
              <div class="title-and-icons">
                <h3 class="ev-charger-details-section-title">Charging Per Day</h3>
                <div class="ev-charging-tab-container">
                <div
                class="ev-tab"
                @click="setDatapoint('cost')"
                :class="{ 'selected': selectedDataPoint === 'cost' }">
                <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/icon-cost.svg" alt="Cost Icon">
                <span>Cost</span>
                </div>
                <div
                class="ev-tab"
                @click="setDatapoint('duration')"
                :class="{ 'selected': selectedDataPoint === 'duration' }">
                <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/icon-time.svg" alt="Time Icon">
                <span>Time</span>
                </div>
                <div
                class="ev-tab"
                @click="setDatapoint('consumed')"
                :class="{ 'selected': selectedDataPoint === 'consumed' }">
                <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/icon-energy.svg" alt="Energy Icon">
                <span>kWh</span>
                </div>
                </div>
            </div>
          </div>
        </div>
        <div class="highcharts-container ev-bar-chart">
          <UsageDetailEVChargerBarChart v-if="topChartSeries"
          :selectedDataPoint="selectedDataPoint"
          :chartSeries="topChartSeries"
          :chartDates="topChartDates"
          :chartCategories="topChartCategories"/>
        </div>
      </div>
      <div v-if="!isZeroUsageForPeriod && savingsSeries && savingsSeries.length">
        <div class="title-and-icons-wrapper">
          <h3 class="ev-charger-details-section-title savings">Estimated Cost Savings Per Day <span class="info-icon" @click="showSimpleInfoModal()">
              <span class="info-symbol">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 5 14" width="4" height="13"><title>More Info</title>
                <path fill="currentColor" fill-rule="evenodd" d="M4 1.3a1.2 1.2 0 1 1-2.5 0 1.2 1.2 0 0 1 2.5 0Zm-.1 10.2h.7c.2 0 .4.2.4.5v.8c0 .3-.2.5-.4.5H.9a.4.4 0 0 1-.4-.5V12c0-.3.2-.5.4-.5h.7c.2 0 .3 0 .3-.2V5.9c0-.1-.1-.2-.3-.2H1a.4.4 0 0 1-.4-.4v-1c0-.2.2-.4.4-.4h1c.9 0 1.6.7 1.6 1.6v5.8c0 .1.1.2.3.2Z" clip-rule="evenodd"/>
                </svg>
              </span>
            </span></h3>
          </div>
          <div class="highcharts-container">
            <UsageDetailEVChargerLineGraph
            :chartDates="savingsDates"
            :savingsSeries="savingsSeries"
            :savingsCategories="savingsCategories"/>
          </div>
      </div>
    </div>
    <div v-if="(loadState === 'complete' && isZeroUsageForPeriod)|| loadState === 'error'" class="no-usage-message">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/illustration-ev-charging.svg" />
        <div class="message-content">
          <h2 class="title">Looks like no EV charging sessions this month.</h2>
          <p class="subtitle">If you did charge during this period, your charger might have been offline, or there is a temporary issue fetching your data.</p>
        </div>
      </div>
  </div>
</template>

<script>
import GMPAPI from "../../../../services/gmpapi";
import { DumpError } from "../../../../utilities";
import { isFeatureEnabled } from "../../../../services/featureflags"
import ModalSimpleInfo from "../../../generic/ModalSimpleInfo.vue";
import ModalEvChargingSettings from "./ModalEvChargingSettings.vue";
import UsageDetailEVChargerBarChart from "./UsageDetailEVChargerBarChart.vue";
import UsageDetailEVChargerLineGraph from "./UsageDetailEVChargerLineGraph.vue";
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls.vue";
import { format,  parseISO,  startOfMonth, endOfMonth,
  subMonths, addMonths, setHours, setMinutes, setSeconds, isSameMonth } from 'date-fns';

const X_AXIS_MONTH_DAY = 'M/d';
const LOAD_STATE_ERROR = 'error';
const LOAD_STATE_LOADING = 'loading';
const LOAD_STATE_COMPLETE = 'complete';
const TOOLTIP_DATE_FORMAT = "EEE. MMM. dd, yyyy";
const API_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";

export default {
  name: "UsageDetailEVCharging",
  components: {
    ModalSimpleInfo,
    UsageDetailControls,
    ModalEvChargingSettings,
    UsageDetailEVChargerBarChart,
    UsageDetailEVChargerLineGraph
  },
  data() {
    return {
      loadState: false,
      settingsModalVisible: false,
      simpleInfoModalVisible: false,
      totalCost: 0,
      totalSavings: 0,
      totalConsumed: 0,
      savingsDates: [],
      savingsSeries: [],
      topChartDates: [],
      topChartSeries: undefined,
      savingsCategories: [],
      topChartCategories: [],
      evSettings: undefined,
      energyData: undefined,
      selectedDate: undefined,
      FF_usageEvCharging: undefined,
      selectedDataPoint: 'cost',
      datePickerDateType: 'month',
      datePickerDefaultDate: new Date(),
      selectedDateRange: {start: '', end: '' },
      modalSimpleInfoContent: ``,
      modalSimpleInfoTitle: 'How we calculate savings'
    };
  },
  async mounted() {
    this.FF_usageEvCharging = await isFeatureEnabled('FF_UsageEVCharging', false);

    if (!this.FF_usageEvCharging) {
      this.loadState = 'maintenance';
      return;
    }

    this.initSelectedDate();
    this.initSelectedDateRange();

    if (this.currentAccount) {
      this.GetEvEnergy();
    }
  },
  watch: {
    currentAccount() {
      if (this.currentAccount.evCharger) {
        this.GetEvEnergy();
      } else {
        this.$router.push({ path: 'graph' });
      }
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    intervalString() {
      if (this.selectedDate) {
        return format(parseISO(this.selectedDate), 'MMM. yyyy');
      }
    },
    currentMonthSelected() {
      const currentDate = new Date();
      const selectedDateParsed = parseISO(this.selectedDate);
      return !this.selectedDate ? false : isSameMonth(selectedDateParsed, currentDate);
    },
    isZeroUsageForPeriod(){
      return this.totalConsumed === 0;
    }
  },
  methods: {
    initSelectedDate() {
      this.selectedDate = this.selectedDate = format(new Date(), API_DATE_FORMAT);
    },
    secondsToHours(valueDataPoint) {
      return (valueDataPoint / 60) / 60;
    },
    buildEvTotalsData(energyData) {
      const intervalZero = energyData.intervals[0];

      this.totalCost = Math.round(intervalZero.totalCost);
      this.totalSavings = Math.round(intervalZero.totalSavings);
      this.totalConsumed = Math.round(intervalZero.totalConsumption);
    },
    onViewOptionsClicked() {
      this.settingsModalVisible = true;
    },
    hideSettingsModal() {
      this.settingsModalVisible = false;
    },
    showSimpleInfoModal() {
      this.simpleInfoModalVisible = true;
    },
    hideSimpleInfoModal() {
      this.simpleInfoModalVisible = false;
    },
    onSelectedDateChanged(newDate) {
      const newSelectedDate = new Date(newDate);
      this.selectedDate = format(newSelectedDate, API_DATE_FORMAT);
      this.updateSelectedDateRange(newSelectedDate);
      this.GetEvEnergy();
    },
    onGoToTodayClicked() {
      const today = new Date();
      const firstDayOfCurrentMonth = startOfMonth(today);
      this.selectedDate = format(firstDayOfCurrentMonth, API_DATE_FORMAT);
      this.updateSelectedDateRange(today);
      this.GetEvEnergy();
    },
    onDateBackwardsClicked() {
      const currentDate = new Date(this.selectedDate);
      const previousMonth = subMonths(currentDate, 1);
      const firstDayOfPreviousMonth = startOfMonth(previousMonth);
      this.selectedDate = format(firstDayOfPreviousMonth, API_DATE_FORMAT);
      this.updateSelectedDateRange(previousMonth);
      this.GetEvEnergy();
    },
    onDateForwardsClicked() {
      const currentDate = new Date(this.selectedDate);
      const nextMonth = addMonths(currentDate, 1);
      const firstDayOfNextMonth = startOfMonth(nextMonth);
      this.selectedDate = format(firstDayOfNextMonth, API_DATE_FORMAT);
      this.updateSelectedDateRange(nextMonth);
      this.GetEvEnergy();
    },
    updateSelectedDateRange(date) {
      const firstDayOfMonth = startOfMonth(date);
      const lastDayOfMonth = endOfMonth(date);
      this.selectedDateRange.start = format(firstDayOfMonth, API_DATE_FORMAT);
      this.selectedDateRange.end = format(lastDayOfMonth, API_DATE_FORMAT);
    },
    resetChartData() {
      this.totalSavings = 0;
      this.totalConsumed = 0;
      this.totalCost = 0;

      this.savingsDates = [];
      this.topChartDates = [];
      this.savingsSeries = [];
      this.topChartSeries = undefined;
      this.savingsCategories = [];
      this.topChartCategories = [];
    },
    setDatapoint(datapoint) {
      this.savingsDates = undefined;
      this.topChartDates = undefined;
      this.topChartSeries = undefined;
      this.topChartCategories = undefined;
      this.selectedDataPoint = datapoint;

      this.savingsDates = [];
      this.topChartDates = [];
      this.topChartCategories = [];

      setTimeout(() => this.parseIntervalValues(this.energyData), 50);
    },
    parseIntervalValues(energyData) {
      this.resetChartData();

      this.topChartSeries = {
        onPeak: [],
        offPeak: []
      };

      energyData.intervals[0].values.forEach(value => this.parseIntervalValue(value));
      this.buildEvTotalsData(energyData);
    },
    parseIntervalValue(value) {
      this.topChartDates.push(`${format(parseISO(value.date), TOOLTIP_DATE_FORMAT)}`);
      this.topChartCategories.push(`${format(parseISO(value.date), X_AXIS_MONTH_DAY)}`);

      this.savingsSeries.push(value.savings);
      this.savingsDates.push(`${format(parseISO(value.date), TOOLTIP_DATE_FORMAT)}`);
      this.savingsCategories.push(`${format(parseISO(value.date), X_AXIS_MONTH_DAY)}`);

      switch (this.selectedDataPoint) {
        case 'cost':
          this.topChartSeries.onPeak.push(value.onPeakCost);
          this.topChartSeries.offPeak.push(value.offPeakCost);
        break;
        case 'duration':
          this.topChartSeries.onPeak.push(this.secondsToHours(value.onPeakDuration));
          this.topChartSeries.offPeak.push(this.secondsToHours(value.offPeakDuration));
        break;
        case 'consumed':
          this.topChartSeries.onPeak.push(value.onPeakConsumed);
          this.topChartSeries.offPeak.push(value.offPeakConsumed);
        break;
      }
    },
    initSelectedDateRange() {
      const currentDate = new Date();

      const endOfCurrentMonth = endOfMonth(currentDate);
      const end = format(setHours(setMinutes(setSeconds(endOfCurrentMonth, 59), 59), 23), API_DATE_FORMAT);

      const startOfCurrentMonth = startOfMonth(currentDate);
      const start = format(setHours(setMinutes(setSeconds(startOfCurrentMonth, 0), 0), 0), API_DATE_FORMAT);

      this.selectedDateRange.end = end;
      this.selectedDateRange.start = start;
    },
    async GetEvEnergy() {
      this.resetChartData();
      this.evSettings = undefined;
      const range = this.selectedDateRange;
      this.loadState = LOAD_STATE_LOADING;

      try {
        const energyData = await GMPAPI.GetEvEnergy(this.currentAccount.accountNumber, range.start, range.end);
        this.loadState = LOAD_STATE_COMPLETE;

        if (energyData && energyData.intervals && energyData.intervals.length) {
          this.energyData = energyData;
          this.getEvSettings();
          this.parseIntervalValues(this.energyData);
        }
      } catch (err) {
        this.loadState = LOAD_STATE_ERROR;
        DumpError('Error updating ev settings', err);
      }
    },
    async getEvSettings() {
      try {
        this.evSettings = await GMPAPI.getEvSettings(this.currentAccount.accountNumber);

        if (!this.evSettings.selectedMakeModel) {
          this.evSettings.selectedMakeModel = this.evSettings.makeModels[0].name;
        }

        this.buildModalSimpleInfoContent(this.energyData, this.evSettings);
      } catch (err) {
        this.loadState = LOAD_STATE_ERROR;
        DumpError('Error getting ev settings', err);
      }
    },
    buildModalSimpleInfoContent(energyData, evSettings) {
      let html = '';
      this.modalSimpleInfoContent = '';

      let rates = energyData.rates;
      const isNtou = !!rates.ntou;
      const isTou = !!rates.tou;
      const isTouAndNtou = isNtou && isTou;
      const ntouShouldBeUsed = isNtou && !isTouAndNtou;
      const touShouldBeUsed = !ntouShouldBeUsed;

      if (!rates) {
        return html;
      }

      rates = ntouShouldBeUsed ? rates.ntou : rates = rates.tou;

      let sentenceChargingCosts = '';
      let sentenceMpgAndGasCost = '';

      sentenceMpgAndGasCost = `Savings are calculated by comparing the cost of charging your EV with the cost of fueling a gasoline car that gets ${evSettings.mpgGas} miles per gallon (MPG), with gas priced at $${evSettings.gasPricePerGallon} per gallon. `;

      if (ntouShouldBeUsed) {
        sentenceChargingCosts = `Charging costs are based on an EV efficiency of ${evSettings.mpgEquivalent} miles per gallon equivalent (MPGe) and electricity rates of $${rates.offPeak} per kilowatt-hour.`
      } else if (touShouldBeUsed){
        sentenceChargingCosts = `Charging costs are based on an EV efficiency of ${evSettings.mpgEquivalent} miles per gallon equivalent (MPGe) and electricity rates of $${rates.onPeak} per kilowatt-hour (Peak) and $${rates.offPeak} per kilowatt-hour (Off Peak).`
      }

      html +=
      `<div class="how-we-calculate-savings-content">
        <div class="how-we-calculate-savings-paragraph">`;
          html += `${sentenceMpgAndGasCost}&nbsp;${sentenceChargingCosts}`;
          html +=
        '</div>';
        html +=
        `<div class="how-we-calculate-savings-paragraph">
            You can adjust these values by clicking the "Settings" button on this page.
        </div>`;
        html +=
      '</div>';

      this.modalSimpleInfoContent = html;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>