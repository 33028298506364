var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "resizableElement",
      staticClass: "usage-dashboard__widget-net-metering",
      class: {
        "is-dashboard-view": _vm.isDashboardView,
        "wide-layout": _vm.useWideLayout
      }
    },
    [
      _vm.isDashboardView
        ? _c("div", { staticClass: "header" }, [
            _c("h2", [_vm._v("Performance this Billing Period")]),
            _vm._v(" "),
            _vm.showDetailArrow
              ? _c(
                  "a",
                  {
                    staticClass:
                      "usage-dashboard__circle-arrow-right gds-button gds-button-circle gds-button-outline",
                    attrs: { href: "/account/usage-new/detail/performance" }
                  },
                  [
                    _c("svg", { staticClass: "gds-icon" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"
                        }
                      })
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? [
            _c("WidgetNetMeteringDetail", {
              attrs: {
                billingSummary: _vm.billingSummary,
                isDashboardView: _vm.isDashboardView,
                showDetailArrow: _vm.showDetailArrow,
                summaryGeneration: _vm.summaryGeneration,
                summaryTextGeneration: _vm.summaryTextGeneration,
                summaryIconTotalGeneration: _vm.summaryIconTotalGeneration
              },
              on: { "change-options-event": _vm.handleOptionChangeEvent }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.loadState
        ? _c("div", {
            staticClass:
              "usage-dashboard__widget-current-usage my-account__usage-loading"
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "unavailable"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("Usage information is not available for this account.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v("Unable to load, please try again later.")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }