var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    ref: "mapdiv",
    staticClass: "outage-map",
    class: {
      "homepage-map": _vm.kind === "homepage",
      "town-map": _vm.kind === "town",
      "incident-map": _vm.kind === "incident",
      "planned-map": _vm.kind === "planned"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }