<template>
  <div class="subpage-dashboards subpage-saved-payments--dashboard subpage-dashboards--left-spacer">
    <section v-if="!editingWallet && !creatingWallet" class="my-account__full-width-section gds-space-stack-xl">
      <h2 class="gds-display-1 gds-space-stack-l">Saved Payment Methods</h2>
      <button v-if="currentAccount && isAdmin" @click="LaunchLegacyPortal()" type="button" class="gds-button gds-text-button gds-space-stack-l">
        <span id="bill-pay-recurring-fauxbutton" class="gds-button__text">Legacy speedpay portal</span>
      </button>
      <div v-if="loadState === undefined" class="table-loading" />
      <div v-if="loadState === 'error'" class="table-error">Unable to load your saved payments. Please try again later.</div>
      <div v-if="loadState === 'complete'">
        <div class="gds-space-stack-l">
          <div v-if="wallets && wallets.length === 0">No saved payment methods found</div>
          <div v-for="wallet of wallets" :key="wallet.walletId" class="my-account__header-button-pairing gds-flex-container gds-flex-container--left gds-space-stack-m">
            <div class="my-account__saved-pay-icon-wrap gds-flex-container gds-space-inline-m">
              <svg class="my-account__saved-pay-icon gds-icon">
                <use
                  v-if="wallet.paymentType === 'CC' || wallet.paymentType === 'ATM'"
                  xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card"
                />
                <use v-if="wallet.paymentType === 'ACH'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-bank" />
              </svg>
            </div>
            <div class="my-account__saved-pay-details">
              <div class="gds-font-size-l gds-font-book">{{wallet.accountNickName}}</div>
              <div class="my-account__saved-pay-details--account gds-font-size-m gds-font-book">{{wallet | walletSlug(true)}}</div>
              <div v-if="wallet.recurringSchedule" class="gds-badge info-item space-top">Autopay</div>
              <div v-if="wallet.isDebitAccountUnauthorized && !wallet.isExpired" class="gds-badge danger-item space-top">Needs Attention</div>
              <div v-if="wallet.isExpired" class="gds-badge danger-item space-top">Expired</div>
            </div>
            <button @click="EditWallet(wallet)" type="button" class="gds-button gds-secondary gds-round">Edit</button>
          </div>
        </div>
        <button @click="creatingWallet = true" type="button" class="gds-button gds-round gds-space-stack-l">Add New Payment Method</button>
        <div v-if="returnTo === 'accountsettings'">
          <router-link to="/settings#autopay" class="gds-link gds-link--bold">Return to Account Settings</router-link>
        </div>
        <div v-if="returnTo === 'billingpreferences'">
          <router-link to="/preferences#autopay" class="gds-link gds-link--bold">Return to Billing Preferences</router-link>
        </div>
      </div>
    </section>
    <editor-wallet-card v-if="editingWallet && (editingWallet.paymentType === 'CC' || editingWallet.paymentType === 'ATM')" :wallet="editingWallet" @complete="ReturnFromEditor" />
    <editor-wallet-bank v-if="editingWallet && editingWallet.paymentType === 'ACH'" :wallet="editingWallet" @complete="ReturnFromEditor" />
    <editor-wallet-new v-if="creatingWallet" @complete="ReturnFromEditor" />
  </div>
</template>

<script>
import GMPAPI from "../../../services/gmpapi";
import { EnvironmentConstants } from "../../../environment";

import EditorWalletCard from "../editors/EditorWalletCard";
import EditorWalletBank from "../editors/EditorWalletBank";
import EditorWalletNew from "../editors/EditorWalletNew";
import { DumpError } from '../../../utilities';

export default {
  name: "SubpageSavedPayments",
  components: {
    EditorWalletCard,
    EditorWalletBank,
    EditorWalletNew,
  },
  data() {
    return {
      wallets: undefined,
      editingWallet: undefined,
      creatingWallet: false,

      returnTo: undefined,

      loadState: undefined,

      EnvironmentConstants,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    recurringAuth() {
      if (this.currentAccount && this.currentAccount.mailingAddress && this.currentAccount.mailingAddress.zip) {
        return this.currentAccount.mailingAddress.zip.substr(0, 5);
      } else {
        return "00000";
      }
    },
  },
  async mounted() {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    this.returnTo = params.get("return");
    if (this.$route.hash === "#add") {
      this.creatingWallet = true;
    }
    window.scrollTo(0, 0);
    await this.RefreshWallets();
  },
  methods: {
    async RefreshWallets() {
      this.wallets = undefined;
      this.editingWallet = undefined;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        this.wallets = await GMPAPI.GetWallets(this.currentAccount.accountNumber);
        this.loadState = "complete";
      } catch (err) {
        DumpError("Saved payment refresh error", err);
        this.loadState = "error";
      }
    },
    EditWallet(wallet) {
      this.editingWallet = wallet;
      if (wallet.isDebitAccountUnauthorized) {
        this.editingWallet.debitAccount = undefined;
        this.editingWallet.debitRouting = undefined;
      }
    },
    async ReturnFromEditor() {
      if (this.$route.hash === "#add" && this.returnTo === 'accountsettings') {
        this.$router.push("/settings#autopay");
      } else if (this.$route.hash === "#add" && this.returnTo === 'billingpreferences') {
        this.$router.push("/preferences#autopay");
      } else {
        this.creatingWallet = false;
        window.scrollTo(0, 0);
        await this.RefreshWallets();
      }
    },
    async LaunchLegacyPortal() {
      const cleartext = "SP_CREDIT_ACCOUNT=" + this.currentAccount.accountNumber + "|^|Debit_Zip=" + this.recurringAuth;
      const creds = await GMPAPI.GetSpeedpayCreds(this.currentAccount.accountNumber, cleartext);
      GMPAPI.LaunchSpeedpayPortal(creds.postData, creds.iv);
    },
  },
  watch: {
    // Refresh whenever the account changes
    async currentAccount() {
      this.creatingWallet = false;
      await this.RefreshWallets();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>