var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Information for the specified town",
              state: "error"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c(
            "div",
            [
              _c("outage-breadcrumbs", { attrs: { town: _vm.town } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "town-incident__row gds-flex-container",
                  class: { collapsed: _vm.detailCollapsed }
                },
                [
                  _c(
                    "div",
                    { staticClass: "town-incident__column--details town-page" },
                    [
                      _c("div", { staticClass: "gds-space-inset-l" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "gds-flex-container gds-flex-container--space-between gds-space-stack-m"
                          },
                          [
                            _c(
                              "h1",
                              { staticClass: "town-title gds-space-inline-s" },
                              [_vm._v(_vm._s(_vm.town.town_name) + ", Vermont")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "report_outage_btn gds-button gds-orange gds-compact outage-compact-flat-button",
                                attrs: { href: "/report-outage" }
                              },
                              [_vm._v("Report Outage")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.townIncidents && _vm.townIncidents.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "town-incident__column--metrics gds-flex-container gds-flex-container--left gds-flex-container--top"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "outage-incident__data-column"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "outage-incident__data-column--large-text"
                                      },
                                      [_vm._v(_vm._s(_vm.townIncidents.length))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-font-size-s gds-font-demi gds-text-browngrey"
                                      },
                                      [_vm._v("Active Incidents")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "outage-incident__data-column"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "outage-incident__data-column--large-text"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.town.customers_affected)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-font-size-s gds-font-demi gds-text-browngrey"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.town.town_name) +
                                            " Customers Out"
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "outage-incident__data-column"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "outage-incident__data-column--large-text"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("round")(
                                              _vm.town.affected_percent * 100,
                                              0
                                            )
                                          ) + "%\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-font-size-s gds-font-demi gds-text-browngrey"
                                      },
                                      [
                                        _vm._v(
                                          "Of " +
                                            _vm._s(_vm.town.town_name) +
                                            " Customers Out\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.FF_PlannedOutage
                        ? _c(
                            "div",
                            _vm._l(_vm.plannedOutages, function(plannedOutage) {
                              return _c(
                                "div",
                                {
                                  key: plannedOutage.id,
                                  staticClass:
                                    "planned-outage__message-box gds-flex-container gds-flex-container--space-between gds-flex-container--top"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "planned-outage__message-box--text"
                                    },
                                    [
                                      _vm._v(
                                        "A planned outage on " +
                                          _vm._s(
                                            _vm._f("dateTimeFullAt")(
                                              plannedOutage.occursAt
                                            )
                                          ) +
                                          " will affect some customers in " +
                                          _vm._s(_vm.town.town_name) +
                                          " VT."
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "planned-outage__message-box--link",
                                      attrs: {
                                        to: plannedOutage.incidentId
                                          ? "/incident/" +
                                            plannedOutage.incidentId
                                          : "/planned-outages/" +
                                            plannedOutage.id.toString()
                                      }
                                    },
                                    [_vm._v("More Info")]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.townIncidents && _vm.townIncidents.length
                        ? _c(
                            "div",
                            [
                              _c(
                                "vue-good-table",
                                {
                                  attrs: {
                                    columns: _vm.columns,
                                    rows: _vm.townIncidents,
                                    "sort-options": {
                                      enabled: true,
                                      initialSortBy: {
                                        field: "customerCount",
                                        type: "desc"
                                      }
                                    },
                                    "pagination-options": {
                                      enabled: _vm.townIncidents.length > 8,
                                      perPage: 8,
                                      perPageDropdownEnabled: false
                                    },
                                    styleClass:
                                      "vgt-table town-page-table outage-table"
                                  },
                                  on: {
                                    "on-row-click": _vm.incidentRowClick,
                                    "on-row-mouseenter": function($event) {
                                      return _vm.incidentRowHover($event.row.id)
                                    },
                                    "on-row-mouseleave": function($event) {
                                      return _vm.incidentRowHover(null)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "table-row",
                                        fn: function(props) {
                                          return [
                                            props.column.field === "active"
                                              ? [
                                                  props.row.customerCount > 0
                                                    ? _c("div", {
                                                        staticClass:
                                                          "alert-icon active"
                                                      })
                                                    : _c("div", {
                                                        staticClass:
                                                          "alert-icon inactive"
                                                      })
                                                ]
                                              : props.column.field === "street"
                                              ? [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-text--capitalize gds-font-demi"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            (props.row.street
                                                              ? props.row.street.toLowerCase()
                                                              : "") +
                                                              (props.row
                                                                .street &&
                                                              props.row
                                                                .remoteTown
                                                                ? ", "
                                                                : "") +
                                                              (props.row
                                                                .remoteTown
                                                                ? props.row.town
                                                                : "")
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "forceLowercase nowrap"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.row.counter
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  props.row.townsAffected > 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "incident-subtext"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Multi-town incident"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.formatEtr(
                                                            props.row
                                                              .estimatedRestoredAt,
                                                            props.row
                                                              .etrEarliest,
                                                            props.row.etrLatest
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ]
                                                ]
                                              : props.column.field === "status"
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("incidentStatus")(
                                                        props.row.status.code
                                                      )
                                                    )
                                                  )
                                                ]
                                              : props.column.field === "arrow"
                                              ? [
                                                  _c(
                                                    "svg",
                                                    { staticClass: "gds-icon" },
                                                    [
                                                      _c("use", {
                                                        attrs: {
                                                          "xlink:href":
                                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.formattedRow[
                                                        props.column.field
                                                      ]
                                                    )
                                                  )
                                                ]
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1659792228
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "emptystate" },
                                      slot: "emptystate"
                                    },
                                    [_vm._v("No matching towns")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "town-incident__column--details--no-outages"
                            },
                            [
                              _c("div", { staticClass: "gds-flex-container" }, [
                                _vm._m(0),
                                _vm._v(" "),
                                _c("div", { staticClass: "gds-font-size-l" }, [
                                  _vm._v(
                                    "\n              There are no reported outages in\n              "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.town.town_name) +
                                      ", Vermont\n            "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "report_outage_btn gds-button gds-round gds-secondary gds-compact",
                                    attrs: { href: "/report-outage" }
                                  },
                                  [_vm._v("Report\n              Outage")]
                                )
                              ])
                            ]
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "town-incident__column--map gds-position--relative"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "incident-row-collapse-toggle",
                          class: { collapsed: _vm.detailCollapsed },
                          attrs: {
                            title:
                              (_vm.detailCollapsed ? "View" : "Close") +
                              " Town Details"
                          },
                          on: { click: _vm.ToggleCollapsed }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "incident-row-collapse-toggle--text"
                            },
                            [_vm._v("View Town Details")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("gmp-map", {
                        ref: "map",
                        attrs: { kind: "town", townName: _vm.town.town_name },
                        on: {
                          centered: function($event) {
                            _vm.isCentered = $event
                          },
                          update: _vm.onMapUpdate
                        }
                      }),
                      _vm._v(" "),
                      !_vm.isCentered
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "recenter-button gds-button gds-round gds-secondary",
                              class: { collapsed: _vm.detailCollapsed },
                              attrs: { id: "recenter-button" },
                              on: {
                                click: function($event) {
                                  return _vm.Recenter()
                                }
                              }
                            },
                            [_vm._v("Back to " + _vm._s(_vm.town.town_name))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "no-outage-icon" }, [
      _c("img", {
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/outage-center/clipboard-with-green-check.svg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }