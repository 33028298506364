var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-modal" }, [
    _c(
      "div",
      {
        staticClass:
          "my-account__modal-box no-scroll-modal my-account__modal-box--usage-detail"
      },
      [
        _c("div", { staticClass: "usage-detail-modal__header" }, [
          _c("span", { staticClass: "usage-detail-modal__header__title" }, [
            _vm._v(_vm._s(_vm.modalTitle))
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "close bill-pay__close gds-button gds-button-circle gds-secondary",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.closeModal()
                }
              }
            },
            [
              _c("svg", { staticClass: "gds-icon close-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                  }
                })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("FocusLock", [
          _c("div", { staticClass: "usage-detail-modal__body" }, [
            _c("div", [
              _c("fieldset", { staticClass: "gds-fieldset" }, [
                _c(
                  "div",
                  { staticClass: "usage-detail-checkboxes-modal__col-2" },
                  _vm._l(_vm.config.options, function(option, i) {
                    return _c(
                      "span",
                      {
                        key: i,
                        staticClass:
                          "usage-detail-checkboxes-modal__checkbox-container"
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "gds-checkbox gds-checkbox-thin" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: option.checked,
                                  expression: "option.checked"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(option.checked)
                                  ? _vm._i(option.checked, null) > -1
                                  : option.checked
                              },
                              on: {
                                change: [
                                  function($event) {
                                    var $$a = option.checked,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            option,
                                            "checked",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            option,
                                            "checked",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(option, "checked", $$c)
                                    }
                                  },
                                  function($event) {
                                    return _vm.handleCheckboxChange(option)
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            !option.checked
                              ? _c("span", {
                                  staticClass: "gds-checkbox__faux"
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            option.checked
                              ? _c("span", {
                                  staticClass: "gds-checkbox__faux",
                                  style: {
                                    "--variable-usage-select-config-checkboxes-checked":
                                      option.color || "#00704a"
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "gds-checkbox__label gds-display-00 gds-text-grey"
                              },
                              [_vm._v(_vm._s(option.label))]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }