export default {
  namespaced: false,
  state: {
    /** Amount of the payment that was made */
    paymentAmount: undefined,
    /** Result of the payment that was made */
    confirmationNumber: undefined,
    /** Date of scheduled payment if it's in the future */
    futurePayment: undefined,
    /** Date for acct stoppage (not really billpay) */
    stoppageDate: undefined,
  },
  actions: {
  },
  // Changes the state
  mutations: {
    setPaymentAmount(state, amount) {
      state.paymentAmount = amount;
    },
    setConfirmationNumber(state, confirm) {
      state.confirmationNumber = confirm;
    },
    setFuturePayment(state, future) {
      state.futurePayment = future;
    },
    setStoppageDate(state, date) {
      state.stoppageDate = date;
    },
   clearPayment(state) {
      state.paymentAmount = undefined;
      state.confirmationNumber = undefined;
      state.futurePayment = undefined;
      state.stoppageDate = undefined;
    },
  },
}