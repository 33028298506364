var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "report-outage__offwhite-message-box gds-flex-container gds-flex-container--top gds-space-stack-l"
    },
    [
      _c(
        "div",
        { staticClass: "report-outage__offwhite-message-box__by-phone" },
        [
          _c(
            "div",
            { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
            [_vm._v("Report an Outage by Phone")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-size-m gds-space-stack-m" }, [
            _vm._v(_vm._s(_vm.GMPPhoneNumber))
          ])
        ]
      ),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "report-outage__offwhite-message-box__by-text" },
      [
        _c(
          "div",
          { staticClass: "gds-font-size-l gds-font-demi gds-space-stack-s" },
          [_vm._v("Report an Outage by Text")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "gds-font-size-m" }, [
          _vm._v('Text "OUT" to 46788 to report an outage by text.*')
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }