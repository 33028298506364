<template>
  <div class="eicproduct-container">
    <div v-if="loadState === undefined" class="eicproduct__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="eicproduct__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error"
      phonetext="For further help, please call us at" closepath="/electric-vehicle-rebate" />
    <div v-if="loadState === 'complete' && isCommercialAccount">
      <EVRebateNotEligible :currentAccount="currentAccount" />
    </div>
    <div v-if="loadState === 'complete' && !isCommercialAccount">
      <!-- SLAT 1 Verify Customer Information -->
      <section id="eicproduct-slat-1" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="eicproduct-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Customer Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="!userIsOTP" class="gds-body-normal gds-space-inline-m">Account: {{ currentAccount.nickname }}
                </div>
                <div v-if="userIsOTP" class="gds-body-normal gds-space-inline-m">Account Number: {{
                currentAccount.accountNumber
                }}</div>
                <button @click="gotoPage(1)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <!-- SLAT 1 Edit Screen For Account Information -->

          <!-- User Is Guest -->
          <div v-if="currentPage === 1 && userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                electric vehicle rebate.</legend>
              <label :key="currentAccount.accountNumber"
                class="eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m">
                <div class="eicproduct__account-box gds-space-stack-l">
                  <span v-if="currentAccount">
                    <div class="row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>

                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </label>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- User Has WWW Account -->
          <div v-if="currentPage === 1 && !userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                electric vehicle rebate.</legend>
              <div class="eicproduct__account-box gds-space-stack-l">
                <span v-if="currentAccount">
                  <div class="row-m">
                    <div class="account-info-row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                    <div v-if="accounts.length > 1" @click="chooseAccount()"
                      class="inline accountInformation eicproduct__edit-account-details">
                      <button class="gds-button gds-text-button choose-account-button">
                        <svg class="eicproduct__edit-icon-inline gds-icon">
                          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                        </svg>
                        <span v-if="accounts.length > 1">Switch Account</span>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 Product Rebate Details -->
      <section id="eicproduct-slat-2" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="eicproduct-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Vehicle Details</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <!-- If one vehicle -->
                <div class="gds-body-normal gds-space-inline-m">Rebate: {{ productSubmission.vehicles[0].brandModel }}
                </div>
                <button @click="gotoPage(2)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 2" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="rebate-slap__inner-row">
                <div>
                  <form data-vv-scope="page2">
                    <fieldset class="gds-fieldset gds-space-stack-s">
                      <h1 class="gds-display-1 gds-space-stack-l">Vehicle Information</h1>
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Purchase Price</span>
                        <div class="gds-position--relative">
                          <money v-model="productSubmission.vehicles[0].purchasePrice"
                            v-validate="'required|min_value:1|max_value:9999999'" type="text" name="purchase price" key="purchase price" id="purchase price" 
                            class="eicproduct-input-field__input gds-input-field__input" :precision=0 placeholder="Type here..."
                            ref="focuser2">
                          </money>
                          <div v-if="errors.first('purchase price', 'page2')" class="validation-error">{{ errors.first("purchase price",
                          "page2")
                          }}</div>
                        </div>
                      </label>
                      <br />
                      <br />
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Vehicle VIN (17 Digits)</span>
                        <div class="gds-position--relative">
                          <the-mask v-model="productSubmission.vehicles[0].vinNumber" :mask="'XXXXXXXXXXXXXXXXX'"
                            v-validate="'required|length:17'" type="text" name="VIN" key="VIN" id="vin"
                            class="eicproduct-input-field__input gds-input-field__input" placeholder="Type here..."
                            ref="focuser2" />
                          <div v-if="errors.first('VIN', 'page2')" class="validation-error">{{ errors.first("VIN",
                          "page2")
                          }}</div>
                        </div>
                      </label>
                      <br />
                      <br />
                      <span class="gds-input-field__label">Engine Type</span>
                      <div class="gds-space-stack-l">
                        <div
                          class="gds-flex-container gds-flex-container--left gds-flex-container--space-between radioContainer">
                          <label class="gds-radio">
                            <input v-model="productSubmission.vehicles[0].engineType" :value="'All Electric'"
                              type="radio" name="engine type" key="engine type" v-validate="'required'"
                              @change="handleEngineTypeChange()" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">
                              <b>All-Electric</b>
                            </span>
                          </label>
                          <label class="gds-radio">
                            <input v-model="productSubmission.vehicles[0].engineType" :value="'Hybrid'" type="radio"
                              name="engine type" key="engine type" @change="handleEngineTypeChange()" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">
                              <b>Plug-in Hybrid</b>
                            </span>
                          </label>
                        </div>
                        <div v-if="errors.first('engine type', 'page2')" class="validation-error">{{
                        errors.first("engine type", "page2")
                        }}</div>
                      </div>
                      <div class="row gds-space-stack-xl">
                        <div class="flex-item margin-right">
                          <label class="gds-input-field gds-space-stack-l">
                            <span class="gds-input-field__label">Vehicle Make and Model</span>
                            <select :disabled="disableBrandModel"
                              @change="parseVehicle(productSubmission.vehicles[0].brandModel);"
                              v-model="productSubmission.vehicles[0].brandModel" v-validate="'required'"
                              name="make and model" key="make and model"
                              class="eicproduct-input-field-l__select gds-input-field" autofocus>
                              <option value disabled selected>Select Make/Model</option>
                              <option v-for="(model, i) in filteredVehicleModels" :key="i" :value="model">{{ model |
                              formatVehicleModel()
                              }}</option>
                            </select>
                            <div v-if="errors.first('make and model', 'page2')" class="validation-error">{{
                            errors.first("make and model", "page2")
                            }}</div>
                          </label>
                        </div>
                        <div class="flex-item">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Model Year (YYYY)</span>
                            <the-mask v-model="productSubmission.vehicles[0].year"
                              v-validate="`required|numeric|min_value: 2000|max_value: ${parseInt(new Date().getFullYear() + 5)}`"
                              :mask="'####'" name="year" key="year" class="gds-input-field__input gds-space-stack-s"
                              type="tel" autocomplete="off" @input="$emit('touched', true);" placeholder="YYYY" />
                            <div v-if="errors.first('year', 'page2')" class="eicproduct-validation-error">{{
                            errors.first("year", "page2")
                            }}</div>
                          </label>
                        </div>
                      </div>

                      <span class="gds-input-field__label">New or Used Vehicle</span>
                      <div class="gds-space-stack-l">
                        <div
                          class="gds-flex-container gds-flex-container--left gds-flex-container--space-between radioContainer">
                          <label class="gds-radio">
                            <input v-model="productSubmission.vehicles[0].preOwned" :value="false" type="radio"
                              name="new or used" v-validate="'required'" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">
                              <b>New Vehicle</b>
                            </span>
                          </label>
                          <label class="gds-radio">
                            <input v-model="productSubmission.vehicles[0].preOwned" :value="true" type="radio"
                              name="new or used" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">
                              <b>Used Vehicle</b>
                            </span>
                          </label>
                        </div>
                        <div v-if="errors.first('new or used', 'page2')" class="eicproduct-validation-error">{{
                        errors.first("new or used", "page2")
                        }}</div>
                      </div>

                      <div class="row-l gds-space-stack-l">
                        <div class="flex-item margin-right">
                          <label @click.prevent class="gds-input-field">
                            <span class="gds-input-field__label">Purchase Date</span>
                            <div class="eicproduct-purchasedate__date-box">
                              <div @click="$refs.datePicker.showCalendar()"
                                class="my-account-billing-report__filtration-date-icon">
                                <svg class="gds-icon">
                                  <use
                                    xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                                </svg>
                              </div>
                              <datepicker ref="datePicker" v-model="productSubmission.vehicles[0].dateOfPurchase"
                                name="date of purchase" key="date of purchase" v-validate="'required'"
                                format="MMMM d, yyyy" :typeable="true" placeholder="Select Date..."
                                :disabled-dates="disableFutureDates"
                                input-class="export-data__date my-account-billing-report__filtration-date-text" />
                            </div>
                            <div v-if="errors.first('date of purchase', 'page2')" class="eicproduct-validation-error">{{
                            errors.first("date of purchase", "page2")
                            }}</div>
                          </label>
                        </div>
                        <div id="dealership" class="flex-item">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Dealership (If private sale, enter "Private
                              Sale")</span>
                            <input @keyup="selectDealership($event)" v-model="productSubmission.vehicles[0].dealerName"
                              @input="$emit('touched', true); filterDealerships();" @change="filterDealerships()"
                              v-validate="'required'" name="dealership" key="dealership"
                              class="gds-input-field__input gds-space-stack-s" type="text" autocomplete="off"
                              placeholder="Type Here..." />
                            <div v-if="errors.first('dealership', 'page2')" class="eicproduct-validation-error">{{
                            errors.first("dealership", "page2")
                            }}</div>
                          </label>
                          <div class="typeAhead-container" v-if="filteredDealerships.length > 0">
                            <div class="typeAhead-card gds-space-stack-m"
                              :class="{ 'active-dealership typeAhead-card gds-space-stack-m': currentDealership === dealer.id }"
                              v-for="(dealer) in filteredDealerships" :key="dealer.id"
                              @click="setDealership(dealer.name)">
                              <div class="typeAhead-text">{{ dealer.name }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <button @click="finishPage('page2', 3)"
                  class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 3 Rebate Savings & Offers -->
      <section id="eicproduct-slat-3" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div ref="focuser3"
            :class="currentPage === 3 ? 'eicproduct-slat__header gds-slat__header  gds-space-stack-xl' : 'eicproduct-slat__header gds-slat__header'">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="eicproduct-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Savings</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">Total Savings: ${{ totalSavings }}</div>
                <button @click="gotoPage(3)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 3" id="eicproduct-slat-3__contents"
            :class="this.productSubmission.vehicles[0].preOwned === false ? 'eicproduct-slat-3__contents' : undefined">
            <div id="eicproduct-slat-3__contents-inner" class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page3">
                  <div :class="'savings-offer-row gds-space-stack-l'">
                    <div class="gds-space-stack-xl">
                      <div v-if="productSubmission.vehicles[0].preOwned === false && !isRestrictedRebateUser">
                        <h1 v-if="!isRestrictedRebateUser" class="gds-display-1 gds-space-stack-m">You may be eligible for an additional incentive from
                          the State of Vermont (up to $4000).</h1>
                        <div class="gds-body-small slat3-body gds-space-stack-l">Get back as much as $4000 for certain
                          EV makes and models, while funds last. To view eligibility and apply, visit <a
                            href="https://www.driveelectricvt.com/incentives/vermont-state-incentives"
                            target="_blank">www.driveelectricvt.com</a>. We'll
                          include this link in your confirmation email.</div>
                        <a href="https://www.driveelectricvt.com/incentives/vermont-state-incentives"
                          class="ev-rebate__incentiveLinks" target="_blank" rel="noopener">Apply for the State of
                          Vermont Incentive</a>
                      </div>
                      <div v-if="productSubmission.vehicles[0].preOwned === true && !isRestrictedRebateUser">
                        <h1 class="gds-display-1 gds-space-stack-m">You may be eligible for an additional incentive from
                          MileageSmart (up to $5000).</h1>
                        <div class="gds-body-small slat3-body gds-space-stack-l">Get back as much as $5000 for certain
                          used EV makes and models, while funds last. To view eligibility and apply, visit <a
                            href="https://www.mileagesmartvt.org/" target="_blank">www.mileagesmartvt.org</a>. We'll
                          include this link in your confirmation email too.</div>
                        <a href="https://www.mileagesmartvt.org/" class="ev-rebate__incentiveLinks" target="_blank"
                          rel="noopener">Apply for the MileageSmart Incentive</a>
                      </div>
                      <div v-if="productSubmission.vehicles[0].engineType === 'Hybrid'">
                        <h1 class="gds-display-1 gds-space-stack-m">Income Qualified Incentive</h1>
                        <div class="gds-body-small slat3-body gds-space-stack-l">Low & Moderate Income Rebate is
                          available only for all-electric vehicles, new or used, and only for customers who meet income
                          requirements. Based on the information you've provided, the Income Qualified Incentive is not
                          available.</div>
                      </div>
                      <div v-if="productSubmission.vehicles[0].engineType === 'All Electric'">
                        <h1 class="gds-display-1 gds-space-stack-m">Provide info for income-based incentive eligibility.
                        </h1>
                        <div v-if="!isRestrictedRebateUser" class="gds-body-small slat3-body gds-space-stack-l">Additional incentives are avalible
                          based on household income while funds last. Please provide your gross household income info to
                          see if you qualify. Rebate total will update if you are eligible.</div>
                        <div v-if="isRestrictedRebateUser && productSubmission.vehicles[0].engineType === 'All Electric'" class="row-s">
                          <label class="gds-input-field">
                          <span class="gds-input-field__label">Did the customer attest that they are eligibile for the income based incentive?</span>
                          <div class="gds-space-stack-ml">
                            <select @change="onChangeIncomeIncentiveEligibility($event)" v-model="productSubmission.isEligibleForIncomeIncentive"
                              class="eicproduct-input-field__select" v-validate="'required'"
                              name="Income Based Incentive" key="Income Based Incentive" autofocus>
                              <option value disabled select>Select...</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          <div v-if="errors.first('Income Based Incentive', 'page3')" class="validation-error">{{
                          errors.first("Income Based Incentive", "page3")
                          }}</div>
                        </label>
                        </div>
                        <div v-if="!isRestrictedRebateUser" class="row-s">
                          <div class="gds-space-stack-m">
                            <label class="gds-input-field">
                              <span class="gds-input-field__label">Number Of Household Members</span>
                              <div class="gds-space-stack-ml">
                                <select @change="addToTotalSavings(); buildIncomeDropDown()"
                                  v-model="productSubmission.rebate.householdIncome.numberInHousehold"
                                  class="eicproduct-input-field__select" v-validate="'required'" name="number in household"
                                  key="number in household" autofocus>
                                  <option v-for="(option, index) of metadata.incomeIncentives" :key="index" :value="option.numberInHousehold">{{ option.numberInHousehold }}
                                  </option>
                                  <option value disabled select>Select...</option>
                                  
                                </select>
                              </div>
                            </label>
                            <div v-if="errors.first('number in household', 'page3')" class="eicproduct-validation-error"
                              style="max-width:80%">{{ errors.first("number in household", "page3") }}</div>
                          </div>
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Gross Household Income in {{ lastYear }}</span>
                            <select @change="addToTotalSavings()"
                              v-model="productSubmission.rebate.householdIncome.grossHouseholdIncome"
                              class="eicproduct-input-field__select gds-space-stack-ml" autofocus v-validate="'required'"
                              name="gross household income" key="gross household income"
                              :disabled="!productSubmission.rebate.householdIncome.numberInHousehold ? true : false">
                             <option v-for="(option, index) of incomeBasedEVIncentiveDropdown" :key="index" :value="option.incomeAmount">{{ option.incomeOption }}
                              </option>
                            </select>
                            <div v-if="errors.first('gross household income', 'page3')" class="eicproduct-validation-error">
                              {{ errors.first("gross household income", "page3") }}</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="hideIfDesktop">
                  <rebate-calculator v-if="currentPage === 3 && totalSavings > 0"
                    :engineType="productSubmission.vehicles[0].engineType"
                    :preOwned="productSubmission.vehicles[0].preOwned" :totalSavings="totalSavings"
                    :usedRebateAmount="usedEVRebateAmount" :allElectricNewRebateAmount="newEVRebateAmount"
                    :hybridNewRebateAmount="newHybridRebateAmount" :hybridUsedRebateAmount="usedHybridRebateAmount" :incomeQualifiedRebate="incomeQualifiedRebate" />
                </div>
                <button @click="finishPage('page3', 4)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
            <div class="hideIfMobile">
              <rebate-calculator v-if="currentPage === 3 && totalSavings > 0"
                :engineType="productSubmission.vehicles[0].engineType"
                :preOwned="productSubmission.vehicles[0].preOwned" :totalSavings="totalSavings"
                :usedRebateAmount="usedEVRebateAmount" :allElectricNewRebateAmount="newEVRebateAmount"
                :hybridNewRebateAmount="newHybridRebateAmount" :hybridUsedRebateAmount="usedHybridRebateAmount" :incomeQualifiedRebate="incomeQualifiedRebate" />
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 Upload Documents -->
      <section id="eicproduct-slat-4" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 4" class="eicproduct-slat__number-with-circle">
                <span>4</span>
              </div>
              <div v-if="currentPage > 4" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Upload Documents</div>
                <modal-document-checklist v-if="selectedFile" :visible="showDocumentChecklist" :isRestrictedRebateUser="isRestrictedRebateUser" :select="onSelect"
                  :save="onSave" :selectedFile="selectedFile" :close="closeModal" :documentTypes="documentTypes" />
                <ModalEICProducts modalName="informational" v-if="showInformationalModal && !isRestrictedRebateUser" :close="closeModal"
                  modalMessage="On your W9 please make sure you have Selected a box in item 3 and have signed it. Please make sure to have the following info on your proof of purchase:"
                  :listItems="['Make and Model of EV', 'Date of Purchase', 'VIN']" />
                <ModalEICProducts modalName="informational" v-if="showInformationalModal && isRestrictedRebateUser" :close="closeModal"
                  modalMessage="Please make sure to have the following info on your proof of purchase:"
                  :listItems="['Make and Model of EV', 'Date of Purchase', 'VIN']" />
                <ModalEICProducts modalName="proofOfPurchaseInfo" v-if="showProofOfPurchaseInfoModal"
                  :close="closeModal"
                  modalMessage="Please make sure you have the following information with your vehicle proof of purchase:"
                  :listItems="['Your Name', 'Date of purchase', 'VIN', 'Your address']" />
                <ModalEICProducts modalName="w9Info" v-if="showW9InfoModal && !isRestrictedRebateUser" :close="closeModal"
                  modalMessage="Please make sure you selected a box in item 3 and that you've signed your W9." />
                <ModalEICProducts modalName="missingDocument" v-if="showMissingDocumentsModal"
                  :close="onMissingDocumentsModalClose" modalTitle="You’re missing a required document"
                  modalMessage="Please upload a copy of the following:" :listItems="missingDocuments"/>
                <ModalEICProducts modalName="fileLoadTooBig" v-if="showFileLoadTooBigModal" :close="closeModal"
                  :modalMessage="`Your total file size is ${this.$options.filters.fileSizeFormat(totalFileSize)}. Please make sure the combined size of all uploaded files is less than ${this.$options.filters.fileSizeFormat(maxFileLoadSize)}.`" />
                <ModalEICProducts modalName="duplicateFilename" v-if="showDuplicateFilenameModal" :close="closeModal"
                  modalMessage="Each file that is uploaded must have a unique file name." />
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 4" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="documents.length > 1" class="gds-body-normal gds-space-inline-s">{{ `${this.documents.length}
                Files Uploaded`}}</div>
                <div v-if="documents.length === 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.documents.length} File Uploaded` }}</div>
                <button @click="gotoPage(4)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 4" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div class="slat-title-wrapper">
                    <div class="eicproduct-slat__contents-inner">
                  <div class="gds-space-stack-l">
                    <div class="slat-title-wrapper">
                      <form>
                        <div id="fileUploader">
                          <File-Uploader
                            ref="fileUploader"
                            :uploadDocumentList="uploadDocumentList"
                            :docTypes="documentTypes" 
                            :allowCombined="isRestrictedRebateUser"
                            @file-list-changed="updateFileList">
                          </File-Uploader>
                        </div>
                      </form>
                    </div>
                    <button @click="finishPage('page4', 5)"
                      class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>

      <section id="eicproduct-slat-5" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 5" class="eicproduct-slat__number-with-circle">
                <span>5</span>
              </div>
              <div v-if="currentPage > 5" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">In-Home Level 2 EV Charger</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 5" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <!-- If one vehicle -->
                <div class="gds-body-normal gds-space-inline-m"></div>
                <button @click="gotoPage(5)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 5" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 5" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <form data-vv-scope="page5">
                <fieldset class="gds-fieldset gds-space-stack-s">
                  <div v-if="!isLevel2Eligible" class="gds-body-small gds-space-stack-l">
                    <div class="gds-body-normal gds-space-stack-l slat3-body">While GMP does not provide the EV home charger for Plug-In Hybrid Vehicles,
                      should you want to enroll in one of GMP’s reduced EV charging rates you will need to purchase and install a compatible 
                      level 2 home charger.  You will find those compatible chargers on our website here: <a v-bind:href="COMPATIBLE_CHARGER_LINK">{{ COMPATIBLE_CHARGER_LINK_NAME }}</a></div>
                  </div>
                  <div v-if="isLevel2Eligible" class="gds-body-small gds-space-stack-l">
                    <h1 v-if="!isRestrictedRebateUser" class="gds-display-1 gds-space-stack-s">Get a free charger when you sign up for the home
                      charging program!</h1>
                      <h1 v-if="isRestrictedRebateUser" class="gds-display-1 gds-space-stack-s">Enroll a customer to receive a free charger by signing them up for the home
                      charging program!</h1>
                  </div>
                  <div v-if="isLevel2Eligible" class="gds-flex-container level2Charger gds-space-stack-l">
                    <img id="level2Charger" src="/wp-content/themes/gmptwentynineteen/assets/images/GMP-EV-thanks.jpeg"
                      alt="Level 2 Charger" />
                    <div style="width: 75%;">
                      <div v-if="!isRestrictedRebateUser" class="gds-body-small gds-space-stack-l slat3-body">With the purchase of a new all electric vehicle,
                        GMP will provide you with a Level 2 Charger to have installed at your home. These chargers allow you to 
                        get our discount EV charging rates to save on your charging costs.</div>
                      <div v-if="isRestrictedRebateUser" class="gds-body-small gds-space-stack-l slat3-body">With the purchase of a new all electric vehicle,
                        GMP will provide a customer with a Level 2 Charger to have installed at their home. These chargers allow a customer to 
                        enroll in our discount EV charging rates to save on charging costs.</div>
                      <div class="gds-space-stack-l agreement">
                        <!-- Faux file picker - wraps it in a label and hides the input -->
                        <label id="chargerCheckbox" class="gds-checkbox gds-checkbox-thin">
                          <input
                            v-model="productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram"
                            @change="onChangeChargerProgram($event)"
                            type="checkbox" />
                          <span class="gds-checkbox__faux"></span>
                          <span v-if="!isRestrictedRebateUser" id="level2Agreement" class>
                            <b>Yes</b> - I agree, I want to join your charging program and receive the charger. Please
                            send me the agreement.
                          </span>
                          <span v-if="isRestrictedRebateUser" id="level2Agreement" class>
                            <b>Yes</b> - The customer wants to join the charging program and receive a home charger. Please
                            send the customer the agreement.
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram && !isRestrictedRebateUser"
                    class="eicproduct-slap__inner-row">
                    <div class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          In order to identify how to best connect your EV charger to the internet, we have a few
                          questions.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Do you have an internet connection to your home?</span>
                          <div class="gds-space-stack-ml">
                            <select @change="onChangeInternet($event)" v-model="hasInternet"
                              class="eicproduct-input-field__select"
                              :v-validate="productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram ? 'required' : null"
                              name="hasInternet" key="hasInternet" autofocus>
                              <option value disabled select>Select...</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          <div v-if="errors.first('hasInternet', 'page5')" class="validation-error">{{
                          errors.first("hasInternet", "page5")
                          }}</div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div v-if="productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram && hasInternet === 'yes'" class="eicproduct-slap__inner-row">
                    <div class="row-s">
                      <div class="gds-space-stack-m">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Do you have a WiFi (wireless) network in your
                            home?</span>
                          <div class="gds-space-stack-ml">
                            <select @change="onChangeWifiNetwork($event)" v-model="hasWifiNetwork"
                              class="eicproduct-input-field__select" :v-validate="hasInternet ? 'required' : null"
                              name="wifiNetworkRequired" key="wifiNetworkRequired" autofocus>
                              <option value disabled select>Select...</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          <div v-if="errors.first('wifiNetworkRequired', 'page5')" class="validation-error">{{
                          errors.first("wifiNetworkRequired", "page5")
                          }}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram && hasWifiNetwork === 'yes'" class="eicproduct-slap__inner-row">
                    <div v-if="hasWifiNetwork === 'yes'" class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          The WiFi signal must strong enough to reach the location where your charger will be
                          installed/located.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Is there a strong, reliable WiFi signal where the EV
                            charger will be located?</span>
                          <div class="gds-position--relative">

                            <div class="gds-space-stack-ml">
                              <select @change="onChangeWifiSignal($event)" v-model="hasWifiSignalAtCharger"
                                class="eicproduct-input-field__select" :v-validate="hasWifiNetwork ? 'required' : null"
                                name="wifiSignalRequired" key="wifiSignalRequired" autofocus>
                                <option value disabled select>Select...</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                        </label>
                        <div v-if="errors.first('wifiSignalRequired', 'page5')" class="validation-error">{{
                        errors.first("wifiSignalRequired", "page5")
                        }}</div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div v-if="showNoQualifyChargerModal">
              <ModalNoQualifyCharger modalType="informational" :modalMessage="modalMessage" :close="closeModal">
              </ModalNoQualifyCharger>
            </div>
            <button @click="finishPage('page5', 6)" class="gds-button gds-compact">Next</button>
          </div>
        </div>
      </section>


      <!-- SLAT 6 Contact & Mailing Address -->
      <section id="eicproduct-slat-6" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 6" class="eicproduct-slat__number-with-circle">
                <span>6</span>
              </div>
              <div v-if="currentPage > 6" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Contact & Mailing Address</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 6" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-s">Mailing Address: {{
                productSubmission.rebate.rebateMailingAddress | addressFormat({ seperator: ", " })
                }}</div>
                <button @click="gotoPage(6)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 6" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 6" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page6">
                  <div class="gds-space-stack-l">
                    <label class="gds-input-field">
                      <span v-if="!isRestrictedRebateUser" class="gds-input-field__label">Please make my check out to:</span>
                      <span v-if="isRestrictedRebateUser" class="gds-input-field__label">Rebate is for:</span>
                      <input v-model="productSubmission.rebate.rebatePayeeName" @input="$emit('touched', true)"
                        v-validate="'required'" name="rebate payee name" key="rebate payee name" :disabled="isRestrictedRebateUser"
                        class="eicproduct-input-field gds-input-field__input" type="text" ref="focuser6" />
                      <div v-if="errors.first('rebate payee name', 'page6')" class="eicproduct-validation-error">{{
                      errors.first("rebate payee name", "page6")
                      }}</div>
                    </label>
                  </div>
                  <div class="slat-title-wrapper">
                    <div v-if="!isRestrictedRebateUser" class="eicproduct-title">Where should we send your rebate check?</div>
                  </div>
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Country</span>
                    <select v-model="productSubmission.rebate.rebateMailingAddress.country" :disabled="isRestrictedRebateUser"
                      :class="productSubmission.rebate.rebateMailingAddress.country === 'USA' ? 'eicproduct-input-field__select-country-us gds-input-field' : productSubmission.rebate.rebateMailingAddress.country === 'CAN' ? 'eicproduct-input-field__select-country-ca gds-input-field' : 'eicproduct-input-field__select-s gds-input-field'"
                      autofocus>
                      <option :value="'USA'" selected
                        data-thumbnail="/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg">United States
                      </option>
                      <option value="CAN">Canada</option>
                      <option value="FOR">Foreign</option>
                    </select>
                    <div v-if="!productSubmission.rebate.rebateMailingAddress.country" disabled="isRestrictedRebateUser" class="eicproduct-validation-error">
                      {{ errors.first("country", "page5") }}</div>
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Street Address</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street1"
                      @input="$emit('touched', true)" v-validate="'required'" name="street address" key="street address"
                      :disabled="isRestrictedRebateUser" class="eicproduct-input-field gds-input-field__input" type="text" placeholder="Type Here..." />
                  </label>
                  <br />
                  <br />
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Suite Apt</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street2"
                      @input="$emit('touched', true)" name="street 2" key="street 2"
                      :disabled="isRestrictedRebateUser" class="eicproduct-input-field gds-input-field__input" type="text" placeholder="Type Here..." />
                  </label>
                  <br />
                  <br />
                  <div class="row-l">
                    <div class="flex-item-l">
                      <label class="gds-input-field-flex">
                        <span class="gds-input-field__label">City</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.city"
                          @input="$emit('touched', true)" v-validate="'required'" name="city" key="city"
                          :disabled="isRestrictedRebateUser" class="gds-input-field__input" type="text" placeholder="Type Here..." />
                        <div v-if="errors.first('city', 'page6')" class="validation-error">{{ errors.first("city",
                        "page6")
                        }}</div>
                      </label>
                    </div>
                    <div class="flex-item-s gds-space-stack-m">
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">State</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" :disabled="isRestrictedRebateUser" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select State...</option>
                          <option v-for="state of stateList" :key="state" :value="state">{{ state }}</option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("state", "page6") }}</div>
                      </label>
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">Province</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" :disabled="isRestrictedRebateUser" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select Province...</option>
                          <option v-for="province of provinceList" :key="province" :value="province">{{ province }}
                          </option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("province", "page6") }}</div>
                      </label>
                    </div>
                    <div></div>
                    <div class="flex-item-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Zipcode</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.zip"
                          @input="$emit('touched', true)" v-validate="'required|numeric|length:5'" name="zipcode"
                          key="zipcode" :disabled="isRestrictedRebateUser" class="gds-input-field__input eicproduct-mailing-zip" type="text"
                          placeholder="Type Here..." autocomplete="postal-code" />
                        <div v-if="errors.first('zipcode', 'page6')" class="validation-error">{{ errors.first("zipcode",
                        "page6")
                        }}</div>
                      </label>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="eicproduct-validation-error">{{ errors.first("zip", "page6") }}</div>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="eicproduct-validation-error">{{ errors.first("canadianZip", "page6") }}</div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div>
                    <div v-if="!isRestrictedRebateUser" class="eicproduct-title">How should we contact you if we need additional information to process
                      your rebate:</div>
                      <div v-if="isRestrictedRebateUser" class="eicproduct-title">What is the email address and phone number of the customer that received the rebate?</div>
                    <br />
                    <br />
                    <br />
                    <div class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span v-if="!isRestrictedRebateUser" class="gds-input-field__label">Email Address</span>
                        <span v-if="isRestrictedRebateUser" class="gds-input-field__label">Customer Email Address</span>
                        <input v-model="productSubmission.rebate.confirmationEmailAddress"
                          @input="$emit('touched', true)" v-validate="'required|email|max:74'" name="email address"
                          key="email address" class="eicproduct-input-field gds-input-field__input" type="email"
                          placeholder="Enter email address" autocomplete="email" ref="email" />
                        <div v-if="errors.first('email address', 'page6')" class="validation-error">{{
                        errors.first("email address", "page6")
                        }}</div>
                      </label>
                    </div>
                    <div v-if="isRestrictedRebateUser" class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Confirm Your Email Address</span>
                        <input v-model="confirmationEmailAddress" @blur="addConfirmationEmailError()"
                          @input="$emit('touched', true);" v-validate="'required|email'"
                          name="confirmation email address" key="confirmation email address"
                          class="eicproduct-input-field gds-input-field__input" type="email" placeholder="Your email"
                          autocomplete="email" ref="email" />
                        <div v-if="productSubmission.rebate.confirmationEmailAddress !== confirmationEmailAddress"
                          class="validation-error">{{
                          errors.first("confirmation email address", "page6")
                          }}</div>
                      </label>
                    </div>
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Phone Number</span>
                      <div class="gds-space-stack-xl">
                        <the-mask v-model="productSubmission.rebate.contactPhoneNumber" :mask="'+1 (###) ###-####'"
                          @input.native="touched = true" v-validate="'required|min:10'" data-vv-validate-on="input"
                          :name="'phone number'" :key="'phone number'" class="eicproduct-input-field gds-input-field__input"
                          type="tel" placeholder="Your phone number" />
                        <div v-if="errors.first('phone number', 'page6')" class="validation-error">{{
                        errors.first("phone number", "page6")
                        }}</div>
                      </div>
                    </label>
                    <div v-if="isRestrictedRebateUser" class="gds-space-stack-m">
                      <label class="gds-checkbox gds-checkbox-thin">
                        <input v-model="customerConfirmation" v-validate="'required'" type="checkbox"
                          key="customer confirmation" name="customer confirmation" />
                        <span class="gds-checkbox__faux"></span>
                        <span class="gds-font-size-ml regulatory-text1">
                            Please confirm the information above is accurate for the customer that you are submitting this rebate for.
                          </span>
                        <div v-if="errors.first('customer confirmation', 'page6')"
                          class="eicproduct-validation-error">{{
                          errors.first("customer confirmation", "page6")
                          }}</div>
                      </label>
                    </div>
                    <div v-if="!isAlreadyEbillEnrolled && !isRestrictedRebateUser">
                      <div class="gds-body-small gds-space-stack-l">
                        <span class="gds-display-1 gds-space-stack-s">Would you like to go paperless?</span>
                      </div>
                      <div class="gds-body-small gds-space-stack-l slat3-body">
                        You can reduce your carbon footprint even more,
                        by switching to paperless billing! Paperless monthly energy
                        statements cut carbon and clutter while saving trees.
                      </div>
                      <div class="gds-space-stack-ml">
                        <!-- Faux file picker - wraps it in a label and hides the input -->
                        <label class="gds-checkbox gds-checkbox-thin">
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEnroll" type="checkbox" name />
                          <span class="gds-checkbox__faux"></span>
                          <span class="gds-font-size-ml">
                            <b>Yes!</b> - Enroll me in paperless billing so I can reduce my carbon footprint even more.
                          </span>
                        </label>
                      </div>
                      <div v-if="productSubmission.programEnrollment.ebill.ebillEnroll" class="gds-space-stack-ml">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Account email address</span>
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEmailAddress"
                            v-validate="'required|email'" @input="$emit('touched', true)" name="account email address"
                            key="account email address" class="eicproduct-input-field gds-input-field__input" type="email"
                            placeholder="Your email" autocomplete="email" ref="email" />
                          <div v-if="errors.first('account email address', 'page6')" class="validation-error">{{
                          errors.first("account email address", "page6")
                          }}</div>
                        </label>
                      </div>
                    </div>
                    <br />
                  </div>
                </form>
                <button @click="finishPage('page6', 7)"
                  class="eicproduct__method-next-btn gds-button gds-compact" :disabled="isRestrictedRebateUser && !customerConfirmation">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 7 Review & Submit-->
      <section id="eicproduct-slat-7" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 7" class="eicproduct-slat__number-with-circle">
                <span>7</span>
              </div>
              <div v-if="currentPage > 7" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Review & Submit</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage < 7" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 7" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div v-if="!isRestrictedRebateUser" class="gds-display-00 gds-space-stack-s" ref="focuser7">Your rebate will be mailed to:</div>
                <div v-if="isRestrictedRebateUser" class="gds-display-00 gds-space-stack-s" ref="focuser7">Total rebate received by customer:</div>
                <div class="gds-body-small gds-space-stack-s">Amount: ${{ totalSavings }}</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebatePayeeName | titlecase()
                }}</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebateMailingAddress.street1 }}</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebateMailingAddress.city }} {{ productSubmission.rebate.rebateMailingAddress.state }} {{ productSubmission.rebate.rebateMailingAddress.zip }}</div>
                <br>
                <div v-if="!isRestrictedRebateUser" class="gds-display-00 gds-space-stack-s">Your Vehicle Information:</div>
                <div v-if="isRestrictedRebateUser" class="gds-display-00 gds-space-stack-s">The customer's Vehicle Information:</div>
                <div class="gds-body-small gds-space-stack-l">
                  <div>Make/Model: {{ productSubmission.vehicles[0].brandModel }}</div>
                  <div>Engine type: {{ productSubmission.vehicles[0].engineType }}</div>
                  <div>VIN: {{ productSubmission.vehicles[0].vinNumber.toUpperCase() }}</div>
                </div>

                <div
                  v-if="incomeQualifiedRebate > 0 || productSubmission.programEnrollment.ebill.enrollInHomeChargerProgram"
                  class="gds-display-00 gds-space-stack-s">Savings & Offers:</div>
                <ol class="gds-space-stack-m">
                  <li class="gds-space-stack-s" v-if="incomeQualifiedRebate > 0">
                    <span v-if="!isRestrictedRebateUser">You qualify for an income based rebate which will be included in your rebate check.</span>
                    <span v-if="isRestrictedRebateUser">The customer qualifies for an income based rebate which will be included in their rebate check.</span>
                  </li>
                  <li class="gds-space-stack-s"
                    v-if="!isRestrictedRebateUser && productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram">
                    Great News! You've decided that you’d like a Free Level 2 Charger. We’ll send you an
                    email in the next couple days with an agreement and additional
                    information about the program.
                  </li>
                  <li class="gds-space-stack-s"
                    v-if="isRestrictedRebateUser && productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram">
                    We’ll follow-up with the customer based on their interest in the home charger program
                  </li>

                </ol>
                <br>
                <div v-if="!isRestrictedRebateUser" class="gds-space-stack-m">
                  <label class="gds-checkbox gds-checkbox-thin">
                    <input v-model="regulatoryDataSharing" v-validate="'required'" type="checkbox"
                      key="regulatory data sharing" name="regulatory data sharing" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-font-size-ml regulatory-text1">
                        {{ REGULATORY_REBATE_SHARING_TEXT1  }}
                      </span>
                      <p class="gds-font-size-ml regulatory-text2">
                        {{ REGULATORY_REBATE_SHARING_TEXT2  }}
                      </p>
                    <div v-if="errors.first('regulatory data sharing', 'page7')"
                      class="eicproduct-validation-error">{{
                      errors.first("regulatory data sharing", "page7")
                      }}</div>
                  </label>
                </div>
                <button v-if="!pendingSubmit" @click="handleSubmit" type="submit" style="margin-top: 2rem;" class="gds-button gds-compact"
                  :disabled="pendingSubmit || (!regulatoryDataSharing && !isRestrictedRebateUser)">
                  <span :disabled="pendingSave || !touched || (!regulatoryDataSharing && !isRestrictedRebateUser) || errors.any()"
                    class="gds-button__text gds-space-stack-m">Submit Rebate</span>
                </button>
                <button v-if="pendingSubmit" style="margin-top: 2rem;" class="gds-button [modifier class] gds-loading">
                  <span class="gds-button__text">Button Element</span>
                  <figure class="gds-button__icon"></figure>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ErrorBag } from "vee-validate";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import Datepicker from 'vuejs-datepicker';
import FileUploader from "../FileUploader.vue";
import {vehicleRegistration, proofOfPurchase, w9, customerRebateForm, regulatoryRebateSharingText1, regulatoryRebateSharingText2 } from "../constants.js";
import { GMPPhoneNumber } from "../../../environment"
import RebateCalculator from "./RebateCalculator.vue"
import ModalDocumentChecklist from "./ModalDocumentChecklist.vue"
import EVRebateNotEligible from "./EVRebateNotEligible.vue";
import ModalEICProducts from "../ModalEICProducts.vue";
import ModalNoQualifyCharger from "./ModalNoQualifyCharger.vue"
import { ToServerDateTruncate } from "../../../utilities";
import { getConfigValue } from "../../../services/featureflags";



export default {
  name: "EVRebateFlow",
  components: {
    Datepicker,
    ModalDocumentChecklist,
    RebateCalculator,
    EVRebateNotEligible,
    FileUploader,
    ModalEICProducts,
    ModalNoQualifyCharger
  },
  data() {
    return {
      GMPPhoneNumber,
      NO_INTERNET_MESSAGE: 'Your home must have a reliable internet connection in order to participate in the EV charger program',
      COMPATIBLE_CHARGER_LINK: 'https://greenmountainpower.com/rebates-programs/electric-vehicles/in-home-ev-charger',
      COMPATIBLE_CHARGER_LINK_NAME: 'Compatible Vehicles',
      REGULATORY_REBATE_SHARING_TEXT1: undefined,
      REGULATORY_REBATE_SHARING_TEXT1_DEFAULT: regulatoryRebateSharingText1,
      REGULATORY_REBATE_SHARING_TEXT2: undefined,
      REGULATORY_REBATE_SHARING_TEXT2_DEFAULT: regulatoryRebateSharingText2,
      DEALER: 'dealer',
      CUSTOMER: 'customer',
      disableFutureDates: {
        from: new Date(),
      },
      disablePastDates: {
        to: new Date()
      },
      isKeyboarding: false,
      loadState: undefined,
      lastYear: new Date().getFullYear() - 1,
      pendingSubmit: undefined,
      touched: false,
      pendingSave: undefined,
      isAlreadyEbillEnrolled: undefined,
      isMobile: undefined,
      isLevel2Eligible: true,
      isCommercialAccount: undefined,
      confirmationEmailAddress: undefined,

      totalFileSize: undefined,
      maxFileLoadSize: undefined,
      metadata: undefined,
      EVIncomeIncentiveLimits: [],
      incomeBasedEVIncentiveDropdown: [],
      stateList: ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"],
      provinceList: ["QC", "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "SK", "NT", "NU", "YT"],

      currentDealership: 0,
      dealerships: [],
      filteredDealerships: [],
      totalSavings: 0,

      vehicleModels: undefined,
      disableBrandModel: undefined,

      newEVRebateAmount : undefined,
      usedEVRebateAmount: undefined,
      newHybridRebateAmount: undefined,
      usedHybridRebateAmount: undefined,

      vehicleRegistration: vehicleRegistration,
      proofOfPurchase: proofOfPurchase,
      w9: w9,
      customerRebateForm: customerRebateForm,

      showDocumentChecklist: false,
      showMissingDocumentsModal: false,
      showInformationalModal: false,
      showProofOfPurchaseInfoModal: false,
      showW9InfoModal: false,
      showFileLoadTooBigModal: false,
      showDuplicateFilenameModal: false,
      showNoQualifyChargerModal: false,

      incentiveType: undefined,

      selectedFile: undefined,
      file: undefined,
      documents: [],
      documentTypes: [],
      uploadDocumentList: [
      {
        documentName: vehicleRegistration,
        displayName: 'Vehicle Registration',
        error: undefined,
        file: null,
        fileName: null,
        fileSize: null,
      },
      {
        documentName: proofOfPurchase,
        displayName: 'Proof of Purchase',
        error: undefined,
        file: null,
        fileName: null,
        fileSize: null,
      },
      {
        documentName: w9,
        displayName: 'IRS W-9',
        error: undefined,
        file: null,
        fileName: null,
        fileSize: null,
      }],
      selectedDocumentType: undefined,

      missingDocuments: [],

      incomeQualifiedRebate: undefined,
      EVRebateIncomeThreshold: undefined,

      hasInternet: '',
      hasWifiNetwork: '',
      hasWifiSignalAtCharger: '',
      regulatoryDataSharing: undefined,
      customerConfirmation: undefined,
      inputPurchasePrice: '',

      productSubmission: {
        submissionSource: undefined,
        account: {
          accountNumber: undefined,
          firstName: undefined,
          lastName: undefined,
          premiseAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            zip: undefined
          },
          mailingAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            zip: undefined
          }
        },
        programEnrollment: {
          ebill: {
            ebillEmailAddress: undefined,
            ebillEnroll: undefined
          },
          inHomeChargerProgram: {
            enrollInHomeChargerProgram: undefined,
            hasWifiSignalAtCharger: false,
          }
        },
        rebate: {
          confirmationEmailAddress: undefined,
          contactPhoneNumber: undefined,
          householdIncome: {
            grossHouseholdIncome: '',
            numberInHousehold: ''
          },
          rebateDeliveryMethod: "Mailed Check",
          rebateMailingAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            street2: undefined,
            zip: undefined
          },
          rebatePayeeName: undefined,
          stateOfVermontRebate: {
            expirationDate: undefined,
            preapprovalNumber: undefined
          }
        },
        submissionType: "electricVehicleRebate",
        isEligibleForIncomeIncentive: undefined,
        vehicles: [
          {
            brandModel: undefined,
            dateOfPurchase: undefined,
            dealerName: undefined,
            engineType: undefined,
            year: undefined,
            preOwned: undefined,
            vinNumber: undefined
          }
        ]
      }
    }
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    isRestrictedRebateUser() {
      return !!this.$store.state.user.userinfo.groups.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
    filteredVehicleModels() {
      let filteredVehicleModels = [];
      for (let i = 0; i < this.vehicleModels.length; i++) {
        if (this.vehicleModels[i].includes(this.productSubmission.vehicles[0].engineType)) {
          filteredVehicleModels.push(this.vehicleModels[i]);
        }
      }
      return filteredVehicleModels;
    },
  },
  filters: {
    formatVehicleModel(vehicle) {
      const brandModel = vehicle.split(/-(.+)/)[1].trim();
      return `${brandModel}`;
    }
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.currentPage === 2) {
        this.isKeyboarding = true;
      } else {
        this.isKeyboarding = false;
      }
    });
  },
  async mounted() {
    if (this.currentAccount.customerClass === "C") {
      this.isCommercialAccount = true;
      this.loadState = "complete";
    } else {
      for (let i = 0; i < this.currentAccount.serviceAgreements.length; i++) {
        const rate = this.currentAccount.serviceAgreements[i].rate;
        if (rate === "N06" || rate === "06") {
          this.productSubmission.programEnrollment.ebill.enrollInHomeChargerProgram = undefined;
          this.isLevel2Eligible = false;
        }
      }
      this.$store.commit("setLastValidPage", 'page1');
      document.addEventListener("click", this.hideTypeAhead);
      this.setRebateSubmissionAccount();
      // Kick off async fetches
      try {
        this.loadState = undefined;
        const localStorageProductSubmissionObj = this.getWithExpiry("localProductSubmission") || undefined;
        if (localStorageProductSubmissionObj &&
          localStorageProductSubmissionObj.submissionType === 'electricVehicleRebate') {
          this.productSubmission = localStorageProductSubmissionObj;
        }
        await Promise.all([
        this.getVehicleModels(),
        this.getDealerships(),
        this.REGULATORY_REBATE_SHARING_TEXT1 = await getConfigValue('TXT_RebateRegulatorySharingText1', this.REGULATORY_REBATE_SHARING_TEXT1_DEFAULT, null),
        this.REGULATORY_REBATE_SHARING_TEXT2 = await getConfigValue('TXT_RebateRegulatorySharingText2', this.REGULATORY_REBATE_SHARING_TEXT2_DEFAULT, null),
        !this.isRestrictedRebateUser ? this.checkIfOnEBill() : Promise.resolve() 
        ]);
        if (!this.productSubmission.vehicles[0].engineType) {
          this.disableBrandModel = true;
        } else if (this.productSubmission.vehicles[0].engineType) {
          this.disableBrandModel = false;
        }

        if (this.productSubmission.vehicles[0] && this.productSubmission.vehicles[0].engineType === 'All Electric') {
          this.isLevel2Eligible = true;
        } else {
          this.isLevel2Eligible = false;
        }

        this.setEVDealerUploadDocuments();

        if (this.productSubmission.rebate.householdIncome.numberInHousehold) {
          this.buildIncomeDropDown();
        }
        this.loadState = "complete";
      } catch (err) {
        DumpError("EVRebate loggedin load error", err);
        this.loadState = "error";
      }
      setTimeout(() => {
        this.slatFocus(1);
      }, 1000);
      setTimeout(() => {
        this.resetState();
      }, 1000 * 60 * 60 * 6); // flush local storage & reload page after 6 hours

    }
  },
  methods: {
    setEVDealerUploadDocuments() {
      if (this.isRestrictedRebateUser) {
          this.uploadDocumentList.push(
          {
            documentName: customerRebateForm,
            displayName: 'Customer Rebate Form',
            error: undefined,
            file: null,
            fileName: null,
            fileSize: null,
          });

          const index = this.uploadDocumentList.findIndex(doc => doc.documentName === w9);
          if (index !== -1) {
            this.uploadDocumentList.splice(index, 1);
          }
      }
    },
    toTitleCase(str) {
      if(!str) { return str; }
      return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },
    addConfirmationEmailError() {
      this.errors.add({ field: "confirmation email address", msg: "Email fields must match.", scope: "page6" })
    },
    async checkIfOnEBill() {
      const status = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber);
      if (status.billRouteInformation.isEBill) {
        this.isAlreadyEbillEnrolled = true;
      } 
    },
    /** Go to a page number without validation */
    gotoPage(page) {
      this.isKeyboarding = false;
      this.$router.push({ path: "/form/" + `${page}/` });
      this.slatFocus(page);
    },
    chooseAccount() {
      this.isKeyboarding = false;
      this.$router.push({ path: "/account" });
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        var elem = document.getElementById(`eicproduct-slat-${page}`);
        elem.scrollIntoView();
      }, 100);
    },
    hideTypeAhead() {
      this.filteredDealerships = [];
      this.currentDealership = 0;
    },
    onChangeChargerProgram(event) {
      if (!this.productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram) {
        this.hasInternet = null;
        this.hasWifiNetwork = null;
        this.hasWifiSignalAtCharger = null;
      }
    },
    onChangeInternet() {
      this.modalName = 'noQualifyCharger';
      if (this.hasInternet === 'no') {
        this.showNoQualifyChargerModal = true;
        this.modalMessage = this.NO_INTERNET_MESSAGE
      } else {
        this.showNoQualifyChargerModal = false;
      }
    },
    onChangeWifiNetwork(evt) {
      this.hasWifiNetwork = evt.target.value;
    },
    onChangeWifiSignal(evt) {
      this.hasWifiSignalAtCharger = evt.target.value;

      if (this.hasWifiSignalAtCharger === 'yes') {
        this.productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger = true;
      } else {
        this.productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger = false;
      }
    },
    setRebateSubmissionAccount() {
      this.productSubmission.rebate.rebatePayeeName = `${this.toTitleCase(this.currentAccount.firstName)} ${this.toTitleCase(this.currentAccount.lastName)}`;
      this.productSubmission.account.accountNumber = this.currentAccount.accountNumber;
      this.productSubmission.account.firstName = `${this.toTitleCase(this.currentAccount.firstName)}`;
      this.productSubmission.account.lastName = `${this.toTitleCase(this.currentAccount.lastName)}`;

      this.productSubmission.account.mailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.account.premiseAddress = this.currentAccount.address;
      this.productSubmission.rebate.rebateMailingAddress = this.currentAccount.mailingAddress;

      if (!this.isRestrictedRebateUser) {
        this.productSubmission.rebate.contactPhoneNumber = this.currentAccount.phone;
        this.productSubmission.rebate.confirmationEmailAddress = this.currentAccount.emailAddress;
      }
    },
    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      localStorage.removeItem("localProductSubmission")
      this.setWithExpiry("localProductSubmission", this.productSubmission, (1000 * 60 * 60 * 6)); // 6 hours
      let currentPage = nextPage - 1;

      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            break;
          case "page2":
            if (!this.errors) this.errors = new ErrorBag();
            this.incentiveType = this.productSubmission.vehicles[0].engineType;
            // Incentive values vary over time so get meta data and incentives based on purchase date
            const effectiveDate =  ToServerDateTruncate(new Date(this.productSubmission.vehicles[0].dateOfPurchase));
            
            await this.getEICMeta(effectiveDate);  
            this.clearIncomeDropDown();
            this.buildIncomeDropDown();
            this.addToTotalSavings();
            break;
          case "page3":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;
          case "page4":
            this.validateUploadedFiles();
            if (!this.isLevel2Eligible) {
              nextPage = 6;
            }
            break;
          case "page5":
            this.errors.clear(pageName);
            if (this.productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger && !this.hasInternet) {
              this.showNoQualifyChargerModal = true;
            }
            if (!this.errors) this.errors = new ErrorBag();
            break;
          case "page6":
            this.errors.clear(pageName);
        }

        if (this.errors.any(pageName)) {
          this.slatFocus(currentPage);
        } else {
          this.$store.commit("setLastValidPage", pageName);
          this.gotoPage(nextPage);
        }
      }
    },
    validateUploadedFiles() {
      let requiredDocs;
      if (this.isRestrictedRebateUser) {
        requiredDocs = [vehicleRegistration, proofOfPurchase, customerRebateForm];
      } else {
        requiredDocs = [vehicleRegistration, proofOfPurchase, w9];
      }

      let validationResult =  this.$refs.fileUploader.validate(this.documents, this.maxFileLoadSize, requiredDocs);

      if (validationResult.missingDocuments.length) {
        this.modalMessage = "You're missing a required document";
        this.missingDocuments = validationResult.missingDocuments;
        this.showMissingDocumentsModal = true;
      } else if (validationResult.hasDuplicateFilenames) {
        this.showDuplicateFilenameModal = true;
      } else if (validationResult.hasFileTooBig) {
        this.showFileLoadTooBigModal = true;
        this.totalFileSize = validationResult.totalFileSize;
      } else {
        this.modalMessage = "";
        this.missingDocuments = [];
        this.showDuplicateFilenameModal = false;
        this.showFileTooBigModal = false;
      }
    },
    async getVehicleModels() {
      const vehicleModels = await GMPAPI.GetEVModels();
      vehicleModels.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      })
      this.vehicleModels = vehicleModels;
    },
    handleEngineTypeChange() {
      if (this.productSubmission.vehicles[0].engineType) {
        this.disableBrandModel = false;
      } else if (!this.productSubmission.vehicles[0].engineType) {
        this.disableBrandModel = true;
      }

      if (this.productSubmission.vehicles[0].engineType !== 'All Electric') {
        this.productSubmission.rebate.householdIncome.numberInHousehold = '';
        this.productSubmission.rebate.householdIncome.grossHouseholdIncome = '';
        this.incomeQualifiedRebate = undefined;
        this.isLevel2Eligible = false;
      } else {
        this.isLevel2Eligible = true;
      }
      this.productSubmission.vehicles[0].brandModel = "";
    },
    updateFileList(evt) {
      this.documents = evt; 
    },
    async getEICMeta(incentiveDate) {
      const metadata = await GMPAPI.GetEICMeta(incentiveDate);
      this.metadata = metadata;
      this.maxFileLoadSize = metadata.maxFileUploadSize;
      this.newEVRebateAmount = parseInt(metadata.newEVRebateAmount);
      this.usedEVRebateAmount = parseInt(metadata.usedEVRebateAmount);
      this.newHybridRebateAmount = parseInt(metadata.newHybridRebateAmount);
      this.usedHybridRebateAmount = parseInt(metadata.usedHybridRebateAmount);
      this.EVIncomeBasedIncentiveAmount = parseInt(metadata.electricVehicleIncomeIncentiveAmount);

      this.metadata.incomeIncentives.sort((a, b) => a.numberInHousehold - b.numberInHousehold);
      for (let item of metadata.incomeIncentives) {
        this.EVIncomeIncentiveLimits.push({numberInHousehold: item.numberInHousehold,
                                           incomeThreshold: item.incomeLimit});
      }  
      this.clearIncomeDropDown();
    },
    async getDealerships() {
      const dealerships = await GMPAPI.GetEVDealerships();
      dealerships.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      })

      let decodedDealerships = [];
      for (let i = 0; i < dealerships.length; i++) {
        var txt = document.createElement("textarea");
        txt.innerHTML = dealerships[i];
        decodedDealerships.push(txt.value);
      }
      this.dealerships = decodedDealerships;
    },
    filterDealerships() {
      let filteredDealerships;
      for (let i = 0; i < this.dealerships.length; i++) {
        filteredDealerships = this.dealerships.filter(dealer => {
          return dealer.toLowerCase().startsWith(this.productSubmission.vehicles[0].dealerName.toLowerCase());
        })
      }
      this.filteredDealerships = [];
      for (let i = 0; i < filteredDealerships.length; i++) {
        this.filteredDealerships.push({
          id: i,
          name: filteredDealerships[i]
        })
      }
    },
    setDealership(dealership, i) {
      if (dealership) {
        this.filteredDealerships = [];
        this.productSubmission.vehicles[0].dealerName = dealership;
      }
    },
    selectDealership(event) {
      const typeAhead = document.querySelector(".typeAhead-container")
      if (typeAhead) {
        if (event.keyCode === 38 && this.currentDealership > 0) {
          this.currentDealership--;
          typeAhead.children[this.currentDealership].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        } else if (event.keyCode === 40 && this.currentDealership < this.filteredDealerships.length - 1) {
          this.currentDealership++;
          typeAhead.children[this.currentDealership].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });;
        } else if (event.keyCode === 13) {
          this.productSubmission.vehicles[0].dealerName = this.filteredDealerships[this.currentDealership].name;
          this.hideTypeAhead();
        }
      }
    },
    parseVehicle(vehicle) {
      this.productSubmission.vehicles[0].brandModel = vehicle;
      const engineType = vehicle.split(/-(.+)/)[0].trim();
      this.productSubmission.vehicles[0].engineType = engineType;
    },
    showModal(modalName) {
      if (modalName === 'noQualifyCharger') {
        this.showNoQualifyChargerModal = true;
      }
      if (modalName === 'informational') {
        this.showInformationalModal = true;
      }
      if (modalName === 'proofOfPurchaseInfo') {
        this.showProofOfPurchaseInfoModal = true;
      }
      if (modalName === 'w9Info') {
        this.showW9InfoModal = true;
      }
    },
    closeModal(modalName) {
      if (modalName === 'noQualifyCharger') {
        this.showNoQualifyChargerModal = false;
        this.productSubmission.programEnrollment.inHomeChargerProgram.enrollInHomeChargerProgram = false;
        this.hasInternet = '';
        this.hasWifiNetwork = '';
        this.hasWifiSignalAtCharger = '';
      }
      if (modalName === 'informational') {
        this.showInformationalModal = false;
        this.$refs.filePicker.click();
      }
      if (modalName === 'proofOfPurchaseInfo') {
        this.showProofOfPurchaseInfoModal = false;
        this.$refs.filePicker.click();
      }
      if (modalName === 'w9Info') {
        this.showW9InfoModal = false;
        this.$refs.filePicker.click();
      }
      if (modalName === 'missingDocument') {
        this.showMissingDocumentsModal = false;
        this.gotoPage(4);
      }
      if (modalName === 'documentChecklist') {
        if (this.selectedDocumentTypes) {
          const array = this.documentTypes.filter(type => !this.selectedDocumentTypes.includes(type));
          this.documentTypes = array;
        }
        this.selectedFile = undefined;
        this.showDocumentChecklist = false;
      }
      if (modalName === 'fileLoadTooBig') {
        this.showFileLoadTooBigModal = false;
        this.gotoPage(4);
      }

      if (modalName === 'duplicateFilename') {
        this.showDuplicateFilenameModal = false;
        this.gotoPage(4);
      }
    },
    onSave() {
      this.showDocumentChecklist = false;
      this.Save();
    },
    onSelect(event) {
      const filetype = event.target.value;
      if (event.target.checked) {
        if (this.documentTypes.length === 0) {
          this.documentTypes.push(filetype);
          this.selectedDocumentTypes.push(filetype)
          return;
        } else {
          for (let i = 0; i < this.documentTypes.length; i++) {
            if (filetype === this.documentTypes[i]) {
              return;
            }
          }
          this.documentTypes.push(filetype);
          this.selectedDocumentTypes.push(filetype);
        }
      } else if (!event.target.checked) {
        for (let i = 0; i < this.documentTypes.length; i++) {
          if (filetype === this.documentTypes[i]) {
            this.documentTypes.splice(i, 1);
          }
        }
        for (let i = 0; i < this.selectedDocumentTypes.length; i++) {
          if (filetype === this.selectedDocumentTypes[i]) {
            this.selectedDocumentTypes.splice(i, 1);
          }
        }
      }
    },
    async handleSubmit() {
      this.pendingSubmit = true;
      this.productSubmission.vehicles[0].vinNumber = this.productSubmission.vehicles[0].vinNumber.toUpperCase();
      if (this.productSubmission.vehicles[0].preOwned) {
        this.productSubmission.vehicles[0].preOwned = 'Yes';
      } else {
        this.productSubmission.vehicles[0].preOwned = 'No';
      }

      if (this.isRestrictedRebateUser) {
        this.productSubmission.submissionSource = this.DEALER;
      } else {
        this.productSubmission.submissionSource = this.CUSTOMER;
      }
      
      const formdata = new FormData();
      formdata.append('productSubmission', new Blob([JSON.stringify(this.productSubmission)], { type: "application/json" }));

      for (let i = 0; i < this.documents.length; i++) {
        formdata.append('files', this.documents[i].file);
      }
      
      try {
        localStorage.removeItem("localProductSubmission")
        await GMPAPI.SubmitEVRebte(this.productSubmission.account.accountNumber, formdata);
        this.$router.push({ path: "/complete" });
      } catch (err) {
        DumpError("Electric Vehicle Rebate Submission error", err);
        if (err.response && err.response.data) {
          let code = err.response.data.status;
          if (err.response.data.message) {
            console.error(err.response.data.message);
            code = err.response.data.code;
            this.$router.push({ path: "/error/" + code });
          } else {
            this.$router.push({ path: "/error/" + code });
          }
        } else {
          this.$router.push({ path: "/error/" });
        }
      }
    },
    addIncomeBasedRebateToTotal() {
      this.incomeQualifiedRebate = 0;
      let numberInHousehold = this.productSubmission.rebate.householdIncome.numberInHousehold;
      let income = parseInt(this.productSubmission.rebate.householdIncome.grossHouseholdIncome);
      let incomeThreshold = this.metadata[numberInHousehold + "personHousehold"];
      if (income <= incomeThreshold) {
        this.totalSavings += this.EVIncomeBasedIncentiveAmount;
        this.incomeQualifiedRebate += this.EVIncomeBasedIncentiveAmount;
      }
    },
    onChangeIncomeIncentiveEligibility() {
      if (this.productSubmission.isEligibleForIncomeIncentive.toLowerCase() === 'yes') {
        this.totalSavings += this.EVIncomeBasedIncentiveAmount;
        this.incomeQualifiedRebate += this.EVIncomeBasedIncentiveAmount;
      } else {
        if (this.incomeQualifiedRebate > 0) {
          this.totalSavings -= this.EVIncomeBasedIncentiveAmount;
        }
        this.incomeQualifiedRebate = 0;
      }
    },
    clearModal() {
      this.showDocumentChecklist = false;
    },
    clearStateRebateData() {
      this.productSubmission.rebate.stateOfVermontRebate = {
        preapprovalNumber: undefined,
        expirationDate: undefined,
      }
    },
    clearIncomeDropDown() {
      if (this.incomeBasedEVIncentiveDropdown.size > 0) {
        this.incomeBasedEVIncentiveDropdown.size = 0;
      }
      this.productSubmission.rebate.householdIncome.grossHouseholdIncome = null;
    },
    buildIncomeDropDown() {
      const numberInHousehold = this.productSubmission.rebate.householdIncome.numberInHousehold;
      if (this.metadata && this.metadata.incomeIncentives.length > 0) {
        const incomeIncentive = this.metadata.incomeIncentives.find(incomeIncentive => incomeIncentive.numberInHousehold === numberInHousehold);

        if (incomeIncentive) {
          this.incomeBasedEVIncentiveDropdown = [];
          this.incomeBasedEVIncentiveDropdown.push({incomeOption: "Prefer not to provide",
                                                    incomeAmount: 999999999});
          this.incomeBasedEVIncentiveDropdown.push({incomeOption: "$" + incomeIncentive.incomeLimit + " or less",
                                                    incomeAmount: incomeIncentive.incomeLimit});
          this.incomeBasedEVIncentiveDropdown.push({incomeOption: "Greater than $" + incomeIncentive.incomeLimit,
                                                    incomeAmount: incomeIncentive.incomeLimit+1});
          this.EVRebateIncomeThreshold = incomeIncentive.incomeLimit;
        }
      }
    },
    addToTotalSavings() {
      this.totalSavings = 0;
      if (this.productSubmission.vehicles[0].preOwned && this.productSubmission.vehicles[0].engineType === 'All Electric') {
        this.totalSavings += this.usedEVRebateAmount;
        this.addIncomeBasedRebateToTotal();
      } else if (this.productSubmission.vehicles[0].preOwned && this.productSubmission.vehicles[0].engineType !== 'All Electric') {
        this.totalSavings += this.usedHybridRebateAmount;
      } else {
        if (this.productSubmission.vehicles[0].engineType === 'All Electric' && !this.productSubmission.vehicles[0].preOwned) {
          this.totalSavings += this.newEVRebateAmount;

          this.addIncomeBasedRebateToTotal();
        } else if (this.productSubmission.vehicles[0].engineType === 'Hybrid' && !this.productSubmission.vehicles[0].preOwned) {
          this.totalSavings += this.newHybridRebateAmount;
        }
      }
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    resetState() {
      localStorage.removeItem("localProductSubmission");
      window.location.reload(true);
    },
    onMissingDocumentsModalClose(modalName) {
      this.closeModal(modalName);
      setTimeout(() => {
        for (let i = 0; i < this.missingDocuments.length; i++) {
          if (this.missingDocuments[i] === 'Vehicle Registration') {
            const elem = document.getElementById(vehicleRegistration);
            elem.scrollIntoView();
          }
          if (this.missingDocuments[i] === 'Proof Of Purchase') {
            const elem = document.getElementById(proofOfPurchase);
            elem.scrollIntoView();
          }
          if (this.missingDocuments[i] === 'IRS W9') {
            const elem = document.getElementById(w9);
            elem.scrollIntoView();
          }
          if (this.missingDocuments[i] === 'Customer Rebate Form') {
            const elem = document.getElementById(customerRebateForm);
            elem.scrollIntoView();
          }
        }
        this.missingDocuments = [];
      }, 500)
    },
    setMissingDocuments() {
      if (!this.documentTypes.includes(vehicleRegistration)) {
        this.missingDocuments.push('Vehicle Registration');
      }
      if (!this.documentTypes.includes(proofOfPurchase)) {
        this.missingDocuments.push('Proof Of Purchase');
      }
      if (!this.documentTypes.includes(w9) && !this.isRestrictedRebateUser) {
        this.missingDocuments.push('IRS W9');
      }
      if (!this.documentTypes.includes(customerRebateForm) && this.isRestrictedRebateUser) {
        this.missingDocuments.push('Customer Rebate Form');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>