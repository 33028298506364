<template>
    <div class="usage-unavailable-container">
      <img class="maintenance-graphic" src="/wp-content/themes/gmptwentynineteen/assets/images/maintenance-error.svg" />
      <div class="maintenance-message maintenance-message--main gds-space-stack-l">
        Usage information is not available for your account.
      </div>
    </div>
</template>

<script>

export default {
  name: "UsageUnavailable",
  mounted() {
    if (this.currentAccount && !this.currentAccount.resilientHome) {
      this.$router.push({ path: '/account' });
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    }
  },
};
</script>
