var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "event-in-progress-icon-wrapper" }, [
      _c("span", { staticClass: "event-in-progress-icon-border" }, [
        _c("span", { staticClass: "event-in-progress-icon" })
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "event-in-progress-icon top" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }