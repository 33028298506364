<template>
  <section class="settings-editor my-account__full-width-section my-account__section-with-bottom-border">
    <!-- Loading and error display -->
    <div v-if="loadState !== 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
        <div class="my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m">
          <h2 class="settings-editor__title gds-space-inline-m">Recurring Auto Payments</h2>
          <div v-if="loadState === undefined" class="editor-loading" />
        </div>
        <div class="fake-edit-link">Edit</div>
      </div>
      <div v-if="loadState === 'error'" class="editor-error">Unable to load this section. Please try again later.</div>
    </div>
    <!-- Main display when loading complete -->
    <div v-if="loadState === 'complete'">
      <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
        <div class="my-account__on-off-spacer-right gds-flex-container gds-flex-container--top gds-space-stack-m">
          <h2 ref="scrolltarget" class="settings-editor__title gds-space-inline-m">Recurring Auto Payments</h2>
          <!-- Show indicator when not editing if we have obtained the billing status -->
          <div v-if="!editing && billingStatus && recurringStatus !== undefined" class="gds-flex-container gds-flex-container--left">
            <div class="my-account__on-off-text gds-flex-container gds-flex-container--left gds-flex-container--top">
              <!-- Show as on if either recurring is true or deprecated autoPayEnrolled is true -->
              <IndicatorOnOff :value="recurringStatus || billingStatus.legacyAutoPayEnrolled"></IndicatorOnOff>
            </div>
          </div>
        </div>

        <div class="edit-close-button-combo">
          <!-- Note: don't allow editing if using deprecated autopay -->
          <button
            v-if="!editing && recurringStatus !== undefined && billingStatus && !billingStatus.legacyAutoPayEnrolled"
            @click="BeginEdit()"
            type="button"
            class="gds-button gds-text-button gds-space-inline-l"
          >
            <span class="my-account__notifications--text-button gds-button__text">Edit</span>
          </button>
          <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
            <span class="my-account__notifications--text-button gds-button__text">Close</span>
          </button>
        </div>
      </div>

      <!-- Auto Payments Display -->
      <div v-if="!editing && billingStatus">
        <!-- Deprecated direct debit on -->
        <div class="gds-space-stack-l gds-text-grey" v-if="billingStatus.legacyAutoPayEnrolled && recurringStatus === null">
          You are enrolled in Recurring Auto Payments. To make changes please contact customer service at
          <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
        </div>
        <!-- Both methods off -->
        <div
          v-if="!billingStatus.legacyAutoPayEnrolled && recurringStatus === null"
          class="gds-space-stack-l gds-text-grey"
        >Turn on recurring auto payments to automatically pay your bill each month.</div>

        <div v-if="saveComplete && !recurringStatus" class="form-message-box form-message-box--green gds-space-stack-l">
          <div class="gds-flex-container gds-flex-container--left">
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
            </svg>
            <span>You have successfully unenrolled from Recurring Auto Payments.</span>
          </div>
        </div>

        <!-- Enrolled in speedpay -->
        <div v-if="recurringStatus">
          <div v-if="saveComplete" class="form-message-box form-message-box--green gds-space-stack-l">
            <div class="gds-flex-container gds-flex-container--left">
              <svg class="gds-icon">
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
              </svg>
              <span>You have successfully enrolled in Recurring Auto Payments.</span>
            </div>
          </div>
          <div class="gds-font-book gds-space-stack-s">Your balance due will be withdrawn 5 days before the due date.</div>
          <div class="gds-font-book gds-space-stack-ml">Card payments have a $2,500 per month maximum. To avoid cancelled payments, use a bank account for the payment method if your monthly bill might exceed $2,500.</div>

          <div class="gds-font-size-m gds-font-demi gds-space-stack-s" id="payment-method">Payment Method</div>
          <div class="gds-flex-container gds-flex-container--top gds-flex-container--left gds-space-stack-l">
            <div class="gds-space-inline-s">
              <!-- Check paymentType of the wallet if we have one, or the payment itself if not -->
              <svg
                v-if="(recurringWallet && recurringWallet.paymentType === 'ACH') || (recurringStatus.recurringScheduleRecord.debitInformation.paymentType === 'ACH')"
                class="gds-icon gds-gmpgreen-icon"
              >
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-bank" />
              </svg>
              <svg v-else class="gds-icon gds-gmpgreen-icon">
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
              </svg>
            </div>
            <div class="my-account__autopay-wallet-nickname">
              <!-- If we have a wallet and it has a nickname, show it -->
              <div v-if="recurringWallet && recurringWallet.accountNickName" class="gds-font-size-l gds-font-book gds-space-stack-s">{{recurringWallet.accountNickName}}</div>
              <div
                class="gds-font-size-l gds-font-book"
                aria-labelledby="payment-method"
              >{{(recurringWallet || recurringStatus.recurringScheduleRecord.debitInformation) | walletSlug}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Auto Payments Edit -->
      <form v-if="editing && billingStatus" @submit.prevent="Save()">
        <div class="my-account__header-button-pairing gds-flex-container gds-flex-container--left">
          <fieldset class="gds-fieldset gds-space-inline-m">
            <label class="gds-switch">
              <input v-model="autoPaySwitch" @change="touched = true" type="checkbox" />
              <span class="gds-switch__faux"></span>
            </label>
          </fieldset>
          <div>
            <div v-if="autoPaySwitch === autoPayAPIEnabled" class="gds-space-stack-m">Recurring Auto Payments are turned {{autoPaySwitch ? "on" : "off"}}.</div>
            <div
              v-if="autoPaySwitch !== autoPayAPIEnabled"
              class="gds-space-stack-m"
            >Recurring Auto Payments will be turned {{autoPaySwitch ? "on" : "off"}}. Click save to confirm.</div>
          </div>
        </div>
        <div v-if="autoPaySwitch">
          <div class="gds-font-book gds-space-stack-s">Your balance due will be withdrawn 5 days before the due date.</div>
          <div class="gds-font-book gds-space-stack-ml">Card payments have a $2,500 per month maximum. To avoid cancelled payments, use a bank account for the payment method if your monthly bill might exceed $2,500.</div>
          <div class="gds-text-grey gds-space-stack-m">Select a Payment Method</div>

          <!-- IF NO SAVED PAYMENT METHODS -->
          <div v-if="wallets && wallets.length === 0" class="recurring-payments--no-saved-methods gds-flex-container gds-space-stack-l">
            <div class="gds-flex-container gds-flex-container--column">
              <div class="my-account__recurring--add-new-box">
                <div v-if="autoPayAPIEnabled" class="gds-space-stack-m">To make changes, please re-add your payment method.</div>
                <div v-if="!autoPayAPIEnabled" class="gds-space-stack-m">No Saved Payment Methods</div>
                <router-link :to="'/saved-payments' + returnParam + '#add'" class="gds-button gds-round gds-secondary">Add New</router-link>
              </div>
            </div>
          </div>

          <div v-if="wallets && wallets.length > 0" class="gds-space-stack-s">
            <div role="radiogroup">
              <fieldset class="gds-fieldset gds-space-stack-s">
                <wallet-selector v-for="wallet of wallets" :key="wallet.walletId" :value="wallet" v-model="selectedWallet" @change="touched = true" />
              </fieldset>
            </div>
          </div>

          <div v-if="selectedWallet && (selectedWallet.isExpired || selectedWallet.isDebitAccountUnauthorized)" class="form-message-box gds-space-stack-l">
            Your selected payment method has an issue. Please select a different method or fix the payment method on the
            <router-link :to="'/saved-payments' + returnParam">Manage Saved Payments</router-link>&nbsp;page.
          </div>

          <div v-if="wallets && wallets.length !== 0" class="gds-space-stack-l">
            <router-link :to="'/saved-payments' + returnParam + '#add'" class="gds-button gds-round gds-secondary">Add New</router-link>
            <router-link :to="'/saved-payments' + returnParam" class="gds-button gds-round gds-secondary">Manage</router-link>
          </div>

          <!-- CVV -->
          <div v-if="selectedWallet && selectedWallet.paymentType === 'CC'" class="gds-space-stack-m">
            <fieldset class="bill-pay-slat__small-flex gds-fieldset">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Verify your CVV</span>
                <input
                  v-model="cvv"
                  v-mask="'####'"
                  v-validate="'required|numeric|min:3|max:4'"
                  name="CVV"
                  key="CVV"
                  class="gds-input-field__input"
                  type="tel"
                  maxlength="4"
                  autocomplete="cc-csc"
                />
              </label>
              <div v-if="errors.first('CVV')" class="validation-error">{{ errors.first("CVV")}}</div>
            </fieldset>
          </div>

          <!-- Billing Zip -->
          <div v-if="selectedWallet && (selectedWallet.paymentType === 'CC' || selectedWallet.paymentType === 'ATM')" class="gds-space-stack-m">
            <fieldset class="bill-pay-slat__small-flex gds-fieldset">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Verify your Billing ZIP Code</span>
                <input
                  v-model="debitZip"
                  v-mask="'#####'"
                  v-validate="'required|numeric|min:5|max:5'"
                  name="billing zip"
                  key="billing zip"
                  class="gds-input-field__input"
                  type="tel"
                  maxlength="5"
                />
              </label>
              <div v-if="errors.first('billing zip')" class="validation-error">{{ errors.first("billing zip")}}</div>
            </fieldset>
          </div>

          <!-- Email -->
          <div v-if="wallets && wallets.length !== 0" class="gds-space-stack-l">
            <fieldset class="bill-pay-slat__small-flex gds-fieldset">
              <label class="gds-input-field">
                <span class="gds-input-field__label">Email for sending payment confirmations</span>
                <input
                  v-model="emailAddress"
                  v-validate="'required|email|max:74'"
                  maxlength="74"
                  name="email address"
                  key="email address"
                  @change="touched = true"
                  class="bill-pay-input-field__input gds-input-field__input"
                  type="email"
                  placeholder="Your email"
                  autocomplete="email"
                />
              </label>
              <div v-if="errors.first('email address')" class="validation-error">{{ errors.first("email address")}}</div>
            </fieldset>
          </div>
        </div>
        <div v-if="display5DayMessage" class="form-message-box form-message-box--blue gds-space-stack-l">
          Note: your recurring auto payment will not begin this months as it is less than 5 days until your bill due date. 
          It will begin next month. If you wish to make a payment for this bill month, please
          <a href="/make-payment">click here</a>.
        </div>
        <div v-if="errorSave" class="form-message-box gds-space-stack-m">{{errorSave}}</div>
        <div class="gds-space-stack-l">
          <button
            @click="Save()"
            :disabled="pendingSave || !touched || !wallets || (wallets.length === 0 && autoPaySwitch)"
            :class="{ 'gds-loading': pendingSave }"
            type="button"
            class="gds-button gds-round gds-space-stack-m"
          >
            <span class="gds-button__text">Save</span>
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { parseISO, differenceInDays } from "date-fns";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import IndicatorOnOff from "../../generic/IndicatorOnOff";
import WalletSelector from "../../generic/WalletSelector";
import { InterpretSpeedpayCode } from '../../../speedpay';
import { AnalyticsLogEvent } from "../../../services/analytics";
import { GMPPhoneNumber } from "../../../environment";

export default {
  name: "EditorAutoPayments",
  props: ["billingStatus"],
  components: {
    WalletSelector,
    IndicatorOnOff,
  },
  data() {
    return {
      recurringStatus: undefined,
      wallets: undefined,
      selectedWallet: undefined,
      cvv: undefined,
      debitZip: undefined,
      emailAddress: undefined,

      editing: false,
      touched: false,
      pendingSave: false,
      errorSave: undefined,
      saveComplete: false,

      autoPayAPIEnabled: false,
      autoPaySwitch: false,

      loadState: undefined,

      GMPPhoneNumber,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    recurringWallet() {
      if (this.wallets && this.recurringStatus) {
        const found = this.wallets.find(wallet => wallet.walletId === this.recurringStatus.walletId);
        if (found) {
          return found;
        } else {
          return null;
        }
      }
    },
    returnParam() {
      if (this.$route.path === "/settings") {
        return "?return=accountsettings";
      } else if (this.$route.path === "/preferences") {
        return "?return=billingpreferences";
      } else {
        return "";
      }
    },
    display5DayMessage() {
      const acct = this.$store.state.user.currentAccount;
      if(!acct) { return false; }
      if(!this.autoPaySwitch) { return false; }

      const dueDate = new Date(acct.amountDueDate);
      const daysDiff = differenceInDays(dueDate, new Date());
      return dueDate && daysDiff > 0 && daysDiff < 5;
    }
  },
  async mounted() {
    await this.RefreshAll();
    // Returning from saved payments, start edit and scroll
    if (this.$route.hash === "#autopay") {
      this.BeginEdit();
      if (!this.autoPaySwitch) {
        this.autoPaySwitch = true;
        this.touched = true;
      }
      await this.$nextTick();
      if (this.$refs.scrolltarget) {
        this.$refs.scrolltarget.scrollIntoView(true);
      }
    }
  },
  methods: {
    async RefreshAll() {
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      if (this.billingStatus === undefined) {
        // Not loaded yet, wait
        return;
      }
      if (this.billingStatus === null) {
        // Billing load failed
        this.loadState = "error";
        return;
      }
      try {
        await Promise.all([
          this.RefreshRecurring(),
          this.RefreshWallets(),
        ]);
        this.loadState = "complete";
      } catch (err) {
        DumpError("Auto payment editor refresh error", err);
        this.loadState = "error";
      }
    },
    async RefreshRecurring() {
      this.recurringStatus = undefined;
      const status = await GMPAPI.GetRecurring(this.currentAccount.accountNumber);
      if (status.length) {
        // Only take first element
        this.recurringStatus = status[0];
      } else {
        this.recurringStatus = null;
      }
      this.selectedWallet = this.recurringWallet;
    },
    async RefreshWallets() {
      this.wallets = undefined;
      this.wallets = await GMPAPI.GetWallets(this.currentAccount.accountNumber);
      this.selectedWallet = this.recurringWallet;
    },
    BeginEdit() {
      // Copy current value to editor
      this.autoPaySwitch = this.recurringStatus !== null;
      this.autoPayAPIEnabled = this.autoPaySwitch;
      this.editing = true;
      this.touched = false;
      this.errorSave = undefined;
      this.cvv = undefined;
      if (this.recurringStatus) {
        this.emailAddress = this.recurringStatus.recurringScheduleRecord.recurringScheduleInformation.emailAddress;
        this.selectedWallet = this.recurringWallet;
      } else {
        if (this.wallets && this.wallets.length) {
          try {
            // Find the wallet with the most recent modification date
            const freshest = this.wallets.reduce((accumulator, current) => {
              if (!accumulator || parseISO(current.modifiedDate) > parseISO(accumulator.modifiedDate)) {
                return current;
              } else {
                return accumulator;
              }
            });
            if (freshest) {
              this.selectedWallet = freshest;
            } else {
              this.selectedWallet = this.wallets[0];
            }
          } catch (err) {
            DumpError("Issue finding freshest wallet", err);
            this.selectedWallet = this.wallets[0];
          }
        }
      }
      // Fill email from account if not present
      if (!this.emailAddress) {
        this.emailAddress = this.currentAccount.emailAddress;
      }
      // Fill email from user if still not present
      if (!this.emailAddress) {
        this.emailAddress = this.userInfo.email;
      }
    },
    async Save() {
      if (await this.$validator.validateAll()) {
        if (this.autoPaySwitch) {
          try {
            this.pendingSave = true;
            if (!this.selectedWallet) {
              this.errorSave = "You must select a payment method";
              return;
            }
            const userinfo = this.$store.state.user.userinfo;
            const customerInformation = {
              // debitName: this.selectedWallet.debitName || userinfo.fullName,
              debitFirstName: userinfo.givenName,
              debitLastName: userinfo.surname,
              // Account
              creditFirstname: userinfo.givenName,
              creditLastname: userinfo.surname,
              phone: userinfo.phone,
              // Populate all location fields from account, should be required but ignored
              debitAddress1: this.currentAccount.address.street1,
              debitCity: this.currentAccount.address.city,
              debitState: this.currentAccount.address.state,
              debitZip: this.debitZip || this.currentAccount.address.zip,
              creditCity: this.currentAccount.address.city,
              creditState: this.currentAccount.address.state,
              creditZip: this.currentAccount.address.zip,
            };
            let debitInformation;
            // If credit card, include CVV
            if (this.selectedWallet.paymentType === "CC") {
              debitInformation = { cvv: this.cvv };
            }
            await GMPAPI.SetRecurring(this.currentAccount.accountNumber, this.selectedWallet.walletId, this.emailAddress, customerInformation, debitInformation);
            // Log an analytics event
            AnalyticsLogEvent("enabled_recurring_payment");
            await this.RefreshAll();
            this.editing = false;
          } catch (err) {
            DumpError("Save recurring error", err);
            let code;
            if (err.response && err.response.data) {
              code = err.response.data.code;
              if (err.response.data.message) {
                console.error(code + ": " + err.response.data.message);
              }
            }
            // Take specific actions on certain error codes here
            const parsed = InterpretSpeedpayCode(code);
            if (parsed && parsed.msg) {
              this.errorSave = parsed.msg;
            } else {
              // Error not recognized or no response data
              this.errorSave = "There was an unexpected error saving your recurring payment.";
            }
          } finally {
            this.pendingSave = false;
            this.saveComplete = true;
          }
        } else {
          if (this.recurringStatus) {
            try {
              this.pendingSave = true;
              await GMPAPI.DeleteRecurring(this.currentAccount.accountNumber, this.recurringStatus.recurringScheduleRecord.recurringScheduleId);
              await this.RefreshRecurring();
              this.editing = false;
            } catch (err) {
              DumpError("Delete recurring error", err);
              this.errorSave = "There was an unexpected error turning off your recurring payment.";
            } finally {
              this.pendingSave = false;
              this.saveComplete = true;
            }
          }
        }
      }
    },
  },
  watch: {
    // Refresh status whenever the account changes
    async currentAccount() {
      this.editing = false;
      this.touched = false;
      this.errorSave = undefined;
      await this.RefreshAll();
    },
    async billingStatus() {
      this.editing = false;
      this.touched = false;
      this.errorSave = undefined;
      await this.RefreshAll();
      // Returning from saved payments, start edit and scroll
      if (this.$route.hash === "#autopay") {
        this.BeginEdit();
        if (!this.autoPaySwitch) {
          this.autoPaySwitch = true;
          this.touched = true;
        }
        await this.$nextTick();
        if (this.$refs.scrolltarget) {
          this.$refs.scrolltarget.scrollIntoView(true);
        }
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>