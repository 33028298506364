<template>
  <nav class="my-account__sidebar__nav">
    <router-link to="/" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '' }">User Settings</router-link>
    <router-link v-if="!isAdmin" to="/accounts" class="my-account__sidebar__nav-item" :class="{ 'active': subPage === '/accounts' }">Add / Manage Accounts</router-link>
    <a href="/account" class="my-account__sidebar__nav-item arrow-after-link">My Account Dashboard</a>
  </nav>
</template>

<script>
export default {
  name: "WidgetUserProfileNav",
  data() {
    return {
    };
  },
  computed: {
    subPage() {
      if (this.$route.matched.length) {
        return this.$route.matched[0].path;
      }
      return "";
    },
    isAdmin() {
      return !!(this.$store.state.user && this.$store.state.user.userinfo && this.$store.state.user.userinfo.isAdmin);
    },
  },
  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>