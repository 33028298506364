<template>
  <div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error"
      phonetext="If you have questions about the eBike rebate program, please call us at" closepath="/ebike-rebate-test" />
    <flow-error v-if="!featureEnabled" name="eBike" state="feature disabled"
      phonetext="If you have questions about the eBike rebate program, please call us at" />
    <div v-if="routeloading || loadState === undefined">
      <div class="eicproduct__page-loader">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
          class="eicproduct__page-loader--svg" />
      </div>
    </div>
    <router-view v-if="loadState === 'complete'"></router-view>
  </div>
</template>

<script>
import { EnvironmentConstants } from "../../../environment"
import { DumpError, ReturnToSender } from "../../../utilities";
import { GetComponentStatus } from "../../../services/statuspage";
import { isFeatureEnabled } from "../../../services/featureflags";

export default {
  name: "PageEbikeRebate",
  components: {
  },
  data() {
    return {
      loadState: undefined,
      EnvironmentConstants,
      featureEnabled: false,
    };
  },
  computed: {
    routeloading() {
      return this.$store.state.layout.routeloading;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async mounted() {
    // Wire up the close button (outside of vue)
    const closer = document.querySelector("#flow-close");
    if (closer) {
      closer.addEventListener("click", e => {
        ReturnToSender('/ebike-rebate');
      });
    }

    //TODO Check with Jay on whether there should be another added component added to status.io
    const status = await GetComponentStatus("Rebates");
    this.featureEnabled = await isFeatureEnabled('FF_eBikeRebateFlow', false);

    this.loadState = undefined;

    if (!this.featureEnabled) {
      this.loadState = "feature disabled";
    }
    else if (status === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    async Logout() {
      try {
        await this.$store.dispatch("LogOut");
        this.$router.replace("/login");
      } catch (err) {
        DumpError("Logout error", err);
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>