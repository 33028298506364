<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal">
      <button @click="close(modalName)" type="button" class="close bill-pay__close">
        <span class="gds-visibility--hide-visually">Close</span>
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
      </button>
      <FocusLock>
        <div class="eicproducts__modal-box--body">
          <div v-if="modalTitle" class="gds-font-size-xl gds-font-demi gds-space-stack-m">{{ modalTitle }}</div>
          <div v-if="modalMessage && !listItems && !modalLink" class="gds-font-size-xl gds-font-demi gds-space-stack-m">{{ modalMessage }}</div>
          <div v-if="modalMessage && listItems && !modalLink" class="gds-font-size-xl gds-font-demi gds-space-stack-m">{{ modalMessage }}</div>
          <div v-if="modalMessage && modalLink" style="white-space: pre-wrap" class="gds-font-size-xl gds-font-demi gds-space-stack-m">{{ modalMessage }}<a v-bind:href="modalLink">{{ modalLinkName }}</a></div>
          <div v-if="listItems">
            <ul>
              <li v-for="(item) in listItems" :key="item">{{ item }}</li>
            </ul>
          </div>
          <button @click="close(modalName)" type="button" class="gds-button">Okay</button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalEICProducts",
  props: {
    modalTitle: String,
    modalMessage: String,
    visible: Boolean,
    modalName: String,
    modalLink: String,
    modalLinkName: String,
    listItems: Array,
    close: Function,
    mounted: Function,
  },
  mounted() {
    if (this.mounted) {
      this.mounted();
    }
  },
 
  watch: {
    visible(val) {
      if (val && this.selectedFile) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>