import { DefaultChatQueue } from "../environment";
import DataStore from "../store";
import GMPAPI from "./gmpapi";

let chat;

export function InitializeChat() {
  if (typeof logger !== "undefined" && logger) {
    logger.group.logLevel = LogLevel.Warn;
  }
  if (typeof jQuery !== "undefined" && jQuery.webChat) {
    // Initialize external webchat
    chat = jQuery.webChat({
      queue: DefaultChatQueue,
      requireEmail: true,
      // Providing a trigger (that won't be used) is the only way to have chat not immediately visible
      trigger: function () {
        return jQuery("#start-chat-unused");
      }
    });
  }
}

function UpdateChatInfo(queueName) {
  let fullname;
  let account;

  if (DataStore.state.user && DataStore.state.user.userinfo && DataStore.state.user.userinfo.fullName) {
    fullname = DataStore.state.user.userinfo.fullName;
  }
  if (DataStore.state.user && DataStore.state.user.currentAccount && DataStore.state.user.currentAccount.accountNumber) {
    account = DataStore.state.user.currentAccount.accountNumber;
  }
  // If we have logged in data, provide the CSR with email / acct number
  let initialMessage;
  if (fullname && account) {
    initialMessage = "You are connected to logged-in user " + fullname + ", account number " + account;
  } else if (fullname) {
    initialMessage = "You are connected to logged-in user " + fullname;
  } else if (account) {
    initialMessage = "You are connected to logged-in account number " + account;
  }
  // If we have an account selected, pass the account number such that the CSR's system will open oracle to it
  let info;
  if (account) {
    info = account;
  }

  if (chat) {
    chat.settings.initialMessage = initialMessage;
    chat.settings.info = info;
    chat.setQueue(queueName);
  }
}

export function QueueStatus(queueName) {
  // If chat didn't load the first time, try again now
  if (!chat) InitializeChat();
  // If chat still isn't there, give up
  if (!chat) {
    console.error("Chat not initialized");
    return null;
  }
  const status = GMPAPI.GetWebchatStatus(queueName);
  return status;
}

export function ShowChat(queueName) {
  if (chat) {
    UpdateChatInfo(queueName);
    setupMutationObserver();
    chat.showChatbox();
  }
}

function setupMutationObserver() {

  const mutationCallback = (mutationsList, observer) => {
    const HIDE_CLASS = 'hide';
    const MOVE_UP_CLASS = 'move-up';

    const floatingHelpWidget = document.querySelector('.widget-floating-helper');

    for (const mutation of mutationsList) {
      if(mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const isVisible = mutation.target.classList.contains('wc-show') &&
                          !mutation.target.classList.contains('wc-hide');

        if(mutation.target.classList.contains('wc-chatbox')) {
          if (isVisible) {
            addClassToElement(floatingHelpWidget, HIDE_CLASS);
            removeClassFromElement(floatingHelpWidget, MOVE_UP_CLASS);
          } else {
            addClassToElement(floatingHelpWidget, MOVE_UP_CLASS);
            removeClassFromElement(floatingHelpWidget, HIDE_CLASS);
          }
        }

        if(mutation.target.classList.contains('wc-webchat')) {
          if(isVisible) {
            addClassToElement(floatingHelpWidget, MOVE_UP_CLASS);
          } else {
            removeClassFromElement(floatingHelpWidget, MOVE_UP_CLASS);
            removeClassFromElement(floatingHelpWidget, HIDE_CLASS);
          }
        }
      }
  
      if (mutation.type === 'childList') {
        for (const node of mutation.removedNodes) {
          if (node.nodeType === Node.ELEMENT_NODE) {
            removeClassFromElement(floatingHelpWidget, HIDE_CLASS);
            removeClassFromElement(floatingHelpWidget, MOVE_UP_CLASS);
          }
        }
      }
    };
  }

  const chatWindows = document.querySelectorAll('.wc-webchat');
  chatWindows.forEach(chatWindow => {
    let observer = new MutationObserver(mutationCallback);
    observer.observe(chatWindow, { attributes: true, childList: true, subtree: true });
  });
}

function addClassToElement(element, className) {
  if (element) {
      element.classList.add(className);
  }
}

function removeClassFromElement(element, className) {
  if (element) {
      element.classList.remove(className);
  }
}
