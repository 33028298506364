<template>
  <div class="past-due-wizard-container">
    <div v-if="loadState === undefined" class="bill-pay__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Past Due Quick Setup" state="error" phonetext="To set up a payment plan by phone call us at" closepath="/past-due-wizard" />
    <div v-if="loadState === 'complete' && !isPaymentPlanEligible">
      <past-due-not-eligible :arrangementStatus="paymentArrangementStatus" :currentAccount="currentAccount"></past-due-not-eligible>
    </div>
    <div v-if="loadState === 'complete' && isPaymentPlanEligible">
      <past-due-wizard-logged-in
        :paymentArrangementStatus="paymentArrangementStatus"
        :budgetBillingStatus="budgetBillingStatus"
        :isAlreadyRecurringPaymentEnrolled="isAlreadyRecurringPaymentEnrolled"
      ></past-due-wizard-logged-in>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";
import PastDueNotEligible from "./PastDueNotEligible.vue"
import PastDueWizardLoggedIn from "./PastDueWizardLoggedIn.vue"

export default {
  name: "PastDueWizardFlow",
  components: {
    PastDueNotEligible,
    PastDueWizardLoggedIn,
  },
  data() {
    return {
      loadState: undefined,
      paymentArrangementStatus: undefined,
      budgetBillingStatus: undefined,
      isPaymentPlanEligible: undefined,
      isAlreadyRecurringPaymentEnrolled: undefined,
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  created() {
  },
  async mounted() {
    this.loadState = undefined;
    // Kick off async fetches
    try {
      await Promise.all([
        this.GetPaymentArrangement(),
        this.GetBudgetBillingStatus(),
        this.GetRecurring(),
      ]);
      this.loadState = "complete";
    } catch (err) {
      DumpError("PastDueWizard loggedin load error", err);
      this.loadState = "error";
    }
  },
  methods: {
    async GetPaymentArrangement() {
      const paymentArrangementStatus = await GMPAPI.GetPaymentArrangement(this.currentAccount.accountNumber);
      this.paymentArrangementStatus = paymentArrangementStatus;
      this.isPaymentPlanEligible = paymentArrangementStatus.isEligible;
    },
    async GetBudgetBillingStatus() {
      const budgetStatus = await GMPAPI.GetBudgetStatus(this.currentAccount.accountNumber);
      this.budgetBillingStatus = budgetStatus;
    },
    async GetBillingStatus() {
      const billingStatus = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber);
      return billingStatus;
    },
    async GetRecurring() {
      const recurringPaymentStatus = await GMPAPI.GetRecurring(this.currentAccount.accountNumber)
      if (recurringPaymentStatus.code === 1) {
        const legacyRecurringPaymentInfo = await this.GetBillingStatus();
        if (legacyRecurringPaymentInfo.legacyAutoPayEnrolled) {
          this.isAlreadyRecurringPaymentEnrolled = true;
          return;
        }
      }
      if (recurringPaymentStatus.length > 0) {
        this.isAlreadyRecurringPaymentEnrolled = true;
        return;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>