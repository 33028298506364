import { forEach, removeClass } from '../functions';

export default function tabbedDiagram() {
  var tabDiagrams = document.querySelectorAll('.tabbed-diagram');
  forEach(tabDiagrams, function(i, diagram) {
    var tabButtons = diagram.querySelectorAll('.diagram-tab__label');
    var tabs = diagram.querySelectorAll('.diagram-tab');
    jQuery(tabs).hide();
    jQuery(tabs[0]).show();
    jQuery(tabButtons[0]).addClass('active');
    forEach(tabButtons, function(i, button) {
      button.addEventListener('click', function(event) {
        jQuery(tabs).hide();
        jQuery(event.target.getAttribute('data-target')).show();
        jQuery(tabButtons).removeClass('active');
        jQuery(button).addClass('active');
      });
    });
  });
}
