var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "top-card-display__single-card top-card-display__single-card--bill-pay-parent"
    },
    [
      !_vm.currentAccount || _vm.isAdminLite
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "top-card-display__single-card--pay-bill-loggedout"
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "top-banner-card gds-card gds-background-green gds-invert",
                    attrs: { href: "/make-payment" }
                  },
                  [
                    _c("div", { staticClass: "gds-card__contents" }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "home-card__title--small-margin gds-card__title"
                        },
                        [_vm._v("Make Payment")]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "home-card__body--small-margin gds-card__body"
                        },
                        [_vm._v("Pay your balance online")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "gds-card__button gds-button gds-button-circle gds-secondary"
                        },
                        [
                          _c("svg", { staticClass: "gds-icon" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href":
                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ]
                )
              ]
            )
          ])
        : _c("div", [
            _c(
              "div",
              {
                staticClass: "top-card-display__single-card--pay-bill-loggedin"
              },
              [
                _c(
                  "div",
                  { staticClass: "top-banner-card gds-card gds-data-card" },
                  [
                    _c("h3", { staticClass: "gds-card__title" }, [
                      _vm._v("Current Balance")
                    ]),
                    _vm._v(" "),
                    _c("payment-status-display", {
                      attrs: { account: _vm.currentAccount }
                    }),
                    _vm._v(" "),
                    _c(
                      "figure",
                      {
                        staticClass:
                          "card__pay-bill-figure gds-card__data-container gds-flex-container"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card__data-container--flex-container gds-flex-container"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card__data-container--large-text gds-space-inline-s"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("currency")(_vm.amountDue))
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.amountDue < 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "gds-font-size-s gds-align--text-center gds-tag gds-secondary"
                              },
                              [_vm._v("Credit Balance")]
                            )
                          : _vm._e()
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "account_make_payment gds-card__button gds-button gds-round gds-blue",
                        attrs: { href: "/make-payment" }
                      },
                      [_vm._v("Make Payment")]
                    )
                  ],
                  1
                )
              ]
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }