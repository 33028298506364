<template>
  <section class="settings-editor my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l">
    <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
      <h2 class="settings-editor__title gds-space-stack-ml">Name</h2>

      <div class="edit-close-button-combo">
        <button v-if="!editing" @click="editing = true" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Edit</span>
        </button>
        <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Close</span>
        </button>
      </div>
    </div>
    <!-- Display -->
    <div v-if="!editing">
      <div class="gds-font-size-m gds-space-stack-ml">{{userInfo.fullName}}</div>
    </div>
    <!-- Edit -->
    <form data-vv-scope="form" v-if="editing" @submit.prevent="Save()">
      <fieldset class="gds-fieldset">
        <label class="gds-input-field">
          <span class="gds-input-field__label">First Name</span>
          <the-mask
            v-model="editedGivenName"
            v-validate="'required|max:50'"
            maxlength="50"
            name="first name"
            key="first name"
            @input="touched = true; clearError('first name')"
            class="gds-input-field__input"
            type="text"
            :tokens="noNumbersNoSpecialCharacters"
            :mask="'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk'"
            :aria-invalid="errors.first('first name', 'form')?true:false"
          />
        </label>
        <div v-if="errors.first('first name', 'form')" class="validation-error">{{ errors.first("first name", 'form')}}</div>
      </fieldset>
      <fieldset class="gds-fieldset gds-space-stack-m">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Last Name</span>
          <the-mask
            v-model="editedSurname"
            v-validate="'required|max:50'"
            maxlength="50"
            name="last name"
            key="last name"
            @input="touched = true; clearError('last name')"
            class="gds-input-field__input"
            type="text"
            :tokens="noNumbersNoSpecialCharacters"
            :mask="'kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk'"
            :aria-invalid="errors.first('last name', 'form')?true:false"
          />
        </label>
        <div v-if="errors.first('last name', 'form')" class="validation-error">{{ errors.first("last name", 'form')}}</div>
      </fieldset>
      <button type="submit" :disabled="pendingSave || !touched || errors.any()" :class="{ 'gds-loading': pendingSave }" class="gds-button gds-round gds-space-stack-m">
        <span class="gds-button__text">Save</span>
      </button>
      <div v-if="errorSave" class="form-message-box gds-space-stack-m">There was an error updating your name.</div>
    </form>
  </section>
</template>

<script>

import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";

export default {
  name: "EditorProfileName",
  data() {
    return {
      editing: false,
      touched: false,
      pendingSave: false,
      errorSave: false,

      editedGivenName: undefined,
      editedSurname: undefined,

      noNumbersNoSpecialCharacters: {
        "k": {
          pattern: /[a-zA-Z 'ÀÁÂÄÆÃÅĀàáâäæãåāaÈÉÊËĒĖĘèéêëēėęÎÏÍĪĮÌîïíīįìÔÖÒÓŒØŌÕôöòóœøōõŸÿ]/,
        },
      },
      allowedCharacters: {
        "C": {
          pattern: /[0-9a-zA-Z !@#$%^&*()_+~,./?;:'"-=`|ÀÁÂÄÆÃÅĀàáâäæãåāaÈÉÊËĒĖĘèéêëēėęÎÏÍĪĮÌîïíīįìÔÖÒÓŒØŌÕôöòóœøōõŸÿ]/,
        },
      },
    };
  },
  components: {
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      if (this.userInfo) {
        // Copy names over to temporary editable
        this.editedGivenName = this.userInfo.givenName;
        this.editedSurname = this.userInfo.surname;
      }
    },
    async Save() {
      if (await this.$validator.validateAll("form")) {
        this.pendingSave = true;
        this.errorSave = undefined;
        try {
          await GMPAPI.UpdateUser(this.userInfo.username, { givenName: this.editedGivenName, surname: this.editedSurname });
          await this.$store.dispatch("FetchUserInfo");
        } catch (err) {
          DumpError("Update name error", err);
          this.errorSave = true;
        } finally {
          this.pendingSave = false;
        }
      }
    },
    clearError(errorField) {
      this.errors.remove(errorField, 'form')
    }
  },
  watch: {
    // Refresh status whenever the userinfo changes
    async userInfo() {
      this.editing = false;
      this.touched = false;
      this.errorSave = false;
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>