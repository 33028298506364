<template>
  <div>
    <div class="cell date static-size-s">
        <span>{{ getShortWeekdayFromDate(usage.date) }}</span>
        <span>{{ getMonthYearFromDate(usage.date) }}</span>
    </div>



    <div v-if="isNetMetered" class="usage-detail__data-series cell grow-basis-10" data-series="totalEnergyUsed">
      <div class="usage-detail__measured-value">{{ usage.totalEnergyUsed | numberCommaSingleDecimal }} <span class="unit"> kWh</span></div>
      <Usage-Consumption-Bar-CSS :series1="usage.totalEnergyUsed" :maxUsageValue="maxUsageValue" />
    </div>
    <div v-if="isNetMetered" class="usage-detail__data-series cell grow-basis-10" data-series="generation">
      <div class="usage-detail__measured-value">{{ usage.generation | numberCommaSingleDecimal }} <span class="unit"> kWh</span></div>
      <Usage-Consumption-Bar-CSS :series1="usage.generation" :maxUsageValue="maxUsageValue" />
    </div>
    <div v-if="!isNetMetered && !hasTOU" class="usage-detail__data-series cell grow-basis-20" data-series="consumedTotal">
      <div class="usage-detail__measured-value">{{ usage.consumedTotal | numberCommaSingleDecimal }} <span class="unit"> kWh</span></div>
      <Usage-Consumption-Bar-CSS :series1="usage.consumedTotal" :maxUsageValue="maxUsageValue" />
    </div>
    <div v-if="!isNetMetered && hasTOU" class="usage-detail__data-series cell grow-basis-10" data-series="consumedTOU">
      <div class="usage-detail__measured-value">{{ usage.consumedTotal | numberCommaSingleDecimal }} <span class="unit"> kWh</span></div>
      <span class="sub-series">{{ getOnPeakUsage | numberCommaSingleDecimal }} kWh on-peak, {{ getOffPeakUsage | numberCommaSingleDecimal }} kWh off-peak</span>
      <Usage-Consumption-Bar-CSS :series1="getOnPeakUsage" :series2="getOffPeakUsage" :maxUsageValue="maxUsageValue" />
    </div>

    <div class="cell conditions static-size-m">
      <img v-if="usage.weatherIconImageUrl && usage.weatherIconImageUrl != ''" class="gds-icon"
        :src="weatherImagePath + usage.weatherIconImageUrl" alt="Weather Icon" />
        <div :class="[usageTableCellClass, 'widget-daily-consumption__temperature']">
         {{ getTemperature(usage).avg ? getTemperature(usage).avg + '\u00B0F' : "" }}
      </div>
    </div>
    
  </div>
</template>

<script>
import { format } from "date-fns";
import usageTableUtil from "../../../mixins/UsageTableUtil";
import UsageConsumptionBarCSS from "../usageshared/UsageConsumptionBarCSS";

export default {
  name: 'WidgetDailyConsumptionTableRow',
  mixins: [ usageTableUtil ],
  components: {
    UsageConsumptionBarCSS
  },
  props: {
    usage: Object,
    isNetMetered: Boolean,
    maxUsageValue: Number,
    hasGenerationRecordsPresent: Boolean
  },
  async mounted() {
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    hasTOU() {
      return (
        (this.usage.hasOwnProperty("onPeak") ||
        this.usage.hasOwnProperty("offPeak")) &&
        this.usage.hasOwnProperty("consumedTotal")
      );
    },
    getOnPeakUsage() {
      return this.usage.onPeak ? this.usage.onPeak : 0;
    },
    getOffPeakUsage() {
      return this.usage.offPeak ? this.usage.offPeak : 0;
    },
  },
  data() {
    return {
      monthSelected: undefined,
      usageTableCellClass: undefined,
      usageTableEmptyCellClass: undefined
    }
  },
  methods: {
    getDayOfWeek(date) {
      return format(date, 'EEEE');
    },
    formatDate(date) {
      return format(date, 'MMMM d, yyyy');
    },
    getMonthYearFromDate(date) {
      const dateObj = new Date(date);
      return dateObj.getMonth() + 1 + "/" + dateObj.getDate();
    },
    getMonthYearFromDate(date) {
      const dateObj = new Date(date);
      return dateObj.getMonth() + 1 + "/" + dateObj.getDate();
    },
    getShortWeekdayFromDate(date) {
      return date.toLocaleString('en-US', { weekday: 'short' }).toUpperCase();
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>