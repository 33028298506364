var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-detail__component-container" }, [
    _c("div", { staticClass: "usage-detail__usage-performance" }, [
      _c(
        "section",
        { staticClass: "gds-space-stack-l" },
        [
          _c("UsageDetailControls", {
            attrs: {
              selectedDateWidth: "300px",
              datePickerDateType: "month",
              showGoToTodayButton: true,
              summaryText: _vm.summaryText,
              selectedDate: _vm.selectedDate,
              datePickerDisabledDates: _vm.disabledDates,
              selectedDateSubText: _vm.selectedDateSubText,
              showJumpToDateButton: _vm.showJumpToDateButton,
              showDateForwardsButton: _vm.showDateForwardsButton,
              showDateBackwardsButton: _vm.showDateBackwardsButton,
              onGoToTodayClicked: _vm.onGoToTodayClicked,
              onDateForwardsClicked: _vm.onDateForwardsClicked,
              onSelectedDateChanged: _vm.onSelectedDateChanged,
              onDateBackwardsClicked: _vm.onDateBackwardsClicked,
              disableAllDateNavControls: !_vm.loadState,
              disableDateNavigationButtonBack:
                _vm.disableDateNavigationButtonBack,
              disableDateNavigationButtonForward:
                _vm.disableDateNavigationButtonForward
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      !_vm.loadState
        ? _c("div", {
            staticClass: "table-loading",
            staticStyle: { "min-height": "400px" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "usage-page--interior-status-container" },
        [
          _vm.loadState === "error"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "error" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "maintenance"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "maintenance" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "unavailable"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "unavailable",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "empty"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "nodata",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _vm.isMobbileView || _vm.isTabletView
              ? _c("section", { staticClass: "gds-space-stack-l" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "usage-detail__usage-performance__chart-legend-wrapper"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "usage-detail__usage-performance__chart-legend-items"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "usage-detail__usage-performance__chart-legend-item"
                            },
                            [
                              _c("span", { staticClass: "circle current" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "usage-detail__usage-performance__chart-legend-item__label-and-value"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__label"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.legendCurrentConsumptionLabel
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__value"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.legendCurrentConsumptionValue
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "usage-detail__usage-performance__chart-legend-item"
                            },
                            [
                              _c("span", { staticClass: "circle previous" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "usage-detail__usage-performance__chart-legend-item__label-and-value"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__label"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.legendPreviousConsumptionLabel
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "usage-detail__usage-performance__chart-legend-item__value"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.legendPreviousConsumptionValue
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("section", { staticClass: "gds-space-stack-l" }, [
              _vm.loadState === "complete"
                ? _c(
                    "div",
                    { staticClass: "my-account__usage-graph-wrapper" },
                    [
                      _c("div", {
                        ref: "currentUsageChartRef",
                        staticClass: "current-usage-chart-ref"
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass:
                  "usage-detail__usage-performance__summary-bottom gds-space-stack-l"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "usage-detail__usage-performance__summary-text-wrapper"
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "usage-detail__usage-performance__gds-icon-wrapper"
                      },
                      [
                        _c("svg", { staticClass: "gds-icon" }, [
                          _c("use", {
                            attrs: {
                              "xlink:href":
                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                            }
                          })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", {
                      staticClass:
                        "usage-detail__usage-performance__summary-text",
                      domProps: { innerHTML: _vm._s(_vm.summaryText) }
                    })
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }