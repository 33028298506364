<template>
  <div class="make-payment-container">
    <div v-if="loadState === undefined" class="bill-pay__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Stop Service" state="error" phonetext="To stop service by phone call us at" closepath="/stop-service" />
    <flow-error
      v-if="loadState === 'unavailable'"
      name="Stop Service"
      state="unavailable"
      phonetext="For assistance, call GMP at"
      closepath="/stop-service"
      img="/wp-content/themes/gmptwentynineteen/assets/images/stop-not-supported.svg"
    />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 -->
      <section id="stop-slat-1" class="payment-amount bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="bill-pay-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Stop Date</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">{{stopDate | dateFull}}</div>
                <button @click="gotoPage(1)" class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 1" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row">
                <div class="gds-font-size-medium gds-space-stack-l">
                  When would you like to stop your service at {{currentAccount.address | addressFormat({ separator: ", " })}}?
                  <br />Service will be stopped at 8:00 AM on the stop date.
                </div>
                <div class="bill-pay-slap__inner-row">
                  <div class="my-account-billing-report__filtration-date-col gds-space-inline-m">
                    <fieldset class="gds-fieldset">
                      <div class="gds-input-field__label">Stop Date</div>
                      <div class="gds-flex-container gds-flex-container--left">
                        <div class="my-account-billing-report__filtration-date-box gds-space-inline-m">
                          <div @click="$refs.datePicker.showCalendar()" class="my-account-billing-report__filtration-date-icon">
                            <svg class="gds-icon">
                              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                            </svg>
                          </div>
                          <datepicker
                            ref="datePicker"
                            v-model="stopDate"
                            format="MMMM d, yyyy"
                            :disabled-dates="disabledDates"
                            input-class="export-data__date my-account-billing-report__filtration-date-text"
                          />
                        </div>
                        <div>at 8:00 AM</div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="gds-text-blue gds-font-size-s gds-space-stack-l">Choose a date 1 to 30 days in the future.</div>
                  <button @click="gotoPage(2)" :disabled="!stopDate" class="gds-button gds-compact">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 -->
      <section id="stop-slat-2" class="payment-method bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="bill-pay-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Update Mailing Address</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="mailingAddress" class="gds-body-normal gds-space-inline-m">{{mailingAddress.addressLine1}}</div>
                <button @click="gotoPage(2)" class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 2" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row">
                <div class="gds-body-small gds-space-stack-l">Where will you be receiving mail after {{stopDate | dateFull}}?</div>
                <div class="ssmove__mailing-address-wrap">
                  <inline-mailing-address ref="inlineAddress" :address="mailingAddress" @save="InlineSaved" />
                </div>
                <div v-if="errorVerify" class="form-message-box gds-space-stack-l">{{errorVerify}}</div>
                <button
                  @click="Verify()"
                  :disabled="pendingVerify"
                  :class="{ 'gds-loading': pendingVerify }"
                  type="button"
                  class="bill-pay__method-next-btn gds-button gds-compact gds-space-stack-l"
                >
                  <span class="gds-button__text">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 3 -->
      <section id="stop-slat-3" class="review-submit bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div class="bill-pay-slat__number-with-circle">
                <span>3</span>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Review &amp; Submit</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage < 3" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row">
                <div
                  class="gds-font-size-medium gds-space-stack-l"
                >Please review the information you’ve entered and submit. One of our customer service representatives will review your request.</div>
                <div class="gds-font-size-m gds-font-demi gds-space-stack-s">Service Address</div>
                <div class="gds-font-size-l gds-space-stack-l">{{currentAccount.address | addressFormat({ separator: ", " })}}</div>

                <div class="gds-font-size-m gds-font-demi gds-space-stack-s">Requested Stop Date</div>
                <div class="gds-font-size-l gds-space-stack-l">{{stopDate | dateFull}} at 8:00AM</div>

                <div class="gds-font-size-m gds-font-demi gds-space-stack-s">Mailing address as of {{stopDate | dateFull}}</div>
                <div class="gds-font-size-l gds-space-stack-l gds-linebreaks">{{mailingAddress | addressFormat({ mailing: true })}}</div>

                <div v-if="errorSubmit" class="form-message-box gds-space-stack-l">{{errorSubmit}}</div>
                <button @click="submitStop()" :disabled="pendingSubmit" :class="{ 'gds-loading': pendingSubmit }" type="submit" class="gds-button gds-compact">
                  <span class="gds-button__text">Submit Request</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- #main -->
</template>

<script>
import { addDays } from "date-fns";
import Datepicker from 'vuejs-datepicker';

import InlineMailingAddress from "../myaccount/editors/InlineMailingAddress";
import GMPAPI from "../../services/gmpapi";
import { ToServerDateTruncate } from "../../utilities";
import { GMPPhoneNumber } from "../../environment";
import { DumpError } from "../../utilities";

export default {
  name: "StopServiceFlow",
  components: {
    Datepicker,
    InlineMailingAddress,
  },
  data() {
    return {
      stopDate: undefined,
      disabledDates: {
        days: [0, 6],
        to: new Date(),
        from: addDays(new Date(), 30),
      },
      mailingAddress: undefined,

      pendingVerify: false,
      errorVerify: undefined,

      pendingSubmit: false,
      errorSubmit: undefined,

      loadState: undefined,

      GMPPhoneNumber,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
  },
  created() {
  },
  async mounted() {
    this.loadState = undefined;
    try {
      await Promise.all([
        this.FetchHolidays(),
        this.FetchEligibility(),
        this.FetchMailing(),
        this.FetchPhones(),
      ]);
      this.loadState = "complete";
    } catch (err) {
      if (err && err.message === "Ineligible for stoppage") {
        this.loadState = "unavailable";
      } else {
        DumpError("Stop service load error", err);
        this.loadState = "error";
      }
    }
  },
  methods: {
    /** Go to a page number without validation */
    gotoPage(page) {
      this.$router.push({ path: "/stop/" + page });
      this.slatFocus(page);
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        const focuser = this.$refs["focuser" + page];
        if (focuser && focuser.$el) {
          if (focuser.$el.focus) focuser.$el.focus();
        } else if (focuser && Array.isArray(focuser) && focuser.length >= 1) {
          // Page 2 has array of wallets
          if (focuser[0].focus) focuser[0].focus();
        } else if (focuser) {
          if (focuser.focus) focuser.focus();
        }
      }, 100);
    },
    /** Tell the inline editor to validate and verify */
    async Verify() {
      this.pendingVerify = true;
      this.errorVerify = undefined;
      try {
        await this.$refs.inlineAddress.Verify();
      } catch (err) {
        DumpError("Verify address error", err);
        this.errorVerify = "There was an unexpected error verifying your mailing address.";
      } finally {
        this.pendingVerify = false;
      }
    },
    /** Gets triggered by successful verification on the inline editor */
    InlineSaved(address) {
      this.mailingAddress = address;
      this.gotoPage(3);
    },
    async submitStop() {
      this.errorSubmit = undefined;
      this.pendingSubmit = true;
      try {
        const body = {
          actionName: "STOP",
          actionDate: ToServerDateTruncate(this.stopDate),
          firstName: this.currentAccount.firstName,
          lastName: this.currentAccount.lastName,
          address: this.mailingAddress,
          phones: this.phones,
        };
        await GMPAPI.ScheduleStopService(this.currentAccount.accountNumber, body);
        this.$store.commit("setStoppageDate", this.stopDate);
        this.$router.push({ path: "/complete" });
      } catch (err) {
        DumpError("Stop service error", err);
        this.errorSubmit = "There was an unexpected error scheduling your service stoppage.";
      } finally {
        // Either way, turn off the loading indicator
        this.pendingSubmit = false;
      }
    },
    async FetchHolidays() {
      const holidays = await GMPAPI.GetHolidays();
      const dates = holidays.map(item => new Date(item.date));
      this.disabledDates = {
        days: [0, 6],
        to: new Date(),
        from: addDays(new Date(), 30),
        dates,
      };
    },
    async FetchEligibility() {
      const eligible = await GMPAPI.GetStopEligible(this.currentAccount.accountNumber);
      const stopItem = eligible.find(item => item.action === "STOP");
      if (stopItem && stopItem.isEligible) {
      } else {
        throw new Error("Ineligible for stoppage");
      }
    },
    async FetchMailing() {
      this.mailingAddress = await GMPAPI.GetMailingAddress(this.currentAccount.accountNumber)
    },
    async FetchPhones() {
      const result = await GMPAPI.GetPhoneNumbers(this.currentAccount.accountNumber);
      this.phones = result.phones;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>