var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "slat-message-fade" } }, [
        _c(
          "div",
          { staticClass: "gds-space-stack-l scheduled-payments-list" },
          [
            _c(
              "div",
              {
                class:
                  "form-message-box form-message-box--" +
                  _vm.bannerColor +
                  " form-message-box--full-width gds-space-stack-s gds-flex-container gds-flex-container--space-between"
              },
              [
                _c("div", { staticClass: "form-message-box--left" }, [
                  _c("div", { staticClass: "gds-body-normal" }, [
                    _vm._v(_vm._s(_vm.message) + " \n            "),
                    _vm.linkOneText.length
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "gds-link gds-link--bold cursorPointer",
                            on: { click: _vm.processLinkOneClick }
                          },
                          [_vm._v(_vm._s(_vm.linkOneText))]
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.messageTwo) +
                        "\n            "
                    ),
                    _vm.linkTwoText.length
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "gds-link gds-link--bold cursorPointer",
                            on: { click: _vm.processLinkTwoClick }
                          },
                          [_vm._v(_vm._s(_vm.linkTwoText) + ".")]
                        )
                      : _vm._e()
                  ])
                ])
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }