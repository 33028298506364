import DataStore from "./store";
import { differenceInMinutes, parseISO } from "date-fns";
import { DumpError } from "./utilities";

/** Check every x seconds for token expiration */
const EXPIRING_TOKEN_CHECK_INTERVAL_MIN = 1;
/** Refresh the auth token if it will expire in x minutes or less (rounded) */
const EXPIRING_TOKEN_AGE_MIN = 5;
/** Refresh all account data every x minutes */
const ACCOUNT_REFRESH_INTERVAL_MIN = 60;

/** Logout an OTP login if they aren't on an OTP page */
export async function LogoutIfNeeded() {
  const base = location.pathname.split("/")[1].toLowerCase();
  if (base !== "make-payment" && base !== "report-outage") {
    if (DataStore.state.user && DataStore.state.user.userinfo && DataStore.state.user.userinfo.isOTP) {
      await DataStore.dispatch("LogOut");
    }
  }
}

/** Start checking periodically to see if the auth token is near expiring */
export async function BeginRefreshTimer() {
  setInterval(RefreshTokenIfNeeded, EXPIRING_TOKEN_CHECK_INTERVAL_MIN * 60000);
  await RefreshTokenIfNeeded();
}

/** If API token is near expiry, refresh it */
export async function RefreshTokenIfNeeded() {
  if (DataStore.state.user.apitokenExpires && differenceInMinutes(parseISO(DataStore.state.user.apitokenExpires), new Date()) <= EXPIRING_TOKEN_AGE_MIN) {
    try {
      await DataStore.dispatch("LoginWithRefresh");
    } catch (err) {
      DumpError("Refresh token error", err);
      await DataStore.dispatch("LogOut");
    }
  }
}

/** The account info could be stale. If it hasn't been refreshed lately, do so. */
export async function RefreshAccountIfNeeded() {
  let lastRefresh = undefined;
  if (DataStore.state.user.lastUserRefresh) lastRefresh = parseISO(DataStore.state.user.lastUserRefresh);
  if (lastRefresh === undefined || differenceInMinutes(new Date(), lastRefresh) >= ACCOUNT_REFRESH_INTERVAL_MIN) {
    try {
      await DataStore.dispatch("FetchUserInfo");
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        // Auth is no longer valid - try to refresh it even though RefreshTokenIfNeeded should have handled it
        if (DataStore.state.user.refreshtoken) {
          try {
            await DataStore.dispatch("LoginWithRefresh");
            await DataStore.dispatch("FetchUserInfo");
          } catch (err2) {
            DumpError("Refresh account/token error", err2);
            await DataStore.dispatch("LogOut");
          }
        } else {
          // There is no refresh token, nothing we can do
          // Note that for logged out pageload, we will always call /current and then /logout, both failing
          // This is because we can't tell if there's a cookie so we have to try it, then if it fails we
          // log out in case there is an old cookie we need to clear. We could ditch this if we can ever
          // move away from cookie-based auth.
          await DataStore.dispatch("LogOut");
        }
      } else {
        DumpError("Refresh account error", err);
      }
    }
  }
}

/** When coming into a URL with the account number specified in query string, switch to it if possible */
export async function SwitchAccountFromURL() {
  if (DataStore.state.user.userinfo) {
    const query = window.location.search.substring(1);
    const params = new URLSearchParams(query);
    const accountNumber = params.get("account");
    if (accountNumber) {
      // account= was found in query string, see if it's one of ours
      const found = DataStore.state.user.accounts.find(item => item.accountNumber === accountNumber);
      if (found) {
        // Switch to that account
        DataStore.commit("setCurrentAccount", found);
        // Remove the account query string and replace the URL
        params.delete("account");
        let query = params.toString();
        if (query) query = "?" + query;
        const truncated = window.location.origin + window.location.pathname + query + window.location.hash;
        window.history.replaceState(null, "", truncated);
      } else {
        window.location.href = "/not-authorized";
      }
    }
  }
}