import Highcharts from "highcharts";

export function DrawChart(renderingDiv, percentages) {
  const timesOfDay = [
    'Daybreak',
    'Late Morning',
    'Afternoon',
    'Evening',
    'Night',
    'Late Night'
  ];
  const intervals = [
    '4am - 8am',
    '8am - 12pm',
    '12pm - 4pm',
    '4pm - 8pm',
    '8pm - 12am',
    '12am - 4am'
  ];
  const data = percentages;
  
  Highcharts.chart(renderingDiv, {
    chart: {
      type: 'column',
      height: 350,
      marginTop: 50,
      style: {
        fontFamily: 'MaisonNeue, Helvetica, sans-serif',
        fontWeight: '600'
      },
      events: {
        load: function () {
          var chart = this;
          var maxColumn = 0;
          var maxIndex = 0;
          // Find the index of the tallest column
          chart.series[0].points.forEach(function (point, index) {
            if (point.y > maxColumn) {
              maxColumn = point.y;
              maxIndex = index;
            }
          });
          // Update the color of the tallest column
          chart.series[0].points[maxIndex].update({
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#fd9b30"],
                [1, "#fe812f"],
              ]
            }
          });
        }
      }
    },
    title: {
      text: null,
    },
    credits: true,
    tooltip: {
      enabled: false
    },
    xAxis: {
      categories: timesOfDay,
      lineWidth: 0,
      labels: {
        useHTML: true,
        formatter: function() {
          // Position index of current label
          const index = this.pos;
          const timesOfDayStyle = (
            `font-size: 1rem; 
            color: #202020; 
            font-weight: 400; 
            display: flex; 
            justify-content: center;`
            )
          const intervalsStyle = (
            `font-size:.875rem; 
            color:#6F7383; 
            font-weight:600; 
            display:flex; 
            justify-content:center;`
            )
          return `<tspan style="${timesOfDayStyle}">${timesOfDay[index]}</tspan>
          <br>
          <tspan style="${intervalsStyle}">${intervals[index]}</tspan>`;
        },
        y: 40
      }
    },
    yAxis: {
      labels: { enabled: false },
      title: { enabled: false },
      gridLineWidth: 0
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderRadius: 4,
        dataLabels: {
          crop: false,
          overflow: 'allow',
          zIndex: 100,
          y: -10,
          enabled: true,
          format: '{y}%',
          style: {
            fontSize: '2rem',
            fontWeight: '800'
          }
        }
      }
    },
    series: [{
      name: 'Time of Day Usage',
      showInLegend: false,
      data: data,
      groupPadding: 0.05,
      color: '#BFBFBF',
      credits: true,
      states: {
        hover: {
          enabled: false,
        }
      }
    },],
    
    responsive: {
      rules: [
      {
        condition: {
          maxWidth: 575
        },
        chartOptions: {
          xAxis: {
            labels: {
              rotation: 55,
              useHTML: false,
              formatter: function() {
                const index = this.pos;
                const timesOfDayStyle = (
                  `font-size: .875rem; 
                  color: #202020; 
                  font-weight: 800; 
                  display: flex; 
                  justify-content: center;`
                  )
                const intervalsStyle = (
                  `font-size: .75rem; 
                  color: #6F7383; 
                  font-weight: 600; 
                  display: flex; 
                  justify-content: center;`
                  )
                return `<tspan style="${timesOfDayStyle}">${timesOfDay[index]}</tspan>
                <br>
                <tspan style="${intervalsStyle}">${intervals[index]}</tspan>`;
              },
              y: 20
            }
          },
          plotOptions: {
            column: {
              pointPadding: 0.025,
              dataLabels: {
                zIndex: 80,
                style: {
                  fontSize: '1.65rem',
                  fontWeight: '400'
                }
              }
            }
          }
        }
      },
      {
        condition: {
          maxWidth: 300
        },
        chartOptions: {
          chart: {
            marginTop: 25
          },
          xAxis: {
            labels: {
              rotation: 65,
              y: 15
            }
          },
          plotOptions: {
            column: {
              dataLabels: {
                y: -5,
                style: {
                  fontSize: '1.15rem'
                }
              }
            }
          }
        }
      }]
    }
  });
}