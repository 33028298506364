import { forEach, toggleClass } from '../functions';

export default function toggle() {
  var toggleElements = document.querySelectorAll('[data-action="toggleClass"]');
  forEach(toggleElements, function(index, toggleElement) {
    addToggleListener(toggleElement);
  });
}

function addToggleListener(element) {
  return element.addEventListener('click', function(event) {
    event.preventDefault();
    var toggleTarget = document.querySelectorAll(event.target.getAttribute('data-target'));
    toggleClass(toggleTarget[0], event.target.getAttribute('data-classname'));
  });
}
