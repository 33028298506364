<template>
  <div class="gmp-devices-page__devices-list__device">
    <template v-if="device && device.deviceId">
      <div v-if="isDesktopView">
        <div class="devices-card devices-card-desktop border-grey">
          <router-link
            :to="`/devices/${device.deviceId}`"
          >
            <div class="devices-card-desktop__image-container background-blue-gradient gds-flex-container gds-flex-container--bottom">
              <img
                class="width-full"
                :src="device.imageURL"
                alt="Emporia EV Charger image"
              >
            </div>
            <section class="devices-card-desktop__text-container width-full padding-l">
              <div class="color-black gds-font-size-l gds-font-medium">{{ device.deviceName }}</div>
              <div class="gds-font-size-s gds-text-grey gds-space-stack-s">{{ device.deviceType }}</div>
              <div
                v-if="statusIcon"
                class="status-icon-container"
              >
                <template v-if="statusIcon.isEventInProgress">
                  <EventInProgressIndicator />
                </template>
                <span v-if="statusIcon.isImage">
                  <img
                    alt="Status Icon"
                    class="status-icon"
                    :src="statusIcon.url"
                  >
                </span>
                <span class="gds-text-grey devices-font-size-xs devices-font-uppercase">{{ device.statusMessage.message }}</span>
              </div>
            </section>
          </router-link>
        </div>
      </div>
      <div v-if="!isDesktopView">
        <router-link
          :to="`/devices/${device.deviceId}`"
        >
          <div class="devices-card devices-card-mobile gds-flex-container border-grey">
            <section class="devices-card-mobile__image-container devices-flex-1 background-blue-gradient padding-m">
              <img
                class="width-full"
                :src="device.imageURL"
                alt="Emporia EV Charger image"
              >
            </section>
            <section class="devices-card-mobile__text-container devices-flex-5 padding-m">
              <div class="color-black gds-font-size-l gds-font-medium">{{ device.deviceName }}</div>
              <div class="gds-font-size-s gds-text-grey gds-space-stack-s">{{ device.deviceType }}</div>
              <div
                v-if="statusIcon"
                class="status-icon-container"
              >
                <template v-if="statusIcon.isEventInProgress">
                  <EventInProgressIndicator />
                </template>
                <span v-if="statusIcon.isImage">
                  <img
                    alt="Status Icon"
                    class="status-icon"
                    :src="statusIcon.url"
                  >
                </span>
                <span class="gds-text-grey devices-font-size-xs devices-font-uppercase">{{ device.statusMessage.message }}</span>
              </div>
            </section>
          </div>
        </router-link>
      </div>
    </template>
    <template v-if="!device || !device.deviceId">
      <div v-if="isDesktopView">
        <router-link to="/devices/add-device">
          <section class="devices-card devices-card-desktop gds-flex-container gds-flex-container--column border-grey padding-l">
            <div class="gds-button-circle gds-flex-container gds-font-size-xxl gds-space-stack-m add-icon">
              <span class="color-black plus">+</span>
            </div>
            <div class="color-black gds-font-size-l gds-font-medium gds-space-stack-m">Add a Device</div>
            <div class="gds-font-size-m gds-text-grey gds-align--text-center devices-width-60">
              Connect a battery or EV charger you own, or get one from GMP.
            </div>
          </section>
        </router-link>
      </div>
      <div v-if="!isDesktopView">
        <router-link to="/devices/add-device">
          <section class="color-blackdevices-card devices-card-mobile gds-flex-container padding-l">
            <div class="gds-button-circle gds-flex-container gds-font-size-xxl gds-space-inline-m add-icon">
              <span class="plus">+</span>
            </div>
            <div class="color-black gds-font-size-l gds-font-medium">Add a Device</div>
          </section>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../../mixins/MediaQueryMixin';
import EventInProgressIndicator from '../../../shared/components/EventInProgressIndicator.vue';

export default {
  name: 'Device',
  components: {
    EventInProgressIndicator,
  },
  mixins: [
    MediaQueryMixin,
  ],
  props: {
    device: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      statusIcon: undefined,
    };
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  async mounted() {
    this.statusIcon = this.getStatusIcon(this.device);
  },
  methods: {
    getStatusIcon(device) {
      let config;
      const imagePathBase = '/wp-content/themes/gmptwentynineteen/assets/images/devices/';
      const statusMessage = device && device.statusMessage ? device && device.statusMessage : {};

      switch (statusMessage.type) {
      case 'event_in_progress':
        config = {
          isEventInProgress: true,
        };
        break;
      case 'device_offline':
        config = {
          isImage: true,
          url: `${imagePathBase}icon-devices-status-issue.svg`,
        };
        break;
      case 'event_scheduled':
        config = {
          isImage: true,
          url: `${imagePathBase}icon-devices-status-peak-event.svg`,
        };
        break;
      default:
        config = undefined;
      }

      return config;
    },
  },
};
</script>
