export default {
  data() {
    return {
      TOTAL_ENERGY_USED: 'totalEnergyUsed',
      CONSUMPTION: 'consumedTotal',
      GENERATION: 'generation',
      GENERATION_AND_TIME_OF_USE: 'generation-and-tou',
      GENERATION_AND_CONSUMPTION: 'generation-and-consumption',
      RETURNED_GENERATION: 'returnedGeneration',
      OFF_PEAK: 'offPeak',
      ON_PEAK: 'onPeak',
      TIME_OF_USE: 'tou',
      weatherImagePath: '/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/weather/',
      sunnyIcon: 'weather-sun.svg',
      partlyCloudyIcon: 'weather-partly-cloudy.svg',
      cloudyIcon: 'weather-cloudy.svg',
      moonIcon: 'weather-clear-moon.svg',
      cloudyMoonIcon: 'weather-partly-cloudy-moon.svg'
    }
  },
  computed: {
    determineBarType() {
      return (usageRec) => {
        if (usageRec.hasOwnProperty(this.GENERATION) &&
            (usageRec.hasOwnProperty(this.OFF_PEAK) || usageRec.hasOwnProperty(this.ON_PEAK))) {
          return this.GENERATION_AND_TIME_OF_USE;
        }
        if (usageRec.hasOwnProperty(this.GENERATION) && usageRec.hasOwnProperty(this.CONSUMPTION)) {
          return this.GENERATION_AND_CONSUMPTION;
        }
        if (usageRec.hasOwnProperty(this.GENERATION)) {
          return this.GENERATION;
        }
        if (usageRec.hasOwnProperty(this.OFF_PEAK || usageRec.hasOwnProperty(this.ON_PEAK))) {
          return this.TIME_OF_USE;
        }
        return this.CONSUMPTION;
      }
    }
  },
  methods: {
    netUsage(returnedEnergy,usedEnergy) {
        const returnedAmount = returnedEnergy - usedEnergy;
        const floorOfReturnedAmount = Math.floor(returnedAmount);
        let sign;
        if (floorOfReturnedAmount === 0) {
          sign = '';
        } else if (floorOfReturnedAmount > 0) {
          sign = '+';
        } else {
          sign = '';
        }
        return sign + floorOfReturnedAmount;
    },
    roundAmountSingleDecimal(amount) {
      if (amount === undefined) { return 0; }
      return parseFloat(amount.toFixed(1));
    },
    hasGeneration(usageRecord) {
      return this.hasGenerationRecordsPresent &&
        this.hasGenerationRecordProperties(usageRecord) &&
        this.roundAmountSingleDecimal(usageRecord.generation) > 0;
    },
    hasGenerationReturned(usageRec) {
      return usageRec.hasOwnProperty(this.RETURNED_GENERATION);
    },
    hasTou(usageRec) {
      return usageRec.hasOwnProperty(this.TIME_OF_USE) || usageRec.hasOwnProperty(this.GENERATION_AND_TIME_OF_USE);
    },
    hasConsumption(usageRec) {
      return usageRec.hasOwnProperty(this.CONSUMPTION);
    },
    hasGenerationRecordProperties(usageRec) {
      return usageRec.hasOwnProperty(this.GENERATION) || usageRec.hasOwnProperty(this.GENERATION_AND_TIME_OF_USE);
    }, 
    checkForUsageRecordType(usageData, recordType) {
      if (!usageData) return;

      const usageTypes = this.getUniqueUsageTypes(usageData);
      return usageTypes.includes(recordType);
    },
    getUniqueUsageTypes(usageData) {
      const uniqueProperties = new Set();

      usageData.forEach(obj => {
        if (obj.hasOwnProperty(this.GENERATION)) {
          uniqueProperties.add(this.GENERATION);
        }
        if (obj.hasOwnProperty(this.RETURNED_GENERATION)) {
          uniqueProperties.add(this.RETURNED_GENERATION);
        }
        if (obj.hasOwnProperty(this.CONSUMPTION)) {
          uniqueProperties.add(this.CONSUMPTION);
        }
        if (obj.hasOwnProperty(this.ON_PEAK)) {
          uniqueProperties.add(this.ON_PEAK);
        }
      });

      return Array.from(uniqueProperties);
    },
    findMaxUsageValue(dailyUsageData) {
      let usageTypes = [];
      
      if (this.checkForUsageRecordType(dailyUsageData, this.TOTAL_ENERGY_USED)) {
        usageTypes.push(this.TOTAL_ENERGY_USED);
      } else if (
        this.checkForUsageRecordType(dailyUsageData, this.CONSUMPTION) ||
        this.checkForUsageRecordType(dailyUsageData, this.ON_PEAK) ||
        this.checkForUsageRecordType(dailyUsageData, this.OFF_PEAK)
      ) {
        usageTypes.push(this.CONSUMPTION);
      }
      
      if (this.checkForUsageRecordType(dailyUsageData, this.GENERATION)) {
        usageTypes.push(this.GENERATION);
      }

      if (this.checkForUsageRecordType(dailyUsageData, this.RETURNED_GENERATION)) {
        usageTypes.push(this.RETURNED_GENERATION);
      }
      
      const size = dailyUsageData.reduce((max, item) => {
        for (let type of usageTypes) {
          if (item[type]) {
            max = Math.max(max, item[type]);
          }
        }
        return max;
      }, -Infinity);
      
      return size;
    },    
    getTemperature(usageRec) {
      const average = usageRec.temperature ? Math.floor(usageRec.temperature) : Math.floor(usageRec.darkskyTemperature);

      const returnObject = {};
      if (average) { returnObject.avg = average; }
      if (usageRec.temperatureLow) { returnObject.low = Math.floor(usageRec.temperatureLow); }
      if (usageRec.temperatureHigh) { returnObject.high = Math.floor(usageRec.temperatureHigh); }

      return returnObject;
    },
    getWeatherIcon(weatherIcon, isNight) {
      isNight = isNight || false;

      if (weatherIcon && weatherIcon.includes('clear') || weatherIcon === 'wind') {
        if (isNight) {
          return this.moonIcon;
        }
        return this.sunnyIcon;
      }
      if (weatherIcon && weatherIcon.includes('partly')) {
        if (isNight) {
          return this.cloudyMoonIcon;
        }
        return this.partlyCloudyIcon;
      }
      return this.cloudyIcon;
    },
    determineGenerationAmount(usage) {
      if (!usage.generation  && !usage.returnedGeneration) {
        return usage.generation;
      } else if (usage.generation != null) {
          return usage.generation;
      } else if (usage.returnedGeneration != null) {
          return usage.returnedGeneration;
      } else {
        return null;
      } 
    }  
  }
}; 