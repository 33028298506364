import { format, addDays, subDays, addMonths, subMonths, endOfMonth, startOfMonth, isWithinInterval, parseISO, isBefore, isAfter, isToday, isFuture, getMonth } from "date-fns";
import { DumpError, ToServerDateTruncate } from "../../utilities";
import GMPAPI from "../../services/gmpapi";
export default {
  data() {
    return {
      //Override these in your component as necessary
      dateControlsTouched: false,
      selectedEndDate: undefined,
      selectedStartDate: undefined,
      disabledDates: undefined,
      disableAllDateNavControls: false,
      disableDateNavigationButtonBack: false,
      disableDateNavigationButtonForward: false,
      showJumpToDateButton: true,
      showGoToTodayButton: true,
      showViewOptionsButton: true,
      showDateForwardsButton: true,
      showDateBackwardsButton: true,
      billingPeriods: [],
      areBillingPeriodsRead: false,
      loadState: undefined,
      monthSelected: undefined,
      yearSelected: undefined,
      monthYearSelected: undefined,
      selectedDateSubText: undefined,
      summaryText: undefined,
      selectConfigCalendarMonthsBillingPeriod: {
        buttonAppearance: true,
        selectedValue: 'calendar-month',
        options: [
          {
            label: 'Calendar Month',
            value: 'calendar-month'
          },
          {
            label: 'Billing Period',
            value: 'billing-period'
          }
        ]
      },
      periodTypeSelected: 'calendar-month',
      CALENDAR_MONTH: 'calendar-month', 
      EARLIEST_SNAPSHOT_DATA: new Date('10/6/2015')
    }
  },

  mounted() {

  },

  methods: {
    async refreshData(accountNumber, startDate, endDate) {
      console.log('refreshData', accountNumber, startDate, endDate);
      //Override this method in your componet to fetch data for the given acct, start and end dates
    },

    resetData() {
      //Override this method in your component to reset any specific state variables
    },

    handleRefreshError(err) {
      //Override this method if your component needs to set other state variables when an error condition occurs
      this.loadState = 'error';
      DumpError("Error getting usage data.", err);
    },

    async onDateBackwardsClicked() {
      this.dateControlsTouched = true;
      this.adjustDatesBackwards();
    },

    async onDateForwardsClicked() {
      this.dateControlsTouched = true;
      this.adjustDatesForwards();
    },

    onSelectedDateChanged(selectedDate) {
      this.dateControlsTouched = true;

      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.selectedEndDate = endOfMonth(selectedDate);
        this.adjustDatesForCalendarMonth();
      } else {
        this.selectedStartDate = selectedDate;
        this.adjustDatesForBillingPeriod();
      }
    },

    async onGoToTodayClicked() {
      this.dateControlsTouched = true;
      const currentDate = new Date();

      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.selectedEndDate = endOfMonth(currentDate);
        this.adjustDatesForCalendarMonth();
      } else {
        this.selectedStartDate = currentDate;
        await this.adjustDatesForBillingPeriod();
      }
    },

    async onOptionCalendarTypeSelected(optionSelected) {
      this.periodTypeSelected = optionSelected;

      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.adjustDatesForCalendarMonth();
      } else {
        await this.adjustDatesForBillingPeriod();
      }
    },

    onViewOptionsClicked() {
      // set break point here - you'll never reach it
      /*   __ 
         <(o )___
          ( ._> /
           `---'  */
      return;
    },

    adjustDatesBackwards() {
      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.selectedEndDate = endOfMonth(subMonths(this.selectedEndDate, 1));
        this.adjustDatesForCalendarMonth();
      } else {
        this.selectedStartDate = subDays(this.selectedStartDate, 1);
        this.adjustDatesForBillingPeriod();
      }
    },

    adjustDatesForwards() {
      if (this.periodTypeSelected === this.CALENDAR_MONTH) {
        this.selectedEndDate = endOfMonth(addMonths(this.selectedEndDate, 1));
        this.adjustDatesForCalendarMonth();
      } else {
        this.selectedStartDate = addDays(this.selectedEndDate, 1);
        this.adjustDatesForBillingPeriod();
      }
    },

    adjustDatesForCalendarMonth() {
      console.log(this.selectedStartDate);
      const yesterdaysDate = subDays(new Date(), 1);
      const firstOfMonth = startOfMonth(this.selectedEndDate);
      const lastOfMonth = endOfMonth(this.selectedEndDate);
      if (isToday(firstOfMonth) || isFuture(firstOfMonth)) {
        this.selectedEndDate = endOfMonth(subMonths(lastOfMonth, 1));
      } 
      if (isAfter(lastOfMonth, yesterdaysDate)) {
        this.selectedEndDate = yesterdaysDate;
      }else {
        this.selectedEndDate = lastOfMonth;
      }
      this.selectedStartDate = startOfMonth(this.selectedEndDate);
      this.updateDateSubText();
      this.setDateNavigationButtonStatus();
      this.refreshData(this.currentAccount.accountNumber, this.selectedStartDate, this.selectedEndDate);
    },

    async adjustDatesForBillingPeriod() {
      try {
        this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
        let billingPeriodDates = await this.findBillingPeriodDates(this.selectedStartDate);
        if (isToday(this.selectedStartDate) || isFuture(this.selectedStartDate)) {
          billingPeriodDates = this.findBillingPeriodDates(subDays(this.selectedStartDate, 1));
        }
        if (billingPeriodDates === null) {
          this.selectedStartDate = startOfMonth(this.selectedStartDate);
          this.selectedEndDate = endOfMonth(this.selectedStartDate);
        }
      } catch (err) {
        this.handleRefreshError(err);
        return;
      }
      this.updateDateSubText();
      this.setDateNavigationButtonStatus();
      this.refreshData(this.currentAccount.accountNumber, this.selectedStartDate, this.selectedEndDate);
    },

    async getBillingPeriods(accountNumber) {
      if (this.areBillingPeriodsRead) {
        return this.billingPeriods;
      }
      const data = await GMPAPI.GetBillingPeriods(accountNumber);
      if (!data || !data.periods) {
        this.loadState = 'unavailable';
        return;
      }
      this.areBillingPeriodsRead = true;
      return data;
    },

    disableDatePickerDatesOutsideBillingPeriods(billingPeriods) {
      if (billingPeriods && billingPeriods.length) {
        const disabledDates = {
          from: addDays(new Date(), 1),
          to: parseISO(billingPeriods[billingPeriods.length - 1].endDate)
        }
        return disabledDates; 
      }
    },

    sumUsage(arr, propertyName) {
      return arr.reduce((total, item) => total += item[propertyName], 0);
    },

    hasConsumption(usageData) {
      if (usageData && usageData.intervals[0] && usageData.intervals[0].values && usageData.intervals[0].values.length) {
        return this.sumUsage(usageData.intervals[0].values, 'consumedTotal') > 0 ? true : false;
      } else {
        return false;
      }
    },

    async getPeriodUsageData(accountNumber, interval, startDate, endDate) {
      const response = await GMPAPI.GetPeriodUsage(accountNumber, interval, ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
      if (!response || !response.intervals || !response.intervals.length || !response.intervals[0].values || !response.intervals[0].values.length) {
        this.loadState = 'unavailable';
        return;
      }
      return response;
    },

    async getAccountUsageSnapshot(accountNumber, startDate, endDate, expand) {
      const response = await GMPAPI.GetAccountUsageSnapshot(accountNumber, ToServerDateTruncate(startDate), ToServerDateTruncate(endDate), expand);
      if (!response || !response.averageConsumptionPercentage) {
        this.loadState = 'unavailable';
        return;
      }
      this.loadState = 'complete';
      return response;
    },

    findBillingPeriodDates(searchDate) {
      let matchingBillingPeriod = null;

      this.billingPeriods.periods.forEach((billingPeriod) => {
        if (isWithinInterval(searchDate, { start: parseISO(billingPeriod.startDate), end: parseISO(billingPeriod.endDate) })) {
          matchingBillingPeriod = billingPeriod;
          this.selectedStartDate = parseISO(matchingBillingPeriod.startDate);
          this.selectedEndDate = parseISO(matchingBillingPeriod.endDate);
          return;
        }
      });
      return matchingBillingPeriod;
    },

    updateDateSubText() {
      this.formatMonthYearSelected();

      if (this.periodTypeSelected === this.CALENDAR_MONTH || this.loadState === 'unavailable') {
        this.selectedDateSubText = '';
        return;
      }
      this.selectedDateSubText = this.formatSelectedDateSubTextToThreeLetters(this.selectedStartDate, this.selectedEndDate);
     
    },

    formatMonthYearSelected() {
      this.monthYearSelected = this.formatSelectedDate(this.selectedEndDate);
    },

    formatSelectedDate(dateSelected) {
      const monthSelected = format(dateSelected, 'MMM.');
      const yearSelected = format(dateSelected, 'yyyy');
      return `${monthSelected} ${yearSelected}`;
    },

    formatSelectedDateSubText(startDate, endDate) {
      return (`${format(startDate, 'MMM. d, yyyy')} - ${format(endDate, 'MMM. d, yyyy')}`);
    },

    formatSelectedDateSubTextToThreeLetters(startDate, endDate) {
      return (`${format(startDate, 'MMM. d, yyyy')} - ${format(endDate, 'MMM. d, yyyy')}`);
    },

    async setDateNavigationButtonStatus() {
      this.disableDateNavigationButtonBack = false;
      this.disableDateNavigationButtonForward = false;

      if (!this.areBillingPeriodsRead) {
        try {
          this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
        } catch (err) {
          this.handleRefreshError(err);
          return;
        }
      }

      const billingPeriodsLength = this.billingPeriods.periods.length - 1;

      if (isBefore(this.selectedEndDate, parseISO(this.billingPeriods.periods[billingPeriodsLength].endDate))) {
        this.disableDateNavigationButtonBack = true;
      }

      const todayMonth = getMonth(new Date());
      const endDateMonth = getMonth(this.selectedEndDate);
      if (
        isToday(addDays(this.selectedEndDate, 1)) || 
        isFuture(this.selectedEndDate) || 
        isAfter(this.selectedEndDate, parseISO(this.billingPeriods.periods[0].endDate))
      ) {
        this.disableDateNavigationButtonForward = true;
      } else {
        this.disableDateNavigationButtonForward = false;
      }
    }
  }
}