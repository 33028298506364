var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.userIsOTP ? _c("div", [_c("bill-pay-flow-logged-in")], 1) : _vm._e(),
    _vm._v(" "),
    _vm.userIsOTP ? _c("div", [_c("bill-pay-flow-logged-out")], 1) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }