<template>
  <div>
    <div v-if="routeloading || loadState === undefined">
      <div class="bill-pay__page-loader">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
      </div>
    </div>
    <flow-error v-if="loadState === 'maintenance'" name="Bill Pay" state="maintenance" phonetext="To make a payment by phone call us at" closepath="/make-payment" />
    <flow-error v-if="loadState === 'error'" name="Bill Pay" state="error" phonetext="To make a payment by phone call us at" closepath="/make-payment" />
    <router-view v-if="loadState === 'complete'"></router-view>
    <!-- <div v-if="EnvironmentConstants.DevMode" class="bill-pay__temp-nav" style="padding: 20px">
      Links to subroutes (DEV mode only):
      <router-link to="/login">Login</router-link>
      <router-link to="/onetime">Onetime login</router-link>
      <router-link to="/account">Account</router-link>
      <router-link to="/pay/1">Payment</router-link>
      <router-link to="/complete">Complete</router-link>
      <router-link to="/error">Fatal Error</router-link>
      <a @click="Logout()" href="javascript:void(0);">Logout</a>
    </div>-->
    <div class="bill-pay__speedpay-footer-text">&copy;{{new Date().getFullYear()}} Green Mountain Power. Payments processed by Speedpay, an ACI Worldwide company.</div>
    <modal-more-time :visible="this.timerTicks >= TICK_SHOW_WARNING" :onReset="ResetTimer" />
  </div>
</template>

<script>
import { EnvironmentConstants } from "../../environment";
import { DumpError, ReturnToSender } from "../../utilities";
import ModalMoreTime from "./ModalMoreTime";
import { GetComponentStatus } from "../../services/statuspage";

export default {
  name: "PageMakePayment",
  components: {
    ModalMoreTime,
  },
  data() {
    return {
      // Real values (1 tick / minute, 9th tick shows warning)
      TICK_LENGTH_MS: 60000,
      TICK_SHOW_WARNING: 9,

      // Test values (1 tick / 10 secs, 1st tick shows warning)
      // TICK_LENGTH_MS: 10000,
      // TICK_SHOW_WARNING: 1,

      timer: null,
      timerTicks: 0,

      loadState: undefined,

      EnvironmentConstants,
    };
  },
  watch: {
    $route(to, from) {
      this.ResetTimer();
    },
  },
  computed: {
    routeloading() {
      return this.$store.state.layout.routeloading;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async created() {
    // Ticks once a minute
    this.timer = setInterval(() => {
      this.timerTicks++;
      if (this.timerTicks === 10) {
        window.location = EnvironmentConstants.HomePageURL;
      }
    }, this.TICK_LENGTH_MS);
  },
  async mounted() {
    // Wire up the close button (outside of vue)
    const closer = document.querySelector("#flow-close");
    if (closer) {
      closer.addEventListener("click", e => {
        if (document.referrer && new URL(document.referrer).pathname.startsWith("/past-due-wizard")) {
          window.location = "/";
        } else {
          ReturnToSender('/make-payment');
        }
      });
    }
    this.loadState = undefined;
    const status = await GetComponentStatus("BillPay");
    if (status === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    async Logout() {
      try {
        await this.$store.dispatch("LogOut");
        this.$router.replace("/login");
      } catch (err) {
        DumpError("Logout error", err);
      }
    },
    ResetTimer() {
      this.timerTicks = 0;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>