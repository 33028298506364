<template>
  <div
    v-if="FF_Devices"
    class="gmp-devices-page__overview"
  >
    <div>
      <div class="header-container gds-space-stack-l">
        <h1 class="my-account__title gds-space-stack-default">Devices</h1>
      </div>
      <div class="devices-overview-grid">
        <div class="devices-overview__widget devices-overview__widget__devices-list">
          <div
            v-if="!loadState"
            class="table-loading"
          />
          <DevicesList
            v-if="loadState === 'complete'"
            :devices="devices"
          />
        </div>
        <div class="devices-overview__widget devices-overview__widget__my-impact">
          my-impact
        </div>
        <div class="devices-overview__widget devices-overview__widget__status-history">
          status-history
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../../../../services/gmpapi';
import { DumpError } from '../../../../utilities';
import DevicesList from './deviceslist/DevicesList.vue';
import MediaQueryMixin from '../../../mixins/MediaQueryMixin';
import { isFeatureEnabled } from '../../../../services/featureflags';

export default {
  name: 'DevicesOverview',
  components: {
    DevicesList,
  },
  mixins: [MediaQueryMixin],
  data() {
    return {
      devices: undefined,
      loadState: undefined,
      FF_Devices: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  watch: {
    currentAccount() {
      this.getDevices(this.currentAccount.accountNumber);
    },
  },
  async mounted() {
    await this.checkFeatureFlag();

    if (this.currentAccount && this.currentAccount.accountNumber) {
      this.getDevices(this.currentAccount.accountNumber);
    }
  },
  methods: {
    goToDetailRoute(id) {
      this.$router.push({ path: `/devices/${id}` });
    },
    async getDevices(accountNumber) {
      this.loadState = undefined;

      try {
        this.devices = await GMPAPI.GetDevices(accountNumber);
        this.loadState = 'complete';
      } catch (err) {
        DumpError('Get devices error', err);
        this.loadState = 'error';
      }
    },
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
  },
};
</script>
