var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-account__login-page-wrap" }, [
    _c("section", { staticClass: "my-account__login-form-wrap" }, [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("div", [
            _c("div", { staticClass: "gds-display-2 gds-space-stack-l" }, [
              _vm._v("Verification Failed")
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "The token provided does not appear to be valid. If you copied the link from an email, please make sure you copied the entire URL."
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", { staticClass: "gds-align--text-center" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "gds-display-2 gds-space-stack-l" }, [
              _vm._v("Verification Complete")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
              _vm._v("We've successfully verified your email address.")
            ]),
            _vm._v(" "),
            _vm._m(1)
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "verify-contact__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/verify-contact.svg",
          alt: "contact verified"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-m global-panel__form" }, [
      _c("a", { staticClass: "gds-button", attrs: { href: "/" } }, [
        _vm._v("Done")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }