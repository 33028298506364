<template>
  <div class="make-payment-container">
    <div id="top-target" class="bill-pay-success-fail">
      <div class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon"
          src="/wp-content/themes/gmptwentynineteen/assets/images/bill-pay-complete.svg" alt="successful payment" />
      </div>

      <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Thank you!</h2>
      <div v-if="futurePayment" class="gds-font-size-l gds-space-stack-l">
        Your payment of {{ paymentAmount | currency}} was successfully scheduled for {{ futurePayment | dateFull}}{{
        enrollInRecurringPayments && recurringPaymentSuccess ? ", and we've turned on Recurring Auto Payments." : '.' }}
      </div>
      <div v-else class="gds-font-size-l gds-space-stack-l">
        Your payment of {{ paymentAmount | currency}} was successful{{ enrollInRecurringPayments &&
        recurringPaymentSuccess ? ", and we've turned on Recurring Auto Payments. " : '. ' }} If your service is
        scheduled
        to be shut off in the next 48 hours, or if your service has already been shut-off for non-payment, please
        contact Green Mountain Power at
        <span class="gds-nobreak">{{ GMPPhoneNumber }}</span> to report this payment.
      </div>
      <div class="bill-pay-success__receipt gds-space-stack-l">
        <p class="gds-body-small gds-align--text-center gds-text-grey gds-space-stack-s">Confirmation Number</p>
        <p class="gds-body-normal gds-align--text-center">{{ confirmationNumber }}</p>
      </div>
      <div v-if="(isRecurringPayments && recurringPaymentSuccess)" class="gds-font-size-l gds-space-stack-l">
        Your balance due will be withdrawn 5 days before the due date. Card payments have a $2,500 per month maximum. To
        avoid cancelled payments, use a bank account for the payment method if your monthly bill might exceed $2,500.
      </div>
      <div v-else-if="(isRecurringPayments && !recurringPaymentSuccess)"
        class="gds-font-size-l gds-space-stack-l gds-text-red">
        <div class="gds-space-stack-m">
          There was an unexpected error saving your recurring payment.
        </div>
        <a href="/account/settings#autopay" class="gds-display-0 gds-link gds-link--bold">Click here to setup or manage
          recurring auto payments</a>
      </div>
      <div class="bill-pay-success__button-wrap gds-align--text-center">
        <button @click="ReturnToSender('/make-payment')" type="button"
          class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
import { ReturnToSender } from "../../utilities";

export default {
  name: "BillPayComplete",
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  computed: {
    currentAccount() {
      return this.$route.params.currentAccount;
    },
    enrollInRecurringPayments() {
      return this.$route.params.enrollInRecurringPayments;
    },
    isRecurringPayments() {
      return this.$route.params.isRecurringPayments;
    },
    recurringPaymentSuccess() {
      return this.$route.params.recurringPaymentSuccess;
    },
    paymentAmount() {
      return this.$store.state.billpay.paymentAmount;
    },
    confirmationNumber() {
      return this.$store.state.billpay.confirmationNumber;
    },
    futurePayment() {
      if (this.$store.state.billpay.futurePayment) {
        return new Date(this.$store.state.billpay.futurePayment);
      } else {
        return false;
      }
    },
  },
  async mounted() {
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>