var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-account__login-page-wrap" }, [
    !_vm.resetSent
      ? _c("section", { staticClass: "my-account__login-form-wrap" }, [
          _c("div", { staticClass: "gds-display-2 gds-space-stack-m" }, [
            _vm._v("Reset Password")
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "global-panel__form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.Submit()
                }
              }
            },
            [
              _c(
                "fieldset",
                { staticClass: "gds-fieldset gds-space-stack-m" },
                [
                  _c(
                    "label",
                    { staticClass: "gds-input-field" },
                    [
                      _c("span", { staticClass: "gds-input-field__label" }, [
                        _vm._v("New Password")
                      ]),
                      _vm._v(" "),
                      _c("widget-password-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial.continues",
                            value:
                              "required|length:7,30|hasupperandlower|hasnumber",
                            expression:
                              "'required|length:7,30|hasupperandlower|hasnumber'",
                            modifiers: { initial: true, continues: true }
                          }
                        ],
                        key: "password",
                        ref: "password",
                        attrs: {
                          "data-vv-validate-on": "input",
                          name: "password",
                          placeholder: "Enter new password..."
                        },
                        model: {
                          value: _vm.password1,
                          callback: function($$v) {
                            _vm.password1 = $$v
                          },
                          expression: "password1"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("password-requirements", {
                    attrs: { validationErrors: _vm.errors }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "fieldset",
                { staticClass: "gds-fieldset gds-space-stack-m" },
                [
                  _c(
                    "label",
                    { staticClass: "gds-input-field" },
                    [
                      _c("span", { staticClass: "gds-input-field__label" }, [
                        _vm._v("Confirm New Password")
                      ]),
                      _vm._v(" "),
                      _c("widget-password-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|confirmed:password",
                            expression: "'required|confirmed:password'"
                          }
                        ],
                        key: "password confirmation",
                        attrs: {
                          "data-vv-validate-on": "input",
                          name: "password confirmation",
                          placeholder: "Re-enter new password..."
                        },
                        model: {
                          value: _vm.password2,
                          callback: function($$v) {
                            _vm.password2 = $$v
                          },
                          expression: "password2"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.errors.first("password confirmation")
                ? _c("div", { staticClass: "validation-error" }, [
                    _vm._v(_vm._s(_vm.errors.first("password confirmation")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "gds-space-stack-l" }, [
                _vm.errorSubmit
                  ? _c(
                      "div",
                      {
                        staticClass: "gds-helper-text-error",
                        attrs: { id: "submission_error" }
                      },
                      [_vm._v(_vm._s(_vm.errorSubmit))]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gds-space-stack-m" }, [
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    class: { "gds-loading": _vm.pendingSubmit },
                    attrs: { disabled: _vm.pendingSubmit, type: "submit" }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Reset password")
                    ])
                  ]
                )
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.resetSent
      ? _c(
          "section",
          { staticClass: "my-account__login-form-wrap gds-align--text-center" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "gds-display-2 gds-space-stack-m" }, [
              _vm._v("Your password was reset successfully")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
              _vm._v(
                "You will be returned to the homepage, where you can log in with your new password."
              )
            ]),
            _vm._v(" "),
            _vm._m(1)
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/message-sent.svg",
          alt: "password reset sent"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-m global-panel__form" }, [
      _c("a", { staticClass: "gds-button", attrs: { href: "/" } }, [
        _vm._v("Done")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }