<template>
  <div v-if="FF_PlannedOutage">
    <div v-for="plannedOutage of accountPlannedOutages" :key="plannedOutage.id"
      class="planned-outage-banner gds-flex-container gds-flex-container--space-between gds-flex-container--top">
      <div class="planned-outage-banner--message">A planned outage on {{ plannedOutage.occursAt | dateTimeFullAt }} will
        affect your service for approximately {{ plannedOutage.outageDuration | durationMinutes }}.</div>
      <a
        :href="plannedOutage.incidentId ? '/outages/incident/' + plannedOutage.incidentId : '/outages/planned-outages/' + plannedOutage.id">More
        Info</a>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";
import { isFeatureEnabled } from "../../services/featureflags";

export default {
  name: "WidgetAccountPlannedOutages",
  components: {
  },
  data() {
    return {
      FF_PlannedOutage: undefined,
      accountPlannedOutages: [],
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await Promise.all([
      this.getConfigs(),
      this.RefreshData()
    ])
  },
  methods: {
    async getConfigs() {
      this.FF_PlannedOutage = await isFeatureEnabled('FF_PlannedOutage', false);
    },
    async RefreshData() {
      try {
        this.accountPlannedOutages = [];
        const personalData = await GMPAPI.GetPersonalOutageData();
        const account = personalData.find(a => a.accountNumber === this.currentAccount.accountNumber);
        if (account && account.plannedOutages) {
          this.accountPlannedOutages = account.plannedOutages;
        }
      } catch (err) {
        DumpError("Personal outage refresh error", err);
      }
    },
  },
  watch: {
    async currentAccount() {
      await this.RefreshData();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>