import 'aria-modal-dialog';
import './polyfills';
import initToggle from './components/data-toggle';
import initMouseFocusDetection from './components/using-mouse';
import initSubMenu from './components/menu';
import initSearch from './components/search';
import initSlatsExpanding from './components/slats';
import initSlider from './components/sliders';
import initDocumentsGrid from './components/documents-grid';
import initRates from './components/rates';
import initTabbedDiagram from './components/tabbed-diagram';
import initDesignerSearch from './components/designer-search';
import initAdvisorSearch from './components/advisor-search';

document.addEventListener('DOMContentLoaded', function() {
  initMouseFocusDetection();
  initToggle();
  initSubMenu();
  initSearch();
  initSlider();
  initSlatsExpanding();
  initDocumentsGrid();
  initRates();
  initTabbedDiagram();
  initDesignerSearch();
  initAdvisorSearch();
});
