<template>
  <span>
    <button
    :disabled="disabled"
    @click="buttonClicked()"
    class="view-options-button gds-button gds-compact gds-button-outline"
    >
      <span>{{buttonText}}</span>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButton",
  props: {
    disabled: Boolean,
    buttonClicked: Function
  }
};
</script>

<style scoped>
</style>