<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal modal-simple-info">
      <div class="modal-usage-pick-a-view__header">
        <span class="modal-usage-pick-a-view__title modal-title">{{ modalTitle }}</span>
        <button @click="closeModal()" type="button" class="close bill-pay__close gds-button gds-button-circle gds-secondary">
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
      </div>
      <FocusLock>
        <div
        v-html="modalContent"
        class="modal-usage-pick-a-view__modal-body"></div>
      </FocusLock>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalSimpleInfo",
  props: {
    modalTitle: String,
    modalContent: String,
    closeModal: Function
  }
};
</script>

<style scoped></style>