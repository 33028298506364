var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "gmp-modal" }, [
        _c(
          "div",
          { staticClass: "my-account__modal-box no-scroll-modal" },
          [
            _c("FocusLock", [
              _c("div", { staticClass: "my-account__modal-box--body" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-xl gds-font-demi gds-space-stack-m"
                  },
                  [_vm._v("Do you need more time to complete your payment?")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "my-account__modal-box--message gds-space-stack-l"
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "Your payment session is about to time-out. If you need more time to complete the payment, please click on the button below."
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.onReset()
                      }
                    }
                  },
                  [_vm._v("I need more time")]
                )
              ])
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }