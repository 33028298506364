<template>
  <section id="change-password" class="settings-editor my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l">
    <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
      <h2 ref="scrolltarget" class="settings-editor__title gds-space-stack-ml">Password</h2>

      <div class="edit-close-button-combo">
        <button v-if="!editing" @click="BeginEdit()" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Edit</span>
        </button>
        <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Close</span>
        </button>
      </div>
    </div>
    <!-- Display -->
    <div v-if="!editing">
      <div class="gds-font-size-xl gds-space-stack-ml">••••••••••</div>
      <div v-if="updated" class="form-message-box form-message-box--green gds-space-stack-l">
        <div class="gds-flex-container gds-flex-container--left">
          <svg class="gds-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
          </svg>
          <span>Your password was updated successfully!</span>
        </div>
      </div>
    </div>
    <!-- Edit -->
    <form v-if="editing" @submit.prevent="Save()">
      <div class="profile__edit-password-row gds-flex-container gds-flex-container--left">
        <fieldset class="profile__edit-password---fieldset gds-fieldset">
          <label class="gds-input-field">
            <span class="gds-input-field__label">New Password</span>
            <widget-password-input
              ref="password"
              v-model="password1"
              v-validate.initial.continues="'required|length:7,30|hasupperandlower|hasnumber'"
              data-vv-validate-on="input"
              name="password"
              key="password"
              type="password"
              placeholder="Enter new password..."
            />
          </label>
        </fieldset>
        <!-- TODO: this should be replaced with <password-requirements> but needs style rework -->
        <!-- begin password requirements -->
        <div class="create-password__requirements profile__edit-password--requirements">
          <div v-if="!errors.firstByRule('password', 'length')" class="create-password__requirements-single">
            <svg class="my-account__green-check-icon gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
            </svg>
            At least 7 characters
          </div>
          <div v-if="errors.firstByRule('password', 'length')" class="create-password__requirements-single gds-text-grey">
            <span class="bullet-point">&bull;</span>At least 7 characters
          </div>

          <div v-if="!errors.firstByRule('password', 'hasupperandlower')" class="create-password__requirements-single">
            <svg class="my-account__green-check-icon gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
            </svg>
            One lowercase and one uppercase letter
          </div>
          <div v-if="errors.firstByRule('password', 'hasupperandlower')" class="create-password__requirements-single gds-text-grey">
            <span class="bullet-point">&bull;</span>One lowercase and one uppercase letter
          </div>

          <div v-if="!errors.firstByRule('password', 'hasnumber')" class="create-password__requirements-single">
            <svg class="my-account__green-check-icon gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
            </svg>
            One number
          </div>
          <div v-if="errors.firstByRule('password', 'hasnumber')" class="create-password__requirements-single gds-text-grey">
            <span class="bullet-point">&bull;</span>One number
          </div>
        </div>
        <!--/end password requirements -->
      </div>

      <fieldset class="gds-fieldset gds-space-stack-m">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Confirm New Password</span>
          <widget-password-input
            v-model="password2"
            v-validate="'required|confirmed:password'"
            data-vv-validate-on="input"
            name="password confirmation"
            key="password confirmation"
            placeholder="Re-enter new password..."
          />
        </label>
      </fieldset>
      <div v-if="errors.first('password confirmation')" class="validation-error">{{ errors.first("password confirmation")}}</div>
      <button type="submit" :disabled="pendingSave || errors.any()" :class="{ 'gds-loading': pendingSave }" class="gds-button gds-round gds-space-stack-m">
        <span class="gds-button__text">Save</span>
      </button>
      <div v-if="errorSave" class="form-message-box gds-space-stack-m">There was an error updating your password.</div>
    </form>
  </section>
</template>

<script>
import WidgetPasswordInput from "../WidgetPasswordInput";
import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";

export default {
  name: "EditorProfilePassword",
  components: {
    WidgetPasswordInput,
  },
  data() {
    return {
      editing: false,
      pendingSave: false,
      errorSave: false,

      password1: "",
      password2: "",

      updated: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    // Returning from saved payments, start edit and scroll
    if (this.$route.hash === "#change-password") {
      this.BeginEdit();
      await this.$nextTick();
      this.$refs.scrolltarget.scrollIntoView(true);
    }
  },
  methods: {
    BeginEdit() {
      this.updated = false;
      this.password1 = "";
      this.password2 = "";
      this.pendingSave = false;
      this.errorSave = false;
      this.editing = true;
    },
    async Save() {
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        this.errorSave = undefined;
        try {
          await GMPAPI.UpdateUser(this.userInfo.username, { password: this.password1 });
          this.updated = true;
          this.editing = false;
        } catch (err) {
          DumpError("Update password error", err);
          this.errorSave = true;
        } finally {
          this.pendingSave = false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>