<template>
  <div>
    <div v-if="loadState === undefined" class="table-loading" />
    <flow-error v-if="loadState === 'error'" name="Information for planned outages"
      state="error" />
    <div v-if="loadState === 'complete'">
      <outage-breadcrumbs :planned="true" />
      <div class="planned-outage-list-wrapper">
        <div class="planned-outage-list__headline-instructions gds-flex-container gds-flex-container--space-between">
          <h1>Planned Outages</h1>
          <div class="planned-outages__notifications-link">
            <a href="/account/notifications" class="gds-font-size-l gds-dark-arrow-link">My Notification Settings</a>
          </div>
        </div>

        <div v-if="userInfo">
          <div class="planned-outage-list__helper-left-margin gds-font-size-l gds-font-demi">Affecting Me</div>

          <div v-if="!personalOutageData.length" class="planned-outages__message gds-space-stack-xl">
            <div class="planned-outages__message--inner-short">There are no planned outages that will affect your
              service.</div>
          </div>
          <vue-good-table v-else :columns="personalColumns" :rows="personalOutageData"
            :sort-options="{ enabled: true, initialSortBy: { field: 'occursAt', type: 'desc' } }"
            styleClass="vgt-table outage-table planned-outage-table gds-space-stack-xl">
            <template slot="table-row" slot-scope="props">
              <template v-if="props.column.field == 'occursAt'">
                <div v-if="props.row.status === 'IN_PROGRESS'" class="alert-icon active" />
                <div v-else class="alert-icon inactive" />
                <!-- Formatted start datetime -->
                <div class="planned-outage-table__outage-date">{{ props.row.occursAt | dateTimeFull }}</div>
                <!-- If outage currently in progress, show active badge -->
                <div v-if="props.row.status === 'IN_PROGRESS'" class="planned-outage-list__status-button active">Active
                </div>
              </template>
              <template v-else-if="props.column.field === 'outageDuration'">{{ props.row.outageDuration |
                  durationMinutes
              }}</template>
              <template v-else-if="props.column.field === 'affectedAccount'">
                <div class="gds-flex-container gds-flex-container--left">
                  <div v-if="props.row.affectedAccount.photoUrl"
                    :style="{ backgroundImage: 'url(' + GetResizedAccountPhoto(props.row.affectedAccount.photoUrl) + ')' }"
                    class="small-round-img">
                  </div>
                  <div v-else class="small-round-img"></div>
                  <div>
                    <div class="gds-font-demi gds-space-stack-s">{{ props.row.affectedAccount | accountName }}</div>
                    <div class="gds-linebreaks">{{ props.row.affectedAccount.address | addressFormat({
                        separator: "\n",
                        skipFirst: true
                      })
                    }}</div>
                  </div>
                </div>
              </template>
              <template v-else-if="props.column.field === 'link'">
                <router-link
                  :to="props.row.incidentId ? '/incident/' + props.row.incidentId : '/planned-outages/' + props.row.id.toString()"
                  type="button" class="planned-outage-table__details-btn gds-button gds-compact gds-secondary">View
                  Details</router-link>
              </template>
            </template>
          </vue-good-table>

          <div class="planned-outage-list__helper-left-margin gds-font-size-l gds-font-demi">All Planned Outages</div>
        </div>
        <div v-if="!plannedOutageData.length">
          <div v-if="userInfo" class="planned-outages__message">
            <div class="planned-outages__message--inner-short">There are no planned outages at this time.</div>
          </div>
          <div v-else>
            <div class="planned-outages__message">
              <div class="planned-outages__message--inner-long gds-space-stack-m">There are no planned outages at this
                time. Log in and set up notifications so you’ll be informed when a planned outage might affect your
                service.</div>
              <a href="/account/notifications" class="gds-button gds-secondary gds-round">Set Up Notifications</a>
            </div>

          </div>
        </div>
        <vue-good-table v-else :columns="plannedColumns" :rows="plannedOutageData"
          :sort-options="{ enabled: true, initialSortBy: { field: 'occursAt', type: 'desc' } }"
          styleClass="vgt-table outage-table planned-outage-table">
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field == 'occursAt'">
              <div v-if="props.row.status === 'IN_PROGRESS'" class="alert-icon active" />
              <div v-else class="alert-icon inactive" />
              <!-- Formatted start datetime -->
              <div class="planned-outage-table__outage-date">{{ props.row.occursAt | dateTimeFull }}</div>
              <!-- If outage currently in progress, show active badge -->
              <div v-if="props.row.status === 'IN_PROGRESS'" class="planned-outage-list__status-button active">Active
              </div>
            </template>
            <template v-else-if="props.column.field === 'outageDuration'">{{ props.row.outageDuration | durationMinutes
            }}</template>
            <template v-else-if="props.column.field === 'outageLocation'">
              <div v-if="props.row.outageLocation" class="gds-font-demi">{{ props.row.outageLocation }}</div>
              <div>{{ props.row | outageLocationDetail }}</div>
            </template>
            <template v-else-if="props.column.field === 'link'">
              <router-link
                :to="props.row.incidentId ? '/incident/' + props.row.incidentId : '/planned-outages/' + props.row.id.toString()"
                type="button" class="planned-outage-table__details-btn gds-button gds-compact gds-secondary">View
                Details</router-link>
            </template>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import { DumpError, InitialCaps, GetResizedAccountPhoto } from "../../utilities";
import OutageBreadcrumbs from "./OutageBreadcrumbs.vue";
import { isFeatureEnabled } from "../../services/featureflags";


export default {
  name: "SubpagePlannedOutageList",
  components: {
    OutageBreadcrumbs,
  },
  data() {
    return {
      FF_PlannedOutage: undefined,
      loadState: undefined,

      personalOutageData: undefined,
      plannedOutageData: undefined,
      personalColumns: [
        {
          label: "Start Time",
          field: "occursAt",
        },
        {
          label: "Planned Length",
          field: "outageDuration",
        },
        {
          label: "Affected Account",
          field: "affectedAccount",
        },
        {
          label: "Reason",
          field: "outageReason",
        },
        {
          label: "",
          field: "link",
        },
      ],
      plannedColumns: [
        {
          label: "Start Time",
          field: "occursAt",
        },
        {
          label: "Planned Length",
          field: "outageDuration",
        },
        {
          label: "Outage Location",
          field: "outageLocation",
        },
        {
          label: "Reason",
          field: "outageReason",
        },
        {
          label: "",
          field: "link",
        },
      ],
      GetResizedAccountPhoto,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    this.loadState = undefined;
    try {
      await this.RefreshData();
      if (!this.FF_PlannedOutage) {
        this.loadState = "error";
      } else {
        this.loadState = "complete";
      }
    } catch (err) {
      DumpError("Planned outage list load error", err);
      this.loadState = "error";
    }
  },
  filters: {
    outageLocationDetail: function (row) {
      if (row.outageLocationDescription) {
        return row.outageLocationDescription;
      } else if (row.customersByTown && Object.keys(row.customersByTown).length) {
        return "Portions of " + Object.keys(row.customersByTown).map(item => InitialCaps(item)).join(", ");
      } else {
        return "";
      }
    },
  },
  methods: {
    async getConfigs() {
      this.FF_PlannedOutage = await isFeatureEnabled('FF_PlannedOutage', false);
    },
    async RefreshData() {
      await this.getConfigs()
      const [planned, personalAccounts] = await Promise.all([
        GMPAPI.GetPlannedOutages(),
        GMPAPI.GetPersonalOutageData(),
      ]);
      this.plannedOutageData = planned;
      const personalOutages = [];
      // We need to search through the personal data to match outages to accounts
      for (const account of personalAccounts) {
        if (account.plannedOutages && account.plannedOutages.length) {
          for (const outage of planned) {
            // If the outage is listed in account.plannedOutages, we can add the account info to the outage
            if (account.plannedOutages.find(item => item.id === outage.id)) {
              // Get a photoUrl if available
              if (this.userInfo && this.userInfo.customData && this.userInfo.customData.energyAccounts && this.userInfo.customData.energyAccounts.length) {
                const energyAccount = this.userInfo.customData.energyAccounts.find(item => item.accountNumber === account.accountNumber);
                if (energyAccount) {
                  account.photoUrl = energyAccount.photoUrl;
                }
              }
              // Fallback to default photo if needed
              if (!account.photoUrl) account.photoUrl = "/wp-content/themes/gmptwentynineteen/assets/images/default-account.png";
              // Note: we only track/display one affected account because that's all we have space for
              outage.affectedAccount = account;
              personalOutages.push(outage);
            }
          }
        }
      }
      this.personalOutageData = personalOutages;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>