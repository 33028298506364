<template>
  <span class="usage-detail__datepicker floating-panel">
    <datepicker
      ref="datePicker"
      :typeable="false"
      :minimum-view="minimumView"
      :maximum-view="maximumView"
      v-model="selectedDate"
      :disabled-dates="disabledDatesConfig"
      @selected="handleSelectedDateChange"
      input-class="usage-detail__datepicker__date-input"
    />
  </span>
</template>

<script>
import Datepicker from "vuejs-datepicker";

const showDayCalendarMethod = 'showDayCalendar';

export default {
  name: "UsageDetailDatePicker",
  components: { Datepicker },
  mounted() {
    this.setDatePickerDateType();

    if (this.disabledDates) {
      this.disabledDatesConfig = Object.assign({}, this.disabledDates);
    }
  },
  props: {
    selectedDateChanged: Function,
    datePickerVisible: Boolean,
    datePickerDateType: String,
    datePickerDefaultDate: Date,
    disabledDates: Object
  },
  watch: {
    datePickerVisible(val) {
      const datePicker = this.$refs.datePicker;
      val ? datePicker[this.datePickerOpenMethod]() : datePicker.close();
    },
    datePickerDateType() {
      this.setDatePickerDateType();
    },
    disabledDates(val) {
      this.disabledDatesConfig = Object.assign({}, val);
    },
    datePickerDefaultDate(val) {
      this.selectedDate = val;
    }
  },
  data() {
    return {
      keyDay: 'day',
      keyMonth: 'month',
      keyYear: 'year',
      minimumView: 'day',
      maximumView: 'year',
      disabledDatesConfig: {
        from: new Date(),
      },
      datePickerOpenMethod: showDayCalendarMethod,
      selectedDate: this.datePickerDefaultDate || new Date()
    }
  },
  methods: {
    handleSelectedDateChange() {
      this.$nextTick(() => this.selectedDateChanged(this.selectedDate));
    },
    setDatePickerDateType() {
      switch(this.datePickerDateType) {
        case this.keyDay:
          this.minimumView = this.keyDay;
          this.datePickerOpenMethod = showDayCalendarMethod;
        break;
        case this.keyMonth:
          this.minimumView = this.keyMonth;
          this.datePickerOpenMethod = 'showMonthCalendar';
        break;
        case this.keyYear:
          this.minimumView = this.keyYear;
          this.datePickerOpenMethod = 'showYearCalendar';
        break;
      }
    }
  }
};
</script>

<style scoped>
</style>