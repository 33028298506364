<template>







  <div v-if="billingSummaryLoadstate !== 'loading'" >
    <PerformanceChart v-if="!isNetMetered" />
    <PerformanceChartNetMetered v-if="isNetMetered" />
  </div>
</template>

<script>
import GMPAPI from "../../../../services/gmpapi";
import { DumpError } from "../../../../utilities";
import PerformanceChart from "./PerformanceChart";
import PerformanceChartNetMetered from "./PerformanceChartNetMetered";


export default {
  name:'UsageDetailPerformance',
  components: {
    PerformanceChart,
    PerformanceChartNetMetered
  },
  data() {
    return {
      isNetMetered: false,
      billingSummaryLoadstate: undefined
    };
  },
  watch: {
    async currentAccount() {
      await this.getBillingSummary();
    }
  },
  async mounted() {
    await this.getBillingSummary();
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  methods: {
    async getBillingSummary() {
    this.billingSummaryLoadstate = 'loading';
    this.isNetMetered = undefined;

      try {
        const summary = await GMPAPI.GetUsageSummary(this.currentAccount.accountNumber);
        this.bothFeatureFlagAndIsNetmeteredConditionsChecked ++;
        if (summary) {
          this.billingSummaryLoadstate = 'complete';
          this.billingSummary = summary;
          this.isNetMetered = summary.isNetMetered;
          return;
        }
        this.billingSummaryLoadstate = 'unavailable';
        this.isNetMetered = false;
      } catch (err) {
        this.billingSummaryLoadstate = 'error';
        DumpError("Get Billing Summary error", err);
        this.isNetMetered = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>