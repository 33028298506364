var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notifications--container" },
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState !== "complete"
        ? _c(
            "div",
            { staticClass: "usage-page--interior-status-container" },
            [
              _vm.loadState === "error"
                ? _c("flow-error", {
                    attrs: {
                      name: "Notification configuration",
                      state: "error"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loadState === "maintenance"
                ? _c("flow-error", {
                    attrs: {
                      name: "Notification configuration",
                      state: "maintenance"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loadState === "unavailable"
                ? _c("flow-error", {
                    attrs: {
                      name: "Notification configuration",
                      state: "unavailable",
                      img:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? [
            _vm.noContactsOnAccount
              ? _c(
                  "div",
                  [
                    _c("banner-one-action", {
                      attrs: {
                        bannerColor: "blue",
                        message:
                          "There are no contacts for this account. Before turning on notifications, add a contact.",
                        linkText: "Contacts Page",
                        url: "/account/notifications/contacts/"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.alerts, function(alert, index) {
              return _c(
                "div",
                {
                  key: alert.alertTypeId,
                  staticClass: "notifications__overview--sections"
                },
                [
                  _c("img", {
                    staticClass: "notifications__overview--icon",
                    attrs: { src: alert.iconUrl }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "notifications__overview--type" }, [
                    _c(
                      "p",
                      { staticClass: "notifications__overview--type--heading" },
                      [_vm._v(_vm._s(alert.name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass:
                          "notifications__overview--type--description"
                      },
                      [_vm._v(_vm._s(alert.description))]
                    ),
                    _vm._v(" "),
                    alert.code === "USAGE_NOTIFICATIONS" &&
                    !_vm.noContactsOnAccount
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "notifications__overview--configure-usage-alerts",
                            attrs: { href: "/account/configure/usage-alert/" }
                          },
                          [
                            _vm._v(
                              "\n            Customize Usage Alert Threshold\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  !_vm.noContactsOnAccount
                    ? _c(
                        "div",
                        { staticClass: "notifications__overview--status" },
                        [
                          alert.hasSubscriptions && !alert.buttonLoading
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "notifications__overview--status--span"
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "notifications__overview--status--icon",
                                    attrs: {
                                      src:
                                        "/wp-content/themes/gmptwentynineteen/assets/images/check-circle-small-cropped@3x.png",
                                      alt: ""
                                    }
                                  }),
                                  _vm._v(" "),
                                  alert.subscriptionCount === 0 ||
                                  alert.subscriptionCount > 1
                                    ? _c("span", [
                                        _vm._v(
                                          "Enabled for " +
                                            _vm._s(alert.subscriptionCount) +
                                            " Contacts"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "Enabled for " +
                                            _vm._s(alert.subscriptionCount) +
                                            " Contact"
                                        )
                                      ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          alert.hasSubscriptions && _vm.buttonPressed !== index
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "notifications__overview--status--button gds-button gds-compact gds-secondary gds-round",
                                  attrs: { to: "/notifications/contacts/" }
                                },
                                [_vm._v("View Contacts")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !alert.hasSubscriptions && _vm.buttonPressed !== index
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "notifications__overview--status--button gds-button gds-compact gds-round",
                                  on: {
                                    click: function($event) {
                                      return _vm.subscribe(alert, index)
                                    }
                                  }
                                },
                                [_vm._v("Turn On")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.buttonPressed === index
                            ? _c("button", {
                                staticClass:
                                  "notifications__overview--status--button button-loading gds-button gds-compact gds-secondary gds-round"
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showAddContactModal
                    ? _c("modal-add-contact", {
                        attrs: { currentAlert: _vm.subscriptionFailed },
                        on: {
                          complete: function($event) {
                            _vm.showAddContactModal = undefined
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showChangeErrorBanner && _vm.buttonError === index
                    ? _c(
                        "div",
                        { staticClass: "form-message-box gds-space-stack-m" },
                        [
                          _vm._v(
                            "There was an error " +
                              _vm._s(_vm.changeErrorAction) +
                              "."
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }