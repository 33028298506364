<template>
  <div class="usage-detail__legend-item">
    <div class="usage-detail__legend-item--circle" :style="`background-color: ${color};`"></div>
    <div class="usage-dashboard__legend-text-container">
      <div class="usage-dashboard__legend-text" v-if="title">{{ title }}</div>
      <div class="usage-detail__legend-item--text" v-if="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsageDetailLegendItem",
  components: {
  },
  props: {
    color: {
      type: String,
      default: '#5F6776'
    },
    text: String,
    title: String
  },
  async mounted() {
  },
}
</script>