import { forEach, toggleClass } from '../functions';

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export default function sliders() {
  var carousels = document.querySelectorAll('.carousel');
  forEach(carousels, (index, carousel) => {
    var id = carousel.getAttribute('data-target');
    var thumbnails = carousel.querySelectorAll('.carousel-thumb');
    var startIndex = thumbnails ? getRandomInt(3) : 1;
    var slider = tns({
      container: '#' + id,
      items: 1,
      startIndex: startIndex,
      slideBy: 'page',
      autoplay: false,
      navContainer: '.thumbs-' + id,
      navAsThumbnails: true,
      mouseDrag: true
    });
    if(thumbnails) {
      forEach(thumbnails, (index, thumbnail) => {
        thumbnail.addEventListener('click', (event) => {
          event.preventDefault();
          var slideNumber = parseInt(thumbnail.getAttribute('data-slide-number'));
          slider.goTo(slideNumber);
        });
      });
    }
  });
}
