var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-modal" }, [
    _c(
      "div",
      { staticClass: "my-account__modal-box no-scroll-modal" },
      [
        _c(
          "button",
          {
            staticClass: "close bill-pay__close",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.close(_vm.modalName)
              }
            }
          },
          [
            _c("span", { staticClass: "gds-visibility--hide-visually" }, [
              _vm._v("Close")
            ]),
            _vm._v(" "),
            _c("svg", { staticClass: "gds-icon" }, [
              _c("use", {
                attrs: {
                  "xlink:href":
                    "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("FocusLock", [
          _c("div", { staticClass: "eicproducts__modal-box--body" }, [
            _vm.modalTitle
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-xl gds-font-demi gds-space-stack-m"
                  },
                  [_vm._v(_vm._s(_vm.modalTitle))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.modalMessage && !_vm.listItems && !_vm.modalLink
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-xl gds-font-demi gds-space-stack-m"
                  },
                  [_vm._v(_vm._s(_vm.modalMessage))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.modalMessage && _vm.listItems && !_vm.modalLink
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-xl gds-font-demi gds-space-stack-m"
                  },
                  [_vm._v(_vm._s(_vm.modalMessage))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.modalMessage && _vm.modalLink
              ? _c(
                  "div",
                  {
                    staticClass:
                      "gds-font-size-xl gds-font-demi gds-space-stack-m",
                    staticStyle: { "white-space": "pre-wrap" }
                  },
                  [
                    _vm._v(_vm._s(_vm.modalMessage)),
                    _c("a", { attrs: { href: _vm.modalLink } }, [
                      _vm._v(_vm._s(_vm.modalLinkName))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.listItems
              ? _c("div", [
                  _c(
                    "ul",
                    _vm._l(_vm.listItems, function(item) {
                      return _c("li", { key: item }, [_vm._v(_vm._s(item))])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.close(_vm.modalName)
                  }
                }
              },
              [_vm._v("Okay")]
            )
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }