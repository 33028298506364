<template>
  <span class="event-in-progress-icon-wrapper">
    <span class="event-in-progress-icon-border">
      <span class="event-in-progress-icon" />
    </span>
    <span class="event-in-progress-icon top" />
  </span>
</template>

<script>
export default {
  name: 'EventInProgressIndicator',
};
</script>
