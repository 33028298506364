var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.value
      ? _c(
          "div",
          { staticClass: "gds-flex-container gds-flex-container--left" },
          [
            _c("div", { staticClass: "my-account__green-check-cirlce" }, [
              _c(
                "svg",
                { staticClass: "my-account__white-check-icon gds-icon" },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-white-check-on-green"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "gds-font-demi gds-font-size-s" }, [
              _vm._v("On")
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.value
      ? _c(
          "div",
          { staticClass: "gds-flex-container gds-flex-container--left" },
          [
            _c("div", { staticClass: "my-account__grey-minus-cirlce" }, [
              _c(
                "svg",
                { staticClass: "my-account__white-minus-icon gds-icon" },
                [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-disabled-new"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "gds-font-demi gds-font-size-s" }, [
              _vm._v("Off")
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }