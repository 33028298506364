var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-account__login-page-wrap" }, [
    !_vm.resetSent
      ? _c("section", { staticClass: "my-account__login-form-wrap" }, [
          _c("div", { staticClass: "gds-display-2 gds-space-stack-m" }, [
            _vm._v("Forgot Password")
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "global-panel__form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.Submit()
                }
              }
            },
            [
              _c(
                "fieldset",
                {
                  staticClass: "gds-fieldset gds-space-stack-m",
                  class: { "gds-has-error": _vm.errorSubmit }
                },
                [
                  _c("label", { staticClass: "gds-input-field" }, [
                    _c("span", { staticClass: "gds-input-field__label" }, [
                      _vm._v("Email Address")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email|max:74",
                          expression: "'required|email|max:74'"
                        }
                      ],
                      key: "email address",
                      staticClass: "gds-input-field__input",
                      attrs: {
                        maxlength: "74",
                        name: "email address",
                        type: "email",
                        spellcheck: "false",
                        autocapitalize: "none",
                        autocomplete: "email",
                        required: "",
                        "aria-required": "true"
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.errors.first("email address")
                    ? _c("div", { staticClass: "validation-error" }, [
                        _vm._v(_vm._s(_vm.errors.first("email address")))
                      ])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gds-space-stack-l" }, [
                _vm.errorSubmit
                  ? _c(
                      "div",
                      {
                        staticClass: "gds-helper-text-error",
                        attrs: { id: "submission_error" }
                      },
                      [_vm._v(_vm._s(_vm.errorSubmit))]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gds-space-stack-m" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-flex-container gds-space-stack-m" },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "gds-button gds-secondary gds-space-inline-m",
                      attrs: { href: "/" }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "gds-button",
                      class: { "gds-loading": _vm.pendingSubmit },
                      attrs: { disabled: _vm.pendingSubmit, type: "submit" }
                    },
                    [
                      _c("span", { staticClass: "gds-button__text" }, [
                        _vm._v("Submit")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.resetSent
      ? _c(
          "section",
          { staticClass: "my-account__login-form-wrap gds-align--text-center" },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "gds-display-2 gds-space-stack-m" }, [
              _vm._v("Check your inbox")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
              _vm._v(
                "We’ve sent you an email with password reset instructions."
              )
            ]),
            _vm._v(" "),
            _vm._m(2)
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
      _vm._v(
        "\n      Please provide your email address and we’ll send you instructions on how to reset your password. When logging in, use an email address or username.\n      "
      ),
      _c("a", { attrs: { href: "/account/forgot-username" } }, [
        _vm._v("Forgot Username")
      ]),
      _vm._v(".\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/message-sent.svg",
          alt: "password reset sent"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-space-stack-m global-panel__form" }, [
      _c("a", { staticClass: "gds-button", attrs: { href: "/" } }, [
        _vm._v("Done")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }