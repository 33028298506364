<template>
  <section class="settings-editor my-account__full-width-section my-account__section-with-bottom-border gds-space-stack-l">
    <div class="gds-flex-container gds-flex-container--space-between gds-flex-container--top">
      <h2 class="settings-editor__title gds-space-stack-ml">Email</h2>

      <div class="edit-close-button-combo">
        <button v-if="!editing" @click="editing = true" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Edit</span>
        </button>
        <button v-if="editing && !pendingSave" @click="editing = false" type="button" class="gds-button gds-text-button gds-space-inline-l">
          <span class="my-account__notifications--text-button gds-button__text">Close</span>
        </button>
      </div>
    </div>
    <!-- Display -->
    <div v-if="!editing">
      <div class="gds-font-size-m gds-space-stack-ml">{{userInfo.email}}</div>
    </div>
    <!-- Edit -->
    <form v-if="editing" @submit.prevent="Save()">
      <fieldset class="gds-fieldset gds-space-stack-m">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Email</span>
          <input
            v-model="editedEmail"
            v-validate="'required|email|max:74'"
            maxlength="74"
            name="email address"
            key="email address"
            @input="touched = true"
            class="gds-input-field__input"
            type="text"
            placeholder="Type Here..."
          />
          <div v-if="errors.first('email address')" class="validation-error">{{ errors.first("email address")}}</div>
        </label>
      </fieldset>
      <button type="submit" :disabled="pendingSave || !touched || errors.any()" :class="{ 'gds-loading': pendingSave }" class="gds-button gds-round gds-space-stack-m">
        <span class="gds-button__text">Save</span>
      </button>
      <div v-if="errorSave && emailAlreadyExists" class="form-message-box gds-space-stack-m">A user with this email already exists.</div>
      <div v-if="errorSave && !emailAlreadyExists" class="form-message-box gds-space-stack-m">There was an error updating your email.</div>
    </form>
  </section>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import { DumpError } from "../../utilities";

export default {
  name: "EditorProfileEmail",
  data() {
    return {
      editing: false,
      touched: false,
      pendingSave: false,
      errorSave: false,
      emailAlreadyExists: false,

      editedEmail: undefined,
    };
  },
  components: {
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      if (this.userInfo) {
        // Copy email over to temporary editable
        this.editedEmail = this.userInfo.email;
      }
    },
    async Save() {
      this.emailAlreadyExists = false;
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        this.errorSave = undefined;
        try {
          await GMPAPI.UpdateUser(this.userInfo.username, { email: this.editedEmail });
          await this.$store.dispatch("FetchUserInfo");
        } catch (err) {
          if (err.response && err.response.status === 409) {
            this.emailAlreadyExists = true;
          } 
          DumpError("Update email error", err);
          this.errorSave = true;
        } finally {
          this.pendingSave = false;
        }
      }
    },
  },
  watch: {
    // Refresh status whenever the userinfo changes
    async userInfo() {
      this.editing = false;
      this.touched = false;
      this.errorSave = false;
      this.emailAlreadyExists = false;
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>