var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "button",
      {
        staticClass:
          "date-navigation__button gds-button gds-button-outline gds-compact",
        attrs: { disabled: _vm.disabled, title: "Go to today" },
        on: {
          click: function($event) {
            return _vm.goToToday()
          }
        }
      },
      [
        _c(
          "svg",
          {
            staticClass: "gds-icon shift-left",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              fill: "none",
              viewBox: "0 0 32 32"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M8 5a1 1 0 0 1 2 0v2h12V5a1 1 0 0 1 2 0v2l2 2v12h-2v-8H8v10h5v2H8l-2-2V9l2-2V5Zm13 13 1 1-3 4h10v2H19l3 4-1 1-6-6 6-6ZM8 5a1 1 0 0 1 2 0v2h12V5a1 1 0 0 1 2 0v2l2 2v12h-2v-8H8v10h5v2H8l-2-2V9l2-2V5Z"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("span", [_vm._v("Go To Today")])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }