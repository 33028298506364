<template>
  <div class="top-card-display__single-card top-card-display__single-card--payment-plan">
    <div v-if="currentAccount">
      <div class="top-card-display__single-card top-card-display__single-card--pay-bill-loggedin">
        <div class="top-banner-card gds-card gds-data-card" :class="{'gds-loading': loadState === undefined }">
          <div class="my-account__payment-plan-card">
            <div>
              <h3 class="gds-card__title">Payment Plan</h3>
            </div>
            <template v-if="loadState === 'complete'">
              <!-- Eligible, not enrolled, show message -->
              <p v-if="arrangementStatus && arrangementStatus.isEligible && !arrangementStatus.isEnrolled" class="gds-card__caption">Pay off your balance over time.</p>
              <div class="gds-flex-container gds-space-stack-s">
                <div class="card__data-container--large-text">
                  <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-plan.svg" alt=" " />
                </div>
                <div
                  v-if="arrangementStatus && arrangementStatus.isEnrolled && (arrangementStatus.paymentsRemaining > 0 && arrangementStatus.monthlyPayment > 0)"
                  class="my-account__payment-plan--status"
                >You have {{arrangementStatus.paymentsRemaining}} installments of {{arrangementStatus.monthlyPayment | currency}} remaining.</div>
              </div>
              <div class="gds-align--text-center">
                <!-- Eligible, not enrolled, show button -->
                <a
                  v-if="arrangementStatus && arrangementStatus.isEligible && !arrangementStatus.isEnrolled"
                  href="/arrange-payment"
                  class="gds-card__button gds-button gds-round gds-button-full gds-secondary"
                >Set Up Payment Arrangement</a>
                <!-- Not eligible (no message) -->
                <div
                  v-if="arrangementStatus && !arrangementStatus.isEligible && !arrangementStatus.isEnrolled && !arrangementStatus.ineligibleReason"
                  class="gds-text-grey gds-font-size-m"
                >You are not eligible for payment arrangements at this time.</div>
                <!-- Not eligible (with message) -->
                <div
                  v-if="arrangementStatus && !arrangementStatus.isEligible && !arrangementStatus.isEnrolled && arrangementStatus.ineligibleReason"
                  class="gds-text-grey gds-font-size-m"
                >{{arrangementStatus.ineligibleReason}}</div>
                <!-- Already enrolled -->
                <div
                  v-if="arrangementStatus && arrangementStatus.isEnrolled"
                  class="gds-text-grey gds-font-size-m"
                >Your payment arrangement installments are included in your monthly bill.</div>
              </div>
            </template>
            <div v-if="loadState === 'error'" class="card-error">
              Unable to load,
              <br />please try again later.
            </div>
            <div v-if="loadState === 'error'" class="card-error-layout-blank">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from '../services/gmpapi';
import { DumpError } from '../utilities';
export default {
  name: "CardPaymentPlan",
  data() {
    return {
      arrangementStatus: undefined,

      loadState: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    }
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.arrangementStatus = undefined;
      this.loadState = undefined;
      if (!this.currentAccount) {
        this.loadState = "error";
        return;
      }
      try {
        const info = await GMPAPI.GetPaymentArrangement(this.currentAccount.accountNumber);
        this.arrangementStatus = info;
        this.loadState = "complete";
      } catch (err) {
        DumpError("Payment plan card refresh error", err);
        this.loadState = "error";
      }
    },
  },
  watch: {
    // Refresh status whenever the account changes
    async currentAccount() {
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>