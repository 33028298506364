<template>
  <div>
    <div :id="engineType === 'All Electric' ? 'total-savings-box' : 'total-savings-box-no-state'">
      <span class="gds-input-field__label">Rebate Total</span>
      <div id="rebate-total">{{`$${totalSavings.toLocaleString()}`}}</div>
      <br />
      <!-- <span class="gds-input-field__label">Breakdown</span> -->
      <div class="eicproduct-breakdown">
        <div class="eicproduct-row gds-space-stack-s">
          GMP Rebate ({{`${engineType} Vehicle`}})
          <span class="total-savings-flex-item">
            <b v-if="engineType == 'All Electric' && preOwned">{{ `$${usedRebateAmount}` }}</b>
            <b v-if="engineType == 'All Electric' && !preOwned">{{ `$${allElectricNewRebateAmount}` }}</b>
            <b v-if="engineType == 'Hybrid' && preOwned">{{ `$${hybridUsedRebateAmount}` }}</b>
            <b v-if="engineType == 'Hybrid' && !preOwned">{{ `$${hybridNewRebateAmount}` }}</b>
          </span>
        </div>
        <div v-if="incomeQualifiedRebate" class="eicproduct-row gds-space-stack-s">
          Income Qualified Rebate
          <span class="total-savings-flex-item">
            <b>{{`$${incomeQualifiedRebate}`}}</b>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RebateCalculator",
  props: {
    engineType: String,
    preOwned: Boolean,
    totalSavings: Number,
    usedRebateAmount: Number,
    allElectricNewRebateAmount: Number,
    hybridNewRebateAmount: Number,
    hybridUsedRebateAmount: Number,
    incomeQualifiedRebate: Number,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>