<template>
  <div class="make-payment-container">
    <div id="top-target" class="past-due-wizard-success-fail">
      <div v-if="isOverallSuccess" class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/past-due-wizard-complete.svg" alt="successful payment" />
      </div>
      <div v-if="!isOverallSuccess" class="bill-pay-fail__intro-image gds-space-stack-xl">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-fatal-error.svg" alt="unsuccessful payment" />
      </div>
      <div class="gds-space-stack-l">{{language}}</div>
      <div class="bill-pay-success__button-wrap gds-align--text-center">
        <button @click="returnToAccountDashBoard" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
import { ReturnToSender } from "../../utilities";

export default {
  name: "PastDueWizardComplete",
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
      isOverallSuccess: true,
      language: "Thank you!"
    };
  },
  mounted() {
    this.generateLanguage();
  },
  computed: {
    paymentArrangementSuccess() {
      return this.$route.params.paymentArrangementSuccess;
    },
    isRecurringPayments() {
      return this.$route.params.isRecurringPayments;
    },
    recurringPaymentSuccess() {
      return this.$route.params.recurringPaymentSuccess;
    },
    isBudgetBilling() {
      return this.$route.params.isBudgetBilling;
    },
    budgetBillingSuccess() {
      return this.$route.params.budgetBillingSuccess;
    },
    currentAccount() {
      return this.$route.params.currentAccount;
    },
    amountDueDatePlusOneMonth() {
      let date = new Date(this.currentAccount.amountDueDate)
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      date.setMonth(date.getMonth() + 1)
      return date.toLocaleDateString(undefined, options)
    },
    amountDueDatePlusOneMonthMinusFiveDays() {
      let date = new Date(this.currentAccount.amountDueDate)
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      date.setMonth(date.getMonth() + 1)
      date.setDate(date.getDate() - 5)
      return date.toLocaleDateString(undefined, options)
    }
  },
  methods: {
    checkIfIsOverallSuccess() {
      this.isOverallSuccess = true;

      if (!this.paymentArrangementSuccess) {
        this.isOverallSuccess = false;
        return;
      }
      if (this.isRecurringPayments && !this.recurringPaymentSuccess) {
        this.isOverallSuccess = false;
        return;
      }
      if (this.isBudgetBilling && !this.budgetBillingSuccess) {
        this.isOverallSuccess = false;
        return;
      }
    },
    generateLanguage() {
      let language;
      let amountDueDate;
      let recurringPaymentLanguage = 'we’ve set up your monthly recurring auto payments';
      let budgetBillingSuccessLanguage = ' and you’ve been enrolled in budget billing. ';
      let budgetBillingFailLanguage = ' but we are unable to enroll you in budget billing at this time. ';

      if (this.paymentArrangementSuccess) {
        language = "Thank you! Your payment arrangement is approved";
        if (this.isRecurringPayments) {
          // Pay arrange and is recurring
          amountDueDate = this.amountDueDatePlusOneMonthMinusFiveDays;
          if (this.isBudgetBilling) {
            // Pay arrange, is recurring and isBudget
            language += `, ${recurringPaymentLanguage}`;
            if (this.budgetBillingSuccess) {
              // budget success
              language += `${budgetBillingSuccessLanguage}`;
            } else {
              // budget fail
              language += `${budgetBillingFailLanguage}`;
            }
          } else {
            // Pay arrange and is budget
            language += ` and ${recurringPaymentLanguage}. `;
          }
        } else {
          // Pay arrange fail
          amountDueDate = this.amountDueDatePlusOneMonth;
          if (this.isBudgetBilling) {
            if (this.budgetBillingSuccess) {
              language += `${budgetBillingSuccessLanguage}`;
            } else {
              language += `${budgetBillingFailLanguage}`;
            }
          } else {
            language += `. `
          }
        }
        language += `Your current balance due is $0.00 and your next payment will be due on or before ${amountDueDate}.`;
      } else {
        language = `An error occurred when setting up your payment arrangement, but ${recurringPaymentLanguage}. Please contact customer support for assistance at ${GMPPhoneNumber}.`;
      }
      this.language = language;
      return language;
    },
    returnToAccountDashBoard() {
      window.location = "/account";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>