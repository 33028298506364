<template>
  <div class="subpage-dashboards subpage-preferences--dashboard subpage-dashboards--left-spacer">
    <editor-mailing-address />
    <editor-paperless :billingStatus="billingStatus" @update="RefreshStatus()" />
    <editor-auto-payments :billingStatus="billingStatus" />
    <editor-budget-billing />
  </div>
</template>

<script>
import EditorMailingAddress from "../editors/EditorMailingAddress";
import EditorPaperless from "../editors/EditorPaperless";
import EditorAutoPayments from "../editors/EditorAutoPayments";
import EditorBudgetBilling from "../editors/EditorBudgetBilling";

import GMPAPI from "../../../services/gmpapi";
import { DumpError } from '../../../utilities';

export default {
  name: "SubpagePreferences",
  components: {
    EditorMailingAddress,
    EditorPaperless,
    EditorAutoPayments,
    EditorBudgetBilling,
  },
  data() {
    return {
      billingStatus: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.billingStatus = undefined;
      try {
        const status = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber)
        this.billingStatus = status;
      } catch (err) {
        DumpError("Account settings refresh error", err);
        this.billingStatus = null;
      }
    },
  },
  watch: {
    // Refresh billing status whenever the account changes
    async currentAccount() {
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>