import { forEach } from '../functions';
import configureSearchInput from '../helpers/configure-search-input';

const hideClass = 'gds-visibility--hide';

export default function search() {
  const searchInputSelector = '#search-input';
  const searchResultsWindowSelector = '#search-results-window';
  const onClose = function () {
    document.getElementById('global-search').classList.remove('gds-site-search--expanded');
  };

  configureSearchInput('#global-search', searchInputSelector, searchResultsWindowSelector, onClose);
  configureSearchInput('.help-center-search__form', '#help-center-search-input', '#help-search-results-window', false, ['&subtype=help_article', '&post_type=help_article']);

  var searchButtons = document.querySelectorAll('[data-action="toggleGlobalSearch"]'); // hi fix this

  forEach(searchButtons, function (i, searchButton) {
    searchButton.addEventListener('click', (event) => {
      if (document.body.className.indexOf('search-results') > -1) {
        document.querySelector('.search-form-input').focus();
      } else {
        event.preventDefault();
        document.getElementById('global-search').classList.add('gds-site-search--expanded');
        document.querySelector(searchInputSelector).focus();
      }
    });
  });

  var searchCloseButton = document.querySelector('.gds-site-search__close-button');
  searchCloseButton.addEventListener('click', (event) => {
    event.preventDefault();
    document.getElementById('global-search').classList.remove('gds-site-search--expanded');
    document.querySelector(searchResultsWindowSelector).classList.add(hideClass);
    document.querySelector(searchInputSelector).value = "";
  })
}
