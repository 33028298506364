<template>
  <div class="make-payment-container">
    <div v-if="loadState === undefined" class="bill-pay__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Arrange a Payment" state="error" phonetext="To make a payment arrangement by phone call us at" closepath="/arrange-payment" />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 -->
      <section v-if="currentPage < 3" class="payment-amount bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="bill-pay-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="bill-pay-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Choose Installments</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="bill-pay-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">{{selectedOption.money}} added to your next {{selectedOption.periods}} bills.</div>
                <button @click="currentPage = 1" class="bill-pay-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 1 && arrangementStatus" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row gds-space-stack-l bill-pay__account-box">
                <div class="bill-pay__amount-flex-container gds-flex-container gds-flex-container--top">
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Current balance</div>
                    <div
                      v-if="currentAccount.amountDue > 0"
                      class="gds-body-normal gds-space-stack-s"
                    >{{currentAccount.amountDue | currency}} is due {{currentAccount.amountDueDate | dateFull}}.</div>
                    <div v-if="currentAccount.amountDue <= 0" class="gds-body-normal">All paid up, you do not owe any money at this time.</div>
                  </div>
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Service Address</div>
                    <div class="gds-body-normal gds-linebreaks">{{currentAccount.address | addressFormat}}</div>
                  </div>
                  <div class="bill-pay__amount-column">
                    <div class="gds-font-demi gds-font-size-medium gds-space-stack-s">Account Number</div>
                    <div class="gds-body-normal">{{currentAccount.accountNumber}}</div>
                  </div>
                </div>
              </div>
              <div class="bill-pay-slap__inner-row gds-space-stack-l">
                <form v-if="arrangementOptions" data-vv-scope="page1" @submit.prevent>
                  <fieldset class="bill-pay__fieldset--wide-hint gds-fieldset gds-space-stack-m">
                    <legend class="gds-font-size-l gds-space-stack-m">Please select an installment plan</legend>
                    <label v-for="opt of arrangementOptions" :key="opt.type" class="gds-radio">
                      <input v-model="selectedOption" :value="opt" type="radio" />
                      <span class="gds-radio__faux"></span>
                      <span class="gds-radio__label">
                        <span class="gds-font-demi">{{opt.money}}</span>
                        added to your next {{opt.periods}} bills.
                      </span>
                    </label>
                  </fieldset>
                </form>
                <button @click="currentPage = 2" class="gds-button gds-compact gds-space-stack-l">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 -->
      <section v-if="currentPage < 3" class="review-submit bill-pay-section">
        <div class="bill-pay-slat gds-slat gds-slat--active">
          <div class="bill-pay-slat__header gds-slat__header">
            <div class="bill-pay-slat__header-left gds-flex-container">
              <div class="bill-pay-slat__number-with-circle">
                <span>2</span>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="bill-pay-slat__title gds-slat__title">Review &amp; Submit</div>
              </div>
            </div>
            <div class="bill-pay-slat__header-right gds-flex-container">
              <div v-if="currentPage < 2" class="bill-pay-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2 && arrangementStatus" class="bill-pay-slat__contents">
            <div class="bill-pay-slat__contents-inner">
              <div class="bill-pay-slat__inner-row gds-space-stack-l">
                <div class="gds-display-00 gds-space-stack-s">GMP Account</div>
                <div class="gds-body-small gds-space-stack-l">
                  #{{currentAccount.accountNumber}}
                  <br />
                  {{currentAccount.address | addressFormat({ separator: ", " })}}
                </div>
                <div class="gds-display-00 gds-space-stack-m">Balance Due Today</div>
                <!-- Note: current balance is hardcoded to $0.00 because it is being deferred to the payment arrangement -->
                <div class="gds-body-small gds-space-stack-l">$0.00</div>
                <div class="gds-display-00 gds-space-stack-m">Installment added to the next {{selectedOption.periods}} bills:</div>
                <div class="gds-body-small gds-space-stack-l">{{selectedOption.money}}</div>
                <div class="gds-space-stack-l">
                  <p class="gds-body-small">
                    You are about to enter into a Payment Arrangement which is a financial agreement between you
                    and Green Mountain Power. You will not make a payment today. Your current balance of {{currentAccount.amountDue | currency}}
                    will be broken up into {{selectedOption.periods}} payments. Beginning the following month, please make the first of these
                    payments along with your normal bill amount. No payment is required today and any pending
                    severance (disconnect process) will be cancelled.
                  </p>
                  <p class="gds-body-small">
                    You agree to pay your future bills in full (which includes the payment arrangement installment
                    payment) before the bill due date. If the "Total amount due", as printed in the blue box on your bill,
                    is not received by the due date and you are disconnected, GMP has the right (subject to Vermont
                    disconnection rules) to collect the full amount due and assess a deposit on the account before
                    power can be restored.
                  </p>
                </div>
                <div class="gds-space-stack-xl">
                  <label class="gds-checkbox">
                    <input v-model="termsAgreed" type="checkbox" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-checkbox__label">I agree to the payment arrangement terms.</span>
                  </label>
                </div>

                <div v-if="submitError" class="form-message-box gds-space-stack-l">{{submitError}}</div>
                <button @click="Submit()" :disabled="pendingSubmit || !termsAgreed" :class="{ 'gds-loading': pendingSubmit }" type="submit" class="gds-button gds-compact">
                  <span class="gds-button__text">Submit Request</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SUCCESS -->
      <div v-if="currentPage === 3" class="bill-pay-success-fail">
        <div class="bill-pay-success__arrange-image gds-align--text-center gds-space-stack-xl">
          <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-plan-created.svg" alt="successful payment" />
        </div>

        <h2 class="gds-display-2 gds-align--text-center gds-space-stack-ml">Your payment arrangement was approved!</h2>
        <!-- Note: current balance is hardcoded to $0.00 because it is being deferred to the payment arrangement -->
        <div class="gds-body-large gds-align--text-center gds-space-stack-xl">
          Your current balance due is $0.00. We will add {{selectedOption.money}} to
          your next {{selectedOption.periods}} bills.
        </div>

        <div class="bill-pay-success__button-wrap gds-align--text-center">
          <button @click="ReturnToSender('/arrange-payment', '/account/billing')" type="button" class="bill-pay-success__button gds-button">Done</button>
        </div>
      </div>
    </div>
  </div>
  <!-- #main -->
</template>

<script>
import { ErrorBag } from "vee-validate";
import { parseISO, format, addMonths } from "date-fns";

import GMPAPI from "../../services/gmpapi";
import { DumpError, ReturnToSender } from "../../utilities";

export default {
  name: "PayArrangeFlow",
  data() {
    return {
      arrangementStatus: undefined,
      arrangementOptions: undefined,
      selectedOption: undefined,

      currentPage: 1,
      termsAgreed: false,

      submitError: undefined,

      pendingSubmit: false,

      ReturnToSender,

      loadState: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  created() {
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.loadState = undefined;
      this.arrangementStatus = undefined;
      this.arrangementOptions = undefined;
      try {
        const info = await GMPAPI.GetPaymentArrangement(this.currentAccount.accountNumber);
        if (info.isEnrolled || !info.isEligible) {
          // Already enrolled or not eligible, abort!
          window.location.href = "/account/billing";
          return;
        }
        this.arrangementStatus = info;
        this.arrangementOptions = info.arrangementOptions.map(opt => {
          // Period data only comes as a full string so we have to parse it out
          const matches = opt.detail.match(/(\S+) added to your bill for (\d+) billing periods/);
          return {
            type: opt.type,
            money: matches[1],
            periods: matches[2],
          };
        });
        this.selectedOption = this.arrangementOptions[0];
        this.loadState = "complete";
      } catch (err) {
        DumpError("Pay arrange refresh error", err);
        this.loadState = "error";
      }
    },
    async Submit() {
      this.submitError = undefined;
      this.pendingSubmit = true;
      try {
        await GMPAPI.UpdatePaymentArrangement(this.currentAccount.accountNumber, this.selectedOption.type);
        await this.$store.dispatch("FetchUserInfo");
        this.currentPage = 3;
        window.scrollTo(0, 0);
      } catch (err) {
        DumpError("Start payarrange error", err);
        this.$router.push({ path: "/error/" });
      } finally {
        // Either way, turn off the loading indicator
        this.pendingSubmit = false;
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>