<template>
  <div class="top-card-display__single-card top-card-display__single-card--account-profile">
    <div v-if="currentAccount">
      <div class="top-banner-card gds-card gds-data-card top-banner-card--logged-in gds-align--text-center">
        <figure class="card__account-profile--figure gds-align--block-center">
          <div class="card__profile-image--outer-outer">
            <div class="card__profile-image--outer-inner gds-position--relative">
              <div class="card__profile-image--edit-icon-wrap">
                <router-link to="/settings#photo">
                  <svg class="card__profile-image--edit-icon gds-icon">
                    <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                  </svg>
                </router-link>
              </div>
              <div
                v-if="currentAccount.photoUrl"
                :style="{backgroundImage: 'url(' + GetResizedAccountPhoto(currentAccount.photoUrl) + ')'}"
                class="small-round-img img-large card__account-profile--image"
              />
            </div>
          </div>
        </figure>
        <div class="card__account-profile--text-details">
          <div class="gds-body-large gds-space-stack-m">{{currentAccount | accountName}}</div>
          <div class="gds-body-small gds-space-stack-s gds-linebreaks">{{currentAccount.address | addressFormat({ separator: "\n", skipFirst: true })}}</div>
          <div class="gds-body-small gds-space-stack-s">Acct. #{{currentAccount.accountNumber}}</div>
        </div>
        <div class="gds-flex-container">
          <router-link to="/settings" class="gds-card__button gds-button gds-round gds-secondary card__account-profile--settings gds-button-full">Account Settings</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetResizedAccountPhoto } from "../../../utilities";

export default {
  name: "CardAccountProfile",
  data() {
    return {
      GetResizedAccountPhoto,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>