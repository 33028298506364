import DataStore from "../../store";
import GMPAPI from "../../services/gmpapi";

import PayArrangeFlow from "./PayArrangeFlow.vue";
import PayArrangeFatalError from "./PayArrangeFatalError.vue";
import { DumpError } from "../../utilities";

export const PayArrangeRouterOptions = {
  mode: "history",
  base: "/arrange-payment",
  // If passed a hash, scroll to the hash. Otherwise, scroll to the top.
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: "/", component: PayArrangeFlow },
    { path: "/error/:code?", component: PayArrangeFatalError },
    { path: "*", redirect: "/" },
  ],
};

let knownLoggedIn = false;

export function PayArrangeRouterInit(router) {
  // Enforce required login before showing my account
  router.beforeEach(async (to, from, next) => {
    if (knownLoggedIn) {
      next();
    } else {
      try {
        DataStore.commit("setRouteLoading", true);
        await GMPAPI.GetCurrentUser();
        // We have auth, but may not have user info
        if (!DataStore.state.user.userinfo) {
          try {
            await DataStore.dispatch("AttemptCookieLogin");
            // Set this, so we don't try to verify login on every route change
            knownLoggedIn = true;
            next();
          } catch (err) {
            window.location.href = "/transit-login?redirect=" + encodeURIComponent(window.location.pathname + window.location.hash + window.location.search);
          }
        } else {
          // Set this, so we don't try to verify login on every route change
          knownLoggedIn = true;
          next();
        }
      } catch (err) {
        if (!(err.response && err.response.status === 401)) {
          DumpError("Arrange routing error", err);
        }
        window.location.href = "/transit-login?redirect=" + encodeURIComponent(window.location.pathname + window.location.hash + window.location.search);
      } finally {
        DataStore.commit("setRouteLoading", false);
      }
    }
  });
}