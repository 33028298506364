<template>
  <div>
    <div v-if="value" class="gds-flex-container gds-flex-container--left">
      <div class="my-account__green-check-cirlce">
        <svg class="my-account__white-check-icon gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-white-check-on-green" />
        </svg>
      </div>
      <span class="gds-font-demi gds-font-size-s">On</span>
    </div>
    <div v-if="!value" class="gds-flex-container gds-flex-container--left">
      <div class="my-account__grey-minus-cirlce">
        <svg class="my-account__white-minus-icon gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-disabled-new" />
        </svg>
      </div>
      <span class="gds-font-demi gds-font-size-s">Off</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndicatorOnOff",
  props: ["value"],
  data() {
    return {
    };
  },
};
</script>

<style scoped>
</style>