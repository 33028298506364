<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal">
      <button @click="close('noQualifyCharger')" type="button" class="close bill-pay__close">
        <svg class="gds-icon close-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
        </svg>
      </button>
      <FocusLock>
        <div class="eicproducts__modal-box--body">
            <div class="gds-font-size-xl gds-font-demi gds-space-stack-m">{{modalMessage}}</div>

          <div class="gds-space-stack-ml"></div>
          <button @click="close('noQualifyCharger')" type="button" class="gds-button">Okay</button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalNoQualifyCharger",
  props: {
    visible: Boolean,
    close: Function,
    modalMessage: String
  },
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>