var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "create-password__requirements" }, [
    !_vm.validationErrors.firstByRule("password", "length", this.scope)
      ? _c("div", { staticClass: "create-password__requirements-single" }, [
          _c("svg", { staticClass: "my-account__green-check-icon gds-icon" }, [
            _c("use", {
              attrs: {
                "xlink:href":
                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
              }
            })
          ]),
          _vm._v("\n    Must be between 7 and 30 characters\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.validationErrors.firstByRule("password", "length", this.scope)
      ? _c(
          "div",
          { staticClass: "create-password__requirements-single gds-text-grey" },
          [
            _c("span", { staticClass: "bullet-point" }, [_vm._v("•")]),
            _vm._v("Must be between 7 and 30 characters\n  ")
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.validationErrors.firstByRule(
      "password",
      "hasupperandlower",
      this.scope
    )
      ? _c("div", { staticClass: "create-password__requirements-single" }, [
          _c("svg", { staticClass: "my-account__green-check-icon gds-icon" }, [
            _c("use", {
              attrs: {
                "xlink:href":
                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
              }
            })
          ]),
          _vm._v("\n    One lowercase and one uppercase letter\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.validationErrors.firstByRule("password", "hasupperandlower", this.scope)
      ? _c(
          "div",
          { staticClass: "create-password__requirements-single gds-text-grey" },
          [
            _c("span", { staticClass: "bullet-point" }, [_vm._v("•")]),
            _vm._v("One lowercase and one uppercase letter\n  ")
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.validationErrors.firstByRule("password", "hasnumber", this.scope)
      ? _c("div", { staticClass: "create-password__requirements-single" }, [
          _c("svg", { staticClass: "my-account__green-check-icon gds-icon" }, [
            _c("use", {
              attrs: {
                "xlink:href":
                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
              }
            })
          ]),
          _vm._v("\n    One number\n  ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.validationErrors.firstByRule("password", "hasnumber", this.scope)
      ? _c(
          "div",
          { staticClass: "create-password__requirements-single gds-text-grey" },
          [
            _c("span", { staticClass: "bullet-point" }, [_vm._v("•")]),
            _vm._v("One number\n  ")
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }