<template>
  <span>
    <button
      :disabled="disabled"
      @click="goToToday()"
      title="Go to today"
      class="date-navigation__button gds-button gds-button-outline gds-compact"
    >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" class="gds-icon shift-left">
          <path
            d="M8 5a1 1 0 0 1 2 0v2h12V5a1 1 0 0 1 2 0v2l2 2v12h-2v-8H8v10h5v2H8l-2-2V9l2-2V5Zm13 13 1 1-3 4h10v2H19l3 4-1 1-6-6 6-6ZM8 5a1 1 0 0 1 2 0v2h12V5a1 1 0 0 1 2 0v2l2 2v12h-2v-8H8v10h5v2H8l-2-2V9l2-2V5Z"
          />
        </svg>
 
      <span>Go To Today</span>
    </button>
  </span>
</template>

<script>
export default {
  name: "DateNavigationButtonGoToToday",
  props: {
    goToToday: Function,
    disabled: Boolean,
  },
};
</script>

<style scoped></style>
