<template>
  <div>
    <section>
      <UsageDetailControls
        class="usage-detail__compare-nav-controls"
        :disableAllDateNavControls="!loadState  || disableAllDateNavControls"
        :datePickerDisabledDates="disabledDates"
        :disableDateNavigationButtonForward="disableDateNavigationForwardButton"
        :disableDateNavigationButtonBack="disableDateNavigationBackwardButton"
        :comparisonDetailsWidth="comparisonDetailsWidth"
        :datePickerDateType="datePickerDateType"
        :showJumpToDateButton="true"  
        jumpToDateButtonLabel="Base Date"
        :showComparisonDateButton="true"
        :showComparisonDetails="true"
        :comparisonDetailsType="comparisonDetailsType"
        :comparisonDetailsDate="comparisonDetailsDate"
        :datePickerDisabledDatesSecondary="datePickerDisabledDatesSecondary"
        :onSelectedDateChanged="onSelectedBaseDateChanged"
        :onSelectedDateChangedSecondary="onSelectedCompareDateChangedSecondary"
        :onDateBackwardsClicked="onDateBackwardsClicked"
        :onDateForwardsClicked="onDateForwardsClicked"
        :onOptionSelectedUsageType="onOptionSelectedUsageType"
        :onOptionSelectedMonthlyDailyHourly="onOptionSelectedMonthlyDailyHourly"
        :showViewOptionsButton="false"
        :showDateBackwardsButton="true"
        :showDateForwardsButton="true"
        :selectConfigUsageType="selectConfigUsageType"
        :selectConfigMonthlyDailyHourly="selectConfigMonthlyDailyHourly" />
    </section>
    <div>
      <div v-if="!loadState" class="table-loading" />
      <div class="usage-page--interior-status-container">
        <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
        <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
        <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
        <flow-error v-if="loadState === 'empty'" name="Usage information" state="nodata"
          img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
      </div>
      <section v-if="loadState === 'complete'" class="usage-detail__usage-compare">
        <div class="usage-detail__compare-legend-container">
          <UsageDetailLegendItem class="gds-space-inline-ml" v-if="baseDate" :color="selectedUsageTypeColor"
            :text="baseLegendDate" :title="`Base Date`" />
          <UsageDetailLegendItem v-if="compareDate" :text="compareLegendDate" :title="`Comparison Date`" />
        </div>
        <div class="my-account__usage-graph-wrapper">
          <div ref="usageCompareChartRef" />
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Highcharts from "highcharts";
import { DumpError, ToServerDateTruncate } from "../../../../utilities";
import GMPAPI from "../../../../services/gmpapi";
import { addDays, addMonths, addYears, endOfDay, format, isAfter, isBefore, isEqual, parseISO, startOfDay, subDays, subMonths, subYears } from "date-fns";
import { FormatChartData } from "../usagedetailgraph/usagedata";
import UsageDetailLegendItem from "./UsageDetailLegendItem.vue";
import UsageDetailControls from "../usagedetailcontrols/UsageDetailControls.vue";
import UsageDetailBaseComponentMixin from "../../../mixins/UsageDetailBaseComponentMixin";
import rates from "../usagedetailgraph/ratedefs.js";
import { GetComponentStatus } from '../../../../services/statuspage';

//Date format constants
const SHORTMONTH_DAY_YEAR_HOUR = 'EEE. MMM. d, yyyy h:00aaa'
const SHORTMONTH_DAY_YEAR = 'EEE. MMM. d, yyyy';
const SHORTMONTH_YEAR = 'MMM. yyyy';
const YEAR = 'yyyy';

export default {
  name: "UsageDetailCompare",
  mixins: [
    UsageDetailBaseComponentMixin
  ],
  components: {
    UsageDetailLegendItem,
    UsageDetailControls,
},
  data() {
    return {
      earliestBillingPeriod: undefined,
      chart: undefined,
      loadState: undefined,
      masterBaseUsageData: [],
      masterCompareUsageData: [],
      baseUsageData: [],
      compareUsageData: [],
      mini: false,
      datePickerDateType: undefined,
      interval: 'daily',
      comparisonDetailsUsageInterval: 'Daily',
      comparisonDetailsUsageType: 'Consumed Total',
      selectedDate: '',
      comparisonDate: '',
      disableDateNavigationBackwardButton: false,
      selectConfigMonthlyDailyHourly: {
        width: undefined,
        selectedLabel: 'Daily',
        selectedValue: 'daily',
        buttonAppearance: true,
        options: [
          {
            label: 'Comparing Hourly',
            value: 'hourly'
          },
          {
            label: 'Comparing Daily',
            value: 'daily'
          },
          {
            label: 'Comparing Monthly',
            value: 'monthly',
          }
        ]
      },
      selectConfigUsageType: {
        width: undefined,
        buttonAppearance: true,
        selectedLabel: 'Consumed Total',
        selectedValue: 'consumedTotal',
        options: []
      },
      selectedUsageTypeColor: rates.find(element => element.name === 'consumedTotal').flatColor,
      compareBarColor: '#5F6776',
      comparisonDetailsWidth: undefined,
      DAYS_INTO_CURRENT_MONTH: 7
    };
  },
  watch: {
    currentAccount() {
      this.initializeData();
    },
    interval() {
      switch (this.interval) {
        case 'monthly':
          this.datePickerDateType = 'year';
          break;
        case 'daily':
          this.datePickerDateType = 'month';
          break
        case 'hourly':
          this.datePickerDateType = 'day';
          break;
      }
    },
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    baseDate() {
      if (this.baseUsageData.length) {
        return this.baseUsageData[this.baseUsageData.length - 1].date;
      } else if (this.selectedDate != '') {
        return this.selectedDate;
      }
    },
    compareDate() {
      if (this.compareUsageData.length) {
        return this.compareUsageData[this.compareUsageData.length - 1].date;
      } else if (this.comparisonDate != '') {
        return this.comparisonDate;
      }
    },
    baseLegendDate() {
      if (this.baseDate) {
        switch (this.interval) {
          case 'hourly':
            return format(this.baseDate, SHORTMONTH_DAY_YEAR);
          case 'daily':
            return format(this.baseDate, SHORTMONTH_YEAR);
          case 'monthly':
            return format(this.baseDate, YEAR);
        }
      }
    }, 
    compareLegendDate() {
      if (this.compareDate) {
        switch (this.interval) {
          case 'hourly':
            return format(this.compareDate, SHORTMONTH_DAY_YEAR);
          case 'daily':
            return format(this.compareDate, SHORTMONTH_YEAR);
          case 'monthly':
            return format(this.compareDate, YEAR);
        }
      }
    }, 
    comparisonDetailsDate() {
      if (this.baseUsageData.length && this.compareUsageData.length) {
        return this.baseLegendDate + ' to ' + this.compareLegendDate;
      } else if (this.comparisonDate) {
        return this.formatLegendDate(this.selectedDate, this.interval) + ' to ' + this.formatLegendDate(this.comparisonDate, this.interval);
      }
      return '';
    },
    disableDateNavigationForwardButton() {
      if (!this.baseUsageData.length || !this.compareUsageData.length) {
        return false;
      }

      const lastPeriod = this.billingPeriods.periods.find(period => period.last === true);
      const lastPeriodEnd = parseISO(lastPeriod.endDate);

      const baseUsageData = this.baseUsageData;
      const compareUsageData = this.compareUsageData;
      const latestDateBase = new Date(baseUsageData[baseUsageData.length - 1].date);
      const latestDateCompare = new Date(compareUsageData[compareUsageData.length - 1].date);

      const latestDateBaseIsLastPeriodEnd = isEqual(latestDateBase, lastPeriodEnd);
      const latestDateBaseAfterLastPeriodEnd = isAfter(latestDateBase, lastPeriodEnd);

      const latestDateCompareIsLastPeriodEnd = isEqual(latestDateCompare, lastPeriodEnd);
      const latestDateCompareAfterLastPeriodEnd = isAfter(latestDateCompare, lastPeriodEnd);

      const dailyOrMonthlyDisableCheck = latestDateBaseIsLastPeriodEnd || latestDateBaseAfterLastPeriodEnd
      || latestDateCompareIsLastPeriodEnd || latestDateCompareAfterLastPeriodEnd;

      switch (this.interval) {
        case 'daily':
          return dailyOrMonthlyDisableCheck;
        case 'monthly':
          return dailyOrMonthlyDisableCheck;
        case 'hourly':
          const currentDate = new Date();
          const latestDateBaseIsAfterToday = isAfter(latestDateBase, currentDate);
          const latestDateCompareIsAfterToday = isAfter(latestDateCompare, currentDate);

          return latestDateBaseIsAfterToday || latestDateCompareIsAfterToday;
        default:
          return false;
      }
    },
     datePickerDisabledDatesSecondary() {
      let from;
      switch (this.interval) {
        case 'hourly':
          from = subDays(this.baseDate, 1);
          break;
        case 'daily':
          from = subMonths(this.baseDate, 1);
          break;
        case 'monthly':
          from = subYears(this.baseDate, 1);
          break;
      }

      return { from: from,
               to: this.earliestBillingPeriod };
    }, 
    comparisonDetailsType() {
      return `${this.comparisonDetailsUsageInterval} ${this.comparisonDetailsUsageType}`;
    },
  },
  async mounted() {
    try {
      // Before we do anything else, check to see if component is operational
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.disableAllDateNavControls = true;
        this.loadState = status;
        return;
      }

      this.billingPeriods = await this.getBillingPeriods(this.currentAccount.accountNumber);
      if (!this.billingPeriods || !this.billingPeriods.periods ||  this.billingPeriods.periods.length === 0) {
        this.loadState = 'empty';
        return;
      } 
    } catch (err) {
      DumpError("Usage detail compare refresh error", err);
      if (err.response && err.response.status === 404) {
        this.loadState = 'unavailable';
      } else {
        this.loadState = "error";
      }
      return;
    }

    this.earliestBillingPeriod = parseISO(this.billingPeriods.periods[this.billingPeriods.periods.length - 1].startDate);
    const billingPeriodsWithLastRemoved = this.billingPeriods.periods.slice(0,-1);
    this.disabledDates = this.disableDatePickerDatesOutsideBillingPeriods(billingPeriodsWithLastRemoved);
    this.initializeData();
    window.addEventListener("resize", () => { this.handleResize });
  },
  beforeDestroy () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.refreshChart(this.baseUsageData, this.compareUsageData);
      this.setControlWidths();
    },
    initializeData() {
      this.setControlWidths();
      this.resetState();
      let baseEndDate = subDays(new Date(), 1);
      let baseStartDate;
      let compareStartDate;
      let compareEndDate;
      const currentYear = new Date().getFullYear();

      switch (this.interval) {
        case 'monthly':
          this.datePickerDateType = 'year';
          baseStartDate = new Date(currentYear, 0, 1);
          baseEndDate = new Date(currentYear, 11, 31);
          compareStartDate = subYears(baseStartDate, 1);
          compareEndDate = subYears(baseEndDate, 1);
          this.selectedDate = baseStartDate;
          this.comparisonDate = compareStartDate;
          break;

        case 'daily':
          this.datePickerDateType = 'month';
          const today = new Date();
          if (isAfter(today, addDays(today, this.DAYS_INTO_CURRENT_MONTH))) {
            baseStartDate = this.getFirstDayOfMonth(new Date());
          } else {
            baseStartDate = this.getFirstDayOfMonth(subMonths(today, 1));
          }
          baseEndDate = subDays(addMonths(baseStartDate,1),1);

          compareStartDate = subYears(baseStartDate, 1);
          compareEndDate = this.getLastDayOfMonth(compareStartDate)
          this.selectedDate = baseStartDate;
          this.comparisonDate = compareStartDate;
          break

        case 'hourly':
          this.datePickerDateType = 'day';
          this.datePickerDateType = 'month';
          baseStartDate = baseEndDate
          compareStartDate = subYears(baseStartDate, 1);
          compareEndDate = subYears(baseEndDate, 1);
          this.selectedDate = baseStartDate;
          this.comparisonDate = compareStartDate;
          break;
      }
      this.fetchBaseAndCompareData(baseStartDate, baseEndDate, compareStartDate, compareEndDate);
    },
    resetState() {
      this.masterBaseUsageData = [];
      this.masterCompareUsageData = [];
      this.baseUsageData = [];
      this.compareUsageData = [];
    },
    formatDate(date) {
      return format(date, SHORTMONTH_DAY_YEAR);
    },
    async refreshChart(baseUsageData, compareUsageData) {
      this.selectConfigUsageType = JSON.parse(JSON.stringify(this.selectConfigUsageType));
      // TODO: Going straight back to today when changing Monthly/Daily/Hourly view
      this.selectConfigMonthlyDailyHourly = JSON.parse(JSON.stringify(this.selectConfigMonthlyDailyHourly));
      this.drawChart(baseUsageData, compareUsageData);
    },
    getFirstDayOfMonth(aDate) {
      return subDays(aDate, parseInt(format(aDate, 'd')) - 1)
    },
    getLastDayOfMonth(date) {
      const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      return lastDayOfMonth;
    },
    async getUsageData(startDate, endDate) {
      this.loadState = undefined;
      try {
        const response = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, this.interval, ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
        if (response && response.intervals[0] && response.intervals[0].values) {
          this.setSelectConfigUsageType(response.intervals[0].values);
        }
        this.loadState = 'complete'
        return response.intervals[0].values;
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
          this.loadState = "unavailable";
        } else if (err && err.response && err.response.data && err.response.data.errorCode === "NO_DATA_FOUND" || err.response && err.response.status === 404) {
          this.loadState = 'empty';
        } else {
          this.handleRefreshError(err);
        }
        return;
      }
    },
    refreshLayers() {
      if (this.chart && this.chart.series && this.chart.series.length) {
        for (const series of this.chart.series) {
          series.setVisible(!series.visible && series.name === this.comparisonDetailsUsageType);
        }
      }
    },
    formatLegendDate(dateToFormat, interval) {
      if (dateToFormat) {
        switch (interval) {
          case 'hourly':
            return format(dateToFormat, SHORTMONTH_DAY_YEAR);
          case 'daily':
            return format(dateToFormat, SHORTMONTH_YEAR);
          case 'monthly':
            return format(dateToFormat, YEAR);
        }
      }
    },
    formatTooltipDate(dateToFormat, interval) {
      if (dateToFormat) {
        switch (interval) {
          case 'hourly':
            return format(dateToFormat, SHORTMONTH_DAY_YEAR_HOUR);
          case 'daily':
            return format(dateToFormat, SHORTMONTH_DAY_YEAR);
          case 'monthly':
            return format(dateToFormat, SHORTMONTH_YEAR);
        }
      }
    },
    setCompareBarStylingcompareSeries(compareSeries) {
      let coloredSeries = compareSeries;
      const basePointWidth = this.calculateBarWidth();
      for (let i = 0; i < compareSeries.length; i++) {
        compareSeries[i].color = this.compareBarColor;
        compareSeries[i].pointWidth = basePointWidth * 0.7;
      }
      return coloredSeries;
    },
    compareArrayLength(arr1, arr2) {
      return arr1.length < arr2.length ? arr1 :
        arr2.length < arr1.length ? arr2 :
          arr1;
    },
    getDaysOfMonth(month) {
      const date = new Date();
      const year = date.getFullYear();
      const daysInMonth = new Date(year, month, 0).getDate();

      const daysArray = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const dateString = `${month}/${day}`;
        daysArray.push(dateString);
      }

      return daysArray;
    },
    setLabels(baseUsageData, compareUsageData, baseFormattedLabels, compareFormattedLabels) {
      const labels = [];
      let shortestArray;

      if (this.interval === 'daily') {
        const daysOfBaseMonth = baseUsageData.length > 0
          ? this.getDaysOfMonth(baseUsageData[0].date.getMonth() + 1)
          : [];

        const daysOfCompareMonth = compareUsageData.length > 0
          ? this.getDaysOfMonth(compareUsageData[0].date.getMonth() + 1)
          : [];

        shortestArray = daysOfBaseMonth.length === 0 ? daysOfCompareMonth : daysOfBaseMonth;
      } else {
        shortestArray = this.compareArrayLength(baseFormattedLabels, compareFormattedLabels);
      }

      for (let i = 0; i < shortestArray.length; i++) {
        labels.push(`${this.interval === 'daily' ? shortestArray[i] : baseFormattedLabels[i]}`);
      }

      return labels;
    },

    adjustArrayLength(shorterArray, compareUsageData) {
      if (shorterArray.length < compareUsageData.length) {
        const diff = compareUsageData.length - shorterArray.length;
        compareUsageData.splice(-diff);
      }
      return compareUsageData;
    },
    drawChart(baseUsageData, compareUsageData) {
      const interval = this.interval;
      const _self = this;

      let daysOfBaseMonth = 0;
      let daysOfCompareMonth = 0;
      if (baseUsageData && baseUsageData.length > 0) {
        daysOfBaseMonth = this.getDaysOfMonth(baseUsageData[0].date.getMonth() + 1);
      }

      if (compareUsageData && compareUsageData.length > 0) {
        daysOfCompareMonth = this.getDaysOfMonth(compareUsageData[0].date.getMonth() + 1);
      }
      
      const shortestArray = this.compareArrayLength(daysOfBaseMonth, daysOfCompareMonth);

      /* Make a copy of the original base and compare data so that we can mutate/update
         the data for purposes of displaying the correct number of days on the graph */
      let compareUsageDataCopy = [...compareUsageData];
      let baseUsageDataCopy = [...baseUsageData];

      if (baseUsageData.length > 0 || compareUsageData.length > 0) {
        compareUsageDataCopy = this.adjustArrayLength(shortestArray, compareUsageDataCopy);
        baseUsageDataCopy = this.adjustArrayLength(shortestArray, baseUsageDataCopy);

        const usageType = this.selectConfigUsageType.selectedValue;
        const baseFormatted = FormatChartData(baseUsageDataCopy, this.interval, this.mini, false);
        const selectedUsageTypeColor = this.selectedUsageTypeColor;
        const compareBarColor = this.compareBarColor;

        let compareFormatted = FormatChartData(compareUsageDataCopy, this.interval, this.mini, false);
        compareFormatted.series = this.setCompareBarStylingcompareSeries(compareFormatted.series);

        const labels = this.setLabels(baseUsageDataCopy, compareUsageDataCopy, baseFormatted.labels, compareFormatted.labels);

        let options = {
          chart: {
            height: this.height,
            scrollablePlotArea: {
              minWidth: labels.length * 50,
            },
            followTouchMove: false,
            animation: false,
            style: {
              fontFamily: "MaisonNeue, Helvetica, sans-serif",
            },
          },
          title: {
            text: undefined,
          },
          legend: {
            enabled: false,
          },
          credits: false,
          xAxis: {
            categories: labels,
            labels: {
              style: {
                fontSize: "13px",
              },
            },
          },
          yAxis: [{
            title: {
              text: undefined
            },
            gridLineWidth: 1,
            className: 'usage-graph-y-axis-label',
            tickInterval: undefined,
            offset: 50,
            labels: {
              enabled: true,
              align: 'left',
              useHTML: true,
              formatter: function () {
                let formattedValue = undefined;
                formattedValue = this.isLast ? `${this.value} kWh` : this.value;
                return `<i class='value'>${formattedValue}</i>`;
              }
            }
          },
          {
            title: "Temperature",
            color: "#000",
            opposite: true,
            className: 'usage-graph-y-axis-label',
            labels: {
              formatter: e => {
                return e.value + "°F";
              }
            }
          }],
          tooltip: {
            shared: false,
            shadow: false,
            useHTML: true,
            outside: true,
            borderWidth: 0,
            borderRadius: 0,
            valueDecimals: 2,
            backgroundColor: "transparent",
            formatter: function (tooltip) {
              const index = this.point.index;
              const baseIndex = baseUsageDataCopy[index];
              const compareIndex = compareUsageDataCopy[index];


              let html = `<div class='my-account__usage-tooltip'>`;
              if (baseIndex && baseIndex[usageType]) {
                const baseDate = _self.formatTooltipDate(new Date(baseIndex.date), interval);
                html += `
                  <div class='tooltip-rate gds-flex-container gds-flex-container--space-between'>
                    <div class='gds-flex-container gds-flex-container--left'>
                      <div class='rate-swatch' style='background-color: ${selectedUsageTypeColor}'></div>
                      <div class='rate-name'>${baseDate}</div>
                    </div>
                    <div class='rate-value'>${interval === 'hourly' ? baseIndex[usageType].toFixed(2) : Math.floor(baseIndex[usageType])} kWh</div>
                  </div>
                `;
              }
              if (compareIndex && compareIndex[usageType]) {
                const compareDate = _self.formatTooltipDate(new Date(compareIndex.date), interval);
                html += `
                  <div class='tooltip-rate gds-flex-container gds-flex-container--space-between'>
                    <div class='gds-flex-container gds-flex-container--left'>
                      <div class='rate-swatch' style='background-color: ${compareBarColor}'></div>
                      <div class='rate-name'>${compareDate}</div>
                    </div>
                    <div class='rate-value'>${interval === 'hourly' ? compareIndex[usageType].toFixed(2) : Math.floor(compareIndex[usageType])} kWh</div>
                  </div>
                `;
              }
              html += '</div>';

              return html;
            }
          },
          plotOptions: {
            column: {
              pointPadding: 0,
              pointWidth: this.calculateBarWidth(),
            },
            series: {
              states: {
                inactive: {
                  opacity: 1
                }
              }
            }
          },
          series: [...baseFormatted.series, ...compareFormatted.series]
        }
        // media query to adjust Yaxis labels on small screens
        let mql = window.matchMedia("screen and (max-width: 580px)");
        if (mql.matches) { // if media query matches
          options.yAxis[0].labels.reserveSpace = false;
          options.yAxis[0].labels.align = "left";
          options.yAxis[0].labels.x = 0;
          options.yAxis[0].labels.y = -1;
          options.yAxis[1].labels.reserveSpace = false;
          options.yAxis[1].labels.align = "right";
          options.yAxis[1].labels.x = 0;
          options.yAxis[1].labels.y = -1;
        }

        this.$nextTick(() => {
          this.chart = Highcharts.chart(this.$refs.usageCompareChartRef, options);
          this.refreshLayers();
        })
      }
    },
    calculateBarWidth() {
      const breakpoints = [
        { width: 1200, barWidth: this.interval === 'monthly' ? 50 : 30 },
        { width: 992, barWidth: this.interval === 'monthly' ? 45 : 24 },
        { width: 768, barWidth: this.interval === 'monthly' ? 40 : 18 },
      ];

      for (const breakpoint of breakpoints) {
        if (window.innerWidth >= breakpoint.width) {
          return breakpoint.barWidth;
        }
      }
      return this.interval === 'monthly' ? 30 : 20;
    },
   
    async fetchBaseData(baseStart, baseEnd) {
      const newBaseUsageData = await this.getUsageData(baseStart, baseEnd);
      return newBaseUsageData;
    },
    async fetchCompareData(compareStart, compareEnd) {
      const newCompareUsageData = await this.getUsageData(compareStart, compareEnd);
      return newCompareUsageData;
    },
    async fetchBaseAndCompareData(baseStartDate, baseEndDate, compareStartDate, compareEndDate) {
      this.selectConfigUsageType.options = [];
      this.selectConfigUsageType = JSON.parse(JSON.stringify(this.selectConfigUsageType));
      this.baseUsageData = await this.fetchBaseData(baseStartDate, baseEndDate);
      this.masterBaseUsageData = [...this.masterBaseUsageData, ...this.baseUsageData];
      this.compareUsageData = await this.fetchCompareData(compareStartDate, compareEndDate);

      if (this.baseUsageData && this.baseUsageData.length  ||
          this.compareUsageData && this.compareUsageData.length) {
        this.refreshChart(this.baseUsageData, this.compareUsageData);
      } else {
        this.loadState = 'empty';
      }

      this.updateBackwardsButtonDiabledStatus();
    },
    incrementDate(inputDate, interval) {
      switch (interval) {
        case 'monthly':
          return {
            startDate: addYears(inputDate, 1),
            endDate: subDays(addYears(inputDate, 2),1)
          };
        case 'daily':
          return {
            startDate: addMonths(inputDate, 1),
            endDate: subDays(addMonths(inputDate, 2),1)
          };
        case 'hourly':
          return {
            startDate: addDays(inputDate, 1),
            endDate: addDays(inputDate, 1)
          };
      }
    },
    decrementDate(inputDate, interval) {
      switch (interval) {
        case 'monthly':
          const inputYear = inputDate.getFullYear();
          const monthlyStartDate = subYears(new Date(inputYear, 0, 1), 1);
          return {
            startDate: monthlyStartDate,
            endDate: subDays(addYears(monthlyStartDate,1),1)
          };
        case 'daily':
          const dailyStartDate = subMonths(inputDate,1);
          return {
            startDate: dailyStartDate,
            endDate: subDays(addMonths(dailyStartDate,1),1)
          };
        case 'hourly':
          const hourlyStartDate = subDays(startOfDay(inputDate), 1);
          return {
            startDate: hourlyStartDate,
            endDate: endOfDay(hourlyStartDate)
          };
      }
    },
    isBeforeEarliestBillingPeriod(dateToCheck) {
      return isBefore(dateToCheck, this.earliestBillingPeriod) || 
             isEqual(dateToCheck, this.earliestBillingPeriod);
    },
    updateBackwardsButtonDiabledStatus() {
      const baseUsageData = this.baseUsageData;
      const compareUsageData = this.compareUsageData;

      if (!baseUsageData || !baseUsageData.length
        || !compareUsageData || !compareUsageData.length
      ) {
        this.disableDateNavigationBackwardButton = true;
      } else {
        this.disableDateNavigationBackgardButton = false;
      }
    },
    async onDateBackwardsClicked() {
      const newBaseDates = this.decrementDate(this.selectedDate, this.interval);
      const newCompareDates = this.decrementDate(this.comparisonDate, this.interval);
      this.selectedDate = newBaseDates.startDate;
      this.comparisonDate = newCompareDates.startDate;
      await this.fetchBaseAndCompareData(newBaseDates.startDate, newBaseDates.endDate, newCompareDates.startDate, newCompareDates.endDate);

      this.updateBackwardsButtonDiabledStatus();
    },
    async onDateForwardsClicked() {
      this.disableDateNavigationBackwardButton = false;
      const newBaseDates = this.incrementDate(this.selectedDate, this.interval);
      const newCompareDates = this.incrementDate(this.comparisonDate, this.interval);
      this.selectedDate = newBaseDates.startDate;
      this.comparisonDate = newCompareDates.startDate;
      await this.fetchBaseAndCompareData(newBaseDates.startDate, newBaseDates.endDate, newCompareDates.startDate, newCompareDates.endDate);
    },
    async onSelectedBaseDateChanged(selectedDate) {
      this.selectedDate = selectedDate;
      const range = this.setDateRange(selectedDate, false);
      this.baseUsageData = await this.fetchBaseData(range.baseStartDate, range.baseEndDate);
      this.masterBaseUsageData = [...this.masterBaseUsageData, ...this.baseUsageData];
      if (this.baseUsageData && this.baseUsageData.length ||
          this.compareUsageData && this.compareUsageData.length) {
        this.refreshChart(this.baseUsageData, this.compareUsageData);
      } else {
        this.loadState = 'empty'
      }

      this.updateBackwardsButtonDiabledStatus();
    },
    async onSelectedCompareDateChangedSecondary(selectedDate) {
      this.comparisonDate = selectedDate;
      const range = this.setDateRange(selectedDate, true);
      this.compareUsageData = await this.fetchCompareData(range.compareStartDate, range.compareEndDate);
      this.masterCompareUsageData = [...this.masterCompareUsageData, ...this.compareUsageData];
      if (this.baseUsageData && this.baseUsageData.length ||
          this.compareUsageData && this.compareUsageData.length) {
        this.refreshChart(this.baseUsageData, this.compareUsageData);
      } else {
        this.loadState = 'empty'
      }

      this.updateBackwardsButtonDiabledStatus();
    },
    getMatchingOption(config, targetValue) {
      return config.options.find(item => item.value === targetValue);
    },
    setSelectConfigUsageType(responseValues) {
      // Updates the selectConfigUsageType options based on the provided responseValues. 
      // Filters out 'onPeak' and 'offPeak' options, adding them only if both are present in both the base and compare date data.
      // Updates the selectConfigUsageType.options to populate the select dropdown.
      
      const touExists = responseValues.some(value => value === 'onPeak' && value === 'offPeak');
      const ratesMap = new Map();

      for (const rate of rates) {
        rate.rounding = undefined;
        if (rate.name !== 'temperature') {
          ratesMap.set(rate.name, {
            label: rate.label,
            value: rate.name,
            color: rate.flatColor
          });
        }
      }

      const usageTypesSet = new Set();

      for (const value of responseValues) {
        const keys = Object.keys(value);
        for (const key of keys) {
          usageTypesSet.add(key);
        }
      }

      const usageTypes = Array.from(usageTypesSet);
      const filteredOptions = this.selectConfigUsageType.options.filter(option => option.value !== 'onPeak' && option.value !== 'offPeak');
      const newOptions = [];
      for (const usageType of usageTypes) {
        if (ratesMap.has(usageType)) {
          const usageTypeOption = ratesMap.get(usageType);
          const exists = this.selectConfigUsageType.options.some(option => option.value === usageTypeOption.value);
          if (!exists && !touExists) {
            newOptions.push(usageTypeOption);
          }
        }
      }

      this.selectConfigUsageType.options = [...filteredOptions, ...newOptions];
      this.selectConfigUsageType = JSON.parse(JSON.stringify(this.selectConfigUsageType));
    },
    setDateRange(selectedDate, compare) {
      let baseStartDate;
      let baseEndDate;
      let compareStartDate;
      let compareEndDate;

      const baseDate = selectedDate ? selectedDate : this.baseDate;
      const compareDate = compare ? selectedDate ? selectedDate : this.compareDate : null;
      if (!baseDate) { return; }
      const baseUsageYear = baseDate.getFullYear();
      const compareUsageYear = compare ? compareDate.getFullYear() : null;

      switch (this.interval) {
        case 'monthly':
          baseStartDate = new Date(baseUsageYear, 0, 1);
          baseEndDate = new Date(baseUsageYear, 11, 31);
          compareStartDate = compare ? new Date(compareUsageYear, 0, 1) : null;
          compareEndDate = compare ? new Date(compareUsageYear, 11, 31) : null;
          break;
        case 'daily':
          baseStartDate = this.getFirstDayOfMonth(baseDate);
          baseEndDate = this.getLastDayOfMonth(baseDate);
          compareStartDate = compare ? this.getFirstDayOfMonth(compareDate) : null;
          compareEndDate = compare ? this.getLastDayOfMonth(compareDate) : null;
          break;
        case 'hourly':
          baseStartDate = baseDate;
          baseEndDate = baseDate;
          compareStartDate = compare ? compareDate : null;
          compareEndDate = compare ? compareDate : null;
          break;
        default:
          throw new Error('Invalid interval');
      }

      return {
        baseEndDate,
        baseStartDate,
        compareStartDate,
        compareEndDate
      };
    },
    onOptionSelectedMonthlyDailyHourly(selectValue) {
      const matchingOption = this.getMatchingOption(this.selectConfigMonthlyDailyHourly, selectValue);

      if (matchingOption) {
        this.interval = matchingOption.value;
        this.comparisonDetailsUsageInterval = matchingOption.label;
        this.selectConfigMonthlyDailyHourly.selectedLabel = matchingOption.label;
        this.selectConfigMonthlyDailyHourly.selectedValue = matchingOption.value;
        this.selectConfigMonthlyDailyHourly = JSON.parse(JSON.stringify(this.selectConfigMonthlyDailyHourly));
        this.initializeData();
      }
    },
    onOptionSelectedUsageType(selectedValue) {
      const interval = this.interval;
      const _self = this;
      const compareBarColor = this.compareBarColor;
      const matchingOption = this.getMatchingOption(this.selectConfigUsageType, selectedValue);
      if (matchingOption) {
        this.selectConfigUsageType.selectedLabel = matchingOption.label;
        this.selectConfigUsageType.selectedValue = matchingOption.value;
        this.comparisonDetailsUsageType = matchingOption.label;
        this.selectedUsageTypeColor = matchingOption.color;
      }
      this.selectConfigUsageType = JSON.parse(JSON.stringify(this.selectConfigUsageType));

      const baseUsageData = this.baseUsageData;
      const compareUsageData = this.compareUsageData;
      const usageType = this.selectConfigUsageType.selectedValue;
      const selectedUsageTypeColor = this.selectedUsageTypeColor;
      
      this.chart.update({
        tooltip: {
          formatter: function () {
            const index = this.point.index;
            const baseIndex = baseUsageData[index];
            const compareIndex = compareUsageData[index];

            let html = `<div class='my-account__usage-tooltip'>`;
            if (baseIndex && baseIndex[usageType]) {
              const baseDate = _self.formatTooltipDate(new Date(baseIndex.date), interval);
              html += `
                <div class='tooltip-rate gds-flex-container gds-flex-container--space-between'>
                  <div class='gds-flex-container gds-flex-container--left'>
                    <div class='rate-swatch' style='background-color: ${selectedUsageTypeColor}'></div>
                    <div class='rate-name'>${baseDate}</div>
                  </div>
                  <div class='rate-value'>${interval === 'hourly' ? baseIndex[usageType].toFixed(2) : Math.floor(baseIndex[usageType])} kWh</div>
                </div>
              `;
            }
            if (compareIndex && compareIndex[usageType]) {
              const compareDate = _self.formatTooltipDate(new Date(compareIndex.date), interval);
              html += `
                <div class='tooltip-rate gds-flex-container gds-flex-container--space-between'>
                  <div class='gds-flex-container gds-flex-container--left'>
                    <div class='rate-swatch' style='background-color: ${compareBarColor}'></div>
                    <div class='rate-name'>${compareDate}</div>
                  </div>
                  <div class='rate-value'>${interval === 'hourly' ? compareIndex[usageType].toFixed(2) : Math.floor(compareIndex[usageType])} kWh</div>
                </div>
              `;
            }
            html += '</div>';

            return html;
          }
        }
      })
      this.refreshLayers();
    },
    setControlWidths() {
      //TODO: Why do we need to encode and decode in order to get the correct value?
      this.selectConfigMonthlyDailyHourly = JSON.parse(JSON.stringify(this.selectConfigMonthlyDailyHourly));
      this.selectConfigUsageType = JSON.parse(JSON.stringify(this.selectConfigUsageType));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>