<template>
  <div>
    <div v-if="loadState === undefined" class="table-loading" />
    <flow-error v-if="loadState === 'error'" name="Information for the specified town" state="error" />
    <div v-if="loadState === 'complete'">
      <outage-breadcrumbs :town="town" />

      <div class="town-incident__row gds-flex-container" :class="{ collapsed: detailCollapsed }">
        <div class="town-incident__column--details town-page">
          <div class="gds-space-inset-l">
            <div class="gds-flex-container gds-flex-container--space-between gds-space-stack-m">
              <h1 class="town-title gds-space-inline-s">{{ town.town_name }}, Vermont</h1>
              <a href="/report-outage"
                class="report_outage_btn gds-button gds-orange gds-compact outage-compact-flat-button">Report Outage</a>
            </div>
            <!-- Town metrics -->
            <div v-if="townIncidents && townIncidents.length"
              class="town-incident__column--metrics gds-flex-container gds-flex-container--left gds-flex-container--top">
              <div class="outage-incident__data-column">
                <div class="outage-incident__data-column--large-text">{{ townIncidents.length }}</div>
                <div class="gds-font-size-s gds-font-demi gds-text-browngrey">Active Incidents</div>
              </div>

              <div class="outage-incident__data-column">
                <div class="outage-incident__data-column--large-text">{{ town.customers_affected }}</div>
                <div class="gds-font-size-s gds-font-demi gds-text-browngrey">{{ town.town_name }} Customers Out</div>
              </div>

              <div class="outage-incident__data-column">
                <div class="outage-incident__data-column--large-text">{{ (town.affected_percent * 100) | round(0) }}%
                </div>
                <div class="gds-font-size-s gds-font-demi gds-text-browngrey">Of {{ town.town_name }} Customers Out
                </div>
              </div>
            </div>
            <!-- /flex-container -->
          </div>
          <!--gds-space-inset-ml-->

          <!-- Messages -->
          <!-- <a v-if="FF_OutagesMessaging" href="" class="outage-messages__notification-button">
            <div class="gds-flex-container">
              <img class="outage-messages__notification-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/outage-center/messages-icon.svg" />
              <span><b>3</b> Recent Messages</span>
            </div>
            <svg class="gds-icon">
              <use
                xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
            </svg>
          </a> -->

          <!-- Planned outages -->
          <div v-if="FF_PlannedOutage">
            <div v-for="plannedOutage of plannedOutages" :key="plannedOutage.id"
              class="planned-outage__message-box gds-flex-container gds-flex-container--space-between gds-flex-container--top">
              <div class="planned-outage__message-box--text">A planned outage on {{ plannedOutage.occursAt |
                  dateTimeFullAt
              }} will affect some customers in {{ town.town_name }} VT.</div>

              <router-link
                :to="plannedOutage.incidentId ? '/incident/' + plannedOutage.incidentId : '/planned-outages/' + plannedOutage.id.toString()"
                class="planned-outage__message-box--link">More Info</router-link>
            </div>
          </div>

          <!-- Incident list -->
          <div v-if="townIncidents && townIncidents.length">
            <vue-good-table :columns="columns" :rows="townIncidents"
              :sort-options="{ enabled: true, initialSortBy: { field: 'customerCount', type: 'desc' } }"
              :pagination-options="{ enabled: townIncidents.length > 8, perPage: 8, perPageDropdownEnabled: false }"
              styleClass="vgt-table town-page-table outage-table" @on-row-click="incidentRowClick"
              @on-row-mouseenter="incidentRowHover($event.row.id)" @on-row-mouseleave="incidentRowHover(null)">
              <template slot="table-row" slot-scope="props">
                <template v-if="props.column.field === 'active'">
                  <div v-if="props.row.customerCount > 0" class="alert-icon active" />
                  <div v-else class="alert-icon inactive" />
                </template>
                <template v-else-if="props.column.field === 'street'">
                  <div class="gds-text--capitalize gds-font-demi">
                    {{ (props.row.street ? props.row.street.toLowerCase() : "")
                        + (props.row.street && props.row.remoteTown ? ", " : "")
                        + (props.row.remoteTown ? props.row.town : "")
                    }}
                    <span class="forceLowercase nowrap">{{ props.row.counter }}</span>
                  </div>
                  <div v-if="(props.row.townsAffected > 1)" class="incident-subtext">Multi-town incident</div>
                  <template>
                    {{ formatEtr(props.row.estimatedRestoredAt, props.row.etrEarliest, props.row.etrLatest) }}
                  </template>
                </template>
                <template v-else-if="props.column.field === 'status'">{{ props.row.status.code | incidentStatus
                }}</template>
                <template v-else-if="props.column.field === 'arrow'">
                  <svg class="gds-icon">
                    <use
                      xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right" />
                  </svg>
                </template>
                <template v-else>{{ props.formattedRow[props.column.field] }}</template>
              </template>
              <div slot="emptystate">No matching towns</div>
            </vue-good-table>
          </div>
          <div v-else class="town-incident__column--details--no-outages">
            <div class="gds-flex-container">
              <div class="no-outage-icon">
                <img
                  src="/wp-content/themes/gmptwentynineteen/assets/images/outage-center/clipboard-with-green-check.svg" />
              </div>
              <div class="gds-font-size-l">
                There are no reported outages in
                <br />
                {{ town.town_name }}, Vermont
              </div>
              <a href="/report-outage" class="report_outage_btn gds-button gds-round gds-secondary gds-compact">Report
                Outage</a>
            </div>
          </div>
        </div>
        <div class="town-incident__column--map gds-position--relative">
          <button @click="ToggleCollapsed" :class="{ collapsed: detailCollapsed }" class="incident-row-collapse-toggle"
            :title="(detailCollapsed ? 'View' : 'Close') + ' Town Details'">
            <span class="incident-row-collapse-toggle--text">View Town Details</span>
          </button>
          <!-- Town map -->
          <gmp-map ref="map" kind="town" :townName="town.town_name" @centered="isCentered = $event"
            @update="onMapUpdate" />
          <div v-if="!isCentered" @click="Recenter()" :class="{ collapsed: detailCollapsed }" id="recenter-button"
            class="recenter-button gds-button gds-round gds-secondary">Back to {{ town.town_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import { DumpError, HyphenateTown, FormatEstimatedRestoreTime } from "../../utilities";
import GmpMap from "./GmpMap.vue";
import OutageBreadcrumbs from "./OutageBreadcrumbs.vue";
import { isFeatureEnabled } from "../../services/featureflags";

export default {
  name: "SubpageTownView",
  components: {
    GmpMap,
    OutageBreadcrumbs,
  },
  data() {
    return {
      FF_PlannedOutage: undefined,
      FF_OutagesMessaging: false,
      town: undefined,
      townIncidents: undefined,
      isCentered: true,
      detailCollapsed: false,
      plannedOutages: [],

      columns: [
        {
          label: "",
          field: "active",
          sortable: false,
        },
        {
          label: "Incident Location",
          field: "street",
        },
        {
          label: "Customers Out",
          field: "customerCount",
          type: "number",
          firstSortType: "desc",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
        },
        {
          label: "",
          field: "arrow",
          sortable: false,
        }
      ],
      loadState: undefined,
    };
  },
  computed: {
    townname() {
      return this.$route.params.townname.toLowerCase();
    },
  },
  async mounted() {
    this.loadState = undefined;
    await this.getConfigs();
    try {
      // This is kind of backwards. To load the map, we need the real town name.
      // But we would ordinarily get the town name by looking it up in the map data.
      // So we'll prime the pump the first time with a manual data load.
      const [activeIncidents, townData] = await Promise.all([
        GMPAPI.GetAllIncidents(),
        GMPAPI.GetTownData(),
      ]);
      this.onMapUpdate({ townData, activeIncidents });
      this.loadState = "complete";
    } catch (err) {
      DumpError("Town refresh error", err);
      this.loadState = "error";
    }
  },
  methods: {
    formatEtr(etr, etrEarly, etrLate) {
      return FormatEstimatedRestoreTime(etr, etrEarly, etrLate);
    },
    async getConfigs() {
      this.FF_PlannedOutage = await isFeatureEnabled('FF_PlannedOutage', false);
      // this.FF_OutagesMessaging = await isFeatureEnabled('FF_OutagesMessaging', false);
    },
    /** Use the copies of personalData, townData, activeIncidents the map is pulling anyway, rather than pulling them ourselves an extra time */
    onMapUpdate(apiData) {
      if (!apiData) return;
      if (apiData.plannedOutages && this.FF_PlannedOutage) {
        this.plannedOutages = apiData.plannedOutages.filter(planned => planned.customersByTown && Object.keys(planned.customersByTown).find(item => HyphenateTown(item) === this.townname));
      }
      if (apiData.townData && apiData.activeIncidents) {
        const myTown = apiData.townData.find(item => HyphenateTown(item.town_name) === this.townname);
        if (myTown) this.town = myTown;
        const myIncidents = [];
        for (const incident of apiData.activeIncidents) { 
          let subTown = undefined;
          if (incident.customersByTown) {
            subTown = Object.keys(incident.customersByTown).find(
              item =>
                HyphenateTown(item) ===
                this.townname
            );
          }
          if (
            HyphenateTown(incident.town) ===
            this.townname ||
            subTown
          ) {
            const incidentCopy = JSON.parse(JSON.stringify(incident));
            incidentCopy.townsAffected = Object.keys(
              incidentCopy.customersByTown
            ).length;
            if (subTown) {
              // Adjust the customers affected to just the ones from this town
              incidentCopy.customerCount =
                incidentCopy.customersByTown[subTown];
              // Display the town name in the incident location
              incidentCopy.remoteTown = true;
              // Display the "multi-town" text
              incidentCopy.townsAffected = Object.keys(
                incidentCopy.customersByTown
              ).length;
            }
            myIncidents.push(incidentCopy);
            // TODO: Uncomment on a town with incidents to test pagination
            // myIncidents.push(incidentCopy);
            // myIncidents.push(incidentCopy);
            // myIncidents.push(incidentCopy);
            // myIncidents.push(incidentCopy);
          }
        }
        this.townIncidents = myIncidents;
      }
    },
    incidentRowClick(params) {
      this.$router.push({ path: "/incident/" + params.row.id })
    },
    incidentRowHover(incidentId) {
      if (this.$refs.map) {
        this.$refs.map.SetIncidentHover(incidentId);
      }
    },
    Recenter() {
      if (this.$refs.map) {
        this.isCentered = true;
        this.$refs.map.ZoomToTown(this.town.town_name);
      }
    },
    async ToggleCollapsed() {
      this.detailCollapsed = !this.detailCollapsed;
      await this.$nextTick();
      if (this.$refs.map) {
        this.$refs.map.Resize();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>