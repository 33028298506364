var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      { staticClass: "eicproduct-success-fail", attrs: { id: "top-target" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "gds-display-2 gds-align--text-center gds-space-stack-ml"
          },
          [_vm._v("Oh no!")]
        ),
        _vm._v(" "),
        _vm.code === 1
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("No data matching the search criteria was found.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 15
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [
                _vm._v(
                  "You do not have permission to perform the requested action."
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 30
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("The account number entered was not valid.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 62
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("The input did not pass data validation.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 75
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("The product type is invalid.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 500
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("An error occurred with the web service.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.code === 140
          ? _c(
              "div",
              {
                staticClass:
                  "error-1002 form-message-box gds-space-stack-l gds-align--text-center"
              },
              [_vm._v("An error occurred with the web service.")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "bill-pay-fail__description gds-body-large gds-align--text-center gds-space-stack-xl"
          },
          [
            _vm._v(
              "\n      We were unable to process your request to participate in the EV charger program at this time. Please try again or contact us for help at\n      "
            ),
            _c("span", { staticClass: "gds-nobreak" }, [
              _vm._v(_vm._s(_vm.GMPPhoneNumber || "1-(888)-835-4672") + ".")
            ]),
            _vm._v(" "),
            _c("br")
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "bill-pay-success__button-wrap gds-align--text-center gds-space-stack-l"
          },
          [
            _c(
              "button",
              {
                staticClass: "bill-pay-success__button gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.ReturnToSender("/")
                  }
                }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "bill-pay-fail__intro-image gds-space-stack-xl" },
      [
        _c("img", {
          staticClass: "my-account__payment-plan-icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/pay-fatal-error.svg",
            alt: "unsuccessful payment"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }