<template>
  <div class="subpage-dashboards subpage-legacy-usage--graph">
    <div class="banner-container">
      <banner-one-action
        v-if="FF_TestDriveUsage"
        bannerColor="blue"
        message="We are updating our Usage dashboard. Would you like to check it out?"
        linkText="Yeah! I'll give it a try."
        @buttonFunction="goToNewUsage" />
    </div>
    <h1 class="my-account__title gds-space-stack-l subpage-dashboards--left-spacer">Usage</h1>
    <div v-if="loadState === undefined" class="table-loading" />
    <div class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable" img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>
    <div v-if="loadState === 'complete'">
      <div class="my-account__usage-controls subpage-dashboards--left-spacer">
        <fieldset class="my-account__usage--frequency gds-fieldset">
          <label class="gds-input-field">
            <select v-model="drillDropInterval" @change="DrillDropChange" class="my-account__usage--select gds-input-field">
              <option value="monthly">Monthly</option>
              <option value="daily">Daily</option>
              <option value="hourly">Hourly</option>
            </select>
          </label>
        </fieldset>
        <div class="my-account__usage--toggle-export-wrap gds-position--relative">
          <button @click="ToggleLayersMenu" class="my-account__usage--layers-toggle caret caret-down gds-space-inline-m">
            <span class="usage--toggle-text">Toggle</span>Layers
          </button>
          <button @click="showExport = true" class="usage--export-data-button gds-button gds-compact">Export Data</button>
          <div v-if="showMenu" v-click-outside="CloseLayersMenu" class="my-account__usage--layers-menu">
            <div v-for="layer of layers" :key="layer.label">
              <label class="gds-checkbox gds-space-stack-s">
                <input v-model="layer.selected" type="checkbox" />
                <span class="gds-checkbox__faux" :style="{ 'background-color': layer.flatColor }"></span>
                <span class="gds-font-demi gds-font-size-s">{{layer.label}}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <section class="my-account__usage-graphs gds-space-stack-l">
        <div class="my-account__usage-date-controllers gds-flex-container gds-space-inset-l">
          <button @click="NavLeft()" class="my-account__usage-date-controllers--button">
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-left" />
            </svg>
          </button>
          <div class="my-account__usage-date-controllers--date gds-font-size-l gds-font-demi">{{intervalString}}</div>
          <button @click="NavRight()" class="my-account__usage-date-controllers--button">
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
            </svg>
          </button>
        </div>

        <usage-chart ref="chartTop" @click="ClickTop" @IntervalAdjust="IntervalAdjust" :usageData="binsTop" :layers="visibleLayerNames" height="300px" />
        <usage-chart
          v-if="drillDepth === 'hourly' || drillDepth === 'daily'"
          ref="chartMiddle"
          @click="ClickMiddle"
          :usageData="binsMiddle"
          :mini="true"
          :selected="selectedMiddle"
          :layers="visibleLayerNames"
          height="150px"
        />
        <usage-chart
          v-if="drillDepth === 'hourly'"
          ref="chartBottom"
          :usageData="binsBottom"
          @click="ClickBottom"
          :mini="true"
          :selected="selectedBottom"
          :layers="visibleLayerNames"
          height="150px"
        />
      </section>

      <section class="my-account__usage-graph-key gds-space-stack-xl">
        <div
          class="my-account__usage-help-text gds-font-size-s gds-align--text-center"
        >To get even more detailed information, double click on the vertical bars of any month or day.</div>
        <div class="my-account__usage-graph-key--list gds-flex-container">
          <div v-for="layer of visibleLayers" :key="layer.label" class="my-account__usage-graph-key--list-item">
            <div class="gds-flex-container gds-flex-container--left">
              <div class="my-account__usage-graph-key--color-box" :style="{ 'background-color': layer.flatColor }"></div>
              <div class="gds-font-size-s">{{layer.label}}</div>
            </div>
          </div>
        </div>

        <button @click="showExport = true" class="usage--second-export-data-button gds-button">Export Data</button>
      </section>

      <modal-export-data :visible="showExport" @complete="ExportComplete" />
    </div>
  </div>
</template>

<script>
import UsageChart from "./UsageChart";
import { GetAllRates } from './usagedata';
import GMPAPI from '../../../services/gmpapi';
import { addMonths, addDays, addSeconds, format, startOfMonth, endOfMonth, startOfDay, endOfDay, startOfYear, endOfYear, startOfYesterday, startOfToday, parseISO, isFuture, subYears } from 'date-fns';
import { ToServerDateTruncate, DumpError } from '../../../utilities';
import ModalExportData from "./ModalExportData";
import { GetComponentStatus } from '../../../services/statuspage';
import BannerOneAction from "../../BannerOneAction.vue";
import { isFeatureEnabled } from "../../../services/featureflags";

export default {
  name: "SubpageUsage",
  components: {
    UsageChart,
    ModalExportData,
    BannerOneAction
  },
  data() {
    return {
      drillDepth: "monthly",
      usageYear: new Date().getFullYear(),
      layers: undefined,
      binsTop: undefined,
      binsMiddle: undefined,
      binsBottom: undefined,
      selectedMiddle: undefined,
      selectedBottom: undefined,
      showMenu: false,
      intervalDate: undefined,
      drillDropInterval: "monthly",
      showExport: false,
      queryStartDate: undefined,
      queryResolution: undefined,
      queryLayers: undefined,
      loadState: undefined,
      FF_TestDriveUsage: undefined
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    visibleLayers() {
      if (!this.layers) return [];
      return this.layers.filter(item => item.selected);
    },
    visibleLayerNames() {
      return this.visibleLayers.map(item => item.label);
    },
    /** Format the current interval date as a string */
    intervalString() {
      if (!this.intervalDate) return undefined;
      switch (this.drillDepth) {
        case "monthly":
          return this.usageYear;
          break;
        case "daily":
          return format(this.intervalDate, "MMM yyyy");
          break;
        case "hourly":
          return format(this.intervalDate, "MMM d, yyyy");
          break;
      }
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async mounted() {
    this.getConfigs();
    this.RefreshData();
  },
  methods: {
    async getConfigs() {
      this.FF_TestDriveUsage = await isFeatureEnabled('FF_TestDriveUsage', false);
      if(!this.FF_TestDriveUsage) {
        this.$store.commit("setTestDriveUsage", false);
      }
    },
    async RefreshData() {
      // Reset all data and start at current year
      this.usageYear = new Date().getFullYear();
      this.drillDepth = "monthly";
      this.layers = undefined;
      this.binsTop = undefined;
      this.binsMiddle = undefined;
      this.binsBottom = undefined;
      this.selectedMiddle = undefined;
      this.selectedBottom = undefined;
      this.showMenu = false;
      this.intervalDate = undefined;
      this.drillDropInterval = "monthly";
      this.loadState = undefined;

      // Before we do anything else, check for outages
      const status = await GetComponentStatus("Usage");
      if (status !== "operational" && !(this.isAdmin)) {
        this.loadState = status;
        return;
      }

      // Load options from query parameters
      const params = new URLSearchParams(window.location.search);
      // Note - "account" is already handled in userrefresh.js
      if (params.has("startDate")) {
        const tempstart = parseISO(params.get("startDate"));
        // Ignore if date is in the future
        if (!isFuture(tempstart)) {
          this.queryStartDate = tempstart;
          this.usageYear = this.queryStartDate.getFullYear();
        }
      }
      if (this.queryStartDate && params.has("resolution")) {
        this.queryResolution = params.get("resolution");
      }
      if (params.has("layers")) {
        this.queryLayers = params.get("layers").split(",");
      }
      // Remove the query params and replace the URL
      params.delete("startDate");
      params.delete("resolution");
      params.delete("layers");
      let query = params.toString();
      if (query) query = "?" + query;
      const truncated = window.location.origin + window.location.pathname + query + window.location.hash;
      window.history.replaceState(null, "", truncated);

      const yearStart = new Date(this.usageYear, 0, 1);
      const yearEnd = new Date(this.usageYear, 11, 31);

      try {
        await this.UpdateChart(yearStart, yearEnd);
        this.loadState = "complete";
        return;

      } catch (err) {
        try {
          await this.UpdateChart(subYears(yearStart, 1), subYears(yearEnd, 1));
          this.usageYear = subYears(yearStart, 1).getFullYear();
          this.loadState = "complete";
          return;

        } catch (error) {
          if (err && err.response && err.response.data && err.response.data.errorCode === "MDM_NO_ACTIVE_SERVICES") {
            this.loadState = "unavailable";
            return;
          }

          DumpError("Usage page refresh error", err);
          this.loadState = "error";
          return;
        }
      }
    },
    async UpdateChart(yearStart, yearEnd) {
      await this.UpdateTopChart("monthly", yearStart, yearEnd);
        this.layers = GetAllRates(this.binsTop.bins);
        // Sometimes (acct 1001520000) usage comes back valid but empty of data
        if (this.layers.length === 0) {
          this.loadState = "unavailable";
          return;
        } else {
          this.loadState = "complete";
        }
        if (this.queryLayers) {
          // Make sure there is an intersection between queried and available layers
          if (this.queryLayers.find(queried => this.layers.find(layer => layer.name === queried))) {
            // If so, set layer selection according to queried layer names
            for (const layer of this.layers) {
              layer.selected = this.queryLayers.includes(layer.name);
            }
          }
        }
        // If query string says to drill down, fake clicks to do so
        if (this.queryResolution) {
          if (this.queryResolution === "daily" || this.queryResolution === "hourly") {
            let month = this.binsTop.bins.length - 1;
            if (this.queryStartDate) {
              month = this.queryStartDate.getMonth();
            }
            // First drill down to daily view
            await this.ClickTop(month);
            if (this.queryResolution === "hourly") {
              let day = this.binsTop.bins.length - 1;
              if (this.queryStartDate) {
                day = this.queryStartDate.getDate() - 1;
              }
              // Finally drill down to hourly view
              await this.ClickTop(day);
            }
          }
        }
    },
    /** Perform a drilldown on an interval of the top chart */
    async ClickTop(index) {
      if (this.binsTop.bins.length <= index) return;
      const date = this.binsTop.bins[index].date;
      // Disallow future dates
      if (date > startOfToday()) return;
      if (this.drillDepth === "monthly") {
        this.drillDepth = "daily";
        this.drillDropInterval = "daily";

        // Shift data / selections down
        this.binsMiddle = this.binsTop;
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("daily", date, addSeconds(addMonths(date, 1), -1));
      } else if (this.drillDepth === "daily") {
        this.drillDepth = "hourly";
        this.drillDropInterval = "hourly";

        // Shift data / selections down
        this.binsBottom = this.binsMiddle;
        this.selectedBottom = this.selectedMiddle;
        this.binsMiddle = this.binsTop;
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("hourly", date, addSeconds(addDays(date, 1), -1));
      } else if (this.drillDepth === "hourly") {
        // Already drilled down fully, do nothing
      }
    },
    /** Perform a selection on an interval of the middle chart */
    async ClickMiddle(index) {
      const date = this.binsMiddle.bins[index].date;
      // Disallow future dates
      if (date > startOfToday()) return;
      if (this.drillDepth === "daily") {
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("daily", date, addSeconds(addMonths(date, 1), -1));
      } else if (this.drillDepth === "hourly") {
        this.selectedMiddle = index;

        // Refresh the top chart with the selected data
        await this.UpdateTopChart("hourly", date, addSeconds(addDays(date, 1), -1));
      }
    },
    /**
     * Perform a selection on an interval of the bottom chart
     * rightmost should be true if scrolling left, ie select the rightmost item
     */
    async ClickBottom(index, rightmost) {
      const date = this.binsBottom.bins[index].date;
      // Disallow future dates
      if (date > startOfToday()) return;
      if (this.drillDepth === "hourly") {
        this.selectedBottom = index;
        let topdate = date;
        if (rightmost) {
          topdate = addDays(addMonths(date, 1), -1);
        }

        // Refresh the middle and top charts
        await Promise.all([
          this.UpdateMiddleChart("daily", date, addSeconds(addMonths(date, 1), -1), rightmost),
          this.UpdateTopChart("hourly", topdate, addSeconds(addDays(topdate, 1), -1)),
        ]);
      }
    },
    /** Refresh usage data for the top chart */
    async UpdateTopChart(interval, dateStart, dateEnd, rightmost) {
      // Set top chart to "loading"
      this.binsTop = undefined;
      this.intervalDate = undefined;

      const usage = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, interval, ToServerDateTruncate(dateStart), ToServerDateTruncate(dateEnd));
      this.intervalDate = dateStart;
      this.binsTop = { interval, bins: usage.intervals[0].values };
      if (rightmost) {
        // We never select the top chart, but rightmost means scroll to the right (ie if we've navigated over from the interval to the right)
        this.$refs.chartTop.ScrollFarRight();
      }
    },
    ToggleLayersMenu() {
      // Check value
      if (this.showMenu) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
    CloseLayersMenu() {
      this.showMenu = false;
    },
    /**
     * Only called when clicking the bottom chart. Ordinarily, middle chart is populated with the existing data from the top chart.
     * rightmost should be true if scrolling left, ie select the rightmost item
     */
    async UpdateMiddleChart(interval, dateStart, dateEnd, rightmost) {
      // Set middle chart to "loading"
      this.binsMiddle = undefined;

      const usage = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, interval, ToServerDateTruncate(dateStart), ToServerDateTruncate(dateEnd));
      this.binsMiddle = { interval, bins: usage.intervals[0].values };
      this.selectedMiddle = rightmost ? this.binsMiddle.bins.length - 1 : 0;
    },
    async UpdateBottomChart(interval, dateStart, dateEnd, rightmost) {
      // Set bottom chart to "loading"
      this.binsBottom = undefined;

      const usage = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, interval, ToServerDateTruncate(dateStart), ToServerDateTruncate(dateEnd));
      this.binsBottom = { interval, bins: usage.intervals[0].values };
      this.selectedBottom = rightmost ? this.binsBottom.bins.length - 1 : 0;
    },
    /** Right nav button clicked */
    async NavRight() {
      this.$refs.chartTop.Navigate("right");
    },
    /** Left nav button clicked */
    async NavLeft() {
      this.$refs.chartTop.Navigate("left");
    },
    /**
     * Navigate left or right of the current topmost interval
     * Fired by the chart itself if unable to scroll the scrollbar any more
     */
    async IntervalAdjust(direction) {
      if (!this.intervalDate) return;
      if (direction === "left") {
        if ((this.drillDepth === "daily" || this.drillDepth === "hourly") && this.selectedMiddle > 0) {
          // Room to go left on the middle track
          this.ClickMiddle(this.selectedMiddle - 1);
        } else {
          if (this.drillDepth === "hourly" && this.selectedBottom > 0) {
            // Room to go left on the bottom track - wrap the middle track around to rightmost
            this.ClickBottom(this.selectedBottom - 1, true);
          } else {
            // Go to previous year
            this.usageYear--;
            const yearStart = new Date(this.usageYear, 0, 1);
            const yearEnd = new Date(this.usageYear, 11, 31);
            if (this.drillDepth === "monthly") {
              await this.UpdateTopChart("monthly", yearStart, yearEnd, true);
            } else if (this.drillDepth === "daily") {
              // Refresh the middle and top charts
              await Promise.all([
                this.UpdateMiddleChart("monthly", yearStart, yearEnd, true),
                this.UpdateTopChart("daily", startOfMonth(yearEnd), endOfMonth(yearEnd), true),
              ]);
            } else if (this.drillDepth === "hourly") {
              // Refresh all charts
              await Promise.all([
                this.UpdateBottomChart("monthly", yearStart, yearEnd, true),
                this.UpdateMiddleChart("daily", startOfMonth(yearEnd), endOfMonth(yearEnd), true),
                this.UpdateTopChart("hourly", startOfDay(yearEnd), endOfDay(yearEnd), true),
              ]);
            }
          }
        }
      } else {
        if ((this.drillDepth === "daily" || this.drillDepth === "hourly") && this.binsMiddle && this.selectedMiddle < this.binsMiddle.bins.length - 1) {
          // Room to go right on the middle track
          this.ClickMiddle(this.selectedMiddle + 1);
        } else {
          if (this.drillDepth === "hourly" && this.binsBottom && (this.selectedBottom < this.binsBottom.bins.length - 1)) {
            // Room to go right on the bottom track (will also refresh middle track)
            this.ClickBottom(this.selectedBottom + 1);
          } else {
            // Disallow future dates
            if (new Date(this.usageYear + 1, 0, 1) > startOfToday()) return;
            // Go to next year
            this.usageYear++;
            const yearStart = new Date(this.usageYear, 0, 1);
            const yearEnd = new Date(this.usageYear, 11, 31);
            if (this.drillDepth === "monthly") {
              await this.UpdateTopChart("monthly", yearStart, yearEnd);
            } else if (this.drillDepth === "daily") {
              // Refresh the middle and top charts
              await Promise.all([
                this.UpdateMiddleChart("monthly", yearStart, yearEnd, false),
                this.UpdateTopChart("daily", startOfMonth(yearStart), endOfMonth(yearStart)),
              ]);
            } else if (this.drillDepth === "hourly") {
              console.log("hourly");
              // Refresh all charts
              await Promise.all([
                this.UpdateBottomChart("monthly", yearStart, yearEnd, false),
                this.UpdateMiddleChart("daily", startOfMonth(yearStart), endOfMonth(yearStart), false),
                this.UpdateTopChart("hourly", startOfDay(yearStart), endOfDay(yearStart)),
              ]);
            }
          }
        }
      }
    },
    /** Handle changing intervals with the dropdown rather than clicking */
    async DrillDropChange(e) {
      const oldInterval = this.drillDepth;
      const newInterval = this.drillDropInterval;
      const yesterday = startOfYesterday();
      switch (oldInterval) {
        case "monthly":
          switch (newInterval) {
            case "monthly":
              // No change
              break;
            case "daily":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "daily";
              await Promise.all([
                this.UpdateMiddleChart("monthly", startOfYear(yesterday), endOfYear(yesterday)),
                this.UpdateTopChart("daily", startOfMonth(yesterday), endOfMonth(yesterday)),
              ]);
              this.selectedMiddle = yesterday.getMonth();
              break;
            case "hourly":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "hourly";
              await Promise.all([
                this.UpdateBottomChart("monthly", startOfYear(yesterday), endOfYear(yesterday)),
                this.UpdateMiddleChart("daily", startOfMonth(yesterday), endOfMonth(yesterday)),
                this.UpdateTopChart("hourly", startOfDay(yesterday), endOfDay(yesterday)),
              ]);
              this.selectedMiddle = yesterday.getDate() - 1;
              this.selectedBottom = yesterday.getMonth();
              break;
          }
          break;
        case "daily":
          switch (newInterval) {
            case "monthly":
              this.binsTop = this.binsMiddle;
              this.selectedTop = this.selectedMiddle;
              this.drillDepth = "monthly";
              break;
            case "daily":
              // No change
              break;
            case "hourly":
              // Going down, always refresh to target yesterday
              this.usageYear = yesterday.getFullYear();
              this.drillDepth = "hourly";
              await Promise.all([
                this.UpdateBottomChart("monthly", startOfYear(yesterday), endOfYear(yesterday)),
                this.UpdateMiddleChart("daily", startOfMonth(yesterday), endOfMonth(yesterday)),
                this.UpdateTopChart("hourly", startOfDay(yesterday), endOfDay(yesterday)),
              ]);
              this.selectedMiddle = yesterday.getDate() - 1;
              this.selectedBottom = yesterday.getMonth();
              break;
          }
          break;
        case "hourly":
          switch (newInterval) {
            case "monthly":
              this.binsTop = this.binsBottom;
              this.selectedTop = this.selectedBottom;
              this.drillDepth = "monthly";
              break;
            case "daily":
              this.binsTop = this.binsMiddle;
              this.selectedTop = this.selectedMiddle;
              this.binsMiddle = this.binsBottom;
              this.selectedMiddle = this.selectedBottom;
              this.drillDepth = "daily";
              break;
            case "hourly":
              // No change
              break;
          }
          break;
      }
    },
    async ExportComplete() {
      this.showExport = false;
    },
    async goToNewUsage() {
      this.$store.commit("setTestDriveUsage", true);
      this.$router.push({ path: "/usage-new" });

      await GMPAPI.SubmitCSSLogging(this.currentAccount.accountNumber, this.$store.state.user.userinfo.username, "SWITCH_TO_NEW_USAGE", "Switched to new usage.", true);
    },
  },
  watch: {
    // Refresh whenever the account changes
    async currentAccount() {
      await this.RefreshData();
    }
  },
};
</script>
