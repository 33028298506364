<template>
  <div>
    <transition name="slat-message-fade">
      <div class="gds-space-stack-l scheduled-payments-list">
        <div :class="`form-message-box form-message-box--${bannerColor} form-message-box--full-width gds-space-stack-s gds-flex-container gds-flex-container--space-between`">
          <div class="form-message-box--left">
            <div class="gds-body-normal">{{ message }}</div>
          </div>
          <div class="form-message-box--right">
            <div style="text-align: right;">
              <a v-if="!showResultMessage" @click="buttonFunction" :href="url" class="gds-link gds-link--bold cursorPointer">{{ linkText }}</a>
              <div v-if="showResultMessage" class="gds-text--bold">{{ resultMessage }}</div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "BannerOneAction",
  props: {
    bannerColor: String,
    message: String,
    linkText: String,
    url: String,
    resultMessage: String,
  },
  data() {
    return {
      isRefreshing: true,
      showResultMessage: false
    }
  },
  computed: {
  },
  mounted() {
    setTimeout(() => {
      this.isRefreshing = false
    }, 3000)
  },
  methods: {
    buttonFunction() {
      this.$emit('buttonFunction');
      if (this.resultMessage) {
        this.showResultMessage = true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
