<template>
  <div>
      <div class="table-bar" :style="{ width: barWidthTotalPercent + '%'}">
        <div v-if="this.series1 > 0 || isZeroWidth" class="series1" :style="{ width: barWidthSeries1Percent + '%'}"></div>
        <div v-if="this.series2 > 0" class="series2" :style="{ width: barWidthSeries2Percent + '%'}"></div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'UsageConsumptionBarCSS',
    props: {
      series1: {
        type: Number,
        default: 0
      },
      series2: {
        type: Number,
        default: 0
      },
      maxUsageValue: {
        type: Number,
        default: 100
      },
  },
  data() {
    return {
      BAR_WHITESPACE_PERCENT: .8,
    }
  },
  computed: {
    barWidthTotalPercent() {
      //limit to 100%
      return Math.min((((this.series1 + this.series2) / this.maxUsageValue) * this.BAR_WHITESPACE_PERCENT * 100),100)
    },
    barWidthSeries1Percent() {
      return (this.series1 / (this.series1 + this.series2)) * 100
    },
    barWidthSeries2Percent() {
      return (this.series2 / (this.series1 + this.series2)) * 100
    },
    isZeroWidth(){
      return this.series1 === 0 && this.series2 === 0
    }
  },
}
</script>

<style scoped></style>