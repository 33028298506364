var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        attrs: {
          id:
            _vm.engineType === "All Electric"
              ? "total-savings-box"
              : "total-savings-box-no-state"
        }
      },
      [
        _c("span", { staticClass: "gds-input-field__label" }, [
          _vm._v("Rebate Total")
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "rebate-total" } }, [
          _vm._v(_vm._s("$" + _vm.totalSavings.toLocaleString()))
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("div", { staticClass: "eicproduct-breakdown" }, [
          _c("div", { staticClass: "eicproduct-row gds-space-stack-s" }, [
            _vm._v(
              "\n        GMP Rebate (" +
                _vm._s(_vm.engineType + " Vehicle") +
                ")\n        "
            ),
            _c("span", { staticClass: "total-savings-flex-item" }, [
              _vm.engineType == "All Electric" && _vm.preOwned
                ? _c("b", [_vm._v(_vm._s("$" + _vm.usedRebateAmount))])
                : _vm._e(),
              _vm._v(" "),
              _vm.engineType == "All Electric" && !_vm.preOwned
                ? _c("b", [
                    _vm._v(_vm._s("$" + _vm.allElectricNewRebateAmount))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.engineType == "Hybrid" && _vm.preOwned
                ? _c("b", [_vm._v(_vm._s("$" + _vm.hybridUsedRebateAmount))])
                : _vm._e(),
              _vm._v(" "),
              _vm.engineType == "Hybrid" && !_vm.preOwned
                ? _c("b", [_vm._v(_vm._s("$" + _vm.hybridNewRebateAmount))])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.incomeQualifiedRebate
            ? _c("div", { staticClass: "eicproduct-row gds-space-stack-s" }, [
                _vm._v("\n        Income Qualified Rebate\n        "),
                _c("span", { staticClass: "total-savings-flex-item" }, [
                  _c("b", [_vm._v(_vm._s("$" + _vm.incomeQualifiedRebate))])
                ])
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }