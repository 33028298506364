var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "subpage-dashboards subpage-preferences--dashboard subpage-dashboards--left-spacer"
    },
    [
      _c("editor-mailing-address"),
      _vm._v(" "),
      _c("editor-paperless", {
        attrs: { billingStatus: _vm.billingStatus },
        on: {
          update: function($event) {
            return _vm.RefreshStatus()
          }
        }
      }),
      _vm._v(" "),
      _c("editor-auto-payments", {
        attrs: { billingStatus: _vm.billingStatus }
      }),
      _vm._v(" "),
      _c("editor-budget-billing")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }