<template>
  <div class="my-account__login-page-wrap">
    <section v-if="!resetSent" class="my-account__login-form-wrap">
      <div class="gds-display-2 gds-space-stack-m">Forgot Username</div>
      <div class="gds-font-size-l gds-space-stack-l">Please provide your email address and we’ll send you an email with your username information.</div>
      <form class="global-panel__form" @submit.prevent="Submit()">
        <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorSubmit }">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Email Address</span>
            <input
              v-model="email"
              v-validate="'required|email|max:74'"
              maxlength="74"
              name="email address"
              key="email address"
              class="gds-input-field__input"
              type="email"
              spellcheck="false"
              autocapitalize="none"
              autocomplete="email"
              required
              aria-required="true"
            />
          </label>
          <div v-if="errors.first('email address')" class="validation-error">{{ errors.first("email address")}}</div>
        </fieldset>

        <div class="gds-space-stack-l">
          <div v-if="errorSubmit" id="submission_error" class="gds-helper-text-error">{{errorSubmit}}</div>
        </div>

        <div class="gds-space-stack-m"></div>

        <div class="gds-flex-container gds-space-stack-m">
          <a href="/" class="gds-button gds-secondary gds-space-inline-m">Cancel</a>
          <button :disabled="pendingSubmit" :class="{ 'gds-loading': pendingSubmit }" type="submit" class="gds-button">
            <span class="gds-button__text">Submit</span>
          </button>
        </div>
      </form>
    </section>
    <section v-if="resetSent" class="my-account__login-form-wrap gds-align--text-center">
      <div class="bill-pay-success__intro-image">
        <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/message-sent.svg" alt="Email sent" />
      </div>
      <div class="gds-display-2 gds-space-stack-m">Check your inbox</div>
      <div class="gds-font-size-l gds-space-stack-l">We’ve sent you an email with your username.</div>

      <div class="gds-space-stack-m global-panel__form">
        <a href="/" class="gds-button">Done</a>
      </div>
    </section>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";

export default {
  name: "PageForgotUsername",
  components: {
  },
  data() {
    return {
      email: undefined,

      resetSent: false,

      errorSubmit: undefined,
      pendingSubmit: false,
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    async Submit() {
      if (await this.$validator.validateAll()) {
        this.errorSubmit = undefined;
        this.pendingSubmit = true;
        try {
          await GMPAPI.ForgotUsername(this.email);
          this.resetSent = true;
        } catch (err) {
          this.errorSubmit = "Notification failed";
        } finally {
          this.pendingSubmit = false;
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>