<template>
  <div class="subpage-dashboards subpage-account-settings--dashboard">
    <h1 class="my-account__title gds-space-stack-l">Account Settings</h1>
    <div class="my-account__section-with-bottom-border">
      <div class="gds-flex-container gds-flex-container--left my-account__current-login">
        <div class="gds-font-size-l gds-space-inline-s">
          Currently signed in as <span class="gds-font-demi">{{userInfo.fullName}}</span>, {{userInfo.email}}
          <a href="/user-profile" class="gds-button__text gds-font-size-l">Manage User Settings</a>
        </div>
      </div>
    </div>
    <section class="settings-editor my-account__full-width-section gds-space-stack-l">
      <div class="my-account__section-settings__address-account-wrap gds-flex-container gds-flex-container--top gds-flex-container--left">
        <div class="my-account__settings--detail-flex-item">
          <h2 class="settings-editor__title gds-space-stack-m">Service Address</h2>
          <div class="gds-font-size-l gds-space-stack-l">
            <div class="gds-linebreaks">{{currentAccount.address | addressFormat}}</div>
          </div>
        </div>

        <div class="my-account__settings--detail-flex-item">
          <h2 class="settings-editor__title gds-space-stack-m">Service Account</h2>
          <div class="gds-font-size-l">{{currentAccount.lastName}}, {{currentAccount.firstName}}</div>
          <div class="gds-font-size-l gds-space-stack-m">#{{currentAccount.accountNumber}}</div>
        </div>
        <div class="my-account__settings--detail-flex-item primary-account">
          <div v-if="currentAccount.isPrimary" class="gds-flex-container gds-flex-container--left gds-flex-container--bottom">
            <div class="my-account__green-check-cirlce">
              <svg class="my-account__white-check-icon gds-icon">
                <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-white-check-on-green" />
              </svg>
            </div>
            <span class="user-profile__account-status-label gds-font-demi gds-font-size-m gds-space-inline-l">Primary Account</span>
          </div>
        </div>
      </div>
    </section>

    <editor-account-photo />

    <editor-account-nickname />

    <editor-mailing-address />

    <editor-phones />

    <editor-paperless :billingStatus="billingStatus" @update="RefreshStatus()" />
    <editor-auto-payments :billingStatus="billingStatus" />
    <editor-budget-billing />
  <section class="settings-editor my-account__full-width-section gds-space-stack-xl">
    <h3 class="settings-editor__title gds-space-stack-l">Actions</h3>
    <div v-if="currentAccount.isPrimary">This account is marked as the primary account and cannot be removed. <a href="/user-profile/accounts/" class="gds-link gds-link--bold">Change primary account. </a></div>
    <div v-if="!currentAccount.isPrimary">
      <button @click="showRemove = true" type="button" class="gds-button red gds-text-button">
        <span class="gds-button__text">Remove Account</span>
      </button>
    </div>
  </section>
    <modal-remove-account :visible="showRemove" @complete="RemoveComplete" />
  </div>
</template>

<script>
import EditorAccountPhoto from "../editors/EditorAccountPhoto";
import EditorAccountNickname from "../editors/EditorAccountNickname";
import EditorMailingAddress from "../editors/EditorMailingAddress";
import EditorPhones from "../editors/EditorPhones";
import EditorPaperless from "../editors/EditorPaperless";
import EditorAutoPayments from "../editors/EditorAutoPayments";
import EditorBudgetBilling from "../editors/EditorBudgetBilling";
import ModalRemoveAccount from "./ModalRemoveAccount";

import GMPAPI from "../../../services/gmpapi";
import { DumpError } from '../../../utilities';


export default {
  name: "SubpageAccountSettings",
  components: {
    EditorAccountPhoto,
    EditorAccountNickname,
    EditorMailingAddress,
    EditorPhones,
    EditorPaperless,
    EditorAutoPayments,
    EditorBudgetBilling,
    ModalRemoveAccount,
  },
  data() {
    return {
      billingStatus: undefined,
      showRemove: false,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    userInfo() {
      return this.$store.state.user.userinfo;
    },
  },
  async mounted() {
    await this.RefreshStatus();
  },
  methods: {
    async RefreshStatus() {
      this.billingStatus = undefined;
      try {
        const status = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber)
        this.billingStatus = status;
      } catch (err) {
        DumpError("Account settings refresh error", err);
        this.billingStatus = null;
      }
    },
    RemoveComplete() {
      this.showRemove = false;
    },
  },
  watch: {
    // Refresh billing status whenever the account changes
    async currentAccount() {
      await this.RefreshStatus();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>