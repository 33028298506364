/** Definitions for each usage rate, how labeled and colored */
export default [
  {
    name: "waterHeater",
    label: "Water Heater",
    measurement: "kWh",
    stack: "water",
    flatColor: "rgba(113, 84, 216, 1)",
    disabledColor: "rgba(113, 84, 216, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#6448c8"],
        [1, "#7356db"],
      ]
    },
    selected: true,
  },
  {
    name: "storageHeatOffPeak",
    label: "Str. Heat Off Peak",
    measurement: "kWh",
    stack: "storage",
    flatColor: "rgba(113, 84, 216, 1)",
    disabledColor: "rgba(113, 84, 216, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#62b4e4"],
        [1, "#59a3ce"],
      ]
    },
    rounding: "top",
    selected: true,
  },
  {
    name: "storageHeatOnPeak",
    label: "Str. Heat On Peak",
    measurement: "kWh",
    stack: "storage",
    flatColor: "rgba(44, 96, 178, 1)",
    disabledColor: "rgba(44, 96, 178, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#1e519b"],
        [1, "#3067be"],
      ]
    },
    rounding: "bottom",
    selected: true,
  },
  {
    name: "consumed",
    label: "Consumed",
    measurement: "kWh",
    stack: "consumption",
    flatColor: "rgba(254, 133, 48, 1)",
    disabledColor: "rgba(254, 133, 48, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#fd9b30"],
        [1, "#fe812f"],
      ]
    },
    selected: true,
  },
  {
    name: "offPeak",
    label: "Off Peak",
    measurement: "kWh",
    stack: "consumptionPeak",
    flatColor: "rgba(249, 182, 123, 1)",
    disabledColor: "rgba(249, 182, 123, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#f9b67b"],
        [1, "#f9b67b"],
      ]
    },
    rounding: "top",
    selected: true,
  },
  {
    name: "onPeak",
    label: "On Peak",
    measurement: "kWh",
    stack: "consumptionPeak",
    flatColor: "rgba(234, 87, 25, 1)",
    disabledColor: "rgba(234, 87, 25, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#fd5730"],
        [1, "#d75703"],
      ]
    },
    rounding: "bottom",
    selected: true,
  },
  {
    name: "generation",
    label: "Generated",
    measurement: "kWh",
    stack: "generation",
    flatColor: "rgba(63, 149, 77, 1)",
    disabledColor: "rgba(63, 149, 77, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#57a756"],
        [1, "#248143"],
      ]
    },
    selected: true,
  },
  {
    name: "returnedGeneration",
    label: "Returned",
    measurement: "kWh",
    stack: "returned",
    flatColor: "rgba(164, 215, 152, 1)",
    disabledColor: "rgba(164, 215, 152, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#a4d798"],
        [1, "#a4d798"],
      ]
    },
    selected: true,
  },
  {
    name: "temperature",
    label: "Temperature",
    measurement: "°F",
    line: true,
    flatColor: "rgba(0, 0, 0, 1)",
    disabledColor: "rgba(0, 0, 0, 0.3)",
    gradientColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
      stops: [
        [0, "#000"],
        [1, "#000"],
      ]
    },
    selected: false,
  },
];
