<template>
  <div>
    <form @submit.prevent class="gds-space-stack-l">
      <h2 class="gds-display-1 gds-space-stack-l">Edit Saved Card</h2>

      <div v-if="wallet.recurringSchedule" class="form-message-box form-message-box--blue gds-space-stack-l">
        This payment method is used for automatic payments on this GMP Account. Visit the
        <router-link to="/preferences#autopay">Billing Preferences</router-link>&nbsp;page
        to change the payment method used for automatic payments.
      </div>

      <div v-if="wallet.isExpired" class="form-message-box gds-space-stack-l">This payment method has expired, please update the payment details or remove the payment method.</div>

      <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Payment Method Nickname</span>
          <input
            v-model="wallet.accountNickName"
            v-validate="'required|max:45'"
            name="account nickname"
            key="account nickname"
            class="gds-input-field__input"
            type="text"
            placeholder="Account Nickname"
            maxlength="45"
          />
        </label>
        <div v-if="errors.first('account nickname')" class="validation-error">{{ errors.first("account nickname")}}</div>
      </fieldset>

      <fieldset class="bill-pay__fieldset-wrap gds-fieldset gds-fieldset--no-left-padding gds-space-stack-s">
        <label class="gds-input-field">
          <span class="gds-input-field__label">Name on Card</span>
          <input
            v-model="wallet.debitName"
            v-validate="'required|max:45'"
            name="name on card"
            key="name on card"
            class="gds-input-field__input"
            type="text"
            placeholder="Name"
            maxlength="45"
            autocomplete="cc-name"
          />
        </label>
        <div v-if="errors.first('name on card')" class="validation-error">{{ errors.first("name on card")}}</div>
      </fieldset>
      <div class="bill-pay-slat__flex-container gds-flex-container gds-flex-container--top gds-space-stack-m">
        <fieldset class="gds-fieldset gds-fieldset--no-left-padding">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Card Number</span>
            <div class="bill-pay__credit-card-number-last-four gds-flex-container gds-flex-container--left">
              <span class="bill-pay__credit-card-prefix gds-text">
                <svg class="bill-pay__icon gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-credit-card" />
                </svg>
              </span>
              <span class="disabled-cardnumber">************{{wallet.debitAccount}}</span>
            </div>
          </label>
        </fieldset>
        <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding-when-small">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Expiration</span>
            <input
              v-model="wallet.expirationDate"
              v-mask="'##/##'"
              v-validate="'required|date_format:MM/yy|after:' + firstValidCard"
              name="expiration date"
              key="expiration date"
              class="gds-input-field__input"
              type="text"
              placeholder="MM/YY"
              autocomplete="cc-exp"
            />
          </label>
          <div v-if="errors.first('expiration date')" class="validation-error">{{ errors.first("expiration date")}}</div>
        </fieldset>
        <fieldset v-if="wallet.paymentType === 'CC'" class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding-when-small">
          <label class="gds-input-field">
            <span class="gds-input-field__label">CVV</span>
            <input
              v-model="wallet.cvv"
              v-mask="'####'"
              v-validate="'required|numeric|min:3|max:4'"
              name="CVV"
              key="CVV"
              class="gds-input-field__input"
              type="tel"
              maxlength="4"
              autocomplete="cc-csc"
            />
          </label>
          <div v-if="errors.first('CVV')" class="validation-error">{{ errors.first("CVV")}}</div>
        </fieldset>
        <fieldset class="bill-pay-slat__small-flex gds-fieldset gds-fieldset--no-left-padding-when-small">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Billing ZIP Code</span>
            <input
              v-model="wallet.debitZip"
              v-validate="'required|numeric|min:5|max:5'"
              v-mask="'#####'"
              name="billing zip"
              key="billing zip"
              class="gds-input-field__input"
              type="tel"
              maxlength="5"
            />
          </label>
          <div v-if="errors.first('billing zip')" class="validation-error">{{ errors.first("billing zip")}}</div>
        </fieldset>
      </div>
    </form>
    <div class="gds-flex-container gds-flex-container--left gds-space-stack-l">
      <button @click="Complete()" type="button" class="bill-pay__paired-button gds-button gds-compact gds-secondary">Cancel</button>
      <button @click="Save()" :disabled="pendingSave" :class="{ 'gds-loading': pendingSave }" type="button" class="bill-pay__paired-button gds-button gds-compact">
        <span class="gds-button__text">Save</span>
      </button>
    </div>
    <div v-if="errorSave" class="form-message-box gds-space-stack-l">{{errorSave}}</div>

    <div>
      <button @click="RemoveMethod()" :disabled="pendingRemove" :class="{ 'gds-loading': pendingRemove }" type="button" class="gds-button gds-secondary gds-round gds-red">
        <span class="my-account__notifications--text-button gds-button__text">Remove This Payment Method</span>
      </button>
      <div v-if="errorRemove" class="form-message-box gds-space-stack-l">{{errorRemove}}</div>
    </div>
    <modal-remove-autopay-wallet :visible="showRemove" :wallet="wallet" @complete="RemoveComplete" />
  </div>
</template>

<script>
import { format, addMonths } from 'date-fns';
import GMPAPI from "../../../services/gmpapi";
import { DumpError, ToFullExpirationDate } from "../../../utilities";
import { InterpretSpeedpayCode } from "../../../speedpay";
import ModalRemoveAutopayWallet from './ModalRemoveAutopayWallet';

export default {
  name: "EditorWalletCard",
  props: ["wallet"],
  data() {
    return {
      firstValidCard: format(addMonths(new Date(), -1), "MM/yy"),

      pendingSave: false,
      errorSave: false,

      pendingRemove: false,
      errorRemove: false,

      showRemove: false,
    };
  },
  components: {
    ModalRemoveAutopayWallet,
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
  },
  methods: {
    async Save() {
      this.errorSave = undefined;
      if (await this.$validator.validateAll()) {
        this.pendingSave = true;
        try {
          const wallet = this.wallet;
          const walletRecord = {
            accountNickName: wallet.accountNickName,
            paymentType: wallet.paymentType,
            debitAccount: "*" + wallet.debitAccount,
            expirationDate: ToFullExpirationDate(wallet.expirationDate),
            cvv: wallet.cvv,
            transType: wallet.transType,
            creditAccount: this.currentAccount.accountNumber,
            cardType: wallet.cardType,
            accountNickName: wallet.accountNickName,
            // Populate all location fields from account, should be required but ignored
            debitZip: wallet.debitZip || this.currentAccount.address.zip,
            creditZip: this.currentAccount.address.zip,
            debitState: this.currentAccount.address.state,
            creditState: this.currentAccount.address.state,
            debitCity: this.currentAccount.address.city,
            creditCity: this.currentAccount.address.city,
            debitAddress1: this.currentAccount.address.street1
          };
          await GMPAPI.UpdateWallet(
            this.currentAccount.accountNumber,
            walletRecord,
            wallet.walletId
          );
          // Kick back to wallet list and refresh
          this.Complete();
        } catch (err) {
          DumpError("Save credit error", err);
          let code;
          if (err.response && err.response.data) {
            code = err.response.data.code;
            if (err.response.data.message) {
              console.error(code + ": " + err.response.data.message);
            }
          }
          // Take specific actions on certain error codes here
          const parsed = InterpretSpeedpayCode(code);
          if (parsed) {
            // Card number is hidden for card edit
            if (parsed.field && parsed.field !== "card number") {
              // This is a field-specific error
              if (!this.errors) this.errors = new ErrorBag();
              this.errors.add(parsed);
            } else {
              // This is a generic error
              this.errorSave = parsed.msg;
            }
          } else {
            // Error not recognized or no response data
            this.errorSave = "There was an unexpected error saving your card.";
          }
        } finally {
          this.pendingSave = false;
        }
      }
    },
    Complete() {
      this.$emit("complete");
    },
    async RemoveMethod() {
      this.pendingRemove = true;
      try {
        if (this.wallet.recurringSchedule) {
          // If this is the autopay wallet, show a confirmation dialog before delete
          this.showRemove = true;
        } else {
          // Just go ahead and delete it
          await GMPAPI.DeleteWallet(this.currentAccount.accountNumber, this.wallet.walletId);
          this.Complete();
        }
      } catch (err) {
        DumpError("Remove wallet error", err);
        this.errorRemove = "There was an unexpected error removing the payment method.";
      } finally {
        this.pendingRemove = false;
      }
    },
    RemoveComplete(changed) {
      this.showRemove = false;
      // If the wallet was deleted, we are done here
      if (changed) {
        this.Complete();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>