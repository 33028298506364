<template>
  <div v-if="visible" class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal">
      <FocusLock>
        <div class="my-account__modal-box--body">
          <div class="gds-font-size-xl gds-font-demi gds-space-stack-m">Do you need more time to complete your payment?</div>
          <div class="my-account__modal-box--message gds-space-stack-l">
            <div>Your payment session is about to time-out. If you need more time to complete the payment, please click on the button below.</div>
          </div>

          <button @click="onReset()" type="button" class="gds-button">I need more time</button>
        </div>
      </FocusLock>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalMoretime",
  props: ["visible", "onReset"],
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>