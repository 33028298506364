var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.FF_Devices
    ? _c("div", { staticClass: "gmp-devices-page__device-detail" }, [
        _c("div", [
          _c(
            "div",
            { class: { "is-mobile-or-tablet-view": !_vm.isDesktopView } },
            [
              _c(
                "h2",
                { staticClass: "my-account__title gds-space-stack-default" },
                [
                  _vm._v(
                    "\n        Details for device: " +
                      _vm._s(_vm.deviceId) +
                      "\n      "
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "gds-space-stack-s" },
            [
              _c("router-link", { attrs: { to: "/devices" } }, [
                _vm._v("\n        <<Back to Devices Dashboard\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.isDesktopView
            ? _c(
                "div",
                { class: { "is-desktop-content": _vm.isDesktopView } },
                [_vm._v("\n      DESKTOP CONTENT\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isDesktopView
            ? _c(
                "div",
                {
                  class: { "is-mobile-or-tablet-content": !_vm.isDesktopView }
                },
                [_vm._v("\n      TABLET / MOBILE CONTENT\n    ")]
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }