<template>
  <div
    v-if="FF_Devices"
    class="subpage-dashboards gmp-devices-page gmp-devices-page__temp-assets">
    <div>
      <div
        :class="{ 'is-mobile-or-tablet-view': !isDesktopView}"
      >
        <h2 class="my-account__title gds-space-stack-default">
          Temp Assets
        </h2>
      </div>
      <div class="gds-space-stack-s">
        <router-link to="/devices">
          &lt;&lt;Back to Devices Dashboard
        </router-link>
      </div>
      <div
        v-if="isDesktopView"
        :class="{ 'is-desktop-content': isDesktopView}"
      >
        DESKTOP CONTENT
      </div>
      <div
        v-if="!isDesktopView"
        :class="{ 'is-mobile-or-tablet-content': !isDesktopView}"
      >
        TABLET / MOBILE CONTENT
      </div>
    </div>
    <div>
      <!-- START leah content -->

      <!-- END leah content -->
      <!-- START kevin content -->

      <!-- END kevin content -->
      <!-- START todd content -->

      <!-- END todd content -->
      <!-- START ben content -->

      <!-- END ben content -->
      <!-- START jay content -->

      <!-- END jay content -->
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../mixins/MediaQueryMixin';
import { isFeatureEnabled } from '../../../services/featureflags';

export default {
  name: 'TempAssets',
  mixins: [MediaQueryMixin],
  data() {
    return {
      FF_Devices: undefined,
    };
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  async mounted() {
    this.deviceId = this.$route.params.id;
    await this.checkFeatureFlag();
  },
  methods: {
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
  },
};
</script>
