import DataStore from "../store";
import * as configcat from "configcat-js";
import { DumpError } from "../utilities";
import { EnvironmentConstants } from "../environment"

let client;
let userObject;

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function initConfigCatClient(additionalUserProperties) {
  if (client) {
    return;
  }

  let logger = configcat.createConsoleLogger(1);
  let configCatClient = configcat.getClient(EnvironmentConstants.ConfigCatSDKKey,
    configcat.PollingMode.AutoPoll,
    { pollingIntervalSeconds: 600,
      logger: logger
    }
  );
  client = configCatClient;

  if (
    DataStore &&
    DataStore.state &&
    DataStore.state.user &&
    DataStore.state.user.userinfo) {

    userObject = {
      identifier: DataStore.state.user.userinfo.accountId,
      accountId: DataStore.state.user.userinfo.accountId,
      custom: {
        authenticated: 'true',
        isAdmin: !!DataStore.state.user.userinfo.isAdmin ? 'true' : 'false',
        ...additionalUserProperties
      }
    };
  } else {
    userObject = {
      identifier: generateUUID(),
      custom: {
        authenticated: 'false',
        isAdmin: 'false',
        ...additionalUserProperties
      }
    }
  }
}

async function getFeatureFlagAsync(featureName, defaultValue) {
  const value = await client.getValueAsync(
    featureName,
    defaultValue,
    userObject
  );
  return value;
}


export async function isFeatureEnabled(featureName, defaultValue, additionalUserProperties) {
  const value = await getConfigValue(featureName, defaultValue, additionalUserProperties);
  if (typeof value === "boolean") {
    return value;
  } else {
    console.error("featureName must be Boolean");
    return defaultValue;
  }
}

export async function getConfigValue(featureName, defaultValue, additionalUserProperties) {
  try {
    initConfigCatClient(additionalUserProperties);
    return await getFeatureFlagAsync(featureName, defaultValue, additionalUserProperties);
  } catch (err) {
    DumpError("Feature Flag Service error", err);
    return defaultValue;
  }
}

