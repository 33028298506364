<template>
    <span :id="sparkBarId" :data-spark-bar-array="dataAsString"></span>
</template>

<script>
export default {
  name: "HighestUsageMicroSparkbar",
  props: {
    data: Array
  },
  mounted() {
    this.generateSparkline();
  },
  data() {
    return {
      barWidth: 8,
      barSpace: 2,
      attrWidth: 'width',
      attrHeight: 'height',
      attrXmlNs: 'http://www.w3.org/2000/svg',
      dataAsString: JSON.stringify(this.data),
      attrDataSparkBarArray: 'data-spark-bar-array',
      sparkBarId: `highest-usage-micro-sparkbar-${this.generateRandomString(10)}`
    };
  },
  methods: {
    generateRandomString(length) {
      return Array.from({ length }, () => Math.random().toString(36).charAt(2)).join('');
    },
    generateSparkline() {
        const domElementSparkBar = document.getElementById(this.sparkBarId);
        const sparkBarData = JSON.parse(domElementSparkBar.getAttribute(this.attrDataSparkBarArray));
        domElementSparkBar.innerHTML = this.createBarSparkline(sparkBarData);
        domElementSparkBar.removeAttribute(this.attrDataSparkBarArray);
    },
    createBarSparkline(data) {
      const highestValue = Math.max(...data);

      const domElementSvg = document.createElementNS(this.attrXmlNs, 'svg');
      domElementSvg.setAttribute(this.attrHeight, '20');
      domElementSvg.setAttribute(this.attrWidth, '50');
      domElementSvg.setAttribute('preserveAspectRatio', 'none');
      domElementSvg.setAttribute('viewBox', `0 0 ${data.length * (this.barSpace + this.barWidth) - this.barSpace} 100`);

      data.forEach((dataValue, i) => {
          const domElementRect = document.createElementNS(this.attrXmlNs, 'rect');
          // Set a min height if no data
          if (!dataValue) {
            dataValue = 0.1;
          }
          domElementRect.setAttribute('x', (i * (this.barSpace + this.barWidth)).toString());
          domElementRect.setAttribute('y', (100 - dataValue * 100 / highestValue).toString());
          domElementRect.setAttribute(this.attrWidth, this.barWidth.toString());
          domElementRect.setAttribute(this.attrHeight, (dataValue * 100 / highestValue).toString());
          domElementRect.setAttribute('fill', dataValue === highestValue ? '#ef6723' : '#a7a7a7');
          domElementSvg.appendChild(domElementRect);
      });

      const serializer = new XMLSerializer();
      return serializer.serializeToString(domElementSvg);
    }
  }
};
</script>

<style scoped>
</style>