<template>
  <!-- TODO: Validation: this control is generally wrapped in a label, but has two labelable items (input and button) -->
  <div class="password-wrapper">
    <input
      ref="input"
      :type="inputType"
      class="gds-input-field__input"
      :value="value"
      @input="updateValue"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      required
      aria-required="true"
      maxlength="100"
    />
    <button @click="ToggleVisibility()" type="button" class="password-toggle">
      <svg class="gds-icon">
        <use v-if="this.inputType === 'password'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-visibility-off" />
        <use v-if="this.inputType !== 'password'" xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-visibility" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "WidgetPasswordInput",
  props: ["value", "placeholder", "autocomplete"],
  $$_veeValidate: {
    value() {
      return this.$refs.input.value;
    }
  },
  data() {
    return {
      inputType: "password",
    };
  },
  methods: {
    ToggleVisibility() {
      if (this.inputType === "password") {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    // Pass inputs up the chain
    updateValue(e) {
      this.$emit("input", e.target.value);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>