<template>
  <div class="my-account__login-page-wrap">
    <section class="my-account__login-form-wrap">
      <div v-if="loadState === undefined" class="table-loading" />
      <div v-if="loadState === 'error'">
        <div class="gds-display-2 gds-space-stack-l">Verification Failed</div>
        <div>The token provided does not appear to be valid. If you copied the link from an email, please make sure you copied the entire URL.</div>
      </div>
      <div v-if="loadState === 'complete'" class="gds-align--text-center">
        <div class="verify-contact__intro-image">
          <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/verify-contact.svg" alt="contact verified" />
        </div>
        <div class="gds-display-2 gds-space-stack-l">Verification Complete</div>
        <div class="gds-font-size-l gds-space-stack-l">We've successfully verified your email address.</div>

        <div class="gds-space-stack-m global-panel__form">
          <a href="/" class="gds-button">Done</a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import WidgetPasswordInput from "../WidgetPasswordInput";
import GMPAPI from "../../services/gmpapi";

export default {
  name: "PageVerifyContact",
  components: {
    WidgetPasswordInput,
  },
  data() {
    return {
      loadState: undefined,
    };
  },
  async mounted() {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    try {
      const code = params.get("sptoken");
      await GMPAPI.VerifyContact(code);
      this.loadState = "complete";
    } catch (err) {
      this.loadState = "error";
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>