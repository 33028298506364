var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-item typeahead" }, [
    _c("label", { staticClass: "gds-input-field" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.setValue,
            expression: "setValue"
          },
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'"
          }
        ],
        key: _vm.type,
        staticClass: "gds-input-field__input gds-space-stack-s",
        attrs: {
          name: _vm.type,
          type: "text",
          autocomplete: "off",
          placeholder: _vm.placeholderText || "Type Here..."
        },
        domProps: { value: _vm.setValue },
        on: {
          keyup: function($event) {
            return _vm.filterArray()
          },
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.setValue = $event.target.value
            },
            function($event) {
              return _vm.filterArray()
            }
          ],
          change: function($event) {
            return _vm.selectItem($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.errors.first("" + _vm.type)
        ? _c("div", { staticClass: "rebate-validation-error" }, [
            _vm._v(_vm._s(_vm.errors.first("" + _vm.type)))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.showTypeAheadContainer
      ? _c(
          "div",
          { staticClass: "typeAhead-container" },
          _vm._l(_vm.filteredArray, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "typeAhead-card gds-space-stack-m",
                class: {
                  "active-dealership typeAhead-card gds-space-stack-m":
                    _vm.currentItem === item.id
                },
                on: {
                  click: function($event) {
                    return _vm.setItem(item.value)
                  }
                }
              },
              [
                _c("div", { staticClass: "typeAhead-text" }, [
                  _vm._v(_vm._s(item.value))
                ])
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }