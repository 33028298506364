<template>
  <div>
    <div v-if="loadState === undefined">
      <div class="bill-pay__page-loader">
        <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="bill-pay__page-loader--svg" />
      </div>
    </div>
    <flow-error v-if="loadState === 'maintenance'" name="Arrange a Payment" state="maintenance" phonetext="To make a payment arrangement by phone call us at" closepath="/arrange-payment" />
    <flow-error v-if="loadState === 'error'" name="Arrange a Payment" state="error" phonetext="To make a payment arrangement by phone call us at" closepath="/arrange-payment" />
    <router-view v-if="loadState === 'complete'"></router-view>
  </div>
</template>

<script>
import { ReturnToSender } from '../../utilities';
import { GetComponentStatus } from '../../services/statuspage';

export default {
  name: "PagePayArrange",
  data() {
    return {
      loadState: undefined,
    };
  },
  computed: {
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
  },
  async mounted() {
    // Wire up the close button (outside of vue)
    const closer = document.querySelector("#flow-close");
    if (closer) {
      closer.addEventListener("click", e => {
        ReturnToSender("/arrange-payment", "/account/billing");
      });
    }
    this.loadState = undefined;
    // Check for service outages
    const status = await GetComponentStatus("MyAccount");
    if (status === "operational" || this.isAdmin) {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>